import App from "AppInterface/App";
import { getBuildVersion, isAndroid } from "helpers";

export async function logEvent(name, params) {
    if (!name || !App.logEvent) {
        return;
    }

    try {
        let appId = await App.getAppInstallationId();
        let appEnv = App.getAppEnv();
        let appVersion = App.getAppVersion();
        let appBuildCode = await App.getBuildVersionCode();
        let webappBuildVersion = getBuildVersion();

        params = { ...params, appId, appEnv, appVersion, appBuildCode, webappBuildVersion }
    } catch (error) { }

    if (isAndroid()) {
        App.logEvent(name, JSON.stringify(params));
    } else {
        App.logEvent(name, params);
    }
}

export function setUserProperty(name, value) {
    if (!name || !value || !App.setUserProperty) {
        return;
    }

    App.setUserProperty(name, value);
}