import { IntegratedRecordsList } from 'components/IntegratedRecordsList';
import { table } from 'console';
import { isAdmin, isMobile, timeout } from 'helpers';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { AnumatiRecord, ApplicationState } from 'types';
import { AnumatiPatra } from './anand.anumatiPatra';
import { AnumatiTable } from './anumatiTable';
import { AnumatiViewTile, anumatiViewTileCSS } from './anumatiViewTile';

let anumatiCSS = `
#selection-dropdown {
	display: none !important;
}
.find-anything {
	display: none;
}
#record_count {
	margin-top: 0;
}

#anumati-detail-record-form {
	display: none;
}

#anumati-bulk-edit-form {
	display: none;
	margin: 15px 20px 5px 0px;
    padding: 1em;
    width: calc(50% - 35px);
    float: right;
}

#anumati-bulk-edit-form h3 {
	text-align: center;
	margin-bottom: 32px;
}

#anumati-bulk-edit-form h5 {
	float: left;
}

#anumati-bulk-edit-form button {
	float: right;
	margin-top: 24px;
}

#anumati-add-record {
	display: none;
}

#anumati-add-record-open {
	width: 50%;
	float: left;
	padding: 2em 1em 1em;
	display: none;
}

#add-record-open {
	float: right;
}

#date_wise_count {
	margin: 10px 5px 5px;
	box-shadow: 1px 1px 5px grey;
	display: block;
	overflow: auto;
}

.checkins-btn {
	display: none;
}

.intl-tel-input {
	width: 100%;
}

.heading {
	text-align: center;
	font-size: 1.2em;
	font-weight: bold;
	margin-bottom: 1.5em;
	border-bottom: 1px solid grey;
	padding-bottom: 0.3em;
	text-transform: uppercase;
}

form.tile .comments {
	width: 100%;
	resize: none;
	height: 9em;
}

.tile .comments {
	border: 1px solid darkgray;
	padding: 10px;
	overflow: auto;
	max-height: 6em;
}

.tile .comments-title {
	border: 1px solid darkgray;
	border-bottom: 0;
	text-align: center;
}

.tt-suggestion:hover {
	color: white;
	background-color: #0097cf;
	cursor: pointer;
}

.through-sugestion {
	padding: 0.7em 2em;
	white-space: nowrap;
}

.tt-menu {
	width: auto;
	overflow-y: auto;
	max-height: 150px;
	box-shadow: 1px 1px 5px 1px grey;
	background-color: white;
	overflow-x: hidden;
}

input[name='subscriber_no_code'] {
	text-transform: uppercase;
}

.tile.anumati-record.detail {
	padding-top: 15px;
}

.tile.anumati-record .main-details {
	padding-right: 30px;
}

.tile.anumati-record .main-details .title {
	text-align: right;
	padding: 0;
	margin-bottom: 15px;
	font-weight: bold;
	font-size: 0.9em;
}

.tile.anumati-record .main-details .value {
	padding-right: 0;
	margin-bottom: 15px;
}

.tile.anumati-record input {
	width: 100%;
	background-color: white !important;
	border: none;
	border-bottom: 1px solid darkgray;
}

.tile.anumati-record input:disabled {
	background-color: #ccc !important;
}

.tile.anumati-record input[type='checkbox'] {
	width: auto;
	margin-right: 5px;
}

.radio,
.checkbox {
	margin-top: 0;
	margin-bottom: 0;
}

.tile.anumati-record input:focus {
	outline: none;
}

.tile.anumati-record .value select {
	width: 100%;
}

.tile.anumati-record .language-value {
	text-transform: capitalize;
}

.edit-allowed .check-in-details {
	display: none;
}

.bootstrap-dialog-message .edit-allowed .check-in-details {
	display: block;
}

/*
.check-in-section, .check-in-details {
	display: none;
}

.bootstrap-dialog-message .check-in-details {
	display: block;
}*/

form.tile.anumati-record .address {
	width: 100%;
	resize: none;
	height: 6em;
}

.tile.anumati-record .address {
	border: 1px solid darkgray;
	padding: 10px;
	overflow: auto;
	max-height: 6em;
}

.tile.anumati-record .comments-title {
	border: 1px solid darkgray;
	border-bottom: 0;
	text-align: center;
}

div.tile.anumati-record .main-details .value {
	border-bottom: 1px solid lightgrey;
	min-height: 1.5em;
	padding-right: 0;
}

div.tile.anumati-record .self-detail.value, div.tile.anumati-record .ref-detail.value {
	text-transform: capitalize;
}

.lb {
	border-bottom: 1px solid lightgrey;
}

.multi-field-row {
	padding-top: 3px;
	padding-bottom: 3px;
	font-size: 14px;
}

.multi-field-row:nth-last-child(1) {
	border-bottom: none;
}

.btn-danger {
	float: left;
}

table {
	width: 100%;
	font-size: 0.9em;
	max-height: 12em;
}

table,
th,
td {
	border: 1px solid black;
	padding: 3px;
	text-align: center;
}

thead {
	text-align: center;
	font-weight: bold;
}

.reference-suggestion img {
	width: 100%;
	padding: 0 5px;
}

.tt-dropdown-menu {
	top: auto !important;
	bottom: 100% !important;
}

.tt-dropdown-menu-name {
	top: 100% !important;
	bottom: auto !important;
}

.record-sms-button {
	float: right;
}

.print-history {
	max-height: 100px;
	overflow-y: auto;
}

@media (max-width: 991px) {
	#anumati-add-record-open {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 0;
	}

	.check-in-details {
		display: none;
	}
}
`;

let anumatiPrintCSS = `
body {
	background-color: rgba(0, 0, 0, 0);
}

@media print {
	.print-top-div footer {
		page-break-after: always;
	}
}

.print-top-div footer {
	position: absolute;
	bottom: 40px;
	font-weight: bold;
	font-size: 90%;
	padding: 10px 0px;
	right: 40px;
	left: 40px;
	page-break-after: always;
}
/*
.print-record {
	page-break-after: always;	
}*/

.print-top-div footer > div {
	padding: 5px 0;
}

.print-top-div header {
	font-size: 110%;
	text-align: center;
	font-weight: bold;
	width: 100%;
	padding: 40px 0px;
}

.print-top-div {
	background-color: white;
	padding: 0px 40px;
	width: 8.27in;
	position: relative;
	height: 11.69in;
}

.print-top-div .print-top-header {
	font-size: 125%;
	padding: 20px 0px;
	width: 100%;
	text-align: center;
	font-weight: bold;
}

.print-top-div .field {
	padding-top: 30px;
}

.print-top-div .title {
	font-weight: bold;
	font-size: 90%;
	text-align: left;
	padding-right: 0px;
	float: left;
}

.print-top-div .value {
	border-bottom: 1px dotted black;
	display: block;
	overflow: hidden;
	padding-left: 15px;
}

#print-configs #print-preview {
	height: 36em;
}

#mark_printed_records_btn {
	float: right;
}

#send_sms_records_btn {
	float: right;
}
`;

const html = `
<style>
	${anumatiCSS}
	${anumatiPrintCSS}
</style>

<div id="date_wise_count" data-id="${Math.random()}">
	<table>
		<thead>
			<tr>
				<td colspan="13">Date Wise Count</td>
			</tr>
		</thead>
		<tbody>
			<tr id="lables"></tr>
			<tr id="cards"></tr>
			<tr id="values"></tr>
			<tr id="checkins"></tr>
		</tbody>
	</table>
</div>
<div id="page-records">
    <form id="anumati-add-record-open" class="tile anumati-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
		<div class="col-xs-12 main-details">
			<div class="block">
				<div class="row self-detail">
					<div class="title col-xs-2">
						Name:
					</div>
					<div class="value col-xs-10">
						<input type="text"  name="name" value="" required placeholder="Name"/>
					</div>
				</div>
				<div class="row address-detail">
					<div class="title col-xs-2">
						Count:
					</div>
					<div class="value col-xs-10">
						<input type="number" name="people_count" required placeholder="Count"></input>
					</div>
				</div>
				<div class="row date-detail">
					<div class="title col-xs-2">
						Date:
					</div>
					<div class="value col-xs-10">
						<input type="text" class="date" name="permission_date" required placeholder="Date"></input>
					</div>
				</div>
				<div class="row ref-detail">
					<div class="title col-xs-2">
						Ref:
					</div>
					<div class="value col-xs-10">
						<input type="text"  name="reference" value="" required placeholder="Reference"/>
					</div>
				</div>
				<div class="row phone-detail">
					<div class="title col-xs-2">
						Mobile:
					</div>
					<div class="value col-xs-10">
						<!-- <input type="tel" name="phone_ignore" value=""/> -->
						<input type="text" name="phone" value="" style="letter-spacing: 5px;"/>
					</div>
				</div>
				<!-- <div class="row publish-detail">
					<div class="title col-xs-2">
						Publish Date:
					</div>
					<div class="value col-xs-4">
						<input type="text" class="date" name="publishDatePart" placeholder="Publish Date"></input>
					</div>
					<div class="title col-xs-2">
						Publish Time:
					</div>
					<div class="value col-xs-4">
						<input type="text" name="publishTimePart" placeholder="Publish Time"></input>
					</div>
				</div>
				<div class="row expire-detail">
					<div class="title col-xs-2">
						Expire Date:
					</div>
					<div class="value col-xs-4">
						<input type="text" class="date" name="expireDatePart" placeholder="Expire Date"></input>
					</div>
					<div class="title col-xs-2">
						Expire Time:
					</div>
					<div class="value col-xs-4">
						<input type="text" name="expireTimePart" placeholder="Expire Time"></input>
					</div>
				</div> -->
				<button type="submit" id="add-record-open" class="btn-primary btn">ADD</button>
			</div>
		</div>
    </form>
	<div id="anumati-table"></div>
    <form id="anumati-add-record" class="tile anumati-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
		<div class="col-xs-12 main-details">
			<div class="block">
				<div class="row self-detail">
					<div class="title col-xs-3">
						Name:
					</div>
					<div class="value col-xs-9">
						<input type="text"  name="name" value="" required placeholder="Name"/>
					</div>
				</div>
				<div class="row address-detail">
					<div class="title col-xs-3">
						Count:
					</div>
					<div class="value col-xs-9">
						<input type="number" name="people_count" required placeholder="Count"></input>
					</div>
				</div>
				<div class="row date-detail">
					<div class="title col-xs-3">
						Date:
					</div>
					<div class="value col-xs-9">
						<input type="text" class="date value" name="permission_date" required placeholder="Date"></input>
					</div>
				</div>
				<div class="row ref-detail">
					<div class="title col-xs-3">
						Ref:
					</div>
					<div class="value col-xs-9">
						<input type="text"  name="reference" value="" required placeholder="Reference"/>
					</div>
					<div class="row phone-detail">
						<div class="title col-xs-2">
							Mobile:
						</div>
						<div class="value col-xs-10">
							<!-- <input type="tel" name="phone_ignore" value=""/> -->
							<input type="text" name="phone" value="" placeholder="Mobile Number" />
						</div>
					</div>
				</div>
			</div>
		</div>
    </form>
	<form id="anumati-bulk-edit-form" class="tile anumati-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
		<div class="col-xs-12 main-details">
			<div class="block">
				<h3>Edit All Visible Records</h3>
			</div>
		</div>
		<div class="col-xs-12 main-details">
			<div class="block">
				<div class="row self-detail">
					<div class="title col-xs-3">
						Field:
					</div>
					<div class="value col-xs-9">
						<select name="field">
							<option value="name">Name</option>
							<option value="people_count">Count</option>
							<option value="permission_date">Date</option>
							<option value="reference">Ref</option>
							<option value="phone">Mobile</option>
							<option value="publishTime">Publish Time</option>
							<option value="expireTime">Expire Time</option>
							<option value="deleted">Delete (true/false)</option>
							<option value="approval">Approve (true/false)</option>							
						</select>
					</div>
				</div>
				<div class="row address-detail">
					<div class="title col-xs-3">
						Value:
					</div>
					<div class="value col-xs-9">
						<input type="text" name="value" required placeholder="Value"></input>
					</div>
				</div>
				<h5 id="update-msg"></h5>
				<button type="submit" id="anumati-bulk-edit-btn" class="btn-warning btn">Submit</button>
			</div>
		</div>
    </form>
    <div class="records">
        <form id="anumati-detail-record-form" class="tile anumati-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
            <div class="record-id">
                <span></span>
            </div>
			<div class="col-xs-12 main-details">
				<div class="block">
					<div class="row card-detail">
						<div class="title col-xs-3">
							Card No:
						</div>
						<div class="value col-xs-9">
						</div>
					</div>
					<div class="row self-detail">
						<div class="title col-xs-3">
							Name:
						</div>
						<div class="value col-xs-9">
							<input type="text"  name="name" value="" required placeholder="Name"/>
						</div>
					</div>
					<div class="row address-detail">
						<div class="title col-xs-3">
							Count:
						</div>
						<div class="value col-xs-9">
							<input type="number" name="people_count" required placeholder="Count"></input>
						</div>
					</div>
					<div class="row date-detail">
						<div class="title col-xs-3">
							Date:
						</div>
						<div class="value col-xs-9">
							<input type="text" class="date value" name="permission_date" required placeholder="Date"></input>
						</div>
					</div>
					<div class="row ref-detail">
						<div class="title col-xs-3">
							Ref:
						</div>
						<div class="value col-xs-9">
							<input type="text"  name="reference" value="" required placeholder="Reference"/>
						</div>
					</div>
					<div class="row phone-detail">
						<div class="title col-xs-3">
							Mobile:
						</div>
						<div class="value col-xs-9">
							<!-- <input type="tel" name="phone_ignore" value=""/> -->
							<input type="text" name="phone" value="" placeholder="Mobile Number" />
						</div>
					</div>
					<div class="row publish-detail">
						<div class="title col-xs-3">
							Publish Date:
						</div>
						<div class="value col-xs-3">
							<input type="text" class="date" name="publishDatePart" placeholder="Publish Date"></input>
						</div>
						<div class="title col-xs-3">
							Publish Time:
						</div>
						<div class="value col-xs-3">
							<input type="text" name="publishTimePart" placeholder="Publish Time"></input>
						</div>
					</div>
					<div class="row expire-detail">
						<div class="title col-xs-3">
							Expire Date:
						</div>
						<div class="value col-xs-3">
							<input type="text" class="date" name="expireDatePart" placeholder="Expire Date"></input>
						</div>
						<div class="title col-xs-3">
							Expire Time:
						</div>
						<div class="value col-xs-3">
							<input type="text" name="expireTimePart" placeholder="Expire Time"></input>
						</div>
					</div>					
				</div>
			</div>
			<div class="check-in-details">
				<div class='row'>
					<div class='heading'>Check-In Details</div>
				</div>
				<div class="row check-in-info">
					<div class="col-xs-12 main-details">
						<div class="block">
							<div class="row check-in-detail">
								<div id="check-in-row" class="multi-field-row hidden col-xs-12">
									<div class="row">
										<input type="hidden" name="checkin_ids[]" value="" />
										<input type="hidden" name="created_at[]" value="" />
										<div class="hidden value col-xs-1">
											<select name="in_out[]">
												<option value="in">Check-In</option>
												<option value="out">Check-Out</option>
											</select>
										</div>
										<div class="value created_at_date col-xs-3">
											<input type="text" class="date value" disabled value="" placeholder="Date"/>
										</div>
										<div class="value col-xs-3">
											<input type="text"  name="checkin_name[]" value="" placeholder="Name*"/>
										</div>										
										<div class="value col-xs-3">
											<!-- <input type="tel"  name="checkin_phone_ignore[]" value="" placeholder="Mobile*"/> -->
											<input type="text" name="checkin_phone[]" value="" placeholder="Mobile Number" />
										</div>
										<div class="value col-xs-3">
											<input type="text"  name="checkin_city[]" value="" placeholder="City"/>
										</div>
									</div>
									<div class="row">
										<div class="value col-xs-2">
											<input type="number"  name="checkin_count[]" value="" placeholder="Gents"/>
										</div>
										<div class="value col-xs-2">
											<input type="number"  name="checkin_count_ladies[]" value="" placeholder="Ladies"/>
										</div>
										<div class="value col-xs-2">
											<input type="number"  name="checkin_count_minor[]" value="" placeholder="Children"/>
										</div>
										<div class="value col-xs-2">
											<input type="number"  name="checkin_count_infant[]" value="" placeholder="Infant"/>
										</div>
										<div class="value col-xs-3">
											<input type="text"  name="checkin_conveyance[]" value="" placeholder="Conveyance"/>
										</div>
										<button type="button" class="col-xs-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="check-in-row"><i class="glyphicon glyphicon-plus"></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </form>
		<div id="anumati-detail-record" class="tile anumati-record detail edit-allowed" style="display: none;">
			<div class="record-id">
				<span></span>
			</div>
			<div class="row">
				<div class="col-xs-12 main-details">
					<div class="block">
						<div class="title col-xs-3">
							Card No:
						</div>
						<div class="value card-detail col-xs-9">
						</div>
						<div class="title col-xs-3">
							Name:
						</div>
						<div class="value self-detail col-xs-9">
						</div>
						<div class="title col-xs-3">
							Count:
						</div>
						<div class="value count-detail col-xs-9">
						</div>
						<div class="title col-xs-3">
							Date:
						</div>
						<div class="value date-detail col-xs-9">
						</div>
						<div class="title col-xs-3">
							Ref:
						</div>
						<div class="value ref-detail col-xs-9">
						</div>
						<div class="title col-xs-3">
							Mobile:
						</div>
						<div class="value phone-detail col-xs-9">
						</div>
						<div class="deleted">
							<div class="title col-xs-3">
								Deleted:
							</div>
							<div class="value deleted-detail col-xs-9">
							</div>
						</div>
						<div class="publish">
							<div class="title col-xs-3">
								Publish Time:
							</div>
							<div class="value publish-time-detail col-xs-9">
							</div>
						</div>
						<div class="expiry">
							<div class="title col-xs-3">
								Expire Time:
							</div>
							<div class="value expire-time-detail col-xs-9">
							</div>
						</div>
					</div>
					<button class="btn-primary btn record-print-button">Print</button>
					<button class="btn-primary btn record-ams-button">Send AMS</button>
				</div>
			</div>
			<div class="row check-in-details">
				<div class='col-xs-12'>
					<div class='heading'>Check-In Details</div>
				</div>
				<div class="check-in-info">
					<div class="col-xs-12 main-details">
						<div class="block">
							<div class="row check-in-detail">
								<div id="check-in-data-detail" class="multi-field-row hidden col-xs-12">
									<div class="row">
										<div class="col-xs-6">
											<div class="row">
												<div class="date col-xs-6">
													<input type="text" class="value" disabled value="" placeholder="Date"/>												
												</div>
												<div class="title col-xs-1">
													Name:
												</div>
												<div class="value name col-xs-5">
												</div>
											</div>
										</div>
										<div class="col-xs-6">
											<div class="row">
												<div class="title col-xs-3">
													Count Major & Minor:
												</div>
												<div class="value count col-xs-3">
												</div>										
												<div class="title col-xs-1">
													Mobile:
												</div>
												<div class="value phone col-xs-5">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button class="btn-primary btn checkins-btn">Check-In/Out</button>
		</div>
        <div class="filtered-records">
        </div>
    </div>
</div>
`;

interface Props {
	records: AnumatiRecord[];
	admin: boolean;
}

class Component extends React.Component<Props, { dateWiseCountHeight: number; formHeight: number; showDetailed: boolean; bulkHeight: number }> {
	constructor(props) {
		super(props);

		this.state = {
			dateWiseCountHeight: 0,
			formHeight: -25,
			showDetailed: isMobile() ? false : true,
			bulkHeight: 0,
		};
	}

	updateCountHeight = async () => {
		await timeout(100);
		if ($('#date_wise_count').is(':visible') && this.state.dateWiseCountHeight === 0) {
			this.setState({ dateWiseCountHeight: $('#date_wise_count').height() + 15 });
		}

		if (!$('#date_wise_count').is(':visible') && this.state.dateWiseCountHeight > 0) {
			this.setState({ dateWiseCountHeight: 0 });
		}
	};

	updateBulkHeight = async () => {
		await timeout(100);
		if ($('#anumati-bulk-edit-form').is(':visible') && this.state.bulkHeight === 0) {
			this.setState({ bulkHeight: $('#anumati-bulk-edit-form').height() + 50 });
		}

		if (!$('#anumati-bulk-edit-form').is(':visible') && this.state.bulkHeight > 0) {
			this.setState({ bulkHeight: 0 });
		}
	};

	componentDidMount = async () => {
		$('#date_wise_count').bind('heightChange', this.updateCountHeight);
		// ReactDOM.render(<AnumatiTable />, document.getElementById('anumati-table'));
	};

	render() {
		let style = {
			width: isMobile() || (!this.props.admin && this.state.formHeight <= 0) ? '100%' : 'calc(50% - 10px)',
			float: 'right',
			height: `calc(100% - ${this.state.dateWiseCountHeight}px - ${this.state.bulkHeight}px - ${isMobile() ? this.state.formHeight : 0}px)`,
		};

		$('#anumati-bulk-edit-form').css('width', isMobile() || this.state.formHeight <= 0 ? 'calc(100% - 25px)' : 'calc(50% - 35px)');

		let handlerProps = {
			toggleDetailCount: () => {
				$('#date_wise_count').toggle();
				this.updateCountHeight();
			},
			toggleForm: () => {
				$('#anumati-add-record-open').toggle();
				this.setState({ formHeight: this.state.formHeight <= 0 ? 270 : -25 });
			},
			toggleShowDetailed: () => {
				this.setState({ showDetailed: !this.state.showDetailed });
			},
			bulkEdit: async () => {
				$('#anumati-bulk-edit-form').toggle();
				$('#anumati-bulk-edit-form').find('#update-msg').html('');
				this.updateBulkHeight();
			},
			RecordTileProps: {
				showDetailed: this.state.showDetailed,
			},
		};

		let tableStyle = {
			height: `calc(100% - ${this.state.dateWiseCountHeight}px - ${this.state.formHeight}px - 30px)`,
		};

		return (
			<React.Fragment>
				<div dangerouslySetInnerHTML={{ __html: anumatiViewTileCSS }}></div>
				<IntegratedRecordsList
					records={this.props.records}
					RecordTile={AnumatiViewTile}
					html={html}
					RecordsHandler={AnumatiPatra}
					style={style}
					handlerProps={handlerProps}
					// getItemSize={() => (!handlerProps.RecordTileProps.showDetailed ? 100 : 320)}
				/>
				{!isMobile() && this.props.admin && <AnumatiTable style={tableStyle} setRefFilter={AnumatiPatra.setRefFilter} />}
			</React.Fragment>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		records: state.dataState.anumati.filtered,
		admin: isAdmin(state.dataState.userStore.userData),
	};
}

export const Anumati = connect(mapStateToProps)(Component);
