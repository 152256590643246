import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { MjBjRecord } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_MJBJ = '@@mjbj/UPDATE_MJBJ';
export type UPDATE_MJBJ = typeof UPDATE_MJBJ;

export interface UpdateMjBJ extends Action {
	type: UPDATE_MJBJ;
	changes: FBChanges;
}

export function updateMjBJ(changes: FBChanges): UpdateMjBJ {
	return {
		type: UPDATE_MJBJ,
		changes,
	};
}

export const UPDATE_MJBJ_FILTERED = UPDATE_MJBJ + '_FILTERED';
export type UPDATE_MJBJ_FILTERED = typeof UPDATE_MJBJ_FILTERED;

export interface UpdateMjBjFiltered extends Action {
	type: UPDATE_MJBJ_FILTERED;
	payload: MjBjRecord[];
}
