// import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

class Component extends React.Component<any> {
	render() {
		const { src, allowZoom, ...others } = this.props;

		return allowZoom ? (
			// <PinchZoomPan maxScale={5} position='center' zoomButtons={false} doubleTapBehavior='zoom'>
			<TransformWrapper>
			<TransformComponent wrapperClass='mx-center'>
				<img {...others} src={src} alt='' />
				</TransformComponent>
			</TransformWrapper>
			// </PinchZoomPan>
		) : (
				<img {...others} src={src} alt='' />
			);
	}
}

export const Image = Component;
