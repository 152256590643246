import clsx from 'clsx';
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import withWidth from '@material-ui/core/withWidth';
import { alignRhymes } from 'helpers';
import { ApplicationState } from 'types';
import { connect } from 'react-redux';
import { Theme, withStyles } from '@material-ui/core/styles';

interface TextProps {
	classes: any;
	width: string;
	children?: React.ReactNode;
	locale?: string;
	alignRhymes?: boolean;
	useFontSize?: boolean;
	fontSize: number;
}

const styles = (theme: Theme) => ({
	root: (props) => ({
		'line-height': 1.8,
		'text-align': 'justify',
		color: '#555555',

		[theme.breakpoints.up('xl')]: {
			fontSize: '2.2rem',
		},
		[theme.breakpoints.only('lg')]: {
			fontSize: '2.1rem',
		},
		[theme.breakpoints.only('md')]: {
			fontSize: '1.9rem',
		},
		[theme.breakpoints.only('sm')]: {
			fontSize: '1.8rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.7rem',
		},

		'& p': {
			textIndent: 0,

			'&[style*=justify]': {
				textIndent: props.locale === 'hi' ? '3em' : 0,
			},

			'&.ql-align-justify': {
				textIndent: props.locale === 'hi' ? '3em' : 0,
				textAlign: 'justify' as 'justify',

				'& img': {
					width: '100%'
				}
			},

			'&.ql-align-center': {
				textAlign: 'center' as 'center',

				'& img': {
					width: '100%'
				}
			}
		},
	}),
	inline: {
		display: 'inline',
	},
});

class TextContent extends React.Component<TextProps, any> {
	private rootRef;

	constructor(props: any) {
		super(props);
		this.rootRef = React.createRef();
	}

	componentDidMount() {
		if (this.props.alignRhymes) {
			alignRhymes(this.rootRef.current as HTMLElement);

			try {
				(document as any).fonts.ready.then(() => {
					alignRhymes(this.rootRef.current as HTMLElement);
				});
			} catch (e) { }
		}
	}

	componentDidUpdate() {
		if (this.props.alignRhymes) {
			alignRhymes(this.rootRef.current as HTMLElement);
		}
	}

	render() {
		let { classes, children, locale, fontSize, useFontSize } = this.props;

		return children ? (
			<div className={clsx(classes.root, 'locale-' + (locale ?? 'en'))} ref={this.rootRef}>
				{useFontSize !== false && fontSize !== 100 ? (
					<div style={{ fontSize: fontSize / 100.0 + 'em' }}>{children}</div>
				) : (
						children
					)}
			</div>
		) : (
				<React.Fragment>
					<Skeleton animation='wave' />
					<Skeleton animation='wave' />
					<Skeleton animation='wave' />
					<Skeleton animation='wave' width='60%' />
				</React.Fragment>
			);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		fontSize: state.uxState.settings.font ?? 100,
	};
}

export default connect(mapStateToProps)(withWidth()(withStyles(styles)(TextContent)));
