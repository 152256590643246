import { FireStoreModule } from 'fb';
import firebase from 'fb/index';
import 'firebase/firestore';
import { updateAnumati } from 'store/data/anumati/actions';

export class AnumatiFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'anumati';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: firebase.firestore.Firestore) => db.collection('anumati');
	public getChangeProcessAction = (changes) => updateAnumati(changes);
}
