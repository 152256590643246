import { IAnandApp } from './IAnandApp';
import { IOSAnandApp } from './IOSAnandApp';
import { JSAnandApp } from './JSAnandApp';

declare var webkit: any;
declare var AnandApp: any;

declare global {
	interface Window {
		AnandApp: any;
	}
}

let App: IAnandApp;

let isIOS = typeof webkit !== 'undefined' && webkit.messageHandlers && webkit.messageHandlers.AnandApp;
if (isIOS) {
	App = new IOSAnandApp();
} else if (window.AnandApp) {
	App = window.AnandApp as IAnandApp;
} else {
	App = new JSAnandApp();
}
export default App;
