import { Card, Collapse, FormControl, MenuItem, Select, Switch, TextField, withStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { Theme } from "@material-ui/core/styles";
import { DialogContext } from "components/pages/DialogPage";
import { ssnDefaultExpiryKey, ssnDefaultPublishKey, ssnStartingCardKey } from "config";
import { Icons } from "config/icons";
import { setRecord } from "fb";
import firebase from "fb/initconfig";
import { isAdmin } from "helpers";
import AnandDate from "helpers/AnandDate";
import React from "react";
import { connect } from "react-redux";
import { updateUserSettings } from "store/ux/actions";
import { ApplicationState, UserData, UserRecord } from "types";
import { UserPicInfo } from "./UserPicInfo";

const styles = (theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column" as "column",
        backgroundColor: theme.palette.grey[200],
        minHeight: "100%",
    },
    card: {
        margin: theme.spacing(1),
    },
    list: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    text: {
        marginRight: "12px",
    },
    inline: {
        display: "inline",
    },
    nested: {
        padding: theme.spacing(0.25),
        paddingLeft: theme.spacing(10),
        marginRight: theme.spacing(8),
    },
    slider: {
        margin: "auto",
        marginTop: theme.spacing(-2),
        marginBottom: theme.spacing(3),
        width: "85%",
        display: "block",

        "& .MuiSlider-markLabel": {
            fontSize: "1.2rem",
        },
    },
});

interface Props {
    user?: firebase.User;
    userData?: UserData;
    settings: any;
    updateUserSettings: (settings: any) => void;
    classes: any;
    ssnStartingCardValue?: number;
    ssnDefaultPublishValue?: firebase.firestore.Timestamp;
    ssnDefaultExpireValue?: firebase.firestore.Timestamp;
}

class Component extends React.Component<
    Props,
    {
        checked: any;
        ssnStartingCardValue: number;
        ssnDefaultPublishValue: string;
        ssnDefaultExpireValue: string;
        allUsers: UserRecord[];
        expanded: { [email: string]: boolean };
    }
> {
    constructor(props) {
        super(props);

        this.state = {
            checked: props.settings ?? {},
            ssnStartingCardValue: props.ssnStartingCardValue ?? 0,
            ssnDefaultPublishValue: props.ssnDefaultPublishValue ? new AnandDate(props.ssnDefaultPublishValue).format("YYYY-MM-DD hh:mm:ss A") : "",
            ssnDefaultExpireValue: props.ssnDefaultExpireValue ? new AnandDate(props.ssnDefaultExpireValue).format("YYYY-MM-DD hh:mm:ss A") : "",
            allUsers: [],
            expanded: {},
        };
    }

    private handleToggle = (key: string, value?: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
        let { checked } = this.state;

        const newChecked = { ...checked, [key]: value ?? event.target.checked };

        this.setState({ checked: newChecked });

        this.props.updateUserSettings(newChecked);
    };

    private onUpdateUserData = (uid: string, properties: Partial<UserRecord>) => async (event) => {
        this.setState({ allUsers: this.state.allUsers.map((user) => (user.uid === uid ? { ...user, ...properties } : user)) });

        var updateUserAPI = firebase.app().functions("asia-east2").httpsCallable("updateUser");
        let response = await updateUserAPI({ uid, properties });
        console.log(response);

        await this.fetchAllUsers();
    };
    private onUpdateUserClaims = async (uid: string, customClaims: { [key: string]: any }) => {
        this.setState({ allUsers: this.state.allUsers.map((user) => (user.uid === uid ? { ...user, customClaims: customClaims } : user)) });

        var updateUserAPI = firebase.app().functions("asia-east2").httpsCallable("updateUser");
        let response = await updateUserAPI({ uid, customClaims });
        console.log(response);

        await this.fetchAllUsers();
    };

    private handleExpandClick = (userEmail: string) => (event) => {
        this.setState({ expanded: { ...this.state.expanded, [userEmail]: !this.state.expanded[userEmail] } });
    };

    private async fetchAllUsers() {
        if (isAdmin(this.props.userData)) {
            var listUsersAPI = firebase.app().functions("asia-east2").httpsCallable("listUsers");
            let allUsers = await listUsersAPI();
            console.log(allUsers);
            this.setState({
                allUsers: allUsers.data.users.sort((a, b) =>
                    a.disabled !== b.disabled ? a.disabled - b.disabled : a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
                ),
            });
        }
    }

    async componentDidMount() {
        await this.fetchAllUsers();
    }

    private userPermissionList = [
        { mjbj: "MjBj Records" },
        { anumati: "SSN" },
        { anumati_print: "SSN Print" },
        { anumati_sms: "SSN SMS" },
        { anumati_checkins: "SSN Checkins" },
        { kutiya: "Satsang Bhawan" },
        { trustee: "Trustee Records" },
        { moment: "Holy Moments" },
        { book: "Books Records" },
        { sangat: "Sangat Records" },
        { dham: "Dham Records" },
        { idcard: "ID Cards" },
        { category: "Categories" },
        { pincode: "Pincodes" },
        { rate: "Rates" },
        { through: "Through" },
        { subscriber: "Subscribers" },
    ];

    render() {
        let { classes, userData } = this.props;
        let { checked, ssnStartingCardValue, ssnDefaultPublishValue, ssnDefaultExpireValue, allUsers, expanded } = this.state;

        return (
            <div className={classes.root}>
                <UserPicInfo />
                <div style={{ marginTop: 24, marginBottom: 32 }}>
                    {isAdmin(userData) ? (
                        <Card className={classes.card}>
                            <List subheader={<ListSubheader>Admin Settings</ListSubheader>} className={classes.list}>
                                <ListItem>
                                    <ListItemIcon>{Icons.VisibilityOff}</ListItemIcon>
                                    <ListItemText
                                        className={classes.text}
                                        primary='Show All Records'
                                        secondary='Show all records including deleted and expired'
                                    />
                                    <ListItemSecondaryAction>
                                        <Switch
                                            edge='end'
                                            color='primary'
                                            onChange={this.handleToggle("admin.records")}
                                            checked={checked["admin.records"] ?? false}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>{Icons.Settings}</ListItemIcon>
                                    <ListItemText className={classes.text} primary='SSN Start Card No.' secondary='Start from this SSN Card' />
                                    <ListItemSecondaryAction>
                                        <TextField
                                            style={{ maxWidth: 80 }}
                                            type='number'
                                            variant='standard'
                                            value={ssnStartingCardValue}
                                            onChange={(event) => {
                                                let value = event.target.value;
                                                this.setState({
                                                    ssnStartingCardValue: parseInt(value),
                                                });
                                            }}
                                            onKeyUp={(event) => {
                                                if (event.key === "Enter") {
                                                    setRecord("anumati", ssnStartingCardKey, {
                                                        value: ssnStartingCardValue,
                                                    });
                                                }
                                            }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>{Icons.Settings}</ListItemIcon>
                                    <ListItemText className={classes.text} primary='SSN Default Publish Time' secondary='Default Publish Date Time' />
                                    <ListItemSecondaryAction>
                                        <TextField
                                            style={{ maxWidth: 140 }}
                                            type='text'
                                            variant='standard'
                                            value={ssnDefaultPublishValue}
                                            inputProps={{ style: { fontSize: 13 } }}
                                            onChange={(event) => {
                                                let value = event.target.value;
                                                this.setState({
                                                    ssnDefaultPublishValue: value,
                                                });
                                            }}
                                            onKeyUp={(event) => {
                                                if (event.key === "Enter") {
                                                    try {
                                                        let date = new Date(ssnDefaultPublishValue) as any;
                                                        setRecord("anumati", ssnDefaultPublishKey, {
                                                            value:
                                                                date == "Invalid Date"
                                                                    ? firebase.firestore.FieldValue.delete()
                                                                    : firebase.firestore.Timestamp.fromDate(date),
                                                        });
                                                    } catch (e) {
                                                        alert(e.message);
                                                    }
                                                }
                                            }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>{Icons.Settings}</ListItemIcon>
                                    <ListItemText className={classes.text} primary='SSN Default Expiry' secondary='Default Expire Date Time' />
                                    <ListItemSecondaryAction>
                                        <TextField
                                            style={{ maxWidth: 140 }}
                                            type='text'
                                            variant='standard'
                                            value={ssnDefaultExpireValue}
                                            inputProps={{ style: { fontSize: 13 } }}
                                            onChange={(event) => {
                                                let value = event.target.value;
                                                this.setState({
                                                    ssnDefaultExpireValue: value,
                                                });
                                            }}
                                            onKeyUp={(event) => {
                                                if (event.key === "Enter") {
                                                    try {
                                                        let date = new Date(ssnDefaultExpireValue) as any;
                                                        setRecord("anumati", ssnDefaultExpiryKey, {
                                                            value:
                                                                date == "Invalid Date"
                                                                    ? firebase.firestore.FieldValue.delete()
                                                                    : firebase.firestore.Timestamp.fromDate(date),
                                                        });
                                                    } catch (e) {
                                                        alert(e.message);
                                                    }
                                                }
                                            }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Card>
                    ) : null}
                    {!!allUsers.length && (
                        <Card className={classes.card}>
                            <List subheader={<ListSubheader>Users Data</ListSubheader>} className={classes.list}>
                                {allUsers.map((user) => (
                                    <React.Fragment key={user.email}>
                                        <ListItem button onClick={this.handleExpandClick(user.email)}>
                                            <ListItemIcon>
                                                {user.disabled
                                                    ? Icons.PersonOutline
                                                    : user.customClaims && user.customClaims["admin"]
                                                    ? Icons.VerifiedUser
                                                    : Icons.Person}
                                            </ListItemIcon>
                                            <ListItemText className={classes.text} primary={user.displayName} secondary={user.email} />
                                            {expanded[user.email] ? Icons.ExpandLess : Icons.ExpandMore}
                                        </ListItem>
                                        <Collapse key={"collapse" + user.email} in={expanded[user.email]} timeout='auto' unmountOnExit>
                                            <ListItem className={classes.nested}>
                                                <ListItemText className={classes.text} primary={user.disabled ? "Disabled" : "Enabled"} secondary={user.uid} />
                                                <ListItemSecondaryAction>
                                                    <Switch
                                                        edge='end'
                                                        color='primary'
                                                        disabled={user.email === userData.email}
                                                        onChange={this.onUpdateUserData(user.uid, { disabled: !user.disabled })}
                                                        checked={!user.disabled}
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Collapse key={"collapse-details" + user.email} in={!user.disabled} timeout='auto' unmountOnExit>
                                                <ListItem className={classes.nested}>
                                                    <ListItemText className={classes.text} secondary={"Administrator"} />
                                                    <ListItemSecondaryAction>
                                                        <Switch
                                                            edge='end'
                                                            color='primary'
                                                            disabled={user.email === userData.email}
                                                            onChange={() => {
                                                                let currentClaims = { ...user.customClaims };

                                                                if (currentClaims["admin"]) {
                                                                    delete currentClaims["admin"];
                                                                } else {
                                                                    currentClaims["admin"] = true;
                                                                }
                                                                this.onUpdateUserClaims(user.uid, currentClaims);
                                                            }}
                                                            checked={(user.customClaims && user.customClaims["admin"]) ?? false}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <List component='div' disablePadding>
                                                    {this.userPermissionList.map((option) => {
                                                        let key = Object.keys(option)[0];
                                                        let display = Object.values(option)[0];
                                                        let customClaim = user.customClaims && user.customClaims[key] ? user.customClaims[key] : "na";

                                                        return (
                                                            <ListItem className={classes.nested} key={user.email + key}>
                                                                {/* <ListItemIcon style={{ minWidth: 34 }}>{iconMap[option]}</ListItemIcon> */}
                                                                <ListItemText className={classes.text} secondary={display} />
                                                                <ListItemSecondaryAction>
                                                                    <FormControl variant='standard' style={{ margin: 1, minWidth: 40 }}>
                                                                        <Select
                                                                            value={customClaim}
                                                                            onChange={(event) => {
                                                                                let value: string = event.target.value as string;
                                                                                let currentClaims = { ...user.customClaims };
                                                                                if (value === "na") {
                                                                                    delete currentClaims[key];
                                                                                } else {
                                                                                    currentClaims[key] = value;
                                                                                }

                                                                                this.onUpdateUserClaims(user.uid, currentClaims);
                                                                            }}
                                                                        >
                                                                            <MenuItem value='edit'>Edit</MenuItem>
                                                                            <MenuItem value='read'>Read</MenuItem>
                                                                            <MenuItem value='view'>View</MenuItem>
                                                                            {key === "mjbj" && <MenuItem value='lview'>Limited View</MenuItem>}
                                                                            <MenuItem value='na'>Unauthorized</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                            </Collapse>
                                        </Collapse>
                                    </React.Fragment>
                                ))}
                            </List>
                        </Card>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState) {
    return {
        // locale: state.uxState.locale,
        user: state.dataState.userStore.user,
        userData: state.dataState.userStore.userData,
        settings: state.uxState.settings,
        ssnStartingCardValue: (state.dataState.anumati.byId[ssnStartingCardKey] as any)?.value,
        ssnDefaultPublishValue: (state.dataState.anumati.byId[ssnDefaultPublishKey] as any)?.value,
        ssnDefaultExpireValue: (state.dataState.anumati.byId[ssnDefaultExpiryKey] as any)?.value,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateUserSettings: (settings: string[]) => {
            dispatch(updateUserSettings(settings));
        },
        // changeLocale: async (locale: LocaleType) => {
        // 	dispatch(changeLocale(locale));
        // },
    };
}

Component.contextType = DialogContext;

export const UserSettings = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));
