import UserOptions from 'components/profile/UserOptions';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DialogPage from './DialogPage';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function UserOptionsPage(props) {
	let { isModal } = props;
	let { key } = useParams();
	let query = useQuery();

	return (
		<React.Fragment>
			{/* {!isModal ? <HomePage /> : null} */}
			<DialogPage title='Account' transition={false} maxWidth='xs' dark goBackOnClose={!!isModal} goBackTo='/'>
				<UserOptions initScreen={key || query.get('screen')} />
			</DialogPage>
		</React.Fragment>
	);
}
