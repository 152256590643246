import { Action } from 'redux';
import {
	DownloadProgress,
	FBArticle,
} from 'types';

export const ADD_OFFLINE_AUDIO = '@@offline/ADD_OFFLINE_AUDIO';
export type ADD_OFFLINE_AUDIO = typeof ADD_OFFLINE_AUDIO;

export interface AddOfflineAudio extends Action {
	type: ADD_OFFLINE_AUDIO;
	article: FBArticle;
	forExport: boolean;
}

export const addOfflineAudio = (article: FBArticle, forExport: boolean): AddOfflineAudio => {
	return {
		type: ADD_OFFLINE_AUDIO,
		article,
		forExport: forExport,
	};
};

export const REMOVE_OFFLINE_AUDIO = '@@offline/REMOVE_OFFLINE_AUDIO';
export type REMOVE_OFFLINE_AUDIO = typeof REMOVE_OFFLINE_AUDIO;

export interface RemoveOfflineAudio extends Action {
	type: REMOVE_OFFLINE_AUDIO;
	articleId: string;
	localOnly: boolean;
}

export const removeOfflineAudio = (articleId: string, localOnly: boolean): RemoveOfflineAudio => {
	return {
		type: REMOVE_OFFLINE_AUDIO,
		articleId,
		localOnly
	};
};

export const UPDATE_DOWNLOAD_STATE = '@@offline/UPDATE_DOWNLOAD_STATE';
export type UPDATE_DOWNLOAD_STATE = typeof UPDATE_DOWNLOAD_STATE;

export interface UpdateDownloadState extends Action {
	type: UPDATE_DOWNLOAD_STATE;
	articleId: string;
	progress: DownloadProgress | null;
}

export const updateDownloadState = (articleId: string, progress: DownloadProgress | null): UpdateDownloadState => {
	return {
		type: UPDATE_DOWNLOAD_STATE,
		articleId,
		progress,
	};
};
