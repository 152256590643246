import { FireStoreModule } from 'fb';
import firebase from 'fb/index';
import 'firebase/firestore';
import { updateBook } from 'store/data/book/actions';

export class BookFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'book';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: firebase.firestore.Firestore) => db.collection('book');
	public getChangeProcessAction = (changes) => updateBook(changes);
}
