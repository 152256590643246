import RecordList from 'components/RecordList';
import { Records, refreshRecords } from 'core/anand.records';
import { timeout } from 'helpers';
import React from 'react';
import { connect, ConnectedComponent } from 'react-redux';
import { ApplicationState, FBData, UserData } from 'types';

interface Props {
	user?: firebase.default.User;
	userData?: UserData;
	records: FBData[];
	html: string;
	RecordsHandler: any;
	RecordTile:
		| ((props: any) => JSX.Element | null)
		| ConnectedComponent<(props: any) => JSX.Element | null, any | null>;
	style?: any;
	handlerProps?: any;
	getItemSize?: (index: number, listRef: React.RefObject<any>) => number;
	getItemEstimatedSize?: (index: number, listRef: React.RefObject<any>) => number;
}

interface State {
	records: FBData[];
	scrollOffset: number;
}

class Component extends React.Component<Props, State> {
	private listRef = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			records: [],
			scrollOffset: 0,
		};
	}

	private updateRecordsState = async (records, reposition) => {
		let scrollOffset = reposition ? (this.listRef.current as any).scrollOffset : 0;
		await new Promise((resolve) =>
			this.setState({ records, scrollOffset }, async () => {
				resolve(null);
			})
		);
	};

	private repositionRecords = (index: number) => {
		(this.listRef.current as any).scrollToItem(index);
	};

	componentDidMount = async () => {
		refreshRecords();
		await timeout(100);
		this.props.RecordsHandler.initModule(
			{
				user: this.props.user,
				userData: this.props.userData,
				props: { ...this.props.handlerProps, repositionRecords: this.repositionRecords },
			},
			this.updateRecordsState
		);
	};

	componentWillReceiveProps = async (nextProps) => {
		Records.refreshRecords(undefined, undefined, undefined, true);
	};

	private getItemSize = (index) => {
		let { getItemSize, getItemEstimatedSize } = this.props;
		if (getItemSize) {
			return getItemSize(index, this.listRef);
		}

		let record = this.state.records[index];
		let height = 0;
		if ((record as any).height) {
			height = (record as any).height;
		} else {
			height = (getItemEstimatedSize && getItemEstimatedSize(index, this.listRef)) ?? 280;
			// setTimeout(() => {
			// 	(this.listRef.current as any).resetAfterIndex(index);
			// }, 10);
		}
		return height + 15;
	};

	render() {
		let { records } = this.state;

		return (
			<React.Fragment>
				<div dangerouslySetInnerHTML={{ __html: this.props.html }}></div>
				<div style={{ height: '100%', ...this.props.style }}>
					<RecordList
						ref={this.listRef}
						records={records}
						fetching={!Records.populateDetailTile}
						RecordTile={this.props.RecordTile}
						getItemSize={this.getItemSize}
						initialScrollOffset={this.state.scrollOffset}
						RecordTileProps={this.props.handlerProps?.RecordTileProps}
					></RecordList>
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		user: state.dataState.userStore.user,
		userData: state.dataState.userStore.userData,
	};
}

export const IntegratedRecordsList = connect(mapStateToProps)(Component);
