import { combineReducers } from 'redux';
import { SangatRecord, SangatStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateSangat, UpdateSangatFiltered, UPDATE_SANGAT, UPDATE_SANGAT_FILTERED } from './actions';

export const sangatByIdReducer = (state = {}, action: UpdateSangat) => {
	switch (action.type) {
		case UPDATE_SANGAT:
			return updateData(state, action.changes, 'sangat');
		default:
			return state;
	}
};

const filteredSangatReducer = (state: SangatRecord[] = [], action: UpdateSangatFiltered) => {
	switch (action.type) {
		case UPDATE_SANGAT_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const sangatReducer = combineReducers<SangatStore>({
	byId: sangatByIdReducer,
	filtered: filteredSangatReducer,
});
