import { IntegratedRecordsList } from 'components/IntegratedRecordsList';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, MomentRecord } from 'types';
import { RecordsHandler } from './RecordsHandler';
import { ViewTile } from './viewTile';

let CSS = `
#selection-dropdown {
    display: none !important;
}

.holy-moments-record .row {
    padding-top: 5px;
    padding-bottom: 5px;
}

.holy-moments-record .row:last-of-type {
    border-bottom: none;
}

#record_count {
    margin-top: 12px;
}

.moment-image {
    border: 1px solid lightgrey;
}

.moment-desc {
    width: 100%;
}

.title {
    text-align: right;
    font-weight: bold;
    font-size: 0.9em;
}
`;

const html = `
<style>
	${CSS}
</style>

<div id="page-records">
    <div class="records">
        <div class="filtered-records">
        </div>
    </div>
</div>
`;

interface Props {
	records: MomentRecord[];
}

class Component extends React.Component<Props> {
	render() {
		return (
			<React.Fragment>
				<IntegratedRecordsList
					records={this.props.records}
					RecordTile={ViewTile}
					html={html}
					RecordsHandler={RecordsHandler}
				/>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		records: state.dataState.moment.filtered,
	};
}

export const Moment = connect(mapStateToProps)(Component);
