import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { PincodeRecord } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_PINCODE = '@@mjbj/UPDATE_PINCODE';
export type UPDATE_PINCODE = typeof UPDATE_PINCODE;

export interface UpdatePincode extends Action {
	type: UPDATE_PINCODE;
	changes: FBChanges;
}

export function updatePincode(changes: FBChanges): UpdatePincode {
	return {
		type: UPDATE_PINCODE,
		changes,
	};
}

export const UPDATE_PINCODE_FILTERED = UPDATE_PINCODE + '_FILTERED';
export type UPDATE_PINCODE_FILTERED = typeof UPDATE_PINCODE_FILTERED;

export interface UpdatePincodeFiltered extends Action {
	type: UPDATE_PINCODE_FILTERED;
	payload: PincodeRecord[];
}
