import { IntegratedRecordsList } from 'components/IntegratedRecordsList';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, TrusteeRecord } from 'types';
import { TrusteeRecords } from './anand.trusteeRecords';
import { TrusteeViewTile } from './trusteeViewTile';

let trusteeCSS = `
#selection-dropdown {
    display: block !important;
}

#record_count {
	margin-top: 0;
}

#trustee-detail-record-form {
	display: none;
}

#trustee-add-record {
	display: none;	
}

.tile.trustee-record.detail {
    padding-top: 15px;
}

.tile.trustee-record .main-details {
    padding-right: 30px;
}

.tile.trustee-record .main-details .title {
	text-align: right;
	padding: 0;
	padding-bottom: 25px;
    font-weight: bold;
    font-size: 0.9em;
}

form.tile.trustee-record .main-details .value {
	padding-right: 0;
}

.tile.trustee-record input {
	width: 100%;
	background-color: white!important;
    border: none;
    border-bottom: 1px solid darkgray;
}

.tile.trustee-record input:focus {
    outline: none;    
}

.tile.trustee-record .value select {
	width: 100%;
}

.tile.trustee-record .self-image {
	width: 75%;
}

.tile.trustee-record .info-tile {
	border: 1px solid darkgray;
    margin-bottom: 5px;
}

.tile.trustee-record .info-tile .info-title {
	text-align: center;
}

.tile.trustee-record .info-tile select {
    width: 100%;
}

.tile.trustee-record .info-tile .value {
	text-align: center;
	width: 100%;
    border-bottom: none!important;
}

form.tile.trustee-record .comments {
	width: 100%;
	resize: none;
    height: 9em;
}

.tile.trustee-record .comments {
    border: 1px solid darkgray;
    padding: 10px;
    overflow: auto;
    max-height: 6em;
}

.tile.trustee-record .comments-title {
    border: 1px solid darkgray;
    border-bottom: 0;
    text-align: center;
}

div.tile.trustee-record .main-details .value {
    border-bottom: 1px solid lightgrey;
    min-height: 1.5em;
    padding-right: 0;
}

.lb {
    border-bottom: 1px solid lightgrey;
}

.contact-value {
	text-align: left;
}

.contact-detail .title {
	padding-bottom: 5px !important;
}

#contact-type-value {
	display: none;
}

.multi-field-row {
	padding-bottom: 5px;
	margin-bottom: 5px;
	border-bottom: 1px solid lightgrey;
}

.contact-type {
	font-weight: bold;
	text-align: right;
	padding-right: 0;
}

.contact-value {
	text-align: left;
}

.contact-detail, .trustee-detail {
	border: 1px solid lightgrey;
	margin-top: 10px;
}

.contact-detail .title, .trustee-detail .title {
	padding-bottom: 3px !important;
	padding-top: 3px !important;
	text-align: center !important;
	border-bottom: 1px lightgrey solid;
}

#trustee-row, #contact-type-value {
	display: none;
}

.multi-field-row {
	border-bottom: 1px solid lightgrey;
	padding-top: 3px;
	padding-bottom: 3px;
}

.multi-field-row:nth-last-child(1) {
	border-bottom: none !important;
}

@media (min-width: 992px) {
	.col-md-6.multi-field-row:nth-last-child(2):nth-child(odd) {
		border-bottom: none !important;
	}
	.trustee-detail {
		margin-top: 0;
	}
}

.strong {
	font-weight: bold;
	text-align: right;
	padding-right: 0 !important;
}

.odd {
	border: 1px solid grey;
	background-color: bisque !important;
}

.even {
	border: 1px solid grey;
	background-color: lavender !important;
}

.odd.first {
	padding-top: 10px;
	border-top: 2px solid grey;
}

.even.first {
	padding-top: 10px;
	border-top: 2px solid grey;	
}

.odd.last {
	padding-bottom: 10px;
	border-bottom: 2px solid grey;
}

.even.last {
	padding-bottom: 10px;	
	border-bottom: 2px solid grey;
}

.current-trustee .self-detail .value {
	background-color: yellow;
}
`;

const html = `
<style>
	${trusteeCSS}
</style>

<div id="page-records">
    <form id="trustee-add-record" class="tile trustee-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
        <div class="row">
            <div class="col-xs-12 col-md-2">
                <div class="block image-upload-container">
                    <img class="self-image button" />
                    <input type="file" name="image" accept="image/*">
                </div>
            </div>
            <div class="col-xs-12 col-md-4 main-details">
                <div class="block">
                    <div class="row self-detail">
                        <div class="title col-xs-3 col-md-3">
                            Name:
                        </div>
                        <div class="col-xs-2 col-md-3">
                            <select name="title">
                                <option value="Mahatma">Mh.</option>
                                <option value="Swami">Swami</option>
                                <option value="Bh.">Bh.</option>
                                <option value="Bai">Bai</option>
                            </select>
                        </div>
                        <div class="value col-xs-7 col-md-6">
                            <input type="text" name="name" value="" required placeholder="Full Name*"/>
                        </div>
                    </div>
                    <div class="row so-detail">
                        <div class="title col-xs-3 col-md-3">
                            S/o:
                        </div>
                        <div class="value col-xs-9 col-md-9">
                            <input type="text" name="so_name" value="" placeholder="S/o"/>
                        </div>
                    </div>
                    <div class="row holy-name-detail">
                        <div class="title col-xs-3 col-md-3">
                            Holy Name:
                        </div>
                        <div class="col-xs-2 col-md-3">
                            <select name="holy_title">
								<option value=""></option>
                                <option value="Mahatma">Mh.</option>
                                <option value="Swami">Swami</option>
                                <option value="Bai">Bai</option>
                            </select>
                        </div>
                        <div class="value col-xs-7 col-md-6">
                            <input type="text" name="holy_name" value="" placeholder="Holy Name"/>
                        </div>
                    </div>
                    <div class="row address-detail">
                        <div class="title col-xs-3 col-md-3">
                            Address:
                        </div>
                        <div class="value col-xs-9 col-md-9">
                            <input type="text" name="address" value="" placeholder="Address"/>
                        </div>
                    </div>
                    <div class="row pan-detail">
                        <div class="title col-xs-3 col-md-3">
                            Pan:
                        </div>
                        <div class="value col-xs-9 col-md-9">
                            <input type="text" name="pan" value="" placeholder="Pan No."/>
                        </div>
                    </div>
                    <div class="row comments-detail">
                        <div class="title col-xs-3 col-md-3">
                            Comment:
                        </div>
                        <div class="value col-xs-9 col-md-9">
                            <input type="text" name="comments" value="" placeholder="Comment"/>
                        </div>
                    </div>
                    <div class="row contact-detail">
                        <div class="title col-xs-12">
                            Contacts:
                        </div>
						<div id="contact-row" class="multi-field-row hidden col-xs-12 col-md-12">
							<div class="row">
								<div class="value col-xs-4 col-md-4">
									<select name="contact_type[]">
										<option value=""></option>
										<option value="Mobile">Mobile</option>
										<option value="Landline">Landline</option>
										<option value="Email">E-mail</option>
									</select>
								</div>
								<div class="value col-xs-7 col-md-7">
									<input type="text"  name="contact[]" value="" placeholder="Contact"/>
								</div>
								<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="contact-row"><i class="glyphicon glyphicon-plus"></i></button>
							</div>
						</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-6 main-details">
                <div class="block">
					<div class="row trustee-detail">
						<div class="title col-xs-12">
							Trusts:
						</div>
						<div id="trust-row" class="multi-field-row hidden col-xs-12 col-md-6">
							<div class="row">
								<div class="col-xs-11">
									<div class="row">
										<div class="value col-xs-12">
											<input type="text" class="typeahead" name="trust_name[]" value="" placeholder="Trust Name*" />
										</div>
										<div class="value col-xs-12">
											<input type="text" name="designation[]" value="" placeholder="Designation"/>
										</div>
										<div class="value col-xs-12">
											<input type="number" name="serial_no[]" value="" placeholder="Serial*"/>
										</div>
										<div class="value col-xs-12">
											<input type="text" class="date value" name="from_date[]" value="" placeholder="From Date*"/>
										</div>
										<div class="value col-xs-12">
											<input type="text" class="date value" name="to_date[]" value="" placeholder="To Date"/>
										</div>
									</div>
								</div>
								<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="trust-row"><i class="glyphicon glyphicon-plus"></i></button>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    </form>
    <div class="records">
        <div id="trustee-brief-record" class="tile trustee-record brief" style="display:none">
            <div class="row">
                <div class="col-xs-2 no-r-padding">
                    <img class="self-image" />           						
                </div>
                <div class="col-xs-10">
                    <div class="row self-detail">
                        <div class="title col-xs-2 col-md-3 no-padding align-right">

                        </div>
                        <div class="value col-xs-10 col-md-9">

                        </div>
                    </div>           						
                    <div class="row address-detail">
                        <div class="title col-xs-2 col-md-3 no-padding align-right">
                            Address:
                        </div>
                        <div class="value col-xs-10 col-md-9">

                        </div>
                    </div>
                </div>
            </div>
            <div class="record-id">
                <span></span>
            </div>
        </div>
        <form id="trustee-detail-record-form" class="tile trustee-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
            <div class="record-id">
                <span></span>
            </div>
			<div class="row">
				<div class="col-xs-12 col-md-2">
					<div class="block image-upload-container">
						<img class="self-image button" />
						<input type="file" name="image" accept="image/*">
					</div>
				</div>
	            <div class="col-xs-12 col-md-4 main-details">
	                <div class="block">
	                    <div class="row self-detail">
							<div class="title col-xs-3 col-md-3">
								Name:
							</div>
							<div class="col-xs-2 col-md-3">
								<select name="title">
									<option value="Mahatma">Mh.</option>
									<option value="Swami">Swami</option>
									<option value="Bh.">Bh.</option>
									<option value="Bai">Bai</option>
								</select>
							</div>
							<div class="value col-xs-7 col-md-6">
								<input type="text"  name="name" value="" required placeholder="Full Name*"/>
							</div>
						</div>
						<div class="row so-detail">
							<div class="title col-xs-3 col-md-3">
								S/o:
							</div>
							<div class="value col-xs-9 col-md-9">
								<input type="text" name="so_name" value="" placeholder="S/o"/>
							</div>
						</div>
						<div class="row holy-name-detail">
							<div class="title col-xs-3 col-md-3">
								Holy Name:
							</div>
							<div class="col-xs-2 col-md-3">
								<select name="holy_title">
									<option value=""></option>
									<option value="Mahatma">Mh.</option>
									<option value="Swami">Swami</option>
									<option value="Bai">Bai</option>
								</select>
							</div>
							<div class="value col-xs-7 col-md-6">
								<input type="text" name="holy_name" value="" placeholder="Holy Name"/>
							</div>
						</div>
	                    <div class="row address-detail">
							<div class="title col-xs-3 col-md-3">
								Address:
							</div>
							<div class="value col-xs-9 col-md-9">
								<input type="text"  name="address" value="" placeholder="Address"/>
							</div>
						</div>
	                    <div class="row pan-detail">
							<div class="title col-xs-3 col-md-3">
								Pan:
							</div>
							<div class="value col-xs-9 col-md-9">
								<input type="text"  name="pan" value="" placeholder="Pan No."/>
							</div>
						</div>
						<div class="row comments-detail">
							<div class="title col-xs-3 col-md-3">
								Comment:
							</div>
							<div class="value col-xs-9 col-md-9">
								<input type="text" name="comments" value="" placeholder="Comment"/>
							</div>
						</div>
						<div class="row contact-detail">
							<div class="title col-xs-12">
								Contacts:
							</div>
							<div id="contact-row" class="multi-field-row hidden col-xs-12 col-md-12">
								<div class="row">
									<div class="value col-xs-4 col-md-4">
										<select name="contact_type[]">
											<option value=""></option>
											<option value="Mobile">Mobile</option>
											<option value="Landline">Landline</option>
											<option value="Email">E-mail</option>
										</select>
									</div>
									<div class="value col-xs-7 col-md-7">
										<input type="text"  name="contact[]" value="" placeholder="Contact"/>
									</div>
									<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="contact-row"><i class="glyphicon glyphicon-plus"></i></button>
								</div>
							</div>
						</div>
	                </div>
		        </div>
				<div class="col-xs-12 col-md-6 main-details">
					<div class="block">
						<div class="row trustee-detail">
							<div class="title col-xs-12">
								Trusts:
							</div>
							<div id="trust-row-edit" class="multi-field-row hidden col-xs-12 col-md-6">
								<div class="row">
									<div class="col-xs-11">
										<div class="row">
											<input type="hidden" name="trust_trustees_ids[]" value=""/>
											<div class="value col-xs-12">
												<input type="text" class="typeahead" name="trust_name[]" value="" placeholder="Trust Name*" />
											</div>
											<div class="value col-xs-12">
												<input type="text" name="designation[]" value="" placeholder="Designation"/>
											</div>
											<div class="value col-xs-12">
												<input type="number" name="serial_no[]" value="" placeholder="Serial*"/>
											</div>
											<div class="value col-xs-12">
												<input type="text" class="date value" name="from_date[]" value="" placeholder="From Date*"/>
											</div>
											<div class="value col-xs-12">
												<input type="text" class="date value" name="to_date[]" value="" placeholder="To Date"/>
											</div>
										</div>
									</div>
									<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="trust-row-edit"><i class="glyphicon glyphicon-plus"></i></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </form>
        <div id="trustee-detail-record" class="tile trustee-record detail" style="display:none">
            <div class="record-id">
                <span></span>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-2">
                    <div class="block image-upload-container">
                        <img class="self-image button" />
                    </div>
                </div>
                <div class="col-xs-12 col-md-3 main-details">
                    <div class="block">
                        <div class="row self-detail">
                            <div class="title col-xs-3 col-md-3">
                                Name:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row so-detail">
                            <div class="title col-xs-3 col-md-3">
                                S/o:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row holy-name-detail">
                            <div class="title col-xs-3 col-md-3 no-l-padding">
                                HolyName:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row address-detail">
                            <div class="title col-xs-3 col-md-3">
                                Address:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row pan-detail">
                            <div class="title col-xs-3 col-md-3">
                                Pan:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row comments-detail">
                            <div class="title col-xs-3 col-md-3">
                                Comment:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row contact-detail">
                            <div class="title col-xs-12">
                                Contacts:
                            </div>
                            <div id="contact-type-value" class="multi-field-row col-xs-12 col-md-12">
								<div class="row">
									<div class="col-xs-4 contact-type"></div>
									<div class="col-xs-8 contact-value"></div>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-md-7 main-details">
                    <div class="block">
                        <div class="row trustee-detail">
                            <div class="title col-xs-12">
                                Trusts:
                            </div>
							<div id="trustee-row" class="multi-field-row value col-xs-12 col-md-6">
								<div class="col-xs-3 strong no-l-padding">Trust:</div>
								<div class="col-xs-9 trust-name"></div>
								<div class="col-xs-3 strong no-l-padding">Serial:</div>
								<div class="col-xs-9 serial-no"></div>
								<div class="col-xs-3 strong no-l-padding">Designation:</div>
								<div class="col-xs-9 designation"></div>
								<div class="col-xs-3 strong no-l-padding">From:</div>
								<div class="col-xs-9 from-date"></div>
								<div class="col-xs-3 strong no-l-padding to-label">To:</div>
								<div class="col-xs-9 to-date"></div>
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="filtered-records">
        </div>
    </div>
</div>
`;

interface Props {
	records: TrusteeRecord[];
}

class Component extends React.Component<Props> {
	render() {
		return (
			<React.Fragment>
				<IntegratedRecordsList
					records={this.props.records}
					RecordTile={TrusteeViewTile}
					html={html}
					RecordsHandler={TrusteeRecords}
					// getItemSize={() => (isMobile() ? 100 : 320)}
				/>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		records: state.dataState.trustee.filtered,
	};
}

export const Trustee = connect(mapStateToProps)(Component);
