import { LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Theme, withStyles } from '@material-ui/core/styles';
import { DialogContext } from 'components/pages/DialogPage';
import { Formik } from 'formik';
import { getWebAppAssetPath } from 'helpers';
import { Context } from 'helpers/context';
import React from 'react';
import { connect } from 'react-redux';
import { googleSign, login, sendResetPasswordEmail, signupWithEmailLink } from 'services/api/auth';
import { EventType, onEvent } from 'store/temp/actions';
import * as Yup from 'yup';

const styles = (theme: Theme) => ({
	root: {
		padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: 'white',
		fontSize: 12,
		marginBottom: '-24px',
		height: '100%',
	},
	gsignin: {
		marginBottom: theme.spacing(5),
		padding: 0,
		width: '90%',
		alignSelf: 'center',
		border: '1px solid ' + theme.palette.primary.main,

		'& .g-logo': {
			height: 42,
		},

		'& .g-msg': {
			width: 'calc(100% - 42px)',
		},
	},
	or: {
		textAlign: 'center' as 'center',
		backgroundColor: 'white',
		width: '10%',
		marginTop: -9,
		alignSelf: 'center',
	},
	message: {
		marginBottom: theme.spacing(8),
	},
	form: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '90%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	},
	signinbtn: {
		height: 42,
		width: '90%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	},
	newuser: {
		alignSelf: 'center',
		marginTop: theme.spacing(3),
	},
});

export interface SignInProps {
	signin: (email: string, password: string) => Promise<void>;
	googleSign: () => Promise<void>;
	signupWithEmailLink: (email: string) => Promise<void>;
	sendResetPasswordEmail: (email: string) => Promise<void>;
	showMessage: (...payload) => void;
	classes: any;
}

enum SignInMode {
	SignIn,
	SignUp,
	Reset,
}

class SignIn extends React.Component<SignInProps, any> {
	constructor(props) {
		super(props);

		this.state = {
			showPassword: false,
			mode: SignInMode.SignIn,
		};
	}

	render() {
		let { classes, signin, googleSign, signupWithEmailLink, sendResetPasswordEmail, showMessage } = this.props;
		let { showPassword, mode } = this.state;

		const handleClickShowPassword = () => {
			this.setState({ showPassword: !showPassword });
		};

		const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
		};

		return (
			<Formik
				initialValues={{
					email: '',
					password: '',
				}}
				validationSchema={() =>
					Yup.object({
						email: Yup.string().email('Invalid email').required('Required'),
						password: mode === SignInMode.SignIn ? Yup.string().required('Required') : Yup.string(),
					})
				}
				onSubmit={async (values, { setSubmitting }) => {
					try {
						if (mode === SignInMode.SignIn) {
							await signin(values.email, values.password);
							this.context.popScreen();
							return;
						}
						if (mode === SignInMode.SignUp) {
							await signupWithEmailLink(values.email);
							this.context.popScreen();
							showMessage(new Context(), EventType.Information, 'signup', {
								success: true,
								message: 'SignUp email sent to ' + values.email,
							});
							return;
						}
						if (mode === SignInMode.Reset) {
							await sendResetPasswordEmail(values.email);
							this.context.popScreen();
							showMessage(new Context(), EventType.Information, 'password', {
								success: true,
								message: 'Password reset email sent to ' + values.email,
							});
							return;
						}
					} catch (error) {
						if (error.code === 'auth/wrong-password') {
							showMessage(new Context(), EventType.Information, 'signin', {
								success: false,
								message: 'Wrong Password!',
							});
						} else if (error.code === 'auth/too-many-requests') {
							showMessage(new Context(), EventType.Information, 'signin', {
								success: false,
								message: 'Too many requests! Please try after some time!',
							});
						}
					}
					setSubmitting(false);
				}}
			>
				{({ submitForm, isSubmitting, setSubmitting, values }) => (
					<React.Fragment>
						{isSubmitting ? <LinearProgress /> : null}
						<div className={classes.root}>
							{/* <Typography className={classes.message}>
								By verifying your identity, we can provide better cross-device experience and help you
								recover your data when you change your phone:
							</Typography> */}
							<Button
								className={classes.gsignin}
								disabled={isSubmitting}
								onClick={async () => {
									try {
										setSubmitting(true);
										await googleSign();
										setSubmitting(false);

										this.context.popScreen();
									} catch (e) {
										setSubmitting(false);
									}
								}}
								variant='contained'
								color='primary'
							>
								<img src={getWebAppAssetPath('g-logo.png')} className='g-logo' alt='Google Logo' />
								<span className='g-msg'>Connect with Google</span>
							</Button>
							{/* <Divider />
							<div className={classes.or}>or</div>
							<Form className={classes.form} translate='yes'>
								<Field
									component={TextField}
									name='email'
									type='email'
									label='Email'
									autoComplete="off"
									autoCapitalize="off"
									autoCorrect="off"
									spellCheck="false"
									value={values.email}
								/>
								{mode === SignInMode.SignIn ? (
									<Field
										component={TextField}
										type={showPassword ? 'text' : 'password'}
										label='Password'
										name='password'
										autoComplete="off"
										autoCapitalize="off"
										autoCorrect="off"
										spellCheck="false"
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														disabled={isSubmitting}
														tabIndex={-1}>
														{showPassword ? Icons.Visibility : Icons.VisibilityOff}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								) : null}
								<Button
									className={classes.signinbtn}
									variant='contained'
									color='primary'
									type='submit'
									onClick={submitForm}
									disabled={isSubmitting}>
									{mode === SignInMode.SignIn
										? 'Sign In'
										: mode === SignInMode.SignUp
											? 'Sign Up'
											: 'Send Reset Link'}
								</Button>
								{mode === SignInMode.SignIn ? (
									<Button
										className={classes.signinbtn}
										variant='outlined'
										color='primary'
										disabled={isSubmitting}
										onClick={() => {
											if (mode === SignInMode.SignIn) {
												this.setState({ mode: SignInMode.Reset });
											} else {
												this.setState({ mode: SignInMode.SignIn });
											}
										}}>
										{mode === SignInMode.SignIn ? 'Forgot Password' : 'Sign In'}
									</Button>
								) : null}

								<div className={clsx(classes.margin, classes.newuser)}>
									{mode === SignInMode.SignIn
										? 'New User?'
										: mode === SignInMode.SignUp
											? 'Existing User?'
											: 'Remember Password?'}
									<Button
										color='primary'
										disabled={isSubmitting}
										onClick={() => {
											if (mode === SignInMode.SignIn) {
												this.setState({ mode: SignInMode.SignUp });
											} else {
												this.setState({ mode: SignInMode.SignIn });
											}
										}}>
										{mode === SignInMode.SignIn ? 'Sign Up' : 'Sign In'}
									</Button>
								</div> */}
							{/* </Form> */}
						</div>
					</React.Fragment>
				)}
			</Formik>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		signin: async (email: string, password: string) => {
			await dispatch(login({ email, password }));
		},
		googleSign: async () => {
			await dispatch(googleSign());
		},
		signupWithEmailLink: async (email: string) => {
			await dispatch(signupWithEmailLink({ email }));
		},
		sendResetPasswordEmail: async (email: string) => {
			await dispatch(sendResetPasswordEmail({ email }));
		},
		showMessage: (...payload) => {
			dispatch(onEvent(payload[0], payload[1], payload[2], payload[3]));
		},
	};
}

SignIn.contextType = DialogContext;

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(SignIn));
