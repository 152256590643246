import { combineReducers } from 'redux';
import { MomentRecord, MomentStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateMoment, UpdateMomentFiltered, UPDATE_MOMENT, UPDATE_MOMENT_FILTERED } from './actions';

export const momentByIdReducer = (state = {}, action: UpdateMoment) => {
	switch (action.type) {
		case UPDATE_MOMENT:
			return updateData(state, action.changes, 'moment');
		default:
			return state;
	}
};

const filteredMomentReducer = (state: MomentRecord[] = [], action: UpdateMomentFiltered) => {
	switch (action.type) {
		case UPDATE_MOMENT_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const momentReducer = combineReducers<MomentStore>({
	byId: momentByIdReducer,
	filtered: filteredMomentReducer,
});
