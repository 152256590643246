import { HeaderControls, HeaderModule } from 'components/header/anand.header';
import { Records } from 'core/anand.records';
import { utils as Utils } from 'core/anand.utils';
import { addFormHTML, briefTileHTML, detailFormHTML, detailTileHTML } from './viewTile';

export const RecordsHandler = (function ($) {
	var configMap = {};

	var stateMap: any = {
		systemId: 'book',
	};

	function initModule(props?, updateRecordsState?) {
		HeaderModule.initModule([
			HeaderControls.addRecordBtn,
			HeaderControls.selectDropdown,
			HeaderControls.printBtn,
			HeaderControls.sortBtn,
		]);

		Records.projectTitle = 'Books Records';

		Records.getAnySearchFilter = getAnySearchFilter;
		Records.defaultSortOrder = 'title';
		Records.IdentifyingFieldName = 'title';

		Records.sortingFieldsMap = {
			title: 'Title',
			code: 'Code',
			'location, title': 'Location',
			'language, title': 'Language',
		};

		Records.searchFieldsMap = {
			title: 'Title',
			code: 'Code',
			language: 'Language',
			location: 'Location',
			editions: { filter: 'has', text: 'Editions' },
		};

		Records.isSearchDateTypeColumn = function (searchBy) {
			if (searchBy === 'print_date' || searchBy === 'stock_date') {
				return true;
			} else {
				return false;
			}
		};

		Records.printConfigs = {
			lineSpacing: '1.5',
			fontSize: 10,
		};

		Records.printOptions = {
			fieldNames: [
				'S. No.',
				'Title',
				'Code',
				'Pages',
				'Cost',
				'Sale Price',
				'New Price',
				'Edition',
				'Quantity',
				'Date',
				'Press',
				'Yearly Req',
				'Stock',
			],
			fieldKeys: [
				'id',
				'title',
				'code',
				'pages',
				'cost',
				'sale',
				'revised',
				'edition',
				'quantity',
				'date',
				'press',
				'requirement',
				'stock',
			],
			defaultFieldWidths: {
				id: 50,
				title: 250,
				code: 110,
				pages: 50,
				cost: 50,
				sale: 70,
				revised: 70,
				edition: 50,
				quantity: 60,
				date: 70,
				press: 60,
				requirement: 80,
				stock: 80,
			},
			defaultHeader: 'Books Records',
			beforePrintHook: function (data, forExport) {
				for (var i = 0; i < data.length; i++) {
					data[i]['id'] = i + 1 + '.';

					if (data[i]['editions']) {
						var latestEdition = findLatestEdition(data[i]);

						data[i]['date'] = getMY(latestEdition['print_date']);
						data[i]['pages'] = latestEdition['pages'];
						data[i]['cost'] = latestEdition['cost'];
						data[i]['sale'] = latestEdition['sale'];
						data[i]['revised'] = latestEdition['revised'];
						data[i]['edition'] = latestEdition['edition'];
						data[i]['quantity'] = latestEdition['quantity'];
						data[i]['press'] = latestEdition['press'];
					}

					if (data[i]['stock']) {
						var stockInfo = getStockInfo(data[i]);

						var stockHtml = '';
						var newLine = '';
						for (var place in stockInfo['stockObj']) {
							stockHtml += newLine + place + ': ' + stockInfo['stockObj'][place]['qty'];
							newLine = '\n';
						}

						data[i]['stock'] = stockHtml;
					}
				}

				return data;

				function getMY(date) {
					var parts = date.split('-');
					return parts[1] + '/' + parts[0];
				}
			},
		};

		Records.exportOptions = {
			fieldKeys: [
				'id',
				'title',
				'code',
				'requirement',
				'pages',
				'cost',
				'sale',
				'revised',
				'edition',
				'quantity',
				'date',
				'press',
				'stock',
			],
			defaultFileName: 'Books Records',
		};

		Records.dashboardOptions = null;
		Records.hooks.afterHeaderConfigure = function (controlsHeader) {
			controlsHeader.find('.dashboard-btn').hide();
			controlsHeader.find('.reminder-btn').hide();
		};

		Records.detailTileSelector = '#book-detail-record';
		Records.briefTileSelector = '#book-brief-record';
		Records.addTileSelector = '#book-add-record';
		Records.detailFormSelector = '#book-detail-record-form';

		Records.detailTileHTML = detailTileHTML;
		Records.briefTileHTML = briefTileHTML;
		Records.addFormHTML = addFormHTML;
		Records.detailFormHTML = detailFormHTML;

		Records.populateBriefTile = populateBriefTile;
		Records.populateDetailTile = populateDetailTile;
		Records.populateDetailForm = populateDetailForm;

		Records.updateRecordsState = updateRecordsState;

		Records.initModule({ systemId: stateMap.systemId, prepareRecord: prepareRecord, props: props });
	}

	function prepareRecord(record) {
		record['title'] = Utils.upperFirstAll(record['title']);

		Records.prepareMultiFieldForAdd(record, 'editions', [
			'edition',
			'cost',
			'isbn',
			'pages',
			'press',
			'print_date',
			'quantity',
			'revised',
			'sale',
		]);

		Records.prepareMultiFieldForAdd(record, 'stock', ['place', 'qty', 'stock_date']);

		return record;
	}

	function getAnySearchFilter(query) {
		return [
			{ title: { likenocase: query } },
			{ location: { leftnocase: query } },
			{ code: { likenocase: query } },
			{ language: { leftnocase: query } },
			{ editions: { has: query } },
		];
	}

	async function populateBriefTile(sampleTile, record) {
		sampleTile.find('.title-detail .value').html(record['title']);
		sampleTile.find('.location-detail .value').html(record['location']);

		return sampleTile;
	}

	async function populateDetailTile(sampleTile, recordOrig) {
		var record = {};
		for (var key in recordOrig) {
			if (recordOrig[key] == null || recordOrig[key] == '') {
				record[key] = '&nbsp';
			} else {
				record[key] = recordOrig[key];
			}
		}

		sampleTile.find('.main-details .title-detail .value').html(record['title']);
		sampleTile.find('.main-details .code-detail .value').html(record['code']);
		sampleTile.find('.main-details .language-detail .value').html(record['language']);
		sampleTile.find('.main-details .location-detail .value').html(record['location']);
		sampleTile.find('.main-details .requirement-detail .value').html(record['requirement']);

		if (typeof record['stock'] !== 'undefined') {
			var stocks = record['stock'];
			for (var i = 0; i < stocks.length; i++) {
				var stockRow = sampleTile.find('.main-details .stock-detail #stock-value').clone();
				stockRow.attr('id', 'stock-detail' + '-' + i);
				stockRow.find('.stock-place').html(stocks[i]['place']);
				stockRow.find('.stock-qty').html(stocks[i]['qty']);
				setDateField(stockRow.find('.stock-date'), stocks[i]['stock_date'], false);

				stockRow.show();
				sampleTile.find('.main-details .stock-detail').append(stockRow);
			}
		}

		if (typeof record['editions'] !== 'undefined') {
			var editions = record['editions'];
			for (var i = 0; i < editions.length; i++) {
				var editionRow = sampleTile.find('.main-details .book-editions-detail #edition-row-detail').clone();
				editionRow.attr('id', 'edition-row-detail' + '-' + editions[i]['edition'] + '-' + i);
				editionRow.find('.edition').html(editions[i]['edition']);
				setDateField(editionRow.find('.print-date'), editions[i]['print_date'], false);
				editionRow.find('.press').html(editions[i]['press']);
				editionRow.find('.pages').html(editions[i]['pages']);
				editionRow.find('.quantity').html(editions[i]['quantity']);
				editionRow.find('.cost').html(editions[i]['cost']);
				editionRow.find('.sale').html(editions[i]['sale']);
				editionRow.find('.revised').html(editions[i]['revised']);
				editionRow.find('.isbn').html(editions[i]['isbn']);

				editionRow.show();
				sampleTile.find('.main-details .book-editions-detail').append(editionRow);
			}
		}

		return sampleTile;
	}

	function populateDetailForm(sampleTile, record) {
		sampleTile.find('.main-details .title-detail .value input').attr('value', record['title']);
		sampleTile.find('.main-details .code-detail .value input').attr('value', record['code']);
		sampleTile.find('.main-details .language-detail .value input').attr('value', record['language']);
		sampleTile.find('.main-details .location-detail .value input').attr('value', record['location']);
		sampleTile.find('.main-details .requirement-detail .value input').attr('value', record['requirement']);

		if (record['stock']) {
			var stock = record['stock'];
			var stockDivId = Records.populateMultiAddFields(
				sampleTile.find('.main-details .stock-detail'),
				stock.length
			);

			for (var i = 0; i < stock.length; i++) {
				var stockDiv = sampleTile.find('#' + stockDivId + (i + 1));
				stockDiv.find('input[name="place[]"]').val(stock[i]['place']);
				stockDiv.find('input[name="qty[]"]').val(stock[i]['qty']);
				setDateField(stockDiv.find('input[name="stock_date[]"]'), stock[i]['stock_date'], true);
			}
		}

		if (record['editions']) {
			var editions = record['editions'];
			var editionDivId = Records.populateMultiAddFields(
				sampleTile.find('.main-details .book-editions-detail'),
				editions.length
			);

			for (var i = 0; i < editions.length; i++) {
				var editionDiv = sampleTile.find('#' + editionDivId + (i + 1));
				editionDiv.find('input[name="edition[]"]').val(editions[i]['edition']);
				setDateField(editionDiv.find('input[name="print_date[]"]'), editions[i]['print_date'], true);
				editionDiv.find('input[name="press[]"]').val(editions[i]['press']);
				editionDiv.find('input[name="pages[]"]').val(editions[i]['pages']);
				editionDiv.find('input[name="quantity[]"]').val(editions[i]['quantity']);
				editionDiv.find('input[name="cost[]"]').val(editions[i]['cost']);
				editionDiv.find('input[name="sale[]"]').val(editions[i]['sale']);
				editionDiv.find('input[name="revised[]"]').val(editions[i]['revised']);
				editionDiv.find('input[name="isbn[]"]').val(editions[i]['isbn']);
			}
		}

		return sampleTile;
	}

	function setDateField(div, date, isForm) {
		if (date !== null) {
			var dateAr = date.split('-');
			if (dateAr.length === 3) {
				var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
				if (isForm === true) {
					div.attr('value', dateStr);
					div.datepicker('update', dateStr);
				} else {
					dateStr = Utils.getDateStr(dateAr[0], dateAr[1], dateAr[2]);
					div.html(dateStr);
				}
			} else {
				if (isForm === true) {
					div.attr('value', date);
					div.datepicker('update', date);
				} else {
					div.html(date);
				}
			}
		}
	}

	function getStockInfo(record) {
		var stockObj = {};
		var totalStock = 0;

		if (record['stock']) {
			var stock = record['stock'];

			for (var i = 0; i < stock.length; i++) {
				var place = stock[i]['place'];
				if (
					typeof stockObj[place] === 'undefined' ||
					stockObj[place]['date'] < new Date(stock[i]['stock_date'])
				) {
					stockObj[place] = { date: new Date(stock[i]['stock_date']), qty: stock[i]['qty'] };
				}
			}
		}

		for (place in stockObj) {
			totalStock += stockObj[place['qty']];
		}

		return { totalStock: totalStock, stockObj: stockObj };
	}

	function findLatestEdition(record) {
		var editions = record['editions'];

		var latestEdition = editions[0];

		for (var i = 1; i < editions.length; i++) {
			if (new Date(latestEdition['print_date']) < new Date(editions[i]['print_date'])) {
				latestEdition = editions[i];
			}
		}

		return latestEdition;
	}

	return {
		initModule: initModule,
		setConfig: function (key, value) {
			configMap[key] = value;
		},
	};
})(jQuery);
