import { Sangat } from 'components/sangat/main';
import { isAdmin } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';
import Page from './Page';

const Component = (props) => {
	return (
		<Page title='Sangat Records' fullscreen>
			<Sangat />
		</Page>
	);
};

function mapStateToProps(state: ApplicationState) {
	return {
		isAdmin: isAdmin(state.dataState.userStore.userData),
		userData: state.dataState.userStore.userData,
	};
}

export default connect(mapStateToProps)(Component);
