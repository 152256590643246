import { FireStoreModule } from 'fb';
import firebase from 'fb/index';
import 'firebase/firestore';
import { updateRate } from 'store/data/journals/rate/actions';

export class RateFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'rate';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: firebase.firestore.Firestore) => db.collection('rate');
	public getChangeProcessAction = (changes) => updateRate(changes);
}
