import { grey } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { getWebAppAssetPath, isWebViewEnv } from 'helpers';
import './fonts.css';

let theme = createTheme({
	palette: {
		primary: {
			main: '#d2691e',
			contrastText: '#ffffff',
		},
	},
	typography: {
		htmlFontSize: 10,
		fontFamily: 'Roboto',
		body2: {
			letterSpacing: 0,
		},
	},
});

let commonTheme = {
	...theme,
	overrides: {
		MuiBackdrop: {
			root: {
				top: isWebViewEnv() ? 56 : 0,
			},
		},
		MuiPaper: {
			root: {
				color: 'rgba(0, 0, 0, 0.65)',
			},
		},
		MuiButton: {
			root: {
				color: 'rgba(0, 0, 0, 0.6)',
				'&:hover': {
					[theme.breakpoints.down('xs')]: {
						backgroundColor: 'inherit',
					},
				},
			},
		},
		MuiIconButton: {
			root: {
				'&:hover': {
					[theme.breakpoints.down('xs')]: {
						backgroundColor: 'inherit',
					},
				},
			},
		},
		MuiCssBaseline: {
			'@global': {
				body: {
					color: 'rgba(0, 0, 0, 0.75)',
					backgroundColor: grey[200],
				},

				'heading-one, heading-two, heading-three': {
					display: 'block',
					color: theme.palette.primary.main,
					fontSize: '1.1em',
					fontWeight: 'bold',
					textAlign: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
					backgroundPosition: 'center',
					marginBottom: '1.5em',
					marginTop: '1em',
				},

				'heading-one': {
					backgroundImage: `url(${getWebAppAssetPath('bg/heading-bg.png')})`,
					padding: '2em 0',

					[theme.breakpoints.down('xs')]: {
						fontSize: '5.2vw',
						padding: '1.5em 0',
						marginBottom: '1em',
						marginTop: '1em',
					},
				},

				'heading-two': {
					backgroundImage: `url(${getWebAppAssetPath('bg/the-spiritual-master.png')})`,
					padding: '4em 0 0.45em 0',

					[theme.breakpoints.down('xs')]: {
						fontSize: '5.2vw',
						paddingBottom: '1.15em',
						marginBottom: 0,
						marginTop: 0,
					},
				},

				'heading-three': {
					backgroundImage: `url(${getWebAppAssetPath('bg/holy-teachings.png')})`,
					padding: '5em 0 0.6em 0',

					[theme.breakpoints.down('xs')]: {
						fontSize: '5vw',
						padding: '3.5em 0 0.2em 0',
						marginBottom: '1em',
						marginTop: 0,
					},
				},

				'.end-content': {
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
					backgroundPosition: 'center',

					width: '20vw',
					height: '3.5vw',
					maxWidth: 350,
					maxHeight: 80,
					margin: '0 auto',
					backgroundImage: `url(${getWebAppAssetPath('bg/end-content.png')})`,
				},

				'.locale-en': {
					'& heading-one, & heading-two, & heading-three': {
						textTransform: 'uppercase',
						// letterSpacing: 0,
						whiteSpace: 'nowrap',
						fontSize: '1em',
					},

					'& heading-one': {
						[theme.breakpoints.down('xs')]: {
							fontSize: '4.5vw',
							padding: '1.8em 0',
						},
					},

					'& haeding-two': {
						[theme.breakpoints.down('xs')]: {
							fontSize: '4.5vw',
							paddingBottom: '0.9em',
						},
					},
				},
			},
		},
	},
};

export const enUsTheme = commonTheme;
export const hiTheme = commonTheme;
