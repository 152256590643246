import { IntegratedRecordsList } from 'components/IntegratedRecordsList';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, SubscriberRecord } from 'types';
import { RecordsHandler } from './RecordsHandler';
import { ViewTile } from './viewTile';

let CSS = `
#selection-dropdown {
    display: block !important;
}
#record_count {
	margin-top: 0;
}

.heading {
	text-align: center;
	font-size: 1.2em;
	font-weight: bold;
	margin-bottom: 1.5em;
    border-bottom: 1px solid grey;
    padding-bottom: 0.3em;
	text-transform: uppercase;
}

form.tile .comments {
	width: 100%;
	resize: none;
    height: 9em;
}

.tile .comments {
    border: 1px solid darkgray;
    padding: 10px;
    overflow: auto;
    max-height: 6em;
}

.tile .comments-title {
    border: 1px solid darkgray;
    border-bottom: 0;
    text-align: center;
}

.tt-suggestion:hover {
	color: white;
	background-color: #0097cf;
	cursor: pointer;
}

.through-sugestion {
	padding: 0.7em 2em;
	white-space: nowrap;
}

.tt-menu {
	width: auto;
	overflow-y: auto;
	max-height: 150px;
	box-shadow: 1px 1px 5px 1px grey;
	background-color: white;
	overflow-x: hidden;
}

input[name="subscriber_no_code"] {
	text-transform: uppercase;
}


.tile.amar-record.detail {
    padding-top: 15px;
}

.tile.amar-record .main-details {
    padding-right: 30px;
}

.tile.amar-record .main-details .title {
	text-align: right;
	padding: 0;
	margin-bottom: 15px;
    font-weight: bold;
    font-size: 0.9em;
}

.tile.amar-record .main-details .value {
	padding-right: 0;
	margin-bottom: 15px;
}

.tile.amar-record input {
	width: 100%;
	background-color: white!important;
    border: none;
    border-bottom: 1px solid darkgray;
}

	
.tile.amar-record input:disabled {
	background-color: #CCC!important;
}

.tile.amar-record input[type='checkbox'] {
	width: auto;
	margin-right: 5px;
}

.iti-flag {
	background-image: url('/assets/img/flags.png');
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    background-image: url('/assets/img/flags@2x.png');
}

.intl-tel-input .flag-container {
	top: 12px;
	bottom: auto;
}

.radio, .checkbox {
	margin-top: 0;
	margin-bottom: 0;
}

.tile.amar-record input:focus {
    outline: none;    
}

.tile.amar-record .value select {
	width: 100%;
}

.tile.amar-record .language-value {
	text-transform: capitalize;
}

.bulk-users-section, .bulk-users-section-detail {
	display: none;
}

.bootstrap-dialog-message .bulk-users-section-detail {
	display: block;
}

form.tile.amar-record .address {
	width: 100%;
	resize: none;
    height: 6em;
}

.tile.amar-record .address {
    border: 1px solid darkgray;
    padding: 10px;
    overflow: auto;
    max-height: 6em;
}

.tile.amar-record .comments-title {
    border: 1px solid darkgray;
    border-bottom: 0;
    text-align: center;
}

div.tile.amar-record .main-details .value {
    border-bottom: 1px solid lightgrey;
    min-height: 1.5em;
    padding-right: 0;
}

.lb {
    border-bottom: 1px solid lightgrey;
}

.multi-field-row {
	padding-top: 3px;
	padding-bottom: 3px;
	font-size: 14px;
}

.multi-field-row:nth-last-child(1) {
	border-bottom: none;
}

.btn-danger {
	float: left;
}

table {
	width: 100%;
	font-size: 0.9em;
	max-height: 12em;
}

table, th, td {
	border: 1px solid black;
	padding: 3px;
	text-align: center;
}

thead {
	text-align: center;
	font-weight: bold;
}

.markings-div {
	max-height: 12em;
	overflow-y: auto;
}

#mark_btn, #unmark_btn {
	float: right;
}
`;

const html = `
<style>
	${CSS}
</style>

<div id="page-records">
    <div class="records">
        <div class="filtered-records">
        </div>
    </div>
</div>
`;

interface Props {
	records: SubscriberRecord[];
}

class Component extends React.Component<Props> {
	render() {
		return (
			<React.Fragment>
				<IntegratedRecordsList
					records={this.props.records}
					RecordTile={ViewTile}
					html={html}
					RecordsHandler={RecordsHandler}
				/>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		records: state.dataState.subscriber.filtered,
	};
}

export const Subscriber = connect(mapStateToProps)(Component);
