import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { DialogContext } from 'components/pages/DialogPage';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { ToggleButtonGroup } from 'formik-material-ui-lab';
import { Context } from 'helpers/context';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { updateProfile } from 'services/api/auth';
import { ApplicationState, UserData } from 'types';
import * as Yup from 'yup';
import { UserPicInfo } from './UserPicInfo';

const styles = (theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	},
	card: {
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: 'white',
	},
	message: {
		marginBottom: theme.spacing(8),
	},
	label: {
		color: theme.palette.grey[500],
		marginBottom: theme.spacing(-1) + 'px !important',
		transform: 'scale(0.75)',
		transformOrigin: 'top left',
	},
	form: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	},
	signinbtn: {
		height: 42,
		width: '90%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	},
	newuser: {
		alignSelf: 'center',
		marginTop: theme.spacing(3),
	},
});

export interface ProfileProps {
	userData?: UserData;
	updateProfile: (fullName?: string, gender?: string, phone?: string, dob?: string, rahbar?: string) => Promise<void>;
	classes: any;
}

class ProfileDetails extends React.Component<ProfileProps, any> {
	render() {
		let { userData, classes, updateProfile } = this.props;

		return (
			<div className={classes.root}>
				<UserPicInfo />
				<Card className={classes.card}>
					{/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
					<Formik
						initialValues={{
							fullname: userData?.fullName || '',
							gender: userData?.gender || '',
							phone: userData?.phone || '',
							// dob: userData?.dob ? moment(userData?.dob) : null,
							rahbar: userData?.rahbar || '',
						}}
						validationSchema={() => {
							let phoneRegex =
								/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
							return Yup.object({
								fullname: Yup.string(),
								gender: Yup.string().oneOf(['male', 'female']),
								phone: Yup.string().matches(phoneRegex, 'Invalid mobile number'),
								dob: Yup.date().nullable(),
								rahbar: Yup.string(),
							});
						}}
						onSubmit={async (values, { setSubmitting }) => {
							await updateProfile(
								values.fullname,
								values.gender,
								values.phone,
								// values.dob && (values.dob as any).format
								// 	? (values.dob as any).format('YYYY-MM-DD')
								// 	:
								'',
								values.rahbar
							);

							setSubmitting(false);
							this.context.popScreen();
						}}
					>
						{({ submitForm, isSubmitting }) => (
							<Form className={classes.form} translate='yes'>
								<Field
									component={TextField}
									autoComplete='off'
									name='fullname'
									type='text'
									label='Full Name'
								/>
								<Typography className={classes.label}>Gender</Typography>
								<Field
									component={ToggleButtonGroup}
									type='checkbox'
									style={{ width: '100%' }}
									name='gender'
									exclusive
								>
									<ToggleButton color='primary' style={{ width: '50%' }} value='male'>
										Male
									</ToggleButton>
									<ToggleButton color='primary' style={{ width: '50%' }} value='female'>
										Female
									</ToggleButton>
								</Field>
								<Field
									component={TextField}
									autoComplete='off'
									name='phone'
									type='text'
									label='Phone Number'
								/>
								{/* <Field
										component={DatePicker}
										format='ddd, Do MMM yyyy'
										name='dob'
										label='Date of Birth'
									/> */}
								<Field
									component={TextField}
									autoComplete='off'
									name='rahbar'
									type='text'
									label='Rahbar'
								/>
								<Button
									className={classes.signinbtn}
									variant='contained'
									color='primary'
									type='submit'
									onClick={submitForm}
									disabled={isSubmitting}
								>
									Save Changes
								</Button>
							</Form>
						)}
					</Formik>
					{/* </MuiPickersUtilsProvider> */}
				</Card>
			</div>
		);
	}
}

function mapStateToProps({ dataState }: ApplicationState) {
	let { userStore } = dataState;

	return {
		userData: userStore.userData,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateProfile: async (fullName?: string, gender?: string, phone?: string, dob?: string, rahbar?: string) => {
			await dispatch(updateProfile({ fullName, gender, phone, dob, rahbar }, new Context()));
		},
	};
}

ProfileDetails.contextType = DialogContext;

export default hot(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileDetails)));
