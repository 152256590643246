import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { FBUserPlaylists, UserData } from 'types';

export const UPDATE_USER = '@@user/UPDATE_USER';
export type UPDATE_USER = typeof UPDATE_USER;

export interface UpdateUser extends Action {
	type: UPDATE_USER;
	user?: firebase.User;
}

export const updateUser = (user?: firebase.User): UpdateUser => {
	return {
		type: UPDATE_USER,
		user,
	};
};

export const UPDATE_USER_DATA = '@@user/UPDATE_USER_DATA';
export type UPDATE_USER_DATA = typeof UPDATE_USER_DATA;

export interface UpdateUserData extends Action {
	type: UPDATE_USER_DATA;
	userData?: UserData;
}

export const updateUserData = (userData?: UserData): UpdateUserData => {
	return {
		type: UPDATE_USER_DATA,
		userData,
	};
};

export const UPDATE_FB_USER_PLAYLISTS = '@@user/UPDATE_FB_USER_PLAYLISTS';
export type UPDATE_FB_USER_PLAYLISTS = typeof UPDATE_FB_USER_PLAYLISTS;

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export interface UpdateFBUserPlaylists extends Action {
	type: UPDATE_FB_USER_PLAYLISTS;
	userId: string;
	changes: FBChanges;
	loggedInUserId?: string;
}

export const updateFBUserPlaylists = (
	userId: string,
	changes: FBChanges,
	loggedInUserId?: string
): UpdateFBUserPlaylists => {
	return {
		type: UPDATE_FB_USER_PLAYLISTS,
		userId,
		changes,
		loggedInUserId,
	};
};

export const UPDATE_FB_USER_PLAYLISTS_LOCAL = '@@user/UPDATE_FB_USER_PLAYLISTS_LOCAL';
export type UPDATE_FB_USER_PLAYLISTS_LOCAL = typeof UPDATE_FB_USER_PLAYLISTS_LOCAL;

export interface UpdateFBUserPlaylistsLocal extends Action {
	type: UPDATE_FB_USER_PLAYLISTS_LOCAL;
	playlist: FBUserPlaylists;
}

export const updateFBUserPlaylistsLocal = (playlist: FBUserPlaylists): UpdateFBUserPlaylistsLocal => {
	return {
		type: UPDATE_FB_USER_PLAYLISTS_LOCAL,
		playlist,
	};
};
