import { HeaderControls, HeaderModule } from 'components/header/anand.header';
import { ModelClass } from 'core/anand.model';
import { Records } from 'core/anand.records';
import { addFormHTML, briefTileHTML, detailFormHTML, detailTileHTML } from './viewTile';

export const RecordsHandler = (function ($) {
	var configMap = {};

	var stateMap: any = {
		systemId: 'rate',
	};

	function initModule(props?, updateRecordsState?) {
		HeaderModule.initModule([
			HeaderControls.addRecordBtn,
			HeaderControls.selectDropdown,
			HeaderControls.printBtn,
			HeaderControls.sortBtn,
		]);

		Records.projectTitle = 'Amar Satya Sandesh Rates';

		Records.getAnySearchFilter = getAnySearchFilter;
		Records.defaultSortOrder = 'category_id';
		Records.IdentifyingFieldName = 'category_id';

		Records.sortingFieldsMap = {
			country: 'Country',
			rate: 'Rate',
		};

		Records.searchFieldsMap = {
			category: 'Category',
			country: 'Country',
		};

		Records.isSearchDateTypeColumn = function (searchBy) {
			return false;
		};

		Records.printOptions = {
			fieldNames: ['S. No.', 'Category', 'Country', 'Rate'],
			fieldKeys: ['id', 'category', 'country', 'rate'],
			defaultFieldWidths: { id: 60, category: 220, country: 200, rate: 150 },
			defaultHeader: 'Amar Satya Sandesh Rate Records',
			beforePrintHook: function (data, forExport) {
				for (var i = 0; i < data.length; i++) {
					data[i]['id'] = i + 1 + '.';

					data[i]['category'] = getCategoryFromId(data[i]['category_id']);
				}

				return data;
			},
		};

		Records.exportOptions = {
			fieldKeys: ['id', 'category_id', 'country', 'rate'],
			defaultFileName: 'Amar Satya Sandesh Rate Records',
		};

		Records.hooks.afterHeaderConfigure = function (controlsHeader) {
			controlsHeader.find('.reminder-btn').hide();
			controlsHeader.find('.dashboard-btn').hide();
		};

		Records.hooks.specificSearchHook = function (searchBy, query) {
			if (searchBy === 'category') {
				searchBy = 'category_id';

				if (!query) {
					return {
						searchBy: searchBy,
						query: query,
					};
				}

				var categoryIds = stateMap.categoryModel
					.getRecords(
						[[{ name: { likenocase: query } }, { code: { likenocase: query } }]],
						'id logical',
						null,
						true
					)
					.distinct('id');
				query = categoryIds;
			}

			return {
				searchBy: searchBy,
				query: query,
			};
		};

		Records.hooks.beforeAddTileShow = function (addTile, record) {
			configureCategories(addTile);

			return addTile;
		};

		Records.detailTileSelector = '#amar-rates-detail-record';
		Records.briefTileSelector = '#amar-rates-brief-record';
		Records.addTileSelector = '#amar-rates-add-record';
		Records.detailFormSelector = '#amar-rates-detail-record-form';
		Records.imageUploadSelector = null;

		Records.detailTileHTML = detailTileHTML;
		Records.briefTileHTML = briefTileHTML;
		Records.detailFormHTML = detailFormHTML;
		Records.addFormHTML = addFormHTML;

		Records.populateBriefTile = populateBriefTile;
		Records.populateDetailTile = populateDetailTile;
		Records.populateDetailForm = populateDetailForm;

		Records.updateRecordsState = updateRecordsState;

		let CategoryModel = ModelClass.Instance('category');
		stateMap.categoryModel = CategoryModel;
		stateMap.categoryModel.initModule({
			systemId: 'category',
			forceUpdate: configMap['forceUpdate'],
			prepareRecord: prepareRecord,
		});
		stateMap.categoryModel.getRecords([], '');

		Records.initModule({
			systemId: stateMap.systemId,
			prepareRecord: prepareRecord,
			props: props,
			addTileFormValidationRules: {
				phone_ignore: {
					maxlength: 255,
					required: true,
					telInputValidator: true,
				},
			},
		});
	}

	function prepareRecord(record) {
		return record;
	}

	function getAnySearchFilter(query) {
		return [{ country: { likenocase: query } }];
	}

	async function populateBriefTile(sampleTile, record) {
		sampleTile.find('.category-detail .category-value').html(getCategoryFromId(record['category_id']));
		sampleTile.find('.country-detail .country-value').html(record['country']);
		sampleTile.find('.rate-detail .value').html(record['rate']);

		return sampleTile;
	}

	async function populateDetailTile(sampleTile, record) {
		sampleTile.find('.category-detail .category-value').html(getCategoryFromId(record['category_id']));
		sampleTile.find('.country-detail .country-value').html(record['country']);
		sampleTile.find('.rate-detail .value').html(record['rate']);

		return sampleTile;
	}

	function populateDetailForm(sampleTile, record) {
		Records.hooks.beforeAddTileShow(sampleTile, record);

		sampleTile
			.find('.category-detail select[name="category_id"] option[value="' + record['category_id'] + '"]')
			.attr('selected', 'selected');
		sampleTile.find('input[name="country"]').val(record['country']);
		sampleTile.find('input[name="rate"]').val(record['rate']);

		return sampleTile;
	}

	function configureCategories(tile) {
		var categories = stateMap.categoryModel.getRecords([], 'name logical');

		var categoryInput = tile.find("select[name='category_id']");
		categoryInput.html('');
		for (var i = 0; i < categories.length; i++) {
			var option = $("<option value='" + categories[i].id + "'>" + categories[i].name + '</option>');
			categoryInput.append(option);
		}
	}

	function getCategoryFromId(categoryId) {
		var category = stateMap.categoryModel.getRecords([{ id: categoryId }], 'id logical');
		if (category.length > 0) {
			return category[0].name;
		}
		return '';
	}

	return {
		initModule: initModule,
		setConfig: function (key, value) {
			configMap[key] = value;
		},
	};
})(jQuery);
