import { isMobile } from 'helpers';
import { populateViewTileHelper } from 'helpers/RecordsHelper';
import React from 'react';
import { connect } from 'react-redux';
import { AnumatiRecord, ApplicationState } from 'types';

export const kutiyaViewTileCSS = `
<style>

.tile.kutiya-record.detail {
    padding-top: 15px;
}

.tile.kutiya-record .main-details {
    padding-right: 30px;
}

.tile.kutiya-record .main-details .title {
	text-align: right;
	padding: 0;
	margin-bottom: 15px;
    font-weight: bold;
    font-size: 0.9em;
}

.tile.kutiya-record .main-details .value {
	padding-right: 0;
	margin-bottom: 15px;
}

.tile.kutiya-record input {
	width: 100%;
	background-color: white!important;
    border: none;
    border-bottom: 1px solid darkgray;
}

.tile.kutiya-record input:focus {
    outline: none;    
}

.tile.kutiya-record .value select {
	width: 100%;
}

.tile.kutiya-record .self-image {
	width: 100%;
    margin-bottom: 20px;
}

.tile.kutiya-record .info-tile {
	border: 1px solid darkgray;
    margin-bottom: 5px;
}

.tile.kutiya-record .info-tile .info-title {
	text-align: center;
}

.tile.kutiya-record .info-tile select {
    width: 100%;
}

.tile.kutiya-record .info-tile .value {
	text-align: center;
	width: 100%;
    border-bottom: none!important;
}

.tile.kutiya-record .address {
    border: 1px solid darkgray;
    padding: 10px;
    overflow: auto;
    max-height: 6em;
}

.tile.kutiya-record .comments-title {
    border: 1px solid darkgray;
    border-bottom: 0;
    text-align: center;
}

div.tile.kutiya-record .main-details .value {
    border-bottom: 1px solid lightgrey;
    min-height: 1.5em;
    padding-right: 0;
}

div.tile.kutiya-record .main-details .row {
    /*margin-right: 0;*/
}

.lb {
    border-bottom: 1px solid lightgrey;
}

.rahbar-detail select, .past-rahbar-detail select, .self-detail select {
    margin-left: -0.5em;
}

.contact-detail .title {
	padding-bottom: 5px !important;
}

#resident-row, #contact-type-value {
	display: none;
}

.contact-type, .resident_title {
	text-align: right;
	padding-right: 0;
}

.contact-value, .resident_name {
	text-align: left;
	padding-left: 5px;
}

.contact-detail, .resident-detail {
	border: 1px solid lightgrey;
	margin-bottom: 10px;
}

.contact-detail .value, .resident-detail .value {
	padding-bottom: 3px !important;
	padding-top: 3px !important;
	margin-bottom: 0px !important;
}

.contact-detail .title, .resident-detail .title {
	padding-bottom: 3px !important;
	padding-top: 3px !important;
	text-align: center !important;
	border-bottom: 1px lightgrey solid;
	margin-bottom: 0px !important;
}

</style>
`;

const html = `
<div id="kutiya-detail-record" class="tile kutiya-record detail">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row form-trust-detail">
					<div class="title col-xs-3 col-md-2">
						Form#:
					</div>
					<div class="value form-value col-xs-9 col-md-4">
					</div>
					<div class="title col-xs-3 col-md-2">
						Trust:
					</div>
					<div class="value trust-value col-xs-9 col-md-4">
					</div>
				</div>
				<div class="row self-detail">
					<div class="title col-xs-3 col-md-2">
						Kutiya:
					</div>
					<div class="value col-xs-9 col-md-10">
					</div>
				</div>
				<div class="row head-detail">
					<div class="title col-xs-3 col-md-2">
						Head:
					</div>
					<div class="value col-xs-9 col-md-10">
					</div>
				</div>                    
				<div class="row address-detail">
					<div class="title col-xs-3 col-md-2">
						Address:
					</div>
					<div class="value col-xs-9 col-md-10">
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row city-detail">
					<div class="title col-xs-3 col-md-2">
						City:
					</div>
					<div class="value city-value col-xs-9 col-md-4">
					</div>
					<div class="title col-xs-3 col-md-2">
						Pincode:
					</div>
					<div class="value pincode-value col-xs-9 col-md-4">
					</div>
				</div>
				<div class="row state-detail">
					<div class="title col-xs-3 col-md-2">
						State:
					</div>
					<div class="value state-value col-xs-9 col-md-4">
					</div>
					<div class="title col-xs-3 col-md-2">
						Country:
					</div>
					<div class="value country-value col-xs-9 col-md-4">
					</div>
				</div>
				<div class="row lift-area-detail">
					<div class="title col-xs-3 col-md-2">
						Lift:
					</div>
					<div class="value lift-value col-xs-9 col-md-4">
					</div>
					<div class="title col-xs-3 col-md-2">
						Area:
					</div>
					<div class="value area-value col-xs-9 col-md-4">
					</div>
				</div>
				<div class="row members-detail">
					<div class="title col-xs-3 col-md-2">
						Males:
					</div>
					<div class="value males-value col-xs-9 col-md-4">
					</div>
					<div class="title col-xs-3 col-md-2">
						Females:
					</div>
					<div class="value females-value col-xs-9 col-md-4">
					</div>
				</div>
				<div class="row status-detail">
					<div class="title col-xs-3 col-md-2">
						Status:
					</div>
					<div class="value status-value col-xs-9 col-md-10">
					</div>
				</div>
				<div class="row comments-detail">
					<div class="title col-xs-3 col-md-2">
						Comments:
					</div>
					<div class="value col-xs-9 col-md-10">
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row resident-detail">
					<div class="title col-xs-12 col-md-12">
						Residents:
					</div>
					<div id="resident-row" class="multi-field-row col-xs-12">
						<div class="row">
							<div class="resident_title col-xs-3 col-md-2">
							</div>
							<div class="resident_name col-xs-9 col-md-10">
							</div>
						</div>
					</div>
				</div>
				<div class="row contact-detail">
					<div class="title col-xs-12 col-md-12">
						Contacts:
					</div>
					<div id="contact-type-value" class="multi-field-row col-xs-12 col-md-12">
						<div class="row">
							<div class="col-xs-4 contact-type"></div>
							<div class="col-xs-8 contact-value"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
`;

let briefHtml = `
<div id="kutiya-brief-record" class="tile kutiya-record brief">
	<div class="row head-detail">
		<div class="title col-xs-2 col-md-3 no-padding align-right">

		</div>
		<div class="value col-xs-10 col-md-9">

		</div>
	</div>           						
	<div class="row city-detail">
		<div class="title col-xs-2 col-md-3 no-padding align-right">
			City:
		</div>
		<div class="value col-xs-10 col-md-9">

		</div>
	</div>
	<div class="record-id">
		<span></span>
	</div>
</div>
`;

interface Props {
	record?: AnumatiRecord;
	index?: number;
	listRef?: React.RefObject<any>;
}

class Component extends React.PureComponent<Props> {
	private tileRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	componentDidUpdate(prevProps, prevState) {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	render() {
		return <div ref={this.tileRef} dangerouslySetInnerHTML={{ __html: isMobile() ? briefHtml : html }}></div>;
	}
}

function mapStateToProps(state: ApplicationState) {
	return {};
}

export const KutiyaViewTile = connect(mapStateToProps)(Component);
