import { notification } from 'core/anand.notification';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './roboto.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if ('serviceWorker' in navigator) {
	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	const onSWUpdate = (registration: ServiceWorkerRegistration) => {
		notification.showBottomNotification('Loading latest updates...');
		registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
		setTimeout(() => {
			if (window.location.pathname !== '/') {
				window.location.href = '/';
			} else {
				window.location.reload();
			}
		}, 200);
	};

	const onSWSuccess = (registration: ServiceWorkerRegistration) => {
		notification.showBottomNotification('Ready for offline use!');
	};

	if (window.location.hash === '#nocache') {
		serviceWorkerRegistration.unregister();
	} else {
		serviceWorkerRegistration.register({ onUpdate: onSWUpdate, onSuccess: onSWSuccess });
	}
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
