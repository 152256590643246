import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { AnumatiRecord } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_ANUMATI = '@@mjbj/UPDATE_ANUMATI';
export type UPDATE_ANUMATI = typeof UPDATE_ANUMATI;

export interface UpdateAnumati extends Action {
	type: UPDATE_ANUMATI;
	changes: FBChanges;
}

export function updateAnumati(changes: FBChanges): UpdateAnumati {
	return {
		type: UPDATE_ANUMATI,
		changes,
	};
}

export const UPDATE_ANUMATI_FILTERED = UPDATE_ANUMATI + '_FILTERED';
export type UPDATE_ANUMATI_FILTERED = typeof UPDATE_ANUMATI_FILTERED;

export interface UpdateAnumatiFiltered extends Action {
	type: UPDATE_ANUMATI_FILTERED;
	payload: AnumatiRecord[];
}
