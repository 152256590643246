import { HeaderControls, HeaderModule } from 'components/header/anand.header';
import { overlay_panel } from 'core/anand.overlay_panel';
import { Records } from 'core/anand.records';
import { addFormHTML, briefTileHTML, detailFormHTML, detailTileHTML } from './viewTile';

export const RecordsHandler = (function ($) {
	var configMap = {};

	var stateMap: any = {
		systemId: 'dham',
	};

	function initModule(props?, updateRecordsState?) {
		HeaderModule.initModule([
			HeaderControls.addRecordBtn,
			HeaderControls.selectDropdown,
			HeaderControls.printBtn,
			HeaderControls.sortBtn,
		]);

		Records.projectTitle = 'Dham Records';

		Records.getAnySearchFilter = getAnySearchFilter;
		Records.defaultSortOrder = 'dham_name';
		Records.IdentifyingFieldName = 'id';

		Records.sortingFieldsMap = {
			dham_name: 'Dham Name',
			dham_address: 'Address',
			comments: 'Comments',
		};

		Records.searchFieldsMap = {
			dham_name: 'Dham Name',
			dham_address: 'Address',
			comments: 'Comments',
		};

		Records.isSearchDateTypeColumn = function (searchBy) {
			return false;
		};

		function printAddrLabels() {
			Records.refreshRecordsAndReposition();

			var printConfig =
				'<div id="print-configs">\n\
					<div class="col-xs-12">\n\
						<div class="col-md-1 no-padding"><label>Skip Labels:</label></div><div class="col-md-1 no-l-padding"><input type="number" name="skip_labels" value="0" placeholder="Skip Labels"></div>\n\
						<div class="col-md-1 no-padding"><label>Copies:</label></div><div class="col-md-1 no-l-padding"><input type="number" name="copies" value="1" placeholder="Copies"></div>\n\
					</div>';

			var printBtn =
				'<button id="print_records_btn" class="button btn btn-primary">' +
				'<span><span>Print</span></span>' +
				'</button>';

			printConfig += '<iframe id="print-preview" name="print-preview" src="about:blank"></iframe>';
			printConfig += '</div>';

			var printConfigObj = {
				contentHtml: printConfig,
				id: 'print_config',
				title: 'Print Address Labels',
				headerBtnHtml: printBtn,
				afterAppend: function () {
					updatePrintLabelPreview(null);
				},
			};

			overlay_panel.showPanel(printConfigObj);

			$('#print-configs input').on('change', updatePrintLabelPreview);

			$('#print_records_btn').click(function () {
				updatePrintLabelPreview(null, true);
			});
		}

		function getSelectedPrintLabelConfigs() {
			var printConfigs = {
				page_margins: {
					left: 0.5,
					right: 0.5,
					top: 0.7,
					bottom: 0.7,
				},
				label_margins: {
					horizontal: 0.2,
					vertical: 0.3,
				},
				label_height: 4.45,
				font_size: 22,
				font_type: 'helvetica',
				labels_pp: 12,
				skip_labels: parseInt($("#print-configs input[name='skip_labels']").val() as string) % 12,
				copies: parseInt($("#print-configs input[name='copies']").val() as string),
			};

			return printConfigs;
		}

		function updatePrintLabelPreview(e, autoPrint = undefined) {
			var data = JSON.parse(JSON.stringify(Records.getCurrentRecords()));
			data = data.filter((record) => record['dham_address'] && record['dham_address'].length);

			var configs = getSelectedPrintLabelConfigs();

			if (typeof autoPrint === 'undefined') {
				data = data.slice(0, configs.labels_pp - configs.skip_labels);
			}

			let style = `<style>	
				body {
					background-color: rgba(0, 0, 0, 0);		
					line-height: 1.42857143;
					color: #333;				
				}

				* {
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
				}

				.row {
					margin-right: -15px;
					margin-left: -15px;
				}

				.row:before, .row:after {
					display: table;
					content: " ";
				}

				.col-xs-12 {
					width: 100%;
				}
				
				.col-xs-6 {
					width: 50%;
				}

				.col-xs-6, .col-xs-12 {
					float: left;
					position: relative;
					min-height: 1px;
					padding-right: 15px;
					padding-left: 15px;
				}

				.print-top-div {
					background-color: white;
					padding: 0px 40px;
					width: 8.27in;
					position: relative;
					height: 11.69in;
					padding: 0.7cm 0.4cm 0.4cm 0.4cm;
					margin-left: 0;
					margin-right: 0;
					page-break-after: always;
				}
				
				.print-top-div .field {
					padding-top: 30px;
				}
				
				.print-top-div .title {
					font-weight: normal;
					font-size: 90%;
					text-align: left;
					padding-right: 0px;
					float: left;
					margin-bottom: 0;
				}
				
				.print-top-div .value {
					border-bottom: 1px dotted black;
					display: block;
					overflow: hidden;
					padding-left: 15px;
				}
				
				.print-top-div .print-address-label {
					padding: 10px;
					margin: 0 0.1cm 0.3cm 0.1cm;
					height: auto;
					color: #383780;
				}
				
				.print-top-div .print-label-container {
					margin: 0;
					padding: 0;
					height: 4.752cm;
					overflow: hidden;
				}
				
				.print-addresses-dummy {
					position: absolute;
					top: 100%;
				}
			</style>`;
			var headHtml = `<head> ${style} </head>`;
			var html = headHtml + '<body>';

			var topDiv = $(`<div class='print-top-div row'>${style}</div>`);
			topDiv.css('font-size', configs.font_size);
			topDiv.css('font-family', configs.font_type);
			$('body').append(topDiv);

			html += '<div class="print-top-div row">';

			var j = 0;
			for (; j < configs.skip_labels; j++) {
				html += "<div class='col-xs-6 print-label-container'><div class='print-address-label'></div></div>";
			}

			var len = data.length;
			for (var i = 0; i < len * configs.copies; i++) {
				let record = data[Math.floor(i / configs.copies)];

				if ((i + j) % configs.labels_pp === 0 && i !== 0) {
					html += '<div class="print-top-div row">';
				}
				var addrLabel = printOneRecord(record);

				topDiv.append(addrLabel);

				var $label = addrLabel.find('.print-address-label');

				while (parseInt(addrLabel.css('height')) < parseInt($label.css('height'))) {
					$label.css('font-size', parseInt($label.css('font-size')) - 0.5);
				}
				$label.css('height', configs.label_height + 'cm');

				html += topDiv.html();
				addrLabel.remove();

				if ((i + j + 1) % configs.labels_pp === 0) {
					html += '</div>';
				}
			}

			html += '</div></body>';
			topDiv.remove();

			var iFrameWindow = window.frames['print-preview'];
			var doc = iFrameWindow.document;
			$('body', doc).html(html);

			$('body', doc).find('.print-top-div').css('font-size', configs.font_size);
			$('body', doc).find('.print-top-div').css('font-family', configs.font_type);

			$('#print-preview').css('height', '12in');

			if (typeof autoPrint !== 'undefined' && autoPrint === true) {
				setTimeout(function () {
					iFrameWindow.focus();
					iFrameWindow.print();
				}, 1000);
			}

			function printOneRecord(record) {
				var address = '';
				if (record['dham_address'] !== null) {
					address = record['dham_address'].replace(/\n/g, '</br>');
				}

				var html =
					"<div class='col-xs-6 print-label-container'><div class='print-address-label'>" +
					"<div class='row'>" +
					"<div class='col-xs-12'>" +
					"<label class='title address-label'>" +
					address +
					'</label>' +
					'</div>' +
					'</div>' +
					'</div></div>';

				return $(html);
			}
		}

		Records.printOptions = {
			fieldNames: ['S. No.', 'Dham Name', 'Address', 'Comments'],
			fieldKeys: ['id', 'dham_name', 'dham_address', 'comments'],

			defaultFieldWidths: { id: 60, dham_name: 150, dham_address: 580, comments: 250 },
			defaultHeader: 'Dham Records',
			beforePrintHook: function (data) {
				for (var i = 0; i < data.length; i++) {
					data[i]['id'] = i + 1 + '.';
					data[i]['comments'] = data[i]['comments'] ?? '';
				}

				return data;
			},
		};

		Records.exportOptions = {
			fieldKeys: ['dham_name', 'dham_address', 'comments'],
			defaultFileName: 'Dham Records',
		};

		Records.hooks.afterHeaderConfigure = function (controlsHeader) {
			var printMenu = $(
				'<span class="dropdown hidden-sm hidden-xs">\n\
                    <a class="btn selection-btn dropdown-toggle" data-toggle="dropdown">\n\
						<span class="glyphicon glyphicon-print"></span>\n\
                        <span class="menu-text">Print</span>\n\
                        <span class="caret"></span>\n\
                    </a>\n\
                    <ul class="dropdown-menu">\n\
                        <li><a class="btn menu-text print-records">\n\
                                <span class="menu-text">Records</span>\n\
                            </a>\n\
                        </li>\n\
                        <li>\n\
                            <a class="btn menu-text print-addresses">\n\
                                <span class="menu-text">Addresses</span>\n\
                            </a>\n\
                        </li>\n\
                    </ul>\n\
                </span>'
			);

			var ptintBtn = controlsHeader.find('.print-btn');
			ptintBtn.hide();
			ptintBtn.after(printMenu);

			printMenu.find('.print-records').click(function () {
				ptintBtn.click();
			});
			printMenu.find('.print-addresses').click(printAddrLabels);
		};

		Records.detailTileSelector = '#dham-records-detail-record';
		Records.briefTileSelector = '#dham-records-brief-record';
		Records.addTileSelector = '#dham-records-add-form';
		Records.detailFormSelector = '#dham-records-edit-form';

		Records.detailTileHTML = detailTileHTML;
		Records.briefTileHTML = briefTileHTML;
		Records.detailFormHTML = detailFormHTML;
		Records.addFormHTML = addFormHTML;

		Records.populateBriefTile = populateBriefTile;
		Records.populateDetailTile = populateDetailTile;
		Records.populateDetailForm = populateDetailForm;

		Records.updateRecordsState = updateRecordsState;

		Records.initModule({ systemId: stateMap.systemId, prepareRecord: prepareRecord, props: props });
	}

	function prepareRecord(record) {
		return record;
	}

	function getAnySearchFilter(query) {
		return [
			{ dham_name: { likenocase: query } },
			{ dham_address: { likenocase: query } },
			{ comments: { likenocase: query } },
		];
	}

	async function populateBriefTile(sampleTile, record) {
		return populateDetailTile(sampleTile, record);
	}

	async function populateDetailTile(sampleTile, recordOrig) {
		var record = {};
		for (var key in recordOrig) {
			if (recordOrig[key] == null || recordOrig[key] == '') {
				record[key] = '&nbsp';
			} else {
				record[key] = recordOrig[key];
			}
		}

		sampleTile.find('.dham-name').html(record['dham_name']);
		if (record['dham_address'] && record['dham_address'].length > 0) {
			sampleTile.find('.dham-address').html(record['dham_address'].replace(/\n/g, '</br>'));
		}
		if (record['comments'] && record['comments'].length > 0) {
			sampleTile.find('.dham-comments').html(record['comments'].replace(/\n/g, '</br>'));
		}

		return sampleTile;
	}

	function populateDetailForm(sampleTile, record) {
		sampleTile.find('.dham-name').val(record['dham_name']);
		sampleTile.find('.dham-address').val(record['dham_address']);
		sampleTile.find('.dham-comments').val(record['comments']);

		return sampleTile;
	}

	return {
		initModule: initModule,
		setConfig: function (key, value) {
			configMap[key] = value;
		},
	};
})(jQuery);
