import { FireStoreModule } from 'fb';
import firebase from 'fb/index';
import 'firebase/firestore';
import { updateSubscriber } from 'store/data/journals/subscriber/actions';

export class SubscriberFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'subscriber';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: firebase.firestore.Firestore) => db.collection('subscriber');
	public getChangeProcessAction = (changes) => updateSubscriber(changes);
}
