import { HeaderControls, HeaderModule } from 'components/header/anand.header';
import { Records } from 'core/anand.records';
import { utils as Utils } from 'core/anand.utils';
import { addFormHTML, briefTileHTML, detailFormHTML, detailTileHTML } from './viewTile';

export const RecordsHandler = (function ($) {
	var configMap = {};

	var stateMap: any = {
		systemId: 'sangat',
	};

	function initModule(props?, updateRecordsState?) {
		HeaderModule.initModule([
			HeaderControls.addRecordBtn,
			HeaderControls.selectDropdown,
			HeaderControls.printBtn,
			HeaderControls.sortBtn,
		]);

		Records.projectTitle = 'Sangat Records';

		Records.getAnySearchFilter = getAnySearchFilter;
		Records.defaultSortOrder = 'festival_date';
		Records.IdentifyingFieldName = 'id';

		Records.sortingFieldsMap = {
			festival_date: 'Date',
			festival_name: 'Festival',
			sangat_count: 'Sangat',
		};

		Records.searchFieldsMap = {
			festival_date: 'Date',
			festival_name: 'Festival',
			sangat_count: 'Sangat',
		};

		Records.isSearchDateTypeColumn = function (searchBy) {
			if (searchBy === 'festival_date') {
				return true;
			} else {
				return false;
			}
		};

		Records.printOptions = {
			fieldNames: ['S. No.', 'Date', 'Festival', 'Sangat'],
			fieldKeys: ['id', 'festival_date', 'festival_name', 'sangat_count'],

			defaultFieldWidths: { id: 60, festival_date: 280, festival_name: 500, sangat_count: 200 },
			defaultHeader: 'Sangat Records',
			beforePrintHook: function (data) {
				for (var i = 0; i < data.length; i++) {
					data[i]['id'] = i + 1 + '.';
				}

				return data;
			},
		};

		Records.exportOptions = {
			fieldKeys: ['festival_date', 'festival_name', 'sangat_count'],
			defaultFileName: 'Sangat Records',
		};

		Records.detailTileSelector = '#sangat-records-detail-record';
		Records.briefTileSelector = '#sangat-records-brief-record';
		Records.addTileSelector = '#sangat-records-add-form';
		Records.detailFormSelector = '#sangat-records-edit-form';

		Records.detailTileHTML = detailTileHTML;
		Records.briefTileHTML = briefTileHTML;
		Records.detailFormHTML = detailFormHTML;
		Records.addFormHTML = addFormHTML;

		Records.populateBriefTile = populateBriefTile;
		Records.populateDetailTile = populateDetailTile;
		Records.populateDetailForm = populateDetailForm;

		Records.updateRecordsState = updateRecordsState;

		Records.initModule({ systemId: stateMap.systemId, prepareRecord: prepareRecord, props: props });
	}

	function prepareRecord(record) {
		record['festival_date'] = Utils.formatDate(record['festival_date']);

		return record;
	}

	function getAnySearchFilter(query) {
		return [
			{ festival_date: { likenocase: query } },
			{ festival_name: { likenocase: query } },
			{ sangat_count: { leftnocase: query } },
		];
	}

	async function populateBriefTile(sampleTile, record) {
		return populateDetailTile(sampleTile, record);
	}

	async function populateDetailTile(sampleTile, recordOrig) {
		var record = {};
		for (var key in recordOrig) {
			if (recordOrig[key] == null || recordOrig[key] == '') {
				record[key] = '&nbsp';
			} else {
				record[key] = recordOrig[key];
			}
		}

		sampleTile.find('.festival-name').html(record['festival_name']);

		var dateAr = record['festival_date'].split('-');
		if (dateAr.length === 3) {
			var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
			dateStr = Utils.getDateStr(dateAr[0], dateAr[1], dateAr[2]);
			sampleTile.find('.festival-date').html(dateStr);
		} else {
			sampleTile.find('.festival-date').html(record['festival_date']);
		}

		sampleTile.find('.sangat-count').html(record['sangat_count']);

		return sampleTile;
	}

	function populateDetailForm(sampleTile, record) {
		sampleTile.find('.festival-name').attr('value', record['festival_name']);

		var dateAr = record['festival_date'].split('-');
		if (dateAr.length === 3) {
			var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
			sampleTile.find('.festival-date').attr('value', dateStr);
			sampleTile.find('.festival-date').datepicker('update', dateStr);
		} else {
			sampleTile.find('.festival-date').attr('value', record['festival_date']);
			sampleTile.find('.festival-date').datepicker('update', record['festival_date']);
		}

		sampleTile.find('.sangat-count').attr('value', record['sangat_count']);

		return sampleTile;
	}

	return {
		initModule: initModule,
		setConfig: function (key, value) {
			configMap[key] = value;
		},
	};
})(jQuery);
