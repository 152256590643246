import { AppInterface } from "AppInterface/AppInterface";
import { routerMiddleware } from "connected-react-router";
import { appEpic } from "epics/AppEpic";
import { eventsEpic } from "epics/EventsEpic";
import { opsEpic } from "epics/OpsEpic";
import { filterDataEpic } from "epics/TimeBasedDataEpic";
import { userDataEpic } from "epics/UserDataEpic";
import { InitFireStoreModules } from "fb";
import { createBrowserHistory } from "history";
import storage from "localforage";
import { Action, AnyAction, applyMiddleware, createStore, Reducer } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { createMigrate, createTransform, PersistConfig, Persistor, persistReducer, persistStore } from "redux-persist";
import thunkMiddleware from "redux-thunk";
import { ApplicationState } from "types";
import { migrations } from "./migrations";
import { initialState as is0 } from "./migrations/0";
import { createRootReducer } from "./reducers";

//'redux-persist/lib/storage';
// defaults to localStorage for web

let basename = process.env.PUBLIC_URL || "/";
export const history = createBrowserHistory({ basename });
const prevHistoryPush = history.push;
let lastLocation = history.location;

history.listen((location) => {
    lastLocation = location;
});
history.push = (pathname, state = {}) => {
    if (
        lastLocation === null ||
        pathname !== lastLocation.pathname + lastLocation.search + lastLocation.hash ||
        JSON.stringify(state) !== JSON.stringify(lastLocation.state)
    ) {
        prevHistoryPush(pathname, state);
    }
};

// if (isApple()) {
// storage.setDriver(storage.LOCALSTORAGE);
// }

const filteredBlacklist = createTransform(
    (state: any, key) => {
        const newState = { ...state };
        newState.mjbj = { ...newState.mjbj, filtered: [] };
        newState.anumati = { ...newState.anumati, filtered: [] };
        return newState;
    },
    (state: any, key) => {
        let reState = store.getState().dataState;
        return { ...reState, ...state };
    },
    { whitelist: ["dataState"] }
);

const persistConfig: PersistConfig<any> = {
    version: 35,
    key: "AnandStateRoot" + basename,
    storage,
    blacklist: ["tempState", "router"],
    transforms: [filteredBlacklist],
    migrate: createMigrate(migrations),
    timeout: 0,
    // throttle: 2000,
};

const persistedReducer: Reducer<any, AnyAction> = persistReducer(persistConfig, createRootReducer(history)) as unknown as Reducer<any, AnyAction>;

const epicMiddleware = createEpicMiddleware();

let enhancer = applyMiddleware(routerMiddleware(history), thunkMiddleware, epicMiddleware);

// if (process.env.NODE_ENV !== "production") {
//     enhancer = composeWithDevTools(enhancer);
// }

const store = createStore<ApplicationState, Action, unknown, unknown>(persistedReducer, is0, enhancer);

let appInterface = new AppInterface(store.getState, store.dispatch);

let rootEpic = combineEpics(eventsEpic, opsEpic, filterDataEpic, userDataEpic, appEpic);
epicMiddleware.run(rootEpic);

let modulesInit = new InitFireStoreModules(store.getState, store.dispatch);

// if (process.env.NODE_ENV !== 'production') {
// 	if (module.hot) {
// 		module.hot.accept('./nav/reducers', () => {
// 			store.replaceReducer(persistedReducer);
// 		});
// 	}
// }

const persistor: Persistor = persistStore(store);

export { appInterface, modulesInit, persistor, store };
