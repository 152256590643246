import { isMobile } from 'helpers';
import { populateViewTileHelper } from 'helpers/RecordsHelper';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, MomentRecord } from 'types';

export const detailTileHTML = `
<div id="holy-moments-detail-record" class="tile holy-moments-record detail">
	<div class="row">                
		<div class="title col-md-2 hidden-xs hidden-sm">
			<span>Date:</span>
		</div>
		<div class="moment-date col-xs-12 col-md-10">                    
		</div>
	</div>
	<div class="row">                
		<div class="title col-md-2 hidden-xs hidden-sm">
			<span>Picture:</span>
		</div>
		<div class="col-xs-12 col-md-10">
			<img class="moment-image" />           						
		</div>
	</div>
	<div class="row">                
		<div class="title col-md-2">
			<span>Description:</span>
		</div>
		<div class="col-xs-12 col-md-10">
			<div class="moment-desc">
			</div>
		</div>
		<div class="col-xs-12 col-md-10 col-md-push-2">
			<div class="moment-desc hindi">
			</div>
		</div>
	</div>
	<div class="record-id">
		<span></span>
	</div>
</div>
`;

export const briefTileHTML = `
<div id="holy-moments-brief-record" class="tile holy-moments-record brief">
	<div class="row">                
		<div class="col-xs-12">
			<div class="moment-date">

			</div>
		</div>

		<div class="col-xs-12">
			<img class="moment-image" />           						
		</div>

		<div class="col-xs-12">
			<div class="moment-desc">

			</div>
		</div>
	</div>
	<div class="record-id">
		<span></span>
	</div>
</div>
`;

export const detailFormHTML = `
<form id="holy-moments-edit-form" class="tile holy-moments-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="title hidden-xs hidden-sm col-md-2">
			<span>Date:</span>
		</div>
		<div class="value col-xs-12 col-md-10">
			<input type="text" class="moment-date date value" value="" name="moment_date" placeholder="Date"/>
		</div>
	</div>
	<div class="row">
		<div class="title hidden-xs hidden-sm col-md-2">
			<span>Picture:</span>
		</div>
		<div class="col-xs-12 col-md-10">
			<div class="block image-upload-container">
				<img class="moment-image button" src="/assets/img/null" />
				<input type="file" name="image" accept="image/*">
			</div>
		</div>
	</div>
	<div class="row">
		<div class="title col-xs-2">
			<span>Description:</span>
		</div>
		<div class="col-xs-12 col-md-10">
			<textarea class="moment-desc" name="moment_desc" rows="3" placeholder="Description in English"></textarea>
		</div>
		<div class="col-xs-12 col-md-10 col-md-push-2">
			<textarea class="moment-desc hindi" name="moment_desc_hindi" rows="3" placeholder="Description in Hindi"></textarea>
		</div>
	</div>
</form>
`;

export const addFormHTML = `
<form id="holy-moments-add-form" class="tile holy-moments-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="title hidden-xs hidden-sm col-md-2">
			<span>Date:</span>
		</div>
		<div class="value col-xs-12 col-md-10">
			<input type="text" class="moment-date date value" value="" name="moment_date" placeholder="Date"/>
		</div>
	</div>
	<div class="row">
		<div class="title hidden-xs hidden-sm col-md-2">
			<span>Picture:</span>
		</div>
		<div class="col-xs-12 col-md-10">
			<div class="block image-upload-container">
				<img class="moment-image button" src="/assets/img/null" />
				<input type="file" name="image" accept="image/*">
			</div>
		</div>
	</div>
	<div class="row">
		<div class="title col-xs-2">
			<span>Description:</span>
		</div>
		<div class="col-xs-12 col-md-10">
			<textarea class="moment-desc" name="moment_desc" rows="3" placeholder="Description in English"></textarea>
		</div>
		<div class="col-xs-12 col-md-10 col-md-push-2">
			<textarea class="moment-desc hindi" name="moment_desc_hindi" rows="3" placeholder="Description in Hindi"></textarea>
		</div>
	</div>
</form>
`;
interface Props {
	record?: MomentRecord;
	index?: number;
	listRef?: React.RefObject<any>;
}

class Component extends React.PureComponent<Props> {
	private tileRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	componentDidUpdate(prevProps, prevState) {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	render() {
		return (
			<div
				ref={this.tileRef}
				dangerouslySetInnerHTML={{ __html: isMobile() ? briefTileHTML : detailTileHTML }}
			></div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {};
}

export const ViewTile = connect(mapStateToProps)(Component);
