import AnumatiPage from "components/pages/AnumatiPage";
import BookPage from "components/pages/BookPage";
import DhamPage from "components/pages/DhamPage";
import HomePage from "components/pages/HomePage";
import IDCardPage from "components/pages/IDCardPage";
import CategoryPage from "components/pages/journals/CategoryPage";
import PincodePage from "components/pages/journals/PincodePage";
import RatePage from "components/pages/journals/RatePage";
import SubscriberPage from "components/pages/journals/SubscriberPage";
import ThroughPage from "components/pages/journals/ThroughPage";
import KutiyaPage from "components/pages/KutiyaPage";
import MjBjPage from "components/pages/MjBjPage";
import MomentPage from "components/pages/MomentPage";
import SangatPage from "components/pages/SangatPage";
import SignInPage from "components/pages/SignInPage";
import TrusteePage from "components/pages/TrusteePage";
import UserOptionsPage from "components/pages/UserOptionsPage";
import { logEvent } from "components/telemetry";
import { AdminRoutes, AppRoutes } from "config/route";
import { isAdmin } from "helpers";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { updateAppBarTitle } from "store/temp/actions";
import { ApplicationState, UserData } from "types";

const pageComponentMap = {
    Home: HomePage,
    SignIn: SignInPage,
    Anumati: AnumatiPage,
    MjBj: MjBjPage,
    Kutiya: KutiyaPage,
    Trustee: TrusteePage,
    Moment: MomentPage,
    Book: BookPage,
    Sangat: SangatPage,
    Dham: DhamPage,
    IDCard: IDCardPage,
    Category: CategoryPage,
    Pincode: PincodePage,
    Rate: RatePage,
    Through: ThroughPage,
    Subscriber: SubscriberPage,
    UserOptions: UserOptionsPage,
};

interface PageRouterProps {
    updateAppBarTitle: (string) => void;
    location: any;
    userData?: UserData;
    user?: firebase.default.User;
}

export class PageRouter extends React.Component<PageRouterProps> {
    private previousLocation: any;

    rectifyPath(location) {
        if (location.search.startsWith("?r=")) {
            location.pathname = location.search.replace("?r=", "");
            location.search = "";
        }
    }

    constructor(props: PageRouterProps) {
        super(props);

        this.rectifyPath(this.props.location);
        this.previousLocation = this.props.location;

        logEvent("page_view", { location: this.props.location.pathname });
    }

    componentWillUpdate() {
        const { location } = this.props;

        this.rectifyPath(location);

        if (this.previousLocation.pathname !== location.pathname) {
            logEvent("page_view", { path: location.pathname });
            // refreshRecords();
        }

        if (!(location.state && location.state.isModal)) {
            this.previousLocation = location;
        }
    }

    render() {
        const { location, userData } = this.props;
        this.rectifyPath(location);

        const isModal = location.state && location.state.isModal && this.previousLocation.pathname !== location.pathname;

        let appRoutes;
        if (isAdmin(userData)) {
            appRoutes = [...AdminRoutes, ...AppRoutes];
        } else {
            appRoutes = AppRoutes;
        }

        if (!this.props.user || !this.props.userData || !this.props.userData.roles) {
            return (
                <Route>
                    <SignInPage />
                </Route>
            );
        }

        let routeCases = appRoutes.map((route, index) => {
            if (route.roles) {
                let userRoles = this.props.userData?.roles ?? [];
                let canEdit = userRoles.indexOf(route.roles.edit) >= 0;
                let canView = canEdit || userRoles.indexOf(route.roles.view) >= 0 || userRoles.indexOf(route.roles.lview) >= 0;

                if (!canView) {
                    return (
                        <Route path={route.to} key={"route-key-" + route.to}>
                            <HomePage />
                        </Route>
                    );
                }
            }

            let PageComponent = pageComponentMap[route.page || "Home"] || HomePage;
            return (
                <Route exact path={route.to} key={"route-key-" + route.to}>
                    <PageComponent routeState={route.state} />
                </Route>
            );
        });

        return (
            <React.Fragment>
                <Switch location={isModal ? this.previousLocation : location}>
                    {routeCases}
                    <Route>
                        <HomePage />
                    </Route>
                </Switch>
                {isModal
                    ? appRoutes.map((route, index) => {
                          let PageComponent = pageComponentMap[route.page || "Home"] || HomePage;
                          return route.state?.isModal ? (
                              <Route exact path={route.to} key={"modal-route-key-" + route.to}>
                                  <PageComponent isModal={true} routeState={route.state} />
                              </Route>
                          ) : null;
                      })
                    : null}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: ApplicationState) {
    return {
        userData: state.dataState.userStore.userData,
        user: state.dataState.userStore.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateAppBarTitle: (title: string) => {
            dispatch(updateAppBarTitle(title));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageRouter));
