import { FireStoreModule } from 'fb';
import firebase from 'fb/index';
import 'firebase/firestore';
import { updateMjBJ } from 'store/data/mjbj/actions';

export class MjBjFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'mjbj';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: firebase.firestore.Firestore) => db.collection('mjbj');
	public getChangeProcessAction = (changes) => updateMjBJ(changes);
}
