import { combineReducers } from 'redux';
import { AnumatiRecord, AnumatiStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateAnumati, UpdateAnumatiFiltered, UPDATE_ANUMATI, UPDATE_ANUMATI_FILTERED } from './actions';

export const anumatiByIdReducer = (state = {}, action: UpdateAnumati) => {
	switch (action.type) {
		case UPDATE_ANUMATI:
			return updateData(state, action.changes, 'anumati');
		default:
			return state;
	}
};

const filteredAnumatiReducer = (state: AnumatiRecord[] = [], action: UpdateAnumatiFiltered) => {
	switch (action.type) {
		case UPDATE_ANUMATI_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const anumatiReducer = combineReducers<AnumatiStore>({
	byId: anumatiByIdReducer,
	filtered: filteredAnumatiReducer,
});
