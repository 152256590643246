import { combineReducers } from 'redux';
import { IDCardRecord, IDCardStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateIdcard, UpdateIdcardFiltered, UPDATE_IDCARD, UPDATE_IDCARD_FILTERED } from './actions';

export const idcardByIdReducer = (state = {}, action: UpdateIdcard) => {
	switch (action.type) {
		case UPDATE_IDCARD:
			return updateData(state, action.changes, 'idcard');
		default:
			return state;
	}
};

const filteredIdcardReducer = (state: IDCardRecord[] = [], action: UpdateIdcardFiltered) => {
	switch (action.type) {
		case UPDATE_IDCARD_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const idcardReducer = combineReducers<IDCardStore>({
	byId: idcardByIdReducer,
	filtered: filteredIdcardReducer,
});
