import { createStyles, CssBaseline, IconButton, makeStyles, Theme, withStyles } from "@material-ui/core";
import App from "AppInterface/App";
import { Notifier } from "components/notifier/Notifier";
import StatefulThemeProvider from "components/providers/StatefulThemeProvider";
import AlertDialog from "components/shell/AlertDialog";
import AppBar from "components/shell/AppBar";
import BottomDrawer from "components/shell/BottomDrawer";
import OnDemandPage from "components/shell/OnDemandPage";
import PageRouter from "components/shell/PageRouter";
import ProgressBar from "components/shell/ProgressBar";
import { logEvent } from "components/telemetry";
import { Icons } from "config/icons";
import { ConnectedRouter } from "connected-react-router";
import "fonts/surekhbold.woff2";
import "fonts/surekhnormal.woff2";
import { getBuildVersion, hasPermissionOn, isAdmin, isMobile, timeout } from "helpers";
import { SnackbarProvider } from "notistack";
import React from "react";
import { connect, Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// import 'scss/anand.content.scss';
// import 'scss/anand.scss';
import { AnumatiFireStoreModule } from "services/api/anumati";
import { BookFireStoreModule } from "services/api/book";
import { DhamFireStoreModule } from "services/api/dham";
import { IDCardFireStoreModule } from "services/api/idcard";
import { CategoryFireStoreModule } from "services/api/journals/category";
import { PincodeFireStoreModule } from "services/api/journals/pincode";
import { RateFireStoreModule } from "services/api/journals/rate";
import { SubscriberFireStoreModule } from "services/api/journals/subscriber";
import { ThroughFireStoreModule } from "services/api/journals/through";
import { KutiyaFireStoreModule } from "services/api/kutiya";
import { MjBjFireStoreModule } from "services/api/mjbj";
import { MomentFireStoreModule } from "services/api/moment";
import { SangatFireStoreModule } from "services/api/sangat";
import { TrusteeFireStoreModule } from "services/api/trustee";
import { initializeFilteredData, webAppInitialized } from "store/temp/actions";
import { updateAppParams } from "store/ux/actions";
import { ApplicationState, AppParams, UserData } from "types";
import "./App.css";
import { appInterface, history, modulesInit, persistor, store } from "./store";

class ErrorBoundary extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log("ErrorBoundary", error, errorInfo);
        let stack = {};
        errorInfo?.componentStack
            ?.split("\n")
            .map((line) => line.substr(0, 100))
            .forEach((line, index) => (index ? (stack["stack" + index] = line) : null));

        logEvent("JSErrorBoundary", { errorStr: error?.toString()?.substr(0, 90), ...stack });
        // setTimeout(() => window.location.href = "/", 1000);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div>
                    <h4>Something went wrong.</h4>
                    {/* <p>{JSON.stringify(this.state.error)}</p> */}
                </div>
            );
        }

        return this.props.children;
    }
}

window.onerror = function (msg, url, line, columnNo, error) {
    if (msg === "ResizeObserver loop limit exceeded") {
        return false;
    }

    console.log("OnError: ", msg, url, line, columnNo, error?.stack);

    let stack = {};
    error?.stack
        ?.split("\n")
        .map((line) => line.substr(0, 100))
        .forEach((line, index) => (index ? (stack["stack" + index] = line) : null));

    logEvent("JSOnError", { message: msg.toString().substr(0, 90), url: url, line: line, columnNo, ...stack });
    return false;
};

window.addEventListener("error", function (e) {
    if (e.message === "ResizeObserver loop limit exceeded" || e.error?.message === "ResizeObserver loop limit exceeded") {
        return false;
    }

    let error = e.error;
    console.log("Error occurred: ", error?.message, error?.stack);

    let stack = {};
    error?.stack
        ?.split("\n")
        .map((line) => line.substr(0, 100))
        .forEach((line, index) => (index ? (stack["stack" + index] = line) : null));

    logEvent("JSErrorEvent", {
        message: (error?.message ?? e.message).substr(0, 90),
        line: e.lineno,
        col: e.colno,
        file: e.filename,
        ...stack,
    });
    return false;
});

const styles = (theme: Theme) => ({
    root: {
        height: "100%",
    },
});

interface WebAppComponentProps {
    userData?: UserData;
    updateAppParams: (appParams: AppParams) => void;
    classes: any;
    webAppInitialized: () => void;
    // articles: any[];
    filterInit: () => void;
}

class WebAppComponent extends React.Component<WebAppComponentProps, { init: boolean }> {
    constructor(props) {
        super(props);

        // setTimeout(this.props.filterInit, 0);

        this.state = {
            init: false,
        };

        this.props.filterInit();
    }

    componentDidUpdate() {
        if (!isAdmin(this.props.userData)) {
            let style = document.createElement("style");
            style.textContent = "* { -webkit-user-select: none; }";
            window.document.head?.appendChild(style);
        } else {
            let style = document.createElement("style");
            style.textContent = "* { -webkit-user-select: auto; }";
            window.document.head?.appendChild(style);
        }
    }

    componentWillReceiveProps = (newProps) => {
        let modules = [];
        if (!hasPermissionOn("mjbj", this.props.userData) && hasPermissionOn("mjbj", newProps.userData)) {
            modules.push(new MjBjFireStoreModule());
        }
        if (!hasPermissionOn("anumati", this.props.userData) && hasPermissionOn("anumati", newProps.userData)) {
            modules.push(new AnumatiFireStoreModule());
        }
        if (!hasPermissionOn("kutiya", this.props.userData) && hasPermissionOn("kutiya", newProps.userData)) {
            modules.push(new KutiyaFireStoreModule());
        }
        if (!hasPermissionOn("trustee", this.props.userData) && hasPermissionOn("trustee", newProps.userData)) {
            modules.push(new TrusteeFireStoreModule());
        }
        if (!hasPermissionOn("moment", this.props.userData) && hasPermissionOn("moment", newProps.userData)) {
            modules.push(new MomentFireStoreModule());
        }
        if (!hasPermissionOn("idcard", this.props.userData) && hasPermissionOn("idcard", newProps.userData)) {
            modules.push(new IDCardFireStoreModule());
        }
        if (!hasPermissionOn("book", this.props.userData) && hasPermissionOn("book", newProps.userData)) {
            modules.push(new BookFireStoreModule());
        }
        if (!hasPermissionOn("sangat", this.props.userData) && hasPermissionOn("sangat", newProps.userData)) {
            modules.push(new SangatFireStoreModule());
        }
        if (!hasPermissionOn("dham", this.props.userData) && hasPermissionOn("dham", newProps.userData)) {
            modules.push(new DhamFireStoreModule());
        }
        if (!hasPermissionOn("category", this.props.userData) && hasPermissionOn("category", newProps.userData)) {
            modules.push(new CategoryFireStoreModule());
        }
        if (!hasPermissionOn("pincode", this.props.userData) && hasPermissionOn("pincode", newProps.userData)) {
            modules.push(new PincodeFireStoreModule());
        }
        if (!hasPermissionOn("rate", this.props.userData) && hasPermissionOn("rate", newProps.userData)) {
            modules.push(new RateFireStoreModule());
        }
        if (!hasPermissionOn("through", this.props.userData) && hasPermissionOn("through", newProps.userData)) {
            modules.push(new ThroughFireStoreModule());
        }
        if (!hasPermissionOn("subscriber", this.props.userData) && hasPermissionOn("subscriber", newProps.userData)) {
            modules.push(new SubscriberFireStoreModule());
        }

        if(modules.length) {
            // let configInit = () => modulesInit.initModules([new ConfigsFireStoreModule()]);
            let allInit = () => modulesInit.initModules(modules, true);
            allInit();
        }
    };

    componentDidMount = async () => {
        let webappBuildVersion = getBuildVersion();

        console.log("Build Version: " + webappBuildVersion);

        let modules = [];
        if (hasPermissionOn("mjbj", this.props.userData)) {
            modules.push(new MjBjFireStoreModule());
        }
        if (hasPermissionOn("anumati", this.props.userData)) {
            modules.push(new AnumatiFireStoreModule());
        }
        if (hasPermissionOn("kutiya", this.props.userData)) {
            modules.push(new KutiyaFireStoreModule());
        }
        if (hasPermissionOn("trustee", this.props.userData)) {
            modules.push(new TrusteeFireStoreModule());
        }
        if (hasPermissionOn("moment", this.props.userData)) {
            modules.push(new MomentFireStoreModule());
        }
        if (hasPermissionOn("idcard", this.props.userData)) {
            modules.push(new IDCardFireStoreModule());
        }
        if (hasPermissionOn("book", this.props.userData)) {
            modules.push(new BookFireStoreModule());
        }
        if (hasPermissionOn("sangat", this.props.userData)) {
            modules.push(new SangatFireStoreModule());
        }
        if (hasPermissionOn("dham", this.props.userData)) {
            modules.push(new DhamFireStoreModule());
        }
        if (hasPermissionOn("pincode", this.props.userData)) {
            modules.push(new PincodeFireStoreModule());
        }
        if (hasPermissionOn("category", this.props.userData)) {
            modules.push(new CategoryFireStoreModule());
        }
        if (hasPermissionOn("rate", this.props.userData)) {
            modules.push(new RateFireStoreModule());
        }
        if (hasPermissionOn("through", this.props.userData)) {
            modules.push(new ThroughFireStoreModule());
        }
        if (hasPermissionOn("subscriber", this.props.userData)) {
            modules.push(new SubscriberFireStoreModule());
        }

        // let configInit = () => modulesInit.initModules([new ConfigsFireStoreModule()]);
        let allInit = () => modulesInit.initModules(modules, true);
        allInit();

        // if (!this.props.articles || !this.props.articles.length) {
        // await configInit();
        // await timeout(3000);
        // await allInit();
        // } else {
        // configInit();
        // allInit();
        // }

        (function () {
            var proxy = ($.fn as any).serializeObject;
            ($.fn as any).serializeObject = function () {
                var inputs = this.find(":disabled");
                inputs.prop("disabled", false);
                var serialized = proxy.apply(this, arguments);
                inputs.prop("disabled", true);
                return serialized;
            };
        })();

        // $.validator.addMethod(
        // 	'telInputValidator',
        // 	function (value: string, telInput: any) {
        // 		if ($.trim(value)) {
        // 			if (intlTelInput($(telInput)[0]).isValidNumber() === false) {
        // 				return false;
        // 			} else {
        // 				return true;
        // 			}
        // 		} else {
        // 			return false;
        // 		}
        // 	},
        // 	'Please provide a valid number'
        // );

        await appInterface.initialize();
        let appId = await App.getAppInstallationId();
        let appEnv = App.getAppEnv();
        let appVersion = App.getAppVersion();
        let appBuildCode = await App.getBuildVersionCode();

        this.props.updateAppParams({ appId, appEnv, appVersion, appBuildCode, webappBuildVersion });

        this.setState(
            {
                init: true,
            },
            async () => {
                await timeout(100);
                App.initialize(webappBuildVersion);
                this.props.webAppInitialized();
            }
        );

        if (!isAdmin(this.props.userData)) {
            let style = document.createElement("style");
            style.textContent = "* { -webkit-user-select: none; }";
            window.document.head?.appendChild(style);
        }
    };

    render() {
        let { classes, userData } = this.props;

        let init = this.state.init;
        if (init === false) {
            return (
                <div className={classes.root}>
                    {isMobile() || isAdmin(userData) ? <AppBar /> : <AppBar />}
                    {isMobile() || isAdmin(userData) ? <ProgressBar /> : null}
                </div>
            );
        }

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar />
                {/* <div id='main-wrapper'> */}
                {/* <div className='wrapper'> */}
                {/* <div id='header-container'></div> */}

                {/* <div id='page-container'> */}
                <PageRouter />
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}

                <div id='bottom-notification-container'>
                    <div id='bottom-notification-msg'></div>
                </div>

                <div id='overlay-screen'></div>

                <div id='loading-overlay'></div>

                <div id='footer-container'></div>

                <BottomDrawer />
                <AlertDialog />
                <OnDemandPage />
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState) {
    return {
        userData: state.dataState.userStore.userData,
        // articles: Object.keys(state.dataState.configs),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateAppParams: (appParams: AppParams) => {
            dispatch(updateAppParams(appParams));
        },
        webAppInitialized: () => {
            dispatch(webAppInitialized());
        },
        filterInit: () => {
            dispatch(initializeFilteredData());
        },
    };
}

const WebApp = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WebAppComponent));

// add action to all snackbars
export const notistackRef: React.RefObject<any> = React.createRef();
const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            bottom: "66px; bottom: calc(66px + env(safe-area-inset-bottom)/2)",

            "& .MuiSnackbarContent-message": {
                flex: 1,
            },
        },
        success: { backgroundColor: "#32b78d" },
    })
);

const AppRoot = () => {
    let classes = useStyles();

    return (
        <React.Fragment>
            <Provider store={store}>
                <PersistGate onBeforeLift={() => {}} persistor={persistor}>
                    <ConnectedRouter history={history} noInitialPop>
                        <StatefulThemeProvider>
                            <ErrorBoundary>
                                <SnackbarProvider
                                    ref={notistackRef}
                                    classes={{
                                        variantSuccess: classes.success,
                                        containerRoot: classes.root,
                                    }}
                                    maxSnack={3}
                                    preventDuplicate={true}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    autoHideDuration={6000}
                                    disableWindowBlurListener={true}
                                    action={(key) => (
                                        <IconButton style={{ color: "white" }} onClick={onClickDismiss(key)}>
                                            {Icons.Close}
                                        </IconButton>
                                    )}
                                >
                                    <Notifier />
                                    <WebApp />
                                </SnackbarProvider>
                            </ErrorBoundary>
                        </StatefulThemeProvider>
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        </React.Fragment>
    );
};

export default AppRoot; //process.env.NODE_ENV === 'development' ? hot(AppRoot) : AppRoot;
