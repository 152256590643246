import { AppDidBecomeActive } from 'AppInterface/AppInterface';
import { getServerTime } from 'fb';
import firebase from 'fb/initconfig';
import AnandDate from 'helpers/AnandDate';
import { combineEpics, ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { delay, mergeMap, takeUntil } from 'rxjs/operators';
import { UPDATE_ANUMATI } from 'store/data/anumati/actions';
import { UPDATE_FB_ARTICLES } from 'store/data/articles/actions';
import { UPDATE_BOOK } from 'store/data/book/actions';
import { UPDATE_DHAM } from 'store/data/dham/actions';
import { UPDATE_IDCARD } from 'store/data/idcard/actions';
import { UPDATE_CATEGORY } from 'store/data/journals/category/actions';
import { UPDATE_PINCODE } from 'store/data/journals/pincode/actions';
import { UPDATE_RATE } from 'store/data/journals/rate/actions';
import { UPDATE_SUBSCRIBER } from 'store/data/journals/subscriber/actions';
import { UPDATE_THROUGH } from 'store/data/journals/through/actions';
import { UPDATE_KUTIYA } from 'store/data/kutiya/actions';
import { UPDATE_MJBJ } from 'store/data/mjbj/actions';
import { UPDATE_MOMENT } from 'store/data/moment/actions';
import { UPDATE_SANGAT } from 'store/data/sangat/actions';
import { UPDATE_TRUSTEE } from 'store/data/trustee/actions';
import { UPDATE_USER_DATA } from 'store/data/user/actions';
import { FILTER_DATA_INIT } from 'store/temp/actions';
import { ApplicationState, FBData } from 'types';

const filterData = (action$, state$, type, stateSlice) => {
	let recordStore = (state$.value as ApplicationState).dataState[stateSlice];
	let settings = (state$.value as ApplicationState).dataState.userStore.userData?.settings;
	let showAll = (settings && settings['admin.records']) ?? false;

	let filtered: FBData[] = [];
	let now = getServerTime();
	let maxTime = new Date('9999-12-31').getTime();
	let nextUpdateTime = maxTime;
	let records = recordStore.byId;
	for (let id in records) {
		let record = records[id] as FBData;
		let publishTime = record.publishTime ? new firebase.firestore.Timestamp(record.publishTime.seconds, record.publishTime.nanoseconds).toMillis() : null;
		let expireTime = record.expireTime ? new firebase.firestore.Timestamp(record.expireTime.seconds, record.expireTime.nanoseconds).toMillis() : null;

		let forDate = record.forDate;

		if (record.deleted && !showAll) {
			continue;
		}

		if (record.active === false && !showAll) {
			continue;
		}

		if (expireTime && expireTime < now && !showAll) {
			continue;
		}

		if (publishTime && publishTime > now) {
			if (publishTime < nextUpdateTime) {
				nextUpdateTime = publishTime;
			}

			// if (!showAll) {
			// 	continue;
			// }
		}

		if (forDate && new AnandDate().format('YYYY-MM-DD') < forDate && !showAll) {
			continue;
		}

		if (forDate && new AnandDate().format('YYYY-MM-DD') === forDate) {
			nextUpdateTime = new AnandDate(forDate).add(24, 'hour').getDateObj().getTime();
		}

		if (expireTime && expireTime < nextUpdateTime) {
			nextUpdateTime = expireTime;
		}

		if (record.inApp) {
			record.inApp = Object.keys(record.inApp).length ? record.inApp : undefined;
		}
		filtered.push(record);
	}

	let result: any = of({
		type: type + '_FILTERED',
		payload: filtered,
	});
	if (nextUpdateTime < maxTime) {
		let delayDate = new Date();
		delayDate.setTime(nextUpdateTime);
		result = concat(result, of({ type: 'FILTER_' + type }).pipe(delay(delayDate), takeUntil(of({ type: type + '_FILTERED' }))));
	}

	return result;
};

const filterDataEpicFactory = (type, stateSlice) => {
	return (action$, state$) =>
		action$.pipe(
			ofType(type, 'FILTER_' + type, UPDATE_USER_DATA, FILTER_DATA_INIT, AppDidBecomeActive),
			mergeMap(() => filterData(action$, state$, type, stateSlice))
		);
};

export const filterDataEpic = combineEpics(
	filterDataEpicFactory(UPDATE_FB_ARTICLES, 'articles'),
	filterDataEpicFactory(UPDATE_MJBJ, 'mjbj'),
	filterDataEpicFactory(UPDATE_ANUMATI, 'anumati'),
	filterDataEpicFactory(UPDATE_KUTIYA, 'kutiya'),
	filterDataEpicFactory(UPDATE_MOMENT, 'moment'),
	filterDataEpicFactory(UPDATE_IDCARD, 'idcard'),
	filterDataEpicFactory(UPDATE_BOOK, 'book'),
	filterDataEpicFactory(UPDATE_SANGAT, 'sangat'),
	filterDataEpicFactory(UPDATE_DHAM, 'dham'),
	filterDataEpicFactory(UPDATE_TRUSTEE, 'trustee'),
	filterDataEpicFactory(UPDATE_CATEGORY, 'category'),
	filterDataEpicFactory(UPDATE_RATE, 'rate'),
	filterDataEpicFactory(UPDATE_THROUGH, 'through'),
	filterDataEpicFactory(UPDATE_PINCODE, 'pincode'),
	filterDataEpicFactory(UPDATE_SUBSCRIBER, 'subscriber')
);
