import isMobileJSLib from 'ismobilejs';
import { ApplicationState, ContentType, UserData } from 'types';
import AnandDate from './AnandDate';

declare var webkit: any;

const isMobileLib = isMobileJSLib(window.navigator);

export function inIframe() {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
}

export function isMobile() {
	return isMobileLib.phone || isMobileLib.tablet || inIframe() || !isWebEnv();
}

export function isTablet() {
	return isMobileLib.tablet;
}

export function isAndroid() {
	return /Android/i.test(navigator.userAgent);
}

export function isApple() {
	return /(iPhone|iPod|iPad)/i.test(navigator.userAgent);
}

export function isSafari() {
	var issafari =
		navigator.vendor &&
		navigator.vendor.indexOf('Apple') > -1 &&
		navigator.userAgent &&
		navigator.userAgent.indexOf('CriOS') == -1 &&
		navigator.userAgent.indexOf('FxiOS') == -1;

	issafari = issafari && /.*Version.*Safari.*/.test(navigator.userAgent);

	return issafari;
}

export function isWebViewEnv() {
	return false;
	// return App.getAppEnv() !== 'Web';
}

export function isWebEnv() {
	return true;
	// return !(
	// 	(typeof webkit !== 'undefined' && webkit.messageHandlers && webkit.messageHandlers.AnandApp) ||
	// 	window.AnandApp
	// );
	// return App.getAppEnv() === 'Web';
}

export function timeout(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function alignRhymes(el: HTMLElement) {
	if (!el) {
		return;
	}

	let rhymeContainers = el.getElementsByClassName('content-inline-rhymes');

	for (let i = 0; i < rhymeContainers.length; i++) {
		let rhymeContainer: HTMLElement = rhymeContainers[i] as HTMLElement;
		if (rhymeContainer.offsetParent === null) {
			// checks for visibility
			continue;
		}

		rhymeContainer.style.maxWidth = '';
		let rhymes = rhymeContainer.getElementsByTagName('rhyme-line');
		[].forEach.call(rhymes, (el) => {
			let rhyme = el as HTMLElement;
			rhyme.style.width = '';
			rhyme.classList.remove('rhyme-line-justified');
		});

		var maxLength = 0;
		for (var j = 0; j < rhymes.length; j++) {
			var length = rhymes[j].clientWidth;

			if (length > maxLength) {
				maxLength = length;
			}
		}

		// eslint-disable-next-line
		[].forEach.call(rhymes, (el) => {
			let rhyme = el as HTMLElement;
			rhyme.style.width = maxLength + 10 + 'px';
			rhyme.style.textAlign = '';
			rhyme.classList.add('rhyme-line-justified');
		});

		var rhymeAuthor = rhymeContainer.getElementsByTagName('rhyme-author')[0] as HTMLElement;
		if (rhymeAuthor) {
			rhymeAuthor.style.display = 'inline';
			rhymeAuthor.style.width = '';
			var rhymeAuthorWidth = rhymeAuthor.offsetWidth;
			rhymeContainer.style.maxWidth = maxLength + rhymeAuthorWidth + 'px';
			rhymeAuthor.style.maxWidth = maxLength + rhymeAuthorWidth + 'px';
			rhymeAuthor.style.display = 'block';
		}
	}
}

export function getMediaBasePath(audioUri?, createdAt?) {
	return process.env.REACT_APP_CDN_HOST;
}

export function getContentStoragePath(type, key, object?) {
	switch (type) {
		case ContentType.Article:
			return encodeURI('appstatic/media/' + (object?.mediaUri ?? ''));
		case ContentType.Edition:
			return encodeURI(
				'appstatic/publications/' +
					(key === 'cover' ? 'covers/' + (object?.coverpageUri ?? '') : 'files/' + (object?.fileUri ?? ''))
			);
		case ContentType.WebApp:
			return 'appstatic/img/webapp/' + key;
		case ContentType.PKP:
			return 'appstatic/img/' + key;
		case ContentType.Calendar:
			return 'appstatic/calendar/' + key;
		case ContentType.Data:
			return 'appstatic/data/' + key;
	}
	return '';
}

export function getContentStorageUrl(type, key, object?) {
	return getMediaBasePath() + getContentStoragePath(type, key, object);
}

export function getWebAppAssetPath(key) {
	return getContentStorageUrl(ContentType.WebApp, key);
}

export const isAdmin = (userData?: UserData) => {
	return userData && userData.roles ? userData.roles.indexOf('admin') > -1 : false;
};

export const isEditor = (userData?: UserData) => {
	return (
		userData && userData.roles && (userData.roles.indexOf('admin') > -1 || userData.roles.indexOf('editor') > -1)
	);
};

export const hasPermissionOn = (module: string, userData?: UserData) => {
	return (
		userData &&
		userData.roles &&
		(userData.roles.indexOf(module + '_edit') > -1 ||
			userData.roles.indexOf(module + '_view') > -1 ||
			userData.roles.indexOf(module + '_lview') > -1 ||
			userData.roles.indexOf(module + '_read') > -1)
	);
};

export const capitalize = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getBuildVersion = () => {
	return parseInt(process.env.REACT_APP_BUILD_VER ?? '0');
};

const sessionId = Math.floor(Math.random() * 1000);
export const getSessionId = () => {
	return sessionId;
};

export const getPublishedOn = (dateStr: string, includeDate: boolean) => {
	var date = new AnandDate(dateStr);

	var result;
	if (includeDate) {
		result = date.format('Do MMMM, YYYY');
	} else {
		result = date.format('MMMM, YYYY');
	}

	return result;
};

export const getFormattedTimeFromSec = (seconds: number) => {
	seconds = Math.ceil(seconds);

	if (seconds === 0) {
		return '00:00';
	}

	let timestr = '';
	let remainingSec = seconds;
	if (remainingSec >= 3600) {
		remainingSec = seconds % 3600;
		let hh = (seconds - remainingSec) / 3600;
		let hoursStr = hh.toString();
		if (hh < 10) {
			hoursStr = '0' + hoursStr;
		}
		timestr = hoursStr + ':';
		seconds = remainingSec;
	}

	remainingSec = seconds % 60;
	let mm = (seconds - remainingSec) / 60;

	let minuteStr = mm.toString();
	if (mm < 10) {
		minuteStr = '0' + minuteStr;
	}

	let secondsStr = remainingSec.toString();
	if (remainingSec < 10) {
		secondsStr = '0' + secondsStr;
	}

	timestr += minuteStr + ':' + secondsStr;

	return timestr;
};

export const getAudioFilenameFromSrc = (src: string) => {
	return src.substring(src.lastIndexOf('/') + 1);
};

export const shuffleArray = (array: any[]) => {
	var currentIndex = array.length,
		temporaryValue: any,
		randomIndex: number;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
};

export const updatePushSetting = (enable) => {
	// if (enable) {
	// 	App.subscribeToTopic('subscribed');
	// } else {
	// 	App.unsubscribeFromTopic('subscribed');
	// }
};

export function getUserPermissionsHelper(store, systemId) {
	let state: ApplicationState = store.getState();
	let userRoles = state.dataState.userStore.userData?.roles ?? [];
	let canEdit = userRoles.indexOf(systemId + '_edit') >= 0;
	let canRead = canEdit || userRoles.indexOf(systemId + '_view') >= 0 || userRoles.indexOf(systemId + '_lview') >= 0 || userRoles.indexOf(systemId + '_read') >= 0;
	var data = {
		viewAllowed: canRead,
		editAllowed: canEdit,
	};

	return data;
}
