import { FBUserPlaylist, FBUserPlaylists, UserStore } from 'types';
import {
	UpdateFBUserPlaylists,
	UpdateUser,
	UpdateUserData,
	UPDATE_FB_USER_PLAYLISTS,
	UPDATE_USER,
	UPDATE_USER_DATA,
	UPDATE_FB_USER_PLAYLISTS_LOCAL,
	UpdateFBUserPlaylistsLocal,
} from './actions';

export function userStoreReducer(state: UserStore = {}, action: UpdateUser | UpdateUserData) {
	switch (action.type) {
		case UPDATE_USER:
			return { ...state, user: action.user };
		case UPDATE_USER_DATA:
			return { ...state, userData: action.userData };
		default:
			return state;
	}
}

export function fbUserPlaylistsReducer(
	curState: FBUserPlaylists = {},
	action: UpdateFBUserPlaylists | UpdateFBUserPlaylistsLocal
) {
	switch (action.type) {
		case UPDATE_FB_USER_PLAYLISTS:
			var state = { ...curState };

			let { changes, userId, loggedInUserId } = action;

			if (userId !== loggedInUserId) {
				return curState;
			}

			changes.forEach(function (change) {
				let changeData = change.doc.data();
				changeData.id = change.doc.id;
				changeData.userId = userId;

				let playlist = changeData as FBUserPlaylist;

				if (change.type === 'added' || change.type === 'modified') {
					let tracks = playlist.tracks || {};
					for (let id in tracks) {
						let track = tracks[id];
						track.articleId = id;
					}

					state[change.doc.id] = playlist;
				}
				if (change.type === 'removed') {
					console.log('Ignoring Remove Event: ' + playlist);
					// delete state[change.doc.id];
				}
			});

			return state;
		case UPDATE_FB_USER_PLAYLISTS_LOCAL:
			return { ...action.playlist };
		default:
			return curState;
	}
}
