import clsx from 'clsx';
import React from 'react';
import TextContent from 'components/TextContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { MainRhyme } from 'config';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		rhymes: {
			[theme.breakpoints.up('xl')]: {
				'& > .locale-hi': {
					fontSize: '2.9vw',

					'& .rhyme-line-justified': {
						width: '55%',
					},
				},
			},
			[theme.breakpoints.only('lg')]: {
				'& > .locale-hi': {
					fontSize: '3.1vw',

					'& .rhyme-line-justified': {
						width: '60%',
					},
				},
			},
			[theme.breakpoints.only('md')]: {
				'& > .locale-hi': {
					fontSize: '3.6vw',

					'& .rhyme-line-justified': {
						width: '70%',
					},
				},
			},
			[theme.breakpoints.down('sm')]: {
				'& > .locale-hi': {
					fontSize: '4.1vw',

					'& .rhyme-line-justified': {
						width: '80%',
					},
				},
			},

			color: 'white',
			textAlign: 'center' as 'center',
			padding: '2em 0em 0.5em 0',
			textShadow: '0.1em 0.1em 0.2em blue',
			lineHeight: 1,
			fontWeight: 'bold' as 'bold',

			'& .rhyme-line': {
				'&.rhyme-line-justified': {
					marginBottom: '-0.6em',
				},
			},

			'& .locale-hi': {
				color: 'white',
			},
		},
	})
);

export const BannerRhymeCtrl = (props) => {
	let classes = useStyles();
	let { className, ...others } = props;
	return (
		<div {...others} className={clsx(classes.rhymes, className)}>
			<TextContent locale='hi' alignRhymes={true} useFontSize={false}>
				<span dangerouslySetInnerHTML={{ __html: props.rhyme || MainRhyme }} />
			</TextContent>
		</div>
	);
};
