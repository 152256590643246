import { FireStoreModule } from 'fb';
import firebase from 'fb/index';
import 'firebase/firestore';
import { updateTrustee } from 'store/data/trustee/actions';

export class TrusteeFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'trustee';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: firebase.firestore.Firestore) => db.collection('trustee');
	public getChangeProcessAction = (changes) => updateTrustee(changes);
}
