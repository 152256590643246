import { HeaderControls, HeaderModule } from 'components/header/anand.header';
import { ssnDefaultExpiryKey, ssnDefaultPublishKey, ssnStartingCardKey } from 'config';
import { ModelClass } from 'core/anand.model';
import { notification } from 'core/anand.notification';
import { overlay_panel } from 'core/anand.overlay_panel';
import { Records } from 'core/anand.records';
import { utils as Utils } from 'core/anand.utils';
import { setRecord } from 'fb';
import { getUserPermissionsHelper, isAdmin, timeout } from 'helpers';
import AnandDate from 'helpers/AnandDate';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { store } from 'store';
import { ApplicationState } from 'types';
import { v1 as uuidv1 } from 'uuid';
import firebase from '../../fb/initconfig';
const numWords = require('num-words');

export const AnumatiPatra = (function ($) {
	var configMap = {
		publication: {
			name: 'Anumati Patra',
		},
		lastPrintedRecordId: '--last-prnted--',
		startDate: new Date('2023-05-07'),
		startingCardKey: ssnStartingCardKey,
	};

	var stateMap: any = {
		systemId: 'anumati',
		lastPrinted: 0,
		refFilter: [],
	};

	function printHtml(e?, records?) {
		Records.refreshRecordsAndReposition();

		var printConfigHtml = `<div id="print-configs">
								<div class="col-xs-12">
									<label class="checkbox" style="display:inline;padding:1em">
										<input style="width:auto" type="checkbox" class="complete-card" />
										Print Complete Card
									</label>
									<label class="checkbox" style="display:inline;padding:1em">
										<input style="width:auto" type="checkbox" class="auto-mark-printed" />
										Auto Mark as Printed
									</label>
								</div>`;

		var printBtnHtml = `
			<button id="print_records_btn" class="button btn btn-primary">
				<span><span>Print</span></span>
			</button>
			<button id="mark_printed_records_btn" class="button btn btn-primary">
				<span><span>Mark Printed</span></span>
			</button>
			<!-- <button id="send_sms_records_btn" class="button btn btn-primary">
				<span><span>Send SMS</span></span>
			</button> -->
			<button id="send_ams_records_btn" class="button btn btn-primary">
				<span><span>Send AMS</span></span>
			</button>
		`;

		printConfigHtml += '<iframe id="print-preview" name="print-preview" src="about:blank"></iframe>';
		printConfigHtml += '</div>';

		var printConfigObj = {
			contentHtml: printConfigHtml,
			id: 'print_config',
			title: 'Print Card',
			headerBtnHtml: printBtnHtml,
		};

		overlay_panel.showPanel(printConfigObj);

		$('#print-configs select').on('change', () => {
			updatePrintPreview(null, false, false, records);
		});
		$('#print-configs input').on('change', () => {
			updatePrintPreview(null, false, false, records);
		});

		$('#print_records_btn').click(function () {
			updatePrintPreview(null, true, false, records);
		});

		$('#mark_printed_records_btn').click(function () {
			markAsPrinted(records);
		});

		$('#send_sms_records_btn').click(() => {
			sendSMSToAll(records);
		});

		$('#send_ams_records_btn').click(() => {
			sendAMSToAll(records);
		});

		updatePrintPreview(null, false, false, records);
	}

	function getSelectedPrintConfigs() {
		var printConfigs = {
			completeCard: $('.complete-card').is(':checked'),
			autoMarkPrinted: $('.auto-mark-printed').is(':checked'),
			headers: [],
		};

		return printConfigs;
	}

	async function sendAMSToAll(records?) {
		let allRecords: any[] = records ? records : Records.getCurrentRecords();

		for (let i = 0; i < allRecords.length; i++) {
			let record = allRecords[i];

			await timeout(10);
			await sendAMS(record);
		}

		Records.refreshRecords();
		notification.showBottomNotification(`AMS request sent for ${allRecords.length} records`);
	}

	async function sendSMSToAll(records?) {
		let allRecords: any[] = records ? records : Records.getCurrentRecords();
		let smsindex = 0;

		markOneSMS(allRecords[smsindex]);

		async function markOneSMS(record) {
			await timeout(10);

			sendSMS(record, (response) => {
				if (smsindex < allRecords.length - 1) {
					markOneSMS(allRecords[++smsindex]);
				} else {
					Records.refreshRecords();
					notification.showBottomNotification(`AMS request sent for ${allRecords.length} records`);
				}
			});
		}
	}

	function markAsPrinted(records?) {
		let allRecords: any[] = records ? records : Records.getCurrentRecords();
		let markindex = 0;

		markRecord(allRecords[markindex]);

		function markRecord(record) {
			record.card_printed = true;

			let Model = ModelClass.Instance(stateMap.systemId);
			Model.editRecord({ card_printed: true }, record.id, () => {
				if (markindex < allRecords.length - 1) {
					markRecord(allRecords[++markindex]);
				} else {
					Records.refreshRecords();
				}
			});
		}
	}

	function updatePrintPreview(e, autoPrint?, exportRecords?, records?) {
		var data: any[];
		if (records) {
			data = JSON.parse(JSON.stringify(records));
		} else {
			data = JSON.parse(JSON.stringify(Records.getCurrentRecords()));
		}

		if (typeof autoPrint === 'undefined' || autoPrint === false) {
			data = data.slice(0, 1);
		}

		var configs = getSelectedPrintConfigs();

		var headerHtml = '';
		var footerHtml = '<footer></footer>';

		var headHtml = `
			<head>
				<style>	
					body {
						margin: 0;
					}					
					.print-top-div {
						background-color: white;
						padding: 0px;
						width: 148mm;
						position: relative;
						height: 209.8mm;
						font-family: helvetica;
					}

					.print-record {
						height: 100%;
					}
					
					@media print {
						.print-top-div footer {
							page-break-after: always;
						}
					}

					.print-top-div footer {
						position: absolute;
						bottom: 0px;
						padding: 0px;
						right: 0px;
						left: 0px;
						page-break-after: always;
					}

					.print-top-div footer > div {
						padding: 0;
					}

					.print-top-div img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
					}

					.print-top-div .card-no {
						position: absolute;
						top: 62px;
						left: 106px;
						font-size: 1.3em;
					}

					.print-top-div .name {
						position: absolute;
						top: 158px;
						left: 106px;
						font-size: 1.3em;
						font-weight: bold;
					}

					.print-top-div .card-date {
						position: absolute;
						top: 260px;
						left: 135px;
						font-size: 1.3em;
						text-decoration: underline;
					}

					.print-top-div .card-count {
						position: absolute;
						top: 260px;
						left: 335px;
						font-size: 1.2em;
						width: 120px;
						text-align: center;
						text-transform: capitalize;
						text-decoration: underline;
					}
				</style>
			</head>
		`;
		// '<head>' +
		// '<link rel="stylesheet" type="text/css" href="/application/themes/anand/assets/css/lib/bootstrap/bootstrap.css">' +
		// '<link rel="stylesheet" type="text/css" href="/application/modules/kutiya_records/assets/css/anand.kutiyaRecords.print.css">' +
		// '</head>';
		var html = headHtml + '<body>';

		for (var i = 0; i < data.length; i++) {
			html += '<div class="print-top-div">' + printOneRecord(data[i]) + '</div>';
		}

		html += '</div></body>';

		function printOneRecord(record) {
			for (var key in record) {
				if (record[key] == null || record[key] == '') {
					record[key] = '&nbsp';
				}
			}

			var html = `
				${headerHtml}
				<div class='print-record'>
					${configs.completeCard ? `<img src="${stateMap.cardBase64}" />` : ''}
					<div class='card-no'>
						${record.card_no}
					</div>
					<div class='name'>
						${record.name}
					</div>
					<div class='card-date'>
						${new AnandDate(record.permission_date).format('DD/MM/YYYY')}
					</div>
					<div class='card-count'>
						${numWords(record.people_count)}
					</div>
					${footerHtml}
				</div>
			`;

			return html;
		}

		var iFrameWindow = window.frames['print-preview'];
		var doc = iFrameWindow.document;
		$('body', doc).html(html);

		// $('body', doc).find('.print-top-div').css('font-size', configs.font_size);
		// $('body', doc).find('.print-top-div').css('font-family', configs.font_type);

		$('#print-preview').css('height', '210mm');

		if (typeof autoPrint !== 'undefined' && autoPrint === true) {
			if (configs.autoMarkPrinted) {
				markAsPrinted(records);
			}

			iFrameWindow.focus();
			iFrameWindow.print();
		}
	}

	function initModule(props?, updateRecordsState?) {
		let permissions = getUserPermissionsHelper(store, stateMap.systemId);

		let toggleDetailCountBtn = $(`
		<a class="btn toggle-counts-btn">
			<span class="glyphicon glyphicon-eye-open"></span>
			<span class="hidden-sm hidden-xs menu-text">Counts</span>
		</a>`);
		toggleDetailCountBtn.click(props.props?.toggleDetailCount);

		let toggleFormBtn = $(`
		<a class="btn toggle-counts-btn">
			<span class="glyphicon glyphicon-eye-open"></span>
			<span class="hidden-sm hidden-xs menu-text">Form</span>
		</a>`);
		toggleFormBtn.click(props.props?.toggleForm);

		let toggleDetailedViewBtn = $(`
		<a class="btn toggle-detailed-btn">
			<span class="glyphicon glyphicon-eye-open"></span>
			<span class="hidden-sm hidden-xs menu-text">Detailed</span>
		</a>`);
		toggleDetailedViewBtn.click(props.props?.toggleShowDetailed);

		let bulkEditBtn = $(`
		<a class="btn toggle-counts-btn">
			<span class="glyphicon glyphicon-pencil"></span>
			<span class="hidden-sm hidden-xs menu-text">Bulk Edit</span>
		</a>`);
		bulkEditBtn.click(props.props?.bulkEdit);

		let modules = [HeaderControls.selectDropdown, HeaderControls.sortBtn, toggleDetailCountBtn, toggleDetailedViewBtn];

		if (permissions.editAllowed) {
			modules.push(toggleFormBtn);
		}

		let admin = isAdmin(store.getState().dataState.userStore.userData);
		if (admin) {
			modules.push(bulkEditBtn);
			// } else {
			// 	$('#anumati-add-record-open .publish-detail').hide();
			// 	$('#anumati-add-record-open .expire-detail').hide();
		}

		HeaderModule.initModule(modules, true);

		($.fn as any).intlTelInput.loadUtils('/assets/js/lib/IntlTelInput/utils.js');
		Utils.toDataURL('/assets/img/card.jpg').then((dataUrl) => {
			stateMap.cardBase64 = dataUrl;
		});

		Records.projectTitle = 'Anumati Patra';

		Records.getAnySearchFilter = getAnySearchFilter;
		Records.defaultSortOrder = 'createdTime logicaldesc';
		Records.IdentifyingFieldName = 'id';
		Records.imageFieldName = null;

		Records.updateRecordsState = updateRecordsState;
		Records.customSortingFunction = (title, sortDir) => {
			switch (title) {
				case 'Approval':
					return (a, b) => {
						let result = (a.card_no ?? 0) - (b.card_no ?? 0);
						if (result === 0) {
							result = a.createdTime - b.createdTime;
						}

						return sortDir * result;
					};
			}
		};

		Records.sortingFieldsMap = {
			'card_no, createdTime': 'Card No',
			name: 'Name',
			permission_date: 'Date',
			reference: 'Ref',
			createdTime: 'Created',
			function: 'Approval',
		};

		Records.searchFieldsMap = {
			card_no: { filter: '==', text: 'Card No' },
			name: 'Name',
			reference: 'Ref',
			permission_date: 'Date',
			byName: 'Created By',
		};

		if (permissions.editAllowed) {
			Records.searchFieldsMap['phone'] = 'Phone';
		}

		Records.searchFieldsMap['checkin'] = { filter: 'has', text: 'Checkins' };
		// Records.searchFieldsMap['publishTime'] = 'Publish Time';
		// Records.searchFieldsMap['expireTime'] = 'Expire Time';

		Records.isSearchDateTypeColumn = function (searchBy) {
			if (searchBy === 'permission_date' || searchBy === 'publishTime' || searchBy === 'expireTime') {
				return true;
			} else {
				return false;
			}
		};

		function getLastPrintedCardNo() {
			let state: ApplicationState = store.getState();
			let records = state.dataState[stateMap.systemId]?.byId ?? {};
			let lastPrintedRecord = records[configMap.lastPrintedRecordId];
			return lastPrintedRecord?.value ?? 0;
		}

		function getLastPrintedPageNo() {
			let state: ApplicationState = store.getState();
			let records = state.dataState[stateMap.systemId]?.byId ?? {};
			let lastPrintedRecord = records[configMap.lastPrintedRecordId];
			return lastPrintedRecord?.lastPrintedPageNo ?? 0;
		}

		function getPrintHistory() {
			let state: ApplicationState = store.getState();
			let records = state.dataState[stateMap.systemId]?.byId ?? {};
			let lastPrintedRecord = records[configMap.lastPrintedRecordId];
			return lastPrintedRecord?.history ?? {};
		}

		function printSummary() {
			Records.refreshRecordsAndReposition();

			var printHistory = getPrintHistory();
			var printHistoryHtml = '';
			var historyTimes = Object.keys(printHistory); //.filter((a) => parseInt(a) > configMap.startDate.getTime());

			var lastPrinted = historyTimes.length ? (stateMap.lastPrinted = getLastPrintedCardNo()) : 0;
			var lastPrintedPageNo = historyTimes.length ? (stateMap.lastPrintedPageNo = getLastPrintedPageNo()) : 0;

			var printConfig = `<div id="print-configs">
									<div class="col-xs-12">
										<label class="checkbox" style="display:inline;padding:1em">
											<input style="width:auto" type="checkbox" class="with-mobile" />
											With Mobile
										</label>
										<label class="checkbox" style="display:inline;padding:1em">
											<input style="width:auto" type="checkbox" class="with-ref" />
											With Ref
										</label>
										<label class="checkbox" style="display:inline;padding:1em">
											<input style="width:auto" type="checkbox" class="print-portrait" checked />
											Portrait
										</label>
										<label class="checkbox" style="display:inline;padding:1em">
											<input style="width:auto" type="checkbox" class="from-prev-print" checked />
											Start after card ${lastPrinted}
										</label>
										<label class="checkbox" style="display:inline;padding:1em">
											<input style="width:auto" type="checkbox" class="from-prev-page" checked />
											Start from page <input style="width:50px" type="number" class="from-prev-page-value" value="${lastPrintedPageNo + 1}" />
										</label>
										<label class="checkbox" style="display:inline;padding:1em">
											<input style="width:auto" type="checkbox" class="card-sorted" checked />
											Sort by card no
										</label>
									</div>`;

			if (historyTimes.length) {
				printHistoryHtml = `<div class='print-history'><table>
					<tr>
						<th>Date</th>
						<th>From Card</th>
						<th>To Card</th>
						<th>From Page</th>
						<th>To Page</th>
						<th>Printed By</th>
					</tr>`;

				historyTimes = historyTimes.sort((a, b) => parseInt(b) - parseInt(a));
				for (let i = 0; i < historyTimes.length; i++) {
					let timestamp = historyTimes[i];
					let history = printHistory[timestamp];

					if (history.maxCardNo < stateMap.startingCardNo) {
						continue;
					}

					printHistoryHtml += `<tr>
						<td>${new AnandDate().setEpoch(parseInt(timestamp)).format('ddd Do MMM, YYYY hh:mm:ss A')}</td>
						<td>${history.minCardNo}</td>
						<td>${history.maxCardNo}</td>
						<td>${history.minPageNo}</td>
						<td>${history.maxPageNo}</td>
						<td>${history.by}</td>
					</tr>`;
				}

				printHistoryHtml += '</table></div>';
			}

			printConfig = printHistoryHtml + printConfig;

			var printBtn = '<button id="print_records_btn" class="button btn btn-primary">' + '<span><span>Print</span></span>' + '</button>';

			printConfig += '<iframe id="print-preview" name="print-preview" src="about:blank"></iframe>';
			printConfig += '</div>';

			var printConfigObj = {
				contentHtml: printConfig,
				id: 'print_config',
				title: 'Print Summary',
				headerBtnHtml: printBtn,
				afterAppend: function () {
					updatePrintSummaryPreview(null);
				},
			};

			overlay_panel.showPanel(printConfigObj);

			$('#print-configs select').on('change', updatePrintSummaryPreview);
			$('#print-configs input').on('change', updatePrintSummaryPreview);

			$('#print_records_btn').click(function () {
				updatePrintSummaryPreview(null, true);
			});
		}

		function getSelectedPrintConfigs() {
			var printConfigs = {
				withMobile: $('.with-mobile').is(':checked'),
				withRef: $('.with-ref').is(':checked'),
				portrait: $('.print-portrait').is(':checked'),
				fromPrevPrint: $('.from-prev-print').is(':checked'),
				fromPrevPage: $('.from-prev-page').is(':checked'),
				fromPrevPageValue: parseInt($('.from-prev-page-value').val() as string),
				cardSorted: $('.card-sorted').is(':checked'),
				headers: [],
			};

			return printConfigs;
		}

		function updatePrintSummaryPreview(e, autoPrint?, exportRecords?) {
			var data: any[] = JSON.parse(JSON.stringify(Records.getCurrentRecords()));
			let configs = getSelectedPrintConfigs();

			if (configs.fromPrevPrint) {
				var lastPrinted = stateMap.lastPrinted;
				data = data.filter((record) => record.card_no > lastPrinted);
			}

			if (configs.cardSorted) {
				data = data.sort((a, b) => a.card_no - b.card_no);
			}

			if (typeof autoPrint === 'undefined') {
				data = data.slice(0, 50);
			}

			let pageOffset = configs.fromPrevPage ? configs.fromPrevPageValue - 1 : 0;

			const pdfDoc = new jsPDF({ orientation: configs.portrait ? 'portrait' : 'landscape' });
			let total = data.reduce((prev, cur) => {
				prev[cur.permission_date] = (prev[cur.permission_date] ?? 0) + cur.people_count;
				cur[cur.permission_date] = (cur[cur.permission_date] ?? 0) + cur.people_count;
				return prev;
			}, {});
			let dates = Object.keys(total)
				.map((date) => ({
					header: new AnandDate(date).format('DD-MM'),
					dataKey: date,
				}))
				.sort((a, b) => (a.dataKey > b.dataKey ? 1 : -1));

			// let total: any = {};

			// data.forEach((record) => {
			// 	record[record.permission_date] = (record[record.permission_date] ?? 0) + record.people_count;
			// 	total[record.permission_date] = (total[record.permission_date] ?? 0) + record.people_count;
			// });

			let sortedTotal: any[] = [];
			dates.forEach((date) => {
				sortedTotal.push({ content: total[date.dataKey], rowSpan: 1, colSpan: 1 });
			});

			var totalPagesExp = '{total_pages_count_string}';
			let columns = [
				{ header: 'CARD NO.', dataKey: 'card_no' },
				{ header: 'NAME', dataKey: 'name' },
			];
			if (configs.withRef) {
				columns.push({ header: 'REF.', dataKey: 'reference' });
			}
			if (configs.withMobile) {
				columns.push({ header: 'MOBILE', dataKey: 'phone' });
			}

			columns = columns.concat([...dates]);

			autoTable(pdfDoc, {
				theme: 'grid',
				styles: {
					halign: 'center',
				},
				columnStyles: {
					name: { halign: 'left' },
					reference: { halign: 'left' },
				},
				headStyles: {
					fillColor: 'white',
					textColor: 'black',
					lineWidth: 0.3,
					lineColor: [0, 0, 0],
				},
				footStyles: {
					fillColor: 'white',
					textColor: 'black',
					lineWidth: 0.3,
					lineColor: [0, 0, 0],
				},
				bodyStyles: {
					textColor: [0, 0, 0],
					lineWidth: 0.3,
					lineColor: [0, 0, 0],
				},
				// tableLineColor: [0, 0, 0],
				// tableLineWidth: 0.5,
				columns: columns,
				body: data,
				foot: [
					[
						{
							content: 'TOTAL MEMBERS',
							colSpan: 2 + (configs.withMobile ? 1 : 0) + (configs.withRef ? 1 : 0),
							rowSpan: 1,
						},
						...sortedTotal,
					],
				],
				showFoot: 'lastPage',
				// margin: { bottom: 0 },
				didDrawPage: (data) => {
					var pageSize = pdfDoc.internal.pageSize;
					var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
					var pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

					// Header
					// pdfDoc.setFontSize(16);
					// pdfDoc.setTextColor(40);

					// let ssn = 'SHRI SANT NAGAR';
					// let dim = pdfDoc.getTextDimensions(ssn);
					// pdfDoc.text(ssn, (pageWidth - dim.w) / 2, 10);

					// Footer
					var str = 'Page ' + (data.pageNumber + pageOffset); //pdfDoc.internal.getNumberOfPages();
					// Total page number plugin only available in jspdf v1.0+
					// if (typeof pdfDoc.putTotalPages === 'function') {
					// 	str = str + ' of ' + (totalPagesExp + (configs.fromPrevPage ? stateMap.lastPrintedPageNo : 0));
					// }
					pdfDoc.setFontSize(10);

					let bottom = pageHeight - data.settings.margin.bottom + 5;
					pdfDoc.text(str, data.settings.margin.left, bottom);

					let ssn = 'SHRI SANT NAGAR';
					let dim = pdfDoc.getTextDimensions(ssn);
					pdfDoc.text(ssn, (pageWidth - dim.w) / 2, bottom);

					let dateStr = new AnandDate().format('ddd, Do MMM, YYYY');
					dim = pdfDoc.getTextDimensions(dateStr);
					pdfDoc.text(dateStr, pageWidth - data.settings.margin.left - dim.w, bottom);
				},
			});

			// if (typeof pdfDoc.putTotalPages === 'function') {
			// 	pdfDoc.putTotalPages(totalPagesExp);
			// }

			if (autoPrint === true) {
				pdfDoc.autoPrint();

				const hiddFrame = document.createElement('iframe');
				hiddFrame.style.position = 'fixed';
				// "visibility: hidden" would trigger safety rules in some browsers like safari，
				// in which the iframe display in a pretty small size instead of hidden.
				// here is some little hack ~
				hiddFrame.style.width = '1px';
				hiddFrame.style.height = '1px';
				hiddFrame.style.opacity = '0.01';
				const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
				if (isSafari) {
					// fallback in safari
					hiddFrame.onload = () => {
						try {
							hiddFrame.contentWindow?.document.execCommand('print', false, undefined);
						} catch (e) {
							hiddFrame.contentWindow?.print();
						}
					};
				}
				hiddFrame.src = pdfDoc.output('bloburl').toString();
				document.body.appendChild(hiddFrame);

				let cardNos = data.map((record) => parseInt(record.card_no));
				stateMap.lastPrinted = Math.max(...cardNos);
				setRecord(stateMap.systemId, configMap.lastPrintedRecordId, {
					value: stateMap.lastPrinted,
					lastPrintedPageNo: pageOffset + pdfDoc.getNumberOfPages(),
					history: {
						[new Date().getTime()]: {
							cardNos: cardNos,
							minCardNo: Math.min(...cardNos),
							maxCardNo: stateMap.lastPrinted,
							minPageNo: pageOffset + 1,
							maxPageNo: pageOffset + pdfDoc.getNumberOfPages(),
							by: firebase.auth().currentUser?.displayName,
						},
					},
				});

				// const blob = pdfDoc.output('bloburl').toString();
				// window.open(blob);

				// var now = new Date();
				// var fileName = 'SSN Summary ' + Utils.formatDate(now) + ' ' + now.toLocaleTimeString();
				// pdfDoc.save(fileName);
			} else {
				var url = pdfDoc.output('datauristring');
				$('#print-preview').attr('src', url);
			}
		}

		Records.hooks.afterHeaderConfigure = async function (controlsHeader) {
			if (Records.editAllowed) {
				var openAddRecordForm: any = $('#anumati-add-record-open');
				var bulkEditForm: any = $('#anumati-bulk-edit-form');

				setTimeout(() => {
					Records.triggerAddRecord(null, null, openAddRecordForm);
					if (stateMap.defaultPublishValue) {
						let publishDate = new AnandDate(stateMap.defaultPublishValue);
						openAddRecordForm.find("input[name='publishDatePart']").attr('value', publishDate.format('YYYY-MM-DD'));
						openAddRecordForm.find('input[name="publishDatePart"]').datepicker('update', publishDate.getDateObj());
						openAddRecordForm.find("input[name='publishTimePart']").val(publishDate.format('hh:mm:ss A'));
					}
					if (stateMap.defaultExpireValue) {
						let expireDate = new AnandDate(stateMap.defaultExpireValue);
						openAddRecordForm.find("input[name='expireDatePart']").attr('value', expireDate.format('YYYY-MM-DD'));
						openAddRecordForm.find('input[name="expireDatePart"]').datepicker('update', expireDate.getDateObj());
						openAddRecordForm.find("input[name='expireTimePart']").val(expireDate.format('hh:mm:ss A'));
					}

					openAddRecordForm.find("input[name='name']").focus();
					openAddRecordForm.find("input[name='phone']").change(function () {
						openAddRecordForm.find('.phone-detail .title').html('Mobile: ' + getInApp({ phone: $(this).val() }));
					});
					openAddRecordForm.submit(function () {
						setTimeout(function () {
							openAddRecordForm.find("input[name='name']").val('');
							openAddRecordForm.find("input[name='people_count']").val('');
							openAddRecordForm.find("input[name='name']").focus();
						}, 500);
					});

					bulkEditForm.validate({
						errorClass: 'invalid-field-msg',
						rules: {
							field: {
								required: true,
							},
							value: {
								required: true,
							},
						},
						submitHandler: onBulkEdit,
					});

					// bulkEditForm.submit(onBulkEdit);

					// $(".filtered-records").css("width", "48%");
					// $(".filtered-records").css("float", "right");
					$('#date_wise_count').css('display', 'block');
					$('#date_wise_count').css('overflow', 'auto');
					// openAddRecordForm.css('display', 'block');
					props.props?.toggleForm();

					configureReferenceTypeAhead(openAddRecordForm);
					configureNameTypeAhead(openAddRecordForm);
					// configurePhoneTypeAhead(openAddRecordForm);
				}, 1000);

				// await timeout(10000);
				// let Model = ModelClass.Instance(stateMap.systemId);
				// var allRecords = Model.getRecords([], '', null) ?? [];

				// for (var i = 0; i < allRecords.length; i++) {
				// 	var record = allRecords[i];

				// 	if (record.card_no === undefined || parseInt(record.card_no) < 16) {
				// 		Model.deleteRecord(record, record[Records.IdFieldName], function () {
				// 			notification.showBottomNotification(
				// 				'Record deleted successfully: ' + record[Records.IdFieldName]
				// 			);
				// 		});
				// 		await timeout(100);
				// 	}
				// }
			}

			let Model = ModelClass.Instance(stateMap.systemId);
			Model.getUserPermissions('anumati_checkins', function (permissions) {
				stateMap.checkinAllowed = permissions.editAllowed;
			});

			Model.getUserPermissions('anumati_sms', function (permissions) {
				stateMap.smsAllowed = permissions.editAllowed;
			});

			Model.getUserPermissions('anumati_print', function (permissions) {
				stateMap.printAllowed = permissions.editAllowed;
			});

			if (Records.editAllowed === false) {
				$('.filtered-records').css('width', '100%');
			}

			var sortBtn = controlsHeader.find('.sort-records-btn');
			if (stateMap.printAllowed) {
				var printMenu = $(
					'<span class="dropdown hidden-sm hidden-xs">\n\
                    <a class="btn selection-btn dropdown-toggle" data-toggle="dropdown">\n\
						<span class="glyphicon glyphicon-print"></span>\n\
                        <span class="menu-text">Print</span>\n\
                        <span class="caret"></span>\n\
                    </a>\n\
                    <ul class="dropdown-menu">\n\
                        <li><a class="btn menu-text print-records">\n\
                                <span class="menu-text">Summary</span>\n\
                            </a>\n\
                        </li>\n\
                        <li>\n\
                            <a class="btn menu-text print-partial">\n\
                                <span class="menu-text">Card</span>\n\
                            </a>\n\
                        </li>\n\
                    </ul>\n\
                </span>'
				);

				sortBtn.before(printMenu);

				printMenu.find('.print-records').click(printSummary);
				// printMenu.find(".print-forms").click(printHtml);
				printMenu.find('.print-partial').click(printHtml);
			}

			if (Records.editAllowed) {
				controlsHeader
					.find('.select-dropdown')
					.find('ul')
					.append(
						$(`
				<li class="divider"></li>
				<li>
					<label><input type="checkbox" class="selection-show-approved" /> Approved</label>
				</li>
				<li>
					<label><input type="checkbox" class="selection-show-not-approved" /> Not Approved</label>
				</li>
				<li class="divider"></li>
				<li>
					<label><input type="checkbox" class="selection-show-printed" /> Printed</label>
				</li>
				<li>
					<label><input type="checkbox" class="selection-show-not-printed" /> Not Printed</label>
				</li>
				<li class="divider"></li>
				<li>
					<label><input type="checkbox" class="selection-show-ams" /> AMS SENT</label>
				</li>
				<li>
					<label><input type="checkbox" class="selection-show-not-ams" /> AMS NOT SENT</label>
				</li>				
				<li>
					<label><input type="checkbox" class="selection-show-delivered-ams" /> AMS DELIVERED</label>
				</li>
				<li>
					<label><input type="checkbox" class="selection-show-failed-ams" /> AMS FAILED</label>
				</li>
			`)
					);
			}

			controlsHeader.find('.selection-show-approved').click(() => {
				Records.refreshRecords();
			});

			controlsHeader.find('.selection-show-not-approved').click(() => {
				Records.refreshRecords();
			});

			controlsHeader.find('.selection-show-printed').click(() => {
				Records.refreshRecords();
				// Records.refreshRecords({ card_printed: true });
			});

			controlsHeader.find('.selection-show-not-printed').click(() => {
				Records.refreshRecords();
				// Records.refreshRecords(function (this: any) {
				// 	return this.card_printed == undefined ? true : false;
				// });
			});

			controlsHeader.find('.selection-show-ams').click(() => {
				Records.refreshRecords();
			});

			controlsHeader.find('.selection-show-not-ams').click(() => {
				Records.refreshRecords();
			});

			controlsHeader.find('.selection-show-delivered-ams').click(() => {
				Records.refreshRecords();
			});
			controlsHeader.find('.selection-show-not-delivered-ams').click(() => {
				Records.refreshRecords();
			});
			controlsHeader.find('.selection-show-failed-ams').click(() => {
				Records.refreshRecords();
			});
		};

		Records.hooks.configureHook = function () {
			let state: ApplicationState = store.getState();
			let records = state.dataState[stateMap.systemId]?.byId ?? {};

			let startingCardConfig = records[configMap.startingCardKey];
			stateMap.startingCardNo = startingCardConfig?.value ?? 0;

			let defaultPublishConfig = records[ssnDefaultPublishKey];
			stateMap.defaultPublishValue = defaultPublishConfig?.value;

			let defaultExpireConfig = records[ssnDefaultExpiryKey];
			stateMap.defaultExpireValue = defaultExpireConfig?.value;

			configureMainFilter();
		};

		Records.getAdditionalSearchFilters = function () {
			return getMainFilter();
		};

		Records.isSearchRangeTypeColumn = function (searchBy) {
			if (searchBy === 'card_no') {
				return true;
			}
			return false;
		};

		Records.updateRecordCount = async function () {
			// let Model = ModelClass.Instance(stateMap.systemId);
			// var allRecords = Model.getRecords([], '', null) ?? [];

			let defaultCounts = {
				cards: 0,
				members: 0,
				pendingMembers: 0,
				pendingCards: 0,
				checkins: 0,
				checkins_minor: 0,
				checkins_over: 0,
			};

			var datesCount = {};
			var total = { ...defaultCounts };

			let selectedRecords = Records.getCurrentRecords(); // allRecords;

			for (var i = 0; i < selectedRecords.length; i++) {
				var record = selectedRecords[i];

				if (new Date(record['permission_date']) < configMap.startDate) {
					continue;
				}

				if (record.card_no && record.card_no < stateMap.startingCardNo) {
					continue;
				}

				if (!record.card_no && record.approval && record.approval.status === 'Pending') {
					if (!datesCount[record['permission_date']]) {
						datesCount[record['permission_date']] = { ...defaultCounts };
					}
					datesCount[record['permission_date']]['pendingCards'] += 1;
					datesCount[record['permission_date']]['pendingMembers'] += parseInt(record['people_count'] ?? '0') || 0;

					total.pendingCards += 1;
					total.pendingMembers += parseInt(record['people_count'] ?? '0') || 0;
				} else if (record.card_no) {
					if (!datesCount[record['permission_date']]) {
						datesCount[record['permission_date']] = { ...defaultCounts };
					}
					datesCount[record['permission_date']]['cards'] += 1;
					datesCount[record['permission_date']]['members'] += parseInt(record['people_count'] ?? '0') || 0;

					total.cards += 1;
					total.members += parseInt(record['people_count'] ?? '0') || 0;
				}

				if (typeof record['checkin'] !== 'undefined') {
					var checkins: any[] = record['checkin'];
					checkins = checkins.sort((a, b) => a.created_at - b.created_at);
					var allowed_count = parseInt(record['people_count']) || 0;
					for (var j = 0; j < checkins.length; j++) {
						let checkinDate: string = checkins[j]['created_at'];
						checkinDate = checkinDate.split(' ')[0];
						if (!datesCount[checkinDate]) {
							datesCount[checkinDate] = { ...defaultCounts };
						}

						var checkin_count = parseInt(checkins[j]['checkin_count'] ?? '0') + parseInt(checkins[j]['checkin_count_ladies'] ?? '0');
						var minor_checkin_count = parseInt(checkins[j]['checkin_count_minor'] ?? '0') + parseInt(checkins[j]['checkin_count_infant'] ?? '0');
						allowed_count = allowed_count - checkin_count;

						if (checkins[j]['in_out'] === 'in') {
							datesCount[checkinDate]['checkins'] += checkin_count;
							total.checkins += checkin_count;

							datesCount[checkinDate]['checkins_minor'] += minor_checkin_count;
							total.checkins_minor += minor_checkin_count;

							if (allowed_count < 0) {
								datesCount[checkinDate]['checkins_over'] += -1 * allowed_count;
								total.checkins_over += -1 * allowed_count;
								allowed_count = 0;
							}
							// } else {
							// 	datesCount[checkinDate]['checkouts'] += count;
							// 	total.checkouts += count;
						}
					}
				}
			}

			var dateWiseCountTable = $('#date_wise_count');
			var headRow = dateWiseCountTable.find('#lables');
			var valuesRow = dateWiseCountTable.find('#values');
			var cardsRow = dateWiseCountTable.find('#cards');
			var checkinsRow = dateWiseCountTable.find('#checkins');
			var dates = Object.keys(datesCount).sort();

			headRow.html('');
			valuesRow.html('');
			cardsRow.html('');
			checkinsRow.html('');

			headRow.append($('<th></th>'));
			valuesRow.append($('<th>Members</th>'));
			cardsRow.append($('<th>Cards</th>'));
			checkinsRow.append($('<th>Checkins</th>'));
			for (var i = 0; i < dates.length; i++) {
				headRow.append($('<th>' + dates[i] + '</th>'));
				let pendingMembersStr = '';
				let datesCountRow = datesCount[dates[i]];
				if (datesCountRow['pendingMembers'] > 0) {
					pendingMembersStr = "<span style='color: red'> / " + datesCountRow['pendingMembers'] + '</span>';
				}
				let pendingCardsStr = '';
				if (datesCountRow['pendingCards'] > 0) {
					pendingCardsStr = "<span style='color: red'> / " + datesCountRow['pendingCards'] + '</span>';
				}

				valuesRow.append($('<td>' + datesCountRow['members'] + pendingMembersStr + '</td>'));
				cardsRow.append($('<td>' + datesCountRow['cards'] + pendingCardsStr + '</td>'));

				let checkinCount = datesCountRow['checkins'] + datesCountRow['checkins_minor'];
				let overCount = datesCountRow['checkins_over'];

				let overStr = '';
				if (overCount > 0) {
					overStr = "<span style='color: red'> / " + overCount + '</span>';
				}

				checkinsRow.append(
					$('<td>' + checkinCount + ' (' + datesCountRow['checkins'] + ' & ' + datesCountRow['checkins_minor'] + ')' + overStr + '</td>')
				);
			}

			headRow.append($('<th>Total</th>'));
			let pendingMembersStr = '';
			if (total.pendingMembers > 0) {
				pendingMembersStr = "<span style='color: red'> / " + total.pendingMembers + '</span>';
			}
			let pendingCardsStr = '';
			if (total.pendingCards > 0) {
				pendingCardsStr = "<span style='color: red'> / " + total.pendingCards + '</span>';
			}

			valuesRow.append($('<td>' + total.members + pendingMembersStr + '</td>'));
			cardsRow.append($('<td>' + total.cards + pendingCardsStr + '</td>'));

			let overAllowedStr = '';
			if (total.checkins_over > 0) {
				overAllowedStr = "<span style='color: red'> / " + total.checkins_over + '</span>';
			}

			checkinsRow.append(
				$('<td>' + (total.checkins + total.checkins_minor) + ' (' + total.checkins + ' & ' + total.checkins_minor + ')' + overAllowedStr + '</td>')
			);
			if (total.checkins + total.checkins_minor === 0) {
				checkinsRow.css('display', 'none');
			} else {
				checkinsRow.css('display', 'table-row');
			}

			dateWiseCountTable.trigger('heightChange');

			return datesCount;
		};

		Records.selectionDropdownOptionsMap = { '*ssn': 'Shri Sant Nagar' };
		// stateMap.selectionDropdown = $('#selection-dropdown select');
		// stateMap.selectionDropdown.find('option').remove();
		// stateMap.selectionDropdown.append($("<option value='ssn' selected='selected'>Shri Sant Nagar</option>"));

		Records.detailTileSelector = '#anumati-detail-record';
		Records.briefTileSelector = '#anumati-detail-record';
		Records.addTileSelector = '#anumati-add-record';
		Records.detailFormSelector = '#anumati-detail-record-form';
		Records.imageUploadSelector = null;

		Records.populateBriefTile = populateBriefTile;
		Records.populateDetailTile = populateDetailTile;
		Records.populateDetailForm = populateDetailForm;

		let MjBjModel = ModelClass.Instance('mjbj');
		stateMap.mjbjModel = MjBjModel;
		stateMap.mjbjModel.initModule({
			systemId: 'mjbj',
			forceUpdate: configMap['forceUpdate'],
			prepareRecord: prepareRecord,
		});
		stateMap.mjbjModel.getRecords([], '');
		// anand.mjbjModel.getRecords([], '', function () {
		Records.initModule({
			systemId: 'anumati',
			forceUpdate: configMap['forceUpdate'],
			prepareRecord: prepareRecord,
			props: props,
			addTileFormValidationRules: {
				name: {
					required: true,
				},
				people_count: {
					required: true,
				},
				permission_date: {
					required: true,
					date: true,
				},
				reference: {
					required: true,
				},
				'checkin_name[]': {
					required: function (el) {
						var parent = $(el).parents('.multi-field-row');
						if (
							(parent.find('input[name="checkin_count[]"]').val() as any)?.length ||
							(parent.find('input[name="checkin_count_ladies[]"]').val() as any)?.length ||
							(parent.find('input[name="checkin_phone[]"]').val() as any)?.length
						) {
							return true;
						}

						return false;
					},
				},
				'checkin_count[]': {
					required: function (el) {
						var parent = $(el).parents('.multi-field-row');
						if (
							(parent.find('input[name="checkin_count_ladies[]"]').val() as any)?.length <= 0 &&
							((parent.find('input[name="checkin_name[]"]').val() as any)?.length ||
								(parent.find('input[name="checkin_phone[]"]').val() as any)?.length)
						) {
							return true;
						}

						return false;
					},
				},
				'checkin_count_ladies[]': {
					required: function (el) {
						var parent = $(el).parents('.multi-field-row');
						if (
							(parent.find('input[name="checkin_count[]"]').val() as any)?.length <= 0 &&
							((parent.find('input[name="checkin_name[]"]').val() as any)?.length ||
								(parent.find('input[name="checkin_phone[]"]').val() as any)?.length)
						) {
							return true;
						}

						return false;
					},
				},
				'checkin_phone_ignore[]': {
					required: function (el) {
						var parent = $(el).parents('.multi-field-row');
						if (
							(parent.find('input[name="checkin_count[]"]').val() as any)?.length ||
							(parent.find('input[name="checkin_name[]"]').val() as any)?.length
						) {
							return true;
						}

						return false;
					},
				},
			},
		});
		// });
	}

	function configureMainFilter() {
		$("select[name='main_filter_dropdown']").on('change', function () {
			Records.refreshRecords();
		});
	}

	function getMainFilter() {
		var mainFilter = $("select[name='main_filter_dropdown']");
		var mainFilterVal = mainFilter.val();

		let showApproved = $('.selection-show-approved').is(':checked');
		let showNotApproved = $('.selection-show-not-approved').is(':checked');

		let showPrinted = $('.selection-show-printed').is(':checked');
		let showNotPrinted = $('.selection-show-not-printed').is(':checked');

		let showAMS = $('.selection-show-ams').is(':checked');
		let showNotAMS = $('.selection-show-not-ams').is(':checked');
		let showDeliveredAMS = $('.selection-show-delivered-ams').is(':checked');
		let showFailedAMS = $('.selection-show-failed-ams').is(':checked');

		let filters = function (this: any) {
			let result =
				this.place === mainFilterVal &&
				(!this.card_no || this.card_no >= stateMap.startingCardNo) &&
				new Date(this.permission_date) >= configMap.startDate;

			if (stateMap.checkinAllowed === true && Records.editAllowed === false) {
				result = result && this.card_no;
			}

			if (showApproved !== showNotApproved) {
				let orResult = false;

				if (showApproved) {
					orResult = orResult || !this.approval || this.approval?.status === 'Approved';
				}

				if (showNotApproved) {
					orResult = orResult || (this.approval && this.approval.status !== 'Approved');
				}

				result = result && orResult;
			}

			// if (showApproved) {
			// 	result = result && (!this.approval || this.approval?.status === 'Approved');
			// }

			// if (showNotApproved) {
			// 	result = result && this.approval && this.approval.status !== 'Approved';
			// }

			if (showNotPrinted !== showPrinted) {
				let orResult = false;

				if (showPrinted) {
					orResult = orResult || this.card_printed;
				}

				if (showNotPrinted) {
					orResult = orResult || !this.card_printed;
				}

				result = result && orResult;
			}

			if (showAMS) {
				result = result && this.ams?.state === 'AMS_SENT';
			}

			if (showNotAMS) {
				result = result && !this.ams;
			}

			if (showDeliveredAMS) {
				result = result && this.ams?.state === 'AMS_DELIVERED';
			}

			if (showFailedAMS) {
				result = result && this.ams && this.ams.state !== 'AMS_SENT' && this.ams.state !== 'AMS_DELIVERED';
			}

			if (stateMap.refFilter.length) {
				result = result && stateMap.refFilter.indexOf(this.reference) >= 0;
			}

			return result;
		};

		return filters;
	}

	function prepareRecord(record, isAdd, isAdmin = false) {
		if (record['permission_date']) {
			record['permission_date'] = Utils.formatDate(record['permission_date']);
		}

		if (record['publishDatePart']) {
			record['publishTime'] = firebase.firestore.Timestamp.fromDate(
				new Date(record['publishDatePart'] + (record['publishTimePart'] ? ' ' + record['publishTimePart'] : ''))
			);
		} else if (isAdd && stateMap.defaultPublishValue) {
			record['publishTime'] = firebase.firestore.Timestamp.fromMillis(stateMap.defaultPublishValue.seconds * 1000);
		}

		if (record['expireDatePart']) {
			record['expireTime'] = firebase.firestore.Timestamp.fromDate(
				new Date(record['expireDatePart'] + (record['expireTimePart'] ? ' ' + record['expireTimePart'] : ''))
			);
		} else if (isAdd && stateMap.defaultExpireValue) {
			record['expireTime'] = firebase.firestore.Timestamp.fromMillis(stateMap.defaultExpireValue.seconds * 1000);
		}

		record['place'] = $("select[name='main_filter_dropdown']").val();

		if (record['people_count']) {
			record['people_count'] = parseInt(record['people_count']);
		}

		if (record['name']) {
			record['name'] = Utils.upperFirstAll(record['name']);
		}

		if (record['phone'] && !record['phone'].trim().startsWith('+')) {
			record['phone'] = '+91' + record['phone'].trim();
		}

		let checkins: string[] = record['checkin_ids'] ?? [];
		if (checkins.length > 1) {
			let names = record['checkin_name'];
			let phones = record['checkin_phone'];
			let inOuts = record['in_out'];
			let counts = record['checkin_count'];
			let ladies_counts = record['checkin_count_ladies'];
			let minor_counts = record['checkin_count_minor'];
			let infant_counts = record['checkin_count_infant'];
			let created = record['created_at'];
			let cities = record['checkin_city'];
			let conveyance = record['checkin_conveyance'];

			record.checkin = record.checkin || [];

			checkins.forEach((id, index) => {
				if (index === 0) return;

				if (!counts[index] && !ladies_counts[index]) {
					return;
				}

				let checkinId = id && id.length ? id : uuidv1();
				let checkinRecord = {
					id: checkinId,
					checkin_name: Utils.upperFirstAll(names[index]),
					checkin_count: parseInt(counts[index]) || 0,
					checkin_count_ladies: parseInt(ladies_counts[index]) || 0,
					checkin_count_minor: parseInt(minor_counts[index]) || 0,
					checkin_count_infant: parseInt(infant_counts[index]) || 0,
					checkin_phone: phones[index],
					checkin_city: cities[index],
					checkin_conveyance: conveyance[index],
					in_out: inOuts[index],
					parent_id: record.id,
					status: 'active',
					created_at: created[index] || new AnandDate().format('YYYY-MM-DD HH:mm:ss'),
				};

				let checkinIndex = -1;
				if (id && id.length) {
					let checkinRecordIndex = (record.checkin as any[]).findIndex((value) => value.id == id);
					if (checkinRecordIndex > -1) {
						checkinIndex = checkinRecordIndex;
					}
				}

				if (checkinIndex > -1) {
					record.checkin[checkinIndex] = checkinRecord;
				} else {
					record.checkin.push(checkinRecord);
				}
			});
		}

		delete record['checkin_ids'];
		delete record['checkin_name'];
		delete record['checkin_phone'];
		delete record['checkin_phone_ignore'];
		delete record['in_out'];
		delete record['checkin_count'];
		delete record['checkin_count_ladies'];
		delete record['checkin_count_minor'];
		delete record['checkin_count_infant'];
		delete record['checkin_city'];
		delete record['checkin_conveyance'];
		delete record['created_at'];

		delete record['phone_ignore'];
		delete record['image'];

		delete record['publishDatePart'];
		delete record['publishTimePart'];

		if (isAdd) {
			record['card_printed'] = false;
			record['ams'] = false;

			if (!isAdmin) {
				record['approval'] = {
					status: 'Pending',
				};
			}
		}

		if (stateMap.checkinAllowed === true && Records.editAllowed === false) {
			delete record['name'];
			delete record['people_count'];
			delete record['reference'];
			delete record['phone'];
			delete record['permission_date'];
		}

		console.log(JSON.stringify(record));
		return record;
	}

	function getAnySearchFilter(query) {
		let result: any[] = [
			{ card_no: { '==': query } },
			{ name: { likenocase: query } },
			{ permission_date: { likenocase: query } },
			{ reference: { likenocase: query } },
			{ byName: { likenocase: query } },
		];

		if (Records.editAllowed) {
			result.push({ phone: { likenocase: query } });
		}

		return result;
	}

	function configureReferenceTypeAhead(tile) {
		var substringMatcher = function (field: string = 'reference') {
			return function findMatches(query, callback) {
				query = $.trim(query);

				var filters: any[] = [];
				var words = query.split(' ');
				// filters.push([{ title: { leftnocase: 'Mahatma' } }, { title: { leftnocase: 'Bai' } }]);

				if (query.toLowerCase().indexOf('m.') === 0 || query.toLowerCase().indexOf('mh.') === 0 || query.toLowerCase().indexOf('m ') === 0) {
					filters.push({ title: { leftnocase: 'Mahatma' } });
					query = query.replace(/m./i, '');
				} else if (query.toLowerCase().indexOf('b.') === 0 || query.toLowerCase().indexOf('b ') === 0) {
					filters.push({ title: { leftnocase: 'Bai' } });
					query = query.replace(/b./i, '');
				}

				if ('ji'.indexOf(words[words.length - 1].toLowerCase()) === 0) {
					query = query.substr(0, query.lastIndexOf(words[words.length - 1]));
				}

				query = $.trim(query);

				if (query.split(' ').length === 1) {
					var abbr = query.split('');

					var abbrFilterRegx = '';
					for (var i = 0; i < abbr.length; i++) {
						abbrFilterRegx += i ? ' ' + abbr[i] + '[^ ]*' : '^' + abbr[i] + '[^ ]*';
					}

					var abbrFilter = { name: { regex: new RegExp(abbrFilterRegx, 'i') } };
					filters.push([{ name: { leftnocase: query } }, abbrFilter]);
				} else {
					filters.push({ name: { leftnocase: query } });
				}

				var records: any[] = stateMap.mjbjModel.getRecords(filters, 'name logical', null);
				records = records.concat();
				var matches: any[] = [];

				for (var i = 0; i < records.length; i++) {
					let record = records[i];

					var image = record['image'];

					var titleMapping = {
						Mahatma: 'Mh.',
						Bai: 'Bai',
						Bhagat: 'Bh.',
						Dada: 'Dada',
						Dadi: 'Dadi',
					};

					var rahbarName;
					if (record['rahbar']) {
						var rahbarTitle = titleMapping[record['rahbar_title']];
						rahbarName = record['rahbar'] + ' Ji';
						if (record['rahbar'].indexOf('Shri') === -1) {
							rahbarName = rahbarTitle + ' ' + rahbarName;
						}
					}

					matches.push({
						value: (records[i]['title'] === 'Mahatma' ? 'Mh.' : records[i]['title']) + ' ' + records[i]['name'] + ' Ji',
						title: records[i]['title'],
						image: image,
						image_update_time: records[i]['image_update_time'],
						oldName: records[i]['old_name'],
						sewa: records[i]['sewa'],
						rahbar: rahbarName,
					});
				}

				let Model = ModelClass.Instance(stateMap.systemId);
				let ownRecords = Model.getRecords([{ [field]: { likenocase: query } }], `${field} logical`, null, undefined, undefined, false);
				for (var i = 0; i < ownRecords.length; i++) {
					// let exists =
					// 	matches.findIndex((match) => {
					// 		return match.value === ownRecords[i][field];
					// 	}) > -1;
					// if (!exists) {
					matches.unshift({
						value: ownRecords[i][field],
						phone: ownRecords[i].phone,
						date: ownRecords[i].permission_date,
					});
					// }
				}

				callback(matches);
			};
		};

		tile.find("input[name='reference']").typeahead(
			{
				hint: false,
				minLength: 3,
				highlight: true,
				classNames: {
					menu: 'tt-dropdown-menu',
				},
			},
			{
				name: 'reference',
				limit: 20,
				display: 'value',
				source: substringMatcher('reference'),
				templates: { suggestion: getSuggestionHtml },
			}
		);

		tile.find("input[name='name']").typeahead(
			{
				hint: false,
				minLength: 3,
				highlight: true,
				classNames: {
					menu: 'tt-dropdown-menu tt-dropdown-menu-name',
				},
			},
			{
				name: 'name',
				limit: 20,
				display: 'value',
				source: substringMatcher('name'),
				templates: { suggestion: getSuggestionHtml },
			}
		);

		tile.find("input[name='name']").on('typeahead:selected', function (evt, item) {
			let phoneInput = $(evt.target).parent().parent().parent().parent().find("input[name='phone']");
			phoneInput.val(item.phone);
			phoneInput.change();
		});

		function getSuggestionHtml(obj) {
			var $html = $(
				'<div class="row reference-suggestion">' +
					'<div class="avatar col-xs-2 no-padding">' +
					`<img />` +
					'</div>' +
					'<div class="col-xs-10 no-padding">' +
					'<div><u>Name</u>: ' +
					obj.value +
					'</div>' +
					(obj.oldName ? '<div><u>Old Name</u>: ' + obj.oldName + '</div>' : '') +
					(obj.rahbar ? '<div><u>Rahbar</u>: ' + obj.rahbar + '</div>' : '') +
					(obj.sewa ? '<div><u>Sewa</u>: ' + obj.sewa + '</div>' : '') +
					(obj.phone ? '<div><u>Phone</u>: ' + obj.phone + '</div>' : '') +
					(obj.date ? '<div><u>Date</u>: ' + obj.date + '</div>' : '') +
					'</div>' +
					'</div>'
			);

			// if (obj.image) {
			var $imgDiv = $html.find('img');
			Records.setImageSrc($imgDiv, obj.image, 'mjbj/images/');
			// }

			return $html;
		}
	}

	function configurePhoneTypeAhead(tile) {
		var substringMatcher = function () {
			return function findMatches(query, callback) {
				query = $.trim(query);

				let Model = ModelClass.Instance(stateMap.systemId);
				let records = Model.getRecords([{ phone: { leftnocase: query } }], 'phone logical', null);
				let map: any = {};
				let uniqueRecords = records.filter((item) => (map[item.phone] ? false : (map[item.phone] = true)));

				callback(uniqueRecords);
			};
		};

		tile.find("input[name='phone']").typeahead(
			{
				hint: false,
				minLength: 3,
				highlight: true,
				classNames: {
					menu: 'tt-dropdown-menu',
				},
			},
			{
				name: 'phone',
				display: 'phone',
				source: substringMatcher(),
			}
		);
	}

	function configureNameTypeAhead(tile) {
		// var substringMatcher = function () {
		// 	return function findMatches(query, callback) {
		// 		query = $.trim(query);
		// 		var filters: any[] = [];
		// 		filters.push({ name: { likenocase: query } });
		// 		let Model = ModelClass.Instance(stateMap.systemId);
		// 		let records = Model.getRecords(filters, 'name logical', null, undefined, undefined, false);
		// 		callback(records);
		// 	};
		// };
		// tile.find("input[name='name']").typeahead(
		// 	{
		// 		hint: false,
		// 		minLength: 3,
		// 		highlight: true,
		// 		classNames: {
		// 			menu: 'tt-dropdown-menu tt-dropdown-menu-name',
		// 		},
		// 	},
		// 	{
		// 		name: 'name',
		// 		display: 'name',
		// 		source: substringMatcher(),
		// 	}
		// );
		// tile.find("input[name='name']").on('typeahead:selected', function (evt, item) {
		// 	$(evt.target).parent().parent().parent().parent().find("input[name='phone']").val(item.phone);
		// });
	}

	async function populateBriefTile(sampleTile, record) {
		return await populateDetailTile(sampleTile, record);
	}

	async function sendSMS(record, callback?) {
		if (!record.phone || $.trim(record.phone) === '') {
			callback && callback('Error: Phone number not valid!');
			return;
		}

		if (!record.card_no) {
			callback && callback('Error: Card number not populated!');
			return;
		}

		let cardNo = record.card_no;
		let name = record.name;
		let count = record.people_count;
		let date = new AnandDate(record.permission_date).format('DD/MM/YYYY');
		let message: string = `Shri Anandpur Trust - SSN Card ID: ${cardNo}\n${name}\n# ${count}\n${date}\nAccommodation in SHRI SANT NAGAR is only for one night.`;

		var sendSMS = firebase.app().functions('asia-east2').httpsCallable('sendSMS');
		let result = await sendSMS({
			messages: [
				{
					recordId: record.id,
					phone: record.phone,
					text: message,
				},
			],
			// cc: '+918169023750',
			DLT_TE_ID: '1307161374008328563',
			collection: 'anumati',
		});

		if (callback) callback('AMS Request Sent');

		// SendSMS.send(
		// 	[`+91${record.phone}`, '+918169023750'],
		// 	{
		// 		id: record.id,
		// 		name: record.name,
		// 		count: record.people_count,
		// 		date: new AnandDate(record.permission_date).format('DD/MM/YYYY'),
		// 	},
		// 	function (response) {
		// 		//Returns Message ID, If Sent Successfully or the appropriate Error Message
		// 		console.log(response);
		// 		callback && callback('SMS Request Sent');
		// 		// record.sms_sent = true;

		// 		// let Model = ModelClass.Instance(stateMap.systemId);
		// 		// Model.editRecord({ sms_sent: true }, record.id, () => {
		// 		// 	callback && callback('SMS Request Sent');
		// 		// });
		// 	}
		// );
	}

	async function sendAMS(record) {
		if (!record.phone || $.trim(record.phone) === '') {
			throw 'Error: Phone number not valid!';
		}

		if (!record.card_no) {
			throw 'Error: Card number not populated!';
		}

		let fbFuncs = firebase.app().functions('asia-east2');
		// var refreshInApp = fbFuncs.httpsCallable('refreshInApp');
		// let limit = 500;
		// let startAt = undefined;
		// let size = 0;

		// do {
		// 	let result = await refreshInApp({
		// 		limit,
		// 		startAt
		// 	});
		// 	console.log(result);
		// 	startAt = result.data.lastRecord.name;
		// 	size = result.data.size;
		// } while (size >= limit);

		var sendAMSAPI = fbFuncs.httpsCallable('sendAMS');
		await sendAMSAPI({
			messages: [
				{
					recordId: record.id,
				},
			],
			collection: 'anumati',
		});

		return 'AMS Request Sent';
	}

	async function populateDetailTile(sampleTile, recordOrig) {
		var record = {};
		for (var key in recordOrig) {
			if (key !== 'deleted' && (recordOrig[key] == null || recordOrig[key] == '') && recordOrig[key] !== 0) {
				record[key] = '&nbsp';
			} else {
				record[key] = recordOrig[key];
			}
		}

		sampleTile.find('.self-detail.value').html(record['name']);

		let opsState = '';

		if (Records.editAllowed) {
			let printState = recordOrig['card_printed'] ? ' [Card Printed]' : '';
			let smsState = recordOrig['sms']
				? ` [${recordOrig.sms.state} (${recordOrig.sms.reportAt ? new AnandDate(recordOrig.sms.reportAt).format('DD-MM HH:mm') : ''})]`
				: '';
			let amsState = recordOrig['ams']
				? ` [${recordOrig.ams.state}${
						recordOrig.ams.error
							? ' (' + recordOrig.ams.error + ')'
							: recordOrig.ams.sentAt
							? ' (' + new AnandDate(recordOrig.ams.sentAt).format('DD-MM HH:mm') + ')'
							: ''
				  }]`
				: '';
			opsState = printState + smsState + amsState;
		}

		let cardNo = record['card_no'];
		if (!cardNo && record['approval']) {
			let status = record['approval']['status'];
			let colorMap = { Pending: 'blueviolet', Rejected: 'red', Approved: 'green' };
			cardNo = `<span style="color:${colorMap[status]}">[Approval ${status}]</span>`;
		}
		sampleTile.find('.card-detail.value').html((cardNo ?? '') + opsState);

		sampleTile.find('.ref-detail.value').html(record['reference']);

		if (Records.editAllowed) {
			sampleTile.find('.phone-detail.value').html(record['phone'] + getInApp(record));
		} else {
			sampleTile.find('.phone-detail').prev().hide();
			sampleTile.find('.phone-detail').hide();

			sampleTile.find('.publish').hide();
			sampleTile.find('.expiry').hide();

			sampleTile.find('.created-by-detail').prev().hide();
			sampleTile.find('.created-by-detail').hide();
		}

		sampleTile.find('.created-by-detail.value').html(record['byName'] ?? 'Unknown');
		let expireTime = new AnandDate(record['expireTime']);
		let expireStr = expireTime.format('dd, Do MMM YYYY hh:mm:ss A');

		if (expireTime.isSameOrBefore(new AnandDate())) {
			sampleTile.find('.expiry').css('color', 'red');
			expireStr += ' [Expired]';
		}
		sampleTile.find('.expire-time-detail.value').html(expireStr);

		let publishTime = new AnandDate(record['publishTime']);
		let publishStr = publishTime.format('dd, Do MMM YYYY hh:mm:ss A');
		if (publishTime.isAfter(new AnandDate())) {
			sampleTile.find('.publish').css('color', 'blueviolet');
			publishStr += ' [Not Published]';
		}
		sampleTile.find('.publish-time-detail.value').html(publishStr);

		if (record['permission_date'] !== null) {
			var dateAr = record['permission_date'].split('-');
			if (dateAr.length === 3) {
				var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
				dateStr = Utils.getDateStr(dateAr[0], dateAr[1], dateAr[2]);
				sampleTile.find('.date-detail.value').html(dateStr);
			} else {
				sampleTile.find('.date-detail.value').html(record['permission_date']);
			}
		}

		if (record['deleted']) {
			sampleTile.find('.deleted').show();
			sampleTile.find('.deleted-detail.value').html('True');
		} else {
			sampleTile.find('.deleted').hide();
		}

		let totalCheckins = 0;
		if (typeof recordOrig['checkin'] !== 'undefined' && recordOrig['checkin'] !== null && recordOrig['checkin'].length > 0) {
			var checkins = recordOrig['checkin'];
			for (var i = 0; i < checkins.length; i++) {
				var checkinRow = sampleTile.find('.check-in-details #check-in-data-detail').clone();
				checkinRow.attr('id', 'check-in-detail' + '-' + i);
				// checkinRow.find('.inout').html(checkins[i]['in_out'] === 'in' ? 'Check-In' : 'Check-Out');
				checkinRow.find('.name').html(checkins[i]['checkin_name']);

				let majorCount = (checkins[i]['checkin_count'] ?? 0) + (checkins[i]['checkin_count_ladies'] ?? 0);
				let minorCount = (checkins[i]['checkin_count_minor'] ?? 0) + (checkins[i]['checkin_count_infant'] ?? 0);

				checkinRow.find('.count').html(majorCount + ' & ' + minorCount);
				checkinRow.find('.phone').html(checkins[i]['checkin_phone']);
				//				checkinRow.find('.date').html(checkins[i]['created_at']);

				checkinRow.find('.date input').datepicker({
					format: 'D, dd M yyyy',
					autoclose: true,
					todayHighlight: true,
					todayBtn: 'linked',
				});
				updateDateTime(checkins[i]['created_at'], checkinRow.find('.date input'));
				checkinRow.removeClass('hidden');
				sampleTile.find('.main-details .check-in-detail').append(checkinRow);

				totalCheckins += majorCount;
			}
		} else {
			sampleTile.find('.check-in-details').hide();
		}

		if (stateMap.checkinAllowed === true && Records.editAllowed === false) {
			sampleTile.find('.check-in-details').show();
			sampleTile.find('.checkins-btn').show();
			sampleTile.find('.checkins-btn').on('click', function (e) {
				e.stopPropagation();
				handleCheckins(sampleTile);
			});
		}

		let remainingCount = record['people_count'] - totalCheckins;
		sampleTile
			.find('.count-detail.value')
			.html(record['people_count'] + "<span style='color: " + (remainingCount >= 0 ? 'green' : 'red') + "'> [Remaining: " + remainingCount + ']</span>');

		if (stateMap.printAllowed === false) {
			sampleTile.find('.record-print-button').hide();
		}

		if (stateMap.smsAllowed === false) {
			sampleTile.find('.record-sms-button').hide();
			sampleTile.find('.record-ams-button').hide();
		}

		if (!record['card_no'] && record['approval'] && record['approval']['status'] !== 'Approved') {
			sampleTile.find('.record-print-button').html('Approve');

			sampleTile.find('.record-print-button').on('click', function (e: MouseEvent) {
				e.stopPropagation();

				BootstrapDialog.show({
					type: BootstrapDialog.TYPE_PRIMARY,
					title: 'Approve Card',
					message: 'Approve/Reject this card request?',
					buttons: [
						{
							label: 'Yes, Approve!',
							cssClass: 'btn-success',
							action: function (dialog) {
								let update = {
									approval: {
										status: 'Approved',
									},
								};

								let Model = ModelClass.Instance(stateMap.systemId);
								Model.editRecord(update, record[Records.IdFieldName], function () {
									dialog.close();
								});
							},
						},
						{
							label: 'Reject!',
							cssClass: 'btn-danger',
							action: function (dialog) {
								let update = {
									approval: {
										status: 'Rejected',
									},
								};

								let Model = ModelClass.Instance(stateMap.systemId);
								Model.editRecord(update, record[Records.IdFieldName], function () {
									dialog.close();
								});
							},
						},
						{
							label: 'Cancel',
							cssClass: 'btn-primary',
							action: function (dialog) {
								dialog.close();
							},
						},
					],
				});
			});
		} else {
			sampleTile.find('.record-print-button').on('click', function (e: MouseEvent) {
				e.stopPropagation();
				printHtml(e, [recordOrig]);
			});
		}

		sampleTile.find('.record-sms-button').on('click', function (e: MouseEvent) {
			e.stopPropagation();
			sendSMS(recordOrig, (response) => {
				notification.showBottomNotification(response);
			});
			// msg91.sendOne(
			// 	'171655AWw8xnhTQx4N5b927a6a',
			// 	`+91${recordOrig.phone}`,
			// 	`Shri Anandpur Trust - SSN Card ID: ${recordOrig.id}\n${recordOrig.name}\n# ${
			// 		recordOrig.people_count
			// 	}\n${new AnandDate(recordOrig.permission_date).format('DD/MM/YYYY')}`,
			// 	'SATmsg',
			// 	'4',
			// 	'91',
			// 	function (response) {
			// 		//Returns Message ID, If Sent Successfully or the appropriate Error Message
			// 		console.log(response);
			// 	}
			// );
		});

		sampleTile.find('.record-ams-button').on('click', async function (e: MouseEvent) {
			e.stopPropagation();
			try {
				let response = await sendAMS(recordOrig);
				notification.showBottomNotification(response);
			} catch (error) {
				notification.showBottomNotification(error);
			}
		});

		if (Records.editAllowed === false) {
			sampleTile.expansionAllowed = false;
		}

		return sampleTile;
	}

	function getInAppRecord(record) {
		if (!record['phone'] || record['phone'] === '') {
			return '';
		}

		let phone = record['phone'].startsWith('+') ? record['phone'] : '+91' + record['phone'];
		var records: any[] = stateMap.mjbjModel.getRecords([{ inApp: { has: phone } }], 'name logical', null);

		return records.length ? records[0] : undefined;
	}

	function getInApp(record) {
		let inAppRecord = getInAppRecord(record);

		let refName = '';
		if (inAppRecord) {
			var titleMapping = {
				Mahatma: 'Mh.',
				Bai: 'Bai',
				Bhagat: 'Bh.',
				Dada: 'Dada',
				Dadi: 'Dadi',
			};
			refName = (titleMapping[inAppRecord['title']] ?? inAppRecord['title']) + ' ' + inAppRecord['name'] + ' Ji';
		}

		return inAppRecord ? ` <span style="color:green">[InApp: ${refName}]</span>` : ' <span style="color:red">[NotInApp]</span>';
	}

	function populateDetailForm(sampleTile, record) {
		sampleTile.find('.card-detail .value').html(record['card_no'] ?? '');
		sampleTile.find('input[name="name"]').val(record['name']);
		sampleTile.find('input[name="people_count"]').val(record['people_count']);
		sampleTile.find('input[name="reference"]').val(record['reference']);

		if (record['phone']) {
			sampleTile.find('input[name="phone_ignore"]').intlTelInput('setNumber', record['phone']);
			sampleTile.find('input[name="phone"]').val(record['phone']);
			sampleTile.find('.phone-detail .title').html('Mobile: ' + getInApp(record));
		}

		var dateAr = record['permission_date'].split('-');
		var date = new Date(parseInt(dateAr[0]), parseInt(dateAr[1]) - 1, parseInt(dateAr[2]));
		sampleTile.find('input[name="permission_date"]').attr('value', record['permission_date']);
		sampleTile.find('input[name="permission_date"]').datepicker('update', date);

		if (record['publishTime']) {
			let publishDate = new AnandDate(record['publishTime']);
			sampleTile.find("input[name='publishDatePart']").attr('value', publishDate.format('YYYY-MM-DD'));
			sampleTile.find('input[name="publishDatePart"]').datepicker('update', publishDate.getDateObj());
			sampleTile.find("input[name='publishTimePart']").val(publishDate.format('hh:mm:ss A'));
		}
		if (record['expireTime']) {
			let expireDate = new AnandDate(record['expireTime']);
			sampleTile.find("input[name='expireDatePart']").attr('value', expireDate.format('YYYY-MM-DD'));
			sampleTile.find('input[name="expireDatePart"]').datepicker('update', expireDate.getDateObj());
			sampleTile.find("input[name='expireTimePart']").val(expireDate.format('hh:mm:ss A'));
		}

		if (Records.editAllowed === false) {
			sampleTile.find('input[name="name"]').attr('disabled', 'disabled');
			sampleTile.find('input[name="people_count"]').attr('disabled', 'disabled');
			sampleTile.find('input[name="reference"]').attr('disabled', 'disabled');
			sampleTile.find('input[name="permission_date"]').attr('disabled', 'disabled');

			sampleTile.find('.phone-detail').hide();
			sampleTile.find('.publish-detail').hide();
			sampleTile.find('.expire-detail').hide();
		}

		if (typeof record['checkin'] !== 'undefined') {
			var checkins = record['checkin'];
			let checkinParentDiv = sampleTile.find('.main-details .check-in-detail');
			var multiFieldAddBtn = checkinParentDiv.find('.hidden .multi_field_add_btn');
			var checkinDivId = multiFieldAddBtn.attr('data-field-id');

			// var checkinDivId = Records.populateMultiAddFields(sampleTile.find('.main-details .check-in-detail'), checkins.length);
			for (var i = 0; i < checkins.length; i++) {
				var checkinDiv = sampleTile.find('#' + checkinDivId + (i + 1));
				checkinDiv.find('input[name="checkin_ids[]"]').val(checkins[i]['id']);
				checkinDiv.find('input[name="created_at[]"]').val(checkins[i]['created_at']);

				checkinDiv.find('input[name="checkin_name[]"]').val(checkins[i]['checkin_name']);
				checkinDiv.find('input[name="checkin_count[]"]').val(checkins[i]['checkin_count']);
				checkinDiv.find('input[name="checkin_count_ladies[]"]').val(checkins[i]['checkin_count_ladies']);
				checkinDiv.find('input[name="checkin_count_minor[]"]').val(checkins[i]['checkin_count_minor']);
				checkinDiv.find('input[name="checkin_count_infant[]"]').val(checkins[i]['checkin_count_infant']);

				checkinDiv.find('input[name="checkin_phone_ignore[]"]').intlTelInput('setNumber', checkins[i]['checkin_phone']);
				checkinDiv.find('input[name="checkin_phone[]"]').val(checkins[i]['checkin_phone']);
				checkinDiv.find('input[name="checkin_city[]"]').val(checkins[i]['checkin_city']);
				checkinDiv.find('input[name="checkin_conveyance[]"]').val(checkins[i]['checkin_conveyance']);

				checkinDiv.find('select[name="in_out[]"]').val(checkins[i]['in_out']);

				updateDateTime(checkins[i]['created_at'], checkinDiv.find('.created_at_date input'));

				checkinDiv.find('input').each((index, el) => {
					if ($(el).attr('disabled') !== 'disabled') {
						$(el).attr('disabled', 'disabled');
						$(el).attr('data-disable-first', 'true');
					}
				});
				checkinDiv.find('.multi_field_add_btn').attr('data-disable-first', 'true');

				if (i !== checkins.length - 1) {
					checkinDiv.find('.multi_field_add_btn').trigger('click');
				}

				// checkinDiv.find('input').attr('disabled', 'disabled');

				// checkinDiv.find('.multi_field_add_btn').on('click', function (e: MouseEvent) {
				// 	if (!$(this).hasClass('btn-danger') && $(this).parent().parent().find('input[name="checkin_name[]"]').attr('disabled') === 'disabled') {
				// 		$(this)
				// 			.parent()
				// 			.parent()
				// 			.find('input')
				// 			.each((index: number, el: HTMLInputElement) => {
				// 				if (!$(el).hasClass('created_at_date')) {
				// 					$(el).removeAttr('disabled');
				// 				}
				// 			});
				// 	}

				// 	if ($(e.currentTarget).hasClass('btn-danger')) {
				// 	}
				// });
			}
		}

		configureReferenceTypeAhead(sampleTile);
		configureNameTypeAhead(sampleTile);
		// configurePhoneTypeAhead(sampleTile);

		sampleTile.find('input[name="phone"]').change(function () {
			sampleTile.find('.phone-detail .title').html('Mobile: ' + getInApp({ phone: $(this).val() }));
		});

		if (stateMap.checkinAllowed === false && Records.editAllowed === false) {
			sampleTile.find('.main-details .check-in-detail input').attr('disabled', 'disabled');
			sampleTile.find('.main-details .check-in-detail select').attr('disabled', 'disabled');
		} else {
		}

		return sampleTile;
	}

	function updateDateTime(dateTime, el) {
		dateTime = dateTime.split(' ');
		var dateAr = dateTime[0].split('-');
		var date = new Date(parseInt(dateAr[0]), parseInt(dateAr[1]) - 1, parseInt(dateAr[2]));

		el.attr('value', dateTime[0]);
		el.datepicker('update', date);

		var time = dateTime[1];
		var timeArr = time.split(':');
		if (parseInt(timeArr[0]) > 12) {
			time = parseInt(timeArr[0]) - 12 + ':' + timeArr[1] + ' PM';
		} else {
			time = time + ' AM';
		}

		el.val(el.val() + ' - ' + time);
	}

	function handleCheckins(tile) {
		Records.triggerEditRecord(tile);
	}

	async function onBulkEdit(form) {
		var bulkEditValues = ($(form) as any).serializeObject();
		console.log(bulkEditValues);

		let field: string = bulkEditValues.field;
		let valueExpression: string = bulkEditValues.value.toLowerCase();

		let Model = ModelClass.Instance(stateMap.systemId);
		let records = Records.getCurrentRecords();

		let index = 0;
		let updateCount = 0;

		async function modifyRecord(record) {
			return new Promise((resolve, reject) => {
				let currentValue = record[field];
				let newValue;
				var value;
				let newExpression = valueExpression;

				if (field.endsWith('Time') || field.endsWith('date')) {
					if (newExpression.indexOf('value') >= 0) {
						if (newExpression.indexOf('h') >= 0) {
							newExpression = newExpression.replaceAll('h', '');
							newExpression = newExpression.replaceAll('value', 'value.setHours(value.getHours()') + ')';
						} else if (newExpression.indexOf('d') >= 0) {
							newExpression = newExpression.replaceAll('d', '');
							newExpression = newExpression.replaceAll('value', 'value.setDate(value.getDate()') + ')';
						} else {
							newExpression = newExpression.replaceAll('value', 'value.setDate(value.getDate()') + ')';
						}

						if (field.endsWith('Time')) {
							newExpression = `value = new Date(${currentValue.seconds * 1000});${newExpression}`;
							eval(newExpression);
							newValue = firebase.firestore.Timestamp.fromDate(value);
						} else {
							newExpression = `value = new Date('${currentValue}');${newExpression}`;
							eval(newExpression);
							newValue = new AnandDate().setDateObj(value).format('YYYY-MM-DD');
						}
					} else {
						value = newExpression;
						if (isNaN(new Date(value).getDate())) {
							alert('Invalid Date');
							reject('Invalid Date');
							return;
						}

						if (field.endsWith('Time')) {
							newValue = firebase.firestore.Timestamp.fromDate(new Date(value));
							if (newValue.seconds === currentValue.seconds) {
								resolve(0);
								return;
							}
						} else {
							newValue = new AnandDate(value).format('YYYY-MM-DD');
						}
					}
				} else if (field.endsWith('count') || field === 'deleted') {
					newExpression = newExpression.replaceAll('value', currentValue);
					newValue = eval(newExpression);
				} else if (field === 'approval') {
					if (currentValue && !record.card_no) {
						newValue = {
							status: newExpression === 'true' ? 'Approved' : 'Rejected',
						};
					} else {
						resolve(0);
						return;
					}
				} else {
					newValue = newExpression;
				}

				if (newValue !== currentValue) {
					Model.editRecord({ [field]: newValue }, record.id, async () => {
						await timeout(100);
						updateCount++;
						resolve(updateCount);
					});
				} else {
					resolve(updateCount);
					return;
				}
			});
		}

		for (let j = 0; j < records.length; j++) {
			await modifyRecord(records[j]);
			$(form)
				.find('#update-msg')
				.html(`Updated ${updateCount} / ${j + 1} records`);
		}

		$(form).find('#update-msg').html(`[Complete] Updated all (${updateCount}) / ${records.length} records!`);
		$(form).find('input[name="value"]').val('');

		// if (records.length) {
		// 	modifyRecord(records[index]);
		// }
	}

	return {
		initModule: initModule,
		setConfig: function (key, value) {
			configMap[key] = value;
		},
		setRefFilter: (refs: string[]) => {
			stateMap.refFilter = refs;
			Records.refreshRecords();
		},
	};
})(jQuery);
