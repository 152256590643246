import { HeaderControls, HeaderModule } from 'components/header/anand.header';
import { ModelClass } from 'core/anand.model';
import { Records } from 'core/anand.records';
import { utils as Utils } from 'core/anand.utils';
import AnandDate from 'helpers/AnandDate';

export const TrusteeRecords = (function ($) {
	var configMap = {};

	var stateMap: any = {
		systemId: 'trustee',
		trustNames: [],
		subRecordNo: 1,
	};

	function initModule(props?, updateRecordsState?) {
		HeaderModule.initModule([
			HeaderControls.addRecordBtn,
			HeaderControls.selectDropdown,
			HeaderControls.printBtn,
			HeaderControls.sortBtn,
		]);

		Records.projectTitle = 'Trustee Records';

		Records.getAnySearchFilter = getAnySearchFilter;
		Records.defaultSortOrder = 'function';
		Records.IdentifyingFieldName = 'name';
		Records.sortDirection = 1;
		Records.updateRecordsState = updateRecordsState;

		Records.customSortingFunction = customSorting;
		Records.sortingFieldsMap = {
			function: 'Serial',
		};

		Records.searchFieldsMap = {
			name: 'Name',
			address: 'Address',
			contacts: 'Contacts',
			comments: 'Comments',
			trust_trustees: { filter: 'has', text: 'Trusts' },
		};

		Records.isSearchDateTypeColumn = function (searchBy) {
			return false;
		};

		Records.printOptions = {
			fieldNames: ['S. No.', 'Name', 'Address', 'Pan', 'Contacts', 'Trusts', 'Comments'],
			fieldKeys: ['id', 'name', 'address', 'pan', 'contacts', 'trusts', 'comments'],
			defaultFieldWidths: { id: 40, name: 250, address: 180, pan: 100, contacts: 180, trusts: 300, comments: 0 },
			defaultHeader: 'Trustee Records',
			beforePrintHook: function (data, forExport) {
				var titleMap = {
					Swami: 'Shri Swami',
					Mahatma: 'Mh.',
					Bai: 'Bai',
					'Bh.': 'Bh.',
					'': '',
				};

				for (var i = 0; i < data.length; i++) {
					data[i]['id'] = i + 1 + '.';
					data[i]['title'] = titleMap[data[i]['title']];
					if (typeof forExport === 'undefined' || forExport === false) {
						data[i]['name'] = data[i]['title'] + ' ' + data[i]['name'] + ' Ji';
					} else {
						data[i]['name'] = data[i]['name'] + ' Ji';
					}

					if (data[i]['contacts'] !== null) {
						var contacts = data[i]['contacts'];

						var html = '';
						var newLine = '';
						for (var contactType in contacts) {
							for (var j = 0; j < contacts[contactType].length; j++) {
								html += newLine + contactType + ': ' + contacts[contactType][j];
								newLine = '\n';
							}
						}
						data[i]['contacts'] = html;
					}

					if (typeof data[i]['trust_trustees'] !== 'undefined') {
						var trusts = data[i]['trust_trustees'];

						var html = '';
						var newLine = '';
						for (var j = 0; j < trusts.length; j++) {
							html += newLine + trusts[j]['trust_name'] + ': ' + trusts[j]['designation'];
							newLine = '\n';
						}
						data[i]['trusts'] = html;
					} else {
						data[i]['trusts'] = '';
					}
				}

				return data;

				function getDMY(date) {
					var parts = date.split('-');
					return parts[2] + '-' + parts[1] + '-' + parts[0];
				}
			},
		};

		Records.exportOptions = {
			fieldKeys: ['title', 'name', 'address', 'pan', 'contacts', 'trusts', 'comments'],
			defaultFileName: 'Trustee Records',
		};

		Records.hooks.afterHeaderConfigure = function (controlsHeader) {
			var allBtn = $(
				'<a class="btn all-records-btn"><span class="glyphicon glyphicon-eye-open"></span> <span class="hidden-sm hidden-xs menu-text">All</span></a>'
			);
			controlsHeader.find('.menu-links .sort-records-btn').after(allBtn);
			stateMap.showAll = false;
			allBtn.click(function () {
				if (stateMap.showAll) {
					$(this).find('.glyphicon').removeClass('glyphicon-eye-close').addClass('glyphicon-eye-open');
					$(this).css('color', '');
					$(this).find('.menu-text').html('All');
					stateMap.showAll = false;
				} else {
					$(this).find('.glyphicon').removeClass('glyphicon-eye-open').addClass('glyphicon-eye-close');
					$(this).css('color', 'lightgreen');
					$(this).find('.menu-text').html('Current');
					stateMap.showAll = true;
				}
				Records.refreshRecords();
			});

			stateMap.selectionDropdown.find('option').remove();
			stateMap.selectionDropdown.append($("<option value='All' selected='selected'>All</option>"));

			if (stateMap.trustNames) {
				for (var i = 0; i < stateMap.trustNames.length; i++) {
					stateMap.selectionDropdown.append(
						$("<option value='" + stateMap.trustNames[i] + "'>" + stateMap.trustNames[i] + '</option>')
					);
				}
			}

			stateMap.selectionDropdown.trigger('change');
		};

		Records.hooks.beforeAddTileShow = function (addTile) {
			configureTrustNameTypeAhead(addTile);
			return addTile;
		};

		Records.hooks.configureHook = function () {
			configureMainFilter();
		};

		Records.getAdditionalSearchFilters = function () {
			return [getMainFilter()];
		};

		Records.detailTileSelector = '#trustee-detail-record';
		Records.briefTileSelector = '#trustee-brief-record';
		Records.addTileSelector = '#trustee-add-record';
		Records.detailFormSelector = '#trustee-detail-record-form';
		Records.imageUploadSelector = "input[name='image']";

		Records.populateBriefTile = populateBriefTile;
		Records.populateDetailTile = populateDetailTile;
		Records.populateDetailForm = populateDetailForm;

		stateMap.selectionDropdown = $('#selection-dropdown select');
		Records.initModule({
			systemId: stateMap.systemId,
			prepareRecord: prepareRecord,
			hasContacts: true,
			props: props,
		});
	}

	function configureMainFilter() {
		$("select[name='main_filter_dropdown']").on('change', function () {
			Records.refreshRecords();
		});
	}

	function getMainFilter() {
		var mainFilter = $("select[name='main_filter_dropdown']");
		var mainFilterVal = mainFilter.val();
		if (mainFilterVal === 'All') {
			if (stateMap.showAll === false) {
				return {
					trust_trustees: { has: { to_date: null } },
				};
			} else {
				return {};
			}
		} else {
			if (stateMap.showAll === false) {
				return function () {
					if (this.trust_trustees) {
						for (let i = 0; i < this.trust_trustees.length; i++) {
							if (
								this.trust_trustees[i].trust_name === mainFilterVal &&
								this.trust_trustees[i].to_date === null
							) {
								return true;
							}
						}
					}
					return false;
				};

				// {
				// 	trust_trustees:  { hasAll: [{ trust_name: mainFilterVal }, { to_date: null }] },
				// };
			} else {
				return {
					trust_trustees: { has: { trust_name: mainFilterVal } },
				};
			}
		}
	}

	function prepareRecord(record) {
		record['name'] = Utils.upperFirstAll(record['name']);

		record['trust_trustees'] = [];
		if (record['trust_name']) {
			var trusts = record['trust_name'];
			for (var i = 0; i < trusts.length; i++) {
				if (trusts[i] === '') {
					continue;
				}

				if (stateMap.trustNames.indexOf(trusts[i]) < 0) {
					stateMap.trustNames.push(trusts[i]);
					stateMap.selectionDropdown.append(
						$("<option value='" + trusts[i] + "'>" + trusts[i] + '</option>')
					);
				}

				let trust_name = trusts[i];
				let designation = record['designation'][i];
				let serial_no = record['serial_no'][i];
				let from_date = record['from_date'][i];
				let to_date = record['to_date'][i];
				to_date = to_date === '' ? null : new AnandDate().setDateObj(new Date(to_date)).format('YYYY-MM-DD');
				from_date = new AnandDate().setDateObj(new Date(from_date)).format('YYYY-MM-DD');

				record['trust_trustees'].push({
					designation,
					from_date,
					to_date,
					serial_no,
					trust_name,
				});
			}

			delete record['trust_trustees_ids'];
			delete record['designation'];
			delete record['serial_no'];
			delete record['from_date'];
			delete record['to_date'];
			delete record['trust_name'];
		}

		return record;
	}

	function getAnySearchFilter(query) {
		return [
			{ title: { leftnocase: query } },
			{ name: { likenocase: query } },
			{ address: { likenocase: query } },
			{ pan: { likenocase: query } },
			{ contacts: { likenocase: query } },
			{ trust_trustees: { has: query } },
			{ comments: { likenocase: query } },
		];
	}

	function configureTrustNameTypeAhead(tile) {
		var getSuggestions = function () {
			return function findMatches(query, callback) {
				var matches = [];
				var substrRegex = new RegExp(query, 'i');

				$.each(stateMap.trustNames, function (i, str) {
					if (substrRegex.test(str)) {
						matches.push({ value: str });
					}
				});

				callback(matches);
			};
		};

		tile.find("input[name='trust_name[]']").typeahead(
			{
				hint: false,
				minLength: 0,
				highlight: true,
			},
			{
				name: 'trustnames',
				display: 'value',
				source: getSuggestions(),
			}
		);
	}

	async function populateBriefTile(sampleTile, record) {
		Records.setImageSrc(sampleTile.find('.self-image'), record['image'], record['image_update_time']);

		var titleMapping = {
			Swami: 'Shri Swami',
			Mahatma: 'Mh.',
			Bai: 'Bai',
			'Bh.': 'Bh.',
		};

		sampleTile.find('.self-detail .title').html(titleMapping[record['title']]);
		sampleTile.find('.self-detail .value').html(record['name'] + ' Ji');
		sampleTile.find('.address-detail .value').html(record['address']);

		if (typeof record['trust_trustees'] !== 'undefined') {
			var trusts = record['trust_trustees'];
			for (var i = 0; i < trusts.length; i++) {
				if (stateMap.trustNames.indexOf(trusts[i]['trust_name']) < 0) {
					stateMap.trustNames.push(trusts[i]['trust_name']);
					stateMap.selectionDropdown.append(
						$("<option value='" + trusts[i]['trust_name'] + "'>" + trusts[i]['trust_name'] + '</option>')
					);
				}
			}
		}

		return sampleTile;
	}

	async function populateDetailTile(sampleTile, recordOrig) {
		var record = {};
		for (var key in recordOrig) {
			if (recordOrig[key] == undefined || recordOrig[key] == null || recordOrig[key] == '') {
				record[key] = null;
			} else {
				record[key] = recordOrig[key];
			}
		}

		Records.setImageSrc(sampleTile.find('.self-image'), record['image'], record['image_update_time']);

		var titleMapping = {
			Swami: 'Shri Swami',
			Mahatma: 'Mh.',
			Bai: 'Bai',
			'Bh.': 'Bh.',
		};

		sampleTile.find('.main-details .self-detail .title').html();
		sampleTile
			.find('.main-details .self-detail .value')
			.html(titleMapping[record['title']] + ' ' + record['name'] + ' Ji');

		if (record['so_name'] === null || record['so_name'] === '') {
			sampleTile.find('.main-details .so-detail').hide();
		} else {
			sampleTile.find('.main-details .so-detail .value').html(record['so_name'] + ' Ji');
		}

		if (record['holy_name'] === null || record['holy_name'] === '') {
			sampleTile.find('.main-details .holy-name-detail').hide();
		} else {
			sampleTile
				.find('.main-details .holy-name-detail .value')
				.html(titleMapping[record['holy_title']] + ' ' + record['holy_name'] + ' Ji');
		}

		if (record['address'] === null || record['address'] === '') {
			sampleTile.find('.main-details .address-detail').hide();
		} else {
			sampleTile.find('.main-details .address-detail .value').html(record['address']);
		}

		if (record['pan'] === null || record['pan'] === '') {
			sampleTile.find('.main-details .pan-detail').hide();
		} else {
			sampleTile.find('.main-details .pan-detail .value').html(record['pan']);
		}

		if (record['comments'] === null || record['comments'] === '') {
			sampleTile.find('.comments-detail').hide();
		} else {
			sampleTile.find('.main-details .comments-detail .value').html(record['comments']);
		}

		if (record['contacts'] && record['contacts'].length > 0) {
			var contacts = record['contacts'];
			for (var contactType in contacts) {
				for (var i = 0; i < contacts[contactType].length; i++) {
					var contactRow = sampleTile.find('.main-details .contact-detail #contact-type-value').clone();
					contactRow.attr('id', 'contact-type-value' + '-' + contactType + '-' + i);
					contactRow.find('.contact-type').html(contactType + ':');
					contactRow.find('.contact-value').html(contacts[contactType][i]);
					sampleTile.find('.main-details .contact-detail').append(contactRow);
				}
			}
		} else {
			sampleTile.find('.main-details .contact-detail').hide();
		}

		var selectedTrust = $("select[name='main_filter_dropdown']").val();
		var recordSubOrder;
		if (typeof record['trust_trustees'] !== 'undefined') {
			var trusts = record['trust_trustees'];
			for (var i = 0; i < trusts.length; i++) {
				if (selectedTrust === trusts[i]['trust_name']) {
					let Model = ModelClass.Instance(stateMap.systemId);
					var data = Model.getRecords(
						[
							{
								trust_trustees: {
									hasAll: [{ serial_no: trusts[i]['serial_no'] }, { trust_name: selectedTrust }],
								},
							},
						],
						sortBySerialAsc
					);
					for (var j = 0; j < data.length; j++) {
						if (data[j]['id'] === record['id']) {
							recordSubOrder = j + 1;
						}
					}
					if (stateMap.showAll) {
						if (trusts[i]['serial_no'] % 2 === 1) {
							sampleTile.find('.tile').addClass('odd');
							if (recordSubOrder === 1) {
								sampleTile.find('.tile').addClass('first');
							}
							if (recordSubOrder === data.length) {
								sampleTile.find('.tile').addClass('last');
							}
						} else {
							sampleTile.find('.tile').addClass('even');
							if (recordSubOrder === 1) {
								sampleTile.find('.tile').addClass('first');
							}
							if (recordSubOrder === data.length) {
								sampleTile.find('.tile').addClass('last');
							}
						}
					}
					stateMap.prevRecord = trusts[i];
					stateMap.prevTile = sampleTile;
				}
				var idRow = sampleTile.find('.main-details .trustee-detail #trustee-row').clone();
				idRow.attr('id', 'trustee-row' + '-' + i);
				idRow.find('.trust-name').html(trusts[i]['trust_name']);
				idRow.find('.serial-no').html(trusts[i]['serial_no'] + (recordSubOrder ? ' : ' + recordSubOrder : ''));
				if (trusts[i]['designation'] === '' || trusts[i]['designation'] === null) {
					idRow.find('.designation').html('&nbsp');
				} else {
					idRow.find('.designation').html(trusts[i]['designation']);
				}
				setDateField(idRow.find('.from-date'), trusts[i]['from_date'], false);
				if (trusts[i]['to_date'] === '' || trusts[i]['to_date'] === null) {
					idRow.find('.to-date').html('&nbsp');
					idRow.find('.to-label').html('&nbsp');
					if (stateMap.showAll && (selectedTrust === 'All' || selectedTrust === trusts[i]['trust_name'])) {
						sampleTile.addClass('current-trustee');
					}
				} else {
					setDateField(idRow.find('.to-date'), trusts[i]['to_date'], false);
				}
				idRow.show();
				sampleTile.find('.main-details .trustee-detail').append(idRow);

				if (stateMap.trustNames.indexOf(trusts[i]['trust_name']) < 0) {
					stateMap.trustNames.push(trusts[i]['trust_name']);
					if (stateMap.selectionDropdown) {
						stateMap.selectionDropdown.append(
							$(
								"<option value='" +
									trusts[i]['trust_name'] +
									"'>" +
									trusts[i]['trust_name'] +
									'</option>'
							)
						);
					}
				}
			}
		}

		return sampleTile;
	}

	function populateDetailForm(sampleTile, record) {
		Records.setImageSrc(sampleTile.find('.self-image'), record['image'], record['image_update_time']);

		sampleTile
			.find('.main-details .self-detail select[name="title"] option[value="' + record['title'] + '"]')
			.attr('selected', 'selected');
		sampleTile.find('.main-details .self-detail .value input').attr('value', record['name']);
		sampleTile.find('.main-details .so-detail .value input').attr('value', record['so_name']);
		sampleTile
			.find(
				'.main-details .holy-name-detail select[name="holy_title"] option[value="' + record['holy_title'] + '"]'
			)
			.attr('selected', 'selected');
		sampleTile.find('.main-details .holy-name-detail .value input').attr('value', record['holy_name']);
		sampleTile.find('.main-details .address-detail .value input').attr('value', record['address']);
		sampleTile.find('.main-details .pan-detail .value input').attr('value', record['pan']);
		sampleTile.find('.main-details .comments-detail .value input').attr('value', record['comments']);

		if (record['contacts'] !== null) {
			var contacts = record['contacts'];
			var count = 0;
			for (var contactType in contacts) {
				for (var i = 0; i < contacts[contactType].length; i++) {
					count++;
				}
			}
			var contactDivId = Records.populateMultiAddFields(sampleTile.find('.main-details .contact-detail'), count);

			count = 1;
			for (var contactType in contacts) {
				for (var i = 0; i < contacts[contactType].length; i++) {
					var contactDiv = sampleTile.find('#' + contactDivId + count++);
					contactDiv
						.find('select[name="contact_type[]"] option[value="' + contactType + '"]')
						.attr('selected', 'selected');
					contactDiv.find('input[name="contact[]"]').val(contacts[contactType][i]);
				}
			}
		}

		if (typeof record['trust_trustees'] !== 'undefined') {
			var trusts = record['trust_trustees'];
			count = trusts.length;
			var trusteeDivId = Records.populateMultiAddFields(
				sampleTile.find('.main-details .trustee-detail'),
				trusts.length
			);

			for (var i = 0; i < trusts.length; i++) {
				var trusteeDiv = sampleTile.find('#' + trusteeDivId + (i + 1));
				trusteeDiv.find('input[name="trust_trustees_ids[]"]').val(trusts[i]['id']);
				trusteeDiv.find('input[name="trust_name[]"]').val(trusts[i]['trust_name']);
				trusteeDiv.find('input[name="designation[]"]').val(trusts[i]['designation']);
				trusteeDiv.find('input[name="serial_no[]"]').val(trusts[i]['serial_no']);
				setDateField(trusteeDiv.find('input[name="from_date[]"]'), trusts[i]['from_date'], true);
				setDateField(trusteeDiv.find('input[name="to_date[]"]'), trusts[i]['to_date'], true);
			}
		}

		configureTrustNameTypeAhead(sampleTile);

		return sampleTile;
	}

	Records.clearAllFilters = function () {
		$('select[name="main_filter_dropdown"]').val('All');
	};

	function customSorting(title, sortDir) {
		switch (title) {
			case 'Serial':
				return sortDir === 1 ? sortBySerialAsc : sortBySerialDesc;
		}
	}

	function sortByTrusteeField(a, b, field) {
		var at = a['trust_trustees'];
		var bt = b['trust_trustees'];

		var aSerial = 0;
		var bSerial = 0;
		var aFrom = 0;
		var bFrom = 0;

		var selectedTrust = $("select[name='main_filter_dropdown']").val();
		if (at) {
			for (var i = 0; i < at.length; i++) {
				if (at[i]['trust_name'] === selectedTrust) {
					aSerial = at[i][field];
					aFrom = at[i]['from_date'];
					break;
				}
			}
		}

		if (bt) {
			for (var i = 0; i < bt.length; i++) {
				if (bt[i]['trust_name'] === selectedTrust) {
					bSerial = bt[i][field];
					bFrom = bt[i]['from_date'];
					break;
				}
			}
		}

		if (aSerial === bSerial) {
			return new Date(aFrom) >= new Date(bFrom) ? 1 : -1;
		} else {
			return aSerial - bSerial;
		}
	}

	function sortBySerialAsc(a, b) {
		return sortByTrusteeField(a, b, 'serial_no');
	}

	function sortBySerialDesc(a, b) {
		return sortByTrusteeField(b, a, 'serial_no');
	}

	function setDateField(div, date, isForm) {
		if (date !== null) {
			var dateAr = date.split('-');
			if (dateAr.length === 3) {
				var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
				if (isForm === true) {
					div.attr('value', dateStr);
					div.datepicker('update', dateStr);
				} else {
					dateStr = Utils.getDateStr(dateAr[0], dateAr[1], dateAr[2]);
					div.html(dateStr);
				}
			} else {
				if (isForm === true) {
					div.attr('value', date);
					div.datepicker('update', date);
				} else {
					div.html(date);
				}
			}
		}
	}

	return {
		initModule: initModule,
		setConfig: function (key, value) {
			configMap[key] = value;
		},
	};
})(jQuery);
