import { FireStoreModule } from 'fb';
import firebase from 'fb/index';
import 'firebase/firestore';
import { updateMoment } from 'store/data/moment/actions';

export class MomentFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'moment';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: firebase.firestore.Firestore) => db.collection('moment');
	public getChangeProcessAction = (changes) => updateMoment(changes);
}
