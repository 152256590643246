import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ApplicationState } from 'types';
import { connect } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			flexGrow: 1,
			fontSize: '1.75rem',
		},
	})
);

interface AppBarTitleProps {
	title: string;
}

export function AppBarTitle(props: AppBarTitleProps) {
	const classes = useStyles(props);

	return (
		<Typography className={classes.title} variant='h6' noWrap>
			{props.title}
		</Typography>
	);
}

function mapStateToProps({ tempState }: ApplicationState) {
	let { appBarTitle } = tempState;

	return {
		title: appBarTitle,
	};
}

export default connect(mapStateToProps)(AppBarTitle);
