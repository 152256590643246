import { IntegratedRecordsList } from 'components/IntegratedRecordsList';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, MomentRecord } from 'types';
import { RecordsHandler } from './RecordsHandler';
import { ViewTile } from './viewTile';

let CSS = `
#selection-dropdown {
    display: none !important;
}

.tile.book.detail {
    padding-top: 15px;
}

.tile.book .main-details {
    padding-right: 30px;
    padding-left: 30px;
}

.tile.book .main-details .title {
	text-align: right;
	padding: 0;
	padding-bottom: 25px;
    font-weight: bold;
    font-size: 0.9em;
}

form.tile.book .main-details .value {
	padding-right: 0;
}

.tile.book input {
	width: 100%;
	background-color: white!important;
    border: none;
    border-bottom: 1px solid darkgray;
}

.tile.book input:focus {
    outline: none;    
}

.tile.book .value select {
	width: 100%;
}

div.tile.book .main-details .value {
    border-bottom: 1px solid lightgrey;
    min-height: 1.5em;
}

div.tile.book .main-details .multi-field-row .value {
	text-align: center;
}

div.tile.book .main-details .multi-field-row .title {
	border-bottom: none;
}

.stock-detail, .book-editions-detail {
	border: 1px solid lightgrey;
	margin-bottom: 10px;
}


.stock-detail #stock-value, .book-editions-detail #edition-row-detail {
	display: none;
}

.stock-detail .value, .book-editions-detail .value {
	border-bottom: none !important;
}

.stock-detail .title, .book-editions-detail .title {
	padding-bottom: 3px !important;
	padding-top: 3px !important;
	text-align: center !important;
	border-bottom: 1px lightgrey solid;
}

.lb {
    border-bottom: 1px solid lightgrey;
}

.multi-field-row {
	border-bottom: 1px solid lightgrey;
	padding-top: 3px;
	padding-bottom: 3px;
}

.multi-field-row:nth-last-child(1) {
	border-bottom: none;
}

.strong {
	font-weight: bold;
	text-align: right;
	padding-right: 0 !important;
}
`;

const html = `
<style>
	${CSS}
</style>

<div id="page-records">
    <div class="records">        
        <div class="filtered-records">
        </div>
    </div>
</div>
`;

interface Props {
	records: MomentRecord[];
}

class Component extends React.Component<Props> {
	render() {
		return (
			<React.Fragment>
				<IntegratedRecordsList
					records={this.props.records}
					RecordTile={ViewTile}
					html={html}
					RecordsHandler={RecordsHandler}
				/>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		records: state.dataState.book.filtered,
	};
}

export const Book = connect(mapStateToProps)(Component);
