import { combineReducers } from 'redux';
import { DhamRecord, DhamStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateDham, UpdateDhamFiltered, UPDATE_DHAM, UPDATE_DHAM_FILTERED } from './actions';

export const dhamByIdReducer = (state = {}, action: UpdateDham) => {
	switch (action.type) {
		case UPDATE_DHAM:
			return updateData(state, action.changes, 'dham');
		default:
			return state;
	}
};

const filteredDhamReducer = (state: DhamRecord[] = [], action: UpdateDhamFiltered) => {
	switch (action.type) {
		case UPDATE_DHAM_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const dhamReducer = combineReducers<DhamStore>({
	byId: dhamByIdReducer,
	filtered: filteredDhamReducer,
});
