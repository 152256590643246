import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { AppDataState, ApplicationState } from 'types';
import { anumatiReducer } from './data/anumati/reducers';
import { articlesReducer } from './data/articles/reducers';
import { bookReducer } from './data/book/reducers';
import { configsReducer } from './data/configs/reducers';
import { dhamReducer } from './data/dham/reducers';
import { idcardReducer } from './data/idcard/reducers';
import { categoryReducer } from './data/journals/category/reducers';
import { pincodeReducer } from './data/journals/pincode/reducers';
import { rateReducer } from './data/journals/rate/reducers';
import { subscriberReducer } from './data/journals/subscriber/reducers';
import { throughReducer } from './data/journals/through/reducers';
import { kutiyaReducer } from './data/kutiya/reducers';
import { mjbjReducer } from './data/mjbj/reducers';
import { momentReducer } from './data/moment/reducers';
import { opsReducer } from './data/ops/reducers';
import { sangatReducer } from './data/sangat/reducers';
import { trusteeReducer } from './data/trustee/reducers';
import { fbUserPlaylistsReducer, userStoreReducer } from './data/user/reducers';
import { initialState } from './migrations/0';
import { offlineReducer } from './offline/reducers';
import tempReducers from './temp/reducers';
import uxReducers from './ux/reducers';

const dataReducers = combineReducers<AppDataState>({
	articles: articlesReducer,
	mjbj: mjbjReducer,
	anumati: anumatiReducer,
	kutiya: kutiyaReducer,
	trustee: trusteeReducer,
	moment: momentReducer,
	idcard: idcardReducer,
	book: bookReducer,
	sangat: sangatReducer,
	dham: dhamReducer,
	rate: rateReducer,
	category: categoryReducer,
	through: throughReducer,
	pincode: pincodeReducer,
	subscriber: subscriberReducer,
	userStore: userStoreReducer,
	fbUserPlaylists: fbUserPlaylistsReducer,
	configs: configsReducer,
});

// const combinedReducer = combineReducers<ApplicationState>({
// 	dataState: dataReducers,
// 	uxState: uxReducers,
// 	tempState: tempReducers,
// 	opsState: opsReducer,
// });

export const rootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		dataState: dataReducers,
		uxState: uxReducers,
		tempState: tempReducers,
		opsState: opsReducer,
		offlineState: offlineReducer,
	});

function crossSliceReducer(state, action) {
	switch (action.type) {
		case 'HACK_STATE': {
			let path: string = action.path;
			let value = action.value;

			let keys = path.split('.');
			let newState = { ...state };

			let deepState = newState;
			for (let i = 0; i < keys.length - 1; i++) {
				deepState[keys[i]] = { ...deepState[keys[i]] };
				deepState = deepState[keys[i]];
			}

			deepState[keys[keys.length - 1]] = value;

			return newState;
		}
		default:
			return state;
	}
}

export const createRootReducer =
	(history) =>
	(state: ApplicationState = initialState, action) => {
		let intermediateState = rootReducer(history)(state, action);
		const finalState = crossSliceReducer(intermediateState, action);
		return finalState;
	};
