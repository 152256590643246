import { Card, Divider, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Theme, withStyles } from '@material-ui/core/styles';
import { DialogContext } from 'components/pages/DialogPage';
import { Icons } from 'config/icons';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { getWebAppAssetPath } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { deleteByGoogleSign, deleteByPasswordLogin } from 'services/api/auth';
import { ApplicationState } from 'types';
import * as Yup from 'yup';
import { UserPicInfo } from './UserPicInfo';

const styles = (theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	},
	card: {
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: 'white',
	},
	gsignin: {
		marginBottom: theme.spacing(5),
		padding: 0,
		width: '90%',
		alignSelf: 'center',
		// border: '1px solid ' + theme.palette.secondary.main,

		'& .g-logo': {
			height: 42,
		},

		'& .g-msg': {
			width: 'calc(100% - 42px)',
		},
	},
	or: {
		textAlign: 'center' as 'center',
		backgroundColor: 'white',
		width: '10%',
		marginTop: -10,
		alignSelf: 'center',
	},
	message: {
		marginBottom: theme.spacing(4),
	},
	form: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '90%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	},
	signinbtn: {
		height: 42,
		width: '90%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	},
});

export interface Props {
	deleteByPasswordLogin: (currentPass: string) => Promise<void>;
	deleteByGoogleSign: () => Promise<void>;
	user?: firebase.default.User;
	classes: any;
}

class DeleteAccount extends React.Component<Props, any> {
	constructor(props) {
		super(props);

		this.state = {
			showPassword: false,
		};
	}

	render() {
		let { classes, deleteByGoogleSign, deleteByPasswordLogin, user } = this.props;
		let { showPassword } = this.state;

		const handleClickShowPassword = () => {
			this.setState({ showPassword: !showPassword });
		};

		const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
		};

		return (
			<div className={classes.root}>
				<UserPicInfo />
				<Card className={classes.card}>
					<Icons.WarningIcon style={{ fontSize: 64, alignSelf: 'center', color: 'red' }} />
					<Typography className={classes.message} variant='body2' component='div'>
						<h4>This action is irreversible! Deletion will:</h4>
						<ul style={{ paddingLeft: 24 }}>
							<li>Delete your account details from our server</li>
							<li>Delete your settings and profile photo</li>
							<li>Delete your playlists</li>
							<li>Unsubscribe you from email communications</li>
						</ul>
						<h4>Confirm deletion by again signing-in:</h4>
					</Typography>
					{user && user.providerData.map((provider) => provider?.providerId).indexOf('google.com') >= 0 ? (
						<Button
							className={classes.gsignin}
							onClick={async () => {
								await deleteByGoogleSign();
								this.context.popScreen();
							}}
							variant='contained'
						>
							<img src={getWebAppAssetPath('g-logo.png')} className='g-logo' alt='Google Logo' />
							<span className='g-msg'>Confirm Delete</span>
						</Button>
					) : null}
					{user && user.providerData.length > 1 ? (
						<React.Fragment>
							<Divider />
							<div className={classes.or}>or</div>
						</React.Fragment>
					) : null}

					{user && user.providerData.map((provider) => provider?.providerId).indexOf('password') >= 0 ? (
						<Formik
							initialValues={{
								current: '',
							}}
							validationSchema={() =>
								Yup.object({
									current: Yup.string().required('Required'),
								})
							}
							onSubmit={async (values, { setSubmitting }) => {
								await deleteByPasswordLogin(values.current);
								this.context.popScreen();
								return;
							}}
						>
							{({ submitForm, isSubmitting }) => (
								<Form className={classes.form} translate='yes'>
									<Field
										component={TextField}
										type={showPassword ? 'text' : 'password'}
										label='Current Password'
										name='current'
										autoComplete='off'
										autoCapitalize='off'
										autoCorrect='off'
										spellCheck='false'
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														tabIndex={-1}
													>
														{showPassword ? Icons.Visibility : Icons.VisibilityOff}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									<Button
										className={classes.signinbtn}
										variant='contained'
										onClick={submitForm}
										disabled={isSubmitting}
									>
										Confirm Delete
									</Button>
								</Form>
							)}
						</Formik>
					) : null}
				</Card>
			</div>
		);
	}
}

function mapStateToProps({ dataState }: ApplicationState) {
	let { userStore } = dataState;

	return {
		user: userStore.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		deleteByPasswordLogin: async (currentPass: string) => {
			await dispatch(deleteByPasswordLogin({ currentPass }));
		},
		deleteByGoogleSign: async () => {
			await dispatch(deleteByGoogleSign());
		},
	};
}

DeleteAccount.contextType = DialogContext;
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DeleteAccount));
