
const searchSection = `
	<div id="search-section">
		<div id="searching-element" class="col-xs-4 col-md-4 no-padding">
			<input class="input-sm" type="text" data-searchby="name" placeholder="Name">
		</div>

		<div class="row specific-search-section">
		</div>
	</div>`;

const totalRecords = `
	<div class="col-xs-6 col-md-2 col-lg-2 no-r-padding total-records">
		<div id="record_count" class="no-r-padding">
			<span>TOTAL RECORDS: 0</span>
		</div>

		<div id="selection-dropdown" class="no-r-padding">
			<select name="main_filter_dropdown">
			</select>
		</div>
	</div>`;

const totalRecordsCompact = `
<div class="col-xs-3 col-md-2 col-lg-2 no-r-padding total-records">
	<div id="record_count" class="no-r-padding compact">
		<span>TOTAL RECORDS: 0</span>
	</div>

	<div id="selection-dropdown" class="no-r-padding">
		<select name="main_filter_dropdown">
		</select>
	</div>
</div>`;

const dashboardBtn = `
	<a class="btn dashboard-btn">
		<span class="glyphicon glyphicon-dashboard"></span>
		<span class="hidden-sm hidden-xs menu-text">Dashboard</span>
	</a>`;

const addRecordBtn = `
	<a class="btn add-record-btn">
		<span class="glyphicon glyphicon-plus"></span>
		<span class="hidden-sm hidden-xs menu-text">Add</span>
	</a>`;

const exportBtn = `
	<a class="btn export-btn">
		<span class="glyphicon glyphicon-export"></span>
		<span class="hidden-sm hidden-xs menu-text">Export</span>
	</a>`;

const selectDropdown = `
	<span class="select-dropdown dropdown hidden-sm hidden-xs">
		<a class="btn selection-btn dropdown-toggle" data-toggle="dropdown">
			<span class="glyphicon glyphicon-check"></span>
			<span class="menu-text">Selection</span>
			<span class="caret"></span>
		</a>
		<ul class="dropdown-menu">
			<li><a class="btn menu-text selection-select-all">
					<span class="menu-text">Select All</span>
				</a>
			</li>
			<li>
				<a class="btn menu-text selection-clear-all">
					<span class="menu-text">Clear All</span>
				</a>
			</li>
			<li class="divider"></li>
			<li>
				<a class="btn menu-text selection-show">
					<span class="menu-text">Show Selected</span>
				</a>
			</li>
			<li>
				<a class="btn menu-text selection-show-not">
					<span class="menu-text">Show UnSelected</span>
				</a>
			</li>
			<li>
				<a class="btn menu-text selection-showall">
					<span class="menu-text">Show All</span>
				</a>
			</li>
		</ul>
	</span>`;

const printBtn = `
	<a class="btn print-btn hidden-sm hidden-xs">
		<span class="glyphicon glyphicon-print"></span>
		<span class="hidden-sm hidden-xs menu-text">Print</span>
	</a>`;

const sortBtn = `
	<a class="btn sort-records-btn">
		<span class="glyphicon glyphicon-sort"></span>
		<span class="hidden-sm hidden-xs menu-text">Sort</span>
	</a>`;

const reminderBtn = `
	<a class="btn reminder-btn hidden-xs">
		<span class="glyphicon glyphicon-bell"></span>
		<span class="hidden-sm hidden-xs menu-text">Alarm</span>
		<span class="reminder-btn-badge hidden-sm hidden-xs label label-primary">
			<span class="value">0</span>
		</span>
	</a>`;

const menuLinks = `
	<div class="col-xs-6 col-xs-pull-0 col-md-push-3 col-md-7 menu-links">
	</div>`;

const menuLinksCompact = `
<div class="col-xs-9 col-xs-pull-0 col-md-push-3 col-md-7 menu-links">
</div>`;

const searchAnything = `
	<div class="col-xs-12 col-xs-push-0 col-md-pull-7 col-md-3 no-l-padding search-anything">
		<div id="search-section" class="col-xs-12">
			<div class="row">
				<div class="col-xs-10" id="search-text-input">
					<input type="text" name="search_query" placeholder="Search Anything">
				</div>
				<div class="col-xs-1 clear-search-btn no-padding">
					<i class="glyphicon glyphicon-remove"></i>
				</div>
				<div class="col-xs-1 specific-search-btn no-padding">
					<i class="glyphicon glyphicon-menu-right default-icon float-left"></i>
					<i class="glyphicon glyphicon-menu-up alt-icon float-left"></i>
				</div>
			</div>
		</div>
	</div>`;

const findAnything = `
	<div class="no-padding find-anything hidden-xs">
		<div id="find-section" class="col-xs-12 no-padding ">
			<div class="row">
				<div class="col-xs-12 no-l-padding" id="find-text-input">
					<input type="text" name="find_query" placeholder="Go To $/#/-">
				</div>
			</div>
		</div>
	</div>`;

const sortSection = `
	<div class="row">
		<div id="sorting-element" class="col-xs-3 col-md-2 sort-option" data-sortby="name">
			<div class="icon col-xs-4">
				<i class="glyphicon glyphicon-sort-by-attributes default-order"></i>
				<i class="glyphicon glyphicon-sort-by-attributes-alt alt-order"></i>
			</div>
			<div class="text col-xs-8">
				<span>Name</span>
			</div>	           	  					
		</div>
		<div class="col-xs-12" id="sorting-section">
			<div class="row">
			</div>
		</div>
	</div>`;

const navBar = `
	<nav class="navbar row">
	</nav>`;

export const HeaderControls = {
	totalRecords: 'totalRecords',
	totalRecordsCompact: 'totalRecordsCompact',
	addRecordBtn: 'addRecordBtn',
	dashboardBtn: 'dashboardBtn',
	printBtn: 'printBtn',
	sortBtn: 'sortBtn',
	reminderBtn: 'reminderBtn',
	selectDropdown: 'selectDropdown',
};

export const HeaderModule = (function ($) {
	let stateMap = {
		id: '#controls-header',
		controlsMap: {
			searchSection: { selector: '', control: searchSection },
			sortSection: { selector: '', control: sortSection },
			totalRecords: { selector: '.navbar', control: totalRecords },
			totalRecordsCompact: { selector: '.navbar', control: totalRecordsCompact },
			menuLinks: { selector: '.navbar', control: menuLinks },
			menuLinksCompact: { selector: '.navbar', control: menuLinksCompact },
			searchAnything: { selector: '.navbar', control: searchAnything },
			findAnything: { selector: '.menu-links', control: findAnything },
			dashboardBtn: { selector: '.menu-links', control: dashboardBtn },
			addRecordBtn: { selector: '.menu-links', control: addRecordBtn },
			exportBtn: { selector: '.menu-links', control: exportBtn },
			printBtn: { selector: '.menu-links', control: printBtn },
			sortBtn: { selector: '.menu-links', control: sortBtn },
			reminderBtn: { selector: '.menu-links', control: reminderBtn },
			selectDropdown: { selector: '.menu-links', control: selectDropdown },
		},
	};

	function initModule(controls?: (string | JQuery<HTMLElement>)[], compactTotal?: boolean) {
		let header = $(stateMap.id);
		header.children().remove();

		if (!controls) {
			return;
		}

		header.append($(searchSection));
		header.append($(navBar));

		controls = controls || [];
		if (compactTotal === true) {
			controls.unshift('menuLinksCompact');
			controls.unshift('totalRecordsCompact');
		} else {
			controls.unshift('menuLinks');
			controls.unshift('totalRecords');
		}
		controls.push('searchAnything');
		controls.push('findAnything');

		controls.forEach((control) => {
			if (typeof control === 'string') {
				let controlDetails = stateMap.controlsMap[control];
				if (controlDetails) {
					header.find(controlDetails.selector).append($(controlDetails.control));
				}
			} else {
				header.find('.menu-links').append(control);
			}
		});

		header.append($(sortSection));
	}

	return {
		initModule: initModule,
	};
})(jQuery);
