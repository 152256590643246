import App from "AppInterface/App";
import DialogForResult from "components/shell/DialogForResult";
import { logEvent, setUserProperty } from "components/telemetry";
import { DefaultUserSettings, IsOwnerSAPD, SiteOwner } from "config";
import firebase from "fb/index";
import { fireDB } from "fb/initconfig";
import "firebase/auth";
import "firebase/firestore";
import { timeout } from "helpers";
import { Context } from "helpers/context";
import { appInterface, store } from "store";
import { updateUser, updateUserData } from "store/data/user/actions";
import { EventType, onEvent, toggleOnDemandPage } from "store/temp/actions";
import { updateUserSettings } from "store/ux/actions";
import { ApplicationState, UserData } from "types";
import { v1 as uuidv1 } from "uuid";

export const getUserRoles = async () => {
    let idTokenResult = await firebase.auth().currentUser?.getIdTokenResult();

    let roles: string[] = [];
    if (idTokenResult) {
        if (!!idTokenResult.claims.admin) {
            roles.push("admin");
            setUserProperty("admin", true);
        } else {
            setUserProperty("admin", false);
        }
        if (!!idTokenResult.claims.editor) {
            roles.push("editor");
            setUserProperty("editor", true);
        } else {
            setUserProperty("editor", false);
        }
        let rolesStr = Object.keys(idTokenResult.claims).map((key) => key + "_" + idTokenResult?.claims[key]);
        roles = [...roles, ...rolesStr];
    }

    localStorage.setItem("r", JSON.stringify(roles));
    return roles;
};

const verifyUserEmail = async (user) => {
    try {
        await user.sendEmailVerification();
        alert("Please confirm your email");
    } catch (error) {
        throw error;
    }
};

const updateUserState = async (user, updateData: boolean, createData?: boolean) => {
    if (!user) {
        store.dispatch(updateUser(undefined));
        store.dispatch(updateUserData(undefined));
    } else {
        store.dispatch(updateUser(user));
        if (updateData) {
            let db = fireDB;
            let userDataRecord = await db.collection("users").doc(user.uid).get();
            let userData = userDataRecord?.data() as UserData;
            if (!userData && createData) {
                userData = {
                    email: user.email,
                    verified: user.emailVerified,
                    fullName: user.displayName,
                    photoUrl: user.photoURL,
                    phone: user.phoneNumber,
                    settings: DefaultUserSettings,
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                } as unknown as UserData;
                await db.collection("users").doc(user.uid).set(userData);
            } else if (userData) {
                if ((user.displayName && !userData.fullName) || (user.photoURL && !userData.photoUrl) || (user.phoneNumber && !userData.phone)) {
                    userData = {
                        fullName: userData.fullName || user.displayName,
                        photoUrl: userData.photoUrl || user.photoURL,
                        phone: userData.phone || user.phoneNumber,
                        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                    } as UserData;

                    await db.collection("users").doc(user.uid).set(userData, { merge: true });
                }
            }

            if (userData) {
                userData.roles = await getUserRoles();
                store.dispatch(updateUserData(userData));
                store.dispatch(updateUserSettings(userData.settings, true));
            }
        }
    }
};

let unsubscribeFromUserData;

firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
        if (!user.emailVerified) {
            verifyUserEmail(user);
            return;
        }
        updateUserState(user, true);

        await timeout(5000);

        unsubscribeFromUserData = fireDB
            .collection("users")
            .doc(user.uid)
            .onSnapshot(async (snapshot) => {
                let userData = snapshot.data();
                if (userData) {
                    userData.roles = await getUserRoles();
                    store.dispatch(updateUserData(userData as UserData));
                    store.dispatch(updateUserSettings(userData.settings, true));
                }
            });
    } else {
        updateUserState(undefined, false);

        if (unsubscribeFromUserData) {
            unsubscribeFromUserData();
            unsubscribeFromUserData = null;
        }
    }
});

export interface LoginData {
    email: string;
    password: string;
}

export const login = (data: LoginData) => {
    return async (dispatch, getState) => {
        try {
            let result = await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
            var user = result.user;

            if (user) {
                if (!user.emailVerified) {
                    alert("Your email is not verified, please verify");
                    return;
                }

                logEvent("login", "Password");
                updateUserState(user, true);
            }
        } catch (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            console.error("auth: login: " + errorCode + ": " + errorMessage, error.stack);
            throw error;
        }
    };
};

const postUserSignUp = async (user, dispatch) => {
    await updateUserState(user, true, true);
};

const deleteUser = async (user, dispatch, getState) => {
    try {
        let state: ApplicationState = getState();
        let playlists = state.dataState.fbUserPlaylists;
        Object.keys(playlists).forEach(async (playlistId) => {
            await firebase.firestore().collection("users").doc(user.uid).collection("playlists").doc(playlistId).delete();
            await timeout(100);

            logEvent("user_playlist_delete", { playlistId });
        });
        await fireDB.collection("users").doc(user.uid).delete();
        await user.delete();

        logEvent("user_delete", { userId: user.uid });

        updateUserState(undefined, false);
    } catch (error) {
        throw error;
    }
};

export const deleteByPasswordLogin = (data: { currentPass: string }) => {
    return async (dispatch, getState) => {
        try {
            let currentUser = firebase.auth().currentUser;
            if (currentUser && currentUser.email) {
                let credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, data.currentPass);
                await currentUser.reauthenticateWithCredential(credential);

                await deleteUser(currentUser, dispatch, getState);

                dispatch(
                    onEvent(new Context(), EventType.Information, "deleteAccount", {
                        success: true,
                        message: "Account deleted!",
                    })
                );
            } else {
                throw new Error("User not signedin with Email");
            }
        } catch (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;

            if (errorCode === "auth/wrong-password") {
                dispatch(
                    onEvent(new Context(), EventType.Information, "deleteAccount", {
                        success: false,
                        message: "Wrong Password!",
                    })
                );
            } else if (errorCode === "auth/too-many-requests") {
                dispatch(
                    onEvent(new Context(), EventType.Information, "deleteAccount", {
                        success: false,
                        message: "Too many requests! Please try after some time!",
                    })
                );
            } else {
                dispatch(
                    onEvent(new Context(), EventType.Information, "deleteAccount", {
                        success: false,
                        message: errorMessage,
                    })
                );
            }
        }
    };
};

export const deleteByGoogleSign = () => {
    return async (dispatch, getState) => {
        try {
            let currentUser = firebase.auth().currentUser;
            if (currentUser) {
                await appInterface.initiateGoogleSignIn();

                await deleteUser(currentUser, dispatch, getState);

                dispatch(
                    onEvent(new Context(), EventType.Information, "deleteAccount", {
                        success: true,
                        message: "Account deleted!",
                    })
                );
            }
        } catch (error) {
            dispatch(
                onEvent(new Context(), EventType.Information, "deleteAccount", {
                    success: false,
                    message: error.message,
                })
            );
        }
    };
};

export const googleSign = () => {
    return async (dispatch, getState) => {
        let user: firebase.User | null = null;
        try {
            let result = await appInterface.initiateGoogleSignIn();
            user = result.user;
            logEvent("login", "Google");
        } catch (error) {
            var errorCode = error.code;

            var email = error.email;
            var credential = error.credential;

            if (errorCode === "auth/account-exists-with-different-credential") {
                let methods = await firebase.auth().fetchSignInMethodsForEmail(email);

                if (methods[0] === "password") {
                    dispatch(
                        toggleOnDemandPage(
                            <DialogForResult
                                label='Enter Password'
                                inputType='password'
                                action={async (password) => {
                                    let signinResult = await firebase.auth().signInWithEmailAndPassword(email, password);
                                    let linkResult = await signinResult?.user?.linkWithCredential(credential);
                                    await postUserSignUp(linkResult?.user, dispatch);
                                }}
                            />
                        )
                    );
                }
            } else {
                throw error;
            }
        }

        await postUserSignUp(user, dispatch);
        // await timeout(5000);
        // window.location.href = '/';
    };
};

export interface PasswordResetData {
    email: string;
}

export const sendResetPasswordEmail = (data: PasswordResetData) => {
    return async (dispatch, getState) => {
        try {
            await fireDB.collection("messages").add({
                action: "resetpass",
                email: data.email,
                actionCodeUrl: window.location.origin + "/account",
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });

            logEvent("user_resetpass", { email: data.email });

            // let actionCodeSettings = {
            // 	// URL you want to redirect back to. The domain (www.example.com) for this
            // 	// URL must be whitelisted in the Firebase Console.
            // 	url: window.location.origin + '/account',
            // 	// This must be true.
            // 	handleCodeInApp: true,
            // 	// iOS: {
            // 	// 	bundleId: 'com.example.ios',
            // 	// },
            // 	// android: {
            // 	// 	packageName: 'com.example.android',
            // 	// 	installApp: true,
            // 	// 	minimumVersion: '12',
            // 	// },
            // 	// dynamicLinkDomain: 'example.page.link',
            // };

            // await firebase.auth().sendPasswordResetEmail(data.email, actionCodeSettings);
        } catch (error) {
            throw error;
        }
    };
};

export interface ChangePasswordData {
    current: string;
    password: string;
}

export const changePassword = (data: ChangePasswordData) => {
    return async (dispatch, getState) => {
        try {
            let currentUser = firebase.auth().currentUser;
            if (currentUser && currentUser.email) {
                let credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, data.current);
                await currentUser.reauthenticateWithCredential(credential);
                await currentUser.updatePassword(data.password);
            } else {
                throw new Error("User not signedin with Email");
            }
        } catch (error) {
            throw error;
        }
    };
};

export interface SignupData {
    email: string;
    password: string;
}

export const verifyEmailLinkSignup = (data: SignupData) => {
    return async (dispatch, getState) => {
        try {
            let auth = firebase.auth();

            if (auth.isSignInWithEmailLink(window.location.href)) {
                await auth.signInWithEmailLink(data.email, window.location.href);
                await auth.currentUser?.updatePassword(data.password);

                logEvent("user_emailsignupcomplete", { email: data.email });
                logEvent("sign_up", "Password");

                let user = auth.currentUser;

                await postUserSignUp(user, dispatch);

                dispatch(
                    onEvent(new Context(), EventType.Information, "signup", {
                        success: true,
                        message: "SignUp Successful!",
                    })
                );
            }
        } catch (error) {
            dispatch(
                onEvent(new Context(), EventType.Information, "signup", {
                    success: false,
                    message: error.message,
                })
            );
        }
    };
};

export interface SignupWithEmailLinkData {
    email: string;
}

export const signupWithEmailLink = (data: SignupWithEmailLinkData) => {
    return async (dispatch, getState) => {
        try {
            await fireDB.collection("messages").add({
                action: "signup",
                email: data.email,
                actionCodeUrl: window.location.origin + "/finishEmailLinkSignUp",
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
            logEvent("user_emailsignup", { email: data.email });
        } catch (error) {
            throw error;
        }
    };
};

export interface SignOutData {}

export const signout = (data: SignOutData, context: Context) => {
    return async (dispatch) => {
        try {
            let currentUser = firebase.auth().currentUser;
            if (currentUser && currentUser.providerData.map((provider) => provider?.providerId).indexOf("google.com") >= 0) {
                App.gSignout();
            }

            await firebase.auth().signOut();
            updateUserState(undefined, false);

            // await timeout(1000);
            // window.location.href = '/';
        } catch (error) {
            dispatch(onEvent(context, EventType.Exception, "signout", error));
        }
    };
};

export interface ProfileData {
    fullName?: string;
    gender?: string;
    phone?: string;
    dob?: string;
    rahbar?: string;
    settings?: { [key: string]: any };
}

export const updateProfile = (data: ProfileData, context: Context) => {
    return async (dispatch, getState) => {
        try {
            dispatch(onEvent(context, EventType.OperationStart, "updateProfile", data));

            let userStore = getState().dataState.userStore;
            let user = userStore.user;
            if (!user) {
                dispatch(onEvent(context, EventType.AssertionFailure, "updateProfile", "User Not Signed In"));
                dispatch(onEvent(context, EventType.OperationEnd, "updateProfile", { success: false }));
                return;
            }

            let db = fireDB;
            var userRef = db.collection("users").doc(user.uid);
            let userRecord = {};
            Object.keys(data).forEach((key) => {
                if (data[key]) {
                    userRecord[key] = data[key];
                }
            });

            userRecord["updatedAt"] = firebase.firestore.FieldValue.serverTimestamp();

            await userRef.set(userRecord, { merge: true });

            logEvent("user_profile_update", {
                ...userRecord,
                settings: JSON.stringify(data.settings),
                updatedAt: new Date().toUTCString(),
            });

            if (data.settings) {
                let group: string[] = [];
                if (data.settings.push) {
                    group.push("push");
                }
                if (data.settings.quote) {
                    group.push("quote");
                }

                if (data.settings.email) {
                    group.push("email");
                    if (data.settings.email.pubs) {
                        let pubIds = Object.keys(data.settings.email.pubs).sort();
                        pubIds.forEach((id) => data.settings?.email.pubs[id] && group.push(id));
                    }
                }
                logEvent("join_group", group.join(","));
            }

            let userData = (await db.collection("users").doc(user.uid).get()).data();

            dispatch(updateUserData({ ...userStore.userData, ...userData }));

            // dispatch(
            // 	onEvent(context, EventType.OperationEnd, 'updateProfile', {
            // 		success: true,
            // 		message: 'Profile updated',
            // 	})
            // );
        } catch (error) {
            dispatch(onEvent(context, EventType.Exception, "submitFeedback", error));
        }
    };
};

export interface FeedbackData {
    name: string;
    category: string;
    comments: string;
}

export const submitFeedback = (data: FeedbackData, context: Context) => {
    return async (dispatch, getState) => {
        try {
            dispatch(onEvent(context, EventType.OperationStart, "submitFeedback", data));

            let state = getState() as ApplicationState;
            let userStore = state.dataState.userStore;

            let user = userStore.user;
            if (!user) {
                dispatch(onEvent(context, EventType.AssertionFailure, "submitFeedback", "User Not Signed In"));
                dispatch(onEvent(context, EventType.OperationEnd, "submitFeedback", { success: false }));
                return;
            }

            if (!userStore.userData?.fullName) {
                await updateProfile({ fullName: data.name }, context);
            }

            let record = {
                ...data,
                ...state.uxState.appParams,
                email: user.email,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            };

            if (!IsOwnerSAPD) {
                record["owner"] = SiteOwner;
            }

            await firebase
                .firestore()
                .collection("feedback")
                .doc(user.uid)
                .set(
                    {
                        ["f_" + uuidv1()]: record,
                        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                    },
                    { merge: true }
                );

            dispatch(
                onEvent(context, EventType.OperationEnd, "submitFeedback", {
                    success: true,
                    message: "Feedback submitted",
                })
            );
        } catch (error) {
            dispatch(onEvent(context, EventType.Exception, "submitFeedback", error));
        }
    };
};
