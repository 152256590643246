import { RouteGroup, RouteProps } from "types";
import { Icons } from "./icons";
import { OrgName } from "./index";

const homeRoute: RouteProps = {
    to: "/",
    page: "Home",
    primary: "Home",
    title: OrgName,
    icon: Icons.Home,
};

const anumatiRoute: RouteProps = {
    to: "/anumati",
    page: "Anumati",
    primary: "SSN Pass",
    title: "SSN Pass",
    icon: Icons.Feedback,
    roles: {
        view: "anumati_view",
        edit: "anumati_edit",
    },
};

const mjbjRoute: RouteProps = {
    to: "/mjbj",
    page: "MjBj",
    primary: "MjBj Records",
    title: "MjBj Records",
    icon: Icons.Satsang,
    roles: {
        view: "mjbj_view",
        lview: "mjbj_lview",
        edit: "mjbj_edit",
    },
};

const kutiyaRoute: RouteProps = {
    to: "/kutiya",
    page: "Kutiya",
    primary: "Satsang Bhawan",
    title: "Satsang Bhawan",
    icon: Icons.Business,
    roles: {
        view: "kutiya_view",
        edit: "kutiya_edit",
    },
};

const trusteeRoute: RouteProps = {
    to: "/trustee",
    page: "Trustee",
    primary: "Trustee Records",
    title: "Trustee Records",
    icon: Icons.Person,
    roles: {
        view: "trustee_view",
        edit: "trustee_edit",
    },
};

const momentRoute: RouteProps = {
    to: "/moment",
    page: "Moment",
    primary: "Holy Moments",
    title: "Holy Moments",
    icon: Icons.DateRange,
    roles: {
        view: "moment_view",
        edit: "moment_edit",
    },
};

const bookRoute: RouteProps = {
    to: "/book",
    page: "Book",
    primary: "Books Records",
    title: "Books Records",
    icon: Icons.Publication,
    roles: {
        view: "book_view",
        edit: "book_edit",
    },
};

const sangatRoute: RouteProps = {
    to: "/sangat",
    page: "Sangat",
    primary: "Sangat Records",
    title: "Sangat Records",
    icon: Icons.Account,
    roles: {
        view: "sangat_view",
        edit: "sangat_edit",
    },
};

const dhamRoute: RouteProps = {
    to: "/dham",
    page: "Dham",
    primary: "Dham Records",
    title: "Dham Records",
    icon: Icons.Business,
    roles: {
        view: "dham_view",
        edit: "dham_edit",
    },
};

const idCardRoute: RouteProps = {
    to: "/idcard",
    page: "IDCard",
    primary: "ID Cards",
    title: "ID Cards",
    icon: <Icons.AccountBoxIcon />,
    roles: {
        view: "idcard_view",
        edit: "idcard_edit",
    },
};

const categoryRoute: RouteProps = {
    to: "/category",
    page: "Category",
    primary: "Categories",
    title: "Categories",
    icon: Icons.ListAlt,
    roles: {
        view: "category_view",
        edit: "category_edit",
    },
};

const pincodeRoute: RouteProps = {
    to: "/pincode",
    page: "Pincode",
    primary: "Pincodes",
    title: "Pincodes",
    icon: Icons.Download,
    roles: {
        view: "pincode_view",
        edit: "pincode_edit",
    },
};

const rateRoute: RouteProps = {
    to: "/rate",
    page: "Rate",
    primary: "Rates",
    title: "Rates",
    icon: Icons.Help,
    roles: {
        view: "rate_view",
        edit: "rate_edit",
    },
};

const throughRoute: RouteProps = {
    to: "/through",
    page: "Through",
    primary: "Through",
    title: "Through",
    icon: Icons.Person,
    roles: {
        view: "through_view",
        edit: "through_edit",
    },
};

const subscriberRoute: RouteProps = {
    to: "/subscriber",
    page: "Subscriber",
    primary: "Subscribers",
    title: "Subscribers",
    icon: Icons.Repeat,
    roles: {
        view: "subscriber_view",
        edit: "subscriber_edit",
    },
};

const signinRoute: RouteProps = {
    to: "/signin",
    page: "SignIn",
    primary: "Sign In",
    title: "Sign In",
    state: { isModal: true },
};

// const userOptionsRoute: RouteProps = {
// 	to: '/account',
// 	page: 'UserOptions',
// 	primary: 'Account',
// 	title: 'Account',
// 	state: { isModal: true },
// 	icon: Icons.Account,
// };

const userOptionsSubRoute: RouteProps = {
    to: "/account/:key?",
    page: "UserOptions",
    primary: "Account",
    title: "Account",
    state: { isModal: true },
    icon: Icons.Account,
};

export const finishEmailLinkSignUp: RouteProps = {
    to: "/finishEmailLinkSignUp",
    page: "EmailLinkSignup",
    primary: "SignUp",
    title: "SignUp",
    state: { isModal: true },
};

export const homeGroup: RouteGroup = {
    routes: [homeRoute],
};

export const recordsGroup: RouteGroup = {
    routes: [mjbjRoute, anumatiRoute, kutiyaRoute, trusteeRoute, momentRoute, bookRoute, sangatRoute, dhamRoute, idCardRoute],
};

export const journalGroup: RouteGroup = {
    routes: [categoryRoute, pincodeRoute, rateRoute, throughRoute, subscriberRoute],
};

// AppRouteGroups is for surfacing a route in AppDrawer
export const AppRouteGroups: RouteGroup[] = [homeGroup, recordsGroup, journalGroup];

export const adminGroup: RouteGroup = {
    routes: [],
};
// AppRouteGroups is for surfacing a route in AppDrawer
export const AppAdminRouteGroups: RouteGroup[] = [adminGroup, ...AppRouteGroups];

export const WebAppCommonRoutes: RouteProps[] = [
    signinRoute,
    userOptionsSubRoute,
    finishEmailLinkSignUp,
    anumatiRoute,
    mjbjRoute,
    kutiyaRoute,
    trusteeRoute,
    momentRoute,
    bookRoute,
    sangatRoute,
    dhamRoute,
    idCardRoute,
    categoryRoute,
    pincodeRoute,
    rateRoute,
    throughRoute,
    subscriberRoute,
    homeRoute,
];

// AppRoutes is for react-router to show associated content of a route
export const AppRoutes: RouteProps[] = [...WebAppCommonRoutes];

export const AdminRoutes: RouteProps[] = [];
