import { populateViewTileHelper } from 'helpers/RecordsHelper';
import React from 'react';
import { connect } from 'react-redux';
import { AnumatiRecord, ApplicationState } from 'types';

export const anumatiViewTileCSS = `
<style>
	.checkins-btn {
		display: none;
	}

	.intl-tel-input {
		width: 100%;
	}

	.heading {
		text-align: center;
		font-size: 1.2em;
		font-weight: bold;
		margin-bottom: 1.5em;
		border-bottom: 1px solid grey;
		padding-bottom: 0.3em;
		text-transform: uppercase;
	}

	.tile .comments {
		border: 1px solid darkgray;
		padding: 10px;
		overflow: auto;
		max-height: 6em;
	}

	.tile .comments-title {
		border: 1px solid darkgray;
		border-bottom: 0;
		text-align: center;
	}

	.tt-suggestion:hover {
		color: white;
		background-color: #0097cf;
		cursor: pointer;
	}

	.through-sugestion {
		padding: 0.7em 2em;
		white-space: nowrap;
	}

	.tt-menu {
		width: auto;
		overflow-y: auto;
		max-height: 150px;
		box-shadow: 1px 1px 5px 1px grey;
		background-color: white;
		overflow-x: hidden;
	}

	input[name='subscriber_no_code'] {
		text-transform: uppercase;
	}

	.tile.anumati-record.detail {
		padding-top: 15px;
	}

	.tile.anumati-record .main-details {
		padding-right: 30px;
	}

	.tile.anumati-record .main-details .title {
		text-align: right;
		padding: 0;
		margin-bottom: 15px;
		font-weight: bold;
		font-size: 0.9em;
	}

	.tile.anumati-record .main-details .value {
		padding-right: 0;
		margin-bottom: 15px;
	}

	.tile.anumati-record input {
		width: 100%;
		background-color: white !important;
		border: none;
		border-bottom: 1px solid darkgray;
	}

	.tile.anumati-record input:disabled {
		background-color: #ccc !important;
	}

	.tile.anumati-record input[type='checkbox'] {
		width: auto;
		margin-right: 5px;
	}

	.radio,
	.checkbox {
		margin-top: 0;
		margin-bottom: 0;
	}

	.tile.anumati-record input:focus {
		outline: none;
	}

	.tile.anumati-record .value select {
		width: 100%;
	}

	.tile.anumati-record .language-value {
		text-transform: capitalize;
	}

	.edit-allowed .check-in-details {
		display: none;
	}

	.bootstrap-dialog-message .edit-allowed .check-in-details {
		display: block;
	}

	form.tile.anumati-record .address {
		width: 100%;
		resize: none;
		height: 6em;
	}

	.tile.anumati-record .address {
		border: 1px solid darkgray;
		padding: 10px;
		overflow: auto;
		max-height: 6em;
	}

	.tile.anumati-record .comments-title {
		border: 1px solid darkgray;
		border-bottom: 0;
		text-align: center;
	}

	div.tile.anumati-record .main-details .value {
		border-bottom: 1px solid lightgrey;
		min-height: 1.5em;
		padding-right: 0;
	}

	.lb {
		border-bottom: 1px solid lightgrey;
	}

	.multi-field-row {
		padding-top: 3px;
		padding-bottom: 3px;
		font-size: 14px;
	}

	.multi-field-row:nth-last-child(1) {
		border-bottom: none;
	}

	.btn-danger {
		float: left;
	}

	table {
		width: 100%;
		font-size: 0.9em;
		max-height: 12em;
	}

	table,
	th,
	td {
		border: 1px solid black;
		padding: 3px;
		text-align: center;
	}

	thead {
		text-align: center;
		font-weight: bold;
	}

	.reference-suggestion img {
		width: 100%;
		padding: 0 5px;
	}

	.tt-dropdown-menu {
		top: auto !important;
		bottom: 100% !important;
	}

	.tt-dropdown-menu-name {
		top: 100% !important;
		bottom: auto !important;
	}

	.record-sms-button, .record-ams-button {
		float: right;
	}

	.tile .deleted {
		display: none;
		color: red;
	}

	@media (max-width: 991px) {
		.check-in-details {
			display: none;
		}
	}
</style>
`;

const html = `
<div id="anumati-detail-record" class="tile anumati-record detail edit-allowed">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="col-xs-12 main-details">
			<div class="block">
				<div class="title col-xs-3">
					Card No:
				</div>
				<div class="value card-detail col-xs-9">
				</div>
				<div class="title col-xs-3">
					Name:
				</div>
				<div class="value self-detail col-xs-9">
				</div>
				<div class="title col-xs-3">
					Count:
				</div>
				<div class="value count-detail col-xs-9">
				</div>
				<div class="title col-xs-3">
					Date:
				</div>
				<div class="value date-detail col-xs-9">
				</div>
				<div class="title col-xs-3">
					Ref:
				</div>
				<div class="value ref-detail col-xs-9">
				</div>
				<div class="title col-xs-3">
					Mobile:
				</div>
				<div class="value phone-detail col-xs-9">
				</div>
				<div class="title col-xs-3">
					Created By:
				</div>
				<div class="value created-by-detail col-xs-9">
				</div>
				<div class="deleted">
					<div class="title col-xs-3">
						Deleted:
					</div>
					<div class="value deleted-detail col-xs-9">
					</div>
				</div>
				<div class="publish">
					<div class="title col-xs-3">
						Publish Time:
					</div>
					<div class="value publish-time-detail col-xs-9">
					</div>
				</div>
				<div class="expiry">
					<div class="title col-xs-3">
						Expire Time:
					</div>
					<div class="value expire-time-detail col-xs-9">
					</div>
				</div>
			</div>
			<button class="btn-primary btn record-print-button">Print</button>
			<button class="btn-primary btn record-ams-button">Send AMS</button>
		</div>
	</div>
	<div class="row check-in-details">
		<div class='col-xs-12'>
			<div class='heading'>Check-In Details</div>
		</div>
		<div class="check-in-info">
			<div class="col-xs-12 main-details">
				<div class="block">
					<div class="row check-in-detail">
						<div id="check-in-data-detail" class="multi-field-row hidden col-xs-12">
							<div class="row">
								<div class="title inout col-xs-1">
								</div>
								<div class="date col-xs-3">
									<input type="text" class="value" disabled value="" placeholder="Date"/>												
								</div>
								<div class="title col-xs-1">
									Name:
								</div>
								<div class="value name col-xs-2">
								</div>
								<div class="title col-xs-1">
									Count:
								</div>
								<div class="value count col-xs-1">
								</div>
								<div class="title col-xs-1">
									Mobile:
								</div>
								<div class="value phone col-xs-2">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<button class="btn-primary btn checkins-btn">Check-In/Out</button>
</div>
`;

let briefHtml = `
<div id="anumati-brief-record" class="row tile anumati-record brief">
	<div class="record-id">
		<span></span>
	</div>
	<div class="col-xs-12 main-details">
		<div class="block">
			<div class="title col-xs-2">
				Card No:
			</div>
			<div class="value card-detail col-xs-10">
			</div>
			<div class="title col-xs-2">
				Name:
			</div>
			<div class="value self-detail col-xs-10">
			</div>
		</div>
	</div>
</div>
`;

interface Props {
	record?: AnumatiRecord;
	index?: number;
	listRef?: React.RefObject<any>;
	showDetailed?: boolean;
}

class Component extends React.PureComponent<Props> {
	private tileRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	componentDidUpdate(prevProps, prevState) {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	render() {
		return <div ref={this.tileRef} dangerouslySetInnerHTML={{ __html: !this.props.showDetailed ? briefHtml : html }}></div>;
	}
}

function mapStateToProps(state: ApplicationState) {
	return {};
}

export const AnumatiViewTile = connect(mapStateToProps)(Component);
