import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { toggleBottomDrawer } from 'store/temp/actions';
import { ApplicationState } from 'types';

const useStyles = makeStyles({
	root: {
		'& .MuiDrawer-paperAnchorBottom': {
			width: isMobile() ? 'auto' : 500,
			left: isMobile() ? 0 : 'calc(50% - 250px)',
		},
	},
	fullList: {
		width: 'auto',
	},
});

interface BottomDrawerProps {
	content?: any;
	isDrawerOpen: boolean;
	toggleDrawer: () => void;
}

function BottomDrawer(props: BottomDrawerProps) {
	const classes = useStyles();

	const toggleDrawer = () => (e: React.MouseEvent) => {
		props.toggleDrawer();
	};

	const content = () => <div className={classes.fullList}>{props.content}</div>;

	return (
		<div>
			<Drawer className={classes.root} anchor='bottom' open={props.isDrawerOpen} onClose={toggleDrawer()}>
				{content()}
			</Drawer>
		</div>
	);
}

function mapStateToProps({ tempState }: ApplicationState, props: { content?; open? }) {
	let { bottomDrawer } = tempState;

	return {
		isDrawerOpen: props.open || bottomDrawer.open,
		content: props.content || bottomDrawer.content,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: () => {
			dispatch(toggleBottomDrawer());
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomDrawer);
