import { addOfflineAudio, removeOfflineAudio } from 'store/offline/actions';
import { ApplicationState, AudioCurrentState, DownloadStatus } from 'types';
import { combineEpics, ofType } from 'redux-observable';
import { concat, EMPTY, of } from 'rxjs';
import {
	concatMap,
	debounceTime,
	delay,
	delayWhen,
	switchMap,
	throttleTime
} from 'rxjs/operators';
import { Context } from 'helpers/context';
import { DBConfig } from 'config';
import { getBuildVersion, isWebViewEnv, timeout, updatePushSetting } from 'helpers';
import { push } from 'connected-react-router';
import { queueOps } from './OpsEpic';
import { updateFBUserPlaylists } from 'store/data/user/actions';
import {
	DidPlayToEnd,
	DidUpdateDuration,
	DidUpdateElapsedTime,
	DidUpdateStatus,
	DidDownloadMedia,
	ChangePage,
} from 'AppInterface/AppInterface';
import {
	updateAudioPlaybackDuration,
	updateAudioPlaybackState,
	updateAudioPlaybackTime,
	WEB_APP_INIT,
	onEvent,
	EventType,
} from 'store/temp/actions';
import { UPDATE_CONFIG } from 'store/data/configs/actions';
import App from 'AppInterface/App';
import { updateFilters, updateSortOrder } from 'store/ux/actions';

const appBuildVersionEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_CONFIG),
		debounceTime(60000),
		concatMap(() => {
			if (!isWebViewEnv()) {
				return EMPTY;
			}

			let configs = (state$.value as ApplicationState).dataState.configs;
			let latestBuildVersion = parseInt(configs[DBConfig.LatestBuildVersion]?.value ?? '0');
			let currentBuildVersion = getBuildVersion();

			if (latestBuildVersion >= (currentBuildVersion + 10)) {
				window.location.href = '/';
				return EMPTY;
			}

			if (latestBuildVersion > currentBuildVersion) {
				return of(
					onEvent(new Context(), EventType.Action, 'newBuild', {
						action: {
							label: 'Reload',
							callback: () => {
								let reloadedStr = localStorage.getItem('reloadCount');
								if (reloadedStr) {
									let reloadObj = JSON.parse(reloadedStr);
									if (reloadObj.latestVersion === latestBuildVersion && reloadObj.curVersion === currentBuildVersion) {
										if (reloadObj.count <= 2) {
											reloadObj.count++;
											localStorage.setItem('reloadCount', JSON.stringify(reloadObj));
										} else {
											App.showToast('Reloading App...');
											App.clearWebViewCache();
										}
									} else {
										localStorage.setItem('reloadCount', JSON.stringify({ latestVersion: latestBuildVersion, curVersion: currentBuildVersion, count: 1 }));
									}
								} else {
									localStorage.setItem('reloadCount', JSON.stringify({ latestVersion: latestBuildVersion, curVersion: currentBuildVersion, count: 1 }));
								}
								window.location.href = '/';
							}
						},
						message: `New updates are available`,
						persist: true //parseInt(latestBuildVersion) > currentBuildVersion + 2
					})
				)
			}

			return EMPTY;
		})
	);

const changePageEpic = (action$, state$) =>
	action$.pipe(
		ofType(ChangePage),
		concatMap((action: { type: string; payload: string }) => {
			let [page, state] = action.payload.split('$');
			if (page.startsWith("http")) {
				if (page.startsWith(window.location.origin)) {
					page = page.replace(window.location.origin, "");
					page = page.replace("/?r=", "");
				} else {
					window.location.href = page;
					return EMPTY;
				}
			}
			if (state) {
				return of(push(page, JSON.parse(state)));
			} else {
				return of(push(page));
			}
		})
	);

const appMediaCurrentTimeEpic = (action$, state$) =>
	action$.pipe(
		ofType(DidUpdateElapsedTime),
		throttleTime(500),
		switchMap((action: { type: string; payload: number }) => {
			let audioDetails = (state$.value as ApplicationState).tempState.audioPlaybackDetails;
			if (!audioDetails) {
				return EMPTY;
			}
			// if (audioDetails.currentState === AudioCurrentState.Seeking) {
			// 	return concat(
			// 		of(updateAudioPlaybackTime(action.payload)),
			// 		of(updateAudioPlaybackState(AudioCurrentState.Playing))
			// 	);
			// }
			let currentTime = (state$.value as ApplicationState).tempState.audioPlaybackState.currentTime;
			if (Math.floor(currentTime) !== Math.floor(action.payload)) {
				return of(updateAudioPlaybackTime(Math.floor(action.payload)));
			} else {
				return EMPTY;
			}
		})
	);

const appMediaDurationEpic = (action$, state$) =>
	action$.pipe(
		ofType(DidUpdateDuration),
		switchMap((action: { type: string; payload: number }) => {
			let audioDetails = (state$.value as ApplicationState).tempState.audioPlaybackDetails;
			if (!audioDetails) {
				return EMPTY;
			}
			return of(updateAudioPlaybackDuration(action.payload));
		})
	);

const appMediaEndEpic = (action$, state$) =>
	action$.pipe(
		ofType(DidPlayToEnd),
		switchMap((action: { type: string; payload: string }) => {
			let audioDetails = (state$.value as ApplicationState).tempState.audioPlaybackDetails;
			if (!audioDetails) {
				return EMPTY;
			}
			return of(updateAudioPlaybackState(AudioCurrentState.Ended));
		})
	);

const appMediaStatusEpic = (action$, state$) =>
	action$.pipe(
		ofType(DidUpdateStatus),
		concatMap((action: { type: string; payload: string }) => {
			let audioDetails = (state$.value as ApplicationState).tempState.audioPlaybackDetails;
			if (!audioDetails) {
				return EMPTY;
			}

			let curState;
			let status: string = action.payload;
			if (status.indexOf('paused') === 0) {
				status = 'paused';
			}
			if (status.indexOf('error') === 0) {
				status = 'error';
			}
			switch (status) {
				case 'playing':
					curState = AudioCurrentState.Playing;
					break;
				case 'paused':
					curState = AudioCurrentState.Paused;
					break;
				case 'idle':
					return EMPTY;
				case 'buffering':
					return EMPTY;
				case 'error':
					curState = AudioCurrentState.Error;
					break;
				default:
					return EMPTY;
			}
			return of(updateAudioPlaybackState(curState));
		})
	);

const appMediaEpic = combineEpics(appMediaStatusEpic, appMediaCurrentTimeEpic, appMediaDurationEpic, appMediaEndEpic);

const webAppInitEpic = (action$, state$) =>
	action$.pipe(
		ofType(WEB_APP_INIT),
		switchMap(() => {
			let state = state$.value as ApplicationState;
			let user = state.dataState.userStore.user;

			let offlineAudio = state.offlineState.audio;
			let articles = state.dataState.articles.byId;
			let settings = state.uxState.settings;

			updatePushSetting(state.uxState.settings.push);

			let result: any[] = [of(updateSortOrder('..clearall', '')), of(updateFilters('..clearall', ''))];

			if (settings?.download) {
				for (let id in offlineAudio) {
					if (!articles[id]) {
						continue;
					}

					let progress = offlineAudio[id]?.progress;
					if (progress && progress?.status !== DownloadStatus.Complete) {
						result.push(of(addOfflineAudio(articles[id], false)));
					} else if (
						offlineAudio[id]?.exportProgress &&
						offlineAudio[id]?.exportProgress?.status !== DownloadStatus.Complete
					) {
						result.push(of(addOfflineAudio(articles[id], true)));
					}
				}
			}

			if (user) {
				result.push(of(updateFBUserPlaylists(user.uid, [])));
			}

			if (isWebViewEnv() && false) {
				// We should download PKP and other imp files/images etc..
				let configs = state.dataState.configs;
				let autoDownloadIds: string[] = ((configs[DBConfig.AutoDownload]?.value ?? []) as unknown) as string[];
				let autoDownloadActions: any[] = [];

				autoDownloadIds.forEach((id) => {
					if (
						articles[id] && (!offlineAudio[id] ||
							offlineAudio[id].progress?.status !== DownloadStatus.Complete)
					) {
						if (autoDownloadActions.length === 0) {
							autoDownloadActions.push(
								of(
									onEvent(new Context(), EventType.Information, 'autoDownload', {
										success: true,
										message: "Downloading 'Pooja Ke Phool' for offline play...",
									})
								).pipe(delay(10000))
							);

							autoDownloadActions.push(of(addOfflineAudio(articles[id], false)));
						} else {
							autoDownloadActions.push(
								of(addOfflineAudio(articles[id], false)).pipe(
									delayWhen(() => action$.ofType(DidDownloadMedia))
								)
							);
						}
					}
				});

				if (autoDownloadActions.length) {
					result.push(of(queueOps('autoDownload', concat(...[...autoDownloadActions]))));
				}
			}

			return concat(...[...result]);
		})
	);

export const appEpic = combineEpics(appMediaEpic, changePageEpic, webAppInitEpic, appBuildVersionEpic);
