import App from 'AppInterface/App';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import {
	ApplicationState,
	DownloadState,
	DownloadStatus,
} from 'types';
import { AppName, SHARE_ORIGIN } from 'config';
import { Badge, CircularProgress } from '@material-ui/core';
import { BottomDrawerOptions } from './BottomDrawerOptions';
import { connect } from 'react-redux';
import { Icons } from 'config/icons';
import { isAdmin, isWebViewEnv } from 'helpers';
import { makeStyles } from '@material-ui/core/styles';
import { toggleBottomDrawer } from 'store/temp/actions';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		width: '100%',
		bottom: '0px',
		'box-shadow': '0 -1px 5px lightgrey',
	},
	button: {
		'text-transform': 'capitalize',
		'align-self': 'flex-end',
		minWidth: 'auto',
	},
	navHeight: {
		height: '55px; height: calc(55px + env(safe-area-inset-bottom)/2)',
		paddingBottom: '0; padding-bottom: calc(env(safe-area-inset-bottom)/2)',
		backgroundColor: 'transparent',
	},
	progress: {
		position: 'absolute',
		left: -4,
		top: -4,
	},
}));

function SimpleBottomNavigation(props) {
	const classes = useStyles();
	const history = useHistory();

	let value = props.location.pathname;
	if (props.location.search.startsWith('?r=')) {
		value = props.location.search.replace('?r=', '');
	}


	let downloadProgress: { [id: string]: DownloadState } = props.downloadProgress;

	let isDownloadingMedia = Object.keys(downloadProgress).reduce(
		(value, id) =>
			value ||
			downloadProgress[id].progress?.status === DownloadStatus.InProgress ||
			downloadProgress[id].progress?.status === DownloadStatus.Queued,
		false
	);

	let contextualMoreOptions = props.contextualMoreOptions ?? [];

	let optionsObj = {
		options: [
			...contextualMoreOptions,
			{
				title: 'Share',
				icon: Icons.Share,
				onClick: () => {
					try {
						App.shareLink(
							(props.title === AppName ? 'Welcome' : props.title) + ' - ' + AppName + ': ',
							SHARE_ORIGIN + window.location.href.replace(window.location.origin, ""),
							'Page',
							window.location.href
						);
					} catch (error) {
						(App as any).shareLink(
							(props.title === AppName ? 'Welcome' : props.title) + ' - ' + AppName + ': ',
							SHARE_ORIGIN
						);

					}
					props.toggleDrawer();
				},
			},
		],
		actionHandler: () => {
			props.toggleDrawer();
		},
	};

	let optionsList = BottomDrawerOptions(optionsObj);

	return (
		<React.Fragment>
			<Paper square className={classes.root}>
				<BottomNavigation
					value={value}
					className={classes.navHeight}
					onChange={(event, newValue) => {
						if (newValue === 'more') {
							props.toggleDrawer(optionsList);
						} else if (newValue === '/notices') {
							history.push(newValue, { isModal: true });
						} else {
							history.push(newValue);
						}
					}}
					showLabels>
					<BottomNavigationAction className={classes.button} label='Home' value='/' icon={Icons.Home} />
					{isWebViewEnv() || props.isAdmin ? (
						<BottomNavigationAction
							className={classes.button}
							label='Intro'
							value='/introduction'
							icon={Icons.About}
						/>
					) : (
							<BottomNavigationAction
								className={classes.button}
								label='Publications'
								value='/publications'
								icon={Icons.Publication}
							/>
						)}
					{isWebViewEnv() || props.isAdmin ? (
						isDownloadingMedia ? (
							<BottomNavigationAction
								className={classes.button}
								label='Playlists'
								value='/playlists'
								icon={
									<span style={{ position: 'relative' }}>
										{Icons.Download}
										<CircularProgress size={32} className={classes.progress} />
									</span>
								}
							/>
						) : (
								<BottomNavigationAction
									className={classes.button}
									label='Playlists'
									value='/playlists'
									icon={Icons.Playlist}
								/>
							)
					) : (
							<BottomNavigationAction
								className={classes.button}
								label='Calendar'
								value='/calendar'
								icon={Icons.Calendar}
							/>
						)}
					<BottomNavigationAction
						className={classes.button}
						label='Notices'
						value='/notices'
						icon={
							<Badge badgeContent={0} color='secondary'>
								{Icons.Notification}
							</Badge>
						}
					/>
					<BottomNavigationAction
						className={classes.button}
						label='More'
						value='more'
						icon={Icons.MoreVert}
					/>
				</BottomNavigation>
			</Paper>
			<div className={classes.navHeight} style={props.miniMode ? { height: 135 } : {}} />
		</React.Fragment>
	);
}

function mapStateToProps(state: ApplicationState) {
	return {
		isAdmin: isAdmin(state.dataState.userStore.userData),
		location: state.router.location,
		downloadProgress: state.offlineState.audio,
		title: state.tempState.appBarTitle,
		miniMode: false, //state.tempState.audioPlaybackDetails && state.tempState.audioPlaybackState.isMiniPlayerMode,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: (content?: any) => {
			dispatch(toggleBottomDrawer(content));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleBottomNavigation);
