import React from 'react';
import { connect } from 'react-redux';
import { AnumatiRecord, ApplicationState, MjBjRecord } from 'types';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp, GridToolbar } from '@mui/x-data-grid';
import { ssnStartingCardKey } from 'config';

interface Props {
	records: AnumatiRecord[];
	mjbjRecords: MjBjRecord[];
	startingCardNo: number;
	style: any;
	setRefFilter: (refs: string[]) => void;
}

interface State {}

interface RowType {
	id: string;
	name: string;
	cards: number;
	pendingCards: number;
	members: number;
	majorCheckins: number;
	minorCheckins: number;
	extraCheckins: number;
	inApp: string;
}

class Component extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount = async () => {};

	render() {
		let refMap = this.props.records.reduce((prev, anumatiRecord, i) => {
			let rec: any = anumatiRecord;
			if (!rec.reference) {
				return prev;
			}

			let ref = rec.reference;
			let next = prev[ref] ?? {
				id: ref ?? rec.card_no ?? 1,
				name: ref,
				cards: 0,
				pendingCards: 0,
				members: 0,
				majorCheckins: 0,
				minorCheckins: 0,
				inApp: '',
				extraCheckins: 0,
			};

			let cardNo = rec.card_no;
			if (!cardNo && rec.approval && rec.approval.status === 'Pending') {
				next.pendingCards++;
			} else if (cardNo && cardNo >= this.props.startingCardNo) {
				next.cards++;
				next.members += rec.people_count;
			} else {
				return prev;
			}

			let totalCheckins = 0;
			if (typeof rec['checkin'] !== 'undefined' && rec['checkin'] !== null && rec['checkin'].length > 0) {
				var checkins = rec['checkin'];
				for (var i = 0; i < checkins.length; i++) {
					let majorCount = (checkins[i]['checkin_count'] ?? 0) + (checkins[i]['checkin_count_ladies'] ?? 0);
					let minorCount = (checkins[i]['checkin_count_minor'] ?? 0) + (checkins[i]['checkin_count_infant'] ?? 0);

					next.majorCheckins += majorCount;
					next.minorCheckins += minorCount;

					totalCheckins += majorCount;
				}
			}

			next.extraCheckins = next.members - next.majorCheckins; //Math.min(0, rec.people_count - totalCheckins);

			if (rec['phone'] && rec['phone'] !== '') {
				let phone = rec['phone'].startsWith('+') ? rec['phone'] : '+91' + rec['phone'];
				var records: any[] = this.props.mjbjRecords.filter((record) => {
					return record['inApp'] && record['inApp'][phone];
				});
				if (records.length) {
					next.inApp = phone;
				}
			}

			prev[ref] = next;

			return prev;
		}, {} as { [name: string]: RowType });

		this.props.mjbjRecords.forEach((record) => {
			if (record['samadhi_date'] || record['samadhi_place']) {
				return;
			}

			var titleMapping = {
				Mahatma: 'Mh.',
				Bai: 'Bai',
				Bhagat: 'Bh.',
				Dada: 'Dada',
				Dadi: 'Dadi',
			};
			let refName = (titleMapping[record['title']] ?? record['title']) + ' ' + record['name'] + ' Ji';
			let oldName = record['old_name'];
			let sewa = record['sewa'];

			refMap[refName] = refMap[refName] ?? {
				id: refName,
				name: refName,
				cards: 0,
				pendingCards: 0,
				members: 0,
				majorCheckins: 0,
				minorCheckins: 0,
				inApp: '',
				extraCheckins: 0,
			};

			refMap[refName]['oldName'] = oldName;
			refMap[refName]['sewa'] = sewa;

			if (record['contacts']) {
				var contacts = record['contacts'] as any;
				var inApp = record['inApp'];
				let inAppStr = '';
				let seperator = '';
				for (var contactType in contacts) {
					for (var i = 0; i < contacts[contactType].length; i++) {
						let contact = contacts[contactType][i];
						if (inApp && inApp[contact]) {
							inAppStr += seperator + contact; // + ':' + inApp[contact]['userId'];
							seperator = ', ';
						}
					}
				}
				refMap[refName].inApp = inAppStr;
			}
		});

		const rows: GridRowsProp = Object.values(refMap).sort((a, b) => (a.name > b.name ? 1 : -1));

		const columns: GridColDef[] = [
			{
				field: 'name',
				headerName: 'Ref Name',
				width: 200,
				renderCell: (params: GridRenderCellParams) => (
					<span style={{ color: params.getValue(params.id, 'extraCheckins') < 0 ? 'red' : 'inherit' }}>{params.value}</span>
				),
			},
			{ field: 'oldName', headerName: 'Old Name', width: 200, hide: true },
			{ field: 'sewa', headerName: 'Sewa', width: 200, hide: true },
			{ field: 'cards', headerName: 'Cards', width: 100, type: 'number' },
			{ field: 'pendingCards', headerName: 'Pending', width: 120, type: 'number' },
			{ field: 'members', headerName: 'Members', width: 120, type: 'number' },
			{ field: 'majorCheckins', headerName: 'Major', width: 120, type: 'number' },
			{ field: 'minorCheckins', headerName: 'Minor', width: 120, type: 'number' },
			{ field: 'extraCheckins', headerName: 'Extra', width: 120, type: 'number' },
			{ field: 'inApp', headerName: 'In App', width: 300 },
		];

		return (
			<div style={{ display: 'flex', margin: 6, height: 300, width: '50%', ...this.props.style }}>
				<div style={{ flexGrow: 1 }}>
					<DataGrid
						rows={rows}
						columns={columns}
						components={{
							Toolbar: GridToolbar,
						}}
						disableColumnMenu
						density="compact"
						// autoPageSize
						checkboxSelection={true}
						disableSelectionOnClick
						isRowSelectable={(params) => params.row.cards > 0 || params.row.pendingCards > 0}
						onSelectionModelChange={(selectionModel, details) => {
							console.log(selectionModel, details);
							this.props.setRefFilter(selectionModel as string[]);
						}}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	let startingCardConfig: any = state.dataState.anumati.byId[ssnStartingCardKey];
	let startingCardNo = parseInt(startingCardConfig?.value ?? '0');

	return {
		records: state.dataState.anumati.filtered,
		mjbjRecords: state.dataState.mjbj.filtered,
		startingCardNo,
	};
}

export const AnumatiTable = connect(mapStateToProps)(Component);
