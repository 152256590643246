import { IntegratedRecordsList } from 'components/IntegratedRecordsList';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, IDCardRecord } from 'types';
import { RecordsHandler } from './RecordsHandler';
import { ViewTile } from './viewTile';

let CSS = `
#selection-dropdown {
    display: none !important;
}

.tile.id-card.detail {
    padding-top: 15px;
}

.tile.id-card .main-details {
    padding-right: 30px;
}

.tile.id-card .main-details .title {
	text-align: right;
	padding: 0;
	padding-bottom: 25px;
    font-weight: bold;
    font-size: 0.9em;
}

form.tile.id-card .main-details .value {
	padding-right: 0;
}

.tile.id-card input {
	width: 100%;
	background-color: white!important;
    border: none;
    border-bottom: 1px solid darkgray;
}

.tile.id-card input:focus {
    outline: none;    
}

.tile.id-card .value select {
	width: 100%;
}

.tile.id-card .self-image, .idcard-image {
	width: 100%;
    margin-bottom: 5px;
}

.tile.id-card .info-tile {
	border: 1px solid darkgray;
    margin-bottom: 5px;
}

.tile.id-card .info-tile .info-title {
	text-align: center;
}

.tile.id-card .info-tile select {
    width: 100%;
}

.tile.id-card .info-tile .value {
	text-align: center;
	width: 100%;
    border-bottom: none!important;
}

div.tile.id-card .main-details .value {
    border-bottom: 1px solid lightgrey;
    min-height: 1.5em;
    padding-right: 0;
}

.lb {
    border-bottom: 1px solid lightgrey;
}

.contact-type {
	font-weight: bold;
	text-align: right;
	padding-right: 0;
}

.contact-value {
	text-align: left;
}

.contact-detail, .idcard-data-detail {
	border: 1px solid lightgrey;
	margin-top: 10px;
}

.contact-detail .title, .idcard-data-detail .title {
	padding-bottom: 3px !important;
	padding-top: 3px !important;
	text-align: center !important;
	border-bottom: 1px lightgrey solid;
}

#idcard-detail, #contact-type-value {
	display: none;
}

.multi-field-row {
	border-bottom: 1px solid lightgrey;
	padding-top: 3px;
	padding-bottom: 3px;
}

.multi-field-row:nth-last-child(1) {
	border-bottom: none;
}

@media (min-width: 992px) {
	.col-md-6.multi-field-row:nth-last-child(2):nth-child(odd) {
		border-bottom: none;
	}
	.idcard-data-detail {
		margin-top: 0;
	}
}

.strong {
	font-weight: bold;
	text-align: right;
	padding-right: 0 !important;
}
`;

const html = `
<style>
	${CSS}
</style>

<div id="page-records">
    <div class="records">        
        <div class="filtered-records">
        </div>
    </div>
</div>
`;

interface Props {
	records: IDCardRecord[];
}

class Component extends React.Component<Props> {
	render() {
		return (
			<React.Fragment>
				<IntegratedRecordsList
					records={this.props.records}
					RecordTile={ViewTile}
					html={html}
					RecordsHandler={RecordsHandler}
				/>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		records: state.dataState.idcard.filtered,
	};
}

export const IDCard = connect(mapStateToProps)(Component);
