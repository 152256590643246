import React from 'react';
import { ApplicationState } from 'types';
import { connect } from 'react-redux';
import { enUsTheme, hiTheme } from 'config/theme';
import { Theme, ThemeProvider } from '@material-ui/core';

interface ThemeProps {
	locale: string;
	children: JSX.Element | JSX.Element[];
}

class StatefulThemeProvider extends React.Component<ThemeProps> {
	shouldComponentUpdate(nextProps: ThemeProps) {
		if (this.props.locale === nextProps.locale) {
			return false;
		}

		return true;
	}

	componentDidUpdate(prevProps: ThemeProps) {
		if (this.props.locale === prevProps.locale) {
			return;
		}
	}

	render() {
		let theme: Theme;
		if (this.props.locale === 'hi') {
			theme = hiTheme;
		} else {
			theme = enUsTheme;
		}

		return <ThemeProvider theme={theme}>{this.props.children}</ThemeProvider>;
	}
}

function mapStateToProps(state: ApplicationState, props) {
	let { locale } = state.uxState;

	if (props && props.locale) {
		return { locale: props.locale };
	}

	return {
		locale: locale,
	};
}

export default connect(mapStateToProps)(StatefulThemeProvider);
