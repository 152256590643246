import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { TrusteeRecord } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_TRUSTEE = '@@mjbj/UPDATE_TRUSTEE';
export type UPDATE_TRUSTEE = typeof UPDATE_TRUSTEE;

export interface UpdateTrustee extends Action {
	type: UPDATE_TRUSTEE;
	changes: FBChanges;
}

export function updateTrustee(changes: FBChanges): UpdateTrustee {
	return {
		type: UPDATE_TRUSTEE,
		changes,
	};
}

export const UPDATE_TRUSTEE_FILTERED = UPDATE_TRUSTEE + '_FILTERED';
export type UPDATE_TRUSTEE_FILTERED = typeof UPDATE_TRUSTEE_FILTERED;

export interface UpdateTrusteeFiltered extends Action {
	type: UPDATE_TRUSTEE_FILTERED;
	payload: TrusteeRecord[];
}
