import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { createStyles, Divider, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 'calc(100% - 16px)',
			margin: theme.spacing(1),
			boxShadow:
				'0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',

			'& .MuiToggleButton-root': {
				color: theme.palette.primary.main,
				backgroundColor: 'white',
				flex: '1 1 auto',
				fontWeight: 'bold',
				border: 'none',
				borderRight: `1px solid ${theme.palette.primary.main}`,
				marginLeft: 0,

				'&:last-child': {
					borderRight: 'none'
				}
			},

			'& .MuiToggleButton-root.Mui-selected': {
				color: 'white',
				backgroundColor: theme.palette.primary.main,
			},
		},
		divider: {
			padding: theme.spacing(1, 0),
			color: theme.palette.primary.main
		},
	})
);

export const ToggleSelector = (props: { values: string[]; type: string; setType: (type: string) => void; labels?: string[] }) => {
	const classes = useStyles();

	let { type, setType, labels } = props;

	const handleType = (event: React.MouseEvent<HTMLElement>, newType: string) => {
		if (newType) {
			setType(newType);
		}
	};

	return (
		<ToggleButtonGroup className={classes.root} value={type} exclusive onChange={handleType}>
			{props.values.map((value, index) => {
				return (
					<ToggleButton key={value} value={value}>
						{(labels && labels[index]) || value}
					</ToggleButton>
				);
			})}
		</ToggleButtonGroup>
	);
};
