import { ModelClass } from 'core/anand.model';
import firebase from 'fb/initconfig';
import { FBData } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const updateData = <T extends FBData>(curState, changes: FBChanges, slice?: string) => {
	var state = { ...curState };

	changes.forEach(function (change) {
		if (change.type === 'removed') {
			console.log('Ignoring Remove Event: ' + change.doc.id);
			// delete state.articlesById[change.doc.id];
		} else {
			let changeData = change.doc.data();
			changeData.id = change.doc.id;
			changeData.createdTime = changeData.createdAt?.seconds;
			if (changeData.inApp) {
				changeData.inApp = Object.keys(changeData.inApp).length ? changeData.inApp : undefined;
			}

			let data = changeData as T;

			// console.log(data);

			if (change.type === 'added' || change.type === 'modified') {
				console.log(change.type + ': ' + data.id);
				console.log(JSON.stringify(data));
				state[change.doc.id] = data;

				if (slice) {
					let Model = ModelClass.Instance(slice);
					Model.onUpdateRecord(slice, data);
				}
			}
		}
	});

	return state;
};
