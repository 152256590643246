import { HeaderControls, HeaderModule } from 'components/header/anand.header';
import { Records } from 'core/anand.records';
import { addFormHTML, briefTileHTML, detailFormHTML, detailTileHTML } from './viewTile';

export const RecordsHandler = (function ($) {
	var configMap = {};

	var stateMap: any = {
		systemId: 'through',
	};

	function initModule(props?, updateRecordsState?) {
		HeaderModule.initModule([
			HeaderControls.addRecordBtn,
			HeaderControls.selectDropdown,
			HeaderControls.printBtn,
			HeaderControls.sortBtn,
		]);

		Records.projectTitle = 'Amar Satya Sandesh Through Records';

		Records.getAnySearchFilter = getAnySearchFilter;
		Records.defaultSortOrder = 'name';
		Records.IdentifyingFieldName = 'code';

		Records.sortingFieldsMap = {
			name: 'Name',
			code: 'Code',
		};

		Records.searchFieldsMap = {
			name: 'Name',
			code: 'Code',
		};

		Records.isSearchDateTypeColumn = function (searchBy) {
			return false;
		};

		Records.printOptions = {
			fieldNames: ['S. No.', 'Name', 'Code'],
			fieldKeys: ['id', 'name', 'code'],
			defaultFieldWidths: { id: 60, name: 300, code: 100 },
			defaultHeader: 'Amar Sandesh Records Through Records',
			beforePrintHook: function (data, forExport) {
				for (var i = 0; i < data.length; i++) {
					data[i]['id'] = i + 1 + '.';
				}

				return data;
			},
		};

		Records.exportOptions = {
			fieldKeys: ['id', 'name', 'code'],
			defaultFileName: 'Amar Satya Sandesh Through Records',
		};

		Records.detailTileSelector = '#amar-through-detail-record';
		Records.briefTileSelector = '#amar-through-brief-record';
		Records.addTileSelector = '#amar-through-add-record';
		Records.detailFormSelector = '#amar-through-detail-record-form';
		Records.imageUploadSelector = null;

		Records.detailTileHTML = detailTileHTML;
		Records.briefTileHTML = briefTileHTML;
		Records.detailFormHTML = detailFormHTML;
		Records.addFormHTML = addFormHTML;

		Records.populateBriefTile = populateBriefTile;
		Records.populateDetailTile = populateDetailTile;
		Records.populateDetailForm = populateDetailForm;

		Records.updateRecordsState = updateRecordsState;

		Records.initModule({ systemId: stateMap.systemId, prepareRecord: prepareRecord, props: props });
	}

	function prepareRecord(record) {
		return record;
	}

	function getAnySearchFilter(query) {
		return [{ name: { likenocase: query } }, { code: { leftnocase: query } }];
	}

	async function populateBriefTile(sampleTile, record) {
		sampleTile.find('.name-detail .value').html(record['name']);

		sampleTile.find('.code-detail .value').html(record['code']);

		return sampleTile;
	}

	async function populateDetailTile(sampleTile, recordOrig) {
		var record = {};
		for (var key in recordOrig) {
			if (recordOrig[key] == null || recordOrig[key] == '') {
				record[key] = '&nbsp';
			} else {
				record[key] = recordOrig[key];
			}
		}

		sampleTile.find('.name-detail .value').html(record['name']);

		sampleTile.find('.code-detail .value').html(record['code']);

		return sampleTile;
	}

	function populateDetailForm(sampleTile, record) {
		sampleTile.find('input[name="name"]').val(record['name']);
		sampleTile.find('input[name="code"]').val(record['code']);

		return sampleTile;
	}

	return {
		initModule: initModule,
		setConfig: function (key, value) {
			configMap[key] = value;
		},
	};
})(jQuery);
