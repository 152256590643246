import SvgIcon from "@material-ui/core/SvgIcon";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BusinessIcon from "@material-ui/icons/Business";
import CloseIcon from "@material-ui/icons/Close";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Description from "@material-ui/icons/Description";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import EcoIcon from "@material-ui/icons/Eco";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FeedbackIcon from "@material-ui/icons/Feedback";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import FontDownloadIcon from "@material-ui/icons/FontDownload";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import Forward30Icon from "@material-ui/icons/Forward30";
import GetAppIcon from "@material-ui/icons/GetApp";
import HelpIcon from "@material-ui/icons/Help";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LanguageIcon from "@material-ui/icons/Language";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import LibraryMusic from "@material-ui/icons/LibraryMusic";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LockIcon from "@material-ui/icons/Lock";
import MenuIcon from "@material-ui/icons/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import OfflinePinIcon from "@material-ui/icons/OfflinePin";
import Pause from "@material-ui/icons/Pause";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Play from "@material-ui/icons/PlayArrow";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import PolicyIcon from "@material-ui/icons/Policy";
import PublishIcon from "@material-ui/icons/Publish";
import QueueIcon from "@material-ui/icons/Queue";
import QueueMusic from "@material-ui/icons/QueueMusic";
import RemoveIcon from "@material-ui/icons/Remove";
import RepeatIcon from "@material-ui/icons/Repeat";
import Replay30Icon from "@material-ui/icons/Replay30";
import SearchIcon from "@material-ui/icons/Search";
import SettingsIcon from "@material-ui/icons/Settings";
import ShareIcon from "@material-ui/icons/Share";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import TuneIcon from "@material-ui/icons/Tune";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import WhatshotIcon from "@material-ui/icons/Whatshot";

export const Icons = {
    VerifiedUser: <VerifiedUserIcon />,
    OfflinePinIcon: OfflinePinIcon,
    Duplicate: <FileCopyIcon />,
    Video: <VideoLibraryIcon />,
    CloudDownload: <CloudDownloadIcon />,
    Font: <FontDownloadIcon />,
    ExpandLess: <ExpandLess />,
    Menu: <MenuIcon />,
    Search: <SearchIcon />,
    Description: <Description />,
    NotificationImportant: <NotificationImportantIcon />,
    Email: <EmailIcon />,
    EmailIcon: EmailIcon,
    AccountBoxIcon: AccountBoxIcon,
    Feedback: <FeedbackIcon />,
    Business: <BusinessIcon />,
    Help: <HelpIcon />,
    Language: <LanguageIcon />,
    Lock: <LockIcon />,
    NavigateNext: <NavigateNextIcon />,
    Person: <PersonIcon />,
    PersonOutline: <PersonOutlineIcon />,
    Policy: <PolicyIcon />,
    WarningIcon: WarningRoundedIcon,
    Visibility: <Visibility />,
    VisibilityOff: <VisibilityOff />,
    PlayCircleOutline: <PlayCircleOutlineIcon />,
    PlaylistPlay: <PlaylistPlayIcon />,
    SwapVert: <SwapVertIcon />,
    Forward30: <Forward30Icon />,
    Replay30: <Replay30Icon />,
    Pause: <Pause />,
    Play: <Play />,
    PlayCircle: <PlayCircleFilled />,
    SkipNext: <SkipNextIcon />,
    SkipPrevious: <SkipPreviousIcon />,
    DoubleArrow: <DoubleArrowIcon />,
    Repeat: <RepeatIcon />,
    Shuffle: <ShuffleIcon />,
    Home: <HomeIcon />,
    Bhajan: <LibraryMusic />,
    Satsang: <QueueMusic />,
    About: <InfoIcon />,
    Publication: <LibraryBooksIcon />,
    ShriAmarVani: <WhatshotIcon />,
    Quote: <FormatQuoteIcon />,
    Calendar: <EventNoteIcon />,
    Donation: <EcoIcon />,
    Notification: <NotificationsIcon />,
    Account: <AccountCircle />,
    Admin: <TuneIcon />,
    Settings: <SettingsIcon />,
    // PlayArrowIcon: PlayArrowIcon,
    Download: <GetAppIcon />,
    DownloadIcon: GetAppIcon,
    MoreVert: <MoreVertIcon />,
    Queue: <QueueIcon />,
    Share: <ShareIcon />,
    DoneAll: <DoneAllIcon />,
    Done: <DoneIcon />,
    ExpandMore: <ExpandMoreIcon />,
    FilterList: <FilterListRoundedIcon />,
    Sort: <SortRoundedIcon />,
    Edit: <EditIcon />,
    Add: <AddIcon />,
    Remove: <RemoveIcon />,
    Delete: <DeleteIcon />,
    DeleteForever: <DeleteForeverIcon />,
    ListAlt: <ListAltIcon />,
    Upload: <PublishIcon />,
    KeyLeftArrow: <KeyboardArrowLeft />,
    KeyRightArrow: <KeyboardArrowRight />,
    DateRange: <DateRangeIcon />,
    NotificationsActive: <NotificationsActiveIcon />,
    ArrowBack: <ArrowBackIcon />,
    Close: <CloseIcon />,
    Scroll: (
        <SvgIcon viewBox='0 0 24 24'>
            <path d='M17.8,20C17.4,21.2 16.3,22 15,22H5C3.3,22 2,20.7 2,19V18H5L14.2,18C14.6,19.2 15.7,20 17,20H17.8M19,2C20.7,2 22,3.3 22,5V6H20V5C20,4.4 19.6,4 19,4C18.4,4 18,4.4 18,5V18H17C16.4,18 16,17.6 16,17V16H5V5C5,3.3 6.3,2 8,2H19M8,6V8H15V6H8M8,10V12H14V10H8Z' />
        </SvgIcon>
    ),
    Pooja: (
        <SvgIcon>
            <path d='M15,2L13.5,3.5L15,5L16.5,3.5L15,2M11,3C10,9 17,10 20,6L18,4.5C17,6 13,8 11,3M9,7C7,7 4.5,8.5 4.5,8.5L6,11C7,10 9,9.5 10,10C12,11 9,13 7,12V15.5C10,14 12,16 11,17.5C8,22 3,16 3,13C1,19 6,22 9,22C12,22 14,20 12.5,15H14C12.5,19.5 18,24 21,18C22,16 22,9.5 17,9.5C13,9.5 14,15 10.5,13.5C14,10 12,7 9,7M19,12C22,15 15,21 15,15C15,13 17,10.5 19,12Z' />
        </SvgIcon>
    ),
    Playlist: (
        <SvgIcon>
            <path d='M17,19.09L19.45,20.58L18.8,17.77L21,15.89L18.11,15.64L17,13L15.87,15.64L13,15.89L15.18,17.77L14.5,20.58L17,19.09M4,14H12V16H4V14M4,6H16V8H4V6M4,10H16V12H4V10Z' />
        </SvgIcon>
    ),
};
