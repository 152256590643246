import TextContent from 'components/TextContent';
import { DBConfig } from 'config';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { ApplicationState, FBArticle } from 'types';

interface Props {
	faqRecord: FBArticle;
}

const Component = (props: Props) => {
	return (
		<div style={{ padding: 16 }}>
			<TextContent>{props.faqRecord?.text?.en}</TextContent>
		</div>
	);
};

function mapStateToProps(state: ApplicationState) {
	let faqId = state.dataState.configs[DBConfig.FAQ];
	let faqRecord = state.dataState.articles.byId[faqId?.value];

	return {
		faqRecord,
	};
}

export default hot(connect(mapStateToProps)(Component));
