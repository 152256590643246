import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

export const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	// databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// if (isWebEnv()) {
// 	firebaseConfig['measurementId'] = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
// }
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// if (isWebEnv()) {
// 	require('firebase/analytics');

// 	if (firebase.analytics.isSupported()) {
// 		firebase.analytics();
// 	}
// }

export let fireDB = firebase.firestore();
// fireDB.settings({
// 	cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
// });
// fireDB.enablePersistence({ synchronizeTabs: true });
// fireDB.disableNetwork();

// if (window.location.hostname === 'localhost') {
// 	fireDB.settings({
// 		host: 'localhost:8081',
// 		ssl: false,
// 	});
// 	firebase.auth().useEmulator('http://localhost:9099/');
// }

export default firebase;
