import { withStyles } from '@material-ui/core/styles';
import ProgressBar from 'components/shell/ProgressBar';
import React from 'react';
import { ConnectedComponent } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

interface RecordListProps {
	ref?: any;
	RecordTile:
		| ((props: any) => JSX.Element | null)
		| ConnectedComponent<(props: any) => JSX.Element | null, any | null>;
	RecordTileProps?: any;
	records: any[];
	classes: any;
	fetching?: boolean;
	initialScrollOffset?: number;
	getItemSize: (index: number) => number;
}

const styles = {
	root: {
		'margin-right': '10px',
		width: '100%',
		height: '100%',
		// 'min-height': '60vh'
	},
	inline: {
		display: 'inline',
	},
	paper: {
		marginBottom: 4,
	},
	button: {
		flex: '1 1 auto',
		borderRadius: 0,
		borderRight: '1px solid darkgrey',

		'&:last-of-type': {
			borderRight: 'none',
		},
	},
};

class RecordList extends React.Component<RecordListProps, any> {
	private listRef = React.createRef();
	private listElRef = React.createRef();

	public resetAfterIndex(index: number) {
		if (this.listRef.current) {
			(this.listRef.current as VariableSizeList).resetAfterIndex(index);
		}
	}

	public scrollToItem = (index: number, align: string = 'auto') => {
		// if (this.listElRef.current) {
		// 	(this.listElRef.current as HTMLElement).style.scrollBehavior = 'smooth';
		// }
		(this.listRef.current as VariableSizeList)?.scrollToItem(index, align);
	};

	public scrollTo = (scrollOffset: number) => {
		// if (this.listElRef.current) {
		// 	(this.listElRef.current as HTMLElement).style.scrollBehavior = 'smooth';
		// }
		(this.listRef.current as VariableSizeList)?.scrollTo(scrollOffset ?? 0);
	};

	private renderRow(props: ListChildComponentProps) {
		const { index, style } = props;
		let { records, RecordTile, RecordTileProps } = this.props;

		let record = records[index];
		return (
			<div style={style} key={'fragment-' + index}>
				{record ? (
					<RecordTile record={record} index={index} listRef={this.listRef} {...RecordTileProps} />
				) : (
					<RecordTile />
				)}
			</div>
		);
	}

	public scrollOffset = 0;
	onScroll = (obj) => {
		let { scrollDirection, scrollOffset, scrollUpdateWasRequested } = obj;
		this.scrollOffset = scrollOffset ?? 0;
	};

	componentDidUpdate() {
		this.resetAfterIndex(0);
	}

	render() {
		let { records, classes, fetching, getItemSize, initialScrollOffset } = this.props;
		records = records || Array.from(new Array(4));

		// if (records.length === 0) {
		// 	return <ProgressBar />;
		// }

		let loadingTile = fetching ? <ProgressBar /> : null;
		let tiles = (
			<AutoSizer key={'auto-' + records.length} style={{ position: 'inherit', height: '100%' }}>
				{({ height, width }) => (
					<VariableSizeList
						ref={this.listRef}
						outerRef={this.listElRef}
						itemSize={getItemSize}
						height={height}
						width={width}
						itemCount={records.length}
						overscanCount={4}
						initialScrollOffset={initialScrollOffset}
						onScroll={this.onScroll}
					>
						{this.renderRow.bind(this)}
					</VariableSizeList>
				)}
			</AutoSizer>
		);

		return (
			<div className={classes.root}>
				{loadingTile}
				{tiles}
			</div>
		);
	}
}

export default withStyles(styles)(RecordList);
