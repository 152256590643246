import { isOnlyMjBjSite } from "config";
import { isMobile } from "helpers";
import { populateViewTileHelper } from "helpers/RecordsHelper";
import React from "react";
import { connect } from "react-redux";
import { ApplicationState, MjBjRecord } from "types";

export const MjBjViewTileCSS = `
<style>
	.tile.mjbj-record.detail {
		padding-top: 15px;
	}

	.tile.mjbj-record .main-details {
		padding-right: 30px;
	}

	.tile.mjbj-record .main-details .title {
		text-align: right;
		padding: 0;
		padding-bottom: 25px;
		font-weight: bold;
		font-size: 0.9em;
	}

	.tile.mjbj-record input {
		width: 100%;
		background-color: white !important;
		border: none;
		border-bottom: 1px solid darkgray;
	}

	.tile.mjbj-record input:focus {
		outline: none;
	}

	.tile.mjbj-record .value select {
		width: 100%;
	}

	.tile.mjbj-record .self-image {
		max-height: 250px;
		margin-bottom: 0px;
		margin-top: 0px;
		max-width: 100%;
	}

	.tile.mjbj-record .info-tile {
		border: 1px solid darkgray;
		margin-bottom: 30px;
	}

	.tile.mjbj-record .holydress-detail.info-tile {
		border: 2px solid chocolate;
	}

	.tile.mjbj-record .samadhi-detail.info-tile {
		border: 2px solid green;
	}

	.tile.mjbj-record .info-tile .info-title {
		text-align: center;
	}

	.tile.mjbj-record .info-tile select {
		width: 100%;
	}

	.tile.mjbj-record .info-tile .value {
		text-align: center;
		width: 100%;
		border-bottom: none !important;
	}

	.tile.mjbj-record .comments {
		border: 1px solid darkgray;
		padding: 10px;
		overflow: auto;
		max-height: 6em;
	}

	.tile.mjbj-record .comments-title {
		border: 1px solid darkgray;
		border-bottom: 0;
		text-align: center;
	}

	div.tile.mjbj-record .main-details .value {
		border-bottom: 1px solid lightgrey;
		min-height: 1.5em;
		padding-right: 0;
	}

	.lb {
		border-bottom: 1px solid lightgrey;
	}

	.rahbar-detail select,
	.past-rahbar-detail select,
	.self-detail select,
	.head-detail select {
		margin-left: -0.5em;
	}

	.contact-type {
		font-weight: bold;
		text-align: right;
		padding-right: 0;
	}

	.contact-value {
		text-align: left;
		letter-spacing: 1px;
		font-size: initial;
	}

	.contact-detail .title,
	.group-detail .title {
		padding-bottom: 5px !important;
	}

	#contact-type-value {
		display: none;
	}

	.multi-field-row {
		padding-bottom: 5px;
		margin-bottom: 5px;
		border-bottom: 1px solid lightgrey;
	}

	.tile.brief {
		min-height: 80px;
	}

	@media (max-width: 991px) {
		.tile.detail.mjbj-record .self-image {
			max-height: initial;
			margin-bottom: 10px;
			margin-top: 0px;
			width: 100%;	
		}
		.tile.brief.mjbj-record .self-image {
			max-height: 65px;
		}
	}
</style>
`;

const html = `
<div id="mjbj-detail-record" class="tile mjbj-record detail">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="col-xs-12 col-md-3">
			<div class="block image-upload-container">
				<img class="self-image button" />
			</div>
		</div>
		<div class="col-xs-12 col-md-3 main-details">
			<div class="block">
				<div class="row self-detail">
					<div class="title col-xs-3 col-md-3">
						Name:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row old-name-detail">
					<div class="title col-xs-3 col-md-3">
						Old Name:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row dob-detail">
					<div class="title col-xs-3 col-md-3">
						DoB:
					</div>
					<div class="value col-xs-9 col-md-9">
						<div class="date"></div>
					</div>
				</div>
				<div class="row age-detail">
					<div class="title col-xs-3 col-md-3">
						Age:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row naamdaan-detail">
					<div class="title col-xs-3 col-md-3">
						Naam:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row by-detail">
					<div class="title col-xs-3 col-md-3">
						By:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-3 main-details">
			<div class="block">
				<div class="row rahbar-detail">
					<div class="title col-xs-3 col-md-3">
						Rahbar:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row past-rahbar-detail">
					<div class="title col-xs-3 col-md-3">
						Past Rah.:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row head-detail">
					<div class="title col-xs-3 col-md-3">
						Head:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row sewa-detail">
					<div class="title col-xs-3 col-md-3">
						Sewa:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row contact-detail">
					<div class="title col-xs-3 col-md-3">
						Contacts:
					</div>
					<div id="contact-type-value" class="multi-field-row col-xs-12 col-md-12">
						<div class="row">
							<div class="col-xs-4 contact-type"></div>
							<div class="col-xs-8 contact-value"></div>
						</div>
					</div>
				</div>
				<div class="row group-detail">
					<div class="title col-xs-3 col-md-3">
						Groups:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-3">
			<div class="block">
				<div class="row">
					<div class="col-xs-12">
						<div class="holydress-detail info-tile">
							<div class="row">
								<div class="col-xs-12 info-title">
									<div class="border-bottom">Holydress</div>
								</div>
							</div>
							<div class="row">
								<div class="col-xs-6 info-title no-r-padding">
									<div class="border-bottom border-right">Date</div>
								</div>
								<div class="col-xs-6 info-title no-l-padding">
									<div class="border-bottom">Place</div>
								</div>
							</div>
							<div class="row">
								<div class="col-xs-6 no-r-padding">
									<div class="date value border-right"></div>
								</div>
								<div class="col-xs-6 no-l-padding">
									<div class="place value"></div>
								</div>           										
							</div>
						</div>
					</div>
					<div class="col-xs-12">
						<div class="samadhi-detail info-tile">
							<div class="row">
								<div class="col-xs-12 info-title">
									<div class="border-bottom">Samadhi</div>
								</div>
							</div>
							<div class="row">
								<div class="col-xs-6 info-title no-r-padding">
									<div class="border-bottom border-right">Date</div>
								</div>
								<div class="col-xs-6 info-title no-l-padding">
									<div class="border-bottom">Place</div>
								</div>
							</div>
							<div class="row">
								<div class="col-xs-6 no-r-padding">
									<div class="date value border-right"></div>
								</div>
								<div class="col-xs-6 no-l-padding">
									<div class="place value"></div>
								</div>           										
							</div>
						</div>
					</div>
					<div class="col-xs-12">
						<div class="comments-container">
							<div class="comments-title">Comments</div>
							<div class="comments"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
`;

const briefHtml = `
<div id="mjbj-brief-record" class="tile mjbj-record brief">
	<div class="row">
		<div class="col-xs-2 no-r-padding">
			<img class="self-image" />           						
		</div>
		<div class="col-xs-10">
			<div class="row self-detail">
				<div class="title col-xs-2 col-md-3 no-padding align-right">

				</div>
				<div class="value col-xs-10 col-md-9">

				</div>
			</div>
			<div class="row old-name-detail">
				<div class="title col-xs-3 col-md-3 no-padding align-right">
					Old Name:
				</div>
				<div class="value col-xs-9 col-md-9">
				</div>
			</div>           						
			<div class="row sewa-detail">
				<div class="title col-xs-2 col-md-3 no-padding align-right">
					Sewa:
				</div>
				<div class="value col-xs-10 col-md-9">

				</div>
			</div>
		</div>
	</div>
	<div class="record-id">
		<span></span>
	</div>
</div>`;

interface Props {
    record?: MjBjRecord;
    index?: number;
    listRef?: React.RefObject<any>;
}

class Component extends React.PureComponent<Props> {
    private tileRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
    }

    componentDidUpdate(prevProps, prevState) {
        populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
    }

    render() {
        return <div ref={this.tileRef} dangerouslySetInnerHTML={{ __html: isMobile() || isOnlyMjBjSite() ? briefHtml : html }}></div>;
    }
}

function mapStateToProps(state: ApplicationState) {
    return {};
}

export const MjBjViewTile = connect(mapStateToProps)(Component);
