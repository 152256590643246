import App from './App';
import firebase from 'fb/index';
import { ApplicationState } from 'types';
import { ThunkDispatch } from 'redux-thunk';

declare global {
	interface Window {
		AllModules: any;
	}
}

export const ReceivedAppNotification = '@@app/ReceivedAppNotification';
export const AppDidBecomeActive = '@@app/AppDidBecomeActive';
export const NowPlaying = '@@app/NowPlaying';
export const DidUpdateElapsedTime = '@@app/DidUpdateElapsedTime';
export const DidUpdateDuration = '@@app/DidUpdateDuration';
export const DidUpdateStatus = '@@app/DidUpdateStatus';
export const DidUpdateLoadedByteRanges = '@@app/DidUpdateLoadedByteRanges';
export const DidPlayToEnd = '@@app/DidPlayToEnd';
export const DidDownloadMedia = '@@app/DidDownloadMedia';
export const DidDeleteMedia = '@@app/DidDeleteMedia';
export const DidUpdateDownloadProgress = '@@app/DidUpdateDownloadProgress';
export const AddAppToken = '@@app/AddAppToken';
export const ChangePage = '@@app/ChangePage';

export class AppInterface {
	private isIOS: boolean = false;
	private getState: () => ApplicationState;
	private dispatch: ThunkDispatch<ApplicationState, any, any>;

	public constructor(getState: () => ApplicationState, dispatch: ThunkDispatch<ApplicationState, any, any>) {
		this.getState = getState;
		this.dispatch = dispatch;

		window.AllModules = {
			app: this,
		};
	}

	public getAppVersionRounded = () => {
		return Number(App.getAppVersion().toFixed(1));
	};

	public initialize = async () => {
		if (window.AnandApp) {
			if (this.isIOS) {
				await App.getAppInstallationId();
				await App.getBuildVersionCode();
			}
		}
	};

	private dispatchAction(type, payload) {
		setTimeout(() => {
			this.dispatch({ type: type, payload });
		}, 0);
	}

	public receivedAppNotification(notification) {
		this.dispatchAction(ReceivedAppNotification, notification);
	}

	public appDidBecomeActive() {
		this.dispatchAction(AppDidBecomeActive, null);
	}

	public setNowPlaying(articleId) {
		this.dispatchAction(NowPlaying, articleId);
	}

	public didUpdateElapsedTime(elapsedTime) {
		this.dispatchAction(DidUpdateElapsedTime, elapsedTime);
		// this.dispatch(updateAudioPlaybackTime(elapsedTime));
	}

	public didUpdateDuration(duration) {
		this.dispatchAction(DidUpdateDuration, duration);
		// this.dispatch(updateAudioPlaybackDuration(duration));
	}

	public didUpdateStatus(status) {
		this.dispatchAction(DidUpdateStatus, status);
		// this.dispatch(
		// 	updateAudioPlaybackState(status === 'playing' ? AudioCurrentState.Playing : AudioCurrentState.Paused)
		// );
	}

	public didUpdateLoadedByteRanges(start, duration) {
		this.dispatchAction(DidUpdateLoadedByteRanges, { start: start, duration: duration });
	}

	public didPlayToEnd() {
		this.dispatchAction(DidPlayToEnd, null);
		// this.dispatch(updateAudioPlaybackState(AudioCurrentState.Ended));
	}

	private googleSignInCallback;
	private googleSignInErrorCallback;
	public async googleUserSignedIn(gIdTokenOrResult, error?) {
		// this.dispatchAction('GoogleUserSignedIn', gIdToken);
		if (error) {
			this.googleSignInErrorCallback && this.googleSignInErrorCallback(error);
			return;
		}

		let result = gIdTokenOrResult;
		if (typeof gIdTokenOrResult === 'string') {
			let credential = firebase.auth.GoogleAuthProvider.credential(gIdTokenOrResult);
			result = await firebase.auth().signInWithCredential(credential);
		}

		if (this.googleSignInCallback) {
			this.googleSignInCallback(result);
			this.googleSignInCallback = null;
		}
	}

	public async initiateGoogleSignIn() {
		let result = new Promise<any>((resolve, reject) => {
			this.googleSignInCallback = (result) => {
				resolve(result);
			};
			this.googleSignInErrorCallback = (error) => {
				reject(error);
			};
		});
		App.loadGoogleSignin();
		return result;
	}

	public didDownloadMedia(audioId, result) {
		this.dispatchAction(DidDownloadMedia, { audioId, result });
	}

	public didDeleteMedia(audioId) {
		this.dispatchAction(DidDeleteMedia, audioId);
	}

	public didDownloadAudioData(audioId, bytesWritten, totalBytesWritten, totalBytesExpectedToWrite) {
		if (totalBytesExpectedToWrite !== 0) {
			var progress = (totalBytesWritten * 100.0) / totalBytesExpectedToWrite;
			this.dispatchAction(DidUpdateDownloadProgress, { audioId, progress, totalBytesWritten, totalBytesExpectedToWrite });
		}
	}

	public receiveFromSwift(opName, data) {
		App.receiveFromApp(opName, data);
	}

	public clearAllCache(includeDiskFiles) { }

	public addAppToken(app, deviceId, token) {
		this.dispatchAction(AddAppToken, { app, deviceId, token });
	}

	public changePage(pageName) {
		this.dispatchAction(ChangePage, pageName);
	}

	public action(actionType, actionPayload) {
		this.dispatchAction(actionType, actionPayload);
	}

	public goBackIfCan() {

	}
}
