import { FireStoreModule } from 'fb';
import firebase from 'fb/index';
import 'firebase/firestore';
import { updateThrough } from 'store/data/journals/through/actions';

export class ThroughFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'through';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: firebase.firestore.Firestore) => db.collection('through');
	public getChangeProcessAction = (changes) => updateThrough(changes);
}
