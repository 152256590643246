import { grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { BottomDrawerOption } from 'components/shell/BottomDrawerOptions';
import SimpleBottomNavigation from 'components/shell/BottomNavigation';
import { isAdmin, isMobile } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { updateAppBarTitle } from 'store/temp/actions';
import { ApplicationState } from 'types';

const styles = (theme) => ({
	root: (props) => {
		let offset = props.includeBottomNavigation && isMobile() ? 112 : 57 + (isMobile() ? 30 : 0);
		let inset = '- env(safe-area-inset-bottom)/2';
		let fallback = (css, property) => css.replace(` ${inset}`, '') + '; ' + property + ': ' + css;
		let top = props.topControlHeight ?? 0;
		let template = (top, off, property, condition) =>
			condition ? fallback(`calc(100% ${inset} - ${offset + off + top}px)`, property) : 'auto';

		return {
			'padding-top': 1,
			backgroundColor: props.dark ? grey[200] : 'white',

			minHeight: template(0, 0, 'min-height', !props.fullscreen),
			height: template(top, 0, 'height', props.fullscreen),

			[`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
				height: template(top, -8, 'height', props.fullscreen),
			},

			[theme.breakpoints.up('sm')]: {
				height: template(top, 8, 'height', props.fullscreen),
			},
		};
	},
});

interface PageProps {
	title: string;
	classes: any;
	children: React.ReactNode;
	updateAppBarTitle: (title: string) => void;
	style?: any;
	fullscreen?: boolean;
	includeBottomNavigation?: boolean;
	topControlHeight?: number;
	onLoad?: () => void;
	isAdmin: boolean;
	dark?: boolean;
	miniMode: boolean;
	contextualMoreOptions?: BottomDrawerOption[];
}

class Page extends React.Component<PageProps> {
	constructor(props) {
		super(props);

		props.updateAppBarTitle(props.title);
	}

	componentDidMount() {
		if (this.props.onLoad) {
			this.props.onLoad();
		}
	}

	render() {
		let classes = this.props.classes;

		return (
			<div className={classes.root} style={this.props.style}>
				{this.props.children}
				{this.props.miniMode && !this.props.fullscreen ? <div style={{ height: 80 }} /> : null}
				{this.props.includeBottomNavigation && isMobile() ? (
					<SimpleBottomNavigation contextualMoreOptions={this.props.contextualMoreOptions} />
				) : null}
			</div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		isAdmin: isAdmin(state.dataState.userStore.userData),
		miniMode: state.tempState.audioPlaybackDetails && state.tempState.audioPlaybackState.isMiniPlayerMode,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		updateAppBarTitle: (title: string) => {
			dispatch(updateAppBarTitle(title));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Page));
