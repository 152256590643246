import firebase from 'fb/initconfig';
import { Action } from 'redux';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_CONFIG = '@@configs/UPDATE_CONFIG';
export type UPDATE_CONFIG = typeof UPDATE_CONFIG;

export interface UpdateConfigs extends Action {
	type: UPDATE_CONFIG;
	changes: FBChanges;
}

export function updateConfigs(changes: FBChanges): UpdateConfigs {
	return {
		type: UPDATE_CONFIG,
		changes,
	};
}
