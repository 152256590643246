import { generateRecordId } from 'fb';
import { getDownloadUrl, getStorageUrl, uploadFile } from 'fb/storage';
import { isMobile } from 'helpers';
import { anand } from './anand';
import { ModelClass } from './anand.model';
// import { Model as Model } from './anand.model';
import { isOnlyMjBjSite } from 'config';
import { notification } from './anand.notification';
import { overlay_panel } from './anand.overlay_panel';
import { utils } from './anand.utils';
import './css/anand.records.css';
import './css/anand.typeahead.css';

declare global {
	var BootstrapDialog: any;
	var jsPDF: any;
}

//TODO: Add Redis support
//
//TODO: Move login page to https
//
//TODO: a failed response should not cause pending transactions to be lost
//TODO: Even if we are online but if server doesn't respond then we'll loose transaction also, make sure in that case we save the transaction
//TODO: take care of the cases when we get an exception due to quota
//
//TODO: Add Author field in holy rhymes
//
//TODO: Central class for services to return consistent error/exception/result data
//TODO: Show proper error messages on failing of any service
//Image TOo Big, Any PHP Error, Any Database Error, Required Fields Missing
//
//TODO: Security, data encryption while storing at local storage,

export const RecordsFactory = () =>
	(function ($) {
		var configMap = {
			imgRoot: '/application/assets/img/',
		};

		var stateMap: any = {
			currentPage: 0,
			recordsPerPage: 10,
			isMobile: isMobile(),
			sortOrder: '',
			showingSelected: false,
			showingUnSelected: false,
			systemId: '',
			addTileFormValidationRules: false,
		};

		function initModule(options) {
			stateMap = {
				currentPage: 0,
				recordsPerPage: 10,
				isMobile: isMobile(),
				sortOrder: '',
				showingSelected: false,
				showingUnSelected: false,
				systemId: '',
				addTileFormValidationRules: false,
				options: options,
			};

			stateMap.systemId = options.systemId;
			stateMap.addTileFormValidationRules = options.addTileFormValidationRules;

			clearSearch();

			utils.showLoading(true);
			let Model = ModelClass.Instance(stateMap.systemId);
			Model.initModule(options, function () {
				Model.getUserPermissions(stateMap.systemId, function (permissions) {
					Records.viewAllowed = permissions?.viewAllowed;
					Records.editAllowed = permissions?.editAllowed;

					if (Records.viewAllowed == false) {
						utils.showLoading(false);
						BootstrapDialog.show({
							type: BootstrapDialog.TYPE_DANGER,
							title: 'Authentication Issue',
							message: 'You are not allowed to view this page! Please contact Administrator for access.',
							closable: false,
						});
						return;
					}

					if (options.props.user) {
						proceed();
					}
					// localforage.getItem('logged_in', function (err, value) {
					// 	if (value !== 'true') {
					// 		utils.showLoading(false);
					// 		// window.location.href = '/users/login_page';
					// 	} else {
					// 		proceed();
					// 	}
					// });
				});
			});

			function proceed() {
				$('#main-wrapper').show();
				let optionsMap = Records.selectionDropdownOptionsMap;
				let dropdown = $('#selection-dropdown select');
				for (var key in optionsMap) {
					var value = optionsMap[key];
					if (key.indexOf('*') === 0) {
						key = key.substr(1);
						dropdown.append($(`<option value='${key}' selected='selected'>${value}</option>`));
					} else {
						dropdown.append($(`<option value='${key}'>${value}</option>`));
					}
				}

				if (Records.hooks.configureHook !== null) {
					Records.hooks.configureHook();
				}

				configureAddRecord();
				configureSearch();
				configureSort();

				applyFilters(null, function () {
					handleReadOnlyModel(function () {
						configureHeader();
						configureReminders();

						utils.showLoading(false);
					});
				});
			}
		}

		function handleReadOnlyModel(callback) {
			var isReadOnly = !Records.editAllowed;
			if (isReadOnly === true) {
				disableEditOptions();
			} else {
				enableEditOptions();
			}
			callback();
		}

		function enableEditOptions() {
			$('.add-record-btn').show();
			$('.pending-btn').show();
			$('.reminder-btn').show();
			$('.print-btn').show();
			$('.dashboard-btn').show();
		}

		function disableEditOptions() {
			$('.add-record-btn').hide();
			$('.pending-btn').hide();
			$('.reminder-btn').hide();
			$('.print-btn').hide();
			$('.dashboard-btn').hide();
		}

		function configureHeader() {
			$('#project-title span').html(Records.projectTitle);

			var wrap = $('#main-wrapper');
			var controlsHeader: any = $('#controls-header');
			var controlsHeaderOffsetTop = controlsHeader.offset().top;
			$('#controls-header-placeholder').css('height', 0);

			// $(window).scroll(onScrollForHeader);

			function onScrollForHeader(this: any, e) {
				if (overlay_panel.isVisible() === true) {
					return;
				}

				handleInfiniteScroll();
			}

			anand.setConnectivityListener(function () {
				setOfflineStatus(true);
			});
			setOfflineStatus(false);

			function setOfflineStatus(sync) {
				if (anand.workOffline() === true) {
					$('.pending-btn').css('color', 'red');
					$('.logout-btn').hide();
				} else {
					$('.pending-btn').css('color', 'lightgreen');
					if (sync) {
						// syncRecords();
					}
				}
			}

			$('.dashboard-btn').click(showDashboard);
			$('.export-btn').click(onExportRecords);
			$('.print-btn').click(onPrintRecords);
			$('.pending-btn').hide();
			// $('.reminder-btn').click(onImportData);
			//        $(".pending-btn").click(onImportNewCSV);

			$('.selection-select-all').click(selectAllTiles);
			$('.selection-clear-all').click(clearAllTiles);
			$('.selection-show').click(showSelectedTiles);
			$('.selection-show-not').click(showUnSelectedTiles);
			$('.selection-showall').click(showAllTiles);

			$('.logout-btn').click(onLogout);

			if (Records.hooks.afterHeaderConfigure !== null) {
				Records.hooks.afterHeaderConfigure(controlsHeader);
			}
		}

		function selectAllTiles() {
			for (var i = 0; i < stateMap.allRecords.length; i++) {
				stateMap.allRecords[i].selected = true;
			}
			applyFilters();
		}

		function clearAllTiles() {
			for (var i = 0; i < stateMap.allRecords.length; i++) {
				stateMap.allRecords[i].selected = false;
			}
			applyFilters();
		}

		function showSelectedTiles() {
			//		clearSearch();
			//		Records.clearAllFilters();
			stateMap.showingSelected = true;
			stateMap.showingUnSelected = false;

			applyFilters();
		}

		function showUnSelectedTiles() {
			//		clearSearch();
			//		Records.clearAllFilters();
			stateMap.showingUnSelected = true;
			stateMap.showingSelected = false;

			applyFilters();
		}

		function showAllTiles() {
			stateMap.showingSelected = false;
			stateMap.showingUnSelected = false;
			notification.showBottomNotification('Showing All Records', false);

			applyFilters();
		}

		function configureReminders() {
			if (stateMap.trusteeMode === true) {
				return;
			}

			$('.reminder-btn').click(showDateReminders);
			var reminderAvailable = showDateReminders(null, true);
		}

		function showDateReminders(e, checkOnly) {
			if (Records.getReminderFilter === null) {
				return false;
			}

			var curDate = new Date();

			var curMonth: any = curDate.getMonth() + 1;
			if (curMonth < 10) {
				curMonth = '0' + curMonth;
			}

			var curDay: any = curDate.getDate();
			if (curDay < 10) {
				curDay = '0' + curDay;
			}

			var curDateStr = '-' + curMonth + '-' + curDay;

			var tomDate = new Date(curDate.getTime() + 24 * 60 * 60 * 1000);

			var tomMonth: any = tomDate.getMonth() + 1;
			if (tomMonth < 10) {
				tomMonth = '0' + tomMonth;
			}

			var tomDay: any = tomDate.getDate();
			if (tomDay < 10) {
				tomDay = '0' + tomDay;
			}

			var tomDateStr = '-' + tomMonth + '-' + tomDay;

			var curFilters = Records.getReminderFilter(curDateStr);
			var tomFilters = Records.getReminderFilter(tomDateStr);
			var remindersHtml = $("<div class='reminders_section'></div>");

			var defaultSortOrder = Records.defaultSortOrder;
			let Model = ModelClass.Instance(stateMap.systemId);
			Model.getRecords([curFilters], defaultSortOrder, function (curRecords) {
				Model.getRecords([tomFilters], defaultSortOrder, async (tomRecords) => {
					if (curRecords.length > 0 || tomRecords.length > 0) {
						$('.reminder-btn').css('color', 'lightgreen');
						$('.reminder-btn-badge')
							.find('.value')
							.html(curRecords.length + tomRecords.length);
						$('.reminder-btn-badge').show();
					} else {
						$('.reminder-btn-badge').hide();
					}

					if (typeof checkOnly !== 'undefined' && checkOnly === true) {
						if (curRecords.length > 0 || tomRecords.length > 0) {
							return true;
						} else {
							return false;
						}
					} else {
						pauseAudio();
					}

					let tileRefs: Promise<any>[] = [];
					if (curRecords.length > 0) {
						remindersHtml.append($("<div class='reminder_heading'>For Today</div>"));
						for (var i = 0; i < curRecords.length; i++) {
							tileRefs.push(getDetailTile(curRecords[i], i, true));
						}
					}
					let tiles = await Promise.all(tileRefs);
					tiles.forEach((tile) => {
						tile.find('.tile-controls-header').hide();
						remindersHtml.append(tile);
					});

					tileRefs = [];
					if (tomRecords.length > 0) {
						remindersHtml.append($("<div class='reminder_heading'>For Tomorrow</div>"));
						for (var i = 0; i < tomRecords.length; i++) {
							tileRefs.push(getDetailTile(tomRecords[i], i, true));
						}
					}

					tiles = await Promise.all(tileRefs);
					tiles.forEach((tile) => {
						tile.find('.tile-controls-header').hide();
						remindersHtml.append(tile);
					});

					var remindersObj = {
						contentHtml: remindersHtml.html(),
						id: 'reminder',
						title: 'Date Reminders',
					};

					overlay_panel.showPanel(remindersObj);
				});
			});
		}

		function setSortOrder(element?, sortBy?, sortDir?) {
			$('#sorting-section .sort-option').removeAttr('data-sortdir');
			$('#sorting-section .sort-option').css('color', 'white');
			$('#sorting-section .sort-option i.default-order').show();
			$('#sorting-section .sort-option i.alt-order').hide();

			if (sortDir) {
				if (sortDir === 'logical') {
					element.find('i.default-order').hide();
					element.find('i.alt-order').show();
					sortDir = 'logicaldesc';
				} else {
					element.find('i.default-order').show();
					element.find('i.alt-order').hide();
					sortDir = 'logical';
				}
			} else {
				sortDir = 'logical';
			}

			element.attr('data-sortdir', sortDir);
			element.css('color', 'black');

			if (sortBy === 'function') {
				Records.sortDirection = sortDir === 'logical' ? 1 : -1;
				stateMap.sortOrder = Records.customSortingFunction(Records.sortingFieldsMap[sortBy], Records.sortDirection);
			} else if (sortBy.indexOf(',') > 0) {
				stateMap.sortOrder = sortBy.replace(',', ' ' + sortDir + ',');
			} else {
				stateMap.sortOrder = sortBy + ' ' + sortDir;
			}
		}

		function configureSort(this: any) {
			var fieldsMap = Records.sortingFieldsMap;
			for (var key in fieldsMap) {
				var sortingElement = $('#sorting-element').clone();
				sortingElement.removeAttr('id');
				sortingElement.attr('data-sortby', key);
				sortingElement.find('.text span').html(fieldsMap[key]);
				sortingElement.show();

				$('#sorting-section .row').append(sortingElement);
			}

			$('.sort-records-btn').click(function () {
				$('#sorting-section').slideToggle();
			});

			setSortOrder($(this), Records.defaultSortOrder);

			$('#sorting-section .sort-option').click(function () {
				var sortBy = $(this).attr('data-sortby');
				var sortDir = $(this).attr('data-sortdir');

				setSortOrder($(this), sortBy, sortDir);
				applyFilters();
			});
		}

		async function handleInfiniteScroll() {
			if ($(window).scrollTop() ?? 0 >= 0.95 * ($(document).height() ?? 0) - ($(window).height() ?? 0)) {
				await populateNextPage();
			}
		}

		async function populateNextPage() {
			return;
		}

		function showPrintConfigScreen() {
			var printConfigHtml =
				'<div id="print-configs">' +
				'<div class="row">' +
				'<div class="col-xs-3 col-md-1 no-r-padding"><label>Header:</label></div>' +
				'<div class="col-xs-5 col-md-7 no-l-padding"><input type="text" name="short_header" placeholder="Header Text" value="' +
				Records.printOptions.defaultHeader +
				'" /></div>' +
				'</div>' +
				'<div class="row">' +
				'<div class="col-xs-3 col-md-1 no-r-padding"><label>Paper:</label></div>' +
				'<div class="col-xs-3 col-md-2 no-padding">' +
				'<select id="page_size">' +
				'<option value="a4">A4</option>' +
				'<option value="a5">A5</option>' +
				'<option value="legal">Legal</option>' +
				'<option value="custom">Custom</option>' +
				'</select>' +
				'</div>' +
				'<div class="col-xs-3 col-md-1 no-r-padding"><label>Layout:</label></div>' +
				'<div class="col-xs-3 col-md-2 no-l-padding">' +
				'<select id="page_orientation">' +
				'<option value="l">Landscape</option>' +
				'<option value="p">Portrait</option>' +
				'</select>' +
				'</div>' +
				'<div class="clearfix hidden-lg hidden-md"></div>' +
				'<div class="col-xs-3 col-md-1 no-r-padding"><label>Font:</label></div>' +
				'<div class="col-xs-3 col-md-2 no-padding">' +
				'<select id="font_type">' +
				'<option value="helvetica">helvetica</option>' +
				'<option value="courier">courier</option>' +
				'<option value="times">times</option>' +
				'</select>' +
				'</div>' +
				'<div class="col-xs-3 col-md-1 no-r-padding"><label>Font Size:</label></div>' +
				'<div class="col-xs-3 col-md-2 no-l-padding">' +
				'<select id="font_size">' +
				'<option value="8">8</option>' +
				'<option value="9">9</option>' +
				'<option value="10">10</option>' +
				'<option value="11">11</option>' +
				'<option value="12" selected="selected">12</option>' +
				'<option value="13">13</option>' +
				'<option value="14">14</option>' +
				'</select>' +
				'</div>' +
				'</div>' +
				'<div class="row custom-paper-size">' +
				'<div class="col-md-2"><label>Paper Length (mm):</label></div><div class="col-md-1 no-l-padding"><input type="number" name="paper_length" value="330" placeholder="Paper Length"></div>' +
				'<div class="col-md-2"><label>Paper Width (mm):</label></div><div class="col-md-1 no-l-padding"><input type="number" name="paper_width" value="210" placeholder="Paper Width"></div>' +
				'</div>' +
				'<div class="row">' +
				'<div class="col-md-2"><label>Paper Left Margin:</label></div><div class="col-md-1 no-l-padding"><input type="number" name="margin_left" value="30" placeholder="left"></div>' +
				'<div class="col-md-2"><label>Paper Top Margin:</label></div><div class="col-md-1 no-l-padding"><input type="number" name="margin_top" value="40" placeholder="top"></div>' +
				'<div class="col-md-2"><label>Paper Bottom Margin:</label></div><div class="col-md-1 no-l-padding"><input type="number" name="margin_bottom" value="40" placeholder="bottom"></div>' +
				'<div class="col-md-2"><label>Line Spacing:</label></div><div class="col-md-1 no-l-padding"><input type="number" name="line_spacing" value="' +
				Records.printConfigs.lineSpacing +
				'" step="0.1" placeholder="Line Spacing"></div>' +
				'</div>';

			var fields = Records.printOptions.fieldNames;
			var fieldValues = Records.printOptions.fieldKeys;

			var widths = Records.printOptions.defaultFieldWidths;

			var fieldSelectHtml = '<div class="row">';
			for (var i = 0; i < fields.length; i++) {
				fieldSelectHtml += '<div class="col-xs-4 col-md-2">' + '<select data-index="' + i + '">';
				fieldSelectHtml += '<option value=""> </option>';
				for (var j = 0; j < fields.length; j++) {
					fieldSelectHtml += '<option value="' + fieldValues[j] + '" ' + (i === j ? 'selected="selected" ' : '') + '>' + fields[j] + '</option>';
				}
				fieldSelectHtml +=
					'</select>' +
					'</div>' +
					'<div class="col-xs-2 col-md-1 no-l-padding">' +
					'<input name="width_' +
					i +
					'"type="number" value=' +
					widths[fieldValues[i]] +
					' />' +
					'</div>';
			}
			fieldSelectHtml += '</div>';

			printConfigHtml += '<div class="fields-section">' + fieldSelectHtml + '</div>';

			printConfigHtml += '<iframe id="print-preview" src="about:blank"></iframe>';
			printConfigHtml += '</div>';

			var printBtnHtml =
				'<button id="export_records_btn" class="button btn btn-primary">\n\
						        <span><span>Export</span></span>\n\
					        </button>' +
				'<button id="print_records_btn" class="button btn btn-primary">\n\
						        <span><span>Print</span></span>\n\
					        </button>';
			var printConfigObj = {
				contentHtml: printConfigHtml,
				id: 'print_config',
				title: 'Print Records',
				headerBtnHtml: printBtnHtml,
				afterAppend: function () {
					$('#font_size')
						.find('option[value="' + Records.printConfigs.fontSize + '"]')
						.attr('selected', 'selected');
				},
			};

			overlay_panel.showPanel(printConfigObj);

			$('#print_records_btn').click(function () {
				updatePrintPreview(null, true);
			});

			$('#export_records_btn').click(function () {
				updatePrintPreview(null, true, true);
			});
		}

		function getSelectedPrintConfigs() {
			var printConfigs: any = {
				page_size: $('#print-configs #page_size').val() || ('a4' as any),
				unit: 'pt',
				page_orientation: $('#print-configs #page_orientation').val() || 'l',
				font_size: parseInt($('#print-configs #font_size').val() as string) || 12,
				font_type: $('#print-configs #font_type').val() || 'helvetica',
				short_header: $("#print-configs input[name='short_header']").val(),
				margins: {
					left: parseInt($("#print-configs input[name='margin_left']").val() as string) || 30,
					right: 0, //parseInt($("#print-configs input[name='margin_right']").val()),
					top: parseInt($("#print-configs input[name='margin_top']").val() as string) || 40,
					bottom: parseInt($("#print-configs input[name='margin_bottom']").val() as string) || 40,
					line_spacing: parseFloat($("#print-configs input[name='line_spacing']").val() as string) || 1.8,
				},
				headers: [],
			};

			if (printConfigs.page_size === 'custom') {
				$('#print-configs .custom-paper-size').show();

				var paperLength = Math.floor((parseInt($("#print-configs input[name='paper_length']").val() as any) * 17) / 6 + 1);
				var paperWidth = Math.floor((parseInt($("#print-configs input[name='paper_width']").val() as any) * 17) / 6 + 1);

				printConfigs.page_size = [paperLength, paperWidth];
			} else {
				$('#print-configs .custom-paper-size').hide();
			}

			var columnCount = 0;
			var maxColumnCount = 100;
			$('#print-configs .fields-section select').each(function () {
				if (columnCount >= maxColumnCount) {
					return;
				}
				var value = $(this).val();
				if (!value) {
					return;
				}
				var id = $(this).attr('data-index');
				var width: any = $("#print-configs .fields-section input[name='width_" + id + "']").val();
				if (width <= 0) {
					return;
				}
				var prompt = $(this)
					.find("option[value='" + value + "']")
					.html();
				printConfigs['headers'].push({
					name: value,
					prompt: prompt,
					width: parseInt(width),
				});
				columnCount++;
			});

			return printConfigs;
		}

		function updatePrintPreview(e?, autoPrint?, exportRecords?, previewRecords?) {
			var data = JSON.parse(JSON.stringify(stateMap.allRecords));

			if (typeof autoPrint === 'undefined' && previewRecords !== 0) {
				data = data.slice(0, previewRecords || 50);
			}

			var configs = getSelectedPrintConfigs();

			data = Records.printOptions.beforePrintHook(data, exportRecords);

			var page = new jsPDF({ orientation: configs.page_orientation, lineHeight: configs.margins.line_spacing }, configs.unit, configs.page_size, false);
			page.setFont(configs.font_type, 'normal');

			page.setPageBriefHeader({
				showDate: true,
				fontSize: configs.font_size + 3,
				shortBottomHeader: configs.short_header,
				showPageNo: true,
			});

			page.table(configs.margins.left, configs.margins.top, data, configs.headers, {
				printHeaders: true,
				fontSize: configs.font_size,
				autoSize: false,
				margins: configs.margins,
			});

			if (typeof autoPrint !== 'undefined' && autoPrint === true) {
				if (typeof exportRecords !== 'undefined' && exportRecords === true) {
					onExportRecords(data);
				} else {
					page.autoPrint();
					var now = new Date();
					var fileName = configs.short_header + ' ' + utils.formatDate(now) + ' ' + now.toLocaleTimeString();
					page.save(fileName);
				}
			} else {
				var url = page.output('datauristring');
				$('#print-preview').attr('src', url);
			}
		}

		function onPrintRecords() {
			applyFiltersAndReposition(false);

			showPrintConfigScreen();

			$('#print-configs select').on('change', updatePrintPreview);
			$('#print-configs input').on('change', updatePrintPreview);

			updatePrintPreview();
			return;
		}

		function onExportRecords(data) {
			utils.showLoading(true);

			if (typeof data === 'undefined') {
				data = JSON.parse(JSON.stringify(stateMap.allRecords));
			}
			var now = new Date();
			var fileName = Records.exportOptions.defaultFileName + ' ' + utils.formatDate(now) + ' ' + now.toLocaleTimeString();
			utils.JSONToCSVConvertor(data, true, fileName, Records.exportOptions.fieldKeys);
			utils.showLoading(false);
		}

		function showDashboard() {
			var dashboardObj = {
				contentHtml: Records.dashboardOptions.html,
				id: 'dashboard',
				title: Records.dashboardOptions.title,
			};

			overlay_panel.showPanel(dashboardObj);

			Records.dashboardOptions.populateCharts($('#dashboard-container'));
		}

		function getRecordDiff(record1, record2) {
			var record = {};
			record['Id'] = record1[Records.IdFieldName];
			for (var field in record1) {
				if (record1[field] === null && record2[field] === '') {
					record2[field] = null;
				}
				if (record1[field] !== undefined && record2[field] !== undefined && record1[field] != record2[field]) {
					record[field] = record1[field] + ' --> ' + record2[field];
				} else if (field === Records.IdentifyingFieldName) {
					record[field] = record1[field];
				}
			}
			return record;
		}

		function onLogout() {
			if (anand.workOffline() === true) {
				notification.showBottomNotification("Can't logout in offline mode");
				return;
			}
			utils.showLoading(true);
			let Model = ModelClass.Instance(stateMap.systemId);
			Model.logout(function () {
				window.location.reload();
			});
		}

		function cacheImagesRecurs(allRecords, i) {
			if (i >= allRecords.length) {
				utils.showLoading(false);
				return;
			}
			setImageSrc($('<img></img>'), allRecords[i]['image'], allRecords[i]['image_update_time'], function () {
				cacheImagesRecurs(allRecords, i + 1);
			});
		}

		function cacheImages() {
			if (anand.workOffline() === true) {
				notification.showBottomNotification('You are in offline mode!');
				return;
			}
			utils.showLoading(true);

			let Model = ModelClass.Instance(stateMap.systemId);
			var allRecords = Model.getRecords([], stateMap.defaultSortOrder, null);
			cacheImagesRecurs(allRecords, 0);
		}

		function playAudio(file) {
			if (typeof stateMap.audioElement === 'undefined') {
				stateMap.audioElement = document.createElement('audio');
				stateMap.audioElement.setAttribute('autoplay', 'autoplay');
			} else {
				stateMap.audioElement.pause();
			}

			if (typeof file !== 'undefined') {
				stateMap.audioElement.setAttribute('src', file);
				stateMap.audioElement.play();
			}
		}

		function pauseAudio() {
			if (typeof stateMap.audioElement !== 'undefined') {
				stateMap.audioElement.pause();
			}
		}

		function clearSearch() {
			stateMap.searchQueryArr = [];
			stateMap.searchSpecificQueryArr = [];
			$('#search-section input').val('');
			$('#search-section .abc-filter').val('');
			stateMap.searchTermFilters = [];
			stateMap.searchSpecificFilters = [];
			stateMap.abcFilter = [];
			applyFilters();
		}

		function getEndDate(queryDateStr) {
			var date = new Date(queryDateStr);
			var year = date.getFullYear();
			var month = date.getMonth();

			var queryArr = queryDateStr.split(' ');
			if (queryArr.length === 1) {
				date = new Date(year, 11, 31);
			} else if (queryArr.length === 2) {
				if (month === 11) {
					date = new Date(year + 1, 0, 1);
				} else {
					date = new Date(year, month + 1, 1);
				}
				date = new Date(date.getTime() - 24 * 60 * 60 * 1000);
			} else {
				date = new Date(queryDateStr);
			}
			return date;
		}

		function getDateStr(date) {
			var year = date.getFullYear();
			var month = date.getMonth() + 1;
			if (month < 10) {
				month = '0' + month;
			}
			var day = date.getDate();
			if (day < 10) {
				day = '0' + day;
			}

			return year + '-' + month + '-' + day;
		}

		function getDatePartialStringForSearch(query) {
			//Date format is yyyy-mm-dd
			var monthArr = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
			if (monthArr.indexOf(query?.toLowerCase()) > -1) {
				var month: any = monthArr.indexOf(query?.toLowerCase()) + 1;
				if (month < 10) {
					month = '0' + month;
				}
				return '-' + month + '-';
			} else if (query < 1000) {
				//Check if its an year or date, basically 4 digits
				var day = query;
				if (day < 10) {
					day = '0' + day;
				}
				return '-' + day;
			} else {
				return query + '-';
			}
		}

		function getFilterFromPartialDateStr(dateStr, filterPrefix) {
			var firstChar = dateStr.charAt(0);
			var lastChar = dateStr.charAt(dateStr.length - 1);

			var filter = {};
			if (firstChar === '-' && lastChar === '-') {
				filter[filterPrefix + 'likenocase'] = dateStr;
			} else if (firstChar === '-') {
				filter[filterPrefix + 'rightnocase'] = dateStr;
			} else {
				filter[filterPrefix + 'leftnocase'] = dateStr;
			}

			return filter;
		}

		function clearSpecificSearchBy(searchBy) {
			for (var i = 0; i < stateMap.searchSpecificFilters.length; i++) {
				if (typeof stateMap.searchSpecificFilters[i] === 'undefined') {
					continue;
				}
				if (typeof stateMap.searchSpecificFilters[i][searchBy] !== 'undefined') {
					delete stateMap.searchSpecificFilters[i][searchBy];
				}
				if ($.isEmptyObject(stateMap.searchSpecificFilters[i])) {
					delete stateMap.searchSpecificFilters[i];
				}
			}
		}

		function onSpecificSearch(this: any) {
			var searchBy: any = $(this).attr('data-searchby');
			var query = $.trim($(this).val() as string);

			if (Records.hooks.specificSearchHook !== null) {
				clearSpecificSearchBy(searchBy);
				var result = Records.hooks.specificSearchHook(searchBy, query);
				searchBy = result.searchBy;
				query = result.query;
				clearSpecificSearchBy(searchBy);
			} else {
				clearSpecificSearchBy(searchBy);
			}

			var filterPrefix = '';
			if (query && query[0] === '!') {
				filterPrefix = '!';
				query = query.substr(1);
			}

			if (query === 'true') {
				stateMap.searchSpecificFilters.push({ [searchBy]: { isNull: false, isUndefined: false } });
			} else if (query === 'false') {
				stateMap.searchSpecificFilters.push([{ [searchBy]: { isUndefined: true } }, { [searchBy]: { isNull: true } }]);
			} else if (Records.isSearchDateTypeColumn(searchBy) === true || Records.isSearchRangeTypeColumn(searchBy) === true) {
				var start: any = null;
				var end: any = null;

				var startEnd = query.split('-');
				start = startEnd[0].trim();
				if (startEnd.length > 1) {
					end = startEnd[1].trim();
				}

				if (start && end) {
					var startDateStr = start;
					var endDateStr = end;

					if (Records.isSearchDateTypeColumn(searchBy) === true) {
						startDateStr = getDateStr(new Date(start));
						endDateStr = getDateStr(getEndDate(end));
					} else {
						startDateStr = parseInt(start);
						endDateStr = parseInt(end);
					}

					var filter = {};
					filter[searchBy] = {};
					filter[searchBy][filterPrefix + 'gte'] = startDateStr;
					stateMap.searchSpecificFilters.push(filter);

					var filter = {};
					filter[searchBy] = {};
					filter[searchBy][filterPrefix + 'lte'] = endDateStr;
					stateMap.searchSpecificFilters.push(filter);
				} else {
					let phrase = start ? start : end;

					if (!phrase) {
						stateMap.searchSpecificQueryArr = populateSpicificSearchArr();
						applyFilters();
						return;
					}

					if (!Records.isSearchDateTypeColumn(searchBy)) {
						var filter = {};

						if (query.indexOf('-') < 0) {
							var searchFilter = $(this).attr('data-searchfilter');
							if (!searchFilter) {
								searchFilter = 'likenocase';
							}

							if (searchFilter === 'in') {
								filter[searchBy] = phrase;
							} else {
								filter[searchBy] = {};
								filter[searchBy][filterPrefix + searchFilter] = phrase;
							}

							stateMap.searchSpecificFilters.push(filter);
						} else if (start) {
							var filter = {};
							filter[searchBy] = {};
							filter[searchBy][filterPrefix + 'gte'] = parseInt(start);
							stateMap.searchSpecificFilters.push(filter);
						} else if (end) {
							filter[searchBy] = {};
							filter[searchBy][filterPrefix + 'lte'] = parseInt(end);
							stateMap.searchSpecificFilters.push(filter);
						}
					} else {
						var filter = {};

						var queryArr = query.split(' ');

						if (queryArr.length === 1) {
							query = getDatePartialStringForSearch(query);
						} else if (queryArr.length === 2) {
							var part1 = getDatePartialStringForSearch(queryArr[0]);
							var part2 = getDatePartialStringForSearch(queryArr[1]);
							query = part2 + part1.substr(1);
						} else {
							query = getDateStr(new Date(query));
						}

						if (typeof Records.searchFieldsMap[searchBy] === 'object') {
							filter[searchBy] = {};
							filter[searchBy][filterPrefix + Records.searchFieldsMap[searchBy]['filter']] = query;
						} else {
							filter[searchBy] = getFilterFromPartialDateStr(query, filterPrefix);
						}
						stateMap.searchSpecificFilters.push(filter);
					}
				}
			} else {
				if (!query) {
					stateMap.searchSpecificQueryArr = populateSpicificSearchArr();
					applyFilters();
					return;
				}

				var filter = {};
				var searchFilter = $(this).attr('data-searchfilter');
				if (!searchFilter) {
					searchFilter = 'likenocase';
				}
				if (searchFilter === 'in') {
					filter[searchBy] = query;
				} else {
					filter[searchBy] = {};
					filter[searchBy][filterPrefix + searchFilter] = query;
				}
				stateMap.searchSpecificFilters.push(filter);
			}

			stateMap.searchSpecificQueryArr = populateSpicificSearchArr();
			applyFilters();
		}

		function populateSpicificSearchArr() {
			var result: any[] = [];
			for (var i = 0; i < stateMap.searchSpecificFilters.length; i++) {
				var filter = stateMap.searchSpecificFilters[i];

				if (typeof filter === 'undefined') {
					continue;
				}

				for (var key in filter) {
					for (var key2 in filter[key]) {
						result.push(filter[key][key2]);
					}
				}
			}
			return result;
		}

		function configureSearch() {
			stateMap.searchQueryArr = [];
			stateMap.searchSpecificQueryArr = [];
			stateMap.searchTermFilters = [{}];
			stateMap.searchSpecificFilters = [{}];

			var searchFieldsMap = Records.searchFieldsMap;
			for (var key in searchFieldsMap) {
				var searchElement = $('#searching-element').clone();
				searchElement.removeAttr('id');

				var inputField = searchElement.find('input');
				inputField.attr('data-searchby', key);
				if (typeof searchFieldsMap[key] === 'object') {
					inputField.attr('data-searchfilter', searchFieldsMap[key]['filter']);
					inputField.attr('placeholder', searchFieldsMap[key]['text']);
				} else {
					inputField.attr('placeholder', searchFieldsMap[key]);
				}

				searchElement.show();
				$('.specific-search-section').append(searchElement);
			}

			$('#search-section .specific-search-btn .alt-icon').toggle();
			$('#search-section .specific-search-btn').click(function () {
				$(this).find('.alt-icon').toggle();
				$(this).find('.default-icon').toggle();

				$('.specific-search-section').slideToggle();

				setTimeout(() => {
					$('.app-bar-ph').height($('header').height());
				}, 500);
			});

			var event = 'keyup';
			if (isMobile()) {
				event = 'change';
			}

			$('.specific-search-section input').on(event, onSpecificSearch);
			//		$('#search-section .specific-search-section input.date').on("change", onSpecificSearch);

			$('#search-section .clear-search-btn').click(clearSearch);

			$('#search-text-input input').on(event, function () {
				var query: any = $(this).val();
				var queryArr = query.split(' ');

				stateMap.searchQueryArr = [];

				var filtersArr: any[] = [];
				for (var i = 0; i < queryArr.length; i++) {
					query = queryArr[i];

					if (!query) {
						continue;
					}

					stateMap.searchQueryArr.push(query);

					var filters: any = Records.getAnySearchFilter(query);
					filtersArr.push(filters);
				}

				stateMap.searchTermFilters = filtersArr;
				applyFilters();
			});

			$('#find-text-input input').keypress(function (event) {
				var keycode = event.keyCode ? event.keyCode : event.which;
				if (keycode != 13) {
					return;
				}

				var query: any = $(this).val();
				query = query.trim();
				if (!query || !query.length) {
					stateMap.options.props.props.repositionRecords(0);
					return;
				}

				if (query.startsWith('$')) {
					let index = query.substr(1);
					index = index.length ? index : '0';
					stateMap.options.props.props.repositionRecords(parseInt(index) - 1);
					return;
				}

				var filters;
				if (query.startsWith('#')) {
					let name = query.substr(1);
					filters = [{ name: { leftnocase: name } }];
				} else if (query.startsWith('-')) {
					let oldname = query.substr(1);
					filters = [{ old_name: { leftnocase: oldname } }];
				} else {
					filters = Records.getAnySearchFilter(query);
				}

				let matchingRecords = stateMap.allRecords.filter((record) => {
					return isMatch(filters, record);
				});

				if (matchingRecords.length) {
					let matchingId = matchingRecords[0][Records.IdFieldName];
					let index = stateMap.allRecords.findIndex((record) => record[Records.IdFieldName] === matchingId);
					stateMap.options.props.props.repositionRecords(index);
				}
			});
		}

		function isMatch(filters, record) {
			for (let i = 0; i < filters.length; i++) {
				let filter = filters[i];
				let column = Object.keys(filter)[0];
				let columnFilter = filter[column];
				let filterCriteria = Object.keys(columnFilter)[0];
				let query = columnFilter[filterCriteria];

				switch (filterCriteria) {
					case 'leftnocase':
						if (record[column]?.toLowerCase().startsWith(query.toLowerCase())) {
							return true;
						}
						break;
					case 'likenocase':
						if (record[column]?.toLowerCase().indexOf(query.toLowerCase()) > -1) {
							return true;
						}
				}
			}
			return false;
		}

		function highlightSearchTerms(selector) {
			var queryArr = stateMap.searchSpecificQueryArr.concat(stateMap.searchQueryArr);
			if (queryArr.length !== 0 && typeof queryArr[0] === 'string') {
				selector.highlight(queryArr);
			}
		}

		async function filterRecords(filteredData, reposition = false) {
			$('#page-records .records .filtered-records').empty();
			stateMap.allRecords = [];

			($(Records.briefTileSelector) as any).removeHighlight();
			($(Records.detailTileSelector) as any).removeHighlight();

			await populateRecords(filteredData);

			if (Records.updateRecordsState) {
				await Records.updateRecordsState(filteredData, reposition);
			}

			// highlightSearchTerms($(Records.briefTileSelector));
			// highlightSearchTerms($(Records.detailTileSelector));
		}

		function getCombinedFilters() {
			var filters = stateMap.searchTermFilters.concat(stateMap.searchSpecificFilters);

			if (Records.getAdditionalSearchFilters !== null) {
				filters = filters.concat(Records.getAdditionalSearchFilters());
			}
			return filters;
		}

		function applyFilters(extraFilter?, callback?, forceReload?, reposition = false) {
			if (!Records.viewAllowed) {
				return;
			}

			var filters = getCombinedFilters();
			stateMap.currentPage = 0;

			if (extraFilter) {
				filters = filters.concat(extraFilter);
			}
			if (stateMap.showingSelected === true) {
				filters = filters.concat([{ selected: true }]);
				notification.showBottomNotification('Showing Only Selected Records', true);
			} else if (stateMap.showingUnSelected === true) {
				filters = filters.concat([{ selected: false }]);
				notification.showBottomNotification('Showing Only UnSelected Records', true);
			}

			let Model = ModelClass.Instance(stateMap.systemId);
			Model.getRecords(
				filters,
				stateMap.sortOrder,
				async function (data) {
					await filterRecords(data, reposition);
					if (callback) {
						callback();
					}
				},
				false,
				forceReload
			);
		}

		function enableImageBrowser(tile, imageSelector) {
			if (imageSelector === '') {
				return;
			}
			tile.find(imageSelector).on('change', function (this: any) {
				var $target = $(this).parent().find('img');
				var reader = new FileReader();
				// when image is loaded, set the src of the image where you want to display it
				reader.onload = function (event) {
					$target.attr('src', event?.target?.result as any);
				};

				reader.readAsDataURL(this.files[0]);
			});
		}

		function configureAddRecord() {
			$('.add-record-btn').click(onAddRecord);
		}

		function cancelAddRecord() {
			if (stateMap.curDialog) {
				stateMap.curDialog.close();
				stateMap.curDialog = null;
			}
		}

		function addRecord(newRecord, addTile) {
			utils.showLoading(true);
			let Model = ModelClass.Instance(stateMap.systemId);
			Model.addRecord(newRecord, function (record) {
				utils.showLoading(false);
				if (Records.hooks.afterAddRecord !== null) {
					Records.hooks.afterAddRecord(record, addTile);
				}
				cancelAddRecord();
				notification.showBottomNotification('Record added successfully');
				applyFiltersAndReposition();
			});
		}

		function onAddRecord(event, record, $form?) {
			stateMap.imageName = null;

			var addTile;
			if (typeof stateMap.addTile === 'undefined' && !$form) {
				addTile = Records.addFormHTML.length ? $(Records.addFormHTML) : $(Records.addTileSelector);

				if (Records.hooks.afterAddTileCreate !== null) {
					addTile = Records.hooks.afterAddTileCreate(addTile);
				}
				addTile.find('.record-id').hide();
				addTile.show();

				stateMap.addTile = addTile;
			} else {
				if ($form) {
					stateMap.addTile = addTile = $form;
				} else {
					addTile = stateMap.addTile;
				}
				addTile.find('input').val('');
				addTile.find('select').find('option:eq(0)').prop('selected', true);
				addTile.find('textarea').val('');

				addTile.find('.multi-field-row.visible').remove();
			}

			addTile.validate({
				errorClass: 'invalid-field-msg',
				rules: stateMap.addTileFormValidationRules,
				submitHandler: function (form) {
					var newRecord = ($(form) as any).serializeObject();
					let recordId = generateRecordId(stateMap.systemId); //(parseInt(recordsInRange.max('id') ?? `${range.min - 1}`) + 1).toString(); //generateRecordId(this.stateMap.systemId);
					newRecord[Records.IdFieldName] = recordId;

					var fileInputs: any = $(form).find('input[type="file"]');
					newRecord['fileInputs'] = {};
					for (var i = 0; i < fileInputs.length; i++) {
						if (!fileInputs[i]) {
							continue;
						}

						var name = fileInputs[i].name;
						newRecord['fileInputs'][name] = newRecord['fileInputs'][name] ?? [];
						let files = fileInputs[i].files;
						newRecord['fileInputs'][name].push(files);

						if (files && files.length && Records.isImageTypeColumn(name)) {
							let fileObj = files[0];
							let extension = fileObj.name.substring(fileObj.name.lastIndexOf('.'));
							newRecord[name] = recordId + '__' + name + extension;

							uploadFile(stateMap.systemId + '/images/' + newRecord[name], fileObj);
						}
					}
					addRecord(newRecord, addTile);
					return false;
				},
			});

			addTile.find('.date').datepicker({
				format: 'D, dd M yyyy',
				autoclose: true,
				//                startView: 1,
				todayHighlight: true,
				todayBtn: 'linked',
			});

			configureMultiAddFields(addTile);
			Records.setImageSrc(addTile.find('img'), null, '/images/');
			configureTelInput(addTile);

			if (Records.hooks.beforeAddTileShow !== null) {
				addTile = Records.hooks.beforeAddTileShow(addTile, record);
			}

			enableImageBrowser(addTile, Records.imageUploadSelector);

			if (!$form) {
				stateMap.curDialog = BootstrapDialog.show({
					type: BootstrapDialog.TYPE_PRIMARY,
					size: BootstrapDialog.SIZE_WIDE,
					title: 'Add Record',
					message: addTile,
					cssClass: 'tile-dialog',
					buttons: [
						{
							label: 'Add Now',
							cssClass: 'btn-primary',
							action: function (dialog) {
								dialog.getMessage().submit();
							},
						},
					],
				});
			}
		}

		async function populateRecordsByPage(pageNo) {
			var startIndex = pageNo * stateMap.recordsPerPage;
			var endIndex = startIndex + stateMap.recordsPerPage;

			var records = $('<div></div>');

			let tileRefs: Promise<any>[] = [];
			for (var index = startIndex; index < endIndex && index < stateMap.allRecords.length; index++) {
				if (stateMap.isMobile) {
					tileRefs.push(getBriefTile(stateMap.allRecords[index], index));
				} else {
					tileRefs.push(getDetailTile(stateMap.allRecords[index], index));
				}
			}

			let tiles = await Promise.all(tileRefs);
			tiles.forEach((tile) => {
				if (!stateMap.isMobile && tile.expansionAllowed !== false) {
					tile.find('.row').click(expandTile);
				}
				records.append(tile);
			});

			$('#page-records .records .filtered-records').append(records);
			highlightSearchTerms(records);
			highlightSearchTerms(records);
		}

		async function updateTotalCount(addCount) {
			stateMap.totalRecords += addCount;
			$('#record_count span').html((stateMap.isMobile ? '# ' : 'Total Records: ') + stateMap.totalRecords);

			if (Records.updateRecordCount) {
				await Records.updateRecordCount();
			}
		}

		async function populateRecords(data) {
			stateMap.allRecords = data;
			stateMap.totalRecords = stateMap.allRecords.length;
			await updateTotalCount(0);
			// await populateRecordsByPage(0);
			// $(window).scroll();
		}

		async function getBriefTile(record, index) {
			var sampleTile = Records.briefTileHTML.length ? $(Records.briefTileHTML) : $(Records.briefTileSelector).clone();
			sampleTile.attr('id', 'brief-record-' + record[Records.IdFieldName]);

			sampleTile.find('.record-id span').html((parseInt(index) + 1).toString());
			sampleTile.attr('data-index', index);

			sampleTile = Records.populateBriefTile(sampleTile, record);

			sampleTile.show();
			sampleTile.find('.row').click(expandTile);
			return sampleTile;
		}

		async function expandTile(this: any, event) {
			if (isOnlyMjBjSite()) {
				return;
			}

			var index = $(this).parent().attr('data-index');
			// var index = $(this).parents().find('[data-index]').data('index');
			if (typeof index === 'undefined') {
				return;
			}
			var record = stateMap.allRecords[index];
			var detailTile = await getDetailTile(record, index);

			var buttons = [
				{
					label: 'Edit',
					cssClass: 'btn-primary',
					action: function (dialog) {
						onEditRecord(dialog.getMessage());
					},
				},
				{
					label: record.deleted ? 'Restore' : 'Delete',
					cssClass: record.deleted ? 'btn-warning' : 'btn-danger',
					action: function (dialog) {
						onDeleteRecord(dialog.getMessage());
					},
				},
			];

			if (!Records.editAllowed) {
				buttons = [];
			}

			var extraButtons = Records.getExtraDetailTileButtons ? Records.getExtraDetailTileButtons(record) : [];
			if (extraButtons && extraButtons.length > 0) {
				for (var i = 0; i < extraButtons.length; i++) {
					if (extraButtons[i].enable !== false) {
						buttons.push(extraButtons[i]);
					}
				}
			}

			stateMap.curDialog = BootstrapDialog.show({
				type: BootstrapDialog.TYPE_PRIMARY,
				size: BootstrapDialog.SIZE_WIDE,
				title: 'Record Details',
				message: detailTile,
				cssClass: 'tile-dialog',
				buttons: buttons,
			});

			//		if (!Records.editAllowed) {
			//			stateMap.curDialog.enableButtons(false);
			//		}
		}

		function onEditRecord(detailTile) {
			stateMap.imageName = null;

			var index = detailTile.attr('data-index');
			var record = stateMap.allRecords[index];

			var detailTileForm = populateDetailTileForm(record, index);
			detailTileForm.show();

			detailTileForm.validate({
				errorClass: 'invalid-field-msg',
				rules: stateMap.addTileFormValidationRules,
				submitHandler: function (form) {
					var newRecord = ($(form) as any).serializeObject();
					let recordId = record[Records.IdFieldName];

					var fileInputs: any = $(form).find('input[type="file"]');
					newRecord['fileInputs'] = {};
					for (var i = 0; i < fileInputs.length; i++) {
						if (!fileInputs[i]) {
							continue;
						}

						var name = fileInputs[i].name;
						newRecord['fileInputs'][name] = newRecord['fileInputs'][name] ?? [];
						let files = fileInputs[i].files;
						newRecord['fileInputs'][name].push(files);

						if (files && files.length && Records.isImageTypeColumn(name)) {
							let fileObj = files[0];
							let extension = fileObj.name.substring(fileObj.name.lastIndexOf('.'));
							newRecord[name] = recordId + '__' + name + extension;

							uploadFile(stateMap.systemId + '/images/' + newRecord[name], fileObj);
						}
					}
					editRecord();

					// function loadFile(name, index, files, callback) {
					// 	if (files && files.length > 0) {
					// 		var reader = new FileReader();
					// 		reader.onload = function (event) {
					// 			newRecord['image_dataurl'][name][index] = event?.target?.result;
					// 			callback();
					// 		};

					// 		reader.readAsDataURL(files[0]);
					// 	} else {
					// 		newRecord['image_dataurl'][name][index] = null;
					// 		callback();
					// 	}
					// }
					return false;

					function editRecord() {
						newRecord[Records.IdFieldName] = record[Records.IdFieldName];
						// if (Records.imageFieldName) {
						// 	newRecord[Records.imageFieldName] = record[Records.imageFieldName];
						// }
						utils.showLoading(true);
						let Model = ModelClass.Instance(stateMap.systemId);
						Model.editRecord(newRecord, record[Records.IdFieldName], function (record) {
							stateMap.curDialog.close();
							notification.showBottomNotification('Record modified successfully');
							utils.showLoading(false);
							if (Records.hooks.afterEditRecord !== null) {
								Records.hooks.afterEditRecord(newRecord);
							}
							applyFiltersAndReposition();
						});
					}
				},
			});

			if (stateMap.curDialog) {
				stateMap.curDialog.close();
			}

			stateMap.curDialog = BootstrapDialog.show({
				type: BootstrapDialog.TYPE_WARNING,
				size: BootstrapDialog.SIZE_WIDE,
				title: 'Edit Record',
				message: detailTileForm,
				cssClass: 'tile-dialog',
				buttons: [
					{
						label: 'Save',
						cssClass: 'btn-warning',
						action: function (dialog) {
							dialog.getMessage().submit();
						},
					},
					{
						label: 'Cancel',
						cssClass: 'btn-primary',
						action: function (dialog) {
							dialog.close();
						},
					},
				],
			});
		}

		function applyFiltersAndReposition(forceReload?) {
			// var curScrollTop: any = $(window).scrollTop();
			applyFilters([{}], null, forceReload, true);

			// var repCount = 0;
			// var reposition = function () {
			// 	$('body, html').animate(
			// 		{
			// 			scrollTop: curScrollTop,
			// 		},
			// 		500,
			// 		'linear',
			// 		function () {
			// 			if (($(window).scrollTop() ?? 0) < curScrollTop && repCount++ < 4) {
			// 				reposition();
			// 			}
			// 		}
			// 	);
			// };
			// reposition();
		}

		function onDeleteRecord(detailTile) {
			var index = detailTile.attr('data-index');
			var record = stateMap.allRecords[index];

			if (record.deleted) {
				utils.showLoading(true);
				let Model = ModelClass.Instance(stateMap.systemId);
				Model.editRecord({ deleted: false }, record.id, () => {
					utils.showLoading(false);
					stateMap.curDialog.close();
					notification.showBottomNotification('Record restored successfully');
					applyFiltersAndReposition();
				});
				return;
			}

			BootstrapDialog.show({
				type: BootstrapDialog.TYPE_DANGER,
				title: 'Delete Record',
				message: 'Are you sure you want to DELETE this record?',
				buttons: [
					{
						label: 'Yes, Delete!',
						cssClass: 'btn-danger',
						action: function (dialog) {
							utils.showLoading(true);
							let Model = ModelClass.Instance(stateMap.systemId);
							Model.deleteRecord(record, record[Records.IdFieldName], function () {
								utils.showLoading(false);
								dialog.close();
								stateMap.curDialog.close();
								notification.showBottomNotification('Record deleted successfully');
								applyFiltersAndReposition();
							});
						},
					},
					{
						label: 'Cancel',
						cssClass: 'btn-primary',
						action: function (dialog) {
							dialog.close();
						},
					},
				],
			});
		}

		async function setImageSrc($imgDiv, image, path, callback?) {
			if (typeof image === 'undefined') {
				image = null;
			}

			if (image === null) {
				$imgDiv.attr('src', getStorageUrl('images/null'));
				if (callback !== undefined) {
					callback();
				}
			} else if (typeof image === 'string' && image.indexOf('data:') === 0) {
				$imgDiv.attr('src', image);
				if (callback !== undefined) {
					callback();
				}
			} else if (image) {
				let url = await getDownloadUrl(path + image);

				$imgDiv.attr('src', url ?? getStorageUrl('images/null'));
				$imgDiv.on('error', async function () {
					let url = await getDownloadUrl(path + image, true);
					$imgDiv.attr('src', url ?? getStorageUrl('images/null'));
				});
				if (callback !== undefined) {
					callback();
				}
			}
		}

		async function getDetailTile(record, index, waitOnImage = false) {
			var sampleTile = Records.detailTileHTML.length ? $(Records.detailTileHTML) : $(Records.detailTileSelector).clone();

			sampleTile.attr('id', 'detail-record-' + record[Records.IdFieldName]);

			sampleTile.find('.record-id span').html((parseInt(index) + 1).toString());
			sampleTile.attr('data-index', index);

			sampleTile = await Records.populateDetailTile(sampleTile, record, waitOnImage);

			var selectTileCheckbox = $("<div class='select-tile hidden-xs hidden-sm'><input type='checkbox'></input></div>");

			selectTileCheckbox.find('input').click(async () => await onCheckboxClick(sampleTile));

			if (typeof record.selected === 'undefined') {
				record.selected = false;
			} else if (record.selected) {
				selectTileCheckbox.find('input').attr('checked', 'checked');
				sampleTile.addClass('selected');
			}

			sampleTile.append(selectTileCheckbox);
			sampleTile.show();
			return sampleTile;
		}

		function isSelectionFilterPresent() {
			return stateMap.showingUnSelected === true || stateMap.showingSelected === true;
		}

		async function onCheckboxClick(sampleTile) {
			var index: any = $(this).parent().parent().attr('data-index');
			var record = stateMap.allRecords[index];
			record.selected = !record.selected;
			if (record.selected) {
				sampleTile.addClass('selected');
			} else {
				sampleTile.removeClass('selected');
			}

			if (stateMap.showingUnSelected === true || stateMap.showingSelected === true) {
				updateTotalCount(-1);
				sampleTile.remove();
				var recordsRemoved = stateMap.allRecords.length - stateMap.totalRecords;
				var recordsPopulatedSoFar = (stateMap.currentPage + 1) * stateMap.recordsPerPage;
				var recordsLeftOnScreen = recordsPopulatedSoFar - recordsRemoved;
				if (recordsLeftOnScreen <= stateMap.recordsPerPage) {
					await populateNextPage();
				}
			}
		}

		function populateMultiAddFields(parentDiv, count) {
			var multiFieldAddBtn = parentDiv.find('.hidden .multi_field_add_btn');
			var multiFieldDivId = multiFieldAddBtn.attr('data-field-id');

			for (var i = 1; i < count; i++) {
				var multiFieldLastDiv = parentDiv.find('#' + multiFieldDivId + i);
				multiFieldLastDiv.find('.multi_field_add_btn').trigger('click');
			}

			return multiFieldDivId;
		}

		function configureMultiAddField(tile, multiFieldAddBtn: any) {
			multiFieldAddBtn = $(multiFieldAddBtn);
			var multiFieldDivId = multiFieldAddBtn.attr('data-field-id');

			var multiFieldHiddenDiv = tile.find('#' + multiFieldDivId);
			var multiFieldDiv = multiFieldHiddenDiv.clone();

			stateMap[multiFieldDivId + '_count'] = 1;
			multiFieldDiv.attr('id', multiFieldDivId + stateMap[multiFieldDivId + '_count']);
			multiFieldDiv.insertAfter(multiFieldHiddenDiv);
			multiFieldDiv.removeClass('hidden');
			multiFieldDiv.addClass('visible');
			multiFieldDiv.find('.date').datepicker({
				format: 'D, dd M yyyy',
				autoclose: true,
				todayHighlight: true,
				todayBtn: 'linked',
			});
			configureTelInput(multiFieldDiv);
			enableImageBrowser(multiFieldDiv, ".image-upload-container input[type='file']");

			multiFieldDiv.find('.multi_field_add_btn').off('click').click(onAddFieldRow);

			return multiFieldDivId;

			function onAddFieldRow() {
				var multiFieldLastDiv = tile.find('#' + multiFieldDivId + stateMap[multiFieldDivId + '_count']);
				var multiFieldDiv = multiFieldLastDiv.clone();
				multiFieldDiv.find('input').val('');
				multiFieldDiv.find('select').find('option:eq(0)').prop('selected', true);
				multiFieldDiv.find('.image-upload-container img').attr('src', getStorageUrl('images/null'));
				multiFieldDiv.find('.multi_field_add_btn').removeAttr('data-disable-first');

				let disableFirst = multiFieldLastDiv.find('.multi_field_add_btn').attr('data-disable-first') === 'true';

				multiFieldLastDiv.find('.multi_field_add_btn').removeClass('btn-primary');
				multiFieldLastDiv.find('.multi_field_add_btn i').removeClass('glyphicon-plus');

				if (disableFirst) {
					multiFieldDiv.find('input').each((index, el) => {
						if ($(el).attr('data-disable-first') === 'true') {
							$(el).removeAttr('disabled');
						}
					});

					multiFieldLastDiv.find('.multi_field_add_btn').addClass('btn-warning');
					multiFieldLastDiv.find('.multi_field_add_btn i').addClass('glyphicon-edit');
				} else {
					multiFieldLastDiv.find('.multi_field_add_btn').addClass('btn-danger');
					multiFieldLastDiv.find('.multi_field_add_btn i').addClass('glyphicon-minus');
				}

				multiFieldLastDiv
					.find('.multi_field_add_btn')
					.off('click')
					.click(function () {
						if (disableFirst) {
							multiFieldLastDiv.find('input').each((index, el) => {
								if ($(el).attr('data-disable-first') === 'true') {
									$(el).removeAttr('disabled');
								}
							});
							disableFirst = false;

							multiFieldLastDiv.find('.multi_field_add_btn').removeClass('btn-warning');
							multiFieldLastDiv.find('.multi_field_add_btn i').removeClass('glyphicon-edit');
							multiFieldLastDiv.find('.multi_field_add_btn').addClass('btn-danger');
							multiFieldLastDiv.find('.multi_field_add_btn i').addClass('glyphicon-minus');
						} else {
							multiFieldLastDiv.remove();
						}
					});

				stateMap[multiFieldDivId + '_count'] += 1;
				multiFieldDiv.attr('id', multiFieldDivId + stateMap[multiFieldDivId + '_count']);
				multiFieldDiv.insertAfter(multiFieldLastDiv);
				multiFieldDiv.addClass('visible');
				multiFieldDiv.find('.date').datepicker({
					format: 'D, dd M yyyy',
					autoclose: true,
					todayHighlight: true,
					todayBtn: 'linked',
				});
				configureTelInput(multiFieldDiv);
				enableImageBrowser(multiFieldDiv, ".image-upload-container input[type='file']");

				if (Records.hooks.afterAddMultiFieldRow !== null) {
					Records.hooks.afterAddMultiFieldRow(multiFieldDiv);
				}

				multiFieldDiv.find('.multi_field_add_btn').off('click').click(onAddFieldRow);
			}
		}

		function configureMultiAddFields(tile) {
			var multiFieldAddBtns = tile.find('.multi_field_add_btn');
			for (var i = 0; i < multiFieldAddBtns.length; i++) {
				configureMultiAddField(tile, multiFieldAddBtns[i]);
			}
		}

		function configureTelInput(tile) {
			if (typeof intlTelInputUtils === 'undefined') {
				return;
			}

			tile.find("input[type='tel']").intlTelInput({
				preferredCountries: ['in'],
				nationalMode: true,
				autoPlaceholder: true,
				//			utilsScript: "/application/themes/anand/assets/js/lib/IntlTelInput/utils.js"
			});

			tile.find("input[type='tel']").on('change', function (this: any) {
				$(this)
					.parents('.intl-tel-input')
					.next('input')
					.val(($(this) as any).intlTelInput('getNumber'));
			});
		}

		function populateDetailTileForm(record, index) {
			var sampleTile = Records.detailFormHTML.length ? $(Records.detailFormHTML) : $(Records.detailFormSelector).clone();
			(sampleTile.find('.date') as any).datepicker({
				format: 'D, dd M yyyy',
				autoclose: true,
				//				startView: 1,
				todayHighlight: true,
				todayBtn: 'linked',
			});
			sampleTile.attr('id', 'detail-record-' + record[Records.IdFieldName]);

			sampleTile.find('.record-id span').html((parseInt(index) + 1).toString());
			sampleTile.attr('data-index', index);

			configureMultiAddFields(sampleTile);
			configureTelInput(sampleTile);

			sampleTile = Records.populateDetailForm(sampleTile, record);

			enableImageBrowser(sampleTile, Records.imageUploadSelector);

			return sampleTile;
		}

		function prepareMultiFieldForAdd(record, fieldName: string, columnNames: string[], callback?: (obj, columnName) => void) {
			record[fieldName] = [];
			if (record[columnNames[0]]) {
				var values = record[columnNames[0]];
				for (var i = 0; i < values.length; i++) {
					if (values[i] === '') {
						continue;
					}

					let obj = {};
					for (let j = 0; j < columnNames.length; j++) {
						if (Records.isImageTypeColumn(columnNames[j], fieldName)) {
							let files = record['fileInputs'][columnNames[j]][i];

							if (files && files.length) {
								let fileObj = files[0];
								let extension = fileObj.name.substring(fileObj.name.lastIndexOf('.'));
								obj[columnNames[j]] = record[Records.IdFieldName] + '__' + columnNames[j] + '_' + (i - 1) + extension;

								uploadFile(stateMap.systemId + '/images/' + obj[columnNames[j]], fileObj);
							} else {
								let attrName = `data-image-src-${columnNames[j]}-${i - 1}`;
								let existingImage = $('[' + attrName + ']');
								if (existingImage && existingImage.length) {
									obj[columnNames[j]] = existingImage.attr(attrName);
								}
							}
						} else {
							obj[columnNames[j]] = record[columnNames[j]][i];

							if (Records.isSearchDateTypeColumn(columnNames[j])) {
								obj[columnNames[j]] = utils.formatDate(obj[columnNames[j]]);
							}
						}

						if (callback) {
							callback(obj, columnNames[j]);
						}
					}

					record[fieldName].push(obj);
				}

				for (let j = 0; j < columnNames.length; j++) {
					delete record[columnNames[j]];
				}
			}
		}

		return {
			initModule: initModule,
			setImageSrc: setImageSrc,
			refreshRecords: applyFilters,
			refreshRecordsAndReposition: applyFiltersAndReposition,
			getCurrentRecords: function () {
				return stateMap.allRecords;
			},
			populateMultiAddFields: populateMultiAddFields,
			triggerAddRecord: onAddRecord,
			triggerEditRecord: onEditRecord,
			configureTelInput: configureTelInput,
			expandTile: expandTile,
			highlightSearchTerms: highlightSearchTerms,
			isSelectionFilterPresent: isSelectionFilterPresent,

			updateRecordsState: null as unknown as (records: any[], reposition: boolean) => any,

			projectTitle: '', //*
			selectionDropdownOptionsMap: {}, //*

			getAnySearchFilter: null as unknown as (query: string) => any, //*
			defaultSortOrder: '', //*
			getReminderFilter: null as unknown as (datestr: any) => any,
			getAdditionalSearchFilters: null as unknown as () => any,
			IdFieldName: 'id', //*
			IdentifyingFieldName: '', //*
			imageFieldName: 'image' as string | null,

			sortingFieldsMap: {}, //*
			searchFieldsMap: {}, //*
			isSearchDateTypeColumn: function (searchBy) {
				return false;
			},
			isSearchRangeTypeColumn: function (searchBy) {
				return false;
			},
			isImageTypeColumn: function (searchBy, parent?) {
				return searchBy === Records.imageFieldName && !parent;
			},
			clearAllFilters: function () {},
			customSortingFunction: null as unknown as (sortBy: any, sortDirection: any) => any,
			specificSearchHook: null as unknown as (searchBy: string, query: string) => any,
			clearSpecificSearchBy: null as unknown as (searchBy: string) => any,
			printConfigs: {
				lineSpacing: '1.8',
				fontSize: 12,
			},
			printOptions: {
				fieldNames: [] as any[], //*
				fieldKeys: [] as any[], //*
				defaultFieldWidths: {}, //*
				defaultHeader: '', //*

				beforePrintHook: function (data: any, exportRecords?: any) {
					return data;
				},
			},

			exportOptions: {
				defaultFileName: 'ANAND_RECORDS', //*
				fieldKeys: [] as any[], //*
			},

			dashboardOptions: {
				html: '', //*
				title: '', //*
				populateCharts: null as unknown as (el: any) => void, //*
			},

			detailTileHTML: '', //*
			briefTileHTML: '', //*
			detailFormHTML: '', //*
			addFormHTML: '', //*

			detailTileSelector: '', //*
			briefTileSelector: '', //*
			addTileSelector: '', //*
			detailFormSelector: '', //*
			imageUploadSelector: '' as string | null, //*
			sortDirection: 1,

			hooks: {
				configureHook: null as unknown as () => void,
				afterAddTileCreate: null as unknown as (tile: any) => any,
				beforeAddTileShow: null as unknown as (tile: any, record: any) => any,
				afterAddRecord: null as unknown as (tile: any, record: any) => any,
				afterEditRecord: null as unknown as (record: any) => void,
				afterHeaderConfigure: null as unknown as (el: any) => void,
				specificSearchHook: null as unknown as (searchBy, query) => any,
				afterAddMultiFieldRow: null as unknown as (afterAddMultiFieldRow: any) => void,
			},

			populateBriefTile: null as unknown as (tile: any, record: any) => any, //*
			populateDetailTile: null as unknown as (tile: any, record: any, waitOnImage?: boolean) => Promise<any>, //*
			populateDetailForm: null as unknown as (tile: any, record: any) => any, //*
			getExtraDetailTileButtons: null as unknown as (record: any) => any,

			viewAllowed: false, //*
			editAllowed: false, //*

			updateRecordCount: null as unknown as () => any,

			updatePrintPreview: updatePrintPreview,
			prepareMultiFieldForAdd: prepareMultiFieldForAdd,
		};
	})(jQuery);

export var Records = RecordsFactory();
export const refreshRecords = () => {
	Records = RecordsFactory();
};
