import { isMobile } from 'helpers';
import { populateViewTileHelper } from 'helpers/RecordsHelper';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, PincodeRecord } from 'types';

export const detailTileHTML = `
<div id="amar-pin-detail-record" class="tile amar-pin-record detail">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row tehsil-detail">
					<div class="title col-xs-3">
						Tehsil:
					</div>
					<div class="value col-xs-9">
					</div>
				</div>
				<div class="row district-detail">
					<div class="title col-xs-3">
						District:
					</div>
					<div class="value col-xs-9">
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row city-detail">
					<div class="title col-xs-2">
						City:
					</div>
					<div class="value col-xs-4">
					</div>
				</div>
				<div class="row state-detail">
					<div class="title col-xs-2">
						State:
					</div>
					<div class="value col-xs-4">
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row country-detail">
					<div class="title col-xs-2">
						Country:
					</div>
					<div class="value col-xs-4">
					</div>
				</div>
				<div class="row pincode-detail">
					<div class="title col-xs-2">
						Pincode:
					</div>
					<div class="value col-xs-4">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
`;

export const briefTileHTML = `
<div id="amar-pin-brief-record" class="tile amar-pin-record brief">
	<div class="row">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row tehsil-detail">
					<div class="title col-xs-3">
						Tehsil:
					</div>
					<div class="value col-xs-9">
					</div>
				</div>
				<div class="row district-detail">
					<div class="title col-xs-3">
						District:
					</div>
					<div class="value col-xs-9">
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row city-detail">
					<div class="title col-xs-2">
						City:
					</div>
					<div class="value col-xs-4">
					</div>
				</div>
				<div class="row state-detail">
					<div class="title col-xs-2">
						State:
					</div>
					<div class="value col-xs-4">
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row country-detail">
					<div class="title col-xs-2">
						Country:
					</div>
					<div class="value col-xs-4">
					</div>
				</div>
				<div class="row pincode-detail">
					<div class="title col-xs-2">
						Pincode:
					</div>
					<div class="value col-xs-4">
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="record-id">
		<span></span>
	</div>
</div>
`;

export const detailFormHTML = `
<form id="amar-pin-detail-record-form" class="tile amar-pin-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row tehsil-detail">
					<div class="title col-xs-3">
						Tehsil:
					</div>
					<div class="value col-xs-9">
						<input type="text"  name="tehsil" value="" required placeholder="Tehsil"/>
					</div>
				</div>
				<div class="row district-detail">
					<div class="title col-xs-3">
						District:
					</div>
					<div class="value col-xs-9">
						<input type="text"  name="district" value="" required placeholder="District"/>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row city-detail">
					<div class="title col-xs-2">
						City:
					</div>
					<div class="value col-xs-4">
						<input type="text" value="" required name="city" placeholder="City"/>
					</div>
				</div>
				<div class="row state-detail">
					<div class="title col-xs-2">
						State:
					</div>
					<div class="value col-xs-4">
						<input type="text" value="" required name="state" placeholder="State"/>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row country-detail">
					<div class="title col-xs-2">
						Country:
					</div>
					<div class="value col-xs-4">
						<input type="text" value="" required name="country" placeholder="Country"/>
					</div>
				</div>
				<div class="row pincode-detail">
					<div class="title col-xs-2">
						Pincode:
					</div>
					<div class="value col-xs-4">
						<input type="text" value="" required name="pincode" placeholder="Pincode"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
`;

export const addFormHTML = `
<form id="amar-pin-add-record" class="tile amar-pin-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="row">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row tehsil-detail">
					<div class="title col-xs-3">
						Tehsil:
					</div>
					<div class="value col-xs-9">
						<input type="text"  name="tehsil" value="" required placeholder="Tehsil"/>
					</div>
				</div>
				<div class="row district-detail">
					<div class="title col-xs-3">
						District:
					</div>
					<div class="value col-xs-9">
						<input type="text"  name="district" value="" required placeholder="District"/>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row city-detail">
					<div class="title col-xs-2">
						City:
					</div>
					<div class="value col-xs-4">
						<input type="text" value="" required name="city" placeholder="City"/>
					</div>
				</div>
				<div class="row state-detail">
					<div class="title col-xs-2">
						State:
					</div>
					<div class="value col-xs-4">
						<input type="text" value="" required name="state" placeholder="State"/>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row country-detail">
					<div class="title col-xs-2">
						Country:
					</div>
					<div class="value col-xs-4">
						<input type="text" value="" required name="country" placeholder="Country"/>
					</div>
				</div>
				<div class="row pincode-detail">
					<div class="title col-xs-2">
						Pincode:
					</div>
					<div class="value col-xs-4">
						<input type="text" value="" required name="pincode" placeholder="Pincode"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
`;

interface Props {
	record?: PincodeRecord;
	index?: number;
	listRef?: React.RefObject<any>;
}

class Component extends React.PureComponent<Props> {
	private tileRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	componentDidUpdate(prevProps, prevState) {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	render() {
		return (
			<div
				ref={this.tileRef}
				dangerouslySetInnerHTML={{ __html: isMobile() ? briefTileHTML : detailTileHTML }}
			></div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {};
}

export const ViewTile = connect(mapStateToProps)(Component);
