import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { KutiyaRecord } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_KUTIYA = '@@mjbj/UPDATE_KUTIYA';
export type UPDATE_KUTIYA = typeof UPDATE_KUTIYA;

export interface UpdateKutiya extends Action {
	type: UPDATE_KUTIYA;
	changes: FBChanges;
}

export function updateKutiya(changes: FBChanges): UpdateKutiya {
	return {
		type: UPDATE_KUTIYA,
		changes,
	};
}

export const UPDATE_KUTIYA_FILTERED = UPDATE_KUTIYA + '_FILTERED';
export type UPDATE_KUTIYA_FILTERED = typeof UPDATE_KUTIYA_FILTERED;

export interface UpdateKutiyaFiltered extends Action {
	type: UPDATE_KUTIYA_FILTERED;
	payload: KutiyaRecord[];
}
