import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ApplicationState } from 'types';
import { Card, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { DBConfig, OrgName } from 'config';
import { UserPicInfo } from './UserPicInfo';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	},
	card: {
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
	},
}));

const Component = (props) => {
	let classes = useStyles();

	return (
		<div className={classes.root}>
			<UserPicInfo />

			<Card className={classes.card}>
				<Typography>You can contact us at following postal address:</Typography>
				<Typography style={{ paddingTop: 24 }} variant='subtitle1'>
					<span dangerouslySetInnerHTML={{ __html: props.postalContact }} />
				</Typography>
			</Card>
		</div>
	);
};

function mapStateToProps({ dataState }: ApplicationState) {
	return {
		postalContact: dataState.configs[DBConfig.PostalContact]?.value ?? `<strong>${OrgName}</strong>`,
	};
}

export const PostalContact = connect(mapStateToProps)(Component);
