import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Icons } from 'config/icons';
import React, { Fragment } from 'react';

export interface BottomDrawerOption {
	icon: JSX.Element;
	title?: string;
	element?: JSX.Element;
	onClick?: (e: React.MouseEvent) => void;
	disabled?: boolean;
}

export interface BottomDrawerOptionProps {
	options: BottomDrawerOption[];
	actionIcon?: JSX.Element;
	actionTitle?: string;
	drawerTitle?: string;
	className?: string;
	actionHandler?: (e: React.MouseEvent) => void;
}

export function BottomDrawerOptions(props: BottomDrawerOptionProps) {
	const optionsList = (
		<Fragment>
			<List className={props.className}>
				{props.drawerTitle ? (
					<Fragment>
						<ListItem>
							<ListItemText primary={props.drawerTitle} />
						</ListItem>
						<Divider />
					</Fragment>
				) : null}
				{props.options.map((option) => {
					return (
						<ListItem
							key={option.title || (option.element?.key ?? '')}
							button
							onClick={option.onClick}
							disabled={option.disabled === true}
						>
							<ListItemIcon>{option.icon}</ListItemIcon>
							{option.title ? <ListItemText primary={option.title} /> : null}
							{option.element}
						</ListItem>
					);
				})}
			</List>
			<Divider />
			<List>
				<ListItem button onClick={props.actionHandler}>
					<ListItemIcon>{props.actionIcon || Icons.Close}</ListItemIcon>
					<ListItemText primary={props.actionTitle || 'Cancel'} />
				</ListItem>
			</List>
		</Fragment>
	);

	return optionsList;
}
