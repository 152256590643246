import { isMobile } from 'helpers';
import { populateViewTileHelper } from 'helpers/RecordsHelper';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, BookRecord } from 'types';

export const detailTileHTML = `
<div id="book-detail-record" class="tile book detail">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="col-xs-12 col-md-6 main-details">
			<div class="block">
				<div class="row title-detail">
					<div class="title col-xs-3 col-md-2">
						Title:
					</div>
					<div class="value col-xs-9 col-md-10">
					</div>
				</div>
				<div class="row code-detail">
					<div class="title col-xs-3 col-md-2">
						Code:
					</div>
					<div class="value col-xs-9 col-md-10">
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12 col-md-4">
						<div class="row location-detail">
							<div class="title col-xs-3 col-md-6">
								Location:
							</div>
							<div class="value col-xs-9 col-md-6">
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-md-4">
						<div class="row language-detail">
							<div class="title col-xs-3 col-md-6">
								Language:
							</div>
							<div class="value col-xs-9 col-md-6">
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-md-4">
						<div class="row requirement-detail">
							<div class="title col-xs-3 col-md-6">
								Yearly Req:
							</div>
							<div class="value col-xs-9 col-md-6">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-6 main-details">
			<div class="block">
				<div class="row stock-detail">
					<div class="title col-xs-12 col-md-12">
						Stock:
					</div>
					<div class="multi-field-row col-xs-12 col-md-12">
						<div class="row">
							<div class="title col-xs-6">
								Stock Date
							</div>
							<div class="title col-xs-3 col-md-3">
								Place
							</div>
							<div class="title col-xs-3 col-md-3">
								Quantity
							</div>
						</div>
					</div>
					<div id="stock-value" class="multi-field-row col-xs-12 col-md-12">
						<div class="row">
							<div class="stock-date value col-xs-6">
							</div>
							<div class="stock-place value col-xs-3 col-md-3">
							</div>
							<div class="stock-qty value col-xs-3 col-md-3">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-12 main-details">
			<div class="block">
				<div class="row book-editions-detail">
					<div class="title col-xs-12 col-md-12">
						Book Editions:
					</div>
					<div class="multi-field-row col-xs-12">
						<div class="row">
							<div class="title col-xs-2 col-md-1">
								Edition
							</div>
							<div class="title col-xs-6 col-md-2">
								Print Date
							</div>
							<div class="title col-xs-2 col-md-1">
								Press
							</div>
							<div class="title col-xs-2 col-md-1">
								Pages
							</div>
							<div class="title col-xs-2 col-md-1">
								Quantity
							</div>
							<div class="title col-xs-2 col-md-1">
								Cost
							</div>
							<div class="title col-xs-2 col-md-1">
								Sale Price
							</div>
							<div class="title col-xs-4 col-md-1">
								Revised Price
							</div>
							<div class="title col-xs-2 col-md-3">
								ISBN
							</div>
						</div>
					</div>
					<div id="edition-row-detail" class="multi-field-row col-xs-12">
						<div class="row">
							<div class="edition value col-xs-2 col-md-1">
							</div>
							<div class="print-date value col-xs-6 col-md-2">
							</div>
							<div class="press value col-xs-2 col-md-1">
							</div>
							<div class="pages value col-xs-2 col-md-1">
							</div>
							<div class="quantity value col-xs-2 col-md-1">
							</div>
							<div class="cost value col-xs-2 col-md-1">
							</div>
							<div class="sale value col-xs-2 col-md-1">
							</div>
							<div class="revised value col-xs-4 col-md-1">
							</div>
							<div class="isbn value col-xs-2 col-md-3">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
`;

export const briefTileHTML = `
<div id="book-brief-record" class="tile book brief">
	<div class="row">
		<div class="col-xs-12">
			<div class="row title-detail">
				<div class="value col-xs-12">
				</div>
			</div>           						
		</div>
		<div class="col-xs-12">
			<div class="row location-detail">
				<div class="value col-xs-12">
				</div>
			</div>           						
		</div>
	</div>
	<div class="record-id">
		<span></span>
	</div>
</div>
`;

export const addFormHTML = `
<form id="book-add-record" class="tile book detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="row">
		<div class="col-xs-12 col-md-6 main-details">
			<div class="block">
				<div class="row title-detail">
					<div class="title col-xs-3 col-md-2">
						Title:
					</div>
					<div class="value col-xs-9 col-md-10">
						<input type="text" value="" name="title" placeholder="Title*" required/>
					</div>
				</div>
				<div class="row code-detail">
					<div class="title col-xs-3 col-md-2">
						Code:
					</div>
					<div class="value col-xs-9 col-md-10">
						<input type="text"  name="code" value="" placeholder="Code*" required/>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12 col-md-4">
						<div class="row location-detail">
							<div class="title col-xs-3 col-md-6">
								Location:
							</div>
							<div class="value col-xs-9 col-md-6">
								<input type="text"  name="location" value="" placeholder="Location*" required/>
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-md-4">
						<div class="row language-detail">
							<div class="title col-xs-3 col-md-6">
								Language:
							</div>
							<div class="value col-xs-9 col-md-6">
								<input type="text"  name="language" value="" placeholder="Language*" required/>
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-md-4">
						<div class="row requirement-detail">
							<div class="title col-xs-3 col-md-6">
								Yearly Req:
							</div>
							<div class="value col-xs-9 col-md-6">
								<input type="text"  name="requirement" value="" placeholder="Requirement"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-6 main-details">
			<div class="block">
				<div class="row stock-detail">
					<div class="title col-xs-12 col-md-12">
						Stock:
					</div>
					<div id="stock-row" class="multi-field-row hidden col-xs-12">
						<div class="row">
							<div class="value col-xs-5">
								<input type="text" class="date" value="" name="stock_date[]" placeholder="Stock Date*" required/>
							</div>
							<div class="value col-xs-3 col-md-3">
								<input type="text"  name="place[]" value="" placeholder="Place*" required/>
							</div>
							<div class="value col-xs-3 col-md-3">
								<input type="text"  name="qty[]" value="" placeholder="Quantity*" required/>
							</div>
							<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="stock-row"><i class="glyphicon glyphicon-plus"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-12 main-details">
			<div class="block">
				<div class="row book-editions-detail">
					<div class="title col-xs-12 col-md-12">
						Book Editions:
					</div>
					<div id="edition-row" class="multi-field-row hidden col-xs-12">
						<div class="row">
							<div class="value col-xs-2 col-md-1">
								<input type="text"  name="edition[]" value="" placeholder="Edition*" required/>
							</div>
							<div class="value col-xs-4 col-md-2">
								<input type="text" class="date" value="" name="print_date[]" placeholder="Print Date*" required/>
							</div>
							<div class="value col-xs-2 col-md-1">
								<input type="text"  name="press[]" value="" placeholder="Press*" required/>
							</div>
							<div class="value col-xs-2 col-md-1">
								<input type="number"  name="pages[]" value="" placeholder="Pages*" required/>
							</div>
							<div class="value col-xs-2 col-md-1">
								<input type="number"  name="quantity[]" value="" placeholder="Qty*" required/>
							</div>
							<div class="value col-xs-2 col-md-1">
								<input type="number"  name="cost[]" value="" placeholder="Cost*" required/>
							</div>
							<div class="value col-xs-2 col-md-1">
								<input type="number"  name="sale[]" value="" placeholder="Sale Price*" required/>
							</div>
							<div class="value col-xs-2 col-md-1">
								<input type="number"  name="revised[]" value="" placeholder="Revised Price"/>
							</div>
							<div class="value col-xs-4 col-md-2">
								<input type="text"  name="isbn[]" value="" placeholder="ISBN"/>
							</div>
							<button type="button" class="col-xs-2 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="edition-row"><i class="glyphicon glyphicon-plus"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
`;

export const detailFormHTML = `
<form id="book-detail-record-form" class="tile book detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="col-xs-12 col-md-6 main-details">
			<div class="block">
				<div class="row title-detail">
					<div class="title col-xs-3 col-md-2">
						Title:
					</div>
					<div class="value col-xs-9 col-md-10">
						<input type="text" value="" name="title" placeholder="Title*" required/>
					</div>
				</div>
				<div class="row code-detail">
					<div class="title col-xs-3 col-md-2">
						Code:
					</div>
					<div class="value col-xs-9 col-md-10">
						<input type="text"  name="code" value="" placeholder="Code*" required/>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12 col-md-4">
						<div class="row location-detail">
							<div class="title col-xs-3 col-md-6">
								Location:
							</div>
							<div class="value col-xs-9 col-md-6">
								<input type="text"  name="location" value="" placeholder="Location*" required/>
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-md-4">
						<div class="row language-detail">
							<div class="title col-xs-3 col-md-6">
								Language:
							</div>
							<div class="value col-xs-9 col-md-6">
								<input type="text"  name="language" value="" placeholder="Language*" required/>
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-md-4">
						<div class="row requirement-detail">
							<div class="title col-xs-3 col-md-6">
								Yearly Req:
							</div>
							<div class="value col-xs-9 col-md-6">
								<input type="text"  name="requirement" value="" placeholder="Requirement"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-6 main-details">
			<div class="block">
				<div class="row stock-detail">
					<div class="title col-xs-12 col-md-12">
						Stock:
					</div>
					<div id="stock-row-edit" class="multi-field-row hidden col-xs-12">
						<div class="row">
							<div class="value col-xs-5">
								<input type="text" class="date" value="" name="stock_date[]" placeholder="Stock Date*" required/>
							</div>
							<div class="value col-xs-3 col-md-3">
								<input type="text"  name="place[]" value="" placeholder="Place*" required/>
							</div>
							<div class="value col-xs-3 col-md-3">
								<input type="text"  name="qty[]" value="" placeholder="Quantity*" required/>
							</div>
							<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="stock-row-edit"><i class="glyphicon glyphicon-plus"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-12 main-details">
			<div class="block">
				<div class="row book-editions-detail">
					<div class="title col-xs-12 col-md-12">
						Book Editions:
					</div>
					<div id="edition-row-edit" class="multi-field-row hidden col-xs-12">
						<div class="row">
							<div class="value col-xs-2 col-md-1">
								<input type="text"  name="edition[]" value="" placeholder="Edition*" required/>
							</div>
							<div class="value col-xs-4 col-md-2">
								<input type="text" class="date" value="" name="print_date[]" placeholder="Print Date*" required/>
							</div>
							<div class="value col-xs-2 col-md-1">
								<input type="text"  name="press[]" value="" placeholder="Press*" required/>
							</div>
							<div class="value col-xs-2 col-md-1">
								<input type="number"  name="pages[]" value="" placeholder="Pages*" required/>
							</div>
							<div class="value col-xs-2 col-md-1">
								<input type="number"  name="quantity[]" value="" placeholder="Quantity*" required/>
							</div>
							<div class="value col-xs-2 col-md-1">
								<input type="number"  name="cost[]" value="" placeholder="Cost*" required/>
							</div>
							<div class="value col-xs-2 col-md-1">
								<input type="number"  name="sale[]" value="" placeholder="Sale Price*" required/>
							</div>
							<div class="value col-xs-2 col-md-1">
								<input type="number"  name="revised[]" value="" placeholder="Revised Price"/>
							</div>
							<div class="value col-xs-4 col-md-2">
								<input type="text"  name="isbn[]" value="" placeholder="ISBN"/>
							</div>
							<button type="button" class="col-xs-2 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="edition-row-edit"><i class="glyphicon glyphicon-plus"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
`;

interface Props {
	record?: BookRecord;
	index?: number;
	listRef?: React.RefObject<any>;
}

class Component extends React.PureComponent<Props> {
	private tileRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	componentDidUpdate(prevProps, prevState) {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	render() {
		return (
			<div
				ref={this.tileRef}
				dangerouslySetInnerHTML={{ __html: isMobile() ? briefTileHTML : detailTileHTML }}
			></div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {};
}

export const ViewTile = connect(mapStateToProps)(Component);
