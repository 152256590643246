import { DownloadStatus, OfflineState } from 'types';
import {
	ADD_OFFLINE_AUDIO,
	AddOfflineAudio,
	REMOVE_OFFLINE_AUDIO,
	RemoveOfflineAudio,
	UPDATE_DOWNLOAD_STATE,
	UpdateDownloadState,
} from './actions';

export function offlineReducer(
	state: OfflineState = { audio: {} },
	action: AddOfflineAudio | RemoveOfflineAudio | UpdateDownloadState
) {
	switch (action.type) {
		case ADD_OFFLINE_AUDIO:
			if (!action.article) {
				return state;
			}

			return {
				...state,
				audio: {
					...state.audio,
					[action.article.id]: {
						...state.audio[action.article.id],
						[action.forExport === true ? 'exportProgress' : 'progress']: {
							status: DownloadStatus.Queued,
							value: -1,
							bytes: 0,
							updatedAt: new Date(),
						},
						uri: action.article.mediaUri!,
						forExport: action.forExport,
					},
				},
			};
		case REMOVE_OFFLINE_AUDIO:
			let newState = { ...state, audio: { ...state.audio } };
			delete newState.audio[action.articleId];
			return newState;
		case UPDATE_DOWNLOAD_STATE:
			return {
				...state,
				audio: {
					...state.audio,
					[action.articleId]: {
						...state.audio[action.articleId],
						[state.audio[action.articleId]?.forExport === true
							? 'exportProgress'
							: 'progress']: action.progress,
					},
				},
			};
		default:
			return state;
	}
}
