import { ConfigStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateConfigs, UPDATE_CONFIG } from './actions';

export const configsReducer = (state: ConfigStore = {}, action: UpdateConfigs) => {
	switch (action.type) {
		case UPDATE_CONFIG:
			return updateData(state, action.changes);
		default:
			return state;
	}
};
