import { Card } from '@material-ui/core';
import TextContent from 'components/TextContent';
import { ToggleSelector } from 'components/ToggleSelector';
import { DBConfig } from 'config';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { ApplicationState, FBArticle } from 'types';

interface Props {
	termsRecord: FBArticle;
	ppRecord: FBArticle;
}

const Component = (props: Props) => {
	const [type, setType] = React.useState<string>('Terms');

	return (
		<React.Fragment>
			<ToggleSelector values={['Terms', 'Privacy']} type={type} setType={setType} />
			<Card style={{
				margin: 8,
				marginTop: 16,
				marginBottom: 32,
				padding: 16,
				display: 'flex',
				flexDirection: 'column' as 'column',
				backgroundColor: 'white',
			}}>
				<TextContent>
					<div style={{ fontSize: '0.9em' }} dangerouslySetInnerHTML={{ __html: (type === 'Terms' ? props.termsRecord?.text?.en : props.ppRecord?.text?.en) || '' }} />
				</TextContent>
			</Card>
		</React.Fragment>
	);
};

function mapStateToProps(state: ApplicationState) {
	let terms = state.dataState.configs[DBConfig.Terms];
	let termsRecord = state.dataState.articles.byId[terms?.value];

	let pp = state.dataState.configs[DBConfig.Privacy];
	let ppRecord = state.dataState.articles.byId[pp?.value];

	return {
		termsRecord,
		ppRecord,
	};
}

export default hot(connect(mapStateToProps)(Component));
