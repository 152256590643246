import { combineReducers } from 'redux';
import { ThroughRecord, ThroughStore } from 'types';
import { updateData } from '../../generic/reducers';
import { UpdateThrough, UpdateThroughFiltered, UPDATE_THROUGH, UPDATE_THROUGH_FILTERED } from './actions';

export const throughByIdReducer = (state = {}, action: UpdateThrough) => {
	switch (action.type) {
		case UPDATE_THROUGH:
			return updateData(state, action.changes, 'through');
		default:
			return state;
	}
};

const filteredThroughReducer = (state: ThroughRecord[] = [], action: UpdateThroughFiltered) => {
	switch (action.type) {
		case UPDATE_THROUGH_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const throughReducer = combineReducers<ThroughStore>({
	byId: throughByIdReducer,
	filtered: filteredThroughReducer,
});
