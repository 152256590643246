import { OpsState } from 'types';
import { UpdateOperation, UPDATE_OPS } from './actions';

export function opsReducer(state: OpsState = {}, action: UpdateOperation) {
	switch (action.type) {
		case UPDATE_OPS:
			return { ...state, [action.operation.key]: action.operation };
		default:
			return state;
	}
}
