export const utils = (function ($) {
	var stateMap: any = {
		loadingCount: 0,
	};

	// function isMobile(this: void) {
	// 	!((function (a) {
	// 		var b = /iPhone/i,
	// 			c = /iPod/i,
	// 			d = /iPad/i,
	// 			e = /(?=.*\bAndroid\b)(?=.*\bMobile\b)/i,
	// 			f = /Android/i,
	// 			g = /IEMobile/i,
	// 			h = /(?=.*\bWindows\b)(?=.*\bARM\b)/i,
	// 			i = /BlackBerry/i,
	// 			j = /BB10/i,
	// 			k = /Opera Mini/i,
	// 			l = /(?=.*\bFirefox\b)(?=.*\bMobile\b)/i,
	// 			m = new RegExp('(?:Nexus 7|BNTV250|Kindle Fire|Silk|GT-P1000)', 'i'),
	// 			n = function (a, b) {
	// 				return a.test(b);
	// 			},
	// 			o = function (a) {
	// 				var o = a || navigator.userAgent;
	// 				return (
	// 					(this.apple = {
	// 						phone: n(b, o),
	// 						ipod: n(c, o),
	// 						tablet: n(d, o),
	// 						device: n(b, o) || n(c, o) || n(d, o),
	// 					}),
	// 					(this.android = { phone: n(e, o), tablet: !n(e, o) && n(f, o), device: n(e, o) || n(f, o) }),
	// 					(this.windows = { phone: n(g, o), tablet: n(h, o), device: n(g, o) || n(h, o) }),
	// 					(this.other = {
	// 						blackberry: n(i, o),
	// 						blackberry10: n(j, o),
	// 						opera: n(k, o),
	// 						firefox: n(l, o),
	// 						device: n(i, o) || n(j, o) || n(k, o) || n(l, o),
	// 					}),
	// 					(this.seven_inch = n(m, o)),
	// 					(this.any =
	// 						this.apple.device ||
	// 						this.android.device ||
	// 						this.windows.device ||
	// 						this.other.device ||
	// 						this.seven_inch),
	// 					(this.phone = this.apple.phone || this.android.phone || this.windows.phone),
	// 					(this.tablet = this.apple.tablet || this.android.tablet || this.windows.tablet),
	// 					'undefined' == typeof window ? this : void 0
	// 				);
	// 			},
	// 			p = function () {
	// 				var a = new o();
	// 				return (a.Class = o), a;
	// 			};
	// 		'undefined' != typeof module && module.exports && 'undefined' == typeof window
	// 			? (module.exports = o)
	// 			: 'undefined' != typeof module && module.exports && 'undefined' != typeof window
	// 			? (module.exports = p())
	// 			: 'function' == typeof define && define.amd
	// 			? define('isMobileLib', [], (a.isMobileLib = p()))
	// 			: (a.isMobileLib = p());
	// 	})(this));

	// 	return this.isMobileLib.phone;
	// }

	function JSONToCSVConvertor(JSONData?, ShowLabel?, fileName?, validFields?, returnTableData?) {
		//If JSONData is not an object then JSON.parse will parse the JSON string in an Object
		var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

		var CSV = '';
		var tableData: any[] = [];

		//This condition will generate the Label/Header
		if (ShowLabel) {
			var row = '';
			var tableRow: any[] = [];

			tableRow.push({ text: 'id', style: 'tableHeader' });
			//This loop will extract the label from 1st index of on array
			for (var fieldId in validFields) {
				var index = validFields[fieldId];
				//Now convert each value to string and comma-seprated
				row += $.trim(index) + ',';
				tableRow.push({ text: $.trim(index), style: 'tableHeader' });
			}

			row = row.slice(0, -1);

			//append Label row with line break
			CSV += row + '\r\n';
			tableData.push(tableRow);
		}

		//1st loop is to extract each row
		for (var i = 0; i < arrData.length; i++) {
			var row = '';
			var tableRow: any[] = [];

			tableRow.push('' + (i + 1));
			//2nd loop will extract each column and convert it in string comma-seprated
			for (var fieldId in validFields) {
				var index = validFields[fieldId];
				var value = $.trim(arrData[i][index]);
				value = value.replace(/\"/g, '""');
				if (arrData[i][index] == null) {
					row += '"",';
					tableRow.push('');
				} else {
					row += '"' + value + '",';
					tableRow.push(value);
				}
			}

			row = row.slice(0, -1);

			//add a line break after each row
			CSV += row + '\r\n';
			tableData.push(tableRow);
		}

		if (CSV == '') {
			alert('Invalid data');
			return;
		}

		//We don't want to download but to return data
		if (typeof returnTableData !== 'undefined' && returnTableData == true) {
			return tableData;
		} else if (fileName == '') {
			return CSV;
		}

		//Generate a file name
		var fileName = fileName;

		//Initialize file format you want csv or xls
		var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

		// Now the little tricky part.
		// you can use either>> window.open(uri);
		// but this will not work in some browsers
		// or you will not get the correct file extension

		//this trick will generate a temp <a /> tag
		var link = document.createElement('a') as any;
		link.href = uri;

		//set the visibility hidden so it will not effect on your web-layout
		link.style = 'visibility:hidden';
		link.download = fileName + '.csv';

		//this part will append the anchor tag and remove it after automatic click
		setTimeout(function () {
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}, 500);
	}

	function trim(str, trimChars) {
		if (typeof trimChars == 'undefined') {
			trimChars = ' ';
		}

		while (str.length > 0) {
			if (trimChars.indexOf(str[0]) >= 0) {
				str = str.substr(1);
			} else {
				break;
			}
		}

		while (str.length > 0) {
			if (trimChars.indexOf(str[str.length - 1]) >= 0) {
				str = str.substr(0, str.length - 1);
			} else {
				break;
			}
		}

		return str;
	}

	//yyyy-mm-dd
	function formatDate(date) {
		if (date === null) {
			return null;
		}
		var dateObj: any = new Date(date);
		if (dateObj == 'Invalid Date') {
			return null;
		}
		var year = dateObj.getFullYear();
		var month = dateObj.getMonth() + 1;
		if (month < 10) {
			month = '0' + month;
		}
		var day = dateObj.getDate();
		if (day < 10) {
			day = '0' + day;
		}

		return year + '-' + month + '-' + day;
	}

	function getDateStr(year?, month?, day?) {
		var monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		var weekArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

		var date;
		if (typeof year === 'undefined') {
			date = new Date();
			day = date.getDate();
		} else {
			date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day ? day : 1));
		}

		var dayOfWeek = date.getDay();

		return (
			(day ? weekArr[dayOfWeek] + ', ' + day + ' ' : '') + monthArr[date.getMonth()] + ' ' + date.getFullYear()
		);
	}

	function localStorageSpace() {
		var allStrings = '';
		for (var key in window.localStorage) {
			if (window.localStorage.hasOwnProperty(key)) {
				allStrings += window.localStorage[key];
			}
		}
		return allStrings ? 3 + (allStrings.length * 2) / 1024 : 0;
	}

	function localStorageQuota() {
		if (typeof stateMap.localStorageQuota !== 'undefined') {
			return stateMap.localStorageQuota;
		}

		var repeat = function (str, x) {
			return new Array(x + 1).join(str);
		};

		var curUsage = localStorageSpace();
		var i = 1024 * 4 - curUsage;
		try {
			while (true) {
				i += 512;
				var too_big = repeat('x', (i * 1024) / 2);
				localStorage.setItem('quota_test', too_big);
			}
		} catch (e) {
			localStorage.removeItem('quota_test');
			stateMap.localStorageQuota = parseInt(i + curUsage.toString());
			return stateMap.localStorageQuota;
		}
	}

	function upperFirstAll(text) {
		if (text === null) {
			return null;
		}
		return text
			.toLowerCase()
			.replace(/^(.)|(\s|\-)+(.)/g, function (c) {
				return c.toUpperCase();
			})
			.replace(/ +/g, ' ');
	}

	function showLoading(show) {
		if (show === true) {
			stateMap.loadingCount++;
			// ($('#loading-overlay') as any).activity({ color: 'lightgrey' });
			$('#loading-overlay').show();
		} else if (--stateMap.loadingCount <= 0) {
			// ($('#loading-overlay') as any).activity(false);
			$('#loading-overlay').hide();
		}
	}

	const toDataURL = (url) =>
		fetch(url)
			.then((response) => response.blob())
			.then(
				(blob) =>
					new Promise((resolve, reject) => {
						const reader = new FileReader();
						reader.onloadend = () => resolve(reader.result);
						reader.onerror = reject;
						reader.readAsDataURL(blob);
					})
			);

	function getAgeFromDoB(currentDate, dob) {
		var age = currentDate.getFullYear() - dob.getFullYear();
		if (
			age !== 0 &&
			(currentDate.getMonth() < dob.getMonth() ||
				(currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate()))
		) {
			age--;
		}
		return age;
	}

	return {
		// isMobile: isMobile,
		JSONToCSVConvertor: JSONToCSVConvertor,
		trim: trim,
		formatDate: formatDate,
		localStorageSpace: localStorageSpace,
		localStorageQuota: localStorageQuota,
		upperFirstAll: upperFirstAll,
		getDateStr: getDateStr,
		showLoading: showLoading,
		toDataURL: toDataURL,
		getAgeFromDoB: getAgeFromDoB,
	};
})(jQuery);
