import { combineReducers } from 'redux';
import { FBArticle, FBArticlesById, FBArticleStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateArticlesFiltered, UpdateFBArticles, UPDATE_FB_ARTICLES, UPDATE_FB_ARTICLES_FILTERED } from './actions';

export function fbArticlesByIdReducer(state: FBArticlesById = {}, action: UpdateFBArticles): FBArticlesById {
	switch (action.type) {
		case UPDATE_FB_ARTICLES:
			return updateData(state, action.changes);
		default:
			return state;
	}
}

const filteredArticlesReducer = (state: FBArticle[] = [], action: UpdateArticlesFiltered) => {
	switch (action.type) {
		case UPDATE_FB_ARTICLES_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const articlesReducer = combineReducers<FBArticleStore>({
	byId: fbArticlesByIdReducer,
	filtered: filteredArticlesReducer,
});
