import { isMobile } from 'helpers';
import { populateViewTileHelper } from 'helpers/RecordsHelper';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, SangatRecord } from 'types';

export const detailTileHTML = `
<div id="sangat-records-detail-record" class="tile sangat-records-record detail">
	<div class="row">                
		<div class="title col-md-2 col-xs-3">
			<span>Festival:</span>
		</div>
		<div class="col-xs-9 col-md-10">                    
			<div class="festival-name">					
			</div>
		</div>
	</div>
	<div class="row">                
		<div class="title col-md-2 col-xs-3">
			<span>Date:</span>
		</div>
		<div class="col-xs-9 col-md-10">
			<div class="festival-date">
			</div>
		</div>
	</div>
	<div class="row">                
		<div class="title col-md-2 col-xs-3">
			<span>Sangat:</span>
		</div>
		<div class="col-xs-9 col-md-10">
			<div class="sangat-count">
			</div>
		</div>
	</div>
	<div class="record-id">
		<span></span>
	</div>
</div>
`;

export const briefTileHTML = `
<div id="sangat-records-brief-record" class="tile sangat-records-record brief">
	<div class="row">                
		<div class="title col-xs-3">
			<span>Festival:</span>
		</div>
		<div class="col-xs-9">
			<div class="festival-name">

			</div>
		</div>

		<div class="title col-xs-3">
			<span>Date:</span>
		</div>
		<div class="col-xs-9">
			<div class="festival-date">

			</div>
		</div>

		<div class="title col-xs-3">
			<span>Sangat:</span>
		</div>
		<div class="col-xs-9">
			<div class="sangat-count">

			</div>
		</div>
	</div>
	<div class="record-id">
		<span></span>
	</div>
</div>
`;

export const detailFormHTML = `
<form id="sangat-records-edit-form" class="tile sangat-records-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="title col-xs-3 col-md-2">
			<span>Festival:</span>
		</div>
		<div class="value col-xs-9 col-md-10">
			<input type="text" required class="festival-name" name="festival_name" placeholder="Festival" />
		</div>
	</div>
	<div class="row">
		<div class="title col-xs-3 col-md-2">
			<span>Date:</span>
		</div>
		<div class="value col-xs-9 col-md-10">
			<input type="text" required class="festival-date date value" value="" name="festival_date" placeholder="Date"/>
		</div>
	</div>
	<div class="row">
		<div class="title col-xs-3 col-md-2">
			<span>Sangat:</span>
		</div>
		<div class="col-xs-9 col-md-4">
			<input type="number" required class="sangat-count value" value="" name="sangat_count" placeholder="Sangat"/>
		</div>
	</div>
</form>
`;

export const addFormHTML = `
<form id="sangat-records-add-form" class="tile sangat-records-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="title col-xs-3 col-md-2">
			<span>Festival:</span>
		</div>
		<div class="value col-xs-9 col-md-10">
			<input type="text" required class="festival-name" name="festival_name" placeholder="Festival" />
		</div>
	</div>
	<div class="row">
		<div class="title col-xs-3 col-md-2">
			<span>Date:</span>
		</div>
		<div class="value col-xs-9 col-md-10">
			<input type="text" required class="festival-date date value" value="" name="festival_date" placeholder="Date"/>
		</div>
	</div>
	<div class="row">
		<div class="title col-xs-3 col-md-2">
			<span>Sangat:</span>
		</div>
		<div class="col-xs-9 col-md-4">
			<input type="number" required class="sangat-count value" value="" name="sangat_count" placeholder="Sangat"/>
		</div>
	</div>
</form>
`;

interface Props {
	record?: SangatRecord;
	index?: number;
	listRef?: React.RefObject<any>;
}

class Component extends React.PureComponent<Props> {
	private tileRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	componentDidUpdate(prevProps, prevState) {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	render() {
		return (
			<div
				ref={this.tileRef}
				dangerouslySetInnerHTML={{ __html: isMobile() ? briefTileHTML : detailTileHTML }}
			></div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {};
}

export const ViewTile = connect(mapStateToProps)(Component);
