import AnandDate from 'helpers/AnandDate';
import { ApplicationState, FBData } from 'types';
import { createCachedSelector } from 're-reselect';

function getData(state: ApplicationState, type: string, key?: string) {
	return state.dataState[type];
}

export const getDataById = createCachedSelector([getData], (data) => {
	let result: { [id: string]: any } = {};
	for (let id in data) {
		if (data[id].deleted === true) {
			continue;
		}
		result[id] = data[id];
	}

	return result;
})((_state_, type, key) => type + (key ?? ''));

export const getDataByDate = createCachedSelector(
	(state, type, key) => getData(state, type, key),
	(state, type, key) => type,
	(data, type) => {
		let byId = data.byId ?? data;

		let result: { [date: string]: any[] } = {};
		for (let id in byId) {
			let record = byId[id];
			let date = record.forDate ?? record.publishTime;
			if (date) {
				let dateStr = new AnandDate(date).format(type === 'hd' ? 'MM-DD' : 'YYYY-MM-DD');
				result[dateStr] = result[dateStr] || [];
				result[dateStr].push(record);
			}
		}

		return result;
	}
)((_state_, type, key) => type + (key ?? ''));

export const getDataSortedByPublishTime = createCachedSelector([getDataById], (data) => {
	let result: FBData[] = Object.values(data);

	// Sort descending
	return result.sort(
		(a, b) => b.publishTime.seconds - a.publishTime.seconds || b.publishTime.nanoseconds - a.publishTime.nanoseconds
	);
})((_state_, type, key) => type + (key ?? ''));

export const getDataSorted = createCachedSelector(
	(records) => records,
	(records, sortValue) => sortValue,
	(records, sortValue, sortKey) => sortKey,
	(records, sortValue, sortKey: string) => {
		let sortFunc;
		switch (sortValue) {
			case 'By Day':
				sortFunc = (a, b) => {
					let bDate = new AnandDate(b.forDate);
					let aDate = new AnandDate(a.forDate);
					bDate.subtract(bDate.year(), 'year');
					aDate.subtract(aDate.year(), 'year');

					return bDate.isSameDate(aDate) ? (new AnandDate(b.forDate).isAfter(new AnandDate(a.forDate)) ? -1 : 1) : bDate.isAfter(aDate) ? -1 : 1;
				}
				break;
			case 'Newest First':
			case 'Latest Published':
				sortFunc = (a, b) =>
					b.publishTime
						? b.publishTime.seconds - a.publishTime.seconds
						: new AnandDate(b.forDate).isAfter(new AnandDate(a.forDate))
							? 1
							: -1;
				break;
			case 'Oldest First':
			case 'Oldest Published':
				sortFunc = (a, b) =>
					a.publishTime
						? a.publishTime.seconds - b.publishTime.seconds
						: new AnandDate(a.forDate).isAfter(new AnandDate(b.forDate))
							? 1
							: -1;
				break;
			case 'Latest Updated':
				sortFunc = (a, b) => b.updatedAt.seconds - a.updatedAt.seconds;
				break;
			case 'Oldest Updated':
				sortFunc = (a, b) => a.updatedAt.seconds - b.updatedAt.seconds;
				break;
			case 'Title - Ascending':
				sortFunc = (a, b) =>
					(a.title.en ?? a.title.hi ?? (a.title.length && a.title) ?? '') >
						(b.title.en ?? b.title.hi ?? (b.title.length && b.title) ?? '')
						? 1
						: -1;
				break;
			case 'Title - Descending':
				sortFunc = (a, b) =>
					(a.title.en ?? a.title.hi ?? (a.title.length && a.title) ?? '') >
						(b.title.en ?? b.title.hi ?? (b.title.length && b.title) ?? '')
						? -1
						: 1;
				break;
			default:
				return records;
		}
		// Sort descending
		return [...(records as any[])].sort(sortFunc);
	}
)((records, sortFunc, sortKey) => sortKey);
