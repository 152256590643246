/**
 *
 */
import './css/anand.notification.css';

export const notification = (function ($) {
	let configMap: any = {
		bottomDivId: '#bottom-notification-msg',
	};

	function showBottomNotification(msg?, keepOpen?) {
		if ($(configMap.bottomDivId).is(':visible')) {
			$(configMap.bottomDivId).hide();
		}
		$(configMap.bottomDivId).html(msg);
		if (typeof keepOpen == 'undefined' || keepOpen == false) {
			$(configMap.bottomDivId).fadeIn().delay(3000).fadeOut();
		} else {
			$(configMap.bottomDivId).fadeIn().delay(6000).fadeOut();
		}
	}

	return {
		showBottomNotification: showBottomNotification,
	};
})(jQuery);
