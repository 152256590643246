import { MjBj } from "components/mjbj/mjbj";
import { isAdmin } from "helpers";
import { connect } from "react-redux";
import { ApplicationState } from "types";
import Page from "./Page";

const Component = (props) => {
    return (
        <Page title='MjBj Records' fullscreen>
            <MjBj />
        </Page>
    );
};

function mapStateToProps(state: ApplicationState) {
    return {
        isAdmin: isAdmin(state.dataState.userStore.userData),
        userData: state.dataState.userStore.userData,
    };
}

export default connect(mapStateToProps)(Component);
