import App from 'AppInterface/App';
import { logEvent } from 'components/telemetry';
import { DBConfig } from 'config';
import { getAudioFilenameFromSrc, getContentStorageUrl, isWebViewEnv, updatePushSetting } from 'helpers';
import { Context } from 'helpers/context';
import { combineEpics, ofType } from 'redux-observable';
import { concat, EMPTY, of } from 'rxjs';
import { concatMap, debounceTime, switchMap } from 'rxjs/operators';
import { updateProfile } from 'services/api/auth';
import { UPDATE_FB_USER_PLAYLISTS } from 'store/data/user/actions';
import { addOfflineAudio, AddOfflineAudio, ADD_OFFLINE_AUDIO, removeOfflineAudio } from 'store/offline/actions';
import { EventType, onEvent } from 'store/temp/actions';
import { UpdateUserSettings, UPDATE_USER_SETTINGS } from 'store/ux/actions';
import { ApplicationState, ContentType, DownloadStatus } from 'types';

const settingsEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_USER_SETTINGS),
		debounceTime(2000),
		switchMap((action: UpdateUserSettings) => {
			if (action.fromProfile === true) {
				return EMPTY;
			}

			let user = (state$.value as ApplicationState).dataState.userStore.user;
			let settings = (state$.value as ApplicationState).uxState.settings;

			updatePushSetting(settings.push);

			if (user) {
				return of(updateProfile({ settings }, new Context()));
			} else {
				return EMPTY;
			}
		})
	);

const offlineAudioEpic = (action$, state$) =>
	action$.pipe(
		ofType(UPDATE_FB_USER_PLAYLISTS, UPDATE_USER_SETTINGS),
		debounceTime(2000),
		switchMap((action: any) => {
			let state = state$.value as ApplicationState;
			let user = state.dataState.userStore.user;
			if (user && isWebViewEnv()) {
				let offlinePlaylist: any = state.dataState.fbUserPlaylists['0'] || {};
				if (offlinePlaylist.deleted) {
					offlinePlaylist = {};
				}

				let tracks = offlinePlaylist.tracks || {};

				let offlineAudio = state.offlineState.audio;
				let articles = state.dataState.articles.byId;
				let configs = state.dataState.configs;
				let autoDownloadIds: string[] = ((configs[DBConfig.AutoDownload]?.value ?? []) as unknown) as string[];

				let audioToDownload: any[] = [];
				let audioToDelete: any[] = [];

				let settings = state.uxState.settings;

				if (settings?.download) {
					for (let id in tracks) {
						if (articles[id] && !offlineAudio[id] && autoDownloadIds.indexOf(id) < 0) {
							// if (audioToDownload.length === 0) {
							audioToDownload.push(of(addOfflineAudio(articles[id], false)));
							// } else {
							// audioToDownload.push(
							// of(addOfflineAudio(articles[id], false)).pipe(
							// delayWhen(() => action$.ofType(DidDownloadMedia))
							// )
							// );
							// }
						}
					}
				}

				for (let id in offlineAudio) {
					if (offlineAudio[id].progress?.status === DownloadStatus.Complete) {
						if (!tracks[id] && autoDownloadIds.indexOf(id) < 0) {
							audioToDelete.push(of(removeOfflineAudio(id, true)));
						}
					}
				}
				if (audioToDownload.length || audioToDelete.length) {
					return concat(
						...[
							of(
								onEvent(new Context(), EventType.Information, 'Offline', {
									message: 'Syncing offline audio...',
									success: true,
								})
							),
							...audioToDownload,
							...audioToDelete,
						]
					);
				}
			}
			return EMPTY;
		})
	);

const offlineAudioDownloadEpic = (action$, state$) =>
	action$.pipe(
		ofType(ADD_OFFLINE_AUDIO),
		concatMap((action: AddOfflineAudio) => {
			let article = action.article;
			if (!article) {
				return EMPTY;
			}

			if (action.forExport === true) {
				logEvent('exportMedia', { id: article.id, title: article.title.en, uri: article.mediaUri });
				App.exportMedia(
					article.id,
					article.title.en + '.' + article.mediaUri!.split('.')[1],
					getContentStorageUrl(ContentType.Article, article.articleType, article)
				);
			} else {
				// App.deleteMedia(article.id, getAudioFilenameFromSrc(article.mediaUri!));

				// setTimeout(() => {
				logEvent('downloadMedia', { id: article.id, title: article.title.en, uri: article.mediaUri });
				App.downloadMedia(
					article.id,
					getAudioFilenameFromSrc(article.mediaUri!),
					getContentStorageUrl(ContentType.Article, article.articleType, article),
					true
				);
				// }, 1000);
			}

			if (!isWebViewEnv()) {
				return EMPTY;
			}

			return EMPTY;
		})
	);

const downloadRetries = {};

export const userDataEpic = combineEpics(settingsEpic, offlineAudioDownloadEpic, offlineAudioEpic);
