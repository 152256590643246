import { isMobile } from 'helpers';
import { populateViewTileHelper } from 'helpers/RecordsHelper';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, DhamRecord } from 'types';

export const detailTileHTML = `
<div id="dham-records-detail-record" class="tile dham-records-record detail">
	<div class="row">                
		<div class="title col-md-2 col-xs-3">
			<span>Dham:</span>
		</div>
		<div class="col-xs-9 col-md-10">                    
			<div class="dham-name">					
			</div>
		</div>
	</div>
	<div class="row">                
		<div class="title col-md-2 col-xs-3">
			<span>Address:</span>
		</div>
		<div class="col-xs-9 col-md-10">
			<div class="dham-address">
			</div>
		</div>
	</div>
	<div class="row">                
		<div class="title col-md-2 col-xs-3">
			<span>Comments:</span>
		</div>
		<div class="col-xs-9 col-md-10">
			<div class="dham-comments">
			</div>
		</div>
	</div>
	<div class="record-id">
		<span></span>
	</div>
</div>
`;

export const briefTileHTML = `
<div id="dham-records-brief-record" class="tile dham-records-record brief">
	<div class="row">                
		<div class="title col-xs-3">
			<span>Dham:</span>
		</div>
		<div class="col-xs-9">
			<div class="dham-name">

			</div>
		</div>

		<div class="title col-xs-3">
			<span>Address:</span>
		</div>
		<div class="col-xs-9">
			<div class="dham-address">

			</div>
		</div>
	</div>
	<div class="record-id">
		<span></span>
	</div>
</div>
`;

export const detailFormHTML = `
<form id="dham-records-edit-form" class="tile dham-records-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="title col-xs-3 col-md-2">
			<span>Dham:</span>
		</div>
		<div class="value col-xs-9 col-md-10">
			<input type="text" required class="dham-name" name="dham_name" placeholder="Dham Name"/>
		</div>
	</div>
	<div class="row">
		<div class="title col-xs-3 col-md-2">
			<span>Address:</span>
		</div>
		<div class="col-xs-9 col-md-4">
			<textarea rows="3" class="dham-address value" value="" name="dham_address" placeholder="Address"></textarea>
		</div>
	</div>
	<div class="row">
		<div class="title col-xs-3 col-md-2">
			<span>Comments:</span>
		</div>
		<div class="col-xs-9 col-md-4">
			<textarea rows="3" class="dham-comments value" name="comments" value="" placeholder="Comments" />
		</div>
	</div>
</form>
`;

export const addFormHTML = `
<form id="dham-records-add-form" class="tile dham-records-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="title col-xs-3 col-md-2">
			<span>Dham:</span>
		</div>
		<div class="value col-xs-9 col-md-10">
			<input type="text" required class="dham-name" name="dham_name" placeholder="Dham Name"/>
		</div>
	</div>
	<div class="row">
		<div class="title col-xs-3 col-md-2">
			<span>Address:</span>
		</div>
		<div class="col-xs-9 col-md-4">
			<textarea rows="3" class="dham-address value" value="" name="dham_address" placeholder="Address"></textarea>
		</div>
	</div>
	<div class="row">
		<div class="title col-xs-3 col-md-2">
			<span>Comments:</span>
		</div>
		<div class="col-xs-9 col-md-4">
			<textarea rows="3" class="dham-comments value" name="comments" value="" placeholder="Comments" />
		</div>
	</div>
</form>
`;

interface Props {
	record?: DhamRecord;
	index?: number;
	listRef?: React.RefObject<any>;
}

class Component extends React.PureComponent<Props> {
	private tileRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	componentDidUpdate(prevProps, prevState) {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	render() {
		return (
			<div
				ref={this.tileRef}
				dangerouslySetInnerHTML={{ __html: isMobile() ? briefTileHTML : detailTileHTML }}
			></div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {};
}

export const ViewTile = connect(mapStateToProps)(Component);
