import { combineReducers } from 'redux';
import { MjBjRecord, MjBjStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateMjBJ, UpdateMjBjFiltered, UPDATE_MJBJ, UPDATE_MJBJ_FILTERED } from './actions';

export const mjbjByIdReducer = (state = {}, action: UpdateMjBJ) => {
	switch (action.type) {
		case UPDATE_MJBJ:
			return updateData(state, action.changes, 'mjbj');
		default:
			return state;
	}
};

const filteredMjBjReducer = (state: MjBjRecord[] = [], action: UpdateMjBjFiltered) => {
	switch (action.type) {
		case UPDATE_MJBJ_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const mjbjReducer = combineReducers<MjBjStore>({
	byId: mjbjByIdReducer,
	filtered: filteredMjBjReducer,
});
