import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { RateRecord } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_RATE = '@@mjbj/UPDATE_RATE';
export type UPDATE_RATE = typeof UPDATE_RATE;

export interface UpdateRate extends Action {
	type: UPDATE_RATE;
	changes: FBChanges;
}

export function updateRate(changes: FBChanges): UpdateRate {
	return {
		type: UPDATE_RATE,
		changes,
	};
}

export const UPDATE_RATE_FILTERED = UPDATE_RATE + '_FILTERED';
export type UPDATE_RATE_FILTERED = typeof UPDATE_RATE_FILTERED;

export interface UpdateRateFiltered extends Action {
	type: UPDATE_RATE_FILTERED;
	payload: RateRecord[];
}
