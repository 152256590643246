import { combineEpics, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import {
	enqueueSnackbar,
	EventType,
	ON_EVENT,
	OnEvent
} from 'store/temp/actions';
import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import { Icons } from 'config/icons';
import { notistackRef } from 'App';
import { logEvent } from 'components/telemetry';

const eventEpic = (action$, state$) =>
	action$.pipe(
		ofType(ON_EVENT),
		concatMap((action: OnEvent) => {
			let payload = action.payload;
			let eventType = action.eventType;
			let eventName = action.eventName;

			switch (eventType) {
				case EventType.Exception:
					logEvent('JSException', payload);
					return of(enqueueSnackbar({ message: payload.message, options: { variant: 'error' } }));
				case EventType.AssertionFailure:
					return of(enqueueSnackbar({ message: payload, options: { variant: 'error' } }));
				case EventType.OperationEnd:
				case EventType.Information:
					if (payload.message) {
						return of(
							enqueueSnackbar({
								message: payload.message,
								options: { variant: payload.success ? 'success' : 'error' },
							})
						);
					}
					return EMPTY;
				case EventType.Action:
					const action = key => (
						<React.Fragment>
							<Button onClick={() => { payload.action.callback(); }}>
								{payload.action.label}
							</Button>
							<IconButton style={{ color: 'white' }} onClick={() => {
								notistackRef && notistackRef.current.closeSnackbar(key);
							}}>
								{Icons.Close}
							</IconButton>
						</React.Fragment>
					);

					return of(
						enqueueSnackbar({
							message: payload.message,
							options: { key: eventName, variant: payload.variant ?? 'warning', action: action, persist: payload.persist ?? false, preventDuplicate: true },
						})
					);
				default:
					return EMPTY;
			}
		})
	);

export const eventsEpic = combineEpics(eventEpic);
