import { makeStyles, Paper } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Icons } from 'config/icons';
import { getWebAppAssetPath, isAdmin } from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';

const useStyles = makeStyles((theme) => ({
	picsection: {
		paddingBottom: theme.spacing(2),
		paddingTop: theme.spacing(2),
	},
	userpicNA: {
		width: '100%',
		fontSize: 80,
		color: theme.palette.grey[400],
	},
	userpic: {
		width: theme.spacing(12),
		height: theme.spacing(12),
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: theme.spacing(1),
	},
}));

const Component = (props) => {
	let classes = useStyles();
	let { user, userData } = props;

	if (!user) {
		return null;
	}

	let photoUrl = userData?.photoUrl || user?.photoURL || getWebAppAssetPath('app_icon.png');

	return (
		<Paper className={classes.picsection} square>
			{photoUrl ? (
				<Avatar src={photoUrl} variant='rounded' className={classes.userpic} />
			) : (
				<Icons.AccountBoxIcon className={classes.userpicNA}></Icons.AccountBoxIcon>
			)}
			<Typography align='center' variant='h5'>
				{userData?.fullName || user.displayName || 'Unknown Name'}
			</Typography>
			<Typography align='center' variant='subtitle1'>
				{userData?.email || user.email}
			</Typography>
			{isAdmin(userData) ? (
				<Typography color='primary' align='center' variant='subtitle1'>
					[Administrator]
				</Typography>
			) : null}
		</Paper>
	);
};

function mapStateToProps({ dataState }: ApplicationState) {
	let { userStore } = dataState;

	return {
		userData: userStore.userData,
		user: userStore.user,
	};
}

export const UserPicInfo = connect(mapStateToProps)(Component);
