import { HeaderControls, HeaderModule } from 'components/header/anand.header';
import { ModelClass } from 'core/anand.model';
import { overlay_panel } from 'core/anand.overlay_panel';
import { Records } from 'core/anand.records';
import { utils as Utils } from 'core/anand.utils';
import firebase from '../../../fb/initconfig';
import { addFormHTML, briefTileHTML, detailFormHTML, detailTileHTML } from './viewTile';

export const RecordsHandler = (function ($) {
	var configMap = {
		publication: {
			name: 'Amar Satya Sandesh',
			fq: 2,
			starting: 5,
		},
	};

	var stateMap: any = {
		systemId: 'subscriber',
	};

	function initModule(props?, updateRecordsState?) {
		HeaderModule.initModule([
			HeaderControls.addRecordBtn,
			HeaderControls.selectDropdown,
			HeaderControls.printBtn,
			HeaderControls.sortBtn,
		]);

		stateMap.userData = props.userData;

		($.fn as any).intlTelInput.loadUtils('/assets/js/lib/IntlTelInput/utils.js');

		Records.projectTitle = 'Amar Satya Sandesh Subscribers';

		Records.getAnySearchFilter = getAnySearchFilter;
		Records.defaultSortOrder = 'name';
		Records.IdentifyingFieldName = 'receipt_no';

		Records.sortingFieldsMap = {
			name: 'Name',
			reader_id: 'Reader Id',
			through_id: 'Through',
			entry_date: 'Entry Date',
			'country, state, city, address': 'Address',
			old_subscriber_no: 'Old No',
		};

		Records.searchFieldsMap = {
			name: 'Name',
			reader_id: 'Reader Id',
			through: 'Through',
			entry_date: 'Entry Date',
			city: 'City',
			state: 'State',
			pincode: 'Pin Code',
			email: 'Email',
			phone: 'Phone',
			old_subscriber_no: 'Old No',
			is_free: 'Free',
			markings: { filter: 'has', text: 'Marking' },
		};

		Records.isSearchDateTypeColumn = function (searchBy) {
			if (searchBy === 'entry_date' || searchBy === 'markings' || searchBy === 'mark_date') {
				return true;
			} else {
				return false;
			}
		};

		function printAddrLabels() {
			Records.refreshRecordsAndReposition(true);

			var printConfig =
				'<div id="print-configs">\n\
									<div class="col-xs-12">\n\
										<label class="checkbox">\n\
											<input style="width:auto" type="checkbox" class="address-label-showcontact" />\n\
											Add Contact\n\
										</label>\n\
									</div>';

			var printBtn =
				'<button id="print_records_btn" class="button btn btn-primary">' +
				'<span><span>Print</span></span>' +
				'</button>';
			var markBtn =
				'<button id="mark_btn" class="button btn btn-success">' +
				'<span><span>Mark Records</span></span>' +
				'</button>';
			var unMarkBtn =
				'<button id="unmark_btn" class="button btn btn-warning">' +
				'<span><span>Unmark Records</span></span>' +
				'</button>';

			printConfig += '<iframe id="print-preview" name="print-preview" src="about:blank"></iframe>';
			printConfig += '</div>';

			var printConfigObj = {
				contentHtml: printConfig,
				id: 'print_config',
				title: 'Print Address Labels',
				headerBtnHtml: unMarkBtn + markBtn + printBtn,
				afterAppend: function () {
					updatePrintLabelPreview(null);
				},
			};

			overlay_panel.showPanel(printConfigObj);

			$('#print-configs select').on('change', updatePrintLabelPreview);
			$('#print-configs input').on('change', updatePrintLabelPreview);

			$('#print_records_btn').click(function () {
				updatePrintLabelPreview(null, true);
			});

			$('#mark_btn').click(function () {
				autoMarkAll(false);
			});

			$('#unmark_btn').click(function () {
				autoMarkAll(true);
			});
		}

		function autoMarkAll(unmark) {
			var date = new Date();
			var month = date.getMonth() + 1;
			var year = date.getFullYear();

			if (month % 2 === 0) {
				month++;
				if (month === 13) {
					month = 1;
					year++;
				}
			}

			let monthStr = month.toString();

			if (month < 10) {
				monthStr = '0' + month;
			}

			var records = Records.getCurrentRecords();

			var data = {
				records: [],
				date: year + '-' + monthStr + '-01',
			};

			for (var i = 0; i < records.length; i++) {
				data.records.push({
					id: records[i]['id'],
					copies: unmark ? 0 : records[i]['copies'],
				});
			}

			Utils.showLoading(true);
			let Model = ModelClass.Instance(stateMap.systemId);
			Model.markAll(records, unmark, year + '-' + monthStr + '-01', function () {
				Records.refreshRecords();
				Utils.showLoading(false);
			});
		}

		function getSelectedPrintLabelConfigs() {
			var printConfigs = {
				page_margins: {
					left: 0.5,
					right: 0.5,
					top: 0.7,
					bottom: 0.7,
				},
				label_margins: {
					horizontal: 0.2,
					vertical: 0.3,
				},
				label_height: 4.45,
				font_size: 15,
				font_type: 'helvetica',
				labels_pp: 12,
				bulk_users_bundle: 25,
				bulk_users_last_bundle: 28,
				show_contact: $('.address-label-showcontact').is(':checked'),
			};

			return printConfigs;
		}

		function updatePrintLabelPreview(e, autoPrint?) {
			var data = JSON.parse(JSON.stringify(Records.getCurrentRecords()));

			if (typeof autoPrint === 'undefined') {
				data = data.slice(0, 12);
			}

			var configs = getSelectedPrintLabelConfigs();

			let style = `<style>
				body {
					background-color: rgba(0, 0, 0, 0);		
					line-height: 1.42857143;
					color: #333;				
				}

				* {
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
				}

				.row {
					margin-right: -15px;
					margin-left: -15px;
				}

				.row:before, .row:after {
					display: table;
					content: " ";
				}

				.col-xs-12 {
					width: 100%;
				}
				
				.col-xs-6 {
					width: 50%;
				}

				.col-xs-6, .col-xs-12 {
					float: left;
					position: relative;
					min-height: 1px;
					padding-right: 15px;
					padding-left: 15px;
				}
				
				.print-top-div {
					background-color: white;
					padding: 0px 40px;
					width: 8.27in;
					position: relative;
					height: 11.69in;
					padding: 0.7cm 0.4cm 0.4cm 0.4cm;
					margin-left: 0;
					margin-right: 0;
					page-break-after: always;
				}
				
				.bulk-count {
					font-size: 0.9em;
				}
				
				label {
					font-weight: 700;
				}

				.print-top-div .title.validity {
					float: right;
				}
				
				.print-top-div .reader-id-row {
					border-bottom: 1px solid grey;
					margin-bottom: 5px;
				}
				
				.print-names {
					text-decoration: underline;
				}
				
				.print-top-div .field {
					padding-top: 30px;
				}
				
				.print-top-div .title {
					font-size: 90%;
					text-align: left;
					padding-right: 0px;
					float: left;
					margin-bottom: 0;
				}
				
				.print-top-div .value {
					border-bottom: 1px dotted black;
					display: block;
					overflow: hidden;
					padding-left: 15px;
				}
				
				.print-top-div .print-address-label {
					padding: 10px;
					margin: 0 0.1cm 0.3cm 0.1cm;
					height: auto;
				}
				
				.print-top-div .print-label-container {
					margin: 0;
					padding: 0;
					height: 4.75cm;
					overflow: hidden;
				}
				
				.print-addresses-dummy {
					position: absolute;
					top: 100%;
				}
			</style>`;
			var headHtml = '<head>' + style + '</head>';
			var html = headHtml + '<body>';

			var topDiv = $(`<div class='print-top-div row'>${style}</div>`);
			topDiv.css('font-size', configs.font_size);
			topDiv.css('font-family', configs.font_type);
			$('body').append(topDiv);

			for (var i = 0; i < data.length; i++) {
				if (i % configs.labels_pp === 0) {
					html += '<div class="print-top-div row">';
				}

				var copies = parseInt(data[i]['copies']);
				var from = 1,
					to = 0;
				var addrLabel;
				if (copies > 1) {
					var remaining = copies;
					while (remaining > 0) {
						if (remaining <= configs.bulk_users_last_bundle) {
							to = copies;
							remaining = 0;
							addrLabel = printOneRecord(data[i], copies, from, to);
						} else {
							to = to + configs.bulk_users_bundle;
							remaining = remaining - configs.bulk_users_bundle;
							addrLabel = printOneRecord(data[i], copies, from, to);
						}
						html = addOneLabelToPage(html, topDiv, addrLabel);
						from = to + 1;
					}
				} else {
					addrLabel = printOneRecord(data[i]);
					html = addOneLabelToPage(html, topDiv, addrLabel);
				}
			}

			function addOneLabelToPage(html, topDiv, addrLabel) {
				topDiv.append(addrLabel);

				var $label = addrLabel.find('.print-address-label');

				while (parseInt(addrLabel.css('height')) < parseInt($label.css('height')) + 12) {
					$label.css('font-size', parseInt($label.css('font-size')) - 0.2);
				}
				//				$label.css('font-size', parseInt($label.css('font-size')) - 0.5);
				$label.css('height', configs.label_height + 'cm');

				html += topDiv.html();
				addrLabel.remove();

				if ((i + 1) % configs.labels_pp === 0) {
					html += '</div>';
				}

				return html;
			}

			html += '</div></body>';
			topDiv.remove();

			var iFrameWindow = window.frames['print-preview'];
			var doc = iFrameWindow.document;
			$('body', doc).html(html);

			$('body', doc).find('.print-top-div').css('font-size', configs.font_size);
			$('body', doc).find('.print-top-div').css('font-family', configs.font_type);

			$('#print-preview').css('height', '12in');

			if (typeof autoPrint !== 'undefined' && autoPrint === true) {
				setTimeout(function () {
					iFrameWindow.focus();
					iFrameWindow.print();
				}, 1000);
			}

			function printOneRecord(record, total?, from?, to?) {
				var address = '';
				if (record['address'] !== null) {
					address = record['address'].replace(/\n/g, '</br>');
				}

				var cityDetails = '';
				var comma = '';
				if (record['country'] && record['country'].toLowerCase().trim() !== 'india') {
					if (record['city']) {
						cityDetails += record['city'];
						comma = '</br>';
					}
					if (record['state']) {
						cityDetails += comma + record['state'];
						comma = ' – ';
					}
					if (record['pincode']) {
						cityDetails += comma + record['pincode'];
					}
					comma = '</br>';
					cityDetails += comma + '( ' + record['country'] + ' )';
				} else {
					if (record['tehsil']) {
						cityDetails += record['tehsil'];
						comma = ', ';
					}
					if (record['district']) {
						cityDetails += comma + record['district'];
						comma = '</br>';
					}
					if (record['city']) {
						cityDetails += comma + record['city'];
						comma = ', ';
					}
					if (record['state']) {
						cityDetails += comma + record['state'];
						comma = ' ';
					}
					cityDetails += comma + '( India )';
					comma = ' – ';
					if (record['pincode']) {
						cityDetails += comma + record['pincode'];
					}
				}

				var bulkStr = '';
				if (total && total > 1) {
					var bulkUserClass = 'bulk-count';
					if (record['bulk_users'] && record['bulk_users'].length >= from) {
						bulkUserClass += ' print-names';
					}
					bulkStr = "<span class='" + bulkUserClass + "'>(" + from + '-' + to + '/' + total + ')</span>';
				}

				var html =
					"<div class='col-xs-6 print-label-container'><div class='print-address-label'>" +
					"<div class='row'>";

				html +=
					"<div class='col-xs-12 reader-id-row'>" +
					"<span class='title'>Reader ID: <label>" +
					record['reader_id'] +
					'</label> ' +
					bulkStr +
					'</span>' +
					"<span class='title validity'>Valid Till: <label>" +
					Utils.getDateStr(record['end_year'], record['end_month']) +
					'</label></span>' +
					'</div>' +
					"<div class='col-xs-12'>" +
					"<span class='title'>" +
					record['name'] +
					'</span>' +
					'</div>' +
					'</div>' +
					"<div class='row'>" +
					"<div class='col-xs-12'>" +
					"<span class='title address-label'>" +
					address +
					'</span>' +
					'</div>' +
					'</div>' +
					"<div class='row'>" +
					"<div class='col-xs-12'>" +
					"<span class='title'>" +
					cityDetails +
					'</span>' +
					'</div>' +
					'</div>';
				if (configs.show_contact === true) {
					html +=
						"<div class='row'>" +
						"<div class='col-xs-12'>" +
						"<span class='title'>" +
						record['phone'] +
						'</span>' +
						'</div>' +
						'</div>';
				}

				html += '</div></div>';

				return $(html);
			}
		}

		Records.printOptions = {
			fieldNames: [
				'S. No.',
				'Reader ID',
				'Receipt No',
				'Pending',
				'Name',
				'Address',
				'Email',
				'Contact',
				'Through',
				'Category',
				'Language',
				'Period',
				'Comments',
				'Users',
				'Markings',
				'Status',
			],
			fieldKeys: [
				'id',
				'reader_id',
				'receipt_no',
				'renew_pending',
				'name',
				'full_address',
				'email',
				'phone',
				'through',
				'category',
				'language',
				'period',
				'comments',
				'bulk_users',
				'markings',
				'subscription_status',
			],
			defaultFieldWidths: {
				id: 60,
				reader_id: 120,
				receipt_no: 0,
				renew_pending: 100,
				name: 120,
				full_address: 0,
				email: 0,
				phone: 0,
				through: 250,
				category: 120,
				language: 120,
				period: 150,
				comments: 0,
				bulk_users: 0,
				markings: 0,
				subscription_status: 0,
			},
			defaultHeader: 'Amar Sandesh Records',
			beforePrintHook: function (data, forExport) {
				for (var i = 0; i < data.length; i++) {
					data[i]['id'] = i + 1 + '.';

					data[i]['full_address'] =
						data[i]['address'] +
						'\n' +
						data[i]['tehsil'] +
						',' +
						data[i]['district'] +
						'\n' +
						data[i]['city'] +
						', ' +
						data[i]['state'] +
						' ' +
						data[i]['country'];
					if (data[i]['pincode'] && data[i]['pincode'] != '0') {
						data[i]['full_address'] += ' – ' + data[i]['pincode'];
					}

					data[i]['period'] =
						data[i]['start_month'] +
						'/' +
						data[i]['start_year'] +
						' – ' +
						data[i]['end_month'] +
						'/' +
						data[i]['end_year'];

					data[i]['through'] = getThroughFromId(data[i]['through_id']);
					data[i]['category'] = getCategoryFromId(data[i]['category_id']);

					data[i]['language'] = data[i]['language'].toUpperCase();

					var newLineChar = ', ';
					if (forExport) {
						newLineChar = '\n';
					}
					if (typeof data[i]['bulk_users'] !== 'undefined') {
						var bulk_users = data[i]['bulk_users'];

						var html = '';
						var newLine = '';
						for (var j = 0; j < bulk_users.length; j++) {
							html += newLine + bulk_users[j]['user_name'];
							newLine = newLineChar;
						}
						data[i]['bulk_users'] = html;
					} else {
						data[i]['bulk_users'] = '';
					}

					if (typeof data[i]['markings'] !== 'undefined') {
						var markings = data[i]['markings'];

						var html = '';
						var newLine = '';
						for (var j = 0; j < markings.length; j++) {
							html +=
								newLine + markings[j]['mark_date'].substring(0, 7) + ':' + markings[j]['mark_count'];
							newLine = newLineChar;
						}
						data[i]['markings'] = html;
					} else {
						data[i]['markings'] = '';
					}
				}

				return data;
			},
		};

		Records.exportOptions = {
			fieldKeys: [
				'id',
				'created_by',
				'name',
				'address',
				'city',
				'tehsil',
				'district',
				'state',
				'country',
				'pincode',
				'email',
				'phone',
				'reader_id',
				'old_subscriber_no',
				'is_free',
				'through_id',
				'category_id',
				'language',
				'receipt_no',
				'issues',
				'balance_issues',
				'copies',
				'rate',
				'balance',
				'amount',
				'start_month',
				'start_year',
				'end_month',
				'end_year',
				'entry_date',
				'comments',
				'bulk_users',
				'markings',
				'subscription_status',
			],
			defaultFileName: 'Amar Sandesh Records',
		};

		Records.hooks.afterHeaderConfigure = function (controlsHeader) {
			var printMenu = $(
				'<span class="dropdown hidden-sm hidden-xs">\n\
                    <a class="btn selection-btn dropdown-toggle" data-toggle="dropdown">\n\
						<span class="glyphicon glyphicon-print"></span>\n\
                        <span class="menu-text">Print</span>\n\
                        <span class="caret"></span>\n\
                    </a>\n\
                    <ul class="dropdown-menu">\n\
                        <li><a class="btn menu-text print-records">\n\
                                <span class="menu-text">Records</span>\n\
                            </a>\n\
                        </li>\n\
                        <li>\n\
                            <a class="btn menu-text print-addresses">\n\
                                <span class="menu-text">Addresses</span>\n\
                            </a>\n\
                        </li>\n\
                    </ul>\n\
                </span>'
			);

			var ptintBtn = controlsHeader.find('.print-btn');
			ptintBtn.hide();
			ptintBtn.after(printMenu);

			printMenu.find('.print-records').click(function () {
				ptintBtn.click();
			});
			printMenu.find('.print-addresses').click(printAddrLabels);

			stateMap.selectionDropdown = controlsHeader.find('#selection-dropdown select');
			stateMap.selectionDropdown.find('option').remove();
			stateMap.selectionDropdown.append($("<option value='All' selected='selected'>All Records</option>"));
			stateMap.selectionDropdown.append($("<option value='Active'>Active</option>"));
			stateMap.selectionDropdown.append($("<option value='Expiring'>Expiring</option>"));
			stateMap.selectionDropdown.append($("<option value='Expired'>Expired</option>"));
		};

		Records.hooks.specificSearchHook = function (searchBy, query) {
			if (searchBy === 'through') {
				searchBy = 'through_id';

				if (!query) {
					return {
						searchBy: searchBy,
						query: query,
					};
				}

				var throughIds = stateMap.throughModel
					.getRecords(
						[[{ name: { likenocase: query } }, { code: { likenocase: query } }]],
						'id logical',
						null,
						true
					)
					.distinct('id');
				query = throughIds;
			}

			if (searchBy === 'is_free') {
				if (query) {
					var lQuery = query.toLowerCase();
					if (lQuery === 'yes' || lQuery === 'y' || lQuery === 'true') {
						query = '1';
					} else if (lQuery === 'no' || lQuery === 'n' || lQuery === 'false') {
						query = '0';
					}
				}
			}

			return {
				searchBy: searchBy,
				query: query,
			};
		};

		Records.hooks.beforeAddTileShow = function (addTile, record) {
			setInitialData(addTile);

			if (record && record['action'] === 'renew') {
				addTile
					.find(
						'.date-detail .starting-month-value select option[value="' +
							((parseInt(record['end_month']) + configMap.publication.fq) % 12) +
							'"]'
					)
					.attr('selected', 'selected');
				addTile.find("input[name='start_year']").val(parseInt(record['end_year']));
				addTile.find("input[name='copies']").val(parseInt(record['copies']));

				if (typeof record['bulk_users'] !== 'undefined') {
					var users = record['bulk_users'];
					var usersDivId = Records.populateMultiAddFields(
						addTile.find('.main-details .user-detail'),
						users.length
					);
					for (var i = 0; i < users.length; i++) {
						var userDiv = addTile.find('#' + usersDivId + (i + 1));
						userDiv.find('input[name="user_name[]"]').val(users[i]['user_name']);
					}
				}
			}

			configurePincodeMapping(addTile);
			configureTelInput(addTile);
			configureTypeAheads(addTile);
			configureCalculation(addTile);
			configureAutoIncrementIds(addTile);

			if (record) {
				if (record['action']) {
					addTile = populateSubscriberDetails(addTile, record);
					populateMarkingsTable(addTile, null, true);
				}

				if (record['action'] === 'renew') {
					addTile.find('input[name="reader_id"]').val(record['reader_id']);
					addTile.find('input[name="is_free_ignore"]').prop('checked', record['is_free'] === '1');
					addTile.find('input[name="is_free"]').val(record['is_free']);

					addTile
						.find('.through-detail .through-value input')
						.typeahead('val', getThroughFromId(record['through_id']));
					addTile.find('input[name="through_id"]').val(record['through_id']);

					addTile
						.find(
							'.category-detail select[name="category_id"] option[value="' + record['category_id'] + '"]'
						)
						.attr('selected', 'selected');
					addTile
						.find('.language-detail select[name="language"] option[value="' + record['language'] + '"]')
						.attr('selected', 'selected');

					addTile.find('select[name="category_id"]').trigger('change');
					addTile.find('select[name="language"]').trigger('change');

					addTile.find('#renewed_record_index').remove();
					addTile.append(
						$('<input type="hidden" id="renewed_record_index" value="' + record['data_index'] + '">')
					);
					addTile.find('#renewed_record_id').remove();
					addTile.append($('<input type="hidden" id="renewed_record_id" value="' + record['id'] + '">'));
				}
			} else {
				populateMarkingsTable(addTile, record, true);
			}

			return addTile;
		};

		Records.hooks.afterAddRecord = function (record, addTile) {
			printReceipt(record);

			var $renewedRecordIndex = addTile.find('#renewed_record_index');
			if ($renewedRecordIndex.length > 0) {
				if ($renewedRecordIndex.val()) {
					var index = parseInt($renewedRecordIndex.val());
					var records = Records.getCurrentRecords();
					var renewedRecord = records[index];
					renewedRecord['renew_pending'] -= record['copies'];

					let Model = ModelClass.Instance(stateMap.systemId);
					Model.updateLocalRecord(renewedRecord[Records.IdFieldName], renewedRecord);
					Records.refreshRecordsAndReposition();
				}
			}
		};

		Records.hooks.configureHook = function () {
			configureMainFilter();
		};

		Records.getAdditionalSearchFilters = function () {
			return getMainFilter();
		};

		Records.detailTileSelector = '#amar-detail-record';
		Records.briefTileSelector = '#amar-brief-record';
		Records.addTileSelector = '#amar-add-record';
		Records.detailFormSelector = '#amar-detail-record-form';
		Records.imageUploadSelector = null;

		Records.detailTileHTML = detailTileHTML;
		Records.briefTileHTML = briefTileHTML;
		Records.detailFormHTML = detailFormHTML;
		Records.addFormHTML = addFormHTML;

		Records.populateBriefTile = populateBriefTile;
		Records.populateDetailTile = populateDetailTile;
		Records.populateDetailForm = populateDetailForm;
		Records.getExtraDetailTileButtons = getExtraDetailTileButtons;

		Records.updateRecordsState = updateRecordsState;

		let ThroughModel = ModelClass.Instance('through');
		stateMap.throughModel = ThroughModel;
		stateMap.throughModel.initModule({
			systemId: 'through',
			forceUpdate: configMap['forceUpdate'],
			prepareRecord: prepareRecord,
		});
		stateMap.throughModel.getRecords([], '');

		let CategoryModel = ModelClass.Instance('category');
		stateMap.categoryModel = CategoryModel;
		stateMap.categoryModel.initModule({
			systemId: 'category',
			forceUpdate: configMap['forceUpdate'],
			prepareRecord: prepareRecord,
		});
		stateMap.categoryModel.getRecords([], '');

		let RateModel = ModelClass.Instance('rate');
		stateMap.rateModel = RateModel;
		stateMap.rateModel.initModule({
			systemId: 'rate',
			forceUpdate: configMap['forceUpdate'],
			prepareRecord: prepareRecord,
		});
		stateMap.rateModel.getRecords([], '');

		let PincodeModel = ModelClass.Instance('pincode');
		stateMap.pincodeModel = PincodeModel;
		stateMap.pincodeModel.initModule({
			systemId: 'pincode',
			forceUpdate: configMap['forceUpdate'],
			prepareRecord: prepareRecord,
		});
		stateMap.pincodeModel.getRecords([], '');

		Records.initModule({
			systemId: stateMap.systemId,
			prepareRecord: prepareRecord,
			props: props,
			addTileFormValidationRules: {
				phone_ignore: {
					maxlength: 255,
					required: true,
					// telInputValidator: true,
				},
			},
		});
	}

	function prepareRecord(record) {
		record['entry_date'] = Utils.formatDate(record['entry_date']);
		record['created_by'] = firebase.auth().currentUser?.uid;
		record['through'] = getThroughFromId(record['through_id']);

		var startYear = record.start_year;
		var startMonth = record.start_month;
		if (parseInt(startMonth) < 10) {
			startMonth = '0' + startMonth;
		} else {
			startMonth = '' + startMonth;
		}

		var endYear = record.end_year;
		var endMonth = record.end_month;
		if (parseInt(endMonth) < 10) {
			endMonth = '0' + endMonth;
		} else {
			endMonth = '' + endMonth;
		}

		record.start_date = startYear + '-' + startMonth;
		record.end_date = endYear + '-' + endMonth;

		Records.prepareMultiFieldForAdd(record, 'bulk_users', ['user_name']);
		Records.prepareMultiFieldForAdd(record, 'markings', ['mark_count', 'mark_date']);
		return record;
	}

	function getAnySearchFilter(query) {
		var throughIds = stateMap.throughModel
			.getRecords([[{ name: { likenocase: query } }, { code: { likenocase: query } }]], 'id logical', null, true)
			.distinct('id');

		return [
			{ name: { likenocase: query } },
			{ reader_id: { leftnocase: query } },
			{ old_subscriber_no: { leftnocase: query } },
			{ through_id: throughIds },
			{ entry_date: { likenocase: query } },
			{ address: { likenocase: query } },
			{ city: { leftnocase: query } },
			{ state: { leftnocase: query } },
			{ country: { leftnocase: query } },
			{ pincode: { leftnocase: query } },
			{ email: { leftnocase: query } },
			{ phone: { leftnocase: query } },
			{ receipt_no: { leftnocase: query } },
			{ comments: { likenocase: query } },
			{ markings: { has: query } },
			{ subscription_status: { leftnocase: query } },
		];
	}

	async function populateBriefTile(sampleTile, record) {
		sampleTile.find('.self-detail .value').html(record['name']);

		sampleTile.find('.subscription-detail .value').html(record['reader_id']);

		return sampleTile;
	}

	async function populateDetailTile(sampleTile, recordOrig) {
		var record = {};
		for (var key in recordOrig) {
			if (recordOrig[key] == null || recordOrig[key] == '') {
				record[key] = '&nbsp';
			} else {
				record[key] = recordOrig[key];
			}
		}

		sampleTile.find('.self-detail .value').html(record['name']);

		recordOrig['address'] = record['address'] = record['address'].replace(/\\n/g, '\n');
		sampleTile.find('.address-detail .value').html(record['address'].replace(/\n/g, '</br>'));

		sampleTile.find('.city-detail .city-value').html(record['city']);
		sampleTile.find('.city-detail .state-value').html(record['state']);

		sampleTile.find('.city-detail .tehsil-value').html(record['tehsil']);
		sampleTile.find('.city-detail .country-value').html(record['country']);

		sampleTile.find('.city-detail .district-value').html(record['district']);
		sampleTile.find('.city-detail .pincode-value').html(record['pincode']);

		sampleTile.find('.email-detail .email-value').html(record['email']);
		sampleTile.find('.phone-detail .phone-value').html(record['phone']);

		var readerId = record['reader_id'];
		if (recordOrig['old_subscriber_no'] && recordOrig['old_subscriber_no'].length > 0) {
			readerId += ' (OLD#: ' + record['old_subscriber_no'] + ')';
		}

		if (recordOrig['is_free'] === '1') {
			readerId += " <span style='color:green'>[Free]</span>";
		}
		if (recordOrig['subscription_status'] === 'paused') {
			readerId += " <span style='color:crimson'>[Paused]</span>";
		}

		sampleTile.find('.reader-id .value').html(readerId);

		sampleTile.find('.through-detail .through-value').html(getThroughFromId(record['through_id']));
		sampleTile.find('.category-detail .category-value').html(getCategoryFromId(record['category_id']));
		sampleTile.find('.language-detail .language-value').html(record['language']);
		sampleTile.find('.receipt-detail .receipt-value').html(record['receipt_no']);

		var monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		var starting = monthArr[parseInt(record['start_month']) - 1] + ', ' + record['start_year'];
		var ending = monthArr[parseInt(record['end_month']) - 1] + ', ' + record['end_year'];

		sampleTile.find('.date-detail .starting-value').html(starting);
		sampleTile.find('.date-detail .ending-value').html(ending);

		sampleTile.find('.copies-detail .copies-value').html(record['copies']);
		sampleTile
			.find('.copies-detail .total-issues-value')
			.html(parseInt(record['issues']) + parseInt(record['balance_issues']));

		sampleTile.find('.issues-detail .issues-value').html(record['issues']);
		sampleTile.find('.issues-detail .bal-issues-value').html(record['balance_issues']);

		sampleTile.find('.amount-detail .amount-value').html(record['amount']);
		sampleTile.find('.amount-detail .balance-value').html(record['balance']);
		sampleTile.find('.amount-detail .rate-value').html(record['rate']);
		sampleTile.find('.amount-detail .total-value').html(parseInt(record['amount']) + parseInt(record['balance']));

		sampleTile
			.find('.byhand-detail .byhand-value')
			.html(getByHandString(record['issues'], record['copies'], record['start_month']));

		var dateAr = record['entry_date'].split('-');
		var dateStr = Utils.getDateStr(dateAr[0], dateAr[1], dateAr[2]);
		sampleTile.find('.entry-date-detail .entry-date-value').html(dateStr);

		sampleTile.find('.comments-detail .value').html(record['comments']);

		if (
			typeof recordOrig['bulk_users'] !== 'undefined' &&
			recordOrig['bulk_users'] !== null &&
			recordOrig['bulk_users'].length > 0
		) {
			var users = recordOrig['bulk_users'];
			for (var i = 0; i < users.length; i++) {
				var userRow = sampleTile.find('.main-details .user-detail #user-data-detail').clone();
				userRow.attr('id', 'user-data-detail' + '-' + i);
				userRow.find('.value').html(users[i]['user_name']);
				userRow.removeClass('hidden');
				sampleTile.find('.main-details .user-detail').append(userRow);
			}
		} else {
			sampleTile.find('.bulk-users-section-detail').hide();
		}

		populateMarkingsTable(sampleTile, record, false);

		return sampleTile;
	}

	function populateDetailForm(sampleTile, record) {
		delete record['action'];

		Records.hooks.beforeAddTileShow(sampleTile, record);

		sampleTile = populateSubscriberDetails(sampleTile, record);
		populateMarkingsTable(sampleTile, record, true);

		sampleTile.find('input[name="reader_id"]').val(record['reader_id']);
		sampleTile.find('input[name="is_free_ignore"]').prop('checked', record['is_free'] === '1');
		sampleTile.find('input[name="is_free"]').val(record['is_free']);
		sampleTile
			.find('select[name="subscription_status"] option[value="' + record['subscription_status'] + '"]')
			.attr('selected', 'selected');

		sampleTile
			.find('.through-detail .through-value input')
			.typeahead('val', getThroughFromId(record['through_id']));
		sampleTile.find('input[name="through_id"]').val(record['through_id']);

		sampleTile
			.find('.category-detail select[name="category_id"] option[value="' + record['category_id'] + '"]')
			.attr('selected', 'selected');
		sampleTile
			.find('.language-detail select[name="language"] option[value="' + record['language'] + '"]')
			.attr('selected', 'selected');

		sampleTile.find('.receipt-detail .receipt-value input').val(record['receipt_no']);

		sampleTile
			.find('.date-detail .starting-month-value select option[value="' + record['start_month'] + '"]')
			.attr('selected', 'selected');
		sampleTile
			.find('.date-detail .ending-month-value select option[value="' + record['end_month'] + '"]')
			.attr('selected', 'selected');
		sampleTile.find('.date-detail .starting-year-value input').val(record['start_year']);
		sampleTile.find('.date-detail .ending-year-value input').val(record['end_year']);

		sampleTile.find('input[name="renew_pending"]').val(record['renew_pending']);

		sampleTile
			.find('.copies-detail .total-issues-value input')
			.val(parseInt(record['issues']) + parseInt(record['balance_issues']));

		sampleTile.find('.issues-detail .issues-value input').val(record['issues']);
		sampleTile.find('.issues-detail .bal-issues-value input').val(record['balance_issues']);
		sampleTile.find('input[name="copies"]').val(record['copies']).trigger('change');
		if (record['copies'] > 1) {
			sampleTile.find('.bulk-users-section').show();
		}

		sampleTile.find('.amount-detail .amount-value input').val(record['amount']);
		sampleTile.find('.amount-detail .balance-value input').val(record['balance']);
		sampleTile.find('.amount-detail .rate-value input').val(record['rate']);
		sampleTile
			.find('.amount-detail .total-value input')
			.val(parseInt(record['amount']) + parseInt(record['balance']));

		var dateAr = record['entry_date'].split('-');
		var date = new Date(parseInt(dateAr[0]), parseInt(dateAr[1]) - 1, parseInt(dateAr[2]));
		sampleTile.find('.date-detail input[name="entry_date"]').attr('value', record['entry_date']);
		sampleTile.find('.date-detail input[name="entry_date"]').datepicker('update', date);

		sampleTile.find('.comments-detail .value input').val(record['comments']);

		if (typeof record['bulk_users'] !== 'undefined') {
			var users = record['bulk_users'];
			var usersDivId = Records.populateMultiAddFields(
				sampleTile.find('.main-details .user-detail'),
				users.length
			);
			for (var i = 0; i < users.length; i++) {
				var userDiv = sampleTile.find('#' + usersDivId + (i + 1));
				userDiv.find('input[name="user_name[]"]').val(users[i]['user_name']);
			}
		}

		return sampleTile;
	}

	function populateSubscriberDetails(sampleTile, record) {
		sampleTile.find('.self-detail .value input').val(record['name']);

		sampleTile.find('.address-detail .value textarea').val(record['address']);

		sampleTile.find('.city-detail .city-value input').typeahead('val', record['city']);
		sampleTile.find('.city-detail .state-value input').typeahead('val', record['state']);

		sampleTile.find('.city-detail .tehsil-value input').val(record['tehsil']);
		sampleTile.find('.city-detail .country-value input').typeahead('val', record['country']);
		sampleTile.find('.city-detail .country-value input').trigger('change');

		sampleTile.find('.city-detail .district-value input').val(record['district']);
		sampleTile.find('.city-detail .pincode-value input').typeahead('val', record['pincode']);

		sampleTile.find('.email-detail .email-value input').val(record['email']);
		sampleTile.find('.phone-detail .phone-value input').intlTelInput('setNumber', record['phone']);
		sampleTile.find('input[name="phone"]').val(record['phone']);

		return sampleTile;
	}

	function getThroughFromId(throughId) {
		var through = stateMap.throughModel.getRecords([{ id: throughId }], 'id logical');
		if (through.length > 0) {
			return through[0].name;
		}
		return '';
	}

	function getCategoryFromId(categoryId) {
		var category = stateMap.categoryModel.getRecords([{ id: categoryId }], 'id logical');
		if (category.length > 0) {
			return category[0].name;
		}
		return '';
	}

	function configureMainFilter() {
		$("select[name='main_filter_dropdown']").on('change', function () {
			Records.refreshRecords();
		});
	}

	function getMainFilter() {
		var mainFilter = $("select[name='main_filter_dropdown']");
		var mainFilterVal = mainFilter.val();

		var curYear = new Date().getFullYear();
		var curMonth: number | string = new Date().getMonth() + 1;
		var activeMonth = curMonth % 12 === 0 ? 'next' : 'this';

		if (curMonth < 10) {
			curMonth = '0' + curMonth;
		} else {
			curMonth = '' + curMonth;
		}

		var curDate = curYear + '-' + curMonth;
		var nextYearDate = curYear + 1 + '-' + curMonth;

		var nextMonth: string | number = new Date().getMonth() + 2;
		if (nextMonth === 13) {
			nextMonth = 1;
			curYear += 1;
		}
		if (nextMonth < 10) {
			nextMonth = '0' + nextMonth;
		} else {
			nextMonth = '' + nextMonth;
		}

		var nextDate = curYear + '-' + nextMonth;

		var filters;
		switch (mainFilterVal) {
			case 'All':
				filters = {};
				return [filters];
			case 'Active':
				if (activeMonth === 'next') {
					filters = {
						start_date: { '<=': nextDate },
						end_date: { '>': curDate },
						subscription_status: { is: 'active' },
					};
				} else {
					filters = {
						start_date: { '<=': curDate },
						end_date: { '>=': curDate },
						subscription_status: { is: 'active' },
					};
				}
				return [filters];
			case 'Expired':
				filters = {
					end_date: { '<': curDate },
				};
				return [filters];
			case 'Expiring':
				filters = [
					{
						end_date: { '<=': nextYearDate },
					},
					{
						end_date: { '>': curDate },
					},
					{
						renew_pending: { '!is': '0' },
					},
					{
						renew_pending: { '!is': 0 },
					},
				];
				return filters;
		}
		return filters;
	}

	function getRecordStatus(record) {
		var curYear = new Date().getFullYear();
		var curMonth: string | number = new Date().getMonth() + 1;
		if (curMonth < 10) {
			curMonth = '0' + curMonth;
		} else {
			curMonth = '' + curMonth;
		}

		var curDate = curYear + '-' + curMonth;
		var nextYearDate = curYear + 1 + '-' + curMonth;

		var nextMonth: string | number = new Date().getMonth() + 2;
		if (nextMonth === 13) {
			nextMonth = 1;
			curYear += 1;
		}
		if (nextMonth < 10) {
			nextMonth = '0' + nextMonth;
		} else {
			nextMonth = '' + nextMonth;
		}

		var nextDate = curYear + '-' + nextMonth;

		if (record['end_date'] <= nextYearDate && record['end_date'] > curDate && record['renew_pending'] != '0') {
			return 'Expiring';
		}

		if (record['start_date'] <= curDate && record['end_date'] >= curDate) {
			return 'Active';
		}

		if (record['end_date'] < curDate) {
			return 'Expired';
		}

		if (record['start_date'] > nextDate) {
			return 'Upcoming';
		}

		return 'Unknown';
	}

	function setInitialData(tile) {
		tile.find("input[name='copies']").val(1);
		tile.find("input[name='country']").val('');
		tile.find("input[name='marking']").val(0);
		tile.find("input[name='balance_issues']").val(0);

		var curDate = new Date();
		tile.find('.date-detail input[name="entry_date"]').attr(
			'value',
			curDate.getFullYear() + '-' + (curDate.getMonth() + 1) + '-' + curDate.getDate()
		);
		tile.find('.date-detail input[name="entry_date"]').datepicker('update', curDate);

		var startingMonth = tile.find('select[name="start_month"]');
		var startingYear = tile.find('input[name="start_year"]');
		var endingMonth = tile.find('select[name="end_month"]');
		var endingYear = tile.find('input[name="end_year"]');

		var monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

		var curMonth = curDate.getMonth() + 1;
		curDate.setMonth(configMap.publication.starting - 1);
		if (curMonth < configMap.publication.starting) {
			curDate.setFullYear(curDate.getFullYear() - 1);
		}

		startingYear.val(curDate.getFullYear());
		endingYear.val(curDate.getFullYear() + 1);

		startingMonth.html('');
		endingMonth.html('');
		let mon = curDate.getMonth();
		for (var i = 0; i < 12; i += configMap.publication.fq) {
			startingMonth.append(
				'<option value="' +
					(mon + 1) +
					'"' +
					(configMap.publication.starting - 1 === mon ? 'selected="selected"' : '') +
					'>' +
					monthArr[mon] +
					'</option>'
			);
			endingMonth.append(
				'<option value="' +
					(mon + 1) +
					'"' +
					(configMap.publication.starting - configMap.publication.fq - 1 === mon
						? 'selected="selected"'
						: '') +
					'>' +
					monthArr[mon] +
					'</option>'
			);

			mon += configMap.publication.fq;
			mon %= 12;
		}
	}

	function configurePincodeMapping(tile) {
		var pincodeInput = tile.find("input[name='pincode']");
		var cityInput = tile.find("input[name='city']");
		var stateInput = tile.find("input[name='state']");
		var countryInput = tile.find("input[name='country']");
		var tehsilInput = tile.find("input[name='tehsil']");
		var destrictInput = tile.find("input[name='destrict']");

		pincodeInput.on('change', populateFromPincode);
		cityInput.on('change', populateFromCity);

		configureAllTypeaheads('country');

		function configureAllTypeaheads(field) {
			if (field === 'pincode') {
				return;
			}

			var fields = ['country', 'state', 'city', 'pincode'];
			var include = false;
			for (var i = 0; i < fields.length; i++) {
				if (fields[i] === field) {
					include = true;
				}

				if (include) {
					configureTypeaheads(fields[i]);
				}
			}
		}

		function populateFromPincode() {
			var pincode = pincodeInput.val();

			var records = stateMap.pincodeModel.getRecords([{ pincode: pincode }], 'pincode logical');

			if (records.length > 0) {
				cityInput.typeahead('val', records[0].city);
				stateInput.typeahead('val', records[0].state);
				countryInput.typeahead('val', records[0].country);
				countryInput.trigger('change');

				tehsilInput.val(records[0].tehsil);
				destrictInput.val(records[0].destrict);
			}
		}

		function populateFromCity() {
			var city = cityInput.val();

			var records = stateMap.pincodeModel.getRecords([{ city: city }], 'pincode logical');

			if (records.length > 0) {
				stateInput.typeahead('val', records[0].state);
				countryInput.typeahead('val', records[0].country);
				countryInput.trigger('change');

				tehsilInput.val(records[0].tehsil);
				destrictInput.val(records[0].destrict);
			}
		}

		function configureTypeaheads(field) {
			var substringMatcher = function () {
				return function findMatches(query, callback) {
					query = $.trim(query);

					var filter = {};
					if (query.length !== 0) {
						filter[field] = { leftnocase: query };
					}

					var country = countryInput.val();
					if (field !== 'country' && country.length > 0) {
						filter['country'] = country;

						var state = stateInput.val();
						if (field !== 'state' && state.length > 0) {
							filter['state'] = state;

							var city = cityInput.val();
							if (field !== 'city' && city.length > 0) {
								filter['city'] = city;
							}
						}
					}

					var filters = [filter];

					stateMap.pincodeModel.getRecords(filters, field + ' logical', function (records) {
						var matches = [];
						var valueMap = {};

						for (var i = 0; i < records.length; i++) {
							if (!valueMap[records[i][field]]) {
								matches.push({ value: records[i][field] });
								valueMap[records[i][field]] = true;
							}
						}

						callback(matches);
					});
				};
			};

			var fieldInput = tile.find("input[name='" + field + "']");
			fieldInput.typeahead('destroy');
			fieldInput.typeahead(
				{
					minLength: 0,
					hint: false,
					highlight: true,
				},
				{
					name: field,
					source: substringMatcher(),
					display: 'value',
					templates: {
						suggestion: getSuggestionHtml,
					},
				}
			);

			function getSuggestionHtml(obj) {
				var $html = $(
					'<div class="row">' +
						'<div class="col-xs-12 no-padding">' +
						'<div class="through-sugestion">' +
						obj.value +
						'</div>' +
						'</div>' +
						'</div>'
				);

				return $html;
			}

			fieldInput.on('typeahead:selected', function () {
				fieldInput.trigger('change');
				configureAllTypeaheads(field);
			});
		}
	}

	function configureTelInput(tile) {
		tile.find("input[type='tel']").intlTelInput({
			preferredCountries: ['in'],
			nationalMode: true,
			autoPlaceholder: true,
			//			utilsScript: "/application/themes/anand/assets/js/lib/IntlTelInput/utils.js"
		});

		tile.find("input[type='tel']").on('change', function () {
			tile.find("input[type='tel'] > input").intlTelInput('setNumber', $(this).val());
		});
	}

	function configureTypeAheads(tile) {
		var substringMatcher = function () {
			return function findMatches(query, callback) {
				query = $.trim(query);

				var filters = {};
				if (query.length !== 0) {
					filters = [{ code: { leftnocase: query } }, { name: { likenocase: query } }];
					// filters = [{ name: { likenocase: query } }];
				}

				stateMap.throughModel.getRecords([filters], 'name logical', function (records) {
					var matches = [];

					for (var i = 0; i < records.length; i++) {
						matches.push({ id: records[i]['id'], value: records[i]['name'], suffix: records[i]['code'] });
						// matches.push({ id: records[i]['id'], value: records[i]['name'] });
					}

					callback(matches);
				});
			};
		};

		tile.find("input[name='through']").typeahead(
			{
				minLength: 0,
				hint: false,
				highlight: true,
			},
			{
				name: 'through',
				source: substringMatcher(),
				limit: 1000,
				display: 'value',
				templates: {
					suggestion: getSuggestionHtml,
				},
			}
		);

		tile.find("input[name='through']").on('typeahead:selected', onTypeaheadSelect);

		function onTypeaheadSelect(e, suggestion, dataset) {
			$(this).parents('.subscription-detail').find("input[name='through_id']").val(suggestion['id']);
		}

		function getSuggestionHtml(obj) {
			var $html = $(
				'<div class="row">' +
					'<div class="col-xs-12 no-padding">' +
					'<div class="through-sugestion">' +
					obj.value +
					' (' +
					obj.suffix +
					')' +
					'</div>' +
					'</div>' +
					'</div>'
			);

			return $html;
		}
	}

	function configureAutoIncrementIds(tile) {
		var date = new Date();
		var timestamp = Date.now() - 1475193600000;
		var uuid: string = Math.round(timestamp / 1000).toString();
		uuid = idOf(parseInt(uuid)) + '' + (stateMap.userData?.nid ?? 0);

		tile.find("input[name='reader_id']").val(uuid);

		function idOf(i) {
			return (i >= 26 ? idOf(((i / 26) >> 0) - 1) : '') + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26 >> 0];
		}

		var languageInput = tile.find("select[name='language']");
		var categoryInput = tile.find("select[name='category_id']");
		var copiesInput = tile.find("input[name='copies']");
		var receiptInput = tile.find("input[name='receipt_no']");

		languageInput.on('change', calculateReceiptNo);
		categoryInput.on('change', calculateReceiptNo);
		copiesInput.on('change', calculateReceiptNo);

		calculateReceiptNo();

		function calculateReceiptNo() {
			var language = languageInput.val().substr(0, 1).toUpperCase();
			var copies = copiesInput.val();
			var categoryId = categoryInput.val();
			var category = stateMap.categoryModel.getRecords([{ id: categoryId }], 'id logical');

			if (category.length > 0) {
				var issues = category[0].issues_count;
				issues = issues < 10 ? '0' + issues : issues;

				receiptInput.val(language + '-' + copies + '-' + issues + '/' + uuid);
			}
		}
	}

	function configureRate(tile) {
		var countryInput = tile.find("input[name='country']");
		var categoryInput = tile.find("select[name='category_id']");

		countryInput.on('change', calculateRate);
		categoryInput.on('change', calculateRate);

		calculateRate();

		function calculateRate() {
			var country = countryInput.val();
			var categoryId = categoryInput.val();

			country = country?.length ? country : 'India';

			if (country.length !== 0) {
				var rates = stateMap.rateModel.getRecords(
					[{ country: country, category_id: categoryId }],
					'category_id logical'
				);
				if (rates.length === 0) {
					rates = stateMap.rateModel.getRecords(
						[{ country: 'Abroad', category_id: categoryId }],
						'category_id logical'
					);
				}

				if (rates.length > 0) {
					var rate = rates[0].rate;
					tile.find("input[name='rate']").val(rate).trigger('change');
				}
			}

			var category = stateMap.categoryModel.getRecords([{ id: categoryId }], 'id logical');
			var startingMonth = parseInt(tile.find("select[name='start_month']").val());
			var startingYear = parseInt(tile.find("input[name='start_year']").val());
			var totalMonths =
				startingYear * 12 + startingMonth + parseInt(category[0].period) - configMap.publication.fq;

			tile.find("select[name='end_month']").val(totalMonths % 12);
			tile.find("input[name='end_year']")
				.val(Math.floor(totalMonths / 12))
				.trigger('change');
		}
	}

	function configureCategories(tile) {
		var categories = stateMap.categoryModel.getRecords([], 'category_id logical');

		var categoryInput = tile.find("select[name='category_id']");
		categoryInput.html('');
		for (var i = 0; i < categories.length; i++) {
			var option = $("<option value='" + categories[i].id + "'>" + categories[i].name + '</option>');
			categoryInput.append(option);
		}
	}

	function configureAmountCalculations(tile) {
		var issuesInput = tile.find("input[name='issues']");
		var balanceInput = tile.find("input[name='balance_issues']");
		var rateInput = tile.find("input[name='rate']");

		var amountInput = tile.find("input[name='amount']");
		var balanceAmountInput = tile.find("input[name='balance']");
		var totalAmountInput = tile.find("input[name='total_amount']");
		var isFreeInput = tile.find("input[name='is_free_ignore']");

		calculateAmount();
		calculateBalance();

		setIsFree();

		issuesInput.on('change', calculateAmount);
		rateInput.on('change', calculateAmount);
		balanceInput.on('change', calculateBalance);
		rateInput.on('change', calculateBalance);
		isFreeInput.on('change', setIsFree);

		function calculateAmount() {
			var issues = issuesInput.val();
			var rate = rateInput.val();
			var amount = issues * rate;

			amountInput.val(amount);
			totalAmountInput.val(amount + parseInt(balanceAmountInput.val()));
		}

		function calculateBalance() {
			var balanceIssues = balanceInput.val();
			var rate = rateInput.val();
			var balance = balanceIssues * rate;

			balanceAmountInput.val(balance);
			totalAmountInput.val(balance + parseInt(amountInput.val()));
		}

		function setIsFree() {
			tile.find("input[name='is_free']").val(isFreeInput.is(':checked') ? '1' : '0');
		}
	}

	function configureIssues(tile) {
		var startingMonthInput = tile.find("select[name='start_month']");
		var startingYearInput = tile.find("input[name='start_year']");
		var endingMonthInput = tile.find("select[name='end_month']");
		var endingYearInput = tile.find("input[name='end_year']");

		var copiesInput = tile.find("input[name='copies']");
		var categoryInput = tile.find("select[name='category_id']");
		var issuesInput = tile.find("input[name='issues']");
		var balanceIssuesInput = tile.find("input[name='balance_issues']");
		var totalIssuesInput = tile.find("input[name='total_issues']");
		var byhandIssuesInput = tile.find("input[name='by_hand']");

		startingMonthInput.on('change', calculateIssues);
		startingYearInput.on('change', calculateIssues);
		endingMonthInput.on('change', calculateIssues);
		endingYearInput.on('change', calculateIssues);
		categoryInput.on('change', calculateIssues);
		copiesInput.on('change', calculateIssues);

		calculateIssues();

		function calculateIssues() {
			var endingMonth = parseInt(endingMonthInput.val());
			var endingYear = parseInt(endingYearInput.val());
			var startingMonth = parseInt(startingMonthInput.val());
			var startingYear = parseInt(startingYearInput.val());
			var copies = parseInt(copiesInput.val());

			if (endingYear < startingYear || (endingYear === startingYear && endingMonth < startingMonth)) {
				issuesInput.val(-1);
				balanceIssuesInput.val(-1);
				byhandIssuesInput.val('');
				//				markByHandIssues(-1, "", 0, 0);
				return;
			}

			var totalIssues =
				Math.ceil((13 - startingMonth) / configMap.publication.fq) +
				((endingYear - startingYear - 1) * 12) / configMap.publication.fq +
				Math.ceil(endingMonth / configMap.publication.fq);
			var curDate = new Date();
			var startingDate = new Date();
			startingDate.setMonth(startingMonth - 1);
			startingDate.setFullYear(startingYear);

			var issues;
			if (startingDate > curDate) {
				issues = 0;
			} else {
				issues =
					Math.ceil((13 - startingMonth) / configMap.publication.fq) +
					((curDate.getFullYear() - startingYear - 1) * 12) / configMap.publication.fq +
					Math.ceil((curDate.getMonth() + 1) / configMap.publication.fq);
			}

			issuesInput.val(issues * copies).trigger('change');
			balanceIssuesInput.val((totalIssues - issues) * copies).trigger('change');
			totalIssuesInput.val(totalIssues * copies);
			byhandIssuesInput.val(getByHandString(issues * copies, copies, startingMonth));
			//			markByHandIssues(tile, issues, copies, startingMonth, startingYear);

			if (copies > 1) {
				tile.find('.bulk-users-section').show();
			} else {
				tile.find('.bulk-users-section').hide();
			}

			tile.find("input[name='renew_pending']").val(copies);
		}
	}

	function configureCalculation(tile) {
		configureCategories(tile);
		configureRate(tile);
		configureIssues(tile);
		configureAmountCalculations(tile);
	}

	function populateMarkingsTable(sampleTile, record, isForm) {
		var markings = (record && typeof record['markings'] === 'object' && record['markings']) || [];
		var markingMap = {};
		var minYear = 10000,
			maxYear = 0;
		for (var i = 0; i < markings.length; i++) {
			if (!markings[i]['mark_date']) {
				continue;
			}
			var dateArr = markings[i]['mark_date'].split('-');
			var year = parseInt(dateArr[0]);
			var month: string | number = parseInt(dateArr[1]);

			if (!markingMap[year]) {
				markingMap[year] = {};
			}
			markingMap[year][month] = markings[i]['mark_count'];

			if (year > maxYear) {
				maxYear = year;
			}

			if (year < minYear) {
				minYear = year;
			}
		}

		if (isForm) {
			var curDate = new Date();
			var curYear = curDate.getFullYear();
			if (!markingMap[curYear]) {
				includeYear(curYear);
			}

			var nextMonth = curDate.getMonth() + 3;
			if (nextMonth > 12 && !markingMap[curYear + 1]) {
				includeYear(curYear + 1);
			}
		} else if (markings.length === 0) {
			var curDate = new Date();
			var curYear = curDate.getFullYear();
			if (!markingMap[curYear]) {
				includeYear(curYear);
			}
		}

		function includeYear(year) {
			markingMap[year] = {};
			if (year < minYear) {
				minYear = year;
			}
			if (year > maxYear) {
				maxYear = year;
			}
		}

		var table = sampleTile.find('table.markings-table');
		table.find('.markings-row').remove();
		for (var i = maxYear; i >= minYear; i--) {
			var tableRow = $("<tr class='markings-row'></tr>");
			tableRow.append($('<th>' + i + '</th>'));
			for (var j = 1; j <= 12; j += 2) {
				var count;
				if (!markingMap[i] || !markingMap[i][j] || markingMap[i][j] === '' || markingMap[i][j] === '0') {
					count = '';
				} else {
					count = markingMap[i][j];
				}
				if (!isForm) {
					tableRow.append($('<td>' + count + '</td>'));
				} else {
					var td = $('<td></td>');
					var month: string | number = j;
					if (j < 10) {
						month = '0' + j;
					}
					td.append($("<input type='hidden' name='mark_date[]' value='" + i + '-' + month + "-01' />"));
					td.append($("<input type='number' name='mark_count[]' value='" + markingMap[i][j] + "' />"));
					tableRow.append(td);
				}
			}
			table.append(tableRow);
		}
	}

	function getByHandString(issues, copies, startMonth) {
		var monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

		var byHand = '';
		var issuesPerCopy = parseInt(issues) / parseInt(copies);
		var startMonthIndex = parseInt(startMonth) - 1;
		var comma = '';
		for (var i = 0; i < issuesPerCopy; i++) {
			byHand += comma + monthArr[startMonthIndex];
			startMonthIndex += configMap.publication.fq;
			if (startMonthIndex >= 12) {
				startMonthIndex -= 12;
			}
			comma = ', ';
		}

		if (byHand.length > 0) {
			return parseInt(issues) + ' –– (' + byHand + ') x ' + copies;
		} else {
			return byHand;
		}
	}

	function markByHandIssues(tile, issues, copies, startMonth, startYear) {
		tile.find("input[name='mark_count[]']").val('');
		var startMonthIndex = parseInt(startMonth);
		for (var i = 0; i < issues; i++) {
			var month = startMonthIndex < 10 ? '0' + startMonthIndex : startMonthIndex;
			var date = startYear + '-' + month + '-01';
			var markCountField = tile.find("input[name='mark_date[]'][value='" + date + "']").next();
			markCountField.val(copies);
			startMonthIndex += configMap.publication.fq;
			if (startMonthIndex === 13) {
				startMonthIndex = 1;
				startYear++;
			}
		}
	}

	function printReceipt(record) {
		var printConfig = '<div id="print-configs">';

		printConfig += '<iframe id="print-preview" name="print-preview" src="about:blank"></iframe>';
		printConfig += '</div>';

		var $printConfig = $(printConfig);
		$('body').append($printConfig);
		updatePrintReceiptPreview(record, true);
	}

	function getSelectedPrintReceiptConfigs() {
		var printConfigs = {
			receipt_height: 5.5,
		};

		return printConfigs;
	}

	function updatePrintReceiptPreview(record, autoPrint) {
		var configs = getSelectedPrintReceiptConfigs();

		let style = `<style>
			body {
				background-color: rgba(0, 0, 0, 0);		
				line-height: 1.42857143;
				color: #333;
				font-size: 14px;
				font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;			
			}

			* {
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
			}

			.row {
				margin-right: -15px;
				margin-left: -15px;
			}

			.row:before, .row:after {
				display: table;
				content: " ";
			}

			.col-xs-12 {
				width: 100%;
			}
			
			.col-xs-6 {
				width: 50%;
			}

			.col-xs-2 {
				width: 16.66666667%;
			}

			.col-xs-4 {
				width: 33.33333333%;
			}

			.col-xs-5 {
				width: 41.66666667%;
			}

			.col-xs-7 {
				width: 58.33333333%;
			}

			.col-xs-10 {
				width: 83.33333333%;
			}

			.col-xs-offset-8 {
				margin-left: 66.66666667%;
			}

			.col-xs-6, .col-xs-12, .col-xs-2, .col-xs-4, .col-xs-5, .col-xs-7, .col-xs-10 {
				float: left;
				position: relative;
				min-height: 1px;
				padding-right: 15px;
				padding-left: 15px;
			}
			
			.print-top-div {
				background-color: white;
				width: 8.27in;
				position: relative;
				height: 5.5in;
				margin: 10px;
				padding: 0;
				overflow: hidden;
				font-size: 14px;
			}
			
			.print-receipt-container {
				background-color: white;
				width: 8.27in;
				position: relative;
				height: 5.5in;
				margin: 0;
				padding: 0;
				overflow: hidden;
				font-size: 14px;
			}
			
			.print-receipt-container .title {
				font-weight: bold;
				font-size: 0.9em;
				text-align: left;
			}
			
			.print-receipt-container .value {
				border-bottom: 1px solid lightgrey;
				display: inline-block;
				width: 100%;
				margin-bottom: 10px;
			}
			
			.print-receipt-container .print-receipt {
				padding: 10px;
				margin: 0 0.1cm 0.3cm 0.1cm;
				height: auto;
				width: 100%;
				position: relative;
			}
			
			.print-receipt .heading {
				font-size: 1.3em;
				font-weight: bold;
				text-transform: uppercase;
				padding: 0.3em;
				border-bottom: 1px solid grey;
				width: 80%;
				margin: 1em auto 1.3em;
				text-align: center;
				letter-spacing: 0.1em;
				word-spacing: 0.3em;
			}
			
			.signature {
				margin-top: 1em;
			}
			
			.signature .user-sign {
				text-align: left;
			}
			
			.signature .title {
				text-transform: uppercase;
				font-size: 1em;
			}
			
			.print-addresses-dummy {
				position: absolute;
				top: 100%;
			}
			
			.footer {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
			}
			
			.website {
				text-align: left;
			}
			
			.email {
				text-align: right;
			}
			
			.capitalize {
				text-transform: capitalize;
			}
		</style>`;
		var headHtml = '<head>' + style + '</head>';
		var html = headHtml + '<body>';

		var topDiv = $(`<div class='print-top-div row'>${style}</div>`);
		$('body').append(topDiv);

		var receipt = printOneRecord(record);
		topDiv.append(receipt);

		var $receipt = receipt.find('.print-receipt');

		while (parseInt(receipt.css('height')) < parseInt($receipt.css('height'))) {
			$receipt.css('font-size', parseInt($receipt.css('font-size')) - 0.5);
		}
		$receipt.css('height', configs.receipt_height + 'in');

		html += topDiv.html();
		receipt.remove();

		html += '</div></body>';
		topDiv.remove();

		var iFrameWindow = window.frames['print-preview'];
		var doc = iFrameWindow.document;
		$('body', doc).html(html);

		if (typeof autoPrint !== 'undefined' && autoPrint === true) {
			setTimeout(function () {
				iFrameWindow.focus();
				iFrameWindow.print();

				$('#print-configs').remove();
			}, 1000);
		}

		function printOneRecord(record) {
			var address = '';
			if (record['address'] !== null) {
				address = record['address'].replace(/\\n/g, '</br>').replace(/\n/g, '</br>');
			}

			var cityDetails = '';
			var comma = '';
			if (record['country'] && record['country'].toLowerCase().trim() !== 'india') {
				if (record['city']) {
					cityDetails += record['city'];
					comma = ', ';
				}
				if (record['state']) {
					cityDetails += comma + record['state'];
				}
				comma = ' ';
				cityDetails += comma + '( ' + record['country'] + ' )';
				comma = ' – ';
				if (record['pincode']) {
					cityDetails += comma + record['pincode'];
				}
			} else {
				if (record['tehsil']) {
					cityDetails += record['tehsil'];
					comma = ', ';
				}
				if (record['district']) {
					cityDetails += comma + record['district'];
					comma = ', ';
				}
				if (record['city']) {
					cityDetails += comma + record['city'];
				}
				comma = ', ';
				if (record['state']) {
					cityDetails += comma + record['state'];
				}
				comma = ' ';
				cityDetails += comma + '( India )';
				comma = ' – ';
				if (record['pincode']) {
					cityDetails += comma + record['pincode'];
				}
			}

			var dateAr = record['entry_date'].split('-');
			var dateStr = Utils.getDateStr(dateAr[0], dateAr[1], dateAr[2]);

			var byHand = getByHandString(record['issues'], record['copies'], record['start_month']);

			var html =
				"<div class='col-xs-12 print-receipt-container'>\n\
				<div class='print-receipt'>" +
				"<div class='row'>\n\
						<div class='col-xs-12'>\n\
							<div class='heading'>Amar Sandesh Karyalaya</div>\n\
						</div>\n\
					</div>\n\
					<div class='row'>\n\
						<div class='col-xs-2 title'>\n\
							<span>Receipt No:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>" +
				record['receipt_no'] +
				"</span>\n\
						</div>\n\
						<div class='col-xs-2 title'>\n\
							<span>Date:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>" +
				dateStr +
				"</span>\n\
						</div>\n\
					</div>\n\
					<div class='row'>\n\
						<div class='col-xs-2 title'>\n\
							<span>Reader ID:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>" +
				record['reader_id'] +
				"</span>\n\
						</div>\n\
						<div class='col-xs-2 title'>\n\
							<span>Language:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value capitalize'>" +
				record['language'] +
				"</span>\n\
						</div>\n\
					</div>\n\
					<div class='row'>\n\
						<div class='col-xs-2 title'>\n\
							<span>From:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>" +
				Utils.getDateStr(record['start_year'], record['start_month']) +
				"</span>\n\
						</div>\n\
						<div class='col-xs-2 title'>\n\
							<span>To:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>" +
				Utils.getDateStr(record['end_year'], record['end_month']) +
				"</span>\n\
						</div>\n\
					</div>\n\
					<div class='row'>\n\
						<div class='col-xs-2 title'>\n\
							<span>Name:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>" +
				record['name'] +
				"</span>\n\
						</div>\n\
						<div class='col-xs-2 title'>\n\
							<span>Through:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>" +
				getThroughFromId(record['through_id']) +
				"</span>\n\
						</div>\n\
					</div>\n\
					<div class='row'>\n\
						<div class='col-xs-2 title'>\n\
							<span>Address:</span>\n\
						</div>\n\
						<div class='col-xs-10'>\n\
							<span class='value'>" +
				address +
				'</br>' +
				cityDetails +
				"</span>\n\
						</div>\n\
					</div>\n\
					<div class='row'>\n\
						<div class='col-xs-2 title'>\n\
							<span>Email:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>" +
				record['email'] +
				"</span>\n\
						</div>\n\
						<div class='col-xs-2 title'>\n\
							<span>Contact:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>" +
				record['phone'] +
				"</span>\n\
						</div>\n\
					</div>\n\
					<div class='row'>\n\
						<div class='col-xs-2 title'>\n\
							<span>Readers:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>" +
				record['copies'] +
				"</span>\n\
						</div>\n\
						<div class='col-xs-2 title'>\n\
							<span>Issues:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>" +
				(parseInt(record['issues']) + parseInt(record['balance_issues'])) +
				"</span>\n\
						</div>\n\
					</div>\n\
					<div class='row'>\n\
						<div class='col-xs-2 title'>\n\
							<span>Rate:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>₹ " +
				record['rate'] +
				"</span>\n\
						</div>\n\
						<div class='col-xs-2 title'>\n\
							<span>Sum Total:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>₹ " +
				(parseInt(record['amount']) + parseInt(record['balance'])) +
				'</span>\n\
						</div>\n\
					</div>';
			if (byHand.length > 0) {
				html +=
					"<div class='row'>\n\
						<div class='col-xs-2 title'>\n\
							<span>Issued By Hand:</span>\n\
						</div>\n\
						<div class='col-xs-4'>\n\
							<span class='value'>" +
					byHand +
					'</span>\n\
						</div>\n\
					</div>';
			}
			html +=
				"<div class='row signature'>\n\
						<div class='col-xs-offset-8 col-xs-4 user-sign'>\n\
							<span>" +
				stateMap.userData.fullName +
				"</span>\n\
						</div>\n\
						<div class='col-xs-offset-8 col-xs-4 title'>\n\
							<span>Signature</span>\n\
						</div>\n\
					</div>\n\
					<div class='footer'>\n\
						<div class='row'>\n\
							<div class='col-xs-5 website'>\n\
								<strong>Our Website: </strong><span>www.shriprayagdham.org</span>\n\
							</div>\n\
							<div class='col-xs-7 email'>\n\
								<strong>Our Email: </strong><span>care.publications.spd@gmail.com</span>\n\
							</div>\n\
						</div>\n\
					</div>\n\
				</div>\n\
			</div>";

			return $(html);
		}
	}

	function getExtraDetailTileButtons(record) {
		return [
			{
				label: 'Duplicate',
				cssClass: 'btn-info',
				action: function (dialog) {
					var detailTile = dialog.getMessage();
					var index = detailTile.attr('data-index');
					var records = Records.getCurrentRecords();
					var record = records[index];

					dialog.close();

					record['action'] = 'add';
					Records.triggerAddRecord(null, record);
				},
			},
			{
				label: 'Renew',
				cssClass: 'btn-warning' + (parseInt(record['renew_pending']) === 0 ? ' hidden' : ''),
				action: function (dialog) {
					var detailTile = dialog.getMessage();
					var index = detailTile.attr('data-index');
					var records = Records.getCurrentRecords();
					var record = records[index];

					dialog.close();

					record['action'] = 'renew';
					record['data_index'] = index;
					Records.triggerAddRecord(null, record);
				},
			},
			{
				label: 'Receipt',
				cssClass: 'btn-default',
				action: function (dialog) {
					var detailTile = dialog.getMessage();
					var index = detailTile.attr('data-index');
					var records = Records.getCurrentRecords();
					var record = records[index];
					printReceipt(record);
				},
			},
		];
	}

	return {
		initModule: initModule,
		setConfig: function (key, value) {
			configMap[key] = value;
		},
	};
})(jQuery);

let temp1 = [
	{
		id: '1',
		created_by: '3',
		name: 'Harshit Arya',
		address: 'B804, Bren Celestia, Kaikondrahalli, Sarjapur Road, Behind Reliance Digital',
		city: 'Bangalore',
		tehsil: null,
		district: null,
		state: 'Karnataka',
		country: 'India',
		pincode: '560035',
		email: 'digital.arya@gmail.com',
		phone: '+919740466251',
		reader_id: 'IPYLHP2',
		old_subscriber_no: null,
		is_free: '0',
		through_id: '5',
		category_id: '1',
		language: 'english',
		receipt_no: 'E-4-06/IPYMQN3',
		issues: '4',
		balance_issues: '20',
		copies: '4',
		renew_pending: '4',
		rate: '6',
		balance: '120',
		amount: '24',
		start_month: '5',
		start_year: '2020',
		end_month: '3',
		end_year: '2021',
		start_date: '2020-05',
		end_date: '2021-03',
		entry_date: '2020-05-19',
		comments: null,
		subscription_status: 'active',
		status: 'active',
		created_at: '2020-05-19 16:12:43',
		updated_at: '2020-05-19 16:28:02',
		bulk_users: [
			{
				id: '4',
				parent_id: '1',
				user_name: 'anand-1',
				status: 'active',
				created_at: '2020-05-19 16:28:02',
				updated_at: '2020-05-19 16:28:02',
			},
			{
				id: '5',
				parent_id: '1',
				user_name: 'harshit-2',
				status: 'active',
				created_at: '2020-05-19 16:28:02',
				updated_at: '2020-05-19 16:28:02',
			},
			{
				id: '6',
				parent_id: '1',
				user_name: 'bhakti-1',
				status: 'active',
				created_at: '2020-05-19 16:28:02',
				updated_at: '2020-05-19 16:28:02',
			},
		],
	},
	{
		id: '2',
		created_by: '2',
		name: 'Harshit Arya',
		address: 'B-804, Bren Celestia, Kaikondrahalli,\nSarjapur Road',
		city: 'Bangalore South',
		tehsil: null,
		district: null,
		state: 'Karnataka',
		country: 'India',
		pincode: '560035',
		email: 'digital.arya@gmail.com',
		phone: '+919740466251',
		reader_id: 'IPYNTS2',
		old_subscriber_no: null,
		is_free: '0',
		through_id: '4',
		category_id: '1',
		language: 'english',
		receipt_no: 'E-3-06/IPYOTL2',
		issues: '3',
		balance_issues: '15',
		copies: '3',
		renew_pending: '3',
		rate: '6',
		balance: '90',
		amount: '18',
		start_month: '5',
		start_year: '2020',
		end_month: '3',
		end_year: '2021',
		start_date: '2020-05',
		end_date: '2021-03',
		entry_date: '2020-05-19',
		comments: null,
		subscription_status: 'active',
		status: 'active',
		created_at: '2020-05-19 16:40:22',
		updated_at: '2020-05-19 16:51:20',
	},
	{
		id: '1',
		created_by: '2',
		name: 'Harshit Arya',
		address: 'B804, Bren Celestia, Kaikondrahalli, Sarjapur Road, Behind Reliance Digital',
		city: 'Bangalore',
		tehsil: null,
		district: null,
		state: 'Karnataka',
		country: 'India',
		pincode: '560035',
		email: 'digital.arya@gmail.com',
		phone: '+919740466251',
		reader_id: 'IPYLHP2',
		old_subscriber_no: null,
		is_free: '0',
		through_id: '5',
		category_id: '1',
		language: 'english',
		receipt_no: 'E-4-06/NDTDSC2',
		issues: '44',
		balance_issues: '-20',
		copies: '4',
		renew_pending: '4',
		rate: '6',
		balance: '120',
		amount: '24',
		start_month: '5',
		start_year: '2020',
		end_month: '3',
		end_year: '2021',
		start_date: '2020-05',
		end_date: '2021-03',
		entry_date: '2020-05-19',
		comments: null,
		subscription_status: 'active',
		status: 'active',
		created_at: '2020-05-19 16:12:43',
		updated_at: '2022-02-01 16:56:44',
		bulk_users: [
			{
				id: '7',
				parent_id: '1',
				user_name: 'anand-1',
				status: 'active',
				created_at: '2022-02-01 16:56:44',
				updated_at: '2022-02-01 16:56:44',
			},
			{
				id: '8',
				parent_id: '1',
				user_name: 'harshit-2',
				status: 'active',
				created_at: '2022-02-01 16:56:44',
				updated_at: '2022-02-01 16:56:44',
			},
			{
				id: '9',
				parent_id: '1',
				user_name: 'bhakti-1',
				status: 'active',
				created_at: '2022-02-01 16:56:44',
				updated_at: '2022-02-01 16:56:44',
			},
		],
		markings: [
			{
				id: '1',
				parent_id: '1',
				mark_date: '2022-01-01',
				mark_count: '4',
				status: 'active',
				created_at: '2022-02-01 16:56:44',
				updated_at: '2022-02-01 16:56:44',
			},
			{
				id: '2',
				parent_id: '1',
				mark_date: '2022-03-01',
				mark_count: '4',
				status: 'active',
				created_at: '2022-02-01 16:56:44',
				updated_at: '2022-02-01 16:56:44',
			},
		],
	},
];
