import { FireStoreModule } from 'fb';
import firebase from 'fb/index';
import 'firebase/firestore';
import { updateDham } from 'store/data/dham/actions';

export class DhamFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'dham';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: firebase.firestore.Firestore) => db.collection('dham');
	public getChangeProcessAction = (changes) => updateDham(changes);
}
