import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { connect } from 'react-redux';
import { toggleAlertDialog } from 'store/temp/actions';
import { ApplicationState } from 'types';

export interface AlertDialogProps {
	open: boolean;
	title?: string;
	message: string;
	okAction?: {
		text?: string;
		color?: string;
		callback: () => void;
	};
	cancelAction?: {
		text?: string;
		callback: () => void;
	};
	toggleDialog: () => void;
}

function AlertDialog(props: AlertDialogProps) {
	let { open = false, title, message, okAction, cancelAction, toggleDialog } = props;

	if (!open) {
		return <React.Fragment></React.Fragment>;
	}

	const handleClose = (action: boolean) => () => {
		if (action) {
			okAction?.callback();
		} else {
			cancelAction?.callback();
		}
		toggleDialog();
	};

	return (
		<Dialog open={open} onClose={handleClose(false)}>
			{title ? <DialogTitle>{title}</DialogTitle> : null}
			<DialogContent>
				<DialogContentText>{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose(false)}>{cancelAction?.text || 'Cancel'}</Button>
				<Button onClick={handleClose(true)}>{okAction?.text || 'Ok'}</Button>
			</DialogActions>
		</Dialog>
	);
}

function mapStateToProps({ tempState }: ApplicationState) {
	let { alertDialog } = tempState;

	return {
		open: alertDialog.open,
		title: alertDialog.content?.title,
		message: alertDialog.content?.message,
		okAction: alertDialog.content?.okAction,
		cancelAction: alertDialog.content?.cancelAction,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDialog: () => {
			dispatch(toggleAlertDialog());
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
