import SignIn from 'components/profile/SignIn';
import React from 'react';
import Page from './Page';

export default function SignInPage(props) {
	let { isModal } = props;

	setTimeout(() => {
		$('#main-wrapper').css('display', 'block');
	}, 100);

	return (
		<React.Fragment>
			{/* {!isModal ? <HomePage /> : null} */}

			<Page
				title='Sign In'
				// transition={true}
				maxWidth='xs'
				style={{ height: '100%', maxWidth: 400, margin: '20px auto' }}
				goBackOnClose={false}
				goBackTo='/'
			>
				<SignIn />
			</Page>
		</React.Fragment>
	);
}
