import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { ThroughRecord } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_THROUGH = '@@mjbj/UPDATE_THROUGH';
export type UPDATE_THROUGH = typeof UPDATE_THROUGH;

export interface UpdateThrough extends Action {
	type: UPDATE_THROUGH;
	changes: FBChanges;
}

export function updateThrough(changes: FBChanges): UpdateThrough {
	return {
		type: UPDATE_THROUGH,
		changes,
	};
}

export const UPDATE_THROUGH_FILTERED = UPDATE_THROUGH + '_FILTERED';
export type UPDATE_THROUGH_FILTERED = typeof UPDATE_THROUGH_FILTERED;

export interface UpdateThroughFiltered extends Action {
	type: UPDATE_THROUGH_FILTERED;
	payload: ThroughRecord[];
}
