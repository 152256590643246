import { anand } from './anand';

export const server = (function ($) {
	var configMap = {
		baseUrl: 'https://records.shrianandpur.net/',
	};

	function ajaxRequest(requestObj: any) {
		requestObj.url = configMap.baseUrl + requestObj.url;
		var successCallback = requestObj.success;

		requestObj.success = function (data, textStatus, xhr) {
			var refresh = xhr.getResponseHeader('refresh');
			if (refresh != null && refresh.indexOf('login_page') > 0) {
				window.location.href = '/';
			}
			if (typeof successCallback !== 'undefined' && successCallback !== null) {
				successCallback(data, textStatus, xhr);
			}
		};

		requestObj.error = function (jqXHR, textStatus, errorThrown) {
			if (jqXHR.status === 0 && textStatus === 'error') {
				window.location.href = '/';
			}
		};

		return $.ajax(requestObj);
	}

	function initModule(callback) {
		$.ajax({
			url: configMap.baseUrl + '?rand=' + Math.random(),
			type: 'get',
			success: function (data) {
				anand.setWorkOffline(false, callback);
			},
			error: function (xhr, statusText, errorThrown) {
				anand.setWorkOffline(true, callback);
			},
		});
	}

	function initForOnline(callback) {
		if (typeof callback !== 'undefined') {
			callback();
		}
	}

	function logout() {}

	return {
		request: ajaxRequest,
		initModule: initModule,
		initForOnline: initForOnline,
		logout: logout,
		isReadOnlyModel: function () {
			return false;
		},
	};
})(jQuery);

// declare global {
// 	var anand: { data: { server: typeof server } };
// }

// anand.data.server = server;
