import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			position: 'fixed',
			top: '56px',
			left: 0,

			'& > * + *': {
				marginTop: theme.spacing(0),
			},

			[theme.breakpoints.up('sm')]: {
				top: 64,
			},
		},
	})
);

export default function LinearIndeterminate() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<LinearProgress />
		</div>
	);
}
