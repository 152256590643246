import './css/anand.overlay_panel.css';

export const overlay_panel = (function ($) {
	var configMap = {};
	var stateMap = {
		closeCallbacks: {},
		scrollTop: {},
		visiblePanels: 0,
	};

	function showPanel(obj) {
		var contentHtml = obj.contentHtml;
		var panelId = obj.id;
		stateMap.closeCallbacks[panelId] = obj.onClose;
		var title = obj.title;
		if (typeof title === 'undefined') {
			title = '';
		}

		var markup =
			'<div class="overlay_panel_container" id="opc_' +
			panelId +
			'">\n\
                <div class="overlay_panel" id="op_' +
			panelId +
			'">\n\
                    <div class="op_close_btn">\n\
                        <button onclick="overlay_panel.closePanel(\'' +
			panelId +
			'\')" class="button btn btn-danger">\n\
                            <span><span>Back</span></span>\n\
                        </button>';
		if (obj.headerBtnHtml !== undefined) {
			markup += obj.headerBtnHtml;
		}

		markup +=
			'</div>\n\
                    <div class="op_title">' +
			title +
			'</div>\n\
                    <div id="' +
			panelId +
			'">' +
			contentHtml +
			'\
                    </div>\n\
                </div>\n\
            </div>';
		var containerId = obj.containerId;

		if (typeof containerId === 'undefined') {
			containerId = 'body';
		}
		$(containerId).append(markup);

		var afterAppendCallback = obj.afterAppend;
		if (typeof afterAppendCallback !== 'undefined') {
			afterAppendCallback(panelId);
		}

		setTimeout(function () {
			var containerHeight;
			if (containerId === 'body') {
				containerHeight = $(document).height();
			} else {
				containerHeight = $(containerId).height();
			}
			$(containerId)
				.find('#opc_' + panelId)
				.css('height', containerHeight);
			var $panel = $(containerId).find('#op_' + panelId);
			$panel.css('height', $(window).height() ?? 0);
			$panel.animate(
				{
					left: '0%',
				},
				'slow',
				'swing'
			);
			stateMap.scrollTop[panelId] = document.body.scrollTop;
			$('#main-wrapper').css('top', -1 * document.body.scrollTop);
			$('#main-wrapper').addClass('noscroll');
			stateMap.visiblePanels++;
		}, 200);
	}

	function closePanel(panelId) {
		var $panel = $('#op_' + panelId);
		$panel.animate(
			{
				left: '100%',
			},
			'slow',
			'swing',
			function () {
				$('#opc_' + panelId).hide(200);
				$('#opc_' + panelId).remove();
				$('#main-wrapper').removeClass('noscroll');
				$('#main-wrapper').css('top', '');
				document.body.scrollTop = stateMap.scrollTop[panelId];
				stateMap.visiblePanels--;
				var callback = stateMap.closeCallbacks[panelId];
				if (typeof callback !== 'undefined') {
					callback(panelId);
				}
			}
		);
	}

	function isVisible() {
		return stateMap.visiblePanels > 0;
	}

	return {
		showPanel: showPanel,
		closePanel: closePanel,
		isVisible: isVisible,
	};
})(jQuery);

declare global {
	interface Window {
		overlay_panel: typeof overlay_panel;
	}
}

window.overlay_panel = overlay_panel;
