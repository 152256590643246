import { combineReducers } from 'redux';
import { BookRecord, BookStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateBook, UpdateBookFiltered, UPDATE_BOOK, UPDATE_BOOK_FILTERED } from './actions';

export const bookByIdReducer = (state = {}, action: UpdateBook) => {
	switch (action.type) {
		case UPDATE_BOOK:
			return updateData(state, action.changes, 'book');
		default:
			return state;
	}
};

const filteredBookReducer = (state: BookRecord[] = [], action: UpdateBookFiltered) => {
	switch (action.type) {
		case UPDATE_BOOK_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const bookReducer = combineReducers<BookStore>({
	byId: bookByIdReducer,
	filtered: filteredBookReducer,
});
