import { IntegratedRecordsList } from 'components/IntegratedRecordsList';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, PincodeRecord } from 'types';
import { RecordsHandler } from './RecordsHandler';
import { ViewTile } from './viewTile';

let CSS = `
#selection-dropdown {
    display: none !important;
}

.tile.amar-pin-record.detail {
    padding-top: 15px;
}

.tile.amar-pin-record .main-details {
    padding-right: 30px;
}

.tile.amar-pin-record .main-details .title {
	text-align: right;
	padding: 0;
	margin-bottom: 15px;
    font-weight: bold;
    font-size: 0.9em;
}

.tile.amar-pin-record .main-details .value {
	padding-right: 0;
	margin-bottom: 15px;
}

.tile.amar-pin-record input {
	width: 100%;
	background-color: white!important;
    border: none;
    border-bottom: 1px solid darkgray;
}

.tile.amar-pin-record input:focus {
    outline: none;    
}

div.tile.amar-pin-record .main-details .value {
    border-bottom: 1px solid lightgrey;
    min-height: 1.5em;
    padding-right: 0;
}
`;

const html = `
<style>
	${CSS}
</style>

<div id="page-records">
    <div class="records">
        <div class="filtered-records">
        </div>
    </div>
</div>
`;

interface Props {
	records: PincodeRecord[];
}

class Component extends React.Component<Props> {
	render() {
		return (
			<React.Fragment>
				<IntegratedRecordsList
					records={this.props.records}
					RecordTile={ViewTile}
					html={html}
					RecordsHandler={RecordsHandler}
				/>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		records: state.dataState.pincode.filtered,
	};
}

export const Pincode = connect(mapStateToProps)(Component);
