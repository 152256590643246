import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { MomentRecord } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_MOMENT = '@@mjbj/UPDATE_MOMENT';
export type UPDATE_MOMENT = typeof UPDATE_MOMENT;

export interface UpdateMoment extends Action {
	type: UPDATE_MOMENT;
	changes: FBChanges;
}

export function updateMoment(changes: FBChanges): UpdateMoment {
	return {
		type: UPDATE_MOMENT,
		changes,
	};
}

export const UPDATE_MOMENT_FILTERED = UPDATE_MOMENT + '_FILTERED';
export type UPDATE_MOMENT_FILTERED = typeof UPDATE_MOMENT_FILTERED;

export interface UpdateMomentFiltered extends Action {
	type: UPDATE_MOMENT_FILTERED;
	payload: MomentRecord[];
}
