import { IntegratedRecordsList } from "components/IntegratedRecordsList";
import { isOnlyMjBjSite } from "config";
import React from "react";
import { connect } from "react-redux";
import { ApplicationState, MjBjRecord } from "types";
import { MjbjRecords } from "./anand.mjbjRecords";
import { MjBjViewTile, MjBjViewTileCSS } from "./mjbjViewTile";

const MjBjCSS = `
#selection-dropdown {
	display: block !important;
}
#record_count {
	margin-top: 0;
}

#mjbj-detail-record-form {
	display: none;
}

#mjbj-add-record {
	display: none;
}

.tile.mjbj-record .inApp {
    color: green;
    font-size: 0.8em;
}

form.tile.mjbj-record .main-details .value {
	padding-right: 0;
}

form.tile.mjbj-record .comments {
	width: 100%;
	resize: none;
	height: 9em;
}

#holydress-chart-container,
#samadhi-chart-container {
	overflow-x: auto;
}

#holydress-yearly,
#samadhi-yearly {
	width: 600%;
}

.rahbar-sugestion img {
	width: 100%;
	padding: 0 5px;
}

.lb {
	border-bottom: 1px solid lightgrey;
}

.rahbar-detail select,
.past-rahbar-detail select,
.self-detail select,
.head-detail select {
	margin-left: -0.5em;
}

.contact-type {
	font-weight: bold;
	text-align: right;
	padding-right: 0;
}

.contact-value {
	text-align: left;
	letter-spacing: 1px;
	font-size: initial;
}

.contact-detail .title,
.group-detail .title {
	padding-bottom: 5px !important;
}

#contact-type-value {
	display: none;
}

.multi-field-row {
	padding-bottom: 5px;
	margin-bottom: 5px;
	border-bottom: 1px solid lightgrey;
}

#reminder .select-tile {
	display: none;
}

.tt-dropdown-menu {
	top: auto !important;
	bottom: 100% !important;
}

.tt-dropdown-menu-down {
	top: 100% !important;
	bottom: auto !important;
}

.tt-suggestion {
	cursor: pointer;
}

.tt-suggestion:hover {
	background-color: #0097cf;
	color: white;
}

#send_sms_btn {
	float: right;
}

textarea.sms {
	width: 100%;
	height: 6em;
}

#print-configs input[type='checkbox'] {
	width: auto;
	margin-right: 5px;
}
`;

const onlyMjBjCSS = isOnlyMjBjSite()
    ? `
.select-tile, .selection-btn, .sort-records-btn, #record_count, .find-anything {
    display: none;
}

#selection-dropdown select, .record-id {
    display: none;
}

.tile.mjbj-record .self-image {
    max-height: 70px;
}
`
    : ``;

const html = `
<style>
    ${MjBjCSS}
    ${onlyMjBjCSS}
</style>

<div id="page-records">
    <form id="mjbj-add-record" class="tile mjbj-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
        <div class="row">
            <div class="col-xs-12 col-md-3">
                <div class="block image-upload-container">
                    <img class="self-image button" />
                    <input type="file" name="image" accept="image/*">
                </div>
            </div>
            <div class="col-xs-12 col-md-3 main-details">
                <div class="block">
                    <div class="row old-name-detail">
                        <div class="title col-xs-3 col-md-3">
                            Old Name:
                        </div>
                        <div class="value col-xs-9 col-md-9">
                            <input type="text" value="" name="old_name" placeholder="Old Name"/>
                        </div>
                    </div>
                    <div class="row self-detail">
                        <div class="title col-xs-3 col-md-3">
                            Name:
                        </div>
                        <div class="col-xs-2 col-md-2">
                            <select name="title">
                                <option value="Mahatma">Mh.</option>
                                <option value="Bai">Bai</option>
                                <option value="Dada">Dada</option>
                                <option value="Dadi">Dadi</option>
                            </select>
                        </div>
                        <div class="value col-xs-7 col-md-7">
                            <input type="text"  name="name" value="" required placeholder="Full Name"/>
                        </div>
                    </div>
                    <div class="row dob-detail">
                        <div class="title col-xs-3 col-md-3">
                            DoB:
                        </div>
                        <div class="value col-xs-9 col-md-9">
                            <input type="text" class="date value" value="" name="dob" placeholder="Date of Birth"/>
                        </div>
                    </div>
                    <div class="row age-detail">
                        <div class="title col-xs-3 col-md-3">
                            Age:
                        </div>
                        <div class="value col-xs-9 col-md-9">
                            <input type="text" value="" name="age" placeholder="Age" />
                        </div>
                    </div>
					<div class="row sewa-detail">
                        <div class="title col-xs-3 col-md-3">
                            Sewa:
                        </div>
                        <div class="value col-xs-9 col-md-9">
                            <input type="text"  name="sewa" value="" placeholder="Sewa Place"/>
                        </div>
                    </div>
                    <div class="row rahbar-detail">
                        <div class="title col-xs-3 col-md-3">
                            Rahbar:
                        </div>
                        <div class="col-xs-2 col-md-2">
                            <select name="rahbar_title">
                                <option value="Mahatma">Mh.</option>
                                <option value="Bai">Bai</option>
                                <option value=""></option>
                            </select>
                        </div>
                        <div class="value col-xs-7 col-md-7">
                            <input type="text"  name="rahbar" value="" placeholder="Rahbar's Name"/>
                        </div>
                    </div>
                    <div class="row past-rahbar-detail">
                        <div class="title col-xs-3 col-md-3">
                            Past Rah.:
                        </div>
                        <div class="col-xs-2 col-md-2">
                            <select name="past_rahbar_title">
                                <option value=""></option>
                                <option value="Mahatma">Mh.</option>
                                <option value="Bai">Bai</option>
                                <option value="Bhagat">Bh.</option>
                            </select>
                        </div>
                        <div class="value col-xs-7 col-md-7">
                            <input type="text" value="" name="past_rahbar" placeholder="Past Rahbar's Name"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-3 main-details">
                <div class="block">
                    <div class="row head-detail">
                        <div class="title col-xs-3 col-md-3">
                            Head:
                        </div>
                        <div class="col-xs-2 col-md-2">
                            <select name="head_title">
                                <option value=""></option>
                                <option value="Mahatma">Mh.</option>
                                <option value="Bai">Bai</option>
                            </select>
                        </div>
                        <div class="value col-xs-7 col-md-7">
                            <input type="text" value="" name="head" placeholder="Head's Name"/>
                        </div>
                    </div>
                    <div class="row naamdaan-detail">
                        <div class="title col-xs-3 col-md-3">
                            Naam:
                        </div>
                        <div class="value col-xs-9 col-md-9">
                            <select name="naamdaan">
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </select>
                        </div>
                    </div>
                    <div class="row by-detail">
                        <div class="title col-xs-3 col-md-3">
                            By:
                        </div>
                        <div class="value col-xs-9 col-md-9">
                            <select name="holydress_by">
                                <option value="SHRI-6">SHRI-6 Ji</option>
                                <option value="SHRI-5">SHRI-5 Ji</option>
                                <option value="SHRI-4">SHRI-4 Ji</option>
                                <option value="SHRI-3">SHRI-3 Ji</option>
                                <option value="SHRI-2">SHRI-2 Ji</option>
                                <option value="SHRI-1">SHRI-1 Ji</option>
                                <option value=""></option>
                            </select>
                        </div>
                    </div>
                    <div class="row contact-detail">
                        <div class="title col-xs-3 col-md-3">
                            Contacts:
                        </div>
						<div id="contact-row" class="multi-field-row hidden col-xs-12">
							<div class="row">
								<div class="value col-xs-4 col-md-4">
									<select name="contact_type[]">
										<option value="Mobile">Mobile</option>
										<option value="Landline">Landline</option>
										<option value="Email">E-mail</option>
										<option value=""></option>
									</select>
								</div>
								<div class="value col-xs-7 col-md-7">
									<input type="text"  name="contact[]" value="" placeholder="Contact"/>
								</div>
								<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="contact-row"><i class="glyphicon glyphicon-plus"></i></button>
							</div>
						</div>
                    </div>
					<div class="row group-detail">
                        <div class="title col-xs-3 col-md-3">
                            Groups:
                        </div>
						<div id="group-row" class="multi-field-row hidden col-xs-12">
							<div class="row">
								<div class="value col-xs-11 col-md-11">
									<input type="text"  name="group[]" value="" placeholder="Group"/>
								</div>
								<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="group-row"><i class="glyphicon glyphicon-plus"></i></button>
							</div>
						</div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-3">
                <div class="block">
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="holydress-detail info-tile">
                                <div class="row">
                                    <div class="col-xs-12 info-title">
                                        <div class="border-bottom">Holydress</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6 info-title no-r-padding">
                                        <div class="border-bottom border-right">Date</div>
                                    </div>
                                    <div class="col-xs-6 info-title no-l-padding">
                                        <div class="border-bottom">Place</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6 no-r-padding">
                                        <input class="date value border-right" name="holydress_date" value="" placeholder="Holydress Date"/>
                                    </div>
                                    <div class="col-xs-6 no-l-padding">
                                        <input class="place value" name="holydress_place" value="" placeholder="Holydress Place"/>
                                    </div>           										
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div class="samadhi-detail info-tile">
                                <div class="row">
                                    <div class="col-xs-12 info-title">
                                        <div class="border-bottom">Samadhi</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6 info-title no-r-padding">
                                        <div class="border-bottom border-right">Date</div>
                                    </div>
                                    <div class="col-xs-6 info-title no-l-padding">
                                        <div class="border-bottom">Place</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6 no-r-padding">
                                        <input class="date value border-right" name="samadhi_date" value="" placeholder="Samadhi Date"/>
                                    </div>
                                    <div class="col-xs-6 no-l-padding">
                                        <input class="place value" name="samadhi_place" value ="" placeholder="Samadhi Place"/>
                                    </div>           										
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div class="comments-container">
                                <div class="comments-title">Comments</div>
                                <textarea class="comments" name="comments" rows="3" placeholder="Comments if any"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="records">
        <form id="mjbj-detail-record-form" class="tile mjbj-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
            <div class="record-id">
                <span></span>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-3">
                    <div class="block image-upload-container">
                        <img class="self-image button" />
                        <input type="file" name="image" accept="image/*">
                    </div>
                </div>
                <div class="col-xs-12 col-md-3 main-details">
                    <div class="block">
                        <div class="row old-name-detail">
                            <div class="title col-xs-3 col-md-3">
                                Old Name:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                                <input type="text" value="" name="old_name" placeholder="Old Name"/>
                            </div>
                        </div>
                        <div class="row self-detail">
                            <div class="title col-xs-3 col-md-3">
                                Name:
                            </div>
                            <div class="col-xs-2 col-md-2">
                                <select name="title">
                                    <option value="Mahatma">Mh.</option>
                                    <option value="Bai">Bai</option>
									<option value="Dada">Dada</option>
									<option value="Dadi">Dadi</option>
                               </select>
                            </div>
                            <div class="value col-xs-7 col-md-7">
                                <input type="text"  name="name" value="" required placeholder="Full Name"/>
                            </div>
                        </div>
                        <div class="row dob-detail">
                            <div class="title col-xs-3 col-md-3">
                                DoB:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                                <input type="text" class="date value" value="" name="dob" placeholder="Date of Birth"/>
                            </div>
                        </div>
                        <div class="row age-detail">
                            <div class="title col-xs-3 col-md-3">
                                Age:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                                <input type="text" value="" name="age" placeholder="Age" />
                            </div>
                        </div>
                        <div class="row sewa-detail">
                            <div class="title col-xs-3 col-md-3">
                                Sewa:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                                <input type="text"  name="sewa" value="" placeholder="Sewa Place"/>
                            </div>
                        </div>
                        <div class="row rahbar-detail">
                            <div class="title col-xs-3 col-md-3">
                                Rahbar:
                            </div>
                            <div class="col-xs-2 col-md-2">
                                <select name="rahbar_title">
                                    <option value="Mahatma">Mh.</option>
                                    <option value="Bai">Bai</option>
                                    <option value=""></option>
                                </select>
                            </div>
                            <div class="value col-xs-7 col-md-7">
                                <input type="text"  name="rahbar" value="" placeholder="Rahbar's Name"/>
                            </div>
                        </div>
                        <div class="row past-rahbar-detail">
                            <div class="title col-xs-3 col-md-3">
                                Past Rah.:
                            </div>
                            <div class="col-xs-2 col-md-2">
                                <select name="past_rahbar_title">
                                    <option value=""></option>
                                    <option value="Mahatma">Mh.</option>
                                    <option value="Bai">Bai</option>
                                    <option value="Bhagat">Bh.</option>
                                </select>
                            </div>
                            <div class="value col-xs-7 col-md-7">
                                <input type="text" value="" name="past_rahbar" placeholder="Past Rahbar's Name"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-md-3 main-details">
                    <div class="block">
						<div class="row head-detail">
							<div class="title col-xs-3 col-md-3">
								Head:
							</div>
							<div class="col-xs-2 col-md-2">
								<select name="head_title">
									<option value=""></option>
									<option value="Mahatma">Mh.</option>
									<option value="Bai">Bai</option>
								</select>
							</div>
							<div class="value col-xs-7 col-md-7">
								<input type="text" value="" name="head" placeholder="Head's Name"/>
							</div>
						</div>
                        <div class="row naamdaan-detail">
                            <div class="title col-xs-3 col-md-3">
                                Naam:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                                <select name="naamdaan">
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </div>
                        </div>
                        <div class="row by-detail">
                            <div class="title col-xs-3 col-md-3">
                                By:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                                <select name="holydress_by">
	                                <option value="SHRI-6">SHRI-6 Ji</option>
                                    <option value="SHRI-5">SHRI-5 Ji</option>
                                    <option value="SHRI-4">SHRI-4 Ji</option>
                                    <option value="SHRI-3">SHRI-3 Ji</option>
                                    <option value="SHRI-2">SHRI-2 Ji</option>
                                    <option value="SHRI-1">SHRI-1 Ji</option>
                                    <option value=""></option>
                                </select>
                            </div>
                        </div>
						<div class="row contact-detail">
							<div class="title col-xs-3 col-md-3">
								Contacts:
							</div>
							<div id="contact-row-edit" class="multi-field-row hidden col-xs-12">
								<div class="row">
									<div class="value col-xs-4 col-md-4">
										<select name="contact_type[]">
											<option value="Mobile">Mobile</option>
											<option value="Landline">Landline</option>
											<option value="Email">E-mail</option>
											<option value=""></option>
										</select>
									</div>
									<div class="value col-xs-7 col-md-7">
										<input type="text"  name="contact[]" value="" placeholder="Contact"/>
									</div>
									<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="contact-row-edit"><i class="glyphicon glyphicon-plus"></i></button>
								</div>
							</div>
						</div>
						<div class="row group-detail">
							<div class="title col-xs-3 col-md-3">
								Groups:
							</div>
							<div id="group-row-edit" class="multi-field-row hidden col-xs-12">
								<div class="row">
									<div class="value col-xs-11 col-md-11">
										<input type="text"  name="group[]" value="" placeholder="Group"/>
									</div>
									<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="group-row-edit"><i class="glyphicon glyphicon-plus"></i></button>
								</div>
							</div>
						</div>					
                    </div>
                </div>
                <div class="col-xs-12 col-md-3">
                    <div class="block">
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="holydress-detail info-tile">
                                    <div class="row">
                                        <div class="col-xs-12 info-title">
                                            <div class="border-bottom">Holydress</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-6 info-title no-r-padding">
                                            <div class="border-bottom border-right">Date</div>
                                        </div>
                                        <div class="col-xs-6 info-title no-l-padding">
                                            <div class="border-bottom">Place</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-6 no-r-padding">
                                            <input class="date value border-right" name="holydress_date" value="" placeholder="Holydress Date"/>
                                        </div>
                                        <div class="col-xs-6 no-l-padding">
                                            <input class="place value" name="holydress_place" value="" placeholder="Holydress Place"/>
                                        </div>           										
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12">
                                <div class="samadhi-detail info-tile">
                                    <div class="row">
                                        <div class="col-xs-12 info-title">
                                            <div class="border-bottom">Samadhi</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-6 info-title no-r-padding">
                                            <div class="border-bottom border-right">Date</div>
                                        </div>
                                        <div class="col-xs-6 info-title no-l-padding">
                                            <div class="border-bottom">Place</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-6 no-r-padding">
                                            <input class="date value border-right" name="samadhi_date" value="" placeholder="Samadhi Date"/>
                                        </div>
                                        <div class="col-xs-6 no-l-padding">
                                            <input class="place value" name="samadhi_place" value ="" placeholder="Samadhi Place"/>
                                        </div>           										
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12">
                                <div class="comments-container">
                                    <div class="comments-title">Comments</div>
                                    <textarea class="comments" name="comments" rows="3" placeholder="Comments if any"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div id="mjbj-detail-record" class="tile mjbj-record detail" style="display: none;">
            <div class="record-id">
                <span></span>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-3">
                    <div class="block image-upload-container">
                        <img class="self-image button" />
                    </div>
                </div>
                <div class="col-xs-12 col-md-3 main-details">
                    <div class="block">
                        <div class="row self-detail">
                            <div class="title col-xs-3 col-md-3">
                                Name:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row old-name-detail">
                            <div class="title col-xs-3 col-md-3">
                                Old Name:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row dob-detail">
                            <div class="title col-xs-3 col-md-3">
                                DoB:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                                <div class="date"></div>
                            </div>
                        </div>
                        <div class="row age-detail">
                            <div class="title col-xs-3 col-md-3">
                                Age:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row naamdaan-detail">
                            <div class="title col-xs-3 col-md-3">
                                Naam:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row by-detail">
                            <div class="title col-xs-3 col-md-3">
                                By:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-md-3 main-details">
                    <div class="block">
                        <div class="row rahbar-detail">
                            <div class="title col-xs-3 col-md-3">
                                Rahbar:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row past-rahbar-detail">
                            <div class="title col-xs-3 col-md-3">
                                Past Rah.:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row head-detail">
                            <div class="title col-xs-3 col-md-3">
                                Head:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row sewa-detail">
                            <div class="title col-xs-3 col-md-3">
                                Sewa:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                        <div class="row contact-detail">
                            <div class="title col-xs-3 col-md-3">
                                Contacts:
                            </div>
                            <div id="contact-type-value" class="multi-field-row col-xs-12 col-md-12">
								<div class="row">
									<div class="col-xs-4 contact-type"></div>
									<div class="col-xs-8 contact-value"></div>
								</div>
                            </div>
                        </div>
                        <div class="row group-detail">
                            <div class="title col-xs-3 col-md-3">
                                Groups:
                            </div>
                            <div class="value col-xs-9 col-md-9">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-md-3">
                    <div class="block">
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="holydress-detail info-tile">
                                    <div class="row">
                                        <div class="col-xs-12 info-title">
                                            <div class="border-bottom">Holydress</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-6 info-title no-r-padding">
                                            <div class="border-bottom border-right">Date</div>
                                        </div>
                                        <div class="col-xs-6 info-title no-l-padding">
                                            <div class="border-bottom">Place</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-6 no-r-padding">
                                            <div class="date value border-right"></div>
                                        </div>
                                        <div class="col-xs-6 no-l-padding">
                                            <div class="place value"></div>
                                        </div>           										
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12">
                                <div class="samadhi-detail info-tile">
                                    <div class="row">
                                        <div class="col-xs-12 info-title">
                                            <div class="border-bottom">Samadhi</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-6 info-title no-r-padding">
                                            <div class="border-bottom border-right">Date</div>
                                        </div>
                                        <div class="col-xs-6 info-title no-l-padding">
                                            <div class="border-bottom">Place</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-6 no-r-padding">
                                            <div class="date value border-right"></div>
                                        </div>
                                        <div class="col-xs-6 no-l-padding">
                                            <div class="place value"></div>
                                        </div>           										
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12">
                                <div class="comments-container">
                                    <div class="comments-title">Comments</div>
                                    <div class="comments"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="filtered-records">
        </div>
    </div>
</div>
`;

interface Props {
    records: MjBjRecord[];
}

class Component extends React.Component<Props> {
    render() {
        return (
            <React.Fragment>
                <div dangerouslySetInnerHTML={{ __html: MjBjViewTileCSS }}></div>
                <IntegratedRecordsList records={this.props.records} RecordTile={MjBjViewTile} html={html} RecordsHandler={MjbjRecords} />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: ApplicationState) {
    return {
        records: state.dataState.mjbj.filtered,
    };
}

export const MjBj = connect(mapStateToProps)(Component);
