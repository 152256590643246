import { HeaderControls, HeaderModule } from 'components/header/anand.header';
import { Records } from 'core/anand.records';
import { utils as Utils } from 'core/anand.utils';
import { addFormHTML, briefTileHTML, detailFormHTML, detailTileHTML } from './viewTile';

export const RecordsHandler = (function ($) {
	var configMap = {};

	var stateMap: any = {
		systemId: 'idcard',
	};

	function initModule(props?, updateRecordsState?) {
		HeaderModule.initModule([
			HeaderControls.addRecordBtn,
			HeaderControls.selectDropdown,
			HeaderControls.printBtn,
			HeaderControls.sortBtn,
		]);

		Records.projectTitle = 'ID Card Records';

		Records.getAnySearchFilter = getAnySearchFilter;
		Records.defaultSortOrder = 'name';
		Records.IdentifyingFieldName = 'name';

		Records.sortingFieldsMap = {
			name: 'Name',
			'address, title, name': 'Address',
			'dob, title, name': 'DoB',
		};

		Records.searchFieldsMap = {
			name: 'Name',
			address: 'Address',
			contacts: 'Contacts',
			ids: { filter: 'has', text: 'ID Cards' },
			dob: 'DoB',
			age: 'Age',
		};

		Records.isSearchDateTypeColumn = function (searchBy) {
			if (searchBy === 'dob' || searchBy === 'id_expiry_date' || searchBy === 'id_issue_date') {
				return true;
			} else {
				return false;
			}
		};

		Records.isImageTypeColumn = function (searchBy, parent) {
			if (!parent && searchBy === 'image') {
				return true;
			}

			if (parent === 'ids' && searchBy === 'ids_image') {
				return true;
			}

			return false;
		};

		Records.printOptions = {
			fieldNames: ['S. No.', 'Name', 'Address', 'DoB', 'Contacts', 'ID Cards'],
			fieldKeys: ['id', 'name', 'address', 'dob', 'contacts', 'ids'],
			defaultFieldWidths: { id: 40, name: 200, address: 200, dob: 100, contacts: 250, ids: 250 },
			defaultHeader: 'ID Card Records',
			beforePrintHook: function (data, forExport) {
				var titleMap = {
					Mahatma: 'Mh.',
					Bai: 'Bai',
					Mr: 'Mr.',
					Ms: 'Ms.',
					'': '',
				};

				for (var i = 0; i < data.length; i++) {
					data[i]['id'] = i + 1 + '.';
					var suffix = data[i]['title'] === 'Mahatma' || data[i]['title'] === 'Bai' ? ' Ji' : '';
					data[i]['title'] = titleMap[data[i]['title']];
					if (typeof forExport === 'undefined' || forExport === false) {
						data[i]['name'] = data[i]['title'] + ' ' + data[i]['name'] + suffix;
					} else {
						data[i]['name'] = data[i]['name'] + suffix;
					}

					if (data[i]['dob'] !== null) {
						data[i]['dob'] = getDMY(data[i]['dob']);
					}

					if (data[i]['contacts'] !== null) {
						var contacts = data[i]['contacts'];

						var html = '';
						var newLine = '';
						for (var contactType in contacts) {
							for (var j = 0; j < contacts[contactType].length; j++) {
								html += newLine + contactType + ': ' + contacts[contactType][j];
								newLine = '\n';
							}
						}
						data[i]['contacts'] = html;
					}

					if (typeof data[i]['ids'] !== 'undefined') {
						var idCards = data[i]['ids'];

						var html = '';
						var newLine = '';
						for (var j = 0; j < idCards.length; j++) {
							html += newLine + idCards[j]['id_type'] + ': ' + idCards[j]['id_no'];
							newLine = '\n';
						}
						data[i]['ids'] = html;
					}
				}

				return data;

				function getDMY(date) {
					var parts = date.split('-');
					return parts[2] + '-' + parts[1] + '-' + parts[0];
				}
			},
		};

		Records.exportOptions = {
			fieldKeys: ['title', 'name', 'address', 'dob', 'contacts', 'ids'],
			defaultFileName: 'ID Cards',
		};

		Records.specificSearchHook = function (searchBy, query) {
			if (searchBy === 'age') {
				Records.clearSpecificSearchBy('dob');

				if (!query) {
					return {
						searchBy: searchBy,
						query: query,
					};
				}

				searchBy = 'dob';
				query = '' + getDoBFromAge(new Date(), query).getFullYear();
			} else {
				Records.clearSpecificSearchBy(searchBy);
			}

			return {
				searchBy: searchBy,
				query: query,
			};
		};

		Records.hooks.beforeAddTileShow = function (addTile) {
			configureAutoUpdateAgeDob(addTile);
			return addTile;
		};

		Records.detailTileSelector = '#idcard-detail-record';
		Records.briefTileSelector = '#idcard-brief-record';
		Records.addTileSelector = '#idcard-add-record';
		Records.detailFormSelector = '#idcard-detail-record-form';
		Records.imageUploadSelector = "input[name='image']";

		Records.detailTileHTML = detailTileHTML;
		Records.briefTileHTML = briefTileHTML;
		Records.addFormHTML = addFormHTML;
		Records.detailFormHTML = detailFormHTML;

		Records.populateBriefTile = populateBriefTile;
		Records.populateDetailTile = populateDetailTile;
		Records.populateDetailForm = populateDetailForm;

		Records.updateRecordsState = updateRecordsState;

		Records.initModule({
			systemId: stateMap.systemId,
			prepareRecord: prepareRecord,
			props: props,
			hasContacts: true,
		});
	}

	function getDoBFromAge(currentDate, age) {
		var yob = currentDate.getFullYear() - age;
		var dob = new Date();
		dob.setFullYear(yob);
		dob.setMonth(currentDate.getMonth());
		dob.setDate(currentDate.getDate());

		return dob;
	}

	function getAgeFromDoB(currentDate, dob) {
		var age = currentDate.getFullYear() - dob.getFullYear();
		if (
			age !== 0 &&
			(currentDate.getMonth() < dob.getMonth() ||
				(currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate()))
		) {
			age--;
		}
		return age;
	}

	function prepareRecord(record) {
		record['name'] = Utils.upperFirstAll(record['name']);
		record['title'] = Utils.upperFirstAll(record['title']);

		Records.prepareMultiFieldForAdd(record, 'ids', [
			'id_no',
			'id_type',
			'id_expiry_date',
			'id_issue_date',
			'ids_image',
		]);

		return record;
	}

	function getAnySearchFilter(query) {
		return [
			{ title: { leftnocase: query } },
			{ name: { likenocase: query } },
			{ dob: { likenocase: query } },
			{ address: { likenocase: query } },
			{ contacts: { likenocase: query } },
			{ ids: { has: query } },
		];
	}

	async function populateBriefTile(sampleTile, record) {
		Records.setImageSrc(sampleTile.find('.self-image'), record['image'], '/idcard/images/');

		var suffix = record['title'] === 'Mahatma' || record['title'] === 'Bai' ? ' Ji' : '';
		var titleMapping = {
			Mahatma: 'Mh.',
			Bai: 'Bai',
			Mr: 'Mr.',
			Ms: 'Ms.',
		};
		sampleTile.find('.self-detail .title').html(titleMapping[record['title']]);
		sampleTile.find('.self-detail .value').html(record['name'] + suffix);

		return sampleTile;
	}

	async function populateDetailTile(sampleTile, recordOrig) {
		var record = {};
		for (var key in recordOrig) {
			if (recordOrig[key] == null || recordOrig[key] == '') {
				record[key] = null;
			} else {
				record[key] = recordOrig[key];
			}
		}

		Records.setImageSrc(sampleTile.find('.self-image'), record['image'], '/idcard/images/');

		var suffix = record['title'] === 'Mahatma' || record['title'] === 'Bai' ? ' Ji' : '';
		var titleMapping = {
			Mahatma: 'Mh.',
			Bai: 'Bai',
			Mr: 'Mr.',
			Ms: 'Ms.',
		};

		sampleTile.find('.main-details .self-detail .title').html();
		sampleTile
			.find('.main-details .self-detail .value')
			.html(titleMapping[record['title']] + ' ' + record['name'] + suffix);

		sampleTile.find('.main-details .address-detail .value').html(record['address']);

		if (record['contacts'] !== null) {
			var contacts = record['contacts'];
			for (var contactType in contacts) {
				for (var i = 0; i < contacts[contactType].length; i++) {
					var contactRow = sampleTile.find('.main-details .contact-detail #contact-type-value').clone();
					contactRow.attr('id', 'contact-type-value' + '-' + contactType + '-' + i);
					contactRow.find('.contact-type').html(contactType + ':');
					contactRow.find('.contact-value').html(contacts[contactType][i]);
					contactRow.show();
					sampleTile.find('.main-details .contact-detail').append(contactRow);
				}
			}
		} else {
			sampleTile.find('.main-details .contact-detail').hide();
		}

		if (typeof record['ids'] !== 'undefined') {
			var idCards = record['ids'];
			for (var i = 0; i < idCards.length; i++) {
				var idRow = sampleTile.find('.main-details .idcard-data-detail #idcard-detail').clone();
				idRow.attr('id', 'idcard-detail' + '-' + idCards[i]['id_type'] + '-' + i);
				idRow.find('.idcard-type').html(idCards[i]['id_type'] + ':');
				idRow.find('.idcard-no').html(idCards[i]['id_no']);
				if (idCards[i]['id_issue_date'] === '' || idCards[i]['id_issue_date'] === null) {
					idRow.find('.idcard-issue').html('&nbsp');
				} else {
					setDateField(idRow.find('.idcard-issue'), idCards[i]['id_issue_date'], false);
				}
				if (idCards[i]['id_expiry_date'] === '' || idCards[i]['id_expiry_date'] === null) {
					idRow.find('.idcard-expiry').html('&nbsp');
				} else {
					setDateField(idRow.find('.idcard-expiry'), idCards[i]['id_expiry_date'], false);
				}
				Records.setImageSrc(idRow.find('.idcard-image'), idCards[i]['ids_image'], '/idcard/images/');
				idRow.find('input[name="ids_pics_ids[]"]').val(idCards[i]['image']);
				idRow.show();
				sampleTile.find('.main-details .idcard-data-detail').append(idRow);
			}
		}

		handleEmptyData(sampleTile, record);

		setTimeout(function () {
			sampleTile.find('.idcard-data-detail .multi-field-row:visible:nth-child(odd)').each(function () {
				var nextSibling = $(this).next();
				if (!nextSibling) {
					return;
				}
				var h1 = nextSibling.height();
				var h0 = $(this).height();

				if (h1 > h0) {
					$(this).height(h1);
				} else {
					nextSibling.height(h0);
				}
			});
		}, 200);
		return sampleTile;

		function handleEmptyData(detailTile, record) {
			if (record['dob'] !== null) {
				setDateField(detailTile.find('.dob-detail .date'), record['dob'], false);

				var currentDate = new Date();
				var age = getAgeFromDoB(currentDate, new Date(record['dob']));
				detailTile.find('.age-detail .value').html(age);
			}
		}
	}

	function populateDetailForm(sampleTile, record) {
		Records.setImageSrc(sampleTile.find('.self-image'), record['image'], '/idcard/images/');

		sampleTile
			.find('.main-details .self-detail select[name="title"] option[value="' + record['title'] + '"]')
			.attr('selected', 'selected');
		sampleTile.find('.main-details .self-detail .value input').attr('value', record['name']);
		sampleTile.find('.main-details .address-detail .value input').attr('value', record['address']);

		if (record['contacts'] !== null) {
			var contacts = record['contacts'];
			var count = 0;
			for (var contactType in contacts) {
				for (var i = 0; i < contacts[contactType].length; i++) {
					count++;
				}
			}
			var contactDivId = Records.populateMultiAddFields(sampleTile.find('.main-details .contact-detail'), count);

			count = 1;
			for (var contactType in contacts) {
				for (var i = 0; i < contacts[contactType].length; i++) {
					var contactDiv = sampleTile.find('#' + contactDivId + count++);
					contactDiv
						.find('select[name="contact_type[]"] option[value="' + contactType + '"]')
						.attr('selected', 'selected');
					contactDiv.find('input[name="contact[]"]').val(contacts[contactType][i]);
				}
			}
		}

		if (typeof record['ids'] !== 'undefined') {
			var idCards = record['ids'];
			count = idCards.length;
			var idCardDivId = Records.populateMultiAddFields(
				sampleTile.find('.main-details .idcard-data-detail'),
				idCards.length
			);

			for (var i = 0; i < idCards.length; i++) {
				var idCardDiv = sampleTile.find('#' + idCardDivId + (i + 1));
				idCardDiv
					.find('select[name="id_type[]"] option[value="' + idCards[i]['id_type'] + '"]')
					.attr('selected', 'selected');
				idCardDiv.find('input[name="id_no[]"]').val(idCards[i]['id_no']);
				setDateField(idCardDiv.find('input[name="id_issue_date[]"]'), idCards[i]['id_issue_date'], true);
				setDateField(idCardDiv.find('input[name="id_expiry_date[]"]'), idCards[i]['id_expiry_date'], true);
				Records.setImageSrc(idCardDiv.find('.idcard-image'), idCards[i]['ids_image'], '/idcard/images/');
				idCardDiv.find('.idcard-image').attr('data-image-src-ids_image-' + i, idCards[i]['ids_image']);
			}
		} else {
			Records.setImageSrc(sampleTile.find('.idcard-image'), null, '/images/');
		}

		configureAutoUpdateAgeDob(sampleTile);

		handleEmptyFields(sampleTile, record);

		return sampleTile;

		function handleEmptyFields(detailTile, record) {
			if (record['dob'] !== null) {
				setDateField(detailTile.find('.dob-detail .date'), record['dob'], true);

				var currentDate = new Date();
				var age = getAgeFromDoB(currentDate, new Date(record['dob']));
				detailTile.find('.age-detail input[name="age"]').val(age);
			}
		}
	}

	function setDateField(div, date, isForm) {
		if (date !== null) {
			var dateAr = date.split('-');
			if (dateAr.length === 3) {
				var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
				if (isForm === true) {
					div.attr('value', dateStr);
					div.datepicker('update', dateStr);
				} else {
					dateStr = Utils.getDateStr(dateAr[0], dateAr[1], dateAr[2]);
					div.html(dateStr);
				}
			} else {
				if (isForm === true) {
					div.attr('value', date);
					div.datepicker('update', date);
				} else {
					div.html(date);
				}
			}
		}
	}

	function configureAutoUpdateAgeDob(detailTile) {
		detailTile.find('.dob-detail input[name="dob"]').on('change', function () {
			updateAge(detailTile);
		});

		detailTile.find('.age-detail input[name="age"]').on('keyup', function () {
			updateDob(detailTile);
		});

		function updateDob(detailTile) {
			var currentDate = new Date();

			var age = detailTile.find('.age-detail input[name="age"]').val();

			var dob = getDoBFromAge(currentDate, age);

			detailTile
				.find('.dob-detail input[name="dob"]')
				.attr('value', dob.getFullYear() + '-' + (dob.getMonth() + 1) + '-' + dob.getDate());
			detailTile.find('.dob-detail input[name="dob"]').datepicker('update', dob);
		}

		function updateAge(detailTile) {
			var currentDate = new Date();

			var dob = detailTile.find('.dob-detail input[name="dob"]').val();
			if (dob === null || dob === '') {
				return;
			}
			dob = new Date(dob);

			var age = getAgeFromDoB(currentDate, dob);
			detailTile.find('.age-detail input[name="age"]').val(age);
		}
	}

	return {
		initModule: initModule,
		setConfig: function (key, value) {
			configMap[key] = value;
		},
	};
})(jQuery);
