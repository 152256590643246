import { combineReducers } from 'redux';
import { PincodeRecord, PincodeStore } from 'types';
import { updateData } from '../../generic/reducers';
import { UpdatePincode, UpdatePincodeFiltered, UPDATE_PINCODE, UPDATE_PINCODE_FILTERED } from './actions';

export const pincodeByIdReducer = (state = {}, action: UpdatePincode) => {
	switch (action.type) {
		case UPDATE_PINCODE:
			return updateData(state, action.changes, 'pincode');
		default:
			return state;
	}
};

const filteredPincodeReducer = (state: PincodeRecord[] = [], action: UpdatePincodeFiltered) => {
	switch (action.type) {
		case UPDATE_PINCODE_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const pincodeReducer = combineReducers<PincodeStore>({
	byId: pincodeByIdReducer,
	filtered: filteredPincodeReducer,
});
