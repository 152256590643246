import { combineReducers } from 'redux';
import { RateRecord, RateStore } from 'types';
import { updateData } from '../../generic/reducers';
import { UpdateRate, UpdateRateFiltered, UPDATE_RATE, UPDATE_RATE_FILTERED } from './actions';

export const rateByIdReducer = (state = {}, action: UpdateRate) => {
	switch (action.type) {
		case UPDATE_RATE:
			return updateData(state, action.changes, 'rate');
		default:
			return state;
	}
};

const filteredRateReducer = (state: RateRecord[] = [], action: UpdateRateFiltered) => {
	switch (action.type) {
		case UPDATE_RATE_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const rateReducer = combineReducers<RateStore>({
	byId: rateByIdReducer,
	filtered: filteredRateReducer,
});
