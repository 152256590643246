import { Divider } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { Theme, withStyles } from "@material-ui/core/styles";
import Faq from "components/documents/Faq";
import Policies from "components/documents/Policies";
import { DialogContext } from "components/pages/DialogPage";
import { IsOwnerSAPD } from "config";
import { Icons } from "config/icons";
import { isAdmin } from "helpers";
import { Context } from "helpers/context";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { signout } from "services/api/auth";
import { ApplicationState, UserData } from "types";
import ChangeLanguage from "./ChangeLanguage";
import ChangePassword from "./ChangePassword";
import DeleteAccount from "./DeleteAccount";
import { PostalContact } from "./PostalContact";
import ProfileDetails from "./ProfileDetails";
import SignIn from "./SignIn";
import { UserFeedback } from "./UserFeedback";
import { UserPicInfo } from "./UserPicInfo";
import { UserSettings } from "./UserSettings";

const styles = (theme: Theme) => ({
    root: {
        paddingBottom: theme.spacing(5),
        display: "flex",
        flexDirection: "column" as "column",
        backgroundColor: theme.palette.grey[200],
        minHeight: "100%",
    },
    card: {
        margin: theme.spacing(1),
    },
    picsection: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    options: {
        paddingTop: theme.spacing(3),

        "& .MuiCardHeader-subheader": {
            fontSize: "1.3rem",
            letterSpacing: 0,
        },
    },
    userpicNA: {
        width: "100%",
        fontSize: 80,
        color: theme.palette.grey[400],
    },
    userpic: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: theme.spacing(1),
    },
    signoutbtn: {
        height: 42,
        width: "60%",
        alignSelf: "center",
        marginTop: theme.spacing(4),
    },
    newuser: {
        alignSelf: "center",
        marginTop: theme.spacing(3),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    avatar: {
        color: "#fff",
        backgroundColor: blueGrey[300],
    },
});

export interface UserOptionsProps {
    signout: () => Promise<void>;
    userData?: UserData;
    user?: firebase.default.User;
    initScreen?: string;
    classes: any;
    history: any;
}

enum AccountMode {
    MainOptions = "main",
    SignIn = "signin",
    Profile = "profile",
    Password = "password",
    Delete = "delete",
    ChangeLang = "lang",
    Settings = "settings",
    Faq = "faq",
    Feedback = "feedback",
    Contact = "contact",
    Policy = "policy",
}

class UserOptions extends React.Component<UserOptionsProps> {
    private getScreenTitle(key) {
        let title;
        switch (key) {
            case AccountMode.SignIn:
                title = "Sign In";
                break;
            case AccountMode.Profile:
                title = "Profile Details";
                break;
            case AccountMode.Password:
                title = "Change Password";
                break;
            case AccountMode.Delete:
                title = "Delete Account";
                break;
            case AccountMode.ChangeLang:
                title = "Choose Language";
                break;
            case AccountMode.Settings:
                title = "App Settings";
                break;
            case AccountMode.Faq:
                title = "FAQ";
                break;
            case AccountMode.Policy:
                title = "Policies";
                break;
            case AccountMode.Feedback:
                title = "Provide Feedback";
                break;
            case AccountMode.Contact:
                title = "Contact Us";
                break;
        }
        return title;
    }

    private pushScreen(key) {
        if (key === AccountMode.Feedback && !this.props.user && IsOwnerSAPD) {
            this.context.pushScreen({
                title: "Sign In to Submit Feedback",
                key: AccountMode.SignIn,
            });

            return;
        }

        this.context.pushScreen({
            title: this.getScreenTitle(key),
            key,
            dark: key !== AccountMode.SignIn,
        });
    }

    componentDidMount() {
        if (this.props.initScreen) {
            this.pushScreen(this.props.initScreen);
        }
    }

    render() {
        let { classes, user, signout, userData } = this.props;

        let screen = this.context.screens[this.context.screens.length - 1];
        let component;
        switch (screen.key) {
            case AccountMode.SignIn:
                component = IsOwnerSAPD ? <SignIn /> : <div />;
                break;
            case AccountMode.Profile:
                component = <ProfileDetails />;
                break;
            case AccountMode.Password:
                component = <ChangePassword />;
                break;
            case AccountMode.Delete:
                component = <DeleteAccount />;
                break;
            case AccountMode.ChangeLang:
                component = <ChangeLanguage />;
                break;
            case AccountMode.Settings:
                component = <UserSettings />;
                break;
            case AccountMode.Faq:
                component = <Faq />;
                break;
            case AccountMode.Policy:
                component = <Policies />;
                break;
            case AccountMode.Feedback:
                component = IsOwnerSAPD ? <UserFeedback /> : <div />;
                break;
            case AccountMode.Contact:
                component = <PostalContact />;
                break;
        }

        if (component) {
            return component;
        }

        return (
            <div className={classes.root}>
                {user ? (
                    <UserPicInfo />
                ) : IsOwnerSAPD ? (
                    <Button className={classes.signoutbtn} variant='contained' color='primary' onClick={() => this.pushScreen(AccountMode.SignIn)}>
                        Sign In
                    </Button>
                ) : null}
                <div className={classes.options}>
                    {user ? (
                        <React.Fragment>
                            <Card className={classes.card} onClick={() => this.pushScreen(AccountMode.Profile)}>
                                <CardHeader
                                    className={classes.header}
                                    avatar={<Avatar className={classes.avatar}>{Icons.Person}</Avatar>}
                                    action={<IconButton>{Icons.NavigateNext}</IconButton>}
                                    title={<Typography>Account Details</Typography>}
                                    subheader='Update profile details'
                                />
                            </Card>
                            {user.providerData.map((provider) => provider?.providerId).indexOf("password") >= 0 ? (
                                <Card className={classes.card} onClick={() => this.pushScreen(AccountMode.Password)}>
                                    <CardHeader
                                        className={classes.header}
                                        avatar={<Avatar className={classes.avatar}>{Icons.Lock}</Avatar>}
                                        action={<IconButton>{Icons.NavigateNext}</IconButton>}
                                        title={<Typography>Password</Typography>}
                                        subheader='Change password'
                                    />
                                </Card>
                            ) : null}
                            <Card className={classes.card} onClick={() => this.pushScreen(AccountMode.Delete)}>
                                <CardHeader
                                    className={classes.header}
                                    avatar={<Avatar className={classes.avatar}>{Icons.Delete}</Avatar>}
                                    action={<IconButton>{Icons.NavigateNext}</IconButton>}
                                    title={<Typography>Delete</Typography>}
                                    subheader='Delete your account'
                                />
                            </Card>
                            <Divider className={classes.divider}></Divider>
                        </React.Fragment>
                    ) : null}
                    {/* <Card className={classes.card} onClick={() => this.pushScreen(AccountMode.ChangeLang)}>
						<CardHeader
							className={classes.header}
							avatar={<Avatar className={classes.avatar}>{Icons.Language}</Avatar>}
							action={<IconButton>{Icons.NavigateNext}</IconButton>}
							title={<Typography>Language</Typography>}
							subheader='Choose language - English or Hindi'
						/>
					</Card> */}
                    {isAdmin(userData) && (
                        <Card className={classes.card} onClick={() => this.pushScreen(AccountMode.Settings)}>
                            <CardHeader
                                className={classes.header}
                                avatar={<Avatar className={classes.avatar}>{Icons.Settings}</Avatar>}
                                action={<IconButton>{Icons.NavigateNext}</IconButton>}
                                title={<Typography>Settings</Typography>}
                                subheader='Manage notifications & app settings'
                            />
                        </Card>
                    )}
                    {/* <Card className={classes.card} onClick={() => {}}>
						<CardHeader
							className={classes.header}
							avatar={
								<Avatar className={classes.avatar}>
									<OfflinePinIcon />
								</Avatar>
							}
							action={
								<IconButton>
									<NavigateNextIcon />
								</IconButton>
							}
							title={<Typography>Offline Data</Typography>}
							subheader='View offline data stored on device'
						/>
					</Card> */}
                    {/* <Divider className={classes.divider}></Divider> */}
                    {/* <Card className={classes.card} onClick={() => this.pushScreen(AccountMode.Faq)}>
						<CardHeader
							className={classes.header}
							avatar={<Avatar className={classes.avatar}>{Icons.Help}</Avatar>}
							action={<IconButton>{Icons.NavigateNext}</IconButton>}
							title={<Typography>FAQ</Typography>}
							subheader='Frequently asked questions'
						/>
					</Card> */}
                    {/* {IsOwnerSAPD ? (
						<Card className={classes.card} onClick={() => this.pushScreen(AccountMode.Feedback)}>
							<CardHeader
								className={classes.header}
								avatar={<Avatar className={classes.avatar}>{Icons.Feedback}</Avatar>}
								action={<IconButton>{Icons.NavigateNext}</IconButton>}
								title={<Typography>Feedback</Typography>}
								subheader='Provide your feedback'
							/>
						</Card>
					) : null} */}
                    {/* <Card className={classes.card} onClick={() => this.pushScreen(AccountMode.Contact)}>
						<CardHeader
							className={classes.header}
							avatar={<Avatar className={classes.avatar}>{Icons.Business}</Avatar>}
							action={<IconButton>{Icons.NavigateNext}</IconButton>}
							title={<Typography>Contact Us</Typography>}
							subheader='Postal address'
						/>
					</Card>
					<Card className={classes.card} onClick={() => this.pushScreen(AccountMode.Policy)}>
						<CardHeader
							className={classes.header}
							avatar={<Avatar className={classes.avatar}>{Icons.Policy}</Avatar>}
							action={<IconButton>{Icons.NavigateNext}</IconButton>}
							title={<Typography>Policies</Typography>}
							subheader='Terms of Service and Privacy Policy'
						/>
					</Card> */}
                </div>
                {user ? (
                    <Button
                        className={classes.signoutbtn}
                        variant='contained'
                        color='primary'
                        onClick={async () => {
                            await signout();
                        }}
                    >
                        Sign Out
                    </Button>
                ) : null}
                {/* <Typography align='center' variant='body2' style={{ marginTop: 32 }}>
					Current Version: {getBuildVersion()}
				</Typography> */}
            </div>
        );
    }
}

function mapStateToProps({ dataState }: ApplicationState, props: { initScreen?: string }) {
    let { userStore } = dataState;

    return {
        userData: userStore.userData,
        user: userStore.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        signout: async () => {
            await dispatch(signout({}, new Context()));
        },
    };
}

UserOptions.contextType = DialogContext;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(UserOptions)));
