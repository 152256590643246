import { isMobile } from 'helpers';
import { populateViewTileHelper } from 'helpers/RecordsHelper';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, RateRecord } from 'types';

export const detailTileHTML = `
<div id="amar-rates-detail-record" class="tile amar-rates-record detail">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row category-detail">
					<div class="title col-xs-3">
						Category:
					</div>
					<div class="value category-value col-xs-9">
					</div>
				</div>                    
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row country-detail">
					<div class="title col-xs-3">
						Country:
					</div>
					<div class="value country-value col-xs-9">
					</div>
				</div>                    
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row rate-detail">
					<div class="title col-xs-3">
						Rate:
					</div>
					<div class="value rate-value col-xs-9">
					</div>
				</div>                    
			</div>
		</div>
	</div>
</div>
`;

export const briefTileHTML = `
<div id="amar-rates-brief-record" class="tile amar-rates-record brief">
	<div class="row">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row category-detail">
					<div class="title col-xs-3">
						Category:
					</div>
					<div class="value category-value col-xs-9">
					</div>
				</div>                    
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row country-detail">
					<div class="title col-xs-3">
						Country:
					</div>
					<div class="value country-value col-xs-9">
					</div>
				</div>                    
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row rate-detail">
					<div class="title col-xs-3">
						Rate:
					</div>
					<div class="value rate-value col-xs-9">
					</div>
				</div>                    
			</div>
		</div>
	</div>
	<div class="record-id">
		<span></span>
	</div>
</div>
`;

export const detailFormHTML = `
<form id="amar-rates-detail-record-form" class="tile amar-rates-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row category-detail">
					<div class="title col-xs-3">
						Category:
					</div>
					<div class="value category-value col-xs-9">
						<select name="category_id">
						</select>
					</div>
				</div>                    
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row country-detail">
					<div class="title col-xs-3">
						Country:
					</div>
					<div class="value country-value col-xs-9">
						<input type="text"  name="country" value="" placeholder="Country"/>
					</div>
				</div>                    
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row rate-detail">
					<div class="title col-xs-3">
						Rate:
					</div>
					<div class="value rate-value col-xs-9">
						<input type="number"  name="rate" value="" placeholder="Rate"/>
					</div>
				</div>                    
			</div>
		</div>
	</div>
</form>
`;

export const addFormHTML = `
<form id="amar-rates-add-record" class="tile amar-rates-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="row">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row category-detail">
					<div class="title col-xs-3">
						Category:
					</div>
					<div class="value category-value col-xs-9">
						<select name="category_id">
						</select>
					</div>
				</div>                    
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row country-detail">
					<div class="title col-xs-3">
						Country:
					</div>
					<div class="value country-value col-xs-9">
						<input type="text"  name="country" value="" placeholder="Country"/>
					</div>
				</div>                    
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row rate-detail">
					<div class="title col-xs-3">
						Rate:
					</div>
					<div class="value rate-value col-xs-9">
						<input type="number"  name="rate" value="" placeholder="Rate"/>
					</div>
				</div>                    
			</div>
		</div>
	</div>
</form>
`;

interface Props {
	record?: RateRecord;
	index?: number;
	listRef?: React.RefObject<any>;
}

class Component extends React.PureComponent<Props> {
	private tileRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	componentDidUpdate(prevProps, prevState) {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	render() {
		return (
			<div
				ref={this.tileRef}
				dangerouslySetInnerHTML={{ __html: isMobile() ? briefTileHTML : detailTileHTML }}
			></div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {};
}

export const ViewTile = connect(mapStateToProps)(Component);
