import { IntegratedRecordsList } from 'components/IntegratedRecordsList';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, KutiyaRecord } from 'types';
import { KutiyaRecords } from './anand.kutiyaRecords';
import { KutiyaViewTile, kutiyaViewTileCSS } from './kutiyaViewTile';

let kutiyaCSS = `
#selection-dropdown {
	display: none;
}

#kutiya-detail-record-form {
	display: none;
}

#kutiya-add-record {
	display: none;	
}

form.tile.kutiya-record .address {
	width: 100%;
	resize: none;
    height: 6em;
}

.rahbar-sugestion img {
	width: 100%;
	padding: 0 5px;
}

#resident-detail, #contact-type-value {
	display: none;
}

.multi-field-row {
	border-bottom: 1px solid lightgrey;
	padding-top: 3px;
	padding-bottom: 3px;
}

.multi-field-row:nth-last-child(1) {
	border-bottom: none;
}

#headwise-container {
	overflow-x: auto;
}

#headwise {
	width: 600%;
}
`;

let kutiyaPrintCSS = `
body {
	background-color: rgba(0, 0, 0, 0);
}

@media print {
	.print-top-div footer {
		page-break-after: always;
	}
}

.print-top-div footer {
	position: absolute;
	bottom: 40px;
	font-weight: bold;
	font-size: 90%;
	padding: 10px 0px;
	right: 40px;
	left: 40px;
	page-break-after: always;
}
/*
.print-record {
	page-break-after: always;	
}*/

.print-top-div footer > div {
	padding: 5px 0;
}

.print-top-div header { 
	font-size: 110%;
	text-align:center;
	font-weight: bold;
	width: 100%;
	padding: 40px 0px;	
}

.print-top-div {
	background-color: white;
	padding: 0px 40px;
	width: 8.27in;
	position: relative;
	height: 11.69in;
}

.print-top-div .print-top-header {
	font-size: 125%;
	padding: 20px 0px; 
	width: 100%; 
	text-align: center; 
	font-weight: bold;
}

.print-top-div .field {
	padding-top: 30px;
}

.print-top-div .title {
	font-weight: bold;
	font-size: 90%;
	text-align: left;
	padding-right: 0px;
	float: left;
}

.print-top-div .value {
	border-bottom: 1px dotted black;
	display: block;
	overflow: hidden;
	padding-left: 15px;
}
`;

const html = `
<style>
	${kutiyaCSS}
	${kutiyaPrintCSS}
</style>

<div id="page-records">
    <form id="kutiya-add-record" class="tile kutiya-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
        <div class="row">
            <div class="col-xs-12 col-md-4 main-details">
                <div class="block">
                    <div class="row form-trust-detail">
                        <div class="title col-xs-3 col-md-2">
                            Form#:
                        </div>
                        <div class="value col-xs-9 col-md-4">
                            <input type="text"  name="form_no" value="" placeholder="Form No."/>
                        </div>
                        <div class="title col-xs-3 col-md-2">
                            Trust:
                        </div>
                        <div class="value trust-value col-xs-9 col-md-4">
                            <input type="text" value="" name="trust_name" placeholder="Trust" />
                        </div>
                    </div>
                    <div class="row self-detail">
                        <div class="title col-xs-3 col-md-2">
                            Kutiya:
                        </div>
                        <div class="value col-xs-9 col-md-10">
                            <input type="text"  name="name" value="" required placeholder="Kutiya Name*"/>
                        </div>
                    </div>
					<div class="row head-detail">
						<div class="title col-xs-3 col-md-2">
							Head:
						</div>
						<div class="col-xs-3 col-md-3">
							<select name="head_title">
								<option value="Mahatma">Mahatma</option>
								<option value="Bai">Bai</option>
								<option value="Mh/Bai">Mh./Bai</option>
							</select>
						</div>
						<div class="value col-xs-6 col-md-7">
							<input type="text"  name="head_name" required value="" placeholder="Head Name*"/>
						</div>
					</div>                    
					<div class="row address-detail">
                        <div class="title col-xs-3 col-md-2">
                            Address:
                        </div>
                        <div class="value col-xs-9 col-md-10">
							<textarea class="address" name="address" required rows="2" placeholder="Address*"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-4 main-details">
                <div class="block">
                    <div class="row city-detail">
                        <div class="title col-xs-3 col-md-2">
                            City:
                        </div>
                        <div class="value city-value col-xs-9 col-md-4">
                            <input type="text" value="" required name="city" placeholder="City*"/>
                        </div>
                        <div class="title col-xs-3 col-md-2">
                            Pincode:
                        </div>
                        <div class="value pincode-value col-xs-9 col-md-4">
                            <input type="text" value="" name="pincode" placeholder="Pincode" />
                        </div>
                    </div>
                    <div class="row state-detail">
                        <div class="title col-xs-3 col-md-2">
                            State:
                        </div>
                        <div class="value state-value col-xs-9 col-md-4">
                            <input type="text" value="" name="state" required placeholder="State*" />
                        </div>
                        <div class="title col-xs-3 col-md-2">
                            Country:
                        </div>
                        <div class="value country-value col-xs-9 col-md-4">
                            <input type="text" value="" name="country" required placeholder="Country*" />
                        </div>
                    </div>
                    <div class="row lift-area-detail">
                        <div class="title col-xs-3 col-md-2">
                            Lift:
                        </div>
                        <div class="value col-xs-9 col-md-4">
							<select name="lift">
								<option value="No">No</option>
								<option value="Yes">Yes</option>
								<option value="Unknown">Unknown</option>
							</select>
                        </div>
                        <div class="title col-xs-3 col-md-2">
                            Area:
                        </div>
                        <div class="value area-value col-xs-9 col-md-4">
                            <input type="number" value="" name="area" placeholder="Area (sqft)" />
                        </div>
                    </div>
                    <div class="row members-detail">
                        <div class="title col-xs-3 col-md-2">
                            Males:
                        </div>
                        <div class="value males-value col-xs-9 col-md-4">
                            <input type="number" value="" name="male_members" placeholder="Male Count" />
                        </div>
                        <div class="title col-xs-3 col-md-2">
                            Females:
                        </div>
                        <div class="value females-value col-xs-9 col-md-4">
                            <input type="number" value="" name="female_members" placeholder="Female Count" />
                        </div>
                    </div>
                    <div class="row status-detail">
                        <div class="title col-xs-3 col-md-2">
                            Status:
                        </div>
                        <div class="value status-value col-xs-9 col-md-10">
                            <input type="text" value="" name="property_status" placeholder="Property Status" />
                        </div>
					</div>
                    <div class="row comments-detail">
                        <div class="title col-xs-3 col-md-2">
                            Comments:
                        </div>
                        <div class="value col-xs-9 col-md-10">
                            <input type="text"  name="comments" value="" placeholder="Comments"/>
                        </div>
					</div>
                </div>
            </div>
            <div class="col-xs-12 col-md-4 main-details">
                <div class="block">
					<div class="row resident-detail">
						<div class="title col-xs-12 col-md-12">
							Residents:
						</div>
						<div id="resident-row" class="multi-field-row hidden col-xs-12">
							<div class="row">
								<div class="value col-xs-4 col-md-3">
									<select name="resident_title[]">
										<option value=""></option>
										<option value="Mahatma">Mahatma</option>
										<option value="Bai">Bai</option>
										<option value="Mr.">Mr.</option>
										<option value="Ms.">Ms.</option>
									</select>
								</div>
								<div class="value col-xs-7 col-md-8">
									<input type="text"  name="resident_name[]" value="" placeholder="Name*"/>
								</div>
								<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="resident-row"><i class="glyphicon glyphicon-plus"></i></button>
							</div>
						</div>
					</div>
					<div class="row contact-detail">
						<div class="title col-xs-12 col-md-12">
							Contacts:
						</div>
						<div id="contact-row" class="multi-field-row hidden col-xs-12">
							<div class="row">
								<div class="value col-xs-4 col-md-3">
									<select name="contact_type[]">
										<option value=""></option>
										<option value="Mobile">Mobile</option>
										<option value="Landline">Landline</option>
										<option value="Email">E-mail</option>
									</select>
								</div>
								<div class="value col-xs-7 col-md-8">
									<input type="text"  name="contact[]" value="" placeholder="Contact"/>
								</div>
								<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="contact-row"><i class="glyphicon glyphicon-plus"></i></button>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    </form>
    <div class="records">
        <form id="kutiya-detail-record-form" class="tile kutiya-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
            <div class="record-id">
                <span></span>
            </div>
			<div class="row">
				<div class="col-xs-12 col-md-4 main-details">
					<div class="block">
						<div class="row form-trust-detail">
							<div class="title col-xs-3 col-md-2">
								Form#:
							</div>
							<div class="value form-value col-xs-9 col-md-4">
								<input type="text"  name="form_no" value="" required placeholder="Form No."/>
							</div>
							<div class="title col-xs-3 col-md-2">
								Trust:
							</div>
							<div class="value trust-value col-xs-9 col-md-4">
								<input type="text" value="" name="trust_name" placeholder="Trust" />
							</div>
						</div>
						<div class="row self-detail">
							<div class="title col-xs-3 col-md-2">
								Kutiya:
							</div>
							<div class="value col-xs-9 col-md-10">
								<input type="text"  name="name" value="" required placeholder="Kutiya Name*"/>
							</div>
						</div>
						<div class="row head-detail">
							<div class="title col-xs-3 col-md-2">
								Head:
							</div>
							<div class="col-xs-3 col-md-3">
								<select name="head_title">
									<option value="Mahatma">Mahatma</option>
									<option value="Bai">Bai</option>
									<option value="Mh/Bai">Mh./Bai</option>
								</select>
							</div>
							<div class="value col-xs-6 col-md-7">
								<input type="text"  name="head_name" value="" required placeholder="Head Name*"/>
							</div>
						</div>                    
						<div class="row address-detail">
							<div class="title col-xs-3 col-md-2">
								Address:
							</div>
							<div class="value col-xs-9 col-md-10">
								<textarea class="address" name="address" rows="2" required placeholder="Address*"></textarea>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xs-12 col-md-4 main-details">
					<div class="block">
						<div class="row city-detail">
							<div class="title col-xs-3 col-md-2">
								City:
							</div>
							<div class="value city-value col-xs-9 col-md-4">
								<input type="text" value="" name="city" required placeholder="City*"/>
							</div>
							<div class="title col-xs-3 col-md-2">
								Pincode:
							</div>
							<div class="value pincode-value col-xs-9 col-md-4">
								<input type="text" value="" name="pincode" placeholder="Pincode" />
							</div>
						</div>
						<div class="row state-detail">
							<div class="title col-xs-3 col-md-2">
								State:
							</div>
							<div class="value state-value col-xs-9 col-md-4">
								<input type="text" value="" name="state" required placeholder="State*" />
							</div>
							<div class="title col-xs-3 col-md-2">
								Country:
							</div>
							<div class="value country-value col-xs-9 col-md-4">
								<input type="text" value="" name="country" required placeholder="Country*" />
							</div>
						</div>
						<div class="row lift-area-detail">
							<div class="title col-xs-3 col-md-2">
								Lift:
							</div>
							<div class="value col-xs-9 col-md-4">
								<select name="lift">
									<option value="No">No</option>
									<option value="Yes">Yes</option>
									<option value="Unknown">Unknown</option>
								</select>
							</div>
							<div class="title col-xs-3 col-md-2">
								Area:
							</div>
							<div class="value area-value col-xs-9 col-md-4">
								<input type="number" value="" name="area" placeholder="Area (sqft)" />
							</div>
						</div>
						<div class="row members-detail">
							<div class="title col-xs-3 col-md-2">
								Males:
							</div>
							<div class="value males-value col-xs-9 col-md-4">
								<input type="number" value="" name="male_members" placeholder="Male Count" />
							</div>
							<div class="title col-xs-3 col-md-2">
								Females:
							</div>
							<div class="value females-value col-xs-9 col-md-4">
								<input type="number" value="" name="female_members" placeholder="Female Count" />
							</div>
						</div>
						<div class="row status-detail">
							<div class="title col-xs-3 col-md-2">
								Status:
							</div>
							<div class="value status-value col-xs-9 col-md-10">
								<input type="text" value="" name="property_status" placeholder="Property Status" />
							</div>
						</div>
						<div class="row comments-detail">
							<div class="title col-xs-3 col-md-2">
								Comments:
							</div>
							<div class="value col-xs-9 col-md-10">
								<input type="text"  name="comments" value="" placeholder="Comments"/>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xs-12 col-md-4 main-details">
					<div class="block">
						<div class="row resident-detail">
							<div class="title col-xs-12 col-md-12">
								Residents:
							</div>
							<div id="resident-edit-row" class="multi-field-row hidden col-xs-12">
								<div class="row">
									<div class="value col-xs-4 col-md-3">
										<select name="resident_title[]">
											<option value=""></option>
											<option value="Mahatma">Mahatma</option>
											<option value="Bai">Bai</option>
											<option value="Mr.">Mr.</option>
											<option value="Ms.">Ms.</option>
										</select>
									</div>
									<div class="value col-xs-7 col-md-8">
										<input type="text"  name="resident_name[]" value="" placeholder="Name*"/>
									</div>
									<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="resident-edit-row"><i class="glyphicon glyphicon-plus"></i></button>
								</div>
							</div>
						</div>
						<div class="row contact-detail">
							<div class="title col-xs-12 col-md-12">
								Contacts:
							</div>
							<div id="contact-edit-row" class="multi-field-row hidden col-xs-12">
								<div class="row">
									<div class="value col-xs-4 col-md-3">
										<select name="contact_type[]">
											<option value=""></option>
											<option value="Mobile">Mobile</option>
											<option value="Landline">Landline</option>
											<option value="Email">E-mail</option>
										</select>
									</div>
									<div class="value col-xs-7 col-md-8">
										<input type="text"  name="contact[]" value="" placeholder="Contact"/>
									</div>
									<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="contact-edit-row"><i class="glyphicon glyphicon-plus"></i></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </form>
		<div id="kutiya-detail-record" class="tile kutiya-record detail" style="display:none">
            <div class="record-id">
                <span></span>
            </div>
			<div class="row">
				<div class="col-xs-12 col-md-4 main-details">
					<div class="block">
						<div class="row form-trust-detail">
							<div class="title col-xs-3 col-md-2">
								Form#:
							</div>
							<div class="value form-value col-xs-9 col-md-4">
							</div>
							<div class="title col-xs-3 col-md-2">
								Trust:
							</div>
							<div class="value trust-value col-xs-9 col-md-4">
							</div>
						</div>
						<div class="row self-detail">
							<div class="title col-xs-3 col-md-2">
								Kutiya:
							</div>
							<div class="value col-xs-9 col-md-10">
							</div>
						</div>
						<div class="row head-detail">
							<div class="title col-xs-3 col-md-2">
								Head:
							</div>
							<div class="value col-xs-9 col-md-10">
							</div>
						</div>                    
						<div class="row address-detail">
							<div class="title col-xs-3 col-md-2">
								Address:
							</div>
							<div class="value col-xs-9 col-md-10">
							</div>
						</div>
					</div>
				</div>
				<div class="col-xs-12 col-md-4 main-details">
					<div class="block">
						<div class="row city-detail">
							<div class="title col-xs-3 col-md-2">
								City:
							</div>
							<div class="value city-value col-xs-9 col-md-4">
							</div>
							<div class="title col-xs-3 col-md-2">
								Pincode:
							</div>
							<div class="value pincode-value col-xs-9 col-md-4">
							</div>
						</div>
						<div class="row state-detail">
							<div class="title col-xs-3 col-md-2">
								State:
							</div>
							<div class="value state-value col-xs-9 col-md-4">
							</div>
							<div class="title col-xs-3 col-md-2">
								Country:
							</div>
							<div class="value country-value col-xs-9 col-md-4">
							</div>
						</div>
						<div class="row lift-area-detail">
							<div class="title col-xs-3 col-md-2">
								Lift:
							</div>
							<div class="value lift-value col-xs-9 col-md-4">
							</div>
							<div class="title col-xs-3 col-md-2">
								Area:
							</div>
							<div class="value area-value col-xs-9 col-md-4">
							</div>
						</div>
						<div class="row members-detail">
							<div class="title col-xs-3 col-md-2">
								Males:
							</div>
							<div class="value males-value col-xs-9 col-md-4">
							</div>
							<div class="title col-xs-3 col-md-2">
								Females:
							</div>
							<div class="value females-value col-xs-9 col-md-4">
							</div>
						</div>
						<div class="row status-detail">
							<div class="title col-xs-3 col-md-2">
								Status:
							</div>
							<div class="value status-value col-xs-9 col-md-10">
							</div>
						</div>
						<div class="row comments-detail">
							<div class="title col-xs-3 col-md-2">
								Comments:
							</div>
							<div class="value col-xs-9 col-md-10">
							</div>
						</div>
					</div>
				</div>
				<div class="col-xs-12 col-md-4 main-details">
					<div class="block">
						<div class="row resident-detail">
							<div class="title col-xs-12 col-md-12">
								Residents:
							</div>
							<div id="resident-row" class="multi-field-row col-xs-12">
								<div class="row">
									<div class="resident_title col-xs-3 col-md-2">
									</div>
									<div class="resident_name col-xs-9 col-md-10">
									</div>
								</div>
							</div>
						</div>
						<div class="row contact-detail">
							<div class="title col-xs-12 col-md-12">
								Contacts:
							</div>
                            <div id="contact-type-value" class="multi-field-row col-xs-12 col-md-12">
								<div class="row">
									<div class="col-xs-4 contact-type"></div>
									<div class="col-xs-8 contact-value"></div>
								</div>
                            </div>
						</div>
					</div>
				</div>
			</div>
        </div>
        <div class="filtered-records">
        </div>
    </div>
</div>
`;

interface Props {
	records: KutiyaRecord[];
}

class Component extends React.Component<Props> {
	render() {
		return (
			<React.Fragment>
				<div dangerouslySetInnerHTML={{ __html: kutiyaViewTileCSS }}></div>
				<IntegratedRecordsList
					records={this.props.records}
					RecordTile={KutiyaViewTile}
					html={html}
					RecordsHandler={KutiyaRecords}
					// getItemSize={() => (isMobile() ? 100 : 320)}
				/>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		records: state.dataState.kutiya.filtered,
	};
}

export const Kutiya = connect(mapStateToProps)(Component);
