import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { IDCardRecord } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_IDCARD = '@@mjbj/UPDATE_IDCARD';
export type UPDATE_IDCARD = typeof UPDATE_IDCARD;

export interface UpdateIdcard extends Action {
	type: UPDATE_IDCARD;
	changes: FBChanges;
}

export function updateIdcard(changes: FBChanges): UpdateIdcard {
	return {
		type: UPDATE_IDCARD,
		changes,
	};
}

export const UPDATE_IDCARD_FILTERED = UPDATE_IDCARD + '_FILTERED';
export type UPDATE_IDCARD_FILTERED = typeof UPDATE_IDCARD_FILTERED;

export interface UpdateIdcardFiltered extends Action {
	type: UPDATE_IDCARD_FILTERED;
	payload: IDCardRecord[];
}
