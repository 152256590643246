import { isMobile } from 'helpers';
import { populateViewTileHelper } from 'helpers/RecordsHelper';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, IDCardRecord } from 'types';

export const detailTileHTML = `
<div id="idcard-detail-record" class="tile id-card detail">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="col-xs-12 col-md-3">
			<div class="block image-upload-container">
				<img class="self-image button" />
			</div>
		</div>
		<div class="col-xs-12 col-md-3 main-details">
			<div class="block">
				<div class="row self-detail">
					<div class="title col-xs-3 col-md-3">
						Name:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row dob-detail">
					<div class="title col-xs-3 col-md-3">
						DoB:
					</div>
					<div class="value col-xs-9 col-md-9">
						<div class="date"></div>
					</div>
				</div>
				<div class="row age-detail">
					<div class="title col-xs-3 col-md-3">
						Age:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row address-detail">
					<div class="title col-xs-3 col-md-3">
						Address:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row contact-detail">
					<div class="title col-xs-12 col-md-12">
						Contacts:
					</div>
					<div id="contact-type-value" class="multi-field-row col-xs-12 col-md-12">
						<div class="row">
							<div class="col-xs-4 contact-type"></div>
							<div class="col-xs-8 contact-value"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-6 main-details">
			<div class="block">
				<div class="row idcard-data-detail">
					<div class="title col-xs-12 col-md-12">
						ID Cards:
					</div>
					<div id="idcard-detail" class="multi-field-row col-xs-12 col-md-6">
						<div class="row">
							<div class="col-xs-3 col-md-3 no-padding">
								<div class="block image-upload-container">
									<img class="idcard-image button" />
								</div>
							</div>
							<div class="col-xs-9 col-md-9 no-padding">
								<div class="row">
									<div class="col-xs-3 strong idcard-type"></div>
									<div class="col-xs-9 idcard-no"></div>
									<div class="col-xs-3 strong">Issue:</div>
									<div class="col-xs-9 idcard-issue"></div>
									<div class="col-xs-3 strong">Expiry:</div>
									<div class="col-xs-9 idcard-expiry"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-6">
			<div class="block">
			</div>
		</div>
	</div>
</div>
`;

export const briefTileHTML = `
<div id="idcard-brief-record" class="tile id-card brief">
	<div class="row">
		<div class="col-xs-2 no-r-padding">
			<img class="self-image" />           						
		</div>
		<div class="col-xs-10">
			<div class="row self-detail">
				<div class="title col-xs-2 col-md-3 no-padding align-right">

				</div>
				<div class="value col-xs-10 col-md-9">

				</div>
			</div>           						
		</div>
	</div>
	<div class="record-id">
		<span></span>
	</div>
</div>
`;

export const addFormHTML = `
<form id="idcard-add-record" class="tile id-card detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="row">
		<div class="col-xs-12 col-md-3">
			<div class="block image-upload-container">
				<img class="self-image button" />
				<input type="file" name="image" accept="image/*">
			</div>
		</div>
		<div class="col-xs-12 col-md-3 main-details">
			<div class="block">
				<div class="row self-detail">
					<div class="title col-xs-3 col-md-3">
						Name:
					</div>
					<div class="col-xs-2 col-md-2">
						<select name="title">
							<option value="Mahatma">Mh.</option>
							<option value="Bai">Bai</option>
							<option value="Mr">Mr.</option>
							<option value="Ms">Ms.</option>
						</select>
					</div>
					<div class="value col-xs-7 col-md-7">
						<input type="text"  name="name" value="" required placeholder="Full Name"/>
					</div>
				</div>
				<div class="row dob-detail">
					<div class="title col-xs-3 col-md-3">
						DoB:
					</div>
					<div class="value col-xs-9 col-md-9">
						<input type="text" class="date value" value="" name="dob" placeholder="Date of Birth"/>
					</div>
				</div>
				<div class="row age-detail">
					<div class="title col-xs-3 col-md-3">
						Age:
					</div>
					<div class="value col-xs-9 col-md-9">
						<input type="text" value="" name="age" placeholder="Age" />
					</div>
				</div>
				<div class="row address-detail">
					<div class="title col-xs-3 col-md-3">
						Address:
					</div>
					<div class="value col-xs-9 col-md-9">
						<input type="text"  name="address" value="" placeholder="Address"/>
					</div>
				</div>
				<div class="row contact-detail">
					<div class="title col-xs-12 col-md-12">
						Contacts:
					</div>
					<div id="contact-row" class="multi-field-row hidden col-xs-12">
						<div class="row">
							<div class="value col-xs-4 col-md-4">
								<select name="contact_type[]">
									<option value=""></option>
									<option value="Mobile">Mobile</option>
									<option value="Landline">Landline</option>
									<option value="Email">E-mail</option>
								</select>
							</div>
							<div class="value col-xs-7 col-md-7">
								<input type="text"  name="contact[]" value="" placeholder="Contact"/>
							</div>
							<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="contact-row"><i class="glyphicon glyphicon-plus"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-6 main-details">
			<div class="block">
				<div class="row idcard-data-detail">
					<div class="title col-xs-12 col-md-12">
						ID Cards:
					</div>
					<div id="idcard-row" class="multi-field-row hidden col-xs-12">
						<div class="row">
							<div class="col-xs-4 col-md-2 no-r-padding">
								<div class="block image-upload-container">
									<img class="idcard-image button" />
									<input type="file" name="ids_image" accept="image/*">
								</div>
							</div>
							<div class="col-xs-7 col-md-9">
								<div class="row">
									<div class="value col-xs-12">
										<select name="id_type[]">
											<option value=""></option>
											<option value="Aadhaar">Aadhaar</option>
											<option value="Pan">Pan</option>
											<option value="Passport">Passport</option>
											<option value="Voter">Voter ID</option>
											<option value="Driving">Driving License</option>
											<option value="Student">Student ID</option>
											<option value="Bank">Bank Passbook</option>
											<option value="Gov">Gov. Issued</option>
											<option value="Other">Other</option>
										</select>
									</div>
									<div class="value col-xs-12">
										<input type="text"  name="id_no[]" value="" placeholder="ID Card No."/>
									</div>
									<div class="value col-xs-12">
										<input type="text" class="date value" value="" name="id_issue_date[]" placeholder="Issue Date"/>
									</div>
									<div class="value col-xs-12">
										<input type="text" class="date value" value="" name="id_expiry_date[]" placeholder="Expiry Date"/>
									</div>
								</div>
							</div>
							<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="idcard-row"><i class="glyphicon glyphicon-plus"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
`;

export const detailFormHTML = `
<form id="idcard-detail-record-form" class="tile id-card detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="col-xs-12 col-md-3">
			<div class="block image-upload-container">
				<img class="self-image button" />
				<input type="file" name="image" accept="image/*">
			</div>
		</div>
		<div class="col-xs-12 col-md-3 main-details">
			<div class="block">
				<div class="row self-detail">
					<div class="title col-xs-3 col-md-3">
						Name:
					</div>
					<div class="col-xs-2 col-md-2">
						<select name="title">
							<option value="Mahatma">Mh.</option>
							<option value="Bai">Bai</option>
							<option value="Mr">Mr.</option>
							<option value="Ms">Ms.</option>
						</select>
					</div>
					<div class="value col-xs-7 col-md-7">
						<input type="text"  name="name" value="" required placeholder="Full Name"/>
					</div>
				</div>
				<div class="row dob-detail">
					<div class="title col-xs-3 col-md-3">
						DoB:
					</div>
					<div class="value col-xs-9 col-md-9">
						<input type="text" class="date value" value="" name="dob" placeholder="Date of Birth"/>
					</div>
				</div>
				<div class="row age-detail">
					<div class="title col-xs-3 col-md-3">
						Age:
					</div>
					<div class="value col-xs-9 col-md-9">
						<input type="text" value="" name="age" placeholder="Age" />
					</div>
				</div>
				<div class="row address-detail">
					<div class="title col-xs-3 col-md-3">
						Address:
					</div>
					<div class="value col-xs-9 col-md-9">
						<input type="text"  name="address" value="" placeholder="Address"/>
					</div>
				</div>
				<div class="row contact-detail">
					<div class="title col-xs-12 col-md-12">
						Contacts:
					</div>
					<div id="contact-row-edit" class="multi-field-row hidden col-xs-12">
						<div class="row">
							<div class="value col-xs-4 col-md-4">
								<select name="contact_type[]">
									<option value=""></option>
									<option value="Mobile">Mobile</option>
									<option value="Landline">Landline</option>
									<option value="Email">E-mail</option>
								</select>
							</div>
							<div class="value col-xs-7 col-md-7">
								<input type="text"  name="contact[]" value="" placeholder="Contact"/>
							</div>
							<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="contact-row-edit"><i class="glyphicon glyphicon-plus"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-6 main-details">
			<div class="block">
				<div class="row idcard-data-detail">
					<div class="title col-xs-12 col-md-12">
						ID Cards:
					</div>
					<div id="idcard-row-edit" class="multi-field-row hidden col-xs-12">
						<div class="row">
							<div class="col-xs-4 col-md-2 no-r-padding">
								<div class="block image-upload-container">
									<img class="idcard-image button" />
									<input type="file" name="ids_image" accept="image/*">
								</div>
							</div>
							<div class="col-xs-7 col-md-9">
								<div class="row">
									<div class="value col-xs-12">
										<select name="id_type[]">
											<option value=""></option>
											<option value="Aadhaar">Aadhaar</option>
											<option value="Pan">Pan</option>
											<option value="Passport">Passport</option>
											<option value="Voter">Voter ID</option>
											<option value="Driving">Driving License</option>
											<option value="Student">Student ID</option>
											<option value="Bank">Bank Passbook</option>
											<option value="Gov">Gov. Issued</option>
											<option value="Other">Other</option>
										</select>
									</div>
									<div class="value col-xs-12">
										<input type="text"  name="id_no[]" value="" placeholder="ID Card No."/>
									</div>
									<div class="value col-xs-12">
										<input type="text" class="date value" value="" name="id_issue_date[]" placeholder="Issue Date"/>
									</div>
									<div class="value col-xs-12">
										<input type="text" class="date value" value="" name="id_expiry_date[]" placeholder="Expiry Date"/>
									</div>
								</div>
							</div>
							<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="idcard-row-edit"><i class="glyphicon glyphicon-plus"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
`;

interface Props {
	record?: IDCardRecord;
	index?: number;
	listRef?: React.RefObject<any>;
}

class Component extends React.PureComponent<Props> {
	private tileRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	componentDidUpdate(prevProps, prevState) {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	render() {
		return (
			<div
				ref={this.tileRef}
				dangerouslySetInnerHTML={{ __html: isMobile() ? briefTileHTML : detailTileHTML }}
			></div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {};
}

export const ViewTile = connect(mapStateToProps)(Component);
