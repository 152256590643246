import { AppParams, AppUxState, LocaleType } from 'types';
import { combineReducers } from 'redux';
import { DefaultUserSettings } from 'config';
import {
	ChangeLocale,
	CHANGE_LOCALE,
	UpdateActiveNotifications,
	UpdateAppParams,
	UpdateBookletIndex,
	UpdateFilters,
	UpdateSortOrder,
	UpdateUserSettings,
	UPDATE_ACTIVE_NOTIFICATIONS,
	UPDATE_APP_PARAMS,
	UPDATE_BOOKLET_INDEX,
	UPDATE_FILTERS,
	UPDATE_SORT_ORDER,
	UPDATE_USER_SETTINGS,
	AckNotification,
	ACK_NOTIFICATION,
} from './actions';

function localeReducer(state: LocaleType = 'hi', action: ChangeLocale) {
	switch (action.type) {
		case CHANGE_LOCALE:
			return action.locale;
		default:
			return state;
	}
}

function bookletIndexReducer(state: { [id: string]: number } = {}, action: UpdateBookletIndex) {
	switch (action.type) {
		case UPDATE_BOOKLET_INDEX:
			return { ...state, [action.bookletId]: action.index };
		default:
			return state;
	}
}

function activeNotificationsReducer(state: number = 0, action: UpdateActiveNotifications) {
	switch (action.type) {
		case UPDATE_ACTIVE_NOTIFICATIONS:
			return action.count;
		default:
			return state;
	}
}

function userSettingsReducer(state: { [key: string]: any } = DefaultUserSettings, action: UpdateUserSettings) {
	switch (action.type) {
		case UPDATE_USER_SETTINGS:
			return action.settings ?? DefaultUserSettings;
		default:
			return state;
	}
}

function appParamsReducer(
	state: AppParams = { appId: '', appEnv: '', appVersion: -1, appBuildCode: -1, webappBuildVersion: 0 },
	action: UpdateAppParams
) {
	switch (action.type) {
		case UPDATE_APP_PARAMS:
			return action.appParams;
		default:
			return state;
	}
}

function sortOrderReducer(state = {}, action: UpdateSortOrder) {
	switch (action.type) {
		case UPDATE_SORT_ORDER:
			if (action.recordType === '..clearall') {
				return {};
			}
			return { ...state, [action.recordType]: action.sortValue };
		default:
			return state;
	}
}

function filtersReducer(state = {}, action: UpdateFilters) {
	switch (action.type) {
		case UPDATE_FILTERS:
			if (action.recordType === '..clearall') {
				return {};
			}
			return { ...state, [action.recordType]: action.filters };
		default:
			return state;
	}
}

function ackReducer(state = {}, action: AckNotification) {
	switch (action.type) {
		case ACK_NOTIFICATION:
			return { ...state, [action.id]: action.time };
		default:
			return state;
	}
}

export default combineReducers<AppUxState>({
	locale: localeReducer,
	bookletCurrentIndex: bookletIndexReducer,
	activeNotifications: activeNotificationsReducer,
	settings: userSettingsReducer,
	appParams: appParamsReducer,
	sort: sortOrderReducer,
	filters: filtersReducer,
	acknowledge: ackReducer,
});
