import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { CategoryRecord } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_CATEGORY = '@@mjbj/UPDATE_CATEGORY';
export type UPDATE_CATEGORY = typeof UPDATE_CATEGORY;

export interface UpdateCategory extends Action {
	type: UPDATE_CATEGORY;
	changes: FBChanges;
}

export function updateCategory(changes: FBChanges): UpdateCategory {
	return {
		type: UPDATE_CATEGORY,
		changes,
	};
}

export const UPDATE_CATEGORY_FILTERED = UPDATE_CATEGORY + '_FILTERED';
export type UPDATE_CATEGORY_FILTERED = typeof UPDATE_CATEGORY_FILTERED;

export interface UpdateCategoryFiltered extends Action {
	type: UPDATE_CATEGORY_FILTERED;
	payload: CategoryRecord[];
}
