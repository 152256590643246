import { Collapse } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Omit } from "@material-ui/types";
import { Icons } from "config/icons";
import { AppAdminRouteGroups, AppRouteGroups } from "config/route";
import { getWebAppAssetPath, isAdmin } from "helpers";
import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { closeDrawer } from "store/temp/actions";
import { ApplicationState, RouteGroup, RouteProps, UserData } from "types";

function ListItemLink(props: RouteProps) {
    const { icon, primary, to, state } = props;
    const [open, setOpen] = React.useState(false);

    const handleClick = (e) => {
        e.stopPropagation();

        setOpen(!open);
    };

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => <RouterLink to={{ pathname: to, state: state }} ref={ref} {...itemProps} />),
        [to, state]
    );

    if (state?.subRoutes) {
        return (
            <div>
                <li>
                    <ListItem button onClick={handleClick} data-nested={true}>
                        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                        <ListItemText primary={primary} />
                        {open ? Icons.ExpandLess : Icons.ExpandMore}
                    </ListItem>
                </li>
                <Collapse in={open} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding style={{ paddingLeft: 32 }}>
                        {state.subRoutes.map((route, index) => (
                            <ListItemLink
                                key={primary + "-route-link-key-" + index}
                                to={route.to}
                                primary={route.primary}
                                icon={route.icon || icon || Icons.Description}
                                state={route.state}
                            />
                        ))}
                    </List>
                </Collapse>
            </div>
        );
    }

    return (
        <li>
            <ListItem button target={state?.target} component={renderLink}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

const drawerWidth = 270;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        drawer: {
            [theme.breakpoints.up("sm")]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up("sm")]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
                display: "none",
            },
        },
        toolbar: theme.mixins.toolbar,
        banner: {
            width: `calc(100% + 1px)`,
        },
        ssdn: {
            fontSize: "1.4rem",
        },
        rhyme: {
            fontSize: "1rem",
            padding: "0.5rem 0",

            "& > .locale-hi": {
                fontSize: "1.7rem",
            },

            "& .rhyme-line.rhyme-line-justified": {
                marginBottom: "-0.3em",
            },
        },
        drawerPaper: {
            width: drawerWidth,
            overflowX: "hidden",
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    })
);

function ListRouter(props: { routeGroups: RouteGroup[]; userData: UserData }) {
    return (
        <React.Fragment>
            {props.routeGroups.map((routeGroup, index) => {
                return (
                    <React.Fragment key={"route-groupe-key-" + index}>
                        {index > 0 && <Divider />}
                        <List>
                            {routeGroup.routes
                                .filter((route) => {
                                    if (route.roles) {
                                        let userRoles = props.userData?.roles ?? [];
                                        let canEdit = userRoles.indexOf(route.roles.edit) >= 0;
                                        let canView = canEdit || userRoles.indexOf(route.roles.view) >= 0 || userRoles.indexOf(route.roles.lview) >= 0;

                                        return canView;
                                    }
                                    return true;
                                })
                                .map((route, index) => (
                                    <ListItemLink
                                        key={"route-link-key-" + index}
                                        to={route.to}
                                        primary={route.primary}
                                        icon={route.icon || Icons.Description}
                                        state={route.state}
                                    />
                                ))}
                        </List>
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
}

interface ResponsiveDrawerProps {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container?: Element;
    isDrawerOpen: boolean;
    userData?: UserData;
    onDrawerClose: () => void;
}

function ResponsiveDrawer(props: ResponsiveDrawerProps) {
    const { container, userData, isDrawerOpen, onDrawerClose } = props;
    const classes = useStyles();
    const theme = useTheme();
    // const [mobileOpen, setMobileOpen] = React.useState<Function>(props.isDrawerOpen);

    const onClose = (e) => {
        props.onDrawerClose();
    };

    const drawer = (
        <div role='presentation' onClick={onClose} onKeyDown={onClose}>
            <img className={classes.banner} src={getWebAppAssetPath("bg/js3.jpeg")} alt='' />

            <ListRouter userData={userData} routeGroups={isAdmin(userData) ? AppAdminRouteGroups : AppRouteGroups} />
        </div>
    );

    return (
        <div className={classes.root}>
            <nav className={classes.drawer}>
                <Drawer
                    container={container}
                    variant='temporary'
                    anchor={theme.direction === "rtl" ? "right" : "left"}
                    open={isDrawerOpen}
                    onClose={onDrawerClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </div>
    );
}

function mapStateToProps({ tempState, dataState }: ApplicationState) {
    let { isDrawerOpen } = tempState;

    return {
        isDrawerOpen: isDrawerOpen,
        userData: dataState.userStore.userData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onDrawerClose: () => {
            dispatch(closeDrawer());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawer);
