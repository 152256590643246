import { HeaderModule } from "components/header/anand.header";
import { Banner } from "components/website/Banner";
import { isOnlyMjBjSite } from "config";
import { isAdmin } from "helpers";
import { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "types";

const Component = (props) => {
    HeaderModule.initModule();

    useEffect(() => {
        if (window.location.pathname !== "/mjbj" && isOnlyMjBjSite()) {
            window.location.href = "/mjbj";
        }
    });

    return isOnlyMjBjSite() ? <></> : <Banner />;
};

function mapStateToProps(state: ApplicationState) {
    return {
        isAdmin: isAdmin(state.dataState.userStore.userData),
        userData: state.dataState.userStore.userData,
    };
}

export default connect(mapStateToProps)(Component);
