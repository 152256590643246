import { Zoom } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { grey } from '@material-ui/core/colors';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { Icons } from 'config/icons';
import { isMobile } from 'helpers';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			// '& .MuiPaper-root': {
			// 	backgroundColor: 'red',
			// },
		},
		appBar: {
			position: 'relative',
		},
		title: {
			marginLeft: theme.spacing(1),
			flex: 1,
			fontSize: '1.75rem',
		},
		content: {
			padding: 0,
			paddingBottom: theme.spacing(3),
		},
		drawerType: {
			height: 'auto',
			alignSelf: 'flex-end',
			maxHeight: '100%',

			'& .MuiDialogContent-root:first-child': {
				paddingTop: 0,
			},
		},
	})
);

const UpTransition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: ReactElement<any, any> },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const ZoomTransition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: ReactElement<any, any> },
	ref: React.Ref<unknown>
) {
	return <Zoom ref={ref} {...props} />;
});

const LeftTransition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: ReactElement<any, any> },
	ref: React.Ref<unknown>
) {
	return <Slide direction='left' ref={ref} {...props} />;
});

interface DialogPageProps {
	title: string;
	children?: React.ReactNode;
	transition?: boolean | string;
	showAppBar?: boolean;
	onClose?: () => boolean | void;
	goBackOnClose?: boolean;
	goBackTo?: string;
	extraAppBarElement?: React.ReactNode;
	actionButtons?: React.ReactNode;
	style?: any;
	appBarClassName?: string;
	drawerType?: any;
	scroll?: string;
	disableBackdropClick?: boolean;
	maxWidth?: DialogProps['maxWidth'];
	screenKey?: string;
	dark?: boolean;
	disableEnforceFocus?: boolean;
}

export const DialogContext = React.createContext({
	screens: [{ title: '', key: '' }] as { title: string; key: string; dark?: boolean }[],
	pushScreen: (screen: string) => {},
	popScreen: () => {},
});

const DialogActions = withStyles((theme) => ({
	root: {
		borderTop: '1px solid darkgrey',
		paddingBottom: '8px; padding-bottom: calc(8px + env(safe-area-inset-bottom)/2)',
	},
}))(MuiDialogActions);

const Content = (props: any) => {
	const classes = useStyles();
	const [screens, setScreens] = React.useState([
		{ key: props.screenKey ?? 'main', title: props.title, dark: props.dark },
	]);

	const popScreen = () => {
		if (screens.length > 1) {
			setScreens(screens.slice(0, screens.length - 1));
			return;
		}

		if (props.onClose && props.onClose() === false) {
			return;
		}

		props.handleClose();
	};

	const pushScreen = (screen) => {
		setScreens([...screens, screen]);
	};

	return (
		<React.Fragment>
			{props.showAppBar === false ? null : (
				<AppBar className={clsx(classes.appBar, props.appBarClassName)}>
					<Toolbar>
						<IconButton edge='start' color='inherit' onClick={popScreen}>
							{screens.length === 1 && props.transition !== 'left' ? Icons.Close : Icons.ArrowBack}
						</IconButton>
						{screens[screens.length - 1].title ? (
							<Typography variant='h6' className={classes.title} noWrap>
								{screens[screens.length - 1].title}
							</Typography>
						) : null}
						{props.extraAppBarElement}
					</Toolbar>
				</AppBar>
			)}

			<DialogContent
				className={classes.content}
				style={{
					...props.style,
					backgroundColor: screens[screens.length - 1].dark ?? props.dark ? grey[200] : 'white',
				}}
			>
				<DialogContext.Provider value={{ screens, pushScreen, popScreen }}>
					{props.children}
				</DialogContext.Provider>
			</DialogContent>
			{props.actionButtons ? <DialogActions>{props.actionButtons}</DialogActions> : null}
		</React.Fragment>
	);
};

export default function DialogPage(props: DialogPageProps) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);
	const theme = useTheme();
	let fullScreen = useMediaQuery(`${theme.breakpoints.down('xs')}`);
	fullScreen = useMediaQuery(`${theme.breakpoints.down('sm')} and (orientation: landscape)`) || fullScreen;
	let history = useHistory();

	const handleClose = async () => {
		setOpen(false);

		if (props.goBackOnClose !== false) {
			// await timeout(100);

			if (history.length > 2) {
				history.goBack();
			} else {
				history.push(props.goBackTo ?? '/');
			}
		} else if (props.goBackTo) {
			history.push(props.goBackTo);
		}
	};

	return (
		<div>
			<Dialog
				className={classes.root}
				fullScreen={fullScreen}
				fullWidth={true}
				maxWidth={props.maxWidth || 'sm'}
				disableBackdropClick={props.disableBackdropClick}
				open={open}
				onClose={handleClose}
				TransitionComponent={
					props.transition ? (props.transition === 'left' ? LeftTransition : UpTransition) : ZoomTransition
				}
				classes={props.drawerType ? { paper: classes.drawerType } : {}}
				scroll={(props.scroll ?? (isMobile() ? 'paper' : 'body')) as 'body' | 'paper'}
				disableEnforceFocus={props.disableEnforceFocus ?? false}
			>
				<Content {...props} handleClose={handleClose} />
			</Dialog>
		</div>
	);
}
