import { FireStoreModule } from 'fb';
import firebase from 'fb/index';
import 'firebase/firestore';
import { updateKutiya } from 'store/data/kutiya/actions';

export class KutiyaFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'kutiya';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: firebase.firestore.Firestore) => db.collection('kutiya');
	public getChangeProcessAction = (changes) => updateKutiya(changes);
}
