import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import { Card } from '@material-ui/core';
import { changePassword } from 'services/api/auth';
import { connect } from 'react-redux';
import { DialogContext } from 'components/pages/DialogPage';
import { Field, Form, Formik } from 'formik';
import { Icons } from 'config/icons';
import { TextField } from 'formik-material-ui';
import { Theme, withStyles } from '@material-ui/core/styles';
import { UserPicInfo } from './UserPicInfo';
import { isWebEnv, isWebViewEnv } from 'helpers';

const styles = (theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	},
	card: {
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
	},
	form: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	},
	signinbtn: {
		height: 42,
		width: '80%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	},
});

export interface Props {
	changePassword: (current: string, password: string) => Promise<void>;
	classes: any;
}

class ChangePassword extends React.Component<Props, any> {
	constructor(props) {
		super(props);

		this.state = {
			showPassword: false,
		};
	}

	render() {
		let { classes, changePassword } = this.props;
		let { showPassword } = this.state;

		const handleClickShowPassword = () => {
			this.setState({ showPassword: !showPassword });
		};

		const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();
		};

		return (
			<div className={classes.root}>
				<UserPicInfo />

				<Card className={classes.card}>
					<Formik
						initialValues={{
							current: '',
							password: '',
							confirm: '',
						}}
						validationSchema={() =>
							Yup.object({
								current: Yup.string().required('Required'),
								password: Yup.string().required('Required').min(6),
								confirm: Yup.string()
									.required('Required')
									.oneOf([Yup.ref('password')], 'Passwords do not match'),
							})
						}
						onSubmit={async (values, { setSubmitting }) => {
							await changePassword(values.current, values.password);
							this.context.popScreen();
							return;
						}}>
						{({ submitForm, isSubmitting }) => (
							<Form className={classes.form} translate='yes'>
								<Field
									component={TextField}
									type={showPassword ? 'text' : 'password'}
									label='Current Password'
									name='current'
									autoComplete="off"
									autoCapitalize="off"
									autoCorrect="off"
									spellCheck="false"
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													tabIndex={-1}>
													{showPassword ? Icons.Visibility : Icons.VisibilityOff}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<Field
									component={TextField}
									type={showPassword ? 'text' : 'password'}
									label='New Password'
									name='password'
									autoComplete="off"
									autoCapitalize="off"
									autoCorrect="off"
									spellCheck="false"
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													tabIndex={-1}>
													{showPassword ? Icons.Visibility : Icons.VisibilityOff}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<Field
									component={TextField}
									type={showPassword ? 'text' : 'password'}
									label='Confirm Password'
									name='confirm'
									autoComplete="off"
									autoCapitalize="off"
									autoCorrect="off"
									spellCheck="false"
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													tabIndex={-1}>
													{showPassword ? Icons.Visibility : Icons.VisibilityOff}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<Button
									className={classes.signinbtn}
									variant='contained'
									color='primary'
									type='submit'
									onClick={submitForm}
									disabled={isSubmitting}>
									Change Password
								</Button>
							</Form>
						)}
					</Formik>
				</Card>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		changePassword: async (current: string, password: string) => {
			await dispatch(changePassword({ current, password }));
		},
	};
}

ChangePassword.contextType = DialogContext;

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(ChangePassword));
