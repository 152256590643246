import { HeaderControls, HeaderModule } from 'components/header/anand.header';
import { Records } from 'core/anand.records';
import { addFormHTML, briefTileHTML, detailFormHTML, detailTileHTML } from './viewTile';

export const RecordsHandler = (function ($) {
	var configMap = {};

	var stateMap: any = {
		systemId: 'pincode',
	};

	function initModule(props?, updateRecordsState?) {
		HeaderModule.initModule([
			HeaderControls.addRecordBtn,
			HeaderControls.selectDropdown,
			HeaderControls.printBtn,
			HeaderControls.sortBtn,
		]);

		Records.projectTitle = 'Amar Satya Sandesh Pincodes';

		Records.getAnySearchFilter = getAnySearchFilter;
		Records.defaultSortOrder = 'city';
		Records.IdentifyingFieldName = 'pincode';

		Records.sortingFieldsMap = {
			tehsil: 'Tehsil',
			district: 'District',
			city: 'City',
			state: 'State',
			country: 'Country',
			pincode: 'Pincode',
		};

		Records.searchFieldsMap = {
			tehsil: 'Tehsil',
			district: 'District',
			city: 'City',
			state: 'State',
			country: 'Country',
			pincode: 'Pincode',
		};

		Records.isSearchDateTypeColumn = function (searchBy) {
			return false;
		};

		Records.printOptions = {
			fieldNames: ['S. No.', 'Tehsil', 'District', 'City', 'State', 'Country', 'Pincode'],
			fieldKeys: ['id', 'tehsil', 'district', 'city', 'state', 'country', 'pincode'],
			defaultFieldWidths: {
				id: 60,
				tehsil: 150,
				district: 150,
				city: 150,
				state: 100,
				country: 100,
				pincode: 100,
			},
			defaultHeader: 'Amar Satya Sandesh Pincode Records',
			beforePrintHook: function (data, forExport) {
				for (var i = 0; i < data.length; i++) {
					data[i]['id'] = i + 1 + '.';
				}

				return data;
			},
		};

		Records.exportOptions = {
			fieldKeys: ['id', 'tehsil', 'district', 'city', 'state', 'country', 'pincode'],
			defaultFileName: 'Amar Satya Sandesh Pincode Records',
		};

		Records.hooks.afterHeaderConfigure = function (controlsHeader) {
			controlsHeader.find('.reminder-btn').hide();
			controlsHeader.find('.dashboard-btn').hide();
		};

		Records.detailTileSelector = '#amar-pin-detail-record';
		Records.briefTileSelector = '#amar-pin-brief-record';
		Records.addTileSelector = '#amar-pin-add-record';
		Records.detailFormSelector = '#amar-pin-detail-record-form';
		Records.imageUploadSelector = null;

		Records.detailTileHTML = detailTileHTML;
		Records.briefTileHTML = briefTileHTML;
		Records.detailFormHTML = detailFormHTML;
		Records.addFormHTML = addFormHTML;

		Records.populateBriefTile = populateBriefTile;
		Records.populateDetailTile = populateDetailTile;
		Records.populateDetailForm = populateDetailForm;

		Records.updateRecordsState = updateRecordsState;

		Records.initModule({ systemId: stateMap.systemId, prepareRecord: prepareRecord, props: props });
	}

	function prepareRecord(record) {
		return record;
	}

	function getAnySearchFilter(query) {
		return [
			{ tehsil: { likenocase: query } },
			{ district: { likenocase: query } },
			{ city: { likenocase: query } },
			{ state: { likenocase: query } },
			{ country: { likenocase: query } },
			{ pincode: { leftnocase: query } },
		];
	}

	async function populateBriefTile(sampleTile, record) {
		sampleTile.find('.tehsil-detail .value').html(record['tehsil']);
		sampleTile.find('.district-detail .value').html(record['district']);
		sampleTile.find('.city-detail .value').html(record['city']);
		sampleTile.find('.state-detail .value').html(record['state']);
		sampleTile.find('.country-detail .value').html(record['country']);
		sampleTile.find('.pincode-detail .value').html(record['pincode']);

		return sampleTile;
	}

	async function populateDetailTile(sampleTile, recordOrig) {
		var record = {};
		for (var key in recordOrig) {
			if (recordOrig[key] == null || recordOrig[key] == '') {
				record[key] = '&nbsp';
			} else {
				record[key] = recordOrig[key];
			}
		}

		sampleTile.find('.tehsil-detail .value').html(record['tehsil']);
		sampleTile.find('.district-detail .value').html(record['district']);
		sampleTile.find('.city-detail .value').html(record['city']);
		sampleTile.find('.state-detail .value').html(record['state']);
		sampleTile.find('.country-detail .value').html(record['country']);
		sampleTile.find('.pincode-detail .value').html(record['pincode']);

		return sampleTile;
	}

	function populateDetailForm(sampleTile, record) {
		sampleTile.find('.tehsil-detail .value input').val(record['tehsil']);
		sampleTile.find('.district-detail .value input').val(record['district']);
		sampleTile.find('.city-detail .value input').val(record['city']);
		sampleTile.find('.state-detail .value input').val(record['state']);
		sampleTile.find('.country-detail .value input').val(record['country']);
		sampleTile.find('.pincode-detail .value input').val(record['pincode']);

		return sampleTile;
	}

	return {
		initModule: initModule,
		setConfig: function (key, value) {
			configMap[key] = value;
		},
	};
})(jQuery);
