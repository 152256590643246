import { HeaderControls, HeaderModule } from 'components/header/anand.header';
import { Records } from 'core/anand.records';
import { utils as Utils } from 'core/anand.utils';
import { addFormHTML, briefTileHTML, detailFormHTML, detailTileHTML } from './viewTile';

export const RecordsHandler = (function ($) {
	var configMap = {};

	var stateMap: any = {
		systemId: 'moment',
	};

	function initModule(props?, updateRecordsState?) {
		HeaderModule.initModule([
			HeaderControls.addRecordBtn,
			HeaderControls.selectDropdown,
			HeaderControls.printBtn,
			HeaderControls.sortBtn,
		]);

		Records.projectTitle = 'Holy Moments';

		Records.getAnySearchFilter = getAnySearchFilter;
		Records.defaultSortOrder = 'moment-date';
		Records.IdentifyingFieldName = 'id';
		Records.imageFieldName = 'image';

		Records.sortingFieldsMap = {
			moment_date: 'Date',
		};

		Records.searchFieldsMap = {
			moment_date: 'Date',
			moment_desc: 'English Desc',
			moment_desc_hindi: 'Hindi Desc',
		};

		Records.isSearchDateTypeColumn = function (searchBy) {
			if (searchBy === 'moment_date') {
				return true;
			} else {
				return false;
			}
		};

		Records.printOptions = {
			fieldNames: ['S. No.', 'Date', 'Description-En', 'Description-Hi'],
			fieldKeys: ['id', 'moment_date', 'moment_desc', 'moment_desc_hindi'],
			defaultFieldWidths: { id: 40, moment_date: 100, moment_desc: 500, moment_desc_hindi: 400 },
			defaultHeader: 'Holy Moments',
			beforePrintHook: function (data) {
				function getDMY(date) {
					var parts = date.split('-');
					return parts[2] + '-' + parts[1] + '-' + parts[0];
				}

				for (var i = 0; i < data.length; i++) {
					data[i]['id'] = i + 1 + '.';
					if (data[i]['moment_date'] !== null) {
						data[i]['moment_date'] = getDMY(data[i]['moment_date']);
					}
				}

				return data;
			},
		};

		Records.exportOptions = {
			fieldKeys: ['moment_date', 'moment_desc', 'moment_desc_hindi'],
			defaultFileName: 'Holy Moments',
		};

		Records.dashboardOptions = null;
		Records.hooks.afterHeaderConfigure = function (controlsHeader) {
			controlsHeader.find('.dashboard-btn').hide();
		};

		Records.detailTileSelector = '#holy-moments-detail-record';
		Records.briefTileSelector = '#holy-moments-brief-record';
		Records.addTileSelector = '#holy-moments-add-form';
		Records.detailFormSelector = '#holy-moments-edit-form';
		Records.imageUploadSelector = "input[name='image']";

		Records.detailTileHTML = detailTileHTML;
		Records.briefTileHTML = briefTileHTML;
		Records.detailFormHTML = detailFormHTML;
		Records.addFormHTML = addFormHTML;

		Records.populateBriefTile = populateBriefTile;
		Records.populateDetailTile = populateDetailTile;
		Records.populateDetailForm = populateDetailForm;

		Records.updateRecordsState = updateRecordsState;

		Records.initModule({ systemId: stateMap.systemId, prepareRecord: prepareRecord, props: props });
	}

	function prepareRecord(record) {
		record['moment_date'] = Utils.formatDate(record['moment_date']);

		return record;
	}

	function getAnySearchFilter(query) {
		return [
			{ moment_date: { likenocase: query } },
			{ moment_desc: { likenocase: query } },
			{ moment_desc_hindi: { likenocase: query } },
		];
	}

	async function populateBriefTile(sampleTile, record) {
		return populateDetailTile(sampleTile, record);
	}

	async function populateDetailTile(sampleTile, recordOrig) {
		var record = {};
		for (var key in recordOrig) {
			if (recordOrig[key] == null || recordOrig[key] == '') {
				record[key] = '&nbsp';
			} else {
				record[key] = recordOrig[key];
			}
		}

		if (record['image']) {
			Records.setImageSrc(sampleTile.find('.moment-image'), record['image'], 'moment/images/');
		} else {
			sampleTile.find('.moment-image').hide();
		}

		var dateAr = record['moment_date'].split('-');
		if (dateAr.length === 3) {
			var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
			dateStr = Utils.getDateStr(dateAr[0], dateAr[1], dateAr[2]);
			sampleTile.find('.moment-date').html(dateStr);
		} else {
			sampleTile.find('.moment-date').html(record['moment_date']);
		}

		sampleTile.find('.moment-desc').html(record['moment_desc']);
		sampleTile.find('.moment-desc.hindi').html(record['moment_desc_hindi']);

		return sampleTile;
	}

	function populateDetailForm(sampleTile, record) {
		Records.setImageSrc(sampleTile.find('.moment-image'), record['image'], 'moment/images/');

		var dateAr = record['moment_date'].split('-');
		if (dateAr.length === 3) {
			var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
			sampleTile.find('.moment-date').attr('value', dateStr);
			sampleTile.find('.moment-date').datepicker('update', dateStr);
		} else {
			sampleTile.find('.moment-date').attr('value', record['moment_date']);
			sampleTile.find('.moment-date').datepicker('update', record['moment_date']);
		}

		sampleTile.find('.moment-desc').html(record['moment_desc']);
		sampleTile.find('.moment-desc.hindi').html(record['moment_desc_hindi']);

		return sampleTile;
	}

	return {
		initModule: initModule,
		setConfig: function (key, value) {
			configMap[key] = value;
		},
	};
})(jQuery);
