import { isMobile } from 'helpers';
import { populateViewTileHelper } from 'helpers/RecordsHelper';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, SubscriberRecord } from 'types';

export const detailTileHTML = `
<div id="amar-detail-record" class="tile amar-record detail">
	<div class="record-id">
		<span></span>
	</div>
	<div class='row'>
		<div class='heading'>Subscriber Information</div>
	</div>
	<div class="row subscriber-info">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row self-detail">
					<div class="title col-xs-3">
						Name:
					</div>
					<div class="value col-xs-9">
					</div>
				</div>
				<div class="row address-detail">
					<div class="title col-xs-3">
						Address:
					</div>
					<div class="value col-xs-9">
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-5 main-details">
			<div class="block">
				<div class="row city-detail">
					<div class="title col-xs-2">
						City:
					</div>
					<div class="value city-value col-xs-4">
					</div>
					<div class="title col-xs-2">
						State:
					</div>
					<div class="value state-value col-xs-4">
					</div>
				</div>
				<div class="row city-detail">
					<div class="title col-xs-2">
						Tehsil:
					</div>
					<div class="value tehsil-value col-xs-4">
					</div>
					<div class="title col-xs-2">
						Country:
					</div>
					<div class="value country-value col-xs-4">
					</div>
				</div>
				<div class="row city-detail">
					<div class="title col-xs-2">
						District:
					</div>
					<div class="value district-value col-xs-4">
					</div>
					<div class="title col-xs-2">
						Pincode:
					</div>
					<div class="value pincode-value col-xs-4">
					</div>
				</div>
				<div class="row comments-detail">
					<div class="title col-xs-3 col-md-2">
						Comments:
					</div>
					<div class="value col-xs-9 col-md-10">
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-3 main-details">
			<div class="block">
				<div class="row email-detail">
					<div class="title col-xs-3">
						Email:
					</div>
					<div class="col-xs-9 value email-value">
					</div>
				</div>
				<div class="row phone-detail">
					<div class="title col-xs-3">
						Contact:
					</div>
					<div class="col-xs-9 value phone-value">
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class='row'>
		<div class='heading'>Subscription Details</div>
	</div>
	<div class="row subscription-detail">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row reader-id">
					<div class="title col-xs-3">
						Reader ID:
					</div>
					<div class="value col-xs-9">
					</div>
				</div>
				<div class="row through-detail">
					<div class="title col-xs-3">
						Through:
					</div>
					<div class="col-xs-9 through-value value">
					</div>
				</div>                    
				<div class="row category-detail">
					<div class="title col-xs-3">
						Duration:
					</div>
					<div class="value category-value col-xs-9">
					</div>
				</div>                    
				<div class="row language-detail">
					<div class="title col-xs-3">
						Language:
					</div>
					<div class="value language-value col-xs-9">
					</div>
				</div>
				<div class="row receipt-detail">
					<div class="title col-xs-3">
						Receipt No:
					</div>
					<div class="value receipt-value col-xs-9">
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row entry-date-detail">
					<div class="title col-xs-2">
						Date:
					</div>
					<div class="entry-date-value value col-xs-10">
					</div>
				</div>
				<div class="row date-detail">
					<div class="title col-xs-2">
						From:
					</div>
					<div class="value starting-value col-xs-4">
					</div>
					<div class="title col-xs-2">
						To:
					</div>
					<div class="value ending-value col-xs-4">
					</div>
				</div>
				<div class="row copies-detail">
					<div class="title col-xs-2">
						Readers:
					</div>
					<div class="value copies-value col-xs-4">
					</div>
					<div class="title col-xs-2">
						Issues:
					</div>
					<div class="value total-issues-value col-xs-4">
					</div>
				</div>
				<div class="row issues-detail hidden">
					<div class="title col-xs-2">
						Issues:
					</div>
					<div class="value issues-value col-xs-4">
					</div>
					<div class="title col-xs-2">
						Remaining:
					</div>
					<div class="value bal-issues-value col-xs-4">
					</div>
				</div>
				<div class="row amount-detail">
					<div class="title col-xs-2 hidden">
						Amount ₹:
					</div>
					<div class="value amount-value col-xs-4 hidden">
					</div>
					<div class="title col-xs-2 hidden">
						Balance ₹:
					</div>
					<div class="value balance-value col-xs-4 hidden">
					</div>
					<div class="title col-xs-2">
						Rate ₹:
					</div>
					<div class="value rate-value col-xs-4">
					</div>
					<div class="title col-xs-2">
						Total ₹:
					</div>
					<div class="value total-value col-xs-4">
					</div>
				</div>
				<div class="row byhand-detail">
					<div class="title col-xs-2">
						By Hand:
					</div>
					<div class="value byhand-value col-xs-10">
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block markings-div">
				<table class='markings-table'>
					<thead>
						<tr>
							<td colspan="13">Markings</td>
						</tr>
					</thead>
					<tr>
						<th></th>
						<th>Jan</th>
						<th>Mar</th>
						<th>May</th>
						<th>Jul</th>
						<th>Sep</th>
						<th>Nov</th>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="bulk-users-section-detail">
		<div class='row'>
			<div class='heading'>User Names</div>
		</div>
		<div class="row user-info">
			<div class="col-xs-12 col-md-12 main-details">
				<div class="block">
					<div class="row user-detail">
						<div id="user-data-detail" class="multi-field-row hidden col-xs-12 col-md-3">
							<div class="row">
								<div class="title col-xs-2">
									Name: 
								</div>
								<div class="value col-xs-10">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
`;

export const briefTileHTML = `
<div id="amar-brief-record" class="tile amar-record brief">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row subscriber-info">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row self-detail">
					<div class="title col-xs-3">
						Name:
					</div>
					<div class="value col-xs-9">
					</div>
				</div>
				<div class="row subscription-detail">
					<div class="title col-xs-3">
						Reader ID:
					</div>
					<div class="value col-xs-9">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
`;

export const detailFormHTML = `
<form id="amar-detail-record-form" class="tile amar-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class="record-id">
		<span></span>
	</div>
	<div class='row'>
		<div class='heading'>Subscriber Information</div>
	</div>
	<div class="row subscriber-info">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row self-detail">
					<div class="title col-xs-3">
						Name:
					</div>
					<div class="value col-xs-9">
						<input type="text"  name="name" value="" required placeholder="Name"/>
					</div>
				</div>
				<div class="row address-detail">
					<div class="title col-xs-3">
						Address:
					</div>
					<div class="value col-xs-9">
						<textarea class="address" name="address" required rows="2" placeholder="Address*"></textarea>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-5 main-details">
			<div class="block">
				<div class="row city-detail">
					<div class="title col-xs-2">
						City:
					</div>
					<div class="value city-value col-xs-4">
						<input type="text" value="" required name="city" placeholder="City/Village/Town"/>
					</div>
					<div class="title col-xs-2">
						State:
					</div>
					<div class="value state-value col-xs-4">
						<input type="text" value="" name="state" required placeholder="State*" />
					</div>
				</div>
				<div class="row city-detail">
					<div class="title col-xs-2">
						Tehsil:
					</div>
					<div class="value tehsil-value col-xs-4">
						<input type="text" value="" name="tehsil" placeholder="Tehsil"/>
					</div>
					<div class="title col-xs-2">
						Country:
					</div>
					<div class="value country-value col-xs-4">
						<input type="text" value="" name="country" required placeholder="Country*" />
					</div>
				</div>
				<div class="row city-detail">
					<div class="title col-xs-2">
						District:
					</div>
					<div class="value district-value col-xs-4">
						<input type="text" value="" name="district" placeholder="District"/>
					</div>
					<div class="title col-xs-2">
						Pincode:
					</div>
					<div class="value pincode-value col-xs-4">
						<input type="text" value="" name="pincode" placeholder="Pincode" />
					</div>
				</div>
				<div class="row comments-detail">
					<div class="title col-xs-3 col-md-2">
						Comments:
					</div>
					<div class="value col-xs-9 col-md-10">
						<input type="text"  name="comments" value="" placeholder="Comments"/>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-3 main-details">
			<div class="block">
				<div class="row email-detail">
					<div class="title col-xs-3">
						Email:
					</div>
					<div class="col-xs-9 email-value">
						<input type="email" email name="email" value="" placeholder="Email"/>
					</div>
				</div>
				<div class="row phone-detail">
					<div class="title col-xs-3">
						Contact:
					</div>
					<div class="col-xs-9 phone-value">
						<input type="tel" name="phone_ignore" value=""/>
						<input type="hidden" name="phone" value=""/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class='row'>
		<div class='heading'>Subscription Details</div>
	</div>
	<div class="row subscription-detail">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row reader-id">
					<div class="title col-xs-3">
						Reader ID:
					</div>
					<div class="value col-xs-3">
						<input disabled type="text"  name="reader_id" value="" placeholder="No."/>
					</div>
					<div class="value col-xs-3">
						<div class="checkbox">
							<label>
								<input name="is_free_ignore" type="checkbox"> FREE
								<input name="is_free" type="hidden">
							</label>
						</div>
					</div>
					<div class="status-value value col-xs-3">
						<select name="subscription_status">
							<option value="active">Active</option>
							<option value="paused">Paused</option>
						</select>
					</div>
				</div>
				<div class="row through-detail">
					<div class="title col-xs-3">
						Through:
					</div>
					<div class="col-xs-9 through-value value">
						<input type="text"  name="through" value="" required placeholder="Through"/>
						<input type="hidden"  name="through_id" value="" required placeholder="Through"/>
					</div>
				</div>                    
				<div class="row category-detail">
					<div class="title col-xs-3">
						Duration:
					</div>
					<div class="value category-value col-xs-9">
						<select name="category_id">
						</select>
					</div>
				</div>                    
				<div class="row language-detail">
					<div class="title col-xs-3">
						Language:
					</div>
					<div class="value language-value col-xs-9">
						<select name="language">
							<option value="english">English</option>
							<option value="hindi">Hindi</option>
						</select>
					</div>
				</div>
				<div class="row receipt-detail">
					<div class="title col-xs-3">
						Receipt No:
					</div>
					<div class="receipt-value value col-xs-9">
						<input disabled type="text"  name="receipt_no" value="" placeholder="Receipt No."/>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row date-detail">
					<div class="title col-xs-2">
						Date:
					</div>
					<div class="date-value value col-xs-10">
						<input disabled type="text" class="date" value="" name="entry_date" placeholder="Entry Date" />
					</div>
				</div>
				<div class="row date-detail">
					<div class="title col-xs-2">
						From:
					</div>
					<div class="value starting-month-value col-xs-2">
						<select name="start_month">
						</select>
					</div>
					<div class="value starting-year-value col-xs-2">
						<input type="number" value="" name="start_year" placeholder="Year" />
					</div>
					<div class="title col-xs-2">
						To:
					</div>
					<div class="value ending-month-value col-xs-2">
						<select name="end_month">
						</select>
					</div>
					<div class="value ending-year-value col-xs-2">
						<input type="number" value="" name="end_year" placeholder="Ending Year" />
					</div>
				</div>
				<div class="row copies-detail">
					<div class="title col-xs-2">
						Readers:
					</div>
					<div class="value copies-value col-xs-4">
						<input type="number" value="" name="copies" placeholder="No of Copies" />
						<input type="hidden" value="" name="renew_pending" />
					</div>
					<div class="title col-xs-2">
						Issues:
					</div>
					<div class="value total-issues-value col-xs-4">
						<input disabled type="number" value="" name="total_issues" placeholder="No of Issues" />
					</div>
				</div>
				<div class="row issues-detail hidden">
					<div class="title col-xs-2">
						Issues:
					</div>
					<div class="value issues-value col-xs-4">
						<input disabled type="number" value="" name="issues" placeholder="No of Issues" />
					</div>
					<div class="title col-xs-2">
						Remaining:
					</div>
					<div class="value bal-issues-value col-xs-4">
						<input disabled type="number" value="" name="balance_issues" placeholder="Remaining Issues" />
					</div>
				</div>
				<div class="row amount-detail">
					<div class="title col-xs-2 hidden">
						Amount ₹:
					</div>
					<div class="value amount-value col-xs-4 hidden">
						<input disabled type="number" value="" name="amount" placeholder="Amount" />
					</div>
					<div class="title col-xs-2 hidden">
						Balance ₹:
					</div>
					<div class="value balance-value col-xs-4 hidden">
						<input disabled type="number" value="" name="balance" placeholder="Balance" />
					</div>
					<div class="title col-xs-2">
						Rate ₹:
					</div>
					<div class="value rate-value col-xs-4">
						<input disabled type="number" value="" name="rate" placeholder="Rate" />
					</div>
					<div class="title col-xs-2">
						Total ₹:
					</div>
					<div class="value total-value col-xs-4">
						<input disabled type="number" value="" name="total_amount" placeholder="Total" />
					</div>
				</div>
				<div class="row byhand-detail">
					<div class="title col-xs-2">
						By Hand:
					</div>
					<div class="value byhand-value col-xs-10">
						<input disabled type="text" value="" name="by_hand" placeholder="By Hand" />
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block markings-div">
				<table class='markings-table'>
					<thead>
						<tr>
							<td colspan="13">Markings</td>
						</tr>
					</thead>
					<tr>
						<th></th>
						<th>Jan</th>
						<th>Mar</th>
						<th>May</th>
						<th>Jul</th>
						<th>Sep</th>
						<th>Nov</th>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="bulk-users-section">
		<div class='row'>
			<div class='heading'>User Names</div>
		</div>
		<div class="row user-info">
			<div class="col-xs-12 col-md-12 main-details">
				<div class="block">
					<div class="row user-detail">
						<div id="user-row-edit" class="multi-field-row hidden col-xs-12 col-md-3">
							<div class="row">
								<div class="value col-xs-11">
									<input type="text"  name="user_name[]" value="" placeholder="User Name"/>
								</div>
								<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="user-row-edit"><i class="glyphicon glyphicon-plus"></i></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
`;

export const addFormHTML = `
<form id="amar-add-record" class="tile amar-record detail" method="post" action="javascript:void(0);" enctype="multipart/form-data">
	<div class='row'>
		<div class='heading'>Subscriber Information</div>
	</div>
	<div class="row subscriber-info">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row self-detail">
					<div class="title col-xs-3">
						Name:
					</div>
					<div class="value col-xs-9">
						<input type="text"  name="name" value="" required placeholder="Name"/>
					</div>
				</div>
				<div class="row address-detail">
					<div class="title col-xs-3">
						Address:
					</div>
					<div class="value col-xs-9">
						<textarea class="address" name="address" required rows="2" placeholder="Address*"></textarea>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-5 main-details">
			<div class="block">
				<div class="row city-detail">
					<div class="title col-xs-2">
						City:
					</div>
					<div class="value city-value col-xs-4">
						<input type="text" value="" required name="city" placeholder="City/Village/Town"/>
					</div>
					<div class="title col-xs-2">
						State:
					</div>
					<div class="value state-value col-xs-4">
						<input type="text" value="" name="state" required placeholder="State*" />
					</div>
				</div>
				<div class="row city-detail">
					<div class="title col-xs-2">
						Tehsil:
					</div>
					<div class="value tehsil-value col-xs-4">
						<input type="text" value="" name="tehsil" placeholder="Tehsil"/>
					</div>
					<div class="title col-xs-2">
						Country:
					</div>
					<div class="value country-value col-xs-4">
						<input type="text" value="" name="country" required placeholder="Country*" />
					</div>
				</div>
				<div class="row city-detail">
					<div class="title col-xs-2">
						District:
					</div>
					<div class="value district-value col-xs-4">
						<input type="text" value="" name="district" placeholder="District"/>
					</div>
					<div class="title col-xs-2">
						Pincode:
					</div>
					<div class="value pincode-value col-xs-4">
						<input type="text" value="" name="pincode" placeholder="Pincode" />
					</div>
				</div>
				<div class="row comments-detail">
					<div class="title col-xs-3 col-md-2">
						Comments:
					</div>
					<div class="value col-xs-9 col-md-10">
						<input type="text"  name="comments" value="" placeholder="Comments"/>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-3 main-details">
			<div class="block">
				<div class="row email-detail">
					<div class="title col-xs-3">
						Email:
					</div>
					<div class="col-xs-9 email-value">
						<input type="email" email name="email" value="" placeholder="Email"/>
					</div>
				</div>
				<div class="row phone-detail">
					<div class="title col-xs-3">
						Contact:
					</div>
					<div class="col-xs-9 phone-value">
						<input type="tel" name="phone_ignore" value=""/>
						<input type="hidden" name="phone" value=""/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class='row'>
		<div class='heading'>Subscription Details</div>
	</div>
	<div class="row subscription-detail">
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row reader-id">
					<div class="title col-xs-3">
						Reader ID:
					</div>
					<div class="value col-xs-3">
						<input disabled type="text"  name="reader_id" value="" placeholder="No."/>
					</div>
					<div class="value col-xs-3">
						<div class="checkbox">
							<label>
								<input name="is_free_ignore" type="checkbox"> FREE
								<input name="is_free" type="hidden">
							</label>
						</div>
					</div>
					<div class="status-value value col-xs-3">
						<select name="subscription_status">
							<option value="active">Active</option>
							<option value="paused">Paused</option>
						</select>
					</div>
				</div>
				<div class="row through-detail">
					<div class="title col-xs-3">
						Through:
					</div>
					<div class="col-xs-9 through-value value">
						<input type="text"  name="through" value="" required placeholder="Through"/>
						<input type="hidden"  name="through_id" value="" required placeholder="Through"/>
					</div>
				</div>                    
				<div class="row category-detail">
					<div class="title col-xs-3">
						Duration:
					</div>
					<div class="value category-value col-xs-9">
						<select name="category_id">
						</select>
					</div>
				</div>                    
				<div class="row language-detail">
					<div class="title col-xs-3">
						Language:
					</div>
					<div class="value language-value col-xs-9">
						<select name="language">
							<option value="english">English</option>
							<option value="hindi">Hindi</option>
						</select>
					</div>
				</div>
				<div class="row receipt-detail">
					<div class="title col-xs-3">
						Receipt No:
					</div>
					<div class="receipt-value value col-xs-9">
						<input disabled type="text"  name="receipt_no" value="" placeholder="Receipt No."/>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block">
				<div class="row date-detail">
					<div class="title col-xs-2">
						Date:
					</div>
					<div class="date-value value col-xs-10">
						<input disabled type="text" class="date" value="" name="entry_date" placeholder="Entry Date" />
					</div>
				</div>
				<div class="row date-detail">
					<div class="title col-xs-2">
						From:
					</div>
					<div class="value starting-month-value col-xs-2">
						<select name="start_month">
						</select>
					</div>
					<div class="value starting-year-value col-xs-2">
						<input type="number" value="" name="start_year" placeholder="Year" />
					</div>
					<div class="title col-xs-2">
						To:
					</div>
					<div class="value ending-month-value col-xs-2">
						<select name="end_month">
						</select>
					</div>
					<div class="value ending-year-value col-xs-2">
						<input type="number" value="" name="end_year" placeholder="Ending Year" />
					</div>
				</div>
				<div class="row copies-detail">
					<div class="title col-xs-2">
						Readers:
					</div>
					<div class="value copies-value col-xs-4">
						<input type="number" value="" name="copies" placeholder="No of Copies" />
						<input type="hidden" value="" name="renew_pending" />
					</div>
					<div class="title col-xs-2">
						Issues:
					</div>
					<div class="value total-issues-value col-xs-4">
						<input disabled type="number" value="" name="total_issues" placeholder="No of Issues" />
					</div>
				</div>
				<div class="row issues-detail hidden">
					<div class="title col-xs-2">
						Issues:
					</div>
					<div class="value issues-value col-xs-4">
						<input disabled type="number" value="" name="issues" placeholder="No of Issues" />
					</div>
					<div class="title col-xs-2">
						Remaining:
					</div>
					<div class="value bal-issues-value col-xs-4">
						<input disabled type="number" value="" name="balance_issues" placeholder="Remaining Issues" />
					</div>
				</div>
				<div class="row amount-detail">
					<div class="title col-xs-2 hidden">
						Amount ₹:
					</div>
					<div class="value amount-value col-xs-4 hidden">
						<input disabled type="number" value="" name="amount" placeholder="Amount" />
					</div>
					<div class="title col-xs-2 hidden">
						Balance ₹:
					</div>
					<div class="value balance-value col-xs-4 hidden">
						<input disabled type="number" value="" name="balance" placeholder="Balance" />
					</div>
					<div class="title col-xs-2">
						Rate ₹:
					</div>
					<div class="value rate-value col-xs-4">
						<input disabled type="number" value="" name="rate" placeholder="Rate" />
					</div>
					<div class="title col-xs-2">
						Total ₹:
					</div>
					<div class="value total-value col-xs-4">
						<input disabled type="number" value="" name="total_amount" placeholder="Total" />
					</div>
				</div>
				<div class="row byhand-detail">
					<div class="title col-xs-2">
						By Hand:
					</div>
					<div class="value byhand-value col-xs-10">
						<input disabled type="text" value="" name="by_hand" placeholder="By Hand" />
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-4 main-details">
			<div class="block markings-div">
				<table class='markings-table'>
					<thead>
						<tr>
							<td colspan="13">Markings</td>
						</tr>
					</thead>
					<tr>
						<th></th>
						<th>Jan</th>
						<th>Mar</th>
						<th>May</th>
						<th>Jul</th>
						<th>Sep</th>
						<th>Nov</th>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="bulk-users-section">
		<div class='row'>
			<div class='heading'>User Names</div>
		</div>
		<div class="row user-info">
			<div class="col-xs-12 col-md-12 main-details">
				<div class="block">
					<div class="row user-detail">
						<div id="user-row" class="multi-field-row hidden col-xs-12 col-md-3">
							<div class="row">
								<div class="value col-xs-11">
									<input type="text"  name="user_name[]" value="" placeholder="User Name"/>
								</div>
								<button type="button" class="col-xs-1 col-md-1 btn btn-xs btn-primary multi_field_add_btn" data-field-id="user-row"><i class="glyphicon glyphicon-plus"></i></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
`;

interface Props {
	record?: SubscriberRecord;
	index?: number;
	listRef?: React.RefObject<any>;
}

class Component extends React.PureComponent<Props> {
	private tileRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	componentDidUpdate(prevProps, prevState) {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	render() {
		return (
			<div
				ref={this.tileRef}
				dangerouslySetInnerHTML={{ __html: isMobile() ? briefTileHTML : detailTileHTML }}
			></div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {};
}

export const ViewTile = connect(mapStateToProps)(Component);
