import { HeaderControls, HeaderModule } from 'components/header/anand.header';
import { overlay_panel } from 'core/anand.overlay_panel';
import { Records } from 'core/anand.records';
import { utils as Utils } from 'core/anand.utils';

export const KutiyaRecords = (function ($) {
	var configMap = {};

	var stateMap: any = {
		systemId: 'kutiya',
	};

	function initModule(props?, updateRecordsState?) {
		HeaderModule.initModule([
			HeaderControls.addRecordBtn,
			HeaderControls.selectDropdown,
			HeaderControls.printBtn,
			HeaderControls.sortBtn,
		]);

		($.fn as any).intlTelInput.loadUtils('/assets/js/lib/IntlTelInput/utils.js');

		Records.projectTitle = 'Satsang Records';

		Records.getAnySearchFilter = getAnySearchFilter;
		Records.defaultSortOrder = 'head_name';
		Records.IdentifyingFieldName = 'name';

		// Records.imageFieldName = null;

		Records.updateRecordsState = updateRecordsState;

		Records.sortingFieldsMap = {
			form_no: 'Form No',
			'trust_name, head_name, country, state, city': 'Trust',
			'head_name, country, state, city': 'Head',
			'country, state, city, head_title, head_name, address': 'Address',
			'property_status, head_name, country, state, city': 'Status',
		};

		Records.searchFieldsMap = {
			form_no: 'Form No',
			trust_name: 'Trust',
			head_name: 'Head Name',
			address: 'Address',
			city: 'City',
			state: 'State',
			country: 'Country',
			pincode: 'Pin Code',
			property_status: 'Status',
			residents: { filter: 'has', text: 'Residents' },
			contacts: 'Contacts',
			comments: 'Comments',
		};

		Records.isSearchDateTypeColumn = function (searchBy) {
			return false;
		};

		function printAddrLabels() {
			Records.refreshRecordsAndReposition();

			var printConfig =
				'<div id="print-configs">\n\
									<div class="col-xs-12">\n\
										<label class="checkbox" style="display:inline;padding:1em">\n\
											<input style="width:auto" type="checkbox" class="address-label-showcontact" />\n\
											Add Contact\n\
										</label>\n\
										<label class="checkbox" style="display:inline;padding:1em">\n\
											<input style="width:auto" type="checkbox" class="address-label-showresident" />\n\
											Add Resident\n\
										</label>\n\
										<label class="checkbox" style="display:inline;padding:1em">\n\
											<input style="width:auto" type="checkbox" class="address-label-showhead" />\n\
											Add Head\n\
										</label>\n\
										<div class="col-md-1 no-padding"><label>Skip Labels:</label></div><div class="col-md-1 no-l-padding"><input type="number" name="skip_labels" value="0" placeholder="Skip Labels"></div>\n\
										<div class="col-md-1 no-padding"><label>Copies:</label></div><div class="col-md-1 no-l-padding"><input type="number" name="copies" value="1" placeholder="Copies"></div>\n\
									</div>';

			var printBtn =
				'<button id="print_records_btn" class="button btn btn-primary">' +
				'<span><span>Print</span></span>' +
				'</button>';

			printConfig += '<iframe id="print-preview" name="print-preview" src="about:blank"></iframe>';
			printConfig += '</div>';

			var printConfigObj = {
				contentHtml: printConfig,
				id: 'print_config',
				title: 'Print Address Labels',
				headerBtnHtml: printBtn,
				afterAppend: function () {
					updatePrintLabelPreview(null);
				},
			};

			overlay_panel.showPanel(printConfigObj);

			$('#print-configs select').on('change', updatePrintLabelPreview);
			$('#print-configs input').on('change', updatePrintLabelPreview);

			$('#print_records_btn').click(function () {
				updatePrintLabelPreview(null, true);
			});
		}

		function getSelectedPrintLabelConfigs() {
			var printConfigs = {
				page_margins: {
					left: 0.5,
					right: 0.5,
					top: 0.7,
					bottom: 0.7,
				},
				label_margins: {
					horizontal: 0.2,
					vertical: 0.3,
				},
				label_height: 4.45,
				font_size: 18,
				font_type: 'helvetica',
				labels_pp: 12,
				skip_labels: parseInt($("#print-configs input[name='skip_labels']").val() as string) % 12,
				copies: parseInt($("#print-configs input[name='copies']").val() as string),
				show_contact: $('.address-label-showcontact').is(':checked'),
				show_head: $('.address-label-showhead').is(':checked'),
				show_resident: $('.address-label-showresident').is(':checked'),
			};

			return printConfigs;
		}

		function updatePrintLabelPreview(e, autoPrint = undefined) {
			var data = JSON.parse(JSON.stringify(Records.getCurrentRecords()));

			var configs = getSelectedPrintLabelConfigs();

			if (typeof autoPrint === 'undefined') {
				data = data.slice(0, configs.labels_pp - configs.skip_labels);
			}

			// '<link rel="stylesheet" type="text/css" href="/application/themes/anand/assets/css/lib/bootstrap/bootstrap.css">' +
			// '<link rel="stylesheet" type="text/css" media="print" href="/application/modules/kutiya_records/assets/css/anand.kutiyaRecords.print.label.css">' +
			// '<link rel="stylesheet" type="text/css" href="/application/modules/kutiya_records/assets/css/anand.kutiyaRecords.print.label.css">' +

			let style = `<style>	
				body {
					background-color: rgba(0, 0, 0, 0);		
					line-height: 1.42857143;
					color: #333;				
				}

				* {
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
				}

				.row {
					margin-right: -15px;
					margin-left: -15px;
				}

				.row:before, .row:after {
					display: table;
					content: " ";
				}

				.col-xs-12 {
					width: 100%;
				}
				
				.col-xs-6 {
					width: 50%;
				}

				.col-xs-6, .col-xs-12 {
					float: left;
					position: relative;
					min-height: 1px;
					padding-right: 15px;
					padding-left: 15px;
				}

				.print-top-div {
					background-color: white;
					padding: 0px 40px;
					width: 8.27in;
					position: relative;
					height: 11.69in;
					padding: 0.7cm 0.4cm 0.4cm 0.4cm;
					margin-left: 0;
					margin-right: 0;
					page-break-after: always;
				}
				
				.print-top-div .field {
					padding-top: 30px;
				}
				
				.print-top-div .title {
					font-weight: normal;
					font-size: 90%;
					text-align: left;
					padding-right: 0px;
					float: left;
					margin-bottom: 0;
				}
				
				.print-top-div .value {
					border-bottom: 1px dotted black;
					display: block;
					overflow: hidden;
					padding-left: 15px;
				}
				
				.print-top-div .print-address-label {
					padding: 10px;
					margin: 0 0.1cm 0.3cm 0.1cm;
					height: auto;
					color: #383780;
				}
				
				.print-top-div .print-label-container {
					margin: 0;
					padding: 0;
					height: 4.75cm;
					overflow: hidden;
				}
				
				.print-addresses-dummy {
					position: absolute;
					top: 100%;
				}
			</style>`;
			var headHtml = `<head> ${style}	</head>`;
			var html = headHtml + '<body>';

			var topDiv = $(`<div class='print-top-div row'>${style}</div>`);
			topDiv.css('font-size', configs.font_size);
			topDiv.css('font-family', configs.font_type);
			$('body').append(topDiv);

			html += '<div class="print-top-div row">';

			var j = 0;
			for (; j < configs.skip_labels; j++) {
				html += "<div class='col-xs-6 print-label-container'><div class='print-address-label'></div></div>";
			}

			var len = data.length;
			for (var i = 0; i < len * configs.copies; i++) {
				if ((i + j) % configs.labels_pp === 0 && i !== 0) {
					html += '<div class="print-top-div row">';
				}
				var addrLabel = printOneRecord(data[Math.floor(i / configs.copies)]);

				topDiv.append(addrLabel);

				var $label = addrLabel.find('.print-address-label');

				while (parseInt(addrLabel.css('height')) < parseInt($label.css('height'))) {
					$label.css('font-size', parseInt($label.css('font-size')) - 0.5);
				}
				$label.css('height', configs.label_height + 'cm');

				html += topDiv.html();
				addrLabel.remove();

				if ((i + j + 1) % configs.labels_pp === 0) {
					html += '</div>';
				}
			}

			html += '</div></body>';
			topDiv.remove();

			var iFrameWindow = window.frames['print-preview'];
			var doc = iFrameWindow.document;
			$('body', doc).html(html);

			$('body', doc).find('.print-top-div').css('font-size', configs.font_size);
			$('body', doc).find('.print-top-div').css('font-family', configs.font_type);

			$('#print-preview').css('height', '12in');

			if (typeof autoPrint !== 'undefined' && autoPrint === true) {
				setTimeout(function () {
					iFrameWindow.focus();
					iFrameWindow.print();
				}, 1000);
			}

			function printOneRecord(record) {
				var address = '';
				if (record['address'] !== null) {
					address = record['address'].replace(/\n/g, '</br>');
				}

				var cityDetails = '';
				var comma = '';
				if (record['country'] && record['country'].toLowerCase().trim() !== 'india') {
					if (record['city']) {
						cityDetails += record['city'];
						comma = '</br>';
					}
					if (record['state']) {
						cityDetails += comma + record['state'];
						comma = ' - ';
					}
					if (record['pincode'] && record['pincode'] !== '0') {
						cityDetails += comma + record['pincode'];
					}
					comma = '</br>';
					cityDetails += comma + '( ' + record['country'] + ' )';
				} else {
					if (record['city']) {
						cityDetails += record['city'];
						comma = ' - ';
					}
					if (record['pincode'] && record['pincode'] !== '0') {
						cityDetails += comma + record['pincode'];
					}
					comma = ' ';
					if (record['state']) {
						cityDetails += comma + '( ' + record['state'] + ' )';
					}
				}
				var kutiyaName = record['name'];
				var headMapping = {
					Mahatma: 'Mh.',
					Bai: 'Bai',
					'': '',
				};

				var residentName = '';

				if (typeof record['residents'] !== 'undefined') {
					var resident = record['residents'][0];
					residentName = resident['resident_title'] + ' ' + resident['resident_name'];
				}
				var phone = '';
				var phoneType = '';
				if (record['contacts'] !== null) {
					var contacts = record['contacts'];
					if (typeof contacts['Mobile'] !== 'undefined') {
						phone = contacts['Mobile'][0];
						phoneType = 'Mobile: ';
					} else if (typeof contacts['Landline'] !== 'undefined') {
						phone = contacts['Landline'][0];
						phoneType = 'Landline: ';
					}
				}

				var html =
					"<div class='col-xs-6 print-label-container'><div class='print-address-label'>" +
					"<div class='row'>";
				if (residentName && configs.show_resident === true) {
					html +=
						"<div class='col-xs-12'>" + "<label class='title'>" + residentName + ' Ji</label>' + '</div>';
				}
				if (record['head_name'] && configs.show_head === true) {
					html +=
						"<div class='col-xs-12'>" +
						"<label class='title'>" +
						headMapping[record['head_title']] +
						' ' +
						record['head_name'] +
						' Ji</label>' +
						'</div>';
				}
				html +=
					"<div class='col-xs-12'>" +
					"<label class='title'>" +
					kutiyaName +
					'</label>' +
					'</div>' +
					'</div>' +
					"<div class='row'>" +
					"<div class='col-xs-12'>" +
					"<label class='title address-label'>" +
					address +
					'</label>' +
					'</div>' +
					'</div>' +
					"<div class='row'>" +
					"<div class='col-xs-12'>" +
					"<label class='title'>" +
					cityDetails +
					'</label>' +
					'</div>' +
					'</div>';
				if (configs.show_contact === true) {
					html +=
						"<div class='row'>" +
						"<div class='col-xs-12'>" +
						"<label class='title'>" +
						phoneType +
						phone +
						'</label>' +
						'</div>' +
						'</div>';
				}
				html += '</div></div>';

				return $(html);
			}
		}

		function printHtml() {
			Records.refreshRecordsAndReposition();

			var printConfigHtml =
				'<div id="print-configs">' +
				'<div class="row">' +
				'<div class="clearfix hidden-lg hidden-md"></div>' +
				'<div class="col-xs-3 col-md-1 no-r-padding"><label>Font:</label></div>' +
				'<div class="col-xs-3 col-md-2 no-padding">' +
				'<select id="font_type">' +
				'<option value="helvetica">helvetica</option>' +
				'<option value="courier">courier</option>' +
				'<option value="times">times</option>' +
				'</select>' +
				'</div>' +
				'<div class="col-xs-3 col-md-1 no-r-padding"><label>Font Size:</label></div>' +
				'<div class="col-xs-3 col-md-2 no-l-padding">' +
				'<select id="font_size">' +
				'<option value="12">12</option>' +
				'<option value="14">14</option>' +
				'<option value="16" selected="selected">16</option>' +
				'<option value="18">18</option>' +
				'</select>' +
				'</div>' +
				'</div>';

			var printBtnHtml =
				'<button id="print_records_btn" class="button btn btn-primary">' +
				'<span><span>Print</span></span>' +
				'</button>';

			printConfigHtml += '<iframe id="print-preview" name="print-preview" src="about:blank"></iframe>';
			printConfigHtml += '</div>';

			var printConfigObj = {
				contentHtml: printConfigHtml,
				id: 'print_config',
				title: 'Print Forms',
				headerBtnHtml: printBtnHtml,
			};

			overlay_panel.showPanel(printConfigObj);

			$('#print-configs select').on('change', updatePrintPreview);
			$('#print-configs input').on('change', updatePrintPreview);

			$('#print_records_btn').click(function () {
				updatePrintPreview(null, true);
			});

			updatePrintPreview(null);
		}

		function getSelectedPrintConfigs() {
			var printConfigs = {
				font_size: parseInt($('#print-configs #font_size').val() as string),
				font_type: $('#print-configs #font_type').val() as string,
				headers: [],
			};

			return printConfigs;
		}

		function updatePrintPreview(e, autoPrint?, exportRecords?) {
			var data = JSON.parse(JSON.stringify(Records.getCurrentRecords()));

			if (typeof autoPrint === 'undefined') {
				data = data.slice(0, 1);
			}

			var configs = getSelectedPrintConfigs();

			data = Records.printOptions.beforePrintHook(data, exportRecords);

			var headerHtml = '<header>Shri Sadguru Devai Namah</header>';
			var footerHtml = "<footer><div class=''>Signature</div><div class=''>Date:</div></footer>";

			// '<link rel="stylesheet" type="text/css" href="/application/themes/anand/assets/css/lib/bootstrap/bootstrap.css">' +
			// '<link rel="stylesheet" type="text/css" href="/application/modules/kutiya_records/assets/css/anand.kutiyaRecords.print.css">' +

			var headHtml = `
				<head>
					<style>
						body {
							background-color: rgba(0, 0, 0, 0);
							line-height: 1.42857143;
							color: #333;
						}
						
						* {
							-webkit-box-sizing: border-box;
							-moz-box-sizing: border-box;
							box-sizing: border-box;
						}
	
						.row {
							margin-right: -15px;
							margin-left: -15px;
						}
	
						.row:before, .row:after {
							display: table;
							content: " ";
						}
	
						.col-xs-12 {
							width: 100%;
						}
						
						.col-xs-6 {
							width: 50%;
						}
	
						.col-xs-4 {
							width: 33.33333333%;
						}
						
						.col-xs-6, .col-xs-12, .col-xs-4 {
							float: left;
							position: relative;
							min-height: 1px;
							padding-right: 15px;
							padding-left: 15px;
						}
	
						@media print {
							.print-top-div footer {
								page-break-after: always;
							}
						}
						
						.print-top-div footer {
							position: absolute;
							bottom: 40px;
							font-weight: bold;
							font-size: 90%;
							padding: 10px 0px;
							right: 40px;
							left: 40px;
							page-break-after: always;
						}
						/*
						.print-record {
							page-break-after: always;	
						}*/
						
						.print-top-div footer > div {
							padding: 5px 0;
						}
						
						.print-top-div header { 
							font-size: 110%;
							text-align:center;
							font-weight: bold;
							width: 100%;
							padding: 40px 0px;	
						}
						
						.print-top-div {
							background-color: white;
							padding: 0px 40px;
							width: 8.27in;
							position: relative;
							height: 11.69in;
						}
						
						.print-top-div .print-top-header {
							font-size: 125%;
							padding: 20px 0px; 
							width: 100%; 
							text-align: center; 
							font-weight: bold;
						}
						
						.print-top-div .field {
							padding-top: 30px;
						}
						
						.print-top-div .title {
							font-weight: bold;
							font-size: 90%;
							text-align: left;
							padding-right: 0px;
							float: left;
						}
						
						.print-top-div .value {
							border-bottom: 1px dotted black;
							display: block;
							overflow: hidden;
							padding-left: 15px;
						}					
					</style>
				</head>`;
			var html = headHtml + '<body>';

			for (var i = 0; i < data.length; i++) {
				html += '<div class="print-top-div">' + printOneRecord(data[i]) + '</div>';
			}

			html += '</div></body>';

			function printOneRecord(record) {
				for (var key in record) {
					if (record[key] == null || record[key] == '') {
						record[key] = '&nbsp';
					}
				}
				record['residents'] = record['residents'].replace(/\n/g, '</br>');
				record['contacts'] = record['contacts'].replace(/\n/g, '</br>');
				var html =
					headerHtml +
					"<div class='print-record'>" +
					"<div class='row'>" +
					"<div class='field form-field col-xs-6'>" +
					"<label class='title'>Form No.: </label>" +
					"<span class='value'>" +
					record['form_no'] +
					'</span>' +
					'</div>' +
					"<div class='field date-field col-xs-6'>" +
					"<label class='title'>Date: </label>" +
					"<span class='value'>" +
					Utils.getDateStr() +
					'</span>' +
					'</div>' +
					'</div>' +
					"<div class='row'>" +
					"<div class='field head-field col-xs-12'>" +
					"<label class='title'>Head: </label>" +
					"<span class='value'>" +
					record['head_name'] +
					'</span>' +
					'</div>' +
					'</div>' +
					"<div class='row'>" +
					"<div class='field address-field col-xs-12'>" +
					"<label class='title'>Address: </label>" +
					"<span class='value'>" +
					record['name'] +
					'</br>' +
					record['address'].replace(/\n/g, '</br>') +
					'</span>' +
					'</div>' +
					'</div>' +
					"<div class='row'>" +
					"<div class='field city-field col-xs-6'>" +
					"<label class='title'>City / Dist.: </label>" +
					"<span class='value'>" +
					record['city'] +
					'</span>' +
					'</div>' +
					"<div class='field state-field col-xs-6'>" +
					"<label class='title'>State: </label>" +
					"<span class='value'>" +
					record['state'] +
					'</span>' +
					'</div>' +
					'</div>' +
					"<div class='row'>" +
					"<div class='field country-field col-xs-6'>" +
					"<label class='title'>Country: </label>" +
					"<span class='value'>" +
					record['country'] +
					'</span>' +
					'</div>' +
					"<div class='field pincode-field col-xs-6'>" +
					"<label class='title'>Pincode: </label>" +
					"<span class='value'>" +
					record['pincode'] +
					'</span>' +
					'</div>' +
					'</div>' +
					"<div class='row'>" +
					"<div class='field lift-field col-xs-6'>" +
					"<label class='title'>Lift: </label>" +
					"<span class='value'>" +
					record['lift'] +
					'</span>' +
					'</div>' +
					"<div class='field area-field col-xs-6'>" +
					"<label class='title'>Area: </label>" +
					"<span class='value'>" +
					record['area'] +
					'</span>' +
					'</div>' +
					'</div>' +
					"<div class='row'>" +
					"<div class='field col-xs-4'>" +
					"<label class='title'>No. of Permanent Members: </label>" +
					'</div>' +
					"<div class='field males-field col-xs-4'>" +
					"<label class='title'>Male: </label>" +
					"<span class='value'>" +
					record['male_members'] +
					'</span>' +
					'</div>' +
					"<div class='field females-field col-xs-4'>" +
					"<label class='title'>Female: </label>" +
					"<span class='value'>" +
					record['female_members'] +
					'</span>' +
					'</div>' +
					'</div>' +
					"<div class='row'>" +
					"<div class='field trust-field col-xs-6'>" +
					"<label class='title'>Trust: </label>" +
					"<span class='value'>" +
					record['trust_name'] +
					'</span>' +
					'</div>' +
					"<div class='field status-field col-xs-6'>" +
					"<label class='title'>Property Status: </label>" +
					"<span class='value'>" +
					record['property_status'] +
					'</span>' +
					'</div>' +
					'</div>' +
					"<div class='row'>" +
					"<div class='field comments-field col-xs-12'>" +
					"<label class='title'>Comments: </label>" +
					"<span class='value'>" +
					record['comments'] +
					'</span>' +
					'</div>' +
					'</div>' +
					"<div class='row'>" +
					"<div class='field residents-field col-xs-6'>" +
					"<label class='title'>Residents: </label>" +
					"<span class='value'>" +
					record['residents'] +
					'</span>' +
					'</div>' +
					"<div class='field contacts-field col-xs-6'>" +
					"<label class='title'>Contacts: </label>" +
					"<span class='value'>" +
					record['contacts'] +
					'</span>' +
					'</div>' +
					'</div>' +
					footerHtml +
					'</div>';

				return html;
			}

			var iFrameWindow = window.frames['print-preview'];
			var doc = iFrameWindow.document;
			$('body', doc).html(html);

			$('body', doc).find('.print-top-div').css('font-size', configs.font_size);
			$('body', doc).find('.print-top-div').css('font-family', configs.font_type);

			$('#print-preview').css('height', '12in');

			if (typeof autoPrint !== 'undefined' && autoPrint === true) {
				iFrameWindow.focus();
				iFrameWindow.print();
			}
		}

		Records.printOptions = {
			fieldNames: [
				'S. No.',
				'Form No.',
				'Trust',
				'Head',
				'Kutiya Address',
				'City',
				'State',
				'Country',
				'Area',
				'Members',
				'Lift',
				'Residents',
				'Contacts',
				'Status',
				'Comments',
			],
			fieldKeys: [
				'id',
				'form_no',
				'trust_name',
				'head_name',
				'kutiya',
				'city',
				'state',
				'country',
				'area',
				'members',
				'lift',
				'residents',
				'contacts',
				'property_status',
				'comments',
			],
			defaultFieldWidths: {
				id: 60,
				form_no: 0,
				trust_name: 60,
				head_name: 0,
				kutiya: 325,
				city: 0,
				state: 0,
				country: 0,
				area: 60,
				members: 100,
				lift: 50,
				residents: 220,
				contacts: 220,
				property_status: 0,
				comments: 120,
			},
			defaultHeader: 'Satsang Bhawan Records',
			beforePrintHook: function (data, forExport) {
				var titleMap = {
					Mahatma: 'Mh.',
					Bai: 'Bai',
					'Mr.': 'Mr.',
					'Ms.': 'Ms.',
					'Mh/Bai': 'Mh./Bai',
					'': '',
				};

				for (var i = 0; i < data.length; i++) {
					data[i]['id'] = i + 1 + '.';
					var suffix = data[i]['head_title'] === 'Mahatma' || data[i]['head_title'] === 'Bai' ? ' Ji' : '';
					data[i]['head_title'] = titleMap[data[i]['head_title']];
					if (typeof forExport === 'undefined' || forExport === false) {
						data[i]['head_name'] = data[i]['head_name'].replace(/<\/br> /g, '\n');
						data[i]['head_name'] = data[i]['head_name'].replace(/<br\/> /g, '\n');
						data[i]['head_name'] = data[i]['head_name'].replace(/<\/br>/g, '\n');
						data[i]['head_name'] = data[i]['head_name'].replace(/<br\/>/g, '\n');
						data[i]['head_name'] = data[i]['head_title'] + ' ' + data[i]['head_name'] + suffix;
					} else {
						data[i]['head_name'] = data[i]['head_name'] + suffix;
					}

					data[i]['kutiya'] =
						data[i]['name'] +
						'\n' +
						data[i]['address'] +
						'\n' +
						data[i]['city'] +
						', ' +
						data[i]['state'] +
						', ' +
						data[i]['country'];
					if (data[i]['pincode'] && data[i]['pincode'] != '0') {
						data[i]['kutiya'] += ' - ' + data[i]['pincode'];
					}

					data[i]['members'] =
						'male: ' + data[i]['male_members'] + '\n' + 'female:' + data[i]['female_members'];

					if (data[i]['contacts'] !== null) {
						var contacts = data[i]['contacts'];

						var html = '';
						var newLine = '';
						for (var contactType in contacts) {
							for (var j = 0; j < contacts[contactType].length; j++) {
								html += newLine + contactType + ': ' + contacts[contactType][j];
								newLine = '\n';
							}
						}
						data[i]['contacts'] = html;
					}

					if (typeof data[i]['residents'] !== 'undefined') {
						var residents = data[i]['residents'];

						var html = '';
						var newLine = '';
						for (var j = 0; j < residents.length; j++) {
							suffix =
								residents[j]['resident_title'] === 'Mahatma' || residents[j]['resident_title'] === 'Bai'
									? ' Ji'
									: '';
							html +=
								newLine +
								titleMap[residents[j]['resident_title']] +
								' ' +
								residents[j]['resident_name'] +
								suffix;
							newLine = '\n';
						}
						data[i]['residents'] = html;
					} else {
						data[i]['residents'] = '';
					}
				}

				return data;
			},
		};

		Records.exportOptions = {
			fieldKeys: [
				'id',
				'form_no',
				'trust_name',
				'head_title',
				'head_name',
				'address',
				'city',
				'state',
				'country',
				'pincode',
				'area',
				'male_members',
				'female_members',
				'lift',
				'residents',
				'contacts',
				'property_status',
				'comments',
			],
			defaultFileName: 'Kutiya Records',
		};

		Records.hooks.afterHeaderConfigure = function (controlsHeader) {
			var printMenu = $(
				'<span class="dropdown hidden-sm hidden-xs">\n\
                    <a class="btn selection-btn dropdown-toggle" data-toggle="dropdown">\n\
						<span class="glyphicon glyphicon-print"></span>\n\
                        <span class="menu-text">Print</span>\n\
                        <span class="caret"></span>\n\
                    </a>\n\
                    <ul class="dropdown-menu">\n\
                        <li><a class="btn menu-text print-records">\n\
                                <span class="menu-text">Records</span>\n\
                            </a>\n\
                        </li>\n\
                        <li>\n\
                            <a class="btn menu-text print-forms">\n\
                                <span class="menu-text">Forms</span>\n\
                            </a>\n\
                        </li>\n\
                        <li>\n\
                            <a class="btn menu-text print-addresses">\n\
                                <span class="menu-text">Addresses</span>\n\
                            </a>\n\
                        </li>\n\
                    </ul>\n\
                </span>'
			);

			var ptintBtn = controlsHeader.find('.print-btn');
			ptintBtn.hide();
			ptintBtn.after(printMenu);

			printMenu.find('.print-records').click(function () {
				ptintBtn.click();
			});
			printMenu.find('.print-forms').click(printHtml);
			printMenu.find('.print-addresses').click(printAddrLabels);
		};

		Records.detailTileSelector = '#kutiya-detail-record';
		Records.briefTileSelector = '#kutiya-brief-record';
		Records.addTileSelector = '#kutiya-add-record';
		Records.detailFormSelector = '#kutiya-detail-record-form';
		Records.imageUploadSelector = null;

		Records.populateBriefTile = populateBriefTile;
		Records.populateDetailTile = populateDetailTile;
		Records.populateDetailForm = populateDetailForm;
		Records.initModule({
			systemId: stateMap.systemId,
			prepareRecord: prepareRecord,
			hasContacts: true,
			props: props,
		});
	}

	function prepareRecord(record) {
		record['name'] = Utils.upperFirstAll(record['name']);
		record['head_name'] = Utils.upperFirstAll(record['head_name']);

		if (record['male_members'] === null) {
			record['male_members'] = 0;
		}
		if (record['female_members'] === null) {
			record['female_members'] = 0;
		}

		return record;
	}

	function getAnySearchFilter(query) {
		return [
			{ head_title: { leftnocase: query } },
			{ head_name: { likenocase: query } },
			{ address: { likenocase: query } },
			{ city: { likenocase: query } },
			{ state: { likenocase: query } },
			{ country: { likenocase: query } },
			{ pincode: { leftnocase: query } },
			{ contacts: { likenocase: query } },
			{ form_no: { leftnocase: query } },
			{ residents: { has: query } },
			{ trust_name: { likenocase: query } },
			{ comments: { likenocase: query } },
			{ property_status: { likenocase: query } },
		];
	}

	async function populateBriefTile(sampleTile, record) {
		var titleMapping = {
			Mahatma: 'Mh.',
			Bai: 'Bai',
			'Mr.': 'Mr.',
			'Ms.': 'Ms.',
			'Mh/Bai': 'Mh./Bai',
		};

		var suffixMapping = {
			Mahatma: ' Ji',
			Bai: ' Ji',
			'Mr.': '',
			'Ms.': '',
			'Mh/Bai': ' Ji',
		};

		sampleTile.find('.head-detail .title').html(titleMapping[record['head_title']]);
		sampleTile.find('.head-detail .value').html(record['head_name'] + suffixMapping[record['head_title']]);
		sampleTile.find('.city-detail .value').html(record['city']);

		return sampleTile;
	}

	async function populateDetailTile(sampleTile, recordOrig) {
		var record = {};
		for (var key in recordOrig) {
			if (recordOrig[key] == null || recordOrig[key] == '') {
				record[key] = '&nbsp';
			} else {
				record[key] = recordOrig[key];
			}
		}

		var titleMapping = {
			Mahatma: 'Mh.',
			Bai: 'Bai',
			'Mr.': 'Mr.',
			'Ms.': 'Ms.',
			'Mh/Bai': 'Mh./Bai',
		};

		var suffixMapping = {
			Mahatma: ' Ji',
			Bai: ' Ji',
			'Mr.': '',
			'Ms.': '',
			'Mh/Bai': ' Ji',
		};

		sampleTile.find('.form-trust-detail .form-value').html(record['form_no']);
		sampleTile.find('.form-trust-detail .trust-value').html(record['trust_name']);

		sampleTile.find('.self-detail .value').html(record['name']);

		sampleTile
			.find('.head-detail .value')
			.html(titleMapping[record['head_title']] + ' ' + record['head_name'] + suffixMapping[record['head_title']]);

		recordOrig['address'] = record['address'] = record['address'].replace(/\\n/g, '\n');
		sampleTile
			.find('.address-detail .value')
			.html(record['name'] + '</br>' + record['address'].replace(/\n/g, '</br>'));

		sampleTile.find('.city-detail .city-value').html(record['city']);
		sampleTile.find('.city-detail .pincode-value').html(record['pincode']);

		sampleTile.find('.state-detail .state-value').html(record['state']);
		sampleTile.find('.state-detail .country-value').html(record['country']);

		sampleTile.find('.lift-area-detail .lift-value').html(record['lift']);
		sampleTile.find('.lift-area-detail .area-value').html(record['area']);

		sampleTile.find('.members-detail .males-value').html(record['male_members']);
		sampleTile.find('.members-detail .females-value').html(record['female_members']);

		sampleTile.find('.status-detail .value').html(record['property_status']);
		sampleTile.find('.comments-detail .value').html(record['comments']);

		if (recordOrig['contacts'] !== null) {
			var contacts = recordOrig['contacts'];
			for (var contactType in contacts) {
				for (var i = 0; i < contacts[contactType].length; i++) {
					var contactRow = sampleTile.find('.main-details .contact-detail #contact-type-value').clone();
					contactRow.attr('id', 'contact-type-value' + '-' + contactType + '-' + i);
					contactRow.find('.contact-type').html(contactType + ':');
					contactRow.find('.contact-value').html(contacts[contactType][i]);
					sampleTile.find('.main-details .contact-detail').append(contactRow);
				}
			}
		}

		if (typeof recordOrig['residents'] !== 'undefined') {
			var residents = recordOrig['residents'];
			for (var i = 0; i < residents.length; i++) {
				var residentRow = sampleTile.find('.main-details .resident-detail #resident-row').clone();
				residentRow.attr('id', 'resident-row' + '-' + i);
				residentRow.find('.resident_title').html(titleMapping[residents[i]['resident_title']]);
				residentRow
					.find('.resident_name')
					.html(residents[i]['resident_name'] + suffixMapping[residents[i]['resident_title']]);
				sampleTile.find('.main-details .resident-detail').append(residentRow);
				residentRow.show();
			}
		}

		return sampleTile;
	}

	function populateDetailForm(sampleTile, record) {
		sampleTile.find('.form-trust-detail .form-value input').val(record['form_no']);
		sampleTile.find('.form-trust-detail .trust-value input').val(record['trust_name']);

		sampleTile.find('.self-detail .value input').val(record['name']);

		sampleTile
			.find('.head-detail select[name="head_title"] option[value="' + record['head_title'] + '"]')
			.attr('selected', 'selected');
		sampleTile.find('.head-detail .value input').val(record['head_name']);

		sampleTile.find('.address-detail .value textarea').val(record['address']);

		sampleTile.find('.city-detail .city-value input').val(record['city']);
		sampleTile.find('.city-detail .pincode-value input').val(record['pincode']);

		sampleTile.find('.state-detail .state-value input').val(record['state']);
		sampleTile.find('.state-detail .country-value input').val(record['country']);

		sampleTile
			.find('.lift-area-detail select[name="lift"] option[value="' + record['lift'] + '"]')
			.attr('selected', 'selected');
		sampleTile.find('.lift-area-detail .area-value input').val(record['area']);

		sampleTile.find('.members-detail .males-value input').val(record['male_members']);
		sampleTile.find('.members-detail .females-value input').val(record['female_members']);

		sampleTile.find('.status-detail .value input').val(record['property_status']);
		sampleTile.find('.comments-detail .value input').val(record['comments']);

		if (record['contacts'] !== null) {
			var contacts = record['contacts'];
			var count = 0;
			for (var contactType in contacts) {
				for (var i = 0; i < contacts[contactType].length; i++) {
					count++;
				}
			}
			var contactDivId = Records.populateMultiAddFields(sampleTile.find('.main-details .contact-detail'), count);

			count = 1;
			for (var contactType in contacts) {
				for (var i = 0; i < contacts[contactType].length; i++) {
					var contactDiv = sampleTile.find('#' + contactDivId + count++);
					contactDiv
						.find('select[name="contact_type[]"] option[value="' + contactType + '"]')
						.attr('selected', 'selected');
					contactDiv.find('input[name="contact[]"]').val(contacts[contactType][i]);
				}
			}
		}

		if (typeof record['residents'] !== 'undefined') {
			var residents = record['residents'];
			count = residents.length;
			var residentsDivId = Records.populateMultiAddFields(
				sampleTile.find('.main-details .resident-detail'),
				residents.length
			);

			for (var i = 0; i < residents.length; i++) {
				var residentsDiv = sampleTile.find('#' + residentsDivId + (i + 1));
				residentsDiv.find('input[name="resident_name[]"]').val(residents[i]['resident_name']);
				residentsDiv
					.find('select[name="resident_title[]"] option[value="' + residents[i]['resident_title'] + '"]')
					.attr('selected', 'selected');
			}
		}

		return sampleTile;
	}

	function updateDateTime(dateTime, el) {
		dateTime = dateTime.split(' ');
		var dateAr = dateTime[0].split('-');
		var date = new Date(parseInt(dateAr[0]), parseInt(dateAr[1]) - 1, parseInt(dateAr[2]));

		el.attr('value', dateTime[0]);
		el.datepicker('update', date);

		var time = dateTime[1];
		var timeArr = time.split(':');
		if (parseInt(timeArr[0]) >= 12) {
			time = parseInt(timeArr[0]) - 12 + ':' + timeArr[1] + ' PM';
		} else {
			time = time + ' AM';
		}

		el.val(el.val() + ' - ' + time);
	}

	function handleCheckins(tile) {
		Records.triggerEditRecord(tile);
	}

	return {
		initModule: initModule,
		setConfig: function (key, value) {
			configMap[key] = value;
		},
	};
})(jQuery);
