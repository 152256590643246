import { RouterState } from "connected-react-router";

type Timestamp = firebase.default.firestore.Timestamp;

interface UserInfo {
    uid: string;
    displayName: string;
    email: string;
    phoneNumber: string;
    photoURL: string;
    providerId: string;
}

interface UserMetadata {
    lastSignInTime: string;
    creationTime: string;
    lastRefreshTime?: string | null;
}

export interface UserRecord {
    uid: string;
    email?: string;
    emailVerified: boolean;
    displayName?: string;
    phoneNumber?: string;
    photoURL?: string;
    disabled: boolean;
    metadata: UserMetadata;
    providerData: UserInfo[];
    passwordHash?: string;
    passwordSalt?: string;
    customClaims?: {
        [key: string]: any;
    };
    tokensValidAfterTime?: string;
    tenantId?: string | null;
    //   multiFactor?: MultiFactorSettings;
}

export interface FBData {
    id: string;
    draftId: string;
    forDate: string;
    active: boolean;
    deleted: boolean;
    publishTime: Timestamp;
    expireTime: Timestamp;
    createdAt: Timestamp;
    updatedAt: Timestamp;
    inApp?: any;
}

export interface FBArticle extends FBData {
    title: {
        [lang: string]: string;
    };
    articleType: string;
    mediaType: string;
    mediaUri?: string;
    mediaLength: number;
    text?: {
        [lang: string]: string;
    };
    author: string;
    group: string;
    lang: string;
    allowExport?: boolean;
}

export interface FBArticlesById {
    [articleId: string]: FBArticle;
}

export interface FBArticlesByType {
    [articleType: string]: FBArticle[];
}

export interface FBArticleStore {
    byId: FBArticlesById;
    filtered: FBArticle[];
}

export interface Media {
    audioId: number;
    src: string;
    filename: string;
    title: string;
    isFav: boolean;
    href: string;
}

export interface Rhyme extends FBData {
    title: string;
    rhyme: string;
    rhymeType: string;
    author: string;
    book: string;
    chapter: string;
    rhymeNo: string;
    text: {
        [lang: string]: string;
    };
}

export interface RhymesById {
    [id: string]: Rhyme;
}

export interface RhymeStore {
    byId: RhymesById;
    filtered: Rhyme[];
}

export interface UserData extends FBData {
    fullName: string;
    email: string;
    phone: string;
    dob: string;
    gender: string;
    rahbar: string;
    locale: string;
    photoUrl: string;
    roles: string[];
    verified: boolean;
    settings: { [key: string]: any };
    range?: { [key: string]: number };
}

export interface UserStore {
    user?: firebase.default.User;
    userData?: UserData;
}

export interface FBuserPlaylistTrack {
    articleId: string;
    order: number;
}

export interface FBuserPlaylistTracks {
    [articleId: string]: FBuserPlaylistTrack;
}

export interface FBUserPlaylist extends FBData {
    userId: string;
    title: string;
    tracks: FBuserPlaylistTracks;
}

export interface FBUserPlaylists {
    [id: string]: FBUserPlaylist;
}

// export interface UserPlaylistTrack {
// 	href: string;
// 	order: number;
// }

// export interface UserPlaylist {
// 	id: string;
// 	user_id: string;
// 	title: string;
// 	tracks: UserPlaylistTrack[];
// 	updated_at: string;
// }

// export interface UserPlaylists {
// 	[id: string]: UserPlaylist;
// }

export interface Publication extends FBData {
    title: string;
    desc: string;
    type: string;
    owner: string;
    lang: string;
    frequency: number;
}

export interface Edition extends FBData {
    publicationId: string;
    coverpageUri: string;
    fileUri: string;
}

export interface PublicationsById {
    [id: string]: Publication;
}

export interface PublicationStore {
    byId: PublicationsById;
    filtered: Publication[];
}

export interface EditionsById {
    [id: string]: Edition;
}

export interface EditionStore {
    byId: EditionsById;
    filtered: Edition[];
}

export interface BookletPage {
    pageCollectionType: string;
    pageRefId: string;
    order: 0;
    indexTitle: string;
    pages: BookletPage[];
    startTime: Timestamp;
    endTime: Timestamp;
    flatIndex: number; // UX property, Not in DB
}

export interface Booklet extends FBData {
    type: string;
    title: string;
    pages: BookletPage[];
}

export interface BookletsById {
    [id: string]: Booklet;
}

export interface BookletStore {
    byId: BookletsById;
    filtered: Booklet[];
}

export interface MjBjRecord extends FBData {
    contacts?: string;
}

export interface MjBjById {
    [id: string]: MjBjRecord;
}

export interface MjBjStore {
    byId: MjBjById;
    filtered: MjBjRecord[];
}

export interface TrusteeRecord extends FBData {}

export interface TrusteeById {
    [id: string]: TrusteeRecord;
}

export interface TrusteeStore {
    byId: TrusteeById;
    filtered: TrusteeRecord[];
}

export interface KutiyaRecord extends FBData {}

export interface KutiyaById {
    [id: string]: KutiyaRecord;
}

export interface KutiyaStore {
    byId: KutiyaById;
    filtered: KutiyaRecord[];
}

export interface AnumatiRecord extends FBData {}

export interface AnumatiById {
    [id: string]: AnumatiRecord;
}

export interface AnumatiStore {
    byId: AnumatiById;
    filtered: AnumatiRecord[];
}

export interface Config extends FBData {
    value: string;
}

export interface ConfigStore {
    [id: string]: Config;
}

export interface Notification extends FBData {
    type: string; // 'Info', 'ShriAmarVaani' // 'DarshanDates' // 'Felicitations' // 'SnackMessage' // 'AlertMessage' // 'FeatureAnnouncement' // 'HowToGuide'
    priority: number;
    order: number;
    content: {
        collectionType: string; // 'articles',
        refId: string; //'',
    };
    title: {
        [lang: string]: string;
    };
    text: {
        [lang: string]: string;
    };
    showFrequency: string; //'daily', // 'once' // 'startup' // 'home' // 48 (in hours)
    showUpfront: boolean;
    requireAck: boolean;
}

export interface NotificationsById {
    [id: string]: Notification;
}
export interface NotificationStore {
    byId: NotificationsById;
    filtered: Notification[];
}

export interface Quote extends FBData {
    type: string; // 'General', 'Special'
    text: {
        [lang: string]: string;
    };
}

export interface QuotesById {
    [id: string]: Quote;
}
export interface QuoteStore {
    byId: QuotesById;
    filtered: Quote[];
}

export interface HD extends FBData {
    text: {
        [lang: string]: string;
    };
}

export interface HDById {
    [id: string]: HD;
}
export interface HDStore {
    byId: HDById;
    filtered: HD[];
}

export enum DownloadStatus {
    Queued,
    InProgress,
    Complete,
}

export interface DownloadProgress {
    status: DownloadStatus;
    value: number;
    bytes: number;
    updatedAt: Date;
}

export interface DownloadState {
    progress?: DownloadProgress;
    exportProgress?: DownloadProgress;
    uri: string;
    forExport: boolean;
}

export interface OfflineState {
    audio: {
        [id: string]: DownloadState;
    };
}

export interface MomentRecord extends FBData {}

export interface MomentById {
    [id: string]: MomentRecord;
}

export interface MomentStore {
    byId: MomentById;
    filtered: MomentRecord[];
}

export interface IDCardRecord extends FBData {}

export interface IDCardById {
    [id: string]: IDCardRecord;
}

export interface IDCardStore {
    byId: IDCardById;
    filtered: IDCardRecord[];
}

export interface BookRecord extends FBData {}

export interface BookById {
    [id: string]: BookRecord;
}

export interface BookStore {
    byId: BookById;
    filtered: BookRecord[];
}

export interface SangatRecord extends FBData {}

export interface SangatById {
    [id: string]: SangatRecord;
}

export interface SangatStore {
    byId: SangatById;
    filtered: SangatRecord[];
}

export interface DhamRecord extends FBData {}

export interface DhamById {
    [id: string]: DhamRecord;
}

export interface DhamStore {
    byId: DhamById;
    filtered: DhamRecord[];
}

export interface PincodeRecord extends FBData {}

export interface PincodeById {
    [id: string]: PincodeRecord;
}

export interface PincodeStore {
    byId: PincodeById;
    filtered: PincodeRecord[];
}

export interface ThroughRecord extends FBData {}

export interface ThroughById {
    [id: string]: ThroughRecord;
}

export interface ThroughStore {
    byId: ThroughById;
    filtered: ThroughRecord[];
}

export interface RateRecord extends FBData {}

export interface RateById {
    [id: string]: RateRecord;
}

export interface RateStore {
    byId: RateById;
    filtered: RateRecord[];
}

export interface CategoryRecord extends FBData {}

export interface CategoryById {
    [id: string]: CategoryRecord;
}

export interface CategoryStore {
    byId: CategoryById;
    filtered: CategoryRecord[];
}

export interface SubscriberRecord extends FBData {}

export interface SubscriberById {
    [id: string]: SubscriberRecord;
}

export interface SubscriberStore {
    byId: SubscriberById;
    filtered: SubscriberRecord[];
}

export interface AppDataState {
    userStore: UserStore;
    fbUserPlaylists: FBUserPlaylists;
    articles: FBArticleStore;
    configs: ConfigStore;
    mjbj: MjBjStore;
    anumati: AnumatiStore;
    kutiya: KutiyaStore;
    trustee: TrusteeStore;
    moment: MomentStore;
    idcard: IDCardStore;
    book: BookStore;
    sangat: SangatStore;
    dham: DhamStore;
    rate: RateStore;
    pincode: PincodeStore;
    category: CategoryStore;
    through: ThroughStore;
    subscriber: SubscriberStore;
}

export type LocaleType = "en" | "hi";
export interface AppParams {
    appId: string;
    appEnv: string;
    appVersion: number;
    appBuildCode: number;
    webappBuildVersion: number;
}

export interface AppUxState {
    locale: LocaleType;
    bookletCurrentIndex: {
        [id: string]: number;
    };
    activeNotifications: number;
    settings: { [key: string]: any };
    appParams: AppParams;
    sort: {
        [recordType: string]: string;
    };
    filters: {
        [recordType: string]: any;
    };
    acknowledge: {
        [id: string]: number;
    };
}

export type NetStatus = "online" | "offline";

export enum AudioCurrentState {
    Stopped,
    Initializing,
    Playing,
    Paused,
    Ended,
    Buffering,
    Error,
    Seeking,
}

export interface AudioPlaybackState {
    currentState: AudioCurrentState;
    duration?: number;
    currentTime: number;
    repeat: boolean;
    isMiniPlayerMode: boolean;
}

export interface AudioPlaybackDetails {
    articleList: FBArticle[];
    currentIndex: number;
}

export interface AppTempState {
    isLoading: number;
    isDrawerOpen: boolean;
    bottomDrawer: { open: boolean; content?: any };
    alertDialog: { open: boolean; content?: any };
    onDemandPage: { open: boolean; component?: any };
    appBarTitle: string;
    netStatus: NetStatus;
    audioPlaybackDetails: AudioPlaybackDetails | null;
    audioPlaybackState: AudioPlaybackState;
    notifications: any[];
    searchTerm: string;
    showAdminPreview: boolean;
}

export interface Operation {
    key: string;
    value: any;
    createdAt: Date;
    updatedAt: Date;
}

export interface OpsState {
    [key: string]: Operation;
}

export interface ApplicationState {
    router: RouterState<any>;
    dataState: AppDataState;
    uxState: AppUxState;
    tempState: AppTempState;
    opsState: OpsState;
    offlineState: OfflineState;
}

export interface RouteProps {
    icon?: React.ReactElement;
    primary: string;
    to: string;
    title?: string;
    page?: string;
    state?: any;
    roles?: { [key: string]: string };
}

export interface RouteGroup {
    title?: string;
    routes: RouteProps[];
}

export enum ArticleType {
    Bhajan,
    Satsang,
    ShriAarti,
    ShriAmarVani,
}

export enum ContentType {
    Article,
    Publication,
    Edition,
    WebApp,
    Calendar,
    PKP,
    SGM,
    Data,
}
