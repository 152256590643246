import { isOnlyMjBjSite } from "config";
import React from "react";
import "./anand.header.css";

const onlyMjBjCSS = isOnlyMjBjSite()
    ? `
.select-tile, .selection-btn, .sort-records-btn, #record_count, .find-anything {
    display: none;
}

#selection-dropdown select, .record-id {
    display: none;
}

.tile.mjbj-record .self-image {
    max-height: 70px;
}
`
    : ``;

const html = `
<style>
    ${onlyMjBjCSS}
</style>

	<div id="controls-header" class="container-fluid">
	</div>`;

class Component extends React.Component {
    render() {
        return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
    }
}

export const Header = Component;
