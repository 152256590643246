import { combineReducers } from 'redux';
import { TrusteeRecord, TrusteeStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateTrustee, UpdateTrusteeFiltered, UPDATE_TRUSTEE, UPDATE_TRUSTEE_FILTERED } from './actions';

export const trusteeByIdReducer = (state = {}, action: UpdateTrustee) => {
	switch (action.type) {
		case UPDATE_TRUSTEE:
			return updateData(state, action.changes, 'trustee');
		default:
			return state;
	}
};

const filteredTrusteeReducer = (state: TrusteeRecord[] = [], action: UpdateTrusteeFiltered) => {
	switch (action.type) {
		case UPDATE_TRUSTEE_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const trusteeReducer = combineReducers<TrusteeStore>({
	byId: trusteeByIdReducer,
	filtered: filteredTrusteeReducer,
});
