import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { DhamRecord } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_DHAM = '@@mjbj/UPDATE_DHAM';
export type UPDATE_DHAM = typeof UPDATE_DHAM;

export interface UpdateDham extends Action {
	type: UPDATE_DHAM;
	changes: FBChanges;
}

export function updateDham(changes: FBChanges): UpdateDham {
	return {
		type: UPDATE_DHAM,
		changes,
	};
}

export const UPDATE_DHAM_FILTERED = UPDATE_DHAM + '_FILTERED';
export type UPDATE_DHAM_FILTERED = typeof UPDATE_DHAM_FILTERED;

export interface UpdateDhamFiltered extends Action {
	type: UPDATE_DHAM_FILTERED;
	payload: DhamRecord[];
}
