export const SiteOwner = process.env.REACT_APP_SITE_OWNER ?? 'SAPD';
export const IsOwnerSAPD = SiteOwner === 'SAPD';

export const OrgName = IsOwnerSAPD ? 'Shri Anandpur Dham' : 'Shri Prayagdham';
export const OrgNameHi = IsOwnerSAPD ? 'श्री आनन्दपुर धाम' : 'श्री प्रयागधाम';
export const AppName = 'Shri Anandpur Satsang';
export const OrgLogoSrc = IsOwnerSAPD ? 'Shri_AnandpurDham_Logo.png' : 'Shri_Prayagdham_Logo.png';
export const IntroImg1Src = IsOwnerSAPD ? 'Shri_AnandpurDham_Holy_Gate.jpg' : 'Shri_Prayagdham_Holy_Gate.jpg';
export const IntroImg2Src = IsOwnerSAPD ? 'Shri_AnandpurDham_Temples.jpg' : 'Shri_Prayagdham_Temple.jpg';

export const SSDN = 'श्रीसद्गुरुदेवाय नम:';
export const MainRhyme =
	"<rhyme class='content-inline-rhymes locale-hi' data-lang='hi'><rhyme-line class='rhyme-line'>श्री आरती-पूजा सत्संग सेवा, सुमिरण और ध्यान<hide>।</hide>।<hide>।</hide></rhyme-line><rhyme-line class='rhyme-line'>श्रद्धा सहित सेवन करे, निश्चय हो कल्याण<hide>।</hide>।।</rhyme-line></rhyme>";
export const MainRhyme4 =
	"<rhyme class='content-inline-rhymes locale-hi' data-lang='hi'><rhyme-line class='rhyme-line'>श्री आरती-पूजा सत्संग सेवा,<hide>।।।।।</hide></rhyme-line><rhyme-line class='rhyme-line'><hide>।।।।।</hide>सुमिरण और ध्यान<hide>।</hide>।<hide>।</hide></rhyme-line><rhyme-line class='rhyme-line'>श्रद्धा सहित सेवन करे,<hide>।।।।।</hide></rhyme-line><rhyme-line class='rhyme-line'><hide>।।।।।</hide>निश्चय हो कल्याण<hide>।</hide>।।</rhyme-line></rhyme>";
export const BJK = '।। बोलो जयकारा बोल मेरे श्री गुरु महाराज की जय ।।';
export const COPYRIGHT = 'All rights reserved, © 2020';

export const SHARE_ORIGIN = IsOwnerSAPD ? 'https://www.shrianandpur.org/?r=' : 'https://www.shriprayagdham.org/?r=';

export const LangMap = {
	hi: 'Hindi',
	en: 'English',
	si: 'Sindhi',
	pu: 'Punjabi',
	hien: 'Hindi & English'
};

export const ArticleTypeMap = {
	bhajan: 'Bhajan',
	satsang: 'Satsang',
	shriamarvani: 'Shri Amar Vani',
	shriaarti: 'Pooja Ke Phool',
	introduction: 'Introduction',
	notice: 'Notification',
	shridarshan: 'Shri Darshan',
	policies: 'Policies',
	sandesh: 'Sandesh',
	'': '',
};

export const MediaTypeMap = {
	audio: 'Audio',
	text: 'Text',
	video: 'Video'
};

export const LocaleIdMap = {
	1: 'en',
	2: 'hi',
};

export const DBConfig = {
	IntroductionBooklet: IsOwnerSAPD ? 'IntroductionBooklet' : 'SPDIntroductionBooklet',
	Calendars: 'Calendars',
	Calendar: 'Calendar',
	Festivals: 'Festivals',
	FAQ: 'FAQ',
	Terms: IsOwnerSAPD ? 'Terms' : 'SPDTerms',
	Privacy: IsOwnerSAPD ? 'Privacy' : 'SPDPrivacy',
	DonationMail: 'DonationMail',
	PostalContact: IsOwnerSAPD ? 'PostalContact' : 'SPDPostalContact',
	Snapshot: 'Snapshot',
	AutoDownload: 'AutoDownload',
	LatestBuildVersion: IsOwnerSAPD ? 'LatestBuildVersion' : 'LatestBuildVersionSPD',
	UpdateTimes: 'UpdateTimes',
	UpdatedAt: (collection) => collection + 'UpdatedAt'
};

export const DefaultUserSettings = { push: true, quote: true };

export const ssnStartingCardKey = "--starting-card--";
export const ssnDefaultPublishKey = "--default-publish--";
export const ssnDefaultExpiryKey = "--default-expiry--";

export const isOnlyMjBjSite = () => {
	let roles = localStorage.getItem("r");
	if (roles) {
		roles = JSON.parse(roles);
		if (roles.indexOf('mjbj_lview') >= 0) {
			return true;
		}
	}

	return window.location.hostname.indexOf('mjbj.') === 0;
};