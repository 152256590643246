import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { SangatRecord } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_SANGAT = '@@mjbj/UPDATE_SANGAT';
export type UPDATE_SANGAT = typeof UPDATE_SANGAT;

export interface UpdateSangat extends Action {
	type: UPDATE_SANGAT;
	changes: FBChanges;
}

export function updateSangat(changes: FBChanges): UpdateSangat {
	return {
		type: UPDATE_SANGAT,
		changes,
	};
}

export const UPDATE_SANGAT_FILTERED = UPDATE_SANGAT + '_FILTERED';
export type UPDATE_SANGAT_FILTERED = typeof UPDATE_SANGAT_FILTERED;

export interface UpdateSangatFiltered extends Action {
	type: UPDATE_SANGAT_FILTERED;
	payload: SangatRecord[];
}
