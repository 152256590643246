import { generateRecordId, setRecord } from 'fb';
import firebase from 'fb/initconfig';
import { getUserPermissionsHelper, isAdmin } from 'helpers';
import { Context } from 'helpers/context';
import localforage from 'localforage';
import { signout } from 'services/api/auth';
import { store } from 'store';
import { ApplicationState } from 'types';
import { anand } from './anand';

declare global {
	var TAFFY: any;
}

export class ModelClass {
	private static modelMap: { [module: string]: ModelClass } = {};

	public static Instance(moduleName: string) {
		if (!ModelClass.modelMap[moduleName]) {
			ModelClass.modelMap[moduleName] = new ModelClass();
		}

		return ModelClass.modelMap[moduleName];
	}

	private stateMap: any = {
		records: null,
		DB: null,
		filters: {},
		state: undefined
	};

	public initModule(options, callback) {
		this.stateMap = {
			records: null,
			DB: null,
			filters: {},
			state: store.getState()
		};

		this.stateMap.options = options;
		this.stateMap.systemId = options.systemId;
		this.stateMap.forceUpdate = options.forceUpdate;

		this.stateMap.filters = {};
		// this.stateMap.records = null;
		// if (this.stateMap.DB) {
		// 	// stateMap.DB().remove();
		// 	this.stateMap.DB = null;
		// }

		// if (anand.workOffline() === true) {
		if (callback !== undefined) {
			callback();
		}
		// } else {
		// 	anand.data.initModule(callback);
		// }
	}

	private mergeRecords(newRecords, callback) {
		if (this.stateMap.DB !== null) {
			if (newRecords && newRecords.length > 0) {
				this.stateMap.DB.merge(newRecords, 'id');
				callback();
			} else if (callback) {
				callback();
			}
			return;
		}

		this.stateMap.DB = TAFFY(newRecords);
		this.stateMap.DB.settings({ template: { selected: false }, cacheSize: 0 });
		callback();
	}

	private loadRecords(callback) {
		let state: ApplicationState = store.getState();
		let data = state.dataState[this.stateMap.systemId]?.filtered ?? [];
		// data = data.map((record) => {
		// 	if (record.card_no) {
		// 		record.card_no = record.card_no.toString();
		// 	}
		// 	return record;
		// });
		this.mergeRecords(data, () => {
			this.stateMap.records = true;
			callback();
		});
	}

	public getRecords(filters, sortOrder, callback?, returnCollection?, forceReload?, onlyActive = true) {
		if (onlyActive !== false) {
			filters.push({ status: 'active' });
		}
		this.stateMap.filters = filters;

		returnCollection = returnCollection || false;

		if (!forceReload && this.stateMap.records !== null) {
			var data = this.stateMap.DB.apply(this, this.stateMap.filters).order(sortOrder);
			if (!returnCollection) {
				data = data.get();
			}
			if (typeof callback !== 'undefined' && callback !== null) {
				callback(data);
			} else {
				return data;
			}
		} else {
			let stateMap = this.stateMap;
			this.loadRecords(function (this: void) {
				var data = stateMap.DB.apply(this, stateMap.filters).order(sortOrder);
				if (!returnCollection) {
					data = data.get();
				}

				if (typeof callback !== 'undefined' && callback !== null) {
					callback(data);
				}
			});
		}
	}

	private prepareRecord(record, isAdd) {
		var value;
		for (var key in record) {
			value = record[key];

			if ($.trim(value) === '' || $.trim(value).toLowerCase() === 'null') {
				record[key] = null;
			}
		}

		if (this.stateMap.options.hasContacts) {
			// Handle Contacts
			let contactTypes = record['contact_type'];
			let contacts = record['contact'];

			let contactObj = {};

			for (let i = 0; i < contacts?.length; i++) {
				if (!contactTypes[i] || !contacts[i]) {
					continue;
				}

				if (!contactObj[contactTypes[i]]) {
					contactObj[contactTypes[i]] = [];
				}

				if (contactTypes[i] === 'Mobile' && !contacts[i].startsWith('+')) {
					contacts[i] = '+91' + contacts[i];
				}
				contactObj[contactTypes[i]].push(contacts[i]);
			}

			if (Object.keys(contactObj).length) {
				if (!contactObj['Mobile']) {
					contactObj['Mobile'] = firebase.firestore.FieldValue.delete();
				}
				if (!contactObj['Email']) {
					contactObj['Email'] = firebase.firestore.FieldValue.delete();
				}
				if (!contactObj['Landline']) {
					contactObj['Landline'] = firebase.firestore.FieldValue.delete();
				}
				record['contacts'] = contactObj;
			} else {
				record['contacts'] = null;
			}
			delete record['contact'];
			delete record['contact_type'];
		}

		if (typeof this.stateMap.options.prepareRecord !== 'undefined') {
			let admin = isAdmin(store.getState().dataState.userStore.userData);
			record = this.stateMap.options.prepareRecord(record, isAdd, admin);
		}

		delete record['fileInputs'];
		return record;
	}

	public addRecord(record, callback) {
		let range = store.getState().dataState.userStore.userData?.range ?? { min: 70000, max: 100000 };

		let recordsInRange = this.stateMap.DB({ id: { gte: range.min } }, { id: { lte: range.max } });
		if (!record['id']) {
			let recordId = generateRecordId(this.stateMap.systemId); //(parseInt(recordsInRange.max('id') ?? `${range.min - 1}`) + 1).toString(); //generateRecordId(this.stateMap.systemId);
			record['id'] = recordId;
		}

		record = this.prepareRecord(record, true);

		record['status'] = 'active';
		record['active'] = true;
		record['deleted'] = false;

		record['createdAt'] = firebase.firestore.FieldValue.serverTimestamp();
		record['publishTime'] = record.publishTime ?? firebase.firestore.FieldValue.serverTimestamp();
		record['expireTime'] = record.expireTime ?? firebase.firestore.Timestamp.fromDate(new Date('9999-12-31'));

		let fullname = (store.getState() as ApplicationState).dataState.userStore.userData.fullName;

		setRecord(this.stateMap.systemId, record['id'], record, fullname).then(() => {});
		this.stateMap.DB.insert(record);
		callback(record);
	}

	public editRecord(record, recordId, callback) {
		record = this.prepareRecord(record, false);
		setRecord(this.stateMap.systemId, recordId, record).then(() => {});
		this.stateMap.DB({ id: recordId }).update(record);
		callback();
	}

	public deleteRecord(record, recordId, callback) {
		record['deleted'] = true;
		setRecord(this.stateMap.systemId, recordId, { deleted: true }).then(() => {});
		this.stateMap.DB({ id: recordId }).remove();
		callback();
	}

	public logout(callback) {
		(store.dispatch as any)(signout({}, new Context()));
		callback();
	}

	public getUserPermissions(systemId, callback) {
		var data = getUserPermissionsHelper(store, systemId);

		callback(data);
	}

	public getImageDataUri(imageId, callback) {
		return;
	}

	public customCall(url, data, onSuccess) {
		if (anand.workOffline() === true) {
			throw new Error('Custom Call While Offline');
		} else {
			anand.data.request({
				url: url,
				data: data,
				type: 'post',
				success: onSuccess,
			});
		}
	}

	public async markAll(records, unmark, date, callback?) {
		for (var i = 0; i < records.length; i++) {
			let recordId = records[i].id;
			let record = { markings: records[i].markings };

			let existingMarkingIndex = -1;
			for (let i = 0; i < record.markings?.length; i++) {
				if (record.markings[i].mark_date === date) {
					existingMarkingIndex = i;
					break;
				}
			}

			if (unmark) {
				if (existingMarkingIndex >= 0) {
					record.markings = firebase.firestore.FieldValue.arrayRemove(record.markings[existingMarkingIndex]);
				}
			} else {
				record.markings = record.markings ?? [];
				let marking = { mark_count: records[i].copies, mark_date: date };
				if (existingMarkingIndex >= 0) {
					record.markings[existingMarkingIndex] = marking;
				} else {
					record.markings.push(marking);
				}
			}

			await setRecord(this.stateMap.systemId, recordId, record);
			this.stateMap.DB({ id: recordId }).update(record);

			if (callback) {
				callback();
			}
		}
	}

	public updateLocalRecord(recordId, record, callback?) {
		this.stateMap.DB({ id: recordId }).update(record);
		this.stateMap.records = this.stateMap.DB().get();
		localforage.setItem(this.stateMap.systemId + '_records', this.stateMap.records, callback);
	}

	public onUpdateRecord(systemId, record) {
		if (this.stateMap.systemId === systemId && this.stateMap.records) {			
			let userData = this.stateMap.state.dataState.userStore.userData;
			let settings = userData?.settings;			
			let showDeleted = (settings && settings['admin.records']) ?? false;

			if (record.deleted === false || showDeleted) {
				this.stateMap.DB.merge([record], 'id');
			} else {
				this.stateMap.DB({ id: record.id }).remove();
			}			
		}
	}
}

// export const Model = ModelClass();
