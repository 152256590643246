import { isOnlyMjBjSite } from "config";
import { Records } from "core/anand.records";
import { isMobile } from "helpers";

function addCheckbox(sampleTile, record) {
    var selectTileCheckbox = $("<div class='select-tile hidden-xs hidden-sm'><input type='checkbox'></input></div>");

    selectTileCheckbox.find("input").click(async function (e) {
        record.selected = !record.selected;
        if (record.selected) {
            sampleTile.addClass("selected");
        } else {
            sampleTile.removeClass("selected");
        }

        if (Records.isSelectionFilterPresent()) {
            Records.refreshRecords(undefined, undefined, undefined, true);
        }
    });

    if (typeof record.selected === "undefined") {
        record.selected = false;
    } else if (record.selected) {
        selectTileCheckbox.find("input").attr("checked", "checked");
        sampleTile.addClass("selected");
    }

    sampleTile.append(selectTileCheckbox);
}

export async function populateViewTileHelper(tileRef, record, index, listRef) {
    let sampleTile = $(tileRef.current);

    sampleTile.attr("id", "detail-record-" + record[Records.IdFieldName]);
    sampleTile.find(".record-id span").html((index + 1).toString());
    sampleTile.attr("data-index", index);

    sampleTile.find(".tile").css("background-color", index % 2 ? "white" : "#f2dede");

    if (isMobile() || isOnlyMjBjSite()) {
        await Records.populateBriefTile(sampleTile, record);
    } else {
        await Records.populateDetailTile(sampleTile, record);
        addCheckbox(sampleTile, record);
    }

    Records.highlightSearchTerms(sampleTile);

    sampleTile.find(".tile").click(Records.expandTile);

    (record as any).height = sampleTile.outerHeight();
    (listRef?.current as any)?.resetAfterIndex(index);

    return sampleTile;
}
