import { combineReducers } from 'redux';
import { CategoryRecord, CategoryStore } from 'types';
import { updateData } from '../../generic/reducers';
import { UpdateCategory, UpdateCategoryFiltered, UPDATE_CATEGORY, UPDATE_CATEGORY_FILTERED } from './actions';

export const categoryByIdReducer = (state = {}, action: UpdateCategory) => {
	switch (action.type) {
		case UPDATE_CATEGORY:
			return updateData(state, action.changes, 'category');
		default:
			return state;
	}
};

const filteredCategoryReducer = (state: CategoryRecord[] = [], action: UpdateCategoryFiltered) => {
	switch (action.type) {
		case UPDATE_CATEGORY_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const categoryReducer = combineReducers<CategoryStore>({
	byId: categoryByIdReducer,
	filtered: filteredCategoryReducer,
});
