import { isMobile } from 'helpers';
import { populateViewTileHelper } from 'helpers/RecordsHelper';
import React from 'react';
import { connect } from 'react-redux';
import { AnumatiRecord, ApplicationState } from 'types';

const html = `
<div id="trustee-detail-record" class="tile trustee-record detail">
	<div class="record-id">
		<span></span>
	</div>
	<div class="row">
		<div class="col-xs-12 col-md-2">
			<div class="block image-upload-container">
				<img class="self-image button" />
			</div>
		</div>
		<div class="col-xs-12 col-md-3 main-details">
			<div class="block">
				<div class="row self-detail">
					<div class="title col-xs-3 col-md-3">
						Name:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row so-detail">
					<div class="title col-xs-3 col-md-3">
						S/o:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row holy-name-detail">
					<div class="title col-xs-3 col-md-3 no-l-padding">
						HolyName:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row address-detail">
					<div class="title col-xs-3 col-md-3">
						Address:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row pan-detail">
					<div class="title col-xs-3 col-md-3">
						Pan:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row comments-detail">
					<div class="title col-xs-3 col-md-3">
						Comment:
					</div>
					<div class="value col-xs-9 col-md-9">
					</div>
				</div>
				<div class="row contact-detail">
					<div class="title col-xs-12">
						Contacts:
					</div>
					<div id="contact-type-value" class="multi-field-row col-xs-12 col-md-12">
						<div class="row">
							<div class="col-xs-4 contact-type"></div>
							<div class="col-xs-8 contact-value"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-md-7 main-details">
			<div class="block">
				<div class="row trustee-detail">
					<div class="title col-xs-12">
						Trusts:
					</div>
					<div id="trustee-row" class="multi-field-row value col-xs-12 col-md-6">
						<div class="col-xs-3 strong no-l-padding">Trust:</div>
						<div class="col-xs-9 trust-name"></div>
						<div class="col-xs-3 strong no-l-padding">Serial:</div>
						<div class="col-xs-9 serial-no"></div>
						<div class="col-xs-3 strong no-l-padding">Designation:</div>
						<div class="col-xs-9 designation"></div>
						<div class="col-xs-3 strong no-l-padding">From:</div>
						<div class="col-xs-9 from-date"></div>
						<div class="col-xs-3 strong no-l-padding to-label">To:</div>
						<div class="col-xs-9 to-date"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
`;

let briefHtml = `
<div id="trustee-brief-record" class="tile trustee-record brief">
	<div class="row">
		<div class="col-xs-2 no-r-padding">
			<img class="self-image" />           						
		</div>
		<div class="col-xs-10">
			<div class="row self-detail">
				<div class="title col-xs-2 col-md-3 no-padding align-right">

				</div>
				<div class="value col-xs-10 col-md-9">

				</div>
			</div>           						
			<div class="row address-detail">
				<div class="title col-xs-2 col-md-3 no-padding align-right">
					Address:
				</div>
				<div class="value col-xs-10 col-md-9">

				</div>
			</div>
		</div>
	</div>
	<div class="record-id">
		<span></span>
	</div>
</div>
`;

interface Props {
	record?: AnumatiRecord;
	index?: number;
	listRef?: React.RefObject<any>;
}

class Component extends React.PureComponent<Props> {
	private tileRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	componentDidUpdate(prevProps, prevState) {
		populateViewTileHelper(this.tileRef, this.props.record, this.props.index, this.props.listRef);
	}

	render() {
		return <div ref={this.tileRef} dangerouslySetInnerHTML={{ __html: isMobile() ? briefHtml : html }}></div>;
	}
}

function mapStateToProps(state: ApplicationState) {
	return {};
}

export const TrusteeViewTile = connect(mapStateToProps)(Component);
