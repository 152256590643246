import { logEvent } from 'components/telemetry';
import 'firebase/storage';
import firebase, { firebaseConfig } from './initconfig';

export const uploadFile = async (path: string, file: File, progressCallback?: (progress: number) => void) => {
	return new Promise((resolve, reject) => {
		try {
			var storageRef = firebase.storage().ref();
			var fileRef = storageRef.child(path);

			var metadata = {
				contentType: file.type,
				cacheControl: 'private, max-age=3600',
			};

			let uploadTask = fileRef.put(file, metadata);

			uploadTask.on(
				firebase.storage.TaskEvent.STATE_CHANGED,
				function (snapshot) {
					var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					progressCallback && progressCallback(progress);

					if (progress === 100) {
						logEvent('storage_upload', { path, totalBytes: snapshot.totalBytes });
						resolve(100);
					}
				},
				function (error: any) {
					switch (error.code) {
						case 'storage/unauthorized':
							// User doesn't have permission to access the object
							break;

						case 'storage/canceled':
							// User canceled the upload
							break;

						case 'storage/unknown':
							// Unknown error occurred, inspect error.serverResponse
							break;
					}

					logEvent('storage_upload_error', { path, ...error });
					reject(error);
				},
				function () {
					// Upload completed successfully, now we can get the download URL
					// uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
					// 	console.log('File available at', downloadURL);
					// });
				}
			);
		} catch (error) {
			logEvent('storage_upload_error', { path, ...error });
			reject(error);
		}
	});
};

export const deleteFile = async (path: string) => {
	try {
		var storage = firebase.storage();
		var pathReference = storage.ref(path);

		await pathReference.delete();
		logEvent('storage_delete', { path });
	} catch (error) {
		logEvent('storage_delete_error', { path, ...error });
	}
};

let downloadUrlCache = {};
export const getDownloadUrl = async (path: string, force: boolean = false) => {
	var storage = firebase.storage();
	var pathReference = storage.ref(path);

	if (!(downloadUrlCache['--version--'] ?? localStorage.getItem('--version--'))) {
		localStorage.clear();
		localStorage.setItem('--version--', '20210625');
		downloadUrlCache['--version--'] = '20210625';
	}

	try {
		let key = 'download_url_' + path.replaceAll('/', '');
		let downloadUrl = downloadUrlCache[key] ?? localStorage.getItem(key);
		if (!downloadUrl || force) {
			downloadUrl = await pathReference.getDownloadURL();
			localStorage.setItem(key, downloadUrl);
			downloadUrlCache[key] = downloadUrl;
		}
		return downloadUrl;
	} catch (error) {
		// A full list of error codes is available at
		// https://firebase.google.com/docs/storage/web/handle-errors
		switch (error.code) {
			case 'storage/object-not-found':
				// File doesn't exist
				break;

			case 'storage/unauthorized':
				// User doesn't have permission to access the object
				break;

			case 'storage/canceled':
				// User canceled the upload
				break;

			case 'storage/unknown':
				// Unknown error occurred, inspect the server response
				break;
		}
		logEvent('storage_download_error', { path, ...error });
	}
};

export const getStorageUrl = (path: string) => {
	path = path.replace(/\//g, '%2F');

	return 'https://firebasestorage.googleapis.com/v0/b/' + firebaseConfig.storageBucket + '/o/' + path + '?alt=media';
};
