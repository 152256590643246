import { combineReducers } from 'redux';
import { KutiyaRecord, KutiyaStore } from 'types';
import { updateData } from '../generic/reducers';
import { UpdateKutiya, UpdateKutiyaFiltered, UPDATE_KUTIYA, UPDATE_KUTIYA_FILTERED } from './actions';

export const kutiyaByIdReducer = (state = {}, action: UpdateKutiya) => {
	switch (action.type) {
		case UPDATE_KUTIYA:
			return updateData(state, action.changes, 'kutiya');
		default:
			return state;
	}
};

const filteredKutiyaReducer = (state: KutiyaRecord[] = [], action: UpdateKutiyaFiltered) => {
	switch (action.type) {
		case UPDATE_KUTIYA_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const kutiyaReducer = combineReducers<KutiyaStore>({
	byId: kutiyaByIdReducer,
	filtered: filteredKutiyaReducer,
});
