import { combineReducers } from 'redux';
import { SubscriberRecord, SubscriberStore } from 'types';
import { updateData } from '../../generic/reducers';
import { UpdateSubscriber, UpdateSubscriberFiltered, UPDATE_SUBSCRIBER, UPDATE_SUBSCRIBER_FILTERED } from './actions';

export const subscriberByIdReducer = (state = {}, action: UpdateSubscriber) => {
	switch (action.type) {
		case UPDATE_SUBSCRIBER:
			return updateData(state, action.changes, 'subscriber');
		default:
			return state;
	}
};

const filteredSubscriberReducer = (state: SubscriberRecord[] = [], action: UpdateSubscriberFiltered) => {
	switch (action.type) {
		case UPDATE_SUBSCRIBER_FILTERED:
			return action.payload;
		default:
			return state;
	}
};

export const subscriberReducer = combineReducers<SubscriberStore>({
	byId: subscriberByIdReducer,
	filtered: filteredSubscriberReducer,
});
