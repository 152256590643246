import { DefaultUserSettings, OrgName } from 'config';
import { RouterState } from 'connected-react-router';
import { ApplicationState, AudioCurrentState } from 'types';

export const initialState: ApplicationState = {
	router: undefined as any as RouterState,
	dataState: {
		articles: {
			byId: {},
			filtered: [],
		},
		mjbj: {
			byId: {},
			filtered: [],
		},
		anumati: {
			byId: {},
			filtered: [],
		},
		kutiya: {
			byId: {},
			filtered: [],
		},
		trustee: {
			byId: {},
			filtered: [],
		},
		moment: {
			byId: {},
			filtered: [],
		},
		idcard: {
			byId: {},
			filtered: [],
		},
		sangat: {
			byId: {},
			filtered: [],
		},
		book: {
			byId: {},
			filtered: [],
		},
		dham: {
			byId: {},
			filtered: [],
		},
		rate: {
			byId: {},
			filtered: [],
		},
		pincode: {
			byId: {},
			filtered: [],
		},
		subscriber: {
			byId: {},
			filtered: [],
		},
		through: {
			byId: {},
			filtered: [],
		},
		category: {
			byId: {},
			filtered: [],
		},
		userStore: {},
		fbUserPlaylists: {}, // when user signs up first time, we'll create default records
		configs: {},
	},
	uxState: {
		locale: 'hi',
		bookletCurrentIndex: {},
		activeNotifications: 0,
		settings: DefaultUserSettings,
		appParams: { appId: '', appEnv: '', appVersion: -1, appBuildCode: -1, webappBuildVersion: 0 },
		sort: {},
		filters: {},
		acknowledge: {},
	},
	tempState: {
		isLoading: 0,
		isDrawerOpen: false,
		bottomDrawer: { open: false },
		alertDialog: { open: false },
		onDemandPage: { open: false },
		appBarTitle: OrgName,
		netStatus: 'online',
		audioPlaybackDetails: null,
		audioPlaybackState: {
			currentState: AudioCurrentState.Initializing,
			duration: undefined,
			currentTime: 0,
			repeat: false,
			isMiniPlayerMode: false,
		},
		notifications: [],
		searchTerm: '',
		showAdminPreview: false,
	},
	opsState: {},
	offlineState: { audio: {} },
};
