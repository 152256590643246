import { Avatar, Switch } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import { lime } from "@material-ui/core/colors";
import { Theme, createStyles, alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import { Header } from "components/header/header";
import { logEvent } from "components/telemetry";
import { isOnlyMjBjSite } from "config";
import { Icons } from "config/icons";
import { getWebAppAssetPath, isAdmin, isMobile } from "helpers";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { openDrawer, searchFor, showAdminPreview } from "store/temp/actions";
import { ApplicationState, UserData } from "types";
import { AppBarTitle } from "./AppBarTitle";
import AppDrawer from "./AppDrawer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(1),
        },
        title: {
            flexGrow: 1,
        },
        search: {
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            "&:hover": {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            // marginRight: theme.spacing(2),
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(3),
                width: "auto",
            },
        },
        searchIcon: {
            width: theme.spacing(7),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        searchClose: {
            color: theme.palette.common.white,
        },
        inputRoot: {
            color: "inherit",
            width: "100%",
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 1),
            transition: theme.transitions.create("width"),
            width: "100%",
            [theme.breakpoints.up("md")]: {
                width: 200,
            },
        },
        sectionDesktop: {
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "flex",
            },
        },
        sectionMobile: {
            display: "flex",
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        root: {
            display: "flex",
            alignItems: "center",
            width: "100%",
        },
        input: {
            flex: 1,
            color: theme.palette.common.white,
        },
        iconButton: {
            color: theme.palette.common.white,
        },
        divider: {
            height: 28,
            margin: 4,
            backgroundColor: theme.palette.common.white,
        },
        userpic: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
    })
);

interface AppBarProps {
    onClickMenu: () => void;
    isAdmin: boolean;
    searchFor: (term: string) => void;
    history: any;
    user?: firebase.default.User;
    userData?: UserData;
    showPreview: boolean;
    showAdminPreview: (value: boolean) => void;
}

const PurpleSwitch = withStyles({
    switchBase: {
        // color: grey[300],
        "&$checked": {
            color: lime["A400"],
        },
        "&$checked + $track": {
            backgroundColor: lime[900],
        },
    },
    checked: {},
    track: {},
})(Switch);

function PrimarySearchAppBar(props: AppBarProps) {
    const classes = useStyles();
    const [showSearchBar, setShowSearchBar] = React.useState<Boolean>(false);
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    let history = props.history;

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            // location is an object like window.location
            onCloseSearchBar();
            // console.log(action, location.pathname, location.state)
        });

        return () => {
            unlisten();
        };
    }, []);

    const onClickAccountBtn = () => {
        history.push("/account", {
            isModal: true,
        });
    };

    const onClickSearchBtn = (event: React.MouseEvent<HTMLElement>) => {
        setShowSearchBar(true);
    };

    const onCloseSearchBar = () => {
        props.searchFor("");
        setSearchTerm("");
        setShowSearchBar(false);
    };

    const onClickMenu = () => {
        onCloseSearchBar();
        props.onClickMenu();
    };

    let allowSearch = !!history.location.state?.allowSearch ?? false;

    let searchAllowed = ["/admin/articles", "/admin/rhymes", "/bhajan", "/satsang", "/shriamarvani", "/rhymes"];
    allowSearch = allowSearch || searchAllowed.indexOf(history.location.pathname) >= 0;

    let photoUrl = props.userData?.photoUrl || props.user?.photoURL || (props.user && getWebAppAssetPath("app_icon.png"));

    return (
        <div className={classes.grow}>
            <AppBar position='fixed'>
                <Toolbar>
                    {!isOnlyMjBjSite() && (
                        <IconButton onClick={onClickMenu} edge='start' className={classes.menuButton} color='inherit'>
                            {Icons.Menu}
                        </IconButton>
                    )}

                    <React.Fragment>
                        {history.location.pathname === "/" ? (
                            <AppBarTitle title='Welcome' />
                        ) : (
                            <div id='header-container'>
                                <Header />
                            </div>
                        )}
                        <IconButton onClick={onClickAccountBtn} color='inherit' style={isMobile() ? { paddingRight: 0 } : {}}>
                            {photoUrl ? <Avatar src={photoUrl} variant='circular' className={classes.userpic} /> : Icons.Account}
                        </IconButton>
                    </React.Fragment>
                </Toolbar>
            </AppBar>
            <Toolbar className='app-bar-ph' />
            <AppDrawer />
        </div>
    );
}

function mapStateToProps(state: ApplicationState) {
    return {
        isAdmin: isAdmin(state.dataState.userStore.userData),
        user: state.dataState.userStore.user,
        userData: state.dataState.userStore.userData,
        showPreview: state.tempState.showAdminPreview,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onClickMenu: () => {
            dispatch(openDrawer());
        },
        searchFor: (term: string) => {
            if (term && term.length) {
                logEvent("search", term);
            }

            dispatch(searchFor(term));
        },
        showAdminPreview: (value: boolean) => {
            dispatch(showAdminPreview(value));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrimarySearchAppBar));
