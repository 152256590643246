import { logEvent } from 'components/telemetry';
import firebase from 'fb/index';
import { isApple, isSafari } from 'helpers';
import { appInterface } from 'store';
import { IAnandApp } from './IAnandApp';

export class JSAnandApp implements IAnandApp {
	private version: number = -1;
	private AnandApp: any;
	private callbacks = {};
	public appInstallationId;
	public buildVersionCode;

	public initialize = (webappBuildVersion?) => { };

	public getAppVersion = () => {
		return this.version;
	};

	public receiveFromApp(opName, data) { }

	public getBuildVersionCode = async () => {
		return -1;
	};

	public downloadMedia = (id, filename, src, force) => {
		this.downloadFile(src, filename, id);
	};

	public downloadFile = (url, filename, id?) => {
		if (isSafari()) {
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			a.target = '_blank';
			// the filename you want
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			return;
		}

		var xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		if (id) {
			xhr.addEventListener(
				'progress',
				function (evt) {
					if (evt.lengthComputable) {
						appInterface.didDownloadAudioData(id, 0, evt.loaded, evt.total);
						var percentComplete = (evt.loaded * 100) / evt.total;

						if (percentComplete >= 100) {
							appInterface.didDownloadMedia(id, true);
						}
					}
				},
				false
			);
		}

		xhr.onload = function (event) {
			console.log('xhronload');
			var blob = xhr.response;

			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			// the filename you want
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		};

		console.log(url);
		xhr.open('GET', url);
		xhr.send();
	};

	public getAppInstallationId = async () => {
		return 'Web';
	};

	public getAppDetailesJson = async () => { };

	public deleteMedia = (id, filename) => { };

	public loadGoogleSignin = async () => {
		try {
			let provider = new firebase.auth.GoogleAuthProvider();
			provider.addScope('email');
			provider.addScope('profile');
			let result = await firebase.auth().signInWithPopup(provider);
			appInterface.googleUserSignedIn(result);
		} catch (e) {
			appInterface.googleUserSignedIn(null, e);
		}
	};

	public gSignout = () => { };

	public shareLink = (title, link, type, id) => {
		logEvent('share', { content_type: type, item_id: id, link, title });
		window.prompt('Copy to clipboard: Ctrl+C, Enter:: ' + title, link);
	};

	public getAppEnv = () => {
		return 'Web';
	};

	public showToast = (toast) => {
		console.log(toast);
	};

	public showProgress = () => { };

	public hideProgress = () => { };

	public showSnack = (message) => {
		console.log(message);
	};

	public putToAppStorage = (key, value) => { };

	public removeFromAppStorage = (key) => { };

	public getFromAppStorage = async (key) => {
		return '';
	};

	public subscribeToTopic = (topic) => { };

	public unsubscribeFromTopic = (topic) => { };

	public playMedia = (audioId, src, filename, title, isFav, playAuto, startTime, albumTitle, artist) => { };

	public togglePlayPause = () => { };

	public downloadCurrentMedia = (title, force) => { };

	public deleteCurrentMedia = () => { };

	public stopAndRelease = () => { };

	public updateShuffleFlag = (shuffle) => { };

	public updateLoopFlag = (loop) => { };

	public seekToTime = (time) => { };

	public setPlaybackSpeed = (speed) => { };

	public forwardSkipInterval = () => { };

	public backwardSkipInterval = () => { };

	public exportMedia = (id, filename, src) => {
		this.downloadFile(src, filename, id);
	};

	public logEvent = (name, params) => {
		if (firebase.analytics && firebase.analytics.isSupported && firebase.analytics.isSupported()) {
			firebase.analytics().logEvent(name, params);
		}
	}

	public setUserProperty = (name, value) => {
		if (firebase.analytics && firebase.analytics.isSupported && firebase.analytics.isSupported()) {
			firebase.analytics().setUserProperties({ [name]: value });
		}
	}

	public clearAppData() {

	}

	public clearWebViewCache() {

	}
}
