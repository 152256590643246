import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { FBArticle } from 'types';
export const UPDATE_FB_ARTICLES = '@@records/UPDATE_FB_ARTICLES';
export type UPDATE_FB_ARTICLES = typeof UPDATE_FB_ARTICLES;

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export interface UpdateFBArticles extends Action {
	type: UPDATE_FB_ARTICLES;
	changes: FBChanges;
}

export const updateFBArticles = (changes: FBChanges): UpdateFBArticles => {
	return {
		type: UPDATE_FB_ARTICLES,
		changes,
	};
};

export const UPDATE_FB_ARTICLES_FILTERED = UPDATE_FB_ARTICLES + '_FILTERED';
export type UPDATE_FB_ARTICLES_FILTERED = typeof UPDATE_FB_ARTICLES_FILTERED;

export interface UpdateArticlesFiltered extends Action {
	type: UPDATE_FB_ARTICLES_FILTERED;
	payload: FBArticle[];
}
