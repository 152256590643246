import { HeaderControls, HeaderModule } from 'components/header/anand.header';
import { isOnlyMjBjSite } from 'config';
import { ModelClass } from 'core/anand.model';
import { notification as Notification } from 'core/anand.notification';
import { overlay_panel as OverlayPanel } from 'core/anand.overlay_panel';
import { Records } from 'core/anand.records';
import { utils as Utils } from 'core/anand.utils';
import firebase from '../../fb/initconfig';

const Highcharts = require('highcharts');

export const MjbjRecords = (function ($) {
	var configMap: any = {};

	var stateMap: any = {
		systemId: 'mjbj',
		uniqueGroups: [],
		selectedGroupFilters: [],
	};

	function initModule(props?, updateRecordsState?) {
		HeaderModule.initModule([
			// HeaderControls.dashboardBtn,
			HeaderControls.addRecordBtn,
			HeaderControls.selectDropdown,
			HeaderControls.printBtn,
			HeaderControls.sortBtn,
			HeaderControls.reminderBtn,
		]);

		Records.projectTitle = 'Holy Records of Mahatma Ji & Bai Ji';

		Records.getAnySearchFilter = getAnySearchFilter;
		Records.getReminderFilter = getReminderFilter;
		Records.defaultSortOrder = 'name';
		Records.IdentifyingFieldName = 'name';

		Records.viewAllowed = configMap.viewAllowed;
		Records.editAllowed = configMap.editAllowed;

		Records.updateRecordsState = updateRecordsState;

		Records.clearAllFilters = function () {
			$('select[name="main_filter_dropdown"]').val('AMB');
		};

		Records.sortingFieldsMap = {
			name: 'Name',
			'dob, title, name': 'DoB',
			'rahbar, title, name': 'Rahbar',
			'sewa, title, name': 'Sewa',
			'head, title, name': 'Head',
			'holydress_date, title, name': 'Holy Date',
			'samadhi_date, title, name': 'Samadhi Date',
		};

		Records.searchFieldsMap = isOnlyMjBjSite() ? {
			name: 'Name',
			old_name: 'Old Name',
			sewa: 'Sewa',
		} : {
			name: 'Name',
			old_name: 'Old Name',
			sewa: 'Sewa',
			rahbar: 'Rahbar',
			past_rahbar: 'Past Rahbar',
			comments: 'Comments',
			holydress_date: 'Holy Date',
			samadhi_date: 'Sam Date',
			dob: 'DoB',
			holydress_place: 'Holy Place',
			samadhi_place: 'Sam Place',
			head: 'Head',
			image: 'Picture',
			naamdaan: 'Naam',
			holydress_by: 'By',
		};

		Records.isSearchDateTypeColumn = function (searchBy) {
			if (searchBy === 'holydress_date' || searchBy === 'samadhi_date' || searchBy === 'dob') {
				return true;
			} else {
				return false;
			}
		};

		Records.printOptions = {
			fieldNames: [
				'S. No.',
				'Name',
				'Old Name',
				'Rahbar',
				'Past Rahbar',
				'Head',
				'Sewa',
				'DoB',
				'Naam',
				'Holy Date',
				'Holy Place',
				'Holy By',
				'Sam Date',
				'Sam Place',
				'Contacts',
				'Comments',
			],
			fieldKeys: [
				'id',
				'name',
				'old_name',
				'rahbar',
				'past_rahbar',
				'head',
				'sewa',
				'dob',
				'naamdaan',
				'holydress_date',
				'holydress_place',
				'holydress_by',
				'samadhi_date',
				'samadhi_place',
				'contacts',
				'comments',
			],
			defaultFieldWidths: {
				id: 50,
				name: 250,
				old_name: 180,
				rahbar: 250,
				past_rahbar: 0,
				head: 0,
				sewa: 220,
				dob: 0,
				naamdaan: 0,
				holydress_date: 100,
				holydress_place: 0,
				holydress_by: 0,
				samadhi_date: 0,
				samadhi_place: 0,
				contacts: 0,
				comments: 0,
			},
			defaultHeader: 'Holy Records of Mahatma Ji & Bai Ji',
			beforePrintHook: function (data, forExport) {
				var titleMap = {
					Mahatma: 'Mh.',
					Bai: 'Bai',
					Bhagat: 'Bh.',
					Dada: 'Dada',
					Dadi: 'Dadi',
					'': '',
				};

				var forSMS = $('select option[value="main_mobile"]').length > 0;
				var dataLength = data.length;
				for (var i = 0; i < dataLength; i++) {
					data[i]['id'] = i + 1 + '.';
					data[i]['title'] = titleMap[data[i]['title']];
					if (typeof forExport === 'undefined' || forExport === false) {
						data[i]['name'] = data[i]['title'] + ' ' + data[i]['name'] + ' Ji';
						if (data[i]['contacts']) {
							var contacts = data[i]['contacts'];
							data[i]['contacts'] = '';
							var eol = '';
							for (var contactType in contacts) {
								if (contactType === 'Mobile') {
									data[i]['main_mobile'] = contacts[contactType][0];
								}
								var contactValue = JSON.stringify(contacts[contactType]);
								data[i]['contacts'] += eol + contactType + ': ' + contactValue;
								eol = '\n';
							}
						}
						if (!data[i]['main_mobile']) {
							if (forSMS === true) {
								data.splice(i, 1);
								i--;
								dataLength--;
								continue;
							}
							data[i]['main_mobile'] = '';
						}
					} else {
						data[i]['name'] = data[i]['name'] + ' Ji';
						if (data[i]['contacts']) {
							var contacts = data[i]['contacts'];
							for (var contactType in contacts) {
								data[i][contactType] = JSON.stringify(contacts[contactType]);
								if (data[i][contactType].length >= 2) {
									data[i][contactType] = data[i][contactType].substring(
										1,
										data[i][contactType].length - 1
									);
								}
							}
						}
					}
					if (data[i]['rahbar'] !== null) {
						if (data[i]['rahbar'].indexOf('Shri') === -1) {
							data[i]['rahbar_title'] = titleMap[data[i]['rahbar_title']];
							if (typeof forExport === 'undefined' || forExport === false) {
								data[i]['rahbar'] = data[i]['rahbar_title'] + ' ' + data[i]['rahbar'] + ' Ji';
							} else {
								data[i]['rahbar'] = data[i]['rahbar'] + ' Ji';
							}
						} else {
							data[i]['rahbar'] = data[i]['rahbar'] + ' Ji';
							data[i]['rahbar_title'] = '';
						}
					} else {
						data[i]['rahbar_title'] = '';
					}

					if (data[i]['past_rahbar'] !== null) {
						if (data[i]['past_rahbar'].indexOf('Shri') === -1) {
							data[i]['past_rahbar_title'] = titleMap[data[i]['past_rahbar_title']];
							if (typeof forExport === 'undefined' || forExport === false) {
								data[i]['past_rahbar'] =
									data[i]['past_rahbar_title'] + ' ' + data[i]['past_rahbar'] + ' Ji';
							} else {
								data[i]['past_rahbar'] = data[i]['past_rahbar'] + ' Ji';
							}
						} else {
							data[i]['past_rahbar'] = data[i]['past_rahbar'] + ' Ji';
							data[i]['past_rahbar_title'] = '';
						}
					} else {
						data[i]['past_rahbar_title'] = '';
					}

					if (data[i]['head'] !== null) {
						if (data[i]['head'].indexOf('Shri') === -1) {
							data[i]['head_title'] = titleMap[data[i]['head_title']];
							if (typeof forExport === 'undefined' || forExport === false) {
								data[i]['head'] = data[i]['head_title'] + ' ' + data[i]['head'] + ' Ji';
							} else {
								data[i]['head'] = data[i]['head'] + ' Ji';
							}
						} else {
							data[i]['head'] = data[i]['head'] + ' Ji';
							data[i]['head_title'] = '';
						}
					} else {
						data[i]['head_title'] = '';
					}

					if (data[i]['dob'] !== null) {
						data[i]['dob'] = getDMY(data[i]['dob']);
					}
					if (data[i]['holydress_date'] !== null) {
						data[i]['holydress_date'] = getDMY(data[i]['holydress_date']);
					}
					if (data[i]['samadhi_date'] !== null) {
						data[i]['samadhi_date'] = getDMY(data[i]['samadhi_date']);
					}
				}

				return data;

				function getDMY(date) {
					var parts = date.split('-');
					return parts[2] + '-' + parts[1] + '-' + parts[0];
				}
			},
		};

		Records.exportOptions = {
			fieldKeys: [
				'title',
				'name',
				'old_name',
				'rahbar_title',
				'rahbar',
				'past_rahbar_title',
				'past_rahbar',
				'head_title',
				'head',
				'sewa',
				'dob',
				'naamdaan',
				'holydress_date',
				'holydress_place',
				'holydress_by',
				'samadhi_date',
				'samadhi_place',
				'image',
				'Mobile',
				'Landline',
				'Email',
				'contacts',
				'comments',
			],
			defaultFileName: 'MJBJ Records',
		};

		Records.dashboardOptions = {
			populateCharts: populateDashboardCharts,
			html:
				'<div id="dashboard-container">' +
				'<div class="row">' +
				'<div id="present_mjbj" class="col-xs-12 col-md-4"></div>' +
				'<div id="late_mjbj" class="col-xs-12 col-md-4"></div>' +
				'<div id="all_mjbj" class="col-xs-12 col-md-4"></div>' +
				'</div>' +
				'<div class="chart-title"><span>Holydress Yearly Data</span></div>' +
				'<div id="holydress-chart-container">' +
				'<div id="holydress-yearly"></div>' +
				'</div>' +
				'<div class="chart-title">Samadhi Yearly Data</div>' +
				'<div id="samadhi-chart-container">' +
				'<div id="samadhi-yearly"></div>' +
				'</div>' +
				'</div>',
			title: 'Dashboard',
		};

		Records.specificSearchHook = function (searchBy, query) {
			if (searchBy === 'age') {
				Records.clearSpecificSearchBy('dob');

				if (!query) {
					return {
						searchBy: searchBy,
						query: query,
					};
				}

				searchBy = 'dob';
				query = '' + getDoBFromAge(new Date(), query).getFullYear();
			} else {
				Records.clearSpecificSearchBy(searchBy);
			}

			return {
				searchBy: searchBy,
				query: query,
			};
		};

		Records.hooks.beforeAddTileShow = function (addTile) {
			configureAutoUpdateAgeDob(addTile);
			configureRahbarTypeAhead(addTile);
			configureGroupTypeAhead(addTile);
			updateForDD(addTile);

			return addTile;
		};

		Records.hooks.configureHook = function () {
			configureMainFilter();
		};

		Records.hooks.afterHeaderConfigure = function () {
			var $divider = $('<li class="divider"></li>');
			$('.selection-btn + .dropdown-menu').append($divider);

			var $sendSMS = $(
				'<li><a class="btn menu-text selection-sendsms"><span class="menu-text">Send SMS</span></a></li>'
			);
			$('.selection-btn + .dropdown-menu').append($sendSMS);

			$('.selection-sendsms').click(showSMSConfigScreen);

			$('.selection-btn + .dropdown-menu').append($divider.clone());
			setTimeout(updateGroups, 2000);
		};

		Records.hooks.afterAddRecord = function (record) {
			addGroups(record);
		};

		Records.hooks.afterEditRecord = function (record) {
			addGroups(record);
		};

		Records.hooks.afterAddMultiFieldRow = function (row) {
			configureGroupTypeAhead(row);
		};

		Records.getAdditionalSearchFilters = function () {
			return [getMainFilter()].concat(getGroupFilter());
		};

		Records.selectionDropdownOptionsMap = {
			ADA: 'All Dada',
			ADI: 'All Dadi',
			ADD: 'All Dada & Dadi',
			TM: 'This Year Mh. Ji',
			TB: 'This Year Bai Ji',
			TMB: 'This Year Mh. & Bai Ji',
			PM: 'Present Mh. Ji',
			PB: 'Present Bai Ji',
			PMB: 'Present Mh. & Bai Ji',
			LM: 'Late Mh. Ji',
			LB: 'Late Bai Ji',
			LMB: 'Late Mh. & Bai Ji',
			AM: 'All Mh. Ji',
			AB: 'All Bai Ji',
			AMB: 'All Mh. & Bai Ji',
			APP: 'In App',
			'*AR': 'All Records',
		};

		if (isOnlyMjBjSite()) {
			Records.selectionDropdownOptionsMap['*PMB'] = 'Present Mh. & Bai Ji';
			delete Records.selectionDropdownOptionsMap['*AR'];

			Records.selectionDropdownOptionsMap['AR'] = 'All Records';
		}

		Records.detailTileSelector = isOnlyMjBjSite() ? '#mjbj-brief-record' : '#mjbj-detail-record';
		Records.briefTileSelector = '#mjbj-brief-record';
		Records.addTileSelector = '#mjbj-add-record';
		Records.detailFormSelector = '#mjbj-detail-record-form';
		Records.imageUploadSelector = "input[name='image']";

		Records.populateBriefTile = populateBriefTile;
		Records.populateDetailTile = isOnlyMjBjSite() ? populateBriefTile : populateDetailTile;
		Records.populateDetailForm = populateDetailForm;
		Records.initModule({ systemId: 'mjbj', prepareRecord: prepareRecord, hasContacts: true, props: props });
	}

	function configureMainFilter() {
		$("select[name='main_filter_dropdown']").on('change', function () {
			Records.refreshRecords();
		});
	}

	function getMainFilter() {
		var filters = {};
		var mainFilter = $("select[name='main_filter_dropdown']");

		var mainFilterVal: any = mainFilter.val();

		var curYear = new Date().getFullYear() + '-';

		switch (mainFilterVal) {
			case 'ADA':
				filters = {
					title: 'Dada',
				};
				break;
			case 'ADI':
				filters = {
					title: 'Dadi',
				};
				break;
			case 'ADD':
				filters = [
					{
						title: 'Dada',
					},
					{
						title: 'Dadi',
					},
				];
				break;
			case 'TM':
				filters = {
					title: 'Mahatma',
					holydress_date: { leftnocase: curYear },
				};
				break;
			case 'PM':
				filters = {
					title: 'Mahatma',
					samadhi_date: { isNull: true },
					samadhi_place: { isNull: true },
				};
				break;
			case 'LM':
				filters = [
					{
						title: 'Mahatma',
						samadhi_date: { isNull: false },
					},
					{
						title: 'Mahatma',
						samadhi_place: { isNull: false },
					},
				];
				break;
			case 'AM':
				filters = {
					title: 'Mahatma',
				};
				break;
			case 'TB':
				filters = {
					title: 'Bai',
					holydress_date: { leftnocase: curYear },
				};
				break;
			case 'PB':
				filters = {
					title: 'Bai',
					samadhi_date: { isNull: true },
					samadhi_place: { isNull: true },
				};
				break;
			case 'LB':
				filters = [
					{
						title: 'Bai',
						samadhi_date: { isNull: false },
					},
					{
						title: 'Bai',
						samadhi_place: { isNull: false },
					},
				];
				break;
			case 'AB':
				filters = {
					title: 'Bai',
				};
				break;
			case 'TMB':
				filters = {
					holydress_date: { leftnocase: curYear },
				};
				break;
			case 'PMB':
				filters = [
					{
						title: 'Mahatma',
						samadhi_date: { isNull: true },
						samadhi_place: { isNull: true },
					},
					{
						title: 'Bai',
						samadhi_date: { isNull: true },
						samadhi_place: { isNull: true },
					},
				];
				break;
			case 'LMB':
				filters = [
					{
						title: 'Mahatma',
						samadhi_date: { isNull: false },
					},
					{
						title: 'Mahatma',
						samadhi_place: { isNull: false },
					},
					{
						title: 'Bai',
						samadhi_date: { isNull: false },
					},
					{
						title: 'Bai',
						samadhi_place: { isNull: false },
					},
				];
				break;
			case 'AMB':
				filters = [
					{
						title: 'Mahatma',
					},
					{
						title: 'Bai',
					},
				];
				break;
			case 'AR':
				filters = {};
				break;
			case 'APP':
				filters = {
					inApp: { isObject: true },
				};
				break;
			default:
				if (mainFilterVal.indexOf('group_') === 0) {
					var group = mainFilterVal.replace('group_', '');
					filters = [
						{
							group: group,
						},
						{
							group: { leftnocase: group + ',' },
						},
						{
							group: { rightnocase: ', ' + group },
						},
						{
							group: { likenocase: ', ' + group + ',' },
						},
					];
				}
				break;
		}

		return filters;
	}

	function getDoBFromAge(currentDate, age) {
		var yob = currentDate.getFullYear() - age;
		var dob = new Date();
		dob.setFullYear(yob);
		dob.setMonth(currentDate.getMonth());
		dob.setDate(currentDate.getDate());

		return dob;
	}

	function configureRahbarTypeAhead(tile) {
		var substringMatcher = function () {
			return function findMatches(this: any, query, callback) {
				var titleFieldId = $(this)[0].name + '_title';
				var titleValue = tile.find("select[name='" + titleFieldId + "']").val();

				query = $.trim(query);

				var filters: any[] = [];
				var words = query.split(' ');
				words = [titleValue].concat(words);
				if (titleValue.length === 0) {
					filters.push([{ title: { leftnocase: 'Mahatma' } }, { title: { leftnocase: 'Bai' } }]);
				} else if ('mahatma'.indexOf(words[0].toLowerCase()) === 0) {
					filters.push({ title: { leftnocase: 'Mahatma' } });
					query = query.replace(words[0], '');
				} else if ('bai'.indexOf(words[0].toLowerCase()) === 0) {
					filters.push({ title: { leftnocase: 'Bai' } });
					query = query.replace(words[0], '');
				}

				if (query.toLowerCase().indexOf('m.') === 0 || query.toLowerCase().indexOf('mh.') === 0) {
					filters.push({ title: { leftnocase: 'Mahatma' } });
					query = query.replace(/m./i, '');
				} else if (query.toLowerCase().indexOf('b.') === 0) {
					filters.push({ title: { leftnocase: 'Bai' } });
					query = query.replace(/b./i, '');
				}

				if ('ji'.indexOf(words[words.length - 1].toLowerCase()) === 0) {
					query = query.substr(0, query.lastIndexOf(words[words.length - 1]));
				}

				query = $.trim(query);

				filters.push({ name: { leftnocase: query } });

				let Model = ModelClass.Instance(stateMap.systemId);
				var records = Model.getRecords(filters, 'name logical', null);
				var matches: any[] = [];

				for (var i = 0; i < records.length; i++) {
					var image = records[i]['image'];

					matches.push({
						value: records[i]['name'],
						title: records[i]['title'],
						image: image,
						image_update_time: records[i]['image_update_time'],
						oldName: records[i]['old_name'],
						sewa: records[i]['sewa'],
					});
				}

				callback(matches);
			};
		};

		tile.find("input[name='rahbar']").typeahead(
			{
				hint: false,
				minLength: 3,
				highlight: true,
				classNames: {
					menu: 'tt-dropdown-menu',
				},
			},
			{
				name: 'rahbar',
				display: 'value',
				source: substringMatcher(),
				templates: { suggestion: getSuggestionHtml },
			}
		);
		tile.find("input[name='past_rahbar']").typeahead(
			{
				hint: false,
				minLength: 3,
				highlight: true,
				classNames: {
					menu: 'tt-dropdown-menu',
				},
			},
			{
				name: 'past_rahbar',
				display: 'value',
				source: substringMatcher(),
				templates: { suggestion: getSuggestionHtml },
			}
		);
		tile.find("input[name='head']").typeahead(
			{
				hint: false,
				minLength: 3,
				highlight: true,
				classNames: {
					menu: 'tt-dropdown-menu tt-dropdown-menu-down',
				},
			},
			{
				name: 'head',
				display: 'value',
				source: substringMatcher(),
				templates: { suggestion: getSuggestionHtml },
			}
		);

		tile.find("input[name='rahbar'], input[name='past_rahbar'], input[name='head']").on(
			'typeahead:selected',
			onTypeaheadSelect
		);

		function onTypeaheadSelect(this: any, e, suggestion, dataset) {
			var title = suggestion['title'];

			if (title !== null) {
				var titleFieldName = $(this).attr('name') + '_title';
				tile.find("select[name='" + titleFieldName + "']").val(title);
			}
		}

		function getSuggestionHtml(obj) {
			var $html = $(
				'<div class="row rahbar-sugestion">' +
					'<div class="avatar col-xs-2 no-padding">' +
					'<img />' +
					'</div>' +
					'<div class="col-xs-10 no-padding">' +
					'<div>' +
					obj.title +
					' ' +
					obj.value +
					'</div>' +
					'<div>' +
					obj.sewa +
					'</div>' +
					'</div>' +
					'</div>'
			);

			var $imgDiv = $html.find('img');
			Records.setImageSrc($imgDiv, obj.image, 'mjbj/images/');

			return $html;
		}
	}

	function configureGroupTypeAhead(tile) {
		var substringMatcher = function () {
			return function findMatches(query, callback) {
				query = $.trim(query).toUpperCase();

				var matches: any = [];
				stateMap.uniqueGroups.forEach(function (group) {
					if (group.startsWith(query)) {
						matches.push({ value: group });
					}
				});

				callback(matches);
			};
		};

		tile.find("input[name='group[]']").typeahead(
			{
				hint: false,
				minLength: 0,
				highlight: false,
				classNames: {
					menu: 'tt-dropdown-menu',
				},
			},
			{
				name: 'group',
				display: 'value',
				source: substringMatcher(),
			}
		);
	}

	function prepareRecord(record) {
		record['holydress_date'] = Utils.formatDate(record['holydress_date']);
		record['samadhi_date'] = Utils.formatDate(record['samadhi_date']);
		record['dob'] = Utils.formatDate(record['dob']);

		record['dob_md'] = record['dob'] ? (record['dob'] as string).substr(5) : null;
		record['holydress_date_md'] = record['holydress_date'] ? (record['holydress_date'] as string).substr(5) : null;
		record['samadhi_date_md'] = record['samadhi_date'] ? (record['samadhi_date'] as string).substr(5) : null;

		record['name'] = Utils.upperFirstAll(record['name']);
		record['title'] = Utils.upperFirstAll(record['title']);
		record['rahbar'] = Utils.upperFirstAll(record['rahbar']);
		record['past_rahbar'] = Utils.upperFirstAll(record['past_rahbar']);
		record['head'] = Utils.upperFirstAll(record['head']);
		record['old_name'] = Utils.upperFirstAll(record['old_name']);
		var groups = record['group'];
		record['group'] = '';
		var comma = '';
		for (var i = 0; i < groups.length; i++) {
			if (groups[i] && record['group'].indexOf(groups[i]) < 0) {
				record['group'] += comma + groups[i].toUpperCase();
				comma = ', ';
			}
		}
		//record["sewa"] = Utils.upperFirstAll(record["sewa"]);

		// record['card_printed'] = false;
		// record['sms_sent'] = false;

		return record;
	}

	function getAnySearchFilter(query) {
		return isOnlyMjBjSite() ? [
			{ title: { leftnocase: query } },
			{ name: { likenocase: query } },
			{ old_name: { likenocase: query } },
			{ sewa: { likenocase: query } }
		] : [
			{ title: { leftnocase: query } },
			{ name: { likenocase: query } },
			{ old_name: { likenocase: query } },
			{ dob: { likenocase: query } },
			{ rahbar: { likenocase: query } },
			{ past_rahbar: { likenocase: query } },
			{ head: { likenocase: query } },
			{ sewa: { likenocase: query } },
			{ holydress_by: { leftnocase: query } },
			{ contact: { likenocase: query } },
			{ holydress_by: { leftnocase: query } },
			{ holydress_by: { leftnocase: query } },
			{ holydress_date: { likenocase: query } },
			{ holydress_place: { likenocase: query } },
			{ samadhi_date: { likenocase: query } },
			{ samadhi_place: { likenocase: query } },
			{ comments: { likenocase: query } },
			function () {
				if (this.contacts) {
					for (let type in this.contacts) {
						for (let i = 0; i < this.contacts[type].length; i++) {
							if (this.contacts[type][i].indexOf(query) >= 0) {
								return true;
							}
						}
					}
				}
				return false;
			},
		];
	}

	function getReminderFilter(dateStr) {
		return [
			{
				holydress_date: { likenocase: dateStr },
			},
			{
				dob: { likenocase: dateStr },
			},
		];
	}

	function populateDashboardCharts($container) {
		var PMFilters = {
			title: 'Mahatma',
			samadhi_date: { isNull: true },
			samadhi_place: { isNull: true },
		};
		var PBFilters = {
			title: 'Bai',
			samadhi_date: { isNull: true },
			samadhi_place: { isNull: true },
		};
		var PMBFilters = {
			samadhi_date: { isNull: true },
			samadhi_place: { isNull: true },
		};

		var LMFilters = [
			{
				title: 'Mahatma',
				samadhi_date: { isNull: false },
			},
			{
				title: 'Mahatma',
				samadhi_place: { isNull: false },
			},
		];
		var LBFilters = [
			{
				title: 'Bai',
				samadhi_date: { isNull: false },
			},
			{
				title: 'Bai',
				samadhi_place: { isNull: false },
			},
		];
		var LMBFilters = [
			{
				samadhi_date: { isNull: false },
			},
			{
				samadhi_place: { isNull: false },
			},
		];

		var AMFilters = {
			title: 'Mahatma',
		};
		var ABFilters = {
			title: 'Bai',
		};
		var AMBFilters = {};

		let Model = ModelClass.Instance(stateMap.systemId);
		var PMCount = Model.getRecords([PMFilters], '', null).length;
		var PBCount = Model.getRecords([PBFilters], '', null).length;
		var PMBCount = Model.getRecords([PMBFilters], '', null).length;
		var LMCount = Model.getRecords([LMFilters], '', null).length;
		var LBCount = Model.getRecords([LBFilters], '', null).length;
		var LMBCount = Model.getRecords([LMBFilters], '', null).length;
		var AMCount = Model.getRecords([AMFilters], '', null).length;
		var ABCount = Model.getRecords([ABFilters], '', null).length;

		var allRecords = Model.getRecords([AMBFilters], '', null);
		var AMBCount = allRecords.length;

		$container.find('#present_mjbj').highcharts({
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
			},
			title: {
				text: 'Present MJ/BJ',
			},
			subtitle: {
				text: 'Total - ' + PMBCount,
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					size: 250,
					dataLabels: {
						enabled: true,
						distance: -60,
						format: '<b>{point.name}</b>:<br> {point.y} - ({point.percentage:.1f} %)',
						style: {
							color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
						},
					},
				},
			},
			series: [
				{
					type: 'pie',
					name: 'Present MJ/BJ',
					colors: ['chocolate', 'darkcyan'],
					data: [
						['Mahatma Ji', PMCount, (PMCount * 100) / PMBCount],
						['Bai Ji', PBCount, (PBCount * 100) / PMBCount],
					],
				},
			],
		});

		$container.find('#late_mjbj').highcharts({
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
			},
			title: {
				text: 'Late MJ/BJ',
			},
			subtitle: {
				text: 'Total - ' + LMBCount,
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					size: 250,
					dataLabels: {
						enabled: true,
						distance: -60,
						format: '<b>{point.name}</b>:<br> {point.y} - ({point.percentage:.1f} %)',
						style: {
							color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
						},
					},
				},
			},
			series: [
				{
					type: 'pie',
					name: 'Late MJ/BJ',
					colors: ['chocolate', 'darkcyan'],
					data: [
						['Mahatma Ji', LMCount, (LMCount * 100) / LMBCount],
						['Bai Ji', LBCount, (LBCount * 100) / LMBCount],
					],
				},
			],
		});

		$container.find('#all_mjbj').highcharts({
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
			},
			title: {
				text: 'All MJ/BJ',
			},
			subtitle: {
				text: 'Total - ' + AMBCount,
			},
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					size: 250,
					dataLabels: {
						enabled: true,
						distance: -60,
						format: '<b>{point.name}</b>:<br> {point.y} - ({point.percentage:.1f} %)',
						style: {
							color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
						},
					},
				},
			},
			series: [
				{
					type: 'pie',
					name: 'All MJ/BJ',
					colors: ['chocolate', 'darkcyan'],
					data: [
						['Mahatma Ji', AMCount, (AMCount * 100) / AMBCount],
						['Bai Ji', ABCount, (ABCount * 100) / AMBCount],
					],
				},
			],
		});

		var holydressDetails = {
			MJ: [],
			BJ: [],
		};

		var samadhiDetails = {
			MJ: [],
			BJ: [],
		};

		for (var i = 0, record, title, holydress_year, samadhi_year; i < allRecords.length; i++) {
			record = allRecords[i];
			title = record['title'] == 'Mahatma' ? 'MJ' : 'BJ';
			let oppTitle = title == 'MJ' ? 'BJ' : 'MJ';

			if (record['holydress_date']) {
				holydress_year = new Date(record['holydress_date']).getFullYear();

				if (typeof holydressDetails[title][holydress_year] == 'undefined') {
					holydressDetails[title][holydress_year] = 0;
				}
				if (typeof holydressDetails[oppTitle][holydress_year] == 'undefined') {
					holydressDetails[oppTitle][holydress_year] = 0;
				}
				holydressDetails[title][holydress_year]++;
			}

			if (record['samadhi_date']) {
				samadhi_year = new Date(record['samadhi_date']).getFullYear();

				if (typeof samadhiDetails[title][samadhi_year] == 'undefined') {
					samadhiDetails[title][samadhi_year] = 0;
				}
				if (typeof samadhiDetails[oppTitle][samadhi_year] == 'undefined') {
					samadhiDetails[oppTitle][samadhi_year] = 0;
				}
				samadhiDetails[title][samadhi_year]++;
			}
		}

		var holydressData = {
			MJ: [],
			BJ: [],
		};

		var samadhiData = {
			MJ: [],
			BJ: [],
		};

		for (title in holydressDetails) {
			for (var year in holydressDetails[title]) {
				holydressData[title].push([year, holydressDetails[title][year]]);
			}
		}
		for (title in samadhiDetails) {
			for (var year in samadhiDetails[title]) {
				samadhiData[title].push([year, samadhiDetails[title][year]]);
			}
		}

		$container.find('#holydress-yearly').highcharts({
			chart: {
				type: 'column',
			},
			title: {
				text: 'Holydress Yearly Data',
			},
			xAxis: {
				type: 'category',
				reversed: true,
				labels: {
					rotation: -90,
					style: {
						fontSize: '13px',
						fontFamily: 'Verdana, sans-serif',
					},
				},
			},
			yAxis: {
				min: 0,
				title: {
					text: 'Holydress Count',
				},
			},
			legend: {
				align: 'left',
				verticalAlign: 'top',
				floating: true,
				x: 20,
			},
			tooltip: {
				pointFormat: '{series.name} Holydress: <b>{point.y}</b>',
			},
			plotOptions: {
				series: {
					groupPadding: 0.1,
				},
			},
			series: [
				{
					name: 'Mahatma Ji',
					data: holydressData['MJ'],
					color: 'chocolate',
					dataLabels: {
						enabled: true,
						style: {
							fontSize: '9px',
							fontFamily: 'Verdana, sans-serif',
						},
					},
				},
				{
					name: 'Bai Ji',
					data: holydressData['BJ'],
					color: 'darkcyan',
					dataLabels: {
						enabled: true,
						style: {
							fontSize: '9px',
							fontFamily: 'Verdana, sans-serif',
						},
					},
				},
			],
		});

		$container.find('#samadhi-yearly').highcharts({
			chart: {
				type: 'column',
			},
			title: {
				text: 'Samadhi Yearly Data',
			},
			xAxis: {
				type: 'category',
				reversed: true,
				labels: {
					rotation: -90,
					style: {
						fontSize: '13px',
						fontFamily: 'Verdana, sans-serif',
					},
				},
			},
			yAxis: {
				min: 0,
				title: {
					text: 'Samadhi Count',
				},
			},
			legend: {
				align: 'left',
				verticalAlign: 'top',
				floating: true,
				x: 20,
			},
			tooltip: {
				pointFormat: '{series.name} Samadhi: <b>{point.y}</b>',
			},
			plotOptions: {
				series: {
					groupPadding: 0.1,
				},
			},
			series: [
				{
					name: 'Mahatma Ji',
					data: samadhiData['MJ'],
					color: 'chocolate',
					dataLabels: {
						enabled: true,
						style: {
							fontSize: '9px',
							fontFamily: 'Verdana, sans-serif',
						},
					},
				},
				{
					name: 'Bai Ji',
					data: samadhiData['BJ'],
					color: 'darkcyan',
					dataLabels: {
						enabled: true,
						style: {
							fontSize: '9px',
							fontFamily: 'Verdana, sans-serif',
						},
					},
				},
			],
		});
	}

	async function populateBriefTile(sampleTile, record) {
		Records.setImageSrc(sampleTile.find('.self-image'), record['image'], 'mjbj/images/');

		sampleTile.find('.self-detail .title').html(record['title'] === 'Mahatma' ? 'Mh.' : record['title']);
		sampleTile.find('.self-detail .value').html(record['name'] + ' Ji');
		sampleTile.find('.old-name-detail .value').html(record['old_name']);
		sampleTile.find('.sewa-detail .value').html(record['sewa']);

		return sampleTile;
	}

	async function populateDetailTile(sampleTile, record, waitOnImage) {
		let image$ = Records.setImageSrc(sampleTile.find('.self-image'), record['image'], 'mjbj/images/');
		if (waitOnImage) {
			await image$;
		}

		var titleMapping = {
			Mahatma: 'Mh.',
			Bai: 'Bai',
			Bhagat: 'Bh.',
			Dada: 'Dada',
			Dadi: 'Dadi',
		};

		sampleTile.find('.main-details .self-detail .title').html();
		sampleTile
			.find('.main-details .self-detail .value')
			.html(titleMapping[record['title']] + ' ' + record['name'] + ' Ji');
		sampleTile.find('.main-details .old-name-detail .value').html(record['old_name']);
		sampleTile.find('.main-details .naamdaan-detail .value').html(record['naamdaan']);

		if (record['rahbar'] !== null) {
			var rahbarTitle = titleMapping[record['rahbar_title']];
			var rahbarName = record['rahbar'] + ' Ji';
			if (record['rahbar'].indexOf('Shri') === -1) {
				rahbarName = rahbarTitle + ' ' + rahbarName;
			}

			sampleTile.find('.main-details .rahbar-detail .value').html(rahbarName);
		}
		if (record['past_rahbar'] !== null) {
			var rahbarTitle = titleMapping[record['past_rahbar_title']];
			var rahbarName = record['past_rahbar'] + ' Ji';
			if (record['past_rahbar'].indexOf('Shri') === -1) {
				rahbarName = rahbarTitle + ' ' + rahbarName;
			}
			sampleTile.find('.main-details .past-rahbar-detail .value').html(rahbarName);
		}
		if (record['head'] !== null) {
			var rahbarTitle = titleMapping[record['head_title']];
			var rahbarName = record['head'] + ' Ji';
			if (record['head'].indexOf('Shri') === -1) {
				rahbarName = rahbarTitle + ' ' + rahbarName;
			}
			sampleTile.find('.main-details .head-detail .value').html(rahbarName);
		}

		sampleTile.find('.main-details .sewa-detail .value').html(record['sewa']);
		sampleTile.find('.main-details .by-detail .value').html(record['holydress_by'] + ' Ji');

		if (record['contacts']) {
			var contacts = record['contacts'];
			var inApp = record['inApp'];
			for (var contactType in contacts) {
				for (var i = 0; i < contacts[contactType].length; i++) {
					var contactRow = sampleTile.find('.main-details .contact-detail #contact-type-value').clone();
					contactRow.attr('id', 'contact-type-value' + '-' + contactType + '-' + i);
					contactRow.find('.contact-type').html(contactType + ':');
					let contact = contacts[contactType][i];
					let inAppStr =
						inApp && inApp[contact]
							? '<span class="inApp"> [App: ' + inApp[contact]['userId'] + ']</span>'
							: '';
					contactRow.find('.contact-value').html(contact + inAppStr);
					sampleTile.find('.main-details .contact-detail').append(contactRow);
				}
			}
		} else {
			sampleTile.find('.main-details .contact-detail').hide();
		}

		if (record['group']) {
			sampleTile.find('.main-details .group-detail .value').html(record['group']);
		} else {
			sampleTile.find('.main-details .group-detail').hide();
		}

		handleEmptyData(sampleTile, record);

		return sampleTile;

		function handleEmptyData(detailTile, record) {
			if (record['title'] === 'Dada' || record['title'] === 'Dadi') {
				detailTile.find('.main-details .old-name-detail').hide();
				detailTile.find('.main-details .naamdaan-detail').hide();
				detailTile.find('.holydress-detail div:first-child .info-title > div').html('Refuge');
				detailTile.find('.samadhi-detail div:first-child .info-title > div').html('Left');
			}

			if (
				record['holydress_date'] === null &&
				(record['holydress_place'] === null || record['holydress_place'] === '')
			) {
				detailTile.find('.holydress-detail .place').html('&nbsp;');
			} else {
				if (record['holydress_date'] !== null) {
					var dateAr = record['holydress_date'].split('-');
					if (dateAr.length === 3) {
						var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
						dateStr = Utils.getDateStr(dateAr[0], dateAr[1], dateAr[2]);
						detailTile.find('.holydress-detail .date').html(dateStr);
					} else {
						detailTile.find('.holydress-detail .date').html(record['holydress_date']);
					}
				}
				detailTile.find('.holydress-detail .place').html(record['holydress_place']);
			}

			if (
				record['samadhi_date'] === null &&
				(record['samadhi_place'] === null || record['samadhi_place'] === '')
			) {
				detailTile.find('.samadhi-detail').hide();
			} else {
				if (record['samadhi_date'] !== null) {
					var dateAr = record['samadhi_date'].split('-');
					if (dateAr.length === 3) {
						var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
						dateStr = Utils.getDateStr(dateAr[0], dateAr[1], dateAr[2]);
						detailTile.find('.samadhi-detail .date').html(dateStr);
					} else {
						detailTile.find('.samadhi-detail .date').html(record['samadhi_date']);
					}
				}
				detailTile.find('.samadhi-detail .place').html(record['samadhi_place']);
			}

			if (record['dob'] !== null) {
				var dateAr = record['dob'].split('-');
				if (dateAr.length === 3) {
					var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
					dateStr = Utils.getDateStr(dateAr[0], dateAr[1], dateAr[2]);
					detailTile.find('.dob-detail .date').html(dateStr);
				} else {
					detailTile.find('.dob-detail .date').html(record['dob']);
				}

				var currentDate = new Date();
				if (record['samadhi_date'] !== null) {
					currentDate = new Date(record['samadhi_date']);
				}
				var age = Utils.getAgeFromDoB(currentDate, new Date(record['dob']));
				detailTile.find('.age-detail .value').html(age);
			}

			if (record['comments'] === null || record['comments'] === '') {
				detailTile.find('.comments-container').hide();
			} else {
				detailTile.find('.comments').html(record['comments']);
			}
		}
	}

	function configureAutoUpdateAgeDob(detailTile) {
		detailTile.find('.samadhi-detail input[name="samadhi_date"]').on('change', function () {
			updateAge(detailTile);
		});

		detailTile.find('.dob-detail input[name="dob"]').on('change', function () {
			updateAge(detailTile);
		});

		detailTile.find('.age-detail input[name="age"]').on('keyup', function () {
			updateDob(detailTile);
		});

		function updateDob(detailTile) {
			var currentDate = new Date();
			var samadhiDate = detailTile.find(".samadhi-detail input[name='samadhi_date']").val();
			if (samadhiDate !== null && samadhiDate !== '') {
				currentDate = new Date(samadhiDate);
			}

			var age = detailTile.find('.age-detail input[name="age"]').val();

			var dob = getDoBFromAge(currentDate, age);

			detailTile
				.find('.dob-detail input[name="dob"]')
				.attr('value', dob.getFullYear() + '-' + (dob.getMonth() + 1) + '-' + dob.getDate());
			detailTile.find('.dob-detail input[name="dob"]').datepicker('update', dob);
		}

		function updateAge(detailTile) {
			var currentDate = new Date();
			var samadhiDate = detailTile.find(".samadhi-detail input[name='samadhi_date']").val();
			if (samadhiDate !== null && samadhiDate !== '') {
				currentDate = new Date(samadhiDate);
			}

			var dob = detailTile.find('.dob-detail input[name="dob"]').val();
			if (dob === null || dob === '') {
				return;
			}
			dob = new Date(dob);

			var age = Utils.getAgeFromDoB(currentDate, dob);
			detailTile.find('.age-detail input[name="age"]').val(age);
		}
	}

	function populateDetailForm(sampleTile, record) {
		Records.setImageSrc(sampleTile.find('.self-image'), record['image'], 'mjbj/images/');

		sampleTile
			.find('.main-details .self-detail select[name="title"] option[value="' + record['title'] + '"]')
			.attr('selected', 'selected');
		sampleTile.find('.main-details .self-detail .value input').attr('value', record['name']);
		sampleTile.find('.main-details .old-name-detail .value input').attr('value', record['old_name']);
		sampleTile
			.find('.main-details .naamdaan-detail .value select option[value="' + record['naamdaan'] + '"]')
			.attr('selected', 'selected');
		if (record['rahbar'] !== null) {
			sampleTile
				.find('.main-details .rahbar-detail select option[value="' + record['rahbar_title'] + '"]')
				.attr('selected', 'selected');
			sampleTile.find('.main-details .rahbar-detail .value input').attr('value', record['rahbar']);
		}
		if (record['past_rahbar'] !== null) {
			sampleTile
				.find('.main-details .past-rahbar-detail select option[value="' + record['past_rahbar_title'] + '"]')
				.attr('selected', 'selected');
			sampleTile.find('.main-details .past-rahbar-detail .value input').attr('value', record['past_rahbar']);
		}
		if (record['head'] !== null) {
			sampleTile
				.find('.main-details .head-detail select option[value="' + record['head_title'] + '"]')
				.attr('selected', 'selected');
			sampleTile.find('.main-details .head-detail .value input').attr('value', record['head']);
		}
		sampleTile.find('.main-details .sewa-detail .value input').attr('value', record['sewa']);
		sampleTile
			.find('.main-details .by-detail .value select option[value="' + record['holydress_by'] + '"]')
			.attr('selected', 'selected');

		if (record['contacts']) {
			var contacts = record['contacts'];
			var count = 0;
			for (var contactType in contacts) {
				for (var i = 0; i < contacts[contactType].length; i++) {
					count++;
				}
			}
			var contactDivId = Records.populateMultiAddFields(sampleTile.find('.main-details .contact-detail'), count);

			count = 1;
			for (var contactType in contacts) {
				for (var i = 0; i < contacts[contactType].length; i++) {
					var contactDiv = sampleTile.find('#' + contactDivId + count++);
					contactDiv
						.find('select[name="contact_type[]"] option[value="' + contactType + '"]')
						.attr('selected', 'selected');
					contactDiv.find('input[name="contact[]"]').val(contacts[contactType][i]);
				}
			}
		}

		if (record['group']) {
			var groups = record['group'].split(', ');
			count = groups.length;
			var groupDivId = Records.populateMultiAddFields(sampleTile.find('.main-details .group-detail'), count);

			for (var i = 0; i < count; i++) {
				var groupDiv = sampleTile.find('#' + groupDivId + (i + 1));
				groupDiv.find('input[name="group[]"]').val(groups[i]);
			}
		}

		configureAutoUpdateAgeDob(sampleTile);
		configureRahbarTypeAhead(sampleTile);
		configureGroupTypeAhead(sampleTile);
		updateForDD(sampleTile);

		handleEmptyFields(sampleTile, record);

		return sampleTile;

		function handleEmptyFields(detailTile, record) {
			if (record['title'] === 'Dada' || record['title'] === 'Dadi') {
				detailTile.find('.main-details .old-name-detail').hide();
				detailTile.find('.main-details .naamdaan-detail').hide();
				detailTile.find('.holydress-detail div:first-child .info-title > div').html('Refuge');
				detailTile.find('.samadhi-detail div:first-child .info-title > div').html('Left');
			}

			if (
				!(
					record['holydress_date'] === null &&
					(record['holydress_place'] === null || record['holydress_place'] === '')
				)
			) {
				if (record['holydress_date'] !== null) {
					var dateAr = record['holydress_date'].split('-');
					if (dateAr.length === 3) {
						var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
						detailTile.find('.holydress-detail .date').attr('value', dateStr);
						detailTile.find('.holydress-detail .date').datepicker('update', dateStr);
					} else {
						detailTile.find('.holydress-detail .date').attr('value', record['holydress_date']);
						detailTile.find('.holydress-detail .date').datepicker('update', record['holydress_date']);
					}
				}
				detailTile.find('.holydress-detail .place').attr('value', record['holydress_place']);
			}

			if (
				!(
					record['samadhi_date'] === null &&
					(record['samadhi_place'] === null || record['samadhi_place'] === '')
				)
			) {
				if (record['samadhi_date'] !== null) {
					var dateAr = record['samadhi_date'].split('-');
					if (dateAr.length === 3) {
						var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
						detailTile.find('.samadhi-detail .date').attr('value', dateStr);
						detailTile.find('.samadhi-detail .date').datepicker('update', dateStr);
					} else {
						detailTile.find('.samadhi-detail .date').attr('value', record['samadhi_date']);
						detailTile.find('.samadhi-detail .date').datepicker('update', record['samadhi_date']);
					}
				}
				detailTile.find('.samadhi-detail .place').attr('value', record['samadhi_place']);
			}

			if (record['dob'] !== null) {
				var dateAr = record['dob'].split('-');
				if (dateAr.length === 3) {
					var dateStr = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
					detailTile.find('.dob-detail .date').attr('value', dateStr);
					detailTile.find('.dob-detail .date').datepicker('update', dateStr);
				} else {
					detailTile.find('.dob-detail .date').attr('value', record['dob']);
					detailTile.find('.dob-detail .date').datepicker('update', record['dob']);
				}

				var currentDate = new Date();
				if (record['samadhi_date'] !== null) {
					currentDate = new Date(record['samadhi_date']);
				}
				var age = Utils.getAgeFromDoB(currentDate, new Date(record['dob']));
				detailTile.find('.age-detail input[name="age"]').val(age);
			}

			if (!(record['comments'] === null || record['comments'] === '')) {
				detailTile.find('.comments').html(record['comments']);
			}
		}
	}

	function updateForDD(sampleTile) {
		sampleTile.find('.main-details .self-detail select[name="title"]').on('change', function (this: any) {
			var value = $(this).val();
			if (value === 'Dada' || value === 'Dadi') {
				sampleTile.find('.main-details .old-name-detail').hide();
				sampleTile.find('.main-details .naamdaan-detail').hide();
				sampleTile.find('.holydress-detail div:first-child .info-title > div').html('Refuge');
				sampleTile.find('.samadhi-detail div:first-child .info-title > div').html('Left');
			} else {
				sampleTile.find('.main-details .old-name-detail').show();
				sampleTile.find('.main-details .naamdaan-detail').show();
				sampleTile.find('.holydress-detail div:first-child .info-title > div').html('Holydress');
				sampleTile.find('.samadhi-detail div:first-child .info-title > div').html('Samadhi');
			}
		});
	}

	function updateGroups() {
		var $combineCheck = $(
			'<li><label><input type="checkbox" class="group-selection-combine" /> Combine</label></li>'
		);
		$('.selection-btn + .dropdown-menu').append($combineCheck);
		var $onlyCheck = $('<li><label><input type="checkbox" class="group-selection-only" /> Only</label></li>');
		$('.selection-btn + .dropdown-menu').append($onlyCheck);

		var $divider = $('<li class="divider"></li>');
		$('.selection-btn + .dropdown-menu').append($divider);

		$combineCheck.click(function (e) {
			e.stopPropagation();

			if (e.target.localName !== 'input') {
				return;
			}

			if (e.toElement && e.toElement.tagName.toLowerCase() !== 'input') {
				return;
			}

			Records.refreshRecords();
		});

		$onlyCheck.click(function (e) {
			e.stopPropagation();

			if (e.target.localName !== 'input') {
				return;
			}

			if (e.toElement && e.toElement.tagName.toLowerCase() !== 'input') {
				return;
			}

			Records.refreshRecords();
		});

		var records = Records.getCurrentRecords();
		for (var i = 0; i < records.length; i++) {
			addGroups(records[i]);
		}
	}

	function addGroups(record) {
		if (record.group) {
			var groups = record.group.split(', ');
			for (var j = 0; j < groups.length; j++) {
				if (groups[j] && stateMap.uniqueGroups.indexOf(groups[j]) < 0) {
					stateMap.uniqueGroups.push(groups[j]);
					$("select[name='main_filter_dropdown']").append(
						$("<option value='group_" + groups[j] + "'>" + groups[j] + '</option>')
					);
					var $groupCheck = $(
						'<li><label><input type="checkbox" class="group-selection" data-group="' +
							groups[j] +
							'" /> ' +
							groups[j] +
							'</label></li>'
					);
					$('.selection-btn + .dropdown-menu').append($groupCheck);
					$groupCheck.click(function (e) {
						e.stopPropagation();

						if (e.target.localName !== 'input') {
							return;
						}

						if (e.toElement && e.toElement.tagName.toLowerCase() !== 'input') {
							return;
						}

						var $input = $(this).find('input');
						var group = $input.attr('data-group');
						console.log(group);
						if ($input.is(':checked')) {
							console.log('added');
							stateMap.selectedGroupFilters.push(group);
						} else {
							console.log('removed');
							stateMap.selectedGroupFilters.splice(stateMap.selectedGroupFilters.indexOf(group), 1);
						}
						Records.refreshRecords();
					});
				}
			}
		}
	}

	function permute(result, values, str, separator) {
		if (values.length === 1) {
			result.push(str + separator + values[0]);
			return;
		}

		for (var i = 0; i < values.length; i++) {
			var partialValue = str + separator + values[i];
			permute(
				result,
				values.filter(function (value, index) {
					return index !== i;
				}),
				partialValue,
				', '
			);
		}
	}

	function getGroupFilter() {
		var isCombine = $('.group-selection-combine').is(':checked');
		var isOnly = $('.group-selection-only').is(':checked');
		var filters: any[] = [];
		if (isOnly) {
			var allowedValues = [];
			permute(allowedValues, stateMap.selectedGroupFilters, '', '');

			for (var i = 0; i < allowedValues.length; i++) {
				filters.push({
					group: { isnocase: allowedValues[i] },
				});
			}

			if (filters.length) {
				return [filters];
			} else {
				return [
					[
						{
							group: { isNull: true },
						},
					],
				];
			}
		}

		for (var i = 0; i < stateMap.selectedGroupFilters.length; i++) {
			filters.push({
				group: { likenocase: stateMap.selectedGroupFilters[i] },
			});
		}
		if (isCombine && filters.length) {
			return [filters];
		} else {
			return filters;
		}
	}

	function sendSMSToCurrentRecords(message, unicode, now, smsdate, smstime) {
		var sctime;
		if (!now) {
			if (new Date(smsdate + ' ' + smstime).getTime() < new Date().getTime() + 60000) {
				Notification.showBottomNotification('Scheduled Time is Before Current Time!');
				return;
			}

			sctime = Utils.formatDate(new Date(smsdate)) + ' ' + smstime;
		}

		var records = Records.getCurrentRecords();
		var mobileNos: any[] = [];
		let messages: any[] = [];
		for (var i = 0; i < records.length; i++) {
			if (records[i].contacts) {
				var contacts = records[i].contacts;
				if (contacts['Mobile']) {
					var mobiles = contacts['Mobile'][0].split(',');
					for (var j = 0; j < mobiles.length; j++) {
						mobileNos.push(mobiles[j].trim());
						messages.push({
							recordId: records[i].id,
							phone: mobiles[j].trim(),
						});
					}
				}
			}
		}

		sendSMS(message, messages, unicode, sctime);
	}

	async function sendSMS(message, messages, unicode, time) {
		// if (unicode) {
		// 	smsData['unicode'] = '1';
		// }

		// if (time) {
		// 	smsData['scheduledatetime'] = time;
		// }

		// var data = {
		// 	smsdata: JSON.stringify(smsData),
		// };

		var sendSMS = firebase.app().functions('asia-east2').httpsCallable('sendSMS');
		let result = await sendSMS({
			text: message,
			schtime: time,
			messages,
			DLT_TE_ID: '1307161133086133295',
			collection: 'mjbj',
		});

		console.log(result);
		Notification.showBottomNotification('SMS Submitted Successfully. Request ID: ' + result.data);

		// let Model = ModelClass.Instance(stateMap.systemId);
		// Model.customCall('mjbj_records/send_sms', data, function (response) {
		// 	try {
		// 		var result = JSON.parse(response);
		// 		if (result.type === 'success') {
		// 			Notification.showBottomNotification('SMS Submitted Successfully. Request ID: ' + result.message);
		// 		} else {
		// 			Notification.showBottomNotification('Error: ' + result.message);
		// 		}
		// 	} catch (e) {
		// 		Notification.showBottomNotification('Error: ' + response);
		// 	}
		// });
	}

	function showSMSConfigScreen() {
		var smsConfigHtml =
			'<div id="print-configs">' +
			'<div class="row">' +
			'<div class="col-md-1 no-r-padding"><label>Message:</label></div>' +
			'<div class="col-md-6 no-l-padding"><textarea class="sms" name="sms_message" placeholder="Message"></textarea></div>' +
			'<div class="col-md-1 no-l-padding"><input type="checkbox" id="unicode" name="unicode" /><label for="unicode">Unicode</label></div>' +
			'<div class="col-md-1 no-l-padding"><input type="checkbox" id="smsnow" name="smsnow" checked="checked" /><label for="smsnow">Now</label></div>' +
			'<div class="col-md-2 no-l-padding"><input id="smsdate" class="date" name="smsdate" /></div>' +
			'<div class="col-md-1 no-l-padding"><input id="smstime" name="smstime" placeholder="HH:mm:ss" /></div>' +
			'</div>';

		var fields = ['S. No.', 'Name', 'Old Name', 'Sewa', 'Main Mobile'];
		var fieldValues = ['id', 'name', 'old_name', 'sewa', 'main_mobile'];

		var widths = { id: 70, name: 250, old_name: 220, sewa: 250, main_mobile: 250 };

		var fieldSelectHtml = '<div class="row hidden">';
		for (var i = 0; i < fields.length; i++) {
			fieldSelectHtml += '<div class="col-xs-4 col-md-2">' + '<select data-index="' + i + '">';
			fieldSelectHtml += '<option value=""> </option>';
			for (var j = 0; j < fields.length; j++) {
				fieldSelectHtml +=
					'<option value="' +
					fieldValues[j] +
					'" ' +
					(i === j ? 'selected="selected" ' : '') +
					'>' +
					fields[j] +
					'</option>';
			}
			fieldSelectHtml +=
				'</select>' +
				'</div>' +
				'<div class="col-xs-2 col-md-1 no-l-padding">' +
				'<input name="width_' +
				i +
				'"type="number" value=' +
				widths[fieldValues[i]] +
				' />' +
				'</div>';
		}
		fieldSelectHtml += '</div>';

		smsConfigHtml += '<div class="fields-section">' + fieldSelectHtml + '</div>';

		smsConfigHtml += '<iframe id="print-preview" src="about:blank"></iframe>';
		smsConfigHtml += '</div>';

		var smsBtnHtml =
			'<button id="send_sms_btn" class="button btn btn-primary">\n\
						        <span><span>SEND SMS</span></span>\n\
					        </button>';
		var smsConfigObj = {
			contentHtml: smsConfigHtml,
			id: 'print_config',
			title: 'SMS Records',
			headerBtnHtml: smsBtnHtml,
		};

		OverlayPanel.showPanel(smsConfigObj);

		$('#send_sms_btn').click(function () {
			sendSMSToCurrentRecords(
				$("textarea[name='sms_message']").val(),
				$("input[name='unicode']").is(':checked'),
				$('#smsnow').is(':checked'),
				$('#smsdate').val(),
				$('#smstime').val()
			);
		});

		$('#smsnow').on('change', function () {
			if ($('#smsnow').is(':checked')) {
				$('#smsdate').hide();
				$('#smstime').hide();
			} else {
				$('#smsdate').show();
				$('#smstime').show();
			}
		});

		$('#smsdate').hide();
		$('#smstime').hide();

		($('#smsdate') as any).datepicker({
			format: 'D, dd M yyyy',
			autoclose: true,
			todayHighlight: true,
			todayBtn: 'linked',
			startDate: new Date().toLocaleString(),
		});

		Records.updatePrintPreview(null, false, false, 0);
	}

	return {
		initModule: initModule,
		setConfig: function (key, value) {
			configMap[key] = value;
		},
	};
})(jQuery);
