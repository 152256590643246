import { FireStoreModule } from 'fb';
import firebase from 'fb/index';
import 'firebase/firestore';
import { updatePincode } from 'store/data/journals/pincode/actions';

export class PincodeFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'pincode';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: firebase.firestore.Firestore) => db.collection('pincode');
	public getChangeProcessAction = (changes) => updatePincode(changes);
}
