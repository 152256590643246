import { logEvent } from 'components/telemetry';
import { DBConfig } from 'config';
import 'firebase/firestore';
import { getContentStorageUrl } from 'helpers';
import { ThunkDispatch } from 'redux-thunk';
import { getDataById } from 'store/data/generic/selectors';
import { updateOperation } from 'store/data/ops/actions';
import { ApplicationState, ContentType, Operation } from 'types';
import firebase, { fireDB } from './initconfig';

type FBDocChange = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>;
type FBTimestamp = firebase.firestore.Timestamp;
const fbFromDate = firebase.firestore.Timestamp.fromDate;

export interface FireStoreModule {
	getModuleName: () => string;
	getOpsKeySuffix: () => string;
	getFBWatchQuery: (db: firebase.firestore.Firestore) => firebase.firestore.Query<firebase.firestore.DocumentData>;
	getChangeProcessAction: (changes: FBDocChange[]) => any;
}

class SnapChange implements FBDocChange {
	constructor(private record) {
		for (let key in record) {
			if (record[key] && typeof record[key] === 'object' && typeof record[key]._nanoseconds !== 'undefined') {
				record[key] = new firebase.firestore.Timestamp(record[key]._seconds, record[key]._nanoseconds);
			}
		}
	}

	public type: firebase.firestore.DocumentChangeType = 'added';
	public oldIndex = 0;
	public newIndex = 0;
	public doc = {
		data: () => {
			return this.record;
		},
		exists: true,
		ref: null as unknown as firebase.firestore.DocumentReference,
		id: this.record.id,
		metadata: '' as unknown as firebase.firestore.SnapshotMetadata,
		isEqual: () => false,
		get: () => {},
	};
}

export class InitFireStoreModules {
	private static readonly BufferTime: number = 1;
	private getState: () => ApplicationState;
	private dispatch: ThunkDispatch<ApplicationState, any, any>;
	private unsubscribe: { [moduleName: string]: () => void } = {};
	// private allmodules: { [moduleName: string]: FireStoreModule | null } = {};

	constructor(getState: () => ApplicationState, dispatch: ThunkDispatch<ApplicationState, any, any>) {
		this.getState = getState;
		this.dispatch = dispatch;
	}

	private getUniqueKey = (module: FireStoreModule) => module.getModuleName() + '+' + module.getOpsKeySuffix();

	private getOpsKey(module: FireStoreModule) {
		return 'last-fb-fetch-timestamp::' + this.getUniqueKey(module);
	}

	private async processChanges(module: FireStoreModule, fetchTimeKey: string, changes: FBDocChange[]) {
		await this.dispatch(module.getChangeProcessAction(changes));

		let now = new Date();
		let fbFetchOp = this.getState().opsState[fetchTimeKey] || {
			key: fetchTimeKey,
			createdAt: now,
			value: null,
			updatedAt: now,
		};
		let maxUpdatedTime: FBTimestamp = fbFetchOp.value;
		if (!maxUpdatedTime) {
			maxUpdatedTime = fbFromDate(new Date(0));
		}

		changes.forEach((change) => {
			if (change.type === 'added' || change.type === 'modified') {
				let updatedAt: FBTimestamp = change.doc.data().updatedAt;
				if (updatedAt.seconds > maxUpdatedTime.seconds) {
					maxUpdatedTime = updatedAt;
				} else if (
					updatedAt.seconds === maxUpdatedTime.seconds &&
					updatedAt.nanoseconds > maxUpdatedTime.nanoseconds
				) {
					maxUpdatedTime = updatedAt;
				}

				// 	if (module.getModuleName() === 'configs' && change.doc.id.endsWith(DBConfig.UpdatedAt(''))) {
				// 		let collection = change.doc.id.replace(DBConfig.UpdatedAt(''), '');
				// 		let module = this.allmodules[collection];
				// 		if (!module) {
				// 			this.allmodules[collection] = null;
				// 		} else {
				// 			if (!this.unsubscribe[this.getUniqueKey(module)]) {
				// 				this.initModule(module, false, undefined, undefined, true);
				// 			}
				// 		}
				// 	}
			}
			if (change.type === 'removed') {
				// This case won't be there as we are not removing anything
				// In case this comes up due to direct manupulation on server
				// we ignore this even and do not change update time
				// next refresh time, we'll not get any changes from cur updated time
				// and we still keep the upgrade time as it is
			}
		});

		fbFetchOp.value = maxUpdatedTime;
		fbFetchOp.updatedAt = now;

		await this.dispatch(updateOperation(fbFetchOp));
	}

	private async initModule(
		module: FireStoreModule,
		initWithSnapshot?: boolean,
		snapshotTime?: number,
		snapShotJson?: any
		// forceSubscribe?: boolean
	) {
		return new Promise(async (resolve, reject) => {
			try {
				let db = fireDB;

				let fetchTimeKey = this.getOpsKey(module);
				let fbFetchOp: Operation = this.getState().opsState[fetchTimeKey] || ({} as Operation);
				let fetchTime: FBTimestamp = fbFetchOp.value;

				let moduleName = module.getModuleName();

				if (initWithSnapshot && snapshotTime && snapShotJson) {
					let snapData = snapShotJson[module.getModuleName()];
					if (snapData) {
						let changes = snapData.map((record) => new SnapChange(record));

						console.log('Processing snapshot for module(' + moduleName + '):');
						await this.processChanges(module, fetchTimeKey, changes);

						fetchTime = fbFromDate(new Date(snapshotTime));
					} else {
						if (!fetchTime) {
							fetchTime = fbFromDate(new Date(0));
						} else {
							fetchTime = new firebase.firestore.Timestamp(fetchTime.seconds, fetchTime.nanoseconds);
						}
					}
				} else {
					if (!fetchTime) {
						fetchTime = fbFromDate(new Date(0));
					} else {
						fetchTime = new firebase.firestore.Timestamp(fetchTime.seconds, fetchTime.nanoseconds);
					}
				}
				//  else {
				// 	fetchTime = new firebase.firestore.Timestamp(fetchTime.seconds, fetchTime.nanoseconds);
				// 	// let bufferedMillis = (fetchTime.seconds - InitFireStoreModules.BufferTime) * 1000;
				// 	// fetchTime = firebase.firestore.Timestamp.fromMillis(bufferedMillis);
				// }

				let query = module.getFBWatchQuery(db);

				let promiseNotHandled = true;

				// if (module.getModuleName() !== 'configs' && forceSubscribe !== true && this.allmodules[module.getModuleName()] !== null) {
				// 	this.allmodules[module.getModuleName()] = module;
				// 	resolve(true);
				// 	return;
				// }

				this.unsubscribe[this.getUniqueKey(module)] = query
					.where('updatedAt', '>', fetchTime)
					.orderBy('updatedAt', 'desc')
					// .limit(50)
					.onSnapshot(
						async (snapshot) => {
							try {
								console.log('Updates for module(' + moduleName + '):');
								if (snapshot.metadata.fromCache === true) {
									console.log('updates from cache');
									// return;
								}
								let updates = snapshot.docChanges();

								if (updates.length === 0) {
									// return;
								}

								await this.processChanges(module, fetchTimeKey, updates);
								promiseNotHandled && resolve(true);
								promiseNotHandled = false;
							} catch (error) {
								console.error('FB: onSnapshot: ' + error.message, error.stack);
								promiseNotHandled && reject(error);
								promiseNotHandled = false;
							}
						},
						(error) => {
							console.error('FB: onSnapshot: ' + error.message, error.stack);
						}
					);
			} catch (error) {
				console.error('FB: initModule: ' + error.message, error.stack);
			}
		});
	}

	public async initModules(modules: FireStoreModule[], initWithSnapshot?: boolean) {
		try {
			let snapShotJson = undefined;
			let timestamp: number | undefined = undefined;
			let fbFetchOp: Operation | undefined = undefined;
			if (initWithSnapshot === true) {
				try {
					let configs = getDataById(this.getState(), 'configs') || {};
					let uri = configs[DBConfig.Snapshot]?.uri;
					timestamp = configs[DBConfig.Snapshot]?.timestamp;
					if (timestamp && uri) {
						let fetchTimeKey = 'snapshot';
						fbFetchOp = this.getState().opsState[fetchTimeKey] || ({} as Operation);
						let fetchTime: number = fbFetchOp.value;
						if (!fetchTime || fetchTime < timestamp) {
							console.log('Fetching snapshot of time: ' + timestamp);
							let snapshotUrl = getContentStorageUrl(ContentType.Data, uri);
							let result = await window.fetch(snapshotUrl);
							snapShotJson = await result.json();

							let now = new Date();
							fbFetchOp =
								this.getState().opsState[fetchTimeKey] ||
								({ key: fetchTimeKey, createdAt: now } as Operation);
							fbFetchOp.value = timestamp;
							fbFetchOp.updatedAt = now;

							await this.dispatch({
								type: 'HACK_STATE',
								path: 'dataState.articles',
								value: { byId: {}, filtered: [] },
							});
							await this.dispatch({
								type: 'HACK_STATE',
								path: 'dataState.rhymes',
								value: { byId: {}, filtered: [] },
							});
							await this.dispatch({
								type: 'HACK_STATE',
								path: 'dataState.publications',
								value: { byId: {}, filtered: [] },
							});
							await this.dispatch({
								type: 'HACK_STATE',
								path: 'dataState.editions',
								value: { byId: {}, filtered: [] },
							});
							await this.dispatch({
								type: 'HACK_STATE',
								path: 'dataState.booklets',
								value: { byId: {}, filtered: [] },
							});
							await this.dispatch({
								type: 'HACK_STATE',
								path: 'dataState.notifications',
								value: { byId: {}, filtered: [] },
							});
							await this.dispatch({
								type: 'HACK_STATE',
								path: 'dataState.quotes',
								value: { byId: {}, filtered: [] },
							});
							await this.dispatch({
								type: 'HACK_STATE',
								path: 'dataState.hd',
								value: { byId: {}, filtered: [] },
							});
						}
					}
				} catch (error) {
					console.error('FB: initModules: ' + error.message, error.stack);
				}
			}

			let allInits: any[] = [];
			for (let i = 0; i < modules.length; i++) {
				allInits.push(this.initModule(modules[i], initWithSnapshot, timestamp, snapShotJson));
			}

			await Promise.all(allInits);

			if (snapShotJson && fbFetchOp) {
				await this.dispatch(updateOperation(fbFetchOp));
			}

			// let snap = await firebase.firestore().collection('anumati').where('ams', '==', false).where('publishTime', '<=', firebase.firestore.Timestamp.now()).where('publishTime', '>', firebase.firestore.Timestamp.fromDate(new Date('2023-10-13 00:00:00'))).get();
			// let records = snap.docs;
			// records.forEach((record) => console.log(record.data()));
		} catch (error) {
			console.error('FB: initModules: ' + error.message, error.stack);
		}
	}

	public async unsubscribeModule(module: FireStoreModule, clearFetchTime: boolean = true) {
		if (this.unsubscribe[this.getUniqueKey(module)]) {
			this.unsubscribe[this.getUniqueKey(module)]();
			delete this.unsubscribe[this.getUniqueKey(module)];
		}

		if (clearFetchTime) {
			let fetchTimeKey = this.getOpsKey(module);
			let fbFetchOp = this.getState().opsState[fetchTimeKey] || ({} as Operation);
			fbFetchOp.value = null;
			fbFetchOp.updatedAt = new Date();

			await this.dispatch(updateOperation(fbFetchOp));
		}
	}
}

export const getServerTime = () => {
	return new Date().getTime();
};

export const generateRecordId = (collection) => {
	return fireDB.collection(collection).doc().id;
};

export const setRecord = async (collection, recordId, values, byName = undefined) => {
	let db = fireDB;
	let query: any = db.collection(collection);

	values = {
		...values,
		by: firebase.auth().currentUser?.uid,
		updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
	};

	if (values.id) {
		delete values.id;
	}

	if (byName) {
		values.byName = byName;
	}

	if (recordId) {
		await query.doc(recordId).set(values, { merge: true });
		logEvent('setRecord', { ...values, updatedAt: null, createdAt: null });

		// await db.collection('configs').doc(DBConfig.UpdatedAt(collection)).update({
		// 	value: firebase.firestore.FieldValue.serverTimestamp(),
		// 	by: firebase.auth().currentUser?.uid,
		// 	updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
		// });

		return recordId;
	}
};

export default firebase;
