import { FireStoreModule } from 'fb';
import firebase from 'fb/index';
import 'firebase/firestore';
import { updateCategory } from 'store/data/journals/category/actions';

export class CategoryFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'category';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: firebase.firestore.Firestore) => db.collection('category');
	public getChangeProcessAction = (changes) => updateCategory(changes);
}
