import intlTelInput from 'intl-tel-input';
import localforage from 'localforage';
import { server } from './anand.data.server';
import './css/anand.css';

declare global {
	interface Window {
		jQuery: typeof jQuery;
		applicationCache: any;
	}
}

export const anand = (function ($) {
	var configMap = {
		headerId: '#header-container',
		footerId: '#footer-container',
		pageId: '#page-container',
		headerUrl: 'header.html',
		mainPageUrl: 'MjBjRecords.html',
		footerUrl: 'footer.html',
		workWithGoogleDrive: false,
		workOffline: false,
		isOffline: true,
	};

	var stateMap: any = {};

	function setOnline() {
		configMap.isOffline = false;
		if (typeof stateMap.connectivityListener !== 'undefined') {
			stateMap.connectivityListener();
		}
	}

	function setOffline() {
		configMap.isOffline = true;
		if (typeof stateMap.connectivityListener !== 'undefined') {
			stateMap.connectivityListener();
		}
	}

	function initConnectivityListener() {
		if (window.addEventListener) {
			window.addEventListener('online', setOnline, false);
			window.addEventListener('offline', setOffline, false);
		}

		//		if(navigator.onLine == false) {
		//			setOffline();
		//		} else {
		setOnline();
		//		}
	}

	function setConnectivityListener(listener: any) {
		stateMap.connectivityListener = listener;
	}

	function handleObsolete(e?: any) {
		if (window.applicationCache.status == window.applicationCache.OBSOLETE) {
			window.location.reload();
		}
	}
	function initModule() {
		// Check if a new cache is available on page load.
		window.applicationCache.addEventListener('obsolete', handleObsolete, false);

		if (window.applicationCache.status == window.applicationCache.OBSOLETE) {
			handleObsolete();
		}

		initConnectivityListener();
		localforage.config({
			name: 'anand.mjbj_records',
			driver: localforage.LOCALSTORAGE,
			//            size: 10 * 1024 * 1024 * 1024
		});

		$.ajaxSetup({
			cache: true,
			//			timeout: 300000
		});

		(function () {
			var proxy = ($.fn as any).serializeObject;
			($.fn as any).serializeObject = function () {
				var inputs = this.find(':disabled');
				inputs.prop('disabled', false);
				var serialized = proxy.apply(this, arguments);
				inputs.prop('disabled', true);
				return serialized;
			};
		})();

		$.validator.addMethod(
			'telInputValidator',
			function (value: string, telInput: any) {
				if ($.trim(value)) {
					if (intlTelInput($(telInput)[0]).isValidNumber() === false) {
						return false;
					} else {
						return true;
					}
				} else {
					return false;
				}
			},
			'Please provide a valid number'
		);

		localforage.getItem('manual_work_offline', function (err: any, value: boolean | null) {
			if (value !== null) {
				configMap.workOffline = value;
			}
		});
	}

	function getConfig(str: string) {
		return configMap[str];
	}

	function workOffline() {
		return false;

		if (configMap.workOffline || configMap.isOffline) {
			return true;
		} else {
			return false;
		}
	}

	function isOffline() {
		if (configMap.isOffline) {
			return true;
		} else {
			return false;
		}
	}

	function setWorkOffline(value: any, callback: () => void) {
		localforage.getItem('manual_work_offline', function (err: any, manual: any) {
			configMap.workOffline = value || manual;
			if (typeof callback !== 'undefined') {
				callback();
			}
		});
	}

	return {
		data: server,
		initModule: initModule,
		getConfig: getConfig,
		workOffline: workOffline,
		workWithDrive: getConfig('workWithGoogleDrive'),
		isOffline: isOffline,
		setWorkOffline: setWorkOffline,
		setConnectivityListener: setConnectivityListener,
	};
})(jQuery);
