import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import React from 'react';
import { ApplicationState, LocaleType } from 'types';
import {
	Card,
	FormControlLabel,
	Radio,
	Typography
} from '@material-ui/core';
import { changeLocale } from 'store/ux/actions';
import { connect } from 'react-redux';
import { DialogContext } from 'components/pages/DialogPage';
import { Field, Form, Formik } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import { Theme, withStyles } from '@material-ui/core/styles';
import { UserPicInfo } from './UserPicInfo';
import { logEvent } from 'components/telemetry';

const styles = (theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	},
	card: {
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
	},
	form: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	},
	signinbtn: {
		height: 42,
		width: '80%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	},
});

export interface Props {
	changeLocale: (locale: LocaleType) => void;
	locale: string;
	classes: any;
}

class ChangePassword extends React.Component<Props, any> {
	render() {
		let { classes, changeLocale, locale } = this.props;

		return (
			<div className={classes.root}>
				<UserPicInfo />

				<Card className={classes.card}>
					<Typography style={{ marginBottom: 24 }}>Select your preferred language:</Typography>
					<Formik
						initialValues={{
							lang: locale,
						}}
						validationSchema={() =>
							Yup.object({
								lang: Yup.string().required('Required'),
							})
						}
						onSubmit={(values, { setSubmitting }) => {
							changeLocale(values.lang as LocaleType);
							this.context.popScreen();
							return;
						}}>
						{({ submitForm, isSubmitting }) => (
							<Form className={classes.form} translate='yes'>
								<Field component={RadioGroup} name='lang'>
									<FormControlLabel
										value='en'
										control={<Radio disabled={isSubmitting} />}
										label='English'
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value='hi'
										control={<Radio disabled={isSubmitting} />}
										label='Hindi'
										disabled={isSubmitting}
									/>
								</Field>
								<Button
									className={classes.signinbtn}
									variant='contained'
									color='primary'
									onClick={submitForm}
									disabled={isSubmitting}>
									Save
								</Button>
							</Form>
						)}
					</Formik>
				</Card>
			</div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		locale: state.uxState.locale,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		changeLocale: async (locale: LocaleType) => {
			logEvent('locale', locale);
			dispatch(changeLocale(locale));
		},
	};
}

ChangePassword.contextType = DialogContext;

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChangePassword));
