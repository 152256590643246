import { FireStoreModule } from 'fb';
import firebase from 'fb/index';
import 'firebase/firestore';
import { updateSangat } from 'store/data/sangat/actions';

export class SangatFireStoreModule implements FireStoreModule {
	public getModuleName = () => 'sangat';
	public getOpsKeySuffix = () => 'global';
	public getFBWatchQuery = (db: firebase.firestore.Firestore) => db.collection('sangat');
	public getChangeProcessAction = (changes) => updateSangat(changes);
}
