import firebase from 'fb/initconfig';
import { Action } from 'redux';
import { BookRecord } from 'types';

export type FBChanges = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>[];

export const UPDATE_BOOK = '@@mjbj/UPDATE_BOOK';
export type UPDATE_BOOK = typeof UPDATE_BOOK;

export interface UpdateBook extends Action {
	type: UPDATE_BOOK;
	changes: FBChanges;
}

export function updateBook(changes: FBChanges): UpdateBook {
	return {
		type: UPDATE_BOOK,
		changes,
	};
}

export const UPDATE_BOOK_FILTERED = UPDATE_BOOK + '_FILTERED';
export type UPDATE_BOOK_FILTERED = typeof UPDATE_BOOK_FILTERED;

export interface UpdateBookFiltered extends Action {
	type: UPDATE_BOOK_FILTERED;
	payload: BookRecord[];
}
