import { Card, FormControl, InputLabel, MenuItem, Typography, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Theme } from '@material-ui/core/styles';
import { DialogContext } from 'components/pages/DialogPage';
import { DBConfig } from 'config';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { Context } from 'helpers/context';
import React from 'react';
import { connect } from 'react-redux';
import { FeedbackData, submitFeedback } from 'services/api/auth';
import { ApplicationState, AppParams, UserData } from 'types';
import * as Yup from 'yup';
import { UserPicInfo } from './UserPicInfo';

const styles = (theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		backgroundColor: theme.palette.grey[200],
		minHeight: '100%',
	},
	card: {
		margin: theme.spacing(1),
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
	},
	form: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column' as 'column',
		width: '100%',

		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	},
	signinbtn: {
		height: 42,
		width: '80%',
		alignSelf: 'center',
		marginTop: theme.spacing(2),

		'&:first-of-type': {
			marginTop: theme.spacing(5),
		},
	},
});

interface Props {
	classes: any;
	userData?: UserData;
	user?: firebase.default.User;
	appParams: AppParams;
	donationMail?: string;
	submitFeedback: (feedbackData: FeedbackData) => Promise<void>;
}

class Component extends React.Component<Props> {
	render() {
		let { classes, userData, donationMail, submitFeedback } = this.props;

		let defaultMessages = {
			donation: `You may directly send your message to <a href = "mailto:${
				donationMail || 'ssdnmail.donation@gmail.com'
			}">${donationMail || 'ssdnmail.donation@gmail.com'}</a> for any donation related concerns/feedback.`,
			journals:
				"You may directly contact/post your queries to postal address of 'Anand Sandesh' or 'Amar Sandesh' Karyalaya.",
			other: 'For any other concerns, please directly contact your rahbar Mahatma Ji or Bai Ji.',
		};
		return (
			<div className={classes.root}>
				<UserPicInfo />
				<Card className={classes.card}>
					<Typography style={{ marginBottom: 32 }} variant='body1'>
						Your feedback is valuable to us:
					</Typography>
					<Formik
						initialValues={{
							name: userData?.fullName ?? '',
							category: '',
							comments: '',
						}}
						validationSchema={() =>
							Yup.object({
								name: Yup.string().required('Required'),
								category: Yup.string().required('Required'),
								comments: Yup.string().required('Required'),
							})
						}
						onSubmit={async (values, { setSubmitting }) => {
							await submitFeedback(values);
							this.context.popScreen();
							return;
						}}
					>
						{({ values, submitForm, isSubmitting }) => (
							<Form className={classes.form} translate='yes'>
								{userData?.fullName ? null : (
									<Field component={TextField} type='text' label='Full Name' name='name' />
								)}
								<FormControl>
									<InputLabel htmlFor='category'>Category</InputLabel>
									<Field
										component={Select}
										name='category'
										inputProps={{
											id: 'category',
										}}
									>
										<MenuItem value='donation'>Donation</MenuItem>
										<MenuItem value='journals'>Home delivery of journals</MenuItem>
										<MenuItem value='appissue'>Facing issue in this app</MenuItem>
										<MenuItem value='feature'>Request new app feature</MenuItem>
										<MenuItem value='general'>General suggestion or enquiry</MenuItem>
										<MenuItem value='other'>Other</MenuItem>
									</Field>
								</FormControl>
								{['appissue', 'feature', 'general'].indexOf(values.category) > -1 ? (
									<React.Fragment>
										<Field
											style={{ width: '100%' }}
											component={TextField}
											name='comments'
											placeholder='Feedback Comments'
											rows={15}
											multiline
											variant='outlined'
										/>
										<Typography variant='body2'>
											Thank you for your valuable feedback. We give utmost importance to your
											feedback and we read all your messages. However, we may not reply to all
											messages.
										</Typography>
										<Button
											className={classes.signinbtn}
											variant='contained'
											color='primary'
											type='submit'
											onClick={submitForm}
											disabled={isSubmitting}
										>
											Submit
										</Button>
									</React.Fragment>
								) : (
									<Typography variant='body2' style={{ marginTop: 24 }}>
										<span dangerouslySetInnerHTML={{ __html: defaultMessages[values.category] }} />
									</Typography>
								)}
							</Form>
						)}
					</Formik>
				</Card>
			</div>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		// locale: state.uxState.locale,
		user: state.dataState.userStore.user,
		userData: state.dataState.userStore.userData,
		settings: state.uxState.settings,
		appParams: state.uxState.appParams,
		donationMail: state.dataState.configs[DBConfig.DonationMail]?.value,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		submitFeedback: async (feedbackData: FeedbackData) => {
			await dispatch(submitFeedback(feedbackData, new Context()));
		},
	};
}

Component.contextType = DialogContext;

export const UserFeedback = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component));
